import React, { useState } from "react";
import { Table, Card, Button, Modal, Form, Row, Col } from "react-bootstrap";
import { useDispatch } from "react-redux";
import moment from "moment";
import "../assets/css/Dashboard/companyAnalytics.css";
import SkModal from "./common/SkModal";

function FollowingCourse({ data }) {
  const [modalContent, setModalContent] = useState({
    title: "Modal Title",
    show: false,
    componentBody: "",
  });
  const [modalDetail, setModalDetail] = useState(false);
  const handleDetail = (data, create, update, pretest, posttest) => {
    setModalContent({
      ...modalContent,
      show: true,
      title: `Detail Course`,
      body: (
        <Table>
          <thead>
            <th className="pl-0">Mulai</th>
            <th className="pl-0">Terakhir Akses</th>
            <th>Nilai Pre Test</th>
            <th>Nilai Post Test</th>
          </thead>
          <tbody>
            <tr>
              <td className="pl-0">{moment(create).format("DD MMMM YYYY")}</td>
              <td className="pl-0">{moment(update).format("DD MMMM YYYY")}</td>
              <td>
                {pretest.length > 0 ? (
                  <ul className="pl-0">
                    {pretest?.map((e, i) => {
                      return (
                        <li className="mb-1">
                          <span>
                            <b>Percobaan {i + 1}:</b>
                          </span>{" "}
                          {e.score}
                        </li>
                      );
                    })}
                  </ul>
                ) : (
                  <div>Data Belum tersedia</div>
                )}
              </td>
              <td>
                {posttest.length > 0 ? (
                  <ul className="pl-0">
                    {posttest?.map((e, i) => {
                      return (
                        <li className="mb-1">
                          <span>
                            <b>Percobaan {i + 1}:</b>
                          </span>{" "}
                          {e.score}
                        </li>
                      );
                    })}
                  </ul>
                ) : (
                  <div>Data Belum tersedia</div>
                )}
              </td>
            </tr>
          </tbody>
        </Table>
      ),
    });
  };
  return (
    <>
      <Card className="card_container">
        <div className="card_inside__padding ">
          <div className="d-flex flex-column justify-content-between border_card">
            <div className="card_title__info mb-1">Pelatihan yang Diikuti</div>
            <div className="card_subtitle mb-1">
              {data?.stats_pelatihan?.length !== 0 ? (
                <div className="card_subtitle mb-1">
                  Total: {data?.stats_pelatihan?.length}
                </div>
              ) : (
                "N/A"
              )}
            </div>
          </div>
          <div className="row align-items-center">
            {data?.stats_pelatihan?.length !== 0 ? (
              data?.stats_pelatihan?.map((item, index) => {
                return (
                  <div className="d-flex align-items-center w-100" key={index}>
                    <div className="col-8">
                      <div key={index} className="certificate-wrapper">
                        <div className="wrapper-table__list">
                          {/* <div className="img-certificate mr-3"></div> */}
                          <div className="certificate-title__wrap">
                            <div className="certificate-title__main">
                              {item.course}
                            </div>
                            <div className="certificate-subtitle__secondary">
                              {/* <FaUserAlt className="mr-2 size" /> */}
                              Total Materi : {item.total_materials}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-4">
                      <Button
                        onClick={() =>
                          handleDetail(
                            item,
                            item?.created_at,
                            item?.last_updated,
                            item?.attempts_pre_test,
                            item?.attempts_post_test
                          )
                        }
                        className="button_detail__users"
                      >
                        Detail
                      </Button>
                    </div>
                  </div>
                );
              })
            ) : (
              <Col lg={12}>
                <div className="certificate-title__main">N/A</div>
              </Col>
            )}
          </div>
        </div>
      </Card>
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable={true}
        show={modalDetail}
        onHide={() => setModalDetail(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Detail Informasi</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table responsive striped hover>
            <thead>
              <th className="pl-0">Mulai</th>
              <th className="pl-0">Terakhir Akses</th>
              <th>Nilai Pre Test</th>
              <th>Nilai Post Test</th>
            </thead>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setModalDetail(!modalDetail)}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <SkModal content={modalContent} />
    </>
  );
}

export default FollowingCourse;
