import { PromiseService } from "../../services/PromiseService";
import { LEARNING_PATH_DATA_LINK } from "../config/LearningPathConfig";
import {
  //get
  getLearningPathData,
  getLearningPathDataSuccess,
  getLearningPathDataIdSuccess,
  getLearningPathDataFail,
  //post
  postLearningPathData,
  postLearningPathDataSuccess,
  postLearningPathDataFail,
  // put
  putLearningPathData,
  putLearningPathDataSuccess,
  putLearningPathDataFail,
  // delete
  deleteLearningPathData,
  deleteLearningPathDataSuccess,
  deleteLearningPathDataFail,
} from "../actions/LearningPathActions";

export const postLearningPathDataThunk = (data) => async (dispatch) => {
  dispatch(postLearningPathData());
  const body = data;
  try {
    const response = await PromiseService.postData(
      LEARNING_PATH_DATA_LINK,
      body
    );
    if (response.status === 201 && response.data) {
      dispatch(postLearningPathDataSuccess(response.data));
    } else {
      dispatch(postLearningPathDataFail(response));
    }
  } catch (error) {
    dispatch(postLearningPathDataFail(error));
  }
};

export const getLearningPathDataThunk = (data) => async (dispatch) => {
  dispatch(getLearningPathData());
  if (data) {
    let url = `${LEARNING_PATH_DATA_LINK}/${data}`;
    if (data.param) url = `${LEARNING_PATH_DATA_LINK}${data.param}`;
    try {
      const response = await PromiseService.getData(url);
      if (response.status === 200 && response.data) {
        if (data.param) dispatch(getLearningPathDataSuccess(response.data));
        else dispatch(getLearningPathDataIdSuccess(response.data));
      } else {
        dispatch(getLearningPathDataFail(response));
      }
    } catch (error) {
      dispatch(getLearningPathDataFail(error));
    }
  } else {
    try {
      const response = await PromiseService.getData(LEARNING_PATH_DATA_LINK);
      if (response.status === 200 && response.data) {
        dispatch(getLearningPathDataSuccess(response.data));
      } else {
        dispatch(getLearningPathDataFail(response));
      }
    } catch (error) {
      dispatch(getLearningPathDataFail(error));
    }
  }
};

// put
export const putLearningPathDataThunk = (data) => async (dispatch) => {
  dispatch(putLearningPathData());
  const body = data.body;
  const id = data.id;
  try {
    const response = await PromiseService.putData(
      `${LEARNING_PATH_DATA_LINK}/${id}`,
      body
    );
    if (response.status === 201 && response.data) {
      dispatch(putLearningPathDataSuccess(response.data));
    } else {
      dispatch(putLearningPathDataFail(response));
    }
  } catch (error) {
    dispatch(putLearningPathDataFail(error));
  }
};

// delete
export const deleteLearningPathDataThunk = (data) => async (dispatch) => {
  dispatch(deleteLearningPathData());
  const id = data;
  try {
    const response = await PromiseService.deleteData(
      `${LEARNING_PATH_DATA_LINK}/${id}`
    );
    if (response.status === 200 && response.data) {
      dispatch(deleteLearningPathDataSuccess(response.data));
    } else {
      dispatch(deleteLearningPathDataFail(response));
    }
  } catch (error) {
    dispatch(deleteLearningPathDataFail(error));
  }
};
