import React from "react";
import {
  ProSidebar,
  Menu,
  MenuItem,
  SubMenu,
  SidebarHeader,
  SidebarContent,
} from "react-pro-sidebar";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import {
  FaUserAlt,
  FaBookmark,
  FaHistory,
  FaCalendarAlt,
} from "react-icons/fa";
import "../../assets/css/Layout/aside.css";
// import sidebarBg from "../../assets/images/bg1.jpg";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { AuthLogout, AuthData } from "../../helper/auth";
import { useHistory, useLocation } from "react-router-dom";
import IconOverview from "../../assets/images/icon-overview.svg";
import IconManagementPenugasan from "../../assets/images/icon-management-penugasan.svg";
import IconAnalytics from "../../assets/images/histogram.svg";
import IconManagementOrganisasi from "../../assets/images/icon-management-organisasi.svg";
import IconOverviewBlue from "../../assets/images/icon-overview_blue.svg";
import IconManagementPenugasanBlue from "../../assets/images/icon-management-penugasan_blue.svg";
import IconAnalyticsBlue from "../../assets/images/histogram_blue.svg";
import IconManagementOrganisasiBlue from "../../assets/images/icon-management-organisasi_blue.svg";

const MySwal = withReactContent(Swal);

const Aside = ({ image, collapsed, rtl, toggled, handleToggleSidebar }) => {
  let address = useLocation();
  let splitted = address.pathname.split("/");
  const history = useHistory();
  return (
    <StyledWrapperMain>
      <ProSidebar
        rtl={rtl}
        collapsed={collapsed}
        toggled={toggled}
        breakPoint="lg"
        onToggle={handleToggleSidebar}
        className="satukelas_sidebar__container"
      >
        {collapsed ? (
          ""
        ) : (
          <SidebarHeader>
            <div className="greeting_msg">Halo, Admin</div>
          </SidebarHeader>
        )}

        <SidebarContent>
          <Menu iconShape={false}>
            {/* <MenuItem
              icon={<FaHome />}
              suffix={<span className="badge red">new</span>}
            >
              Homepage
              <Link to="/" />
            </MenuItem> */}
            {AuthData()?.role === "admin" ? (
              <>
                <MenuItem
                  id="overview"
                  className={splitted[2] === "overview" ? "active" : ""}
                  icon={
                    <img
                      src={
                        splitted[2] === "overview"
                          ? IconOverviewBlue
                          : IconOverview
                      }
                      alt="icon-1"
                    />
                  }
                >
                  <Link to="/dashboard/overview" className="ml-3">
                    {" "}
                    Overview
                  </Link>
                </MenuItem>
                <MenuItem
                  id="organisasi"
                  className={
                    splitted[2] === "management-organisasi" ? "active" : ""
                  }
                  icon={
                    <img
                      src={
                        splitted[2] === "management-organisasi"
                          ? IconManagementOrganisasiBlue
                          : IconManagementOrganisasi
                      }
                      alt="icon-1"
                    />
                  }
                >
                  <Link
                    to="/dashboard/management-organisasi"
                    className="ml-3"
                    style={{ display: "contents" }}
                  >
                    <span className="d-block">Management</span>
                    <span> Organisasi</span>
                  </Link>
                </MenuItem>
                <MenuItem
                  id="penugasan"
                  className={
                    splitted[2] === "management-penugasan" ? "active" : ""
                  }
                  icon={
                    <img
                      src={
                        splitted[2] === "management-penugasan"
                          ? IconManagementPenugasanBlue
                          : IconManagementPenugasan
                      }
                      alt="icon-2"
                    />
                  }
                >
                  <Link
                    to="/dashboard/management-penugasan"
                    className="ml-3"
                    style={{ display: "contents" }}
                  >
                    <span className="d-block">Management</span>
                    <span> Penugasan</span>
                  </Link>
                </MenuItem>
                <MenuItem
                  id="penugasan"
                  className={
                    splitted[2] === "analitik-pembelajaran" ? "active" : ""
                  }
                  icon={
                    <img
                      src={
                        splitted[2] === "analitik-pembelajaran"
                          ? IconAnalyticsBlue
                          : IconAnalytics
                      }
                      alt="icon-3"
                    />
                  }
                >
                  <Link
                    to="/dashboard/analitik-pembelajaran"
                    className="ml-3"
                    style={{ display: "contents" }}
                  >
                    <span className="d-block">Data & Analitik</span>
                    <span> Pembelajaran</span>
                  </Link>
                </MenuItem>
                <SubMenu
                  title={"Dashboard"}
                  icon={<FaUserAlt />}
                  defaultOpen={
                    splitted[2] === "" ||
                    splitted[2] === "learning-path" ||
                    splitted[2] === "kelola-user" ||
                    splitted[2] === "history" ||
                    splitted[2] === "home-work" ||
                    splitted[2] === "analytic" ||
                    splitted[2] === "my-bookmark"
                      ? true
                      : false
                  }
                >
                  <MenuItem
                    id="analytic"
                    className={splitted[2] === "analytic" ? "active" : ""}
                  >
                    {" "}
                    <b></b>
                    <b></b>
                    Analitik
                    <Link to="/dashboard/analytic" />
                  </MenuItem>
                  {/* <MenuItem
                  id="overview"
                  className={splitted[2] === "" ? "active" : ""}
                >
                  <b></b>
                  <b></b>
                  Overview
                  <Link to="/dashboard/" />
                </MenuItem> */}
                  {/* <MenuItem
                  id="report"
                  className={splitted[2] === "report" ? "active" : ""}
                >
                  <b></b>
                  <b></b>
                  Laporan
                  <Link to="/dashboard/report/" />
                </MenuItem> */}
                  {/* <MenuItem
                  id="learning-path"
                  className={splitted[2] === "learning-path" ? "active" : ""}
                >
                  <b></b>
                  <b></b>
                  Learning Path
                  <Link to="/dashboard/learning-path/" />
                </MenuItem> */}
                  <MenuItem
                    id="kelola-user"
                    className={splitted[2] === "kelola-user" ? "active" : ""}
                  >
                    {" "}
                    <b></b>
                    <b></b>
                    Kelola User
                    <Link to="/dashboard/kelola-user" />
                  </MenuItem>
                  <MenuItem
                    id="history"
                    className={splitted[2] === "history" ? "active" : ""}
                  >
                    {" "}
                    <b></b>
                    <b></b>
                    Riwayat Penugasan Course Wajib
                    <Link to="/dashboard/history" />
                  </MenuItem>
                  {/* <MenuItem
                  id="statistik"
                  className={splitted[2] === "statistik" ? "active" : ""}
                >
                  {" "}
                  <b></b>
                  <b></b>
                  Statistik
                  <Link to="/dashboard/statistik" />
                </MenuItem> */}
                  {/* <MenuItem
                  id="home-work"
                  className={splitted[2] === "home-work" ? "active" : ""}
                >
                  {" "}
                  <b></b>
                  <b></b>
                  PR Student
                  <Link to="/dashboard/home-work" />
                </MenuItem> */}

                  {/* <MenuItem
                  id="settings"
                  className={splitted[2] === "settings" ? "active" : ""}
                >
                  {" "}
                  <b></b>
                  <b></b>
                  Pengaturan
                  <Link to="/dashboard/settings/" />
                </MenuItem> */}
                </SubMenu>
              </>
            ) : null}
            {AuthData()?.role === "instructor" ? (
              ""
            ) : (
              <>
                <MenuItem
                  open={splitted[2] === "kalender-pembelajaran" ? true : false}
                  icon={<FaCalendarAlt />}
                  id="kalender-pembelajaran"
                  className={
                    splitted[2] === "kalender-pembelajaran" ? "active " : ""
                  }
                >
                  {" "}
                  <b></b>
                  <b></b>
                  Kalender
                  <Link to="/dashboard/kalender-pembelajaran" />
                </MenuItem>

                {/* <MenuItem
                  id="chat"
                  className={splitted[2] === "chat" ? "active" : ""}
                >
                  {" "}
                  <b></b>
                  <b></b>
                  <FaBookmark className="icon-parent" />
                  Chat
                  <Link to="/dashboard/chat" />
                </MenuItem> */}
              </>
            )}

            <SubMenu
              title={"Konten Belajar"}
              icon={<FaBookmark />}
              defaultOpen={
                splitted[2] === "table" ||
                (splitted[2] === "table" && splitted[3] === "course") ||
                (splitted[2] === "dashboard" &&
                  splitted[3] === "course" &&
                  splitted[4] === "create") ||
                (splitted[2] === "table" && splitted[3] === "webinar")
                  ? true
                  : false
              }
            >
              {/* <MenuItem
                id="table/learning-path/"
                className={
                  splitted[2] === "table" && splitted[3] === "learning-path"
                    ? "active"
                    : ""
                }
              >
                {" "}
                <b></b>
                <b></b>
                Tabel Learning Path
                <Link to="/dashboard/table/learning-path/" />
              </MenuItem> */}
              <SubMenu title={"Pelatihan"} icon={<FaBookmark />}>
                <MenuItem
                  id="table/course/"
                  className={`mt-2
                    ${
                      splitted[2] === "table" && splitted[3] === "course"
                        ? "active"
                        : ""
                    }
                  `}
                >
                  {" "}
                  <b></b>
                  <b></b>
                  Tabel Pelatihan
                  <Link to="/dashboard/table/course/" />
                </MenuItem>
                {AuthData()?.role === "instructor" && (
                  <MenuItem
                    id="table/webinar/"
                    className={`mt-2
                    ${
                      splitted[2] === "table" && splitted[3] === "webinar"
                        ? "active"
                        : ""
                    }
                  `}
                  >
                    {" "}
                    <b></b>
                    <b></b>
                    Tabel Webinar
                    <Link to="/dashboard/table/webinar/" />
                  </MenuItem>
                )}
                <MenuItem
                  id="course/create/"
                  className={
                    splitted[2] === "dashboard" &&
                    splitted[3] === "course" &&
                    splitted[4] === "create"
                      ? "active"
                      : ""
                  }
                >
                  {" "}
                  <b></b>
                  <b></b>
                  Buat Pelatihan
                  <Link to="/course/create/" />
                </MenuItem>
              </SubMenu>
              {/* <MenuItem id="overview">
                {" "}
                <b></b>
                <b></b>
                Tabel Topik
                <Link to="/dashboard/table/topic/" />
              </MenuItem>
              <MenuItem id="overview">
                {" "}
                <b></b>
                <b></b>
                Tabel Lesson
                <Link to="/dashboard/table/lesson/" />
              </MenuItem>
              <MenuItem id="overview">
                {" "}
                <b></b>
                <b></b>
                Tabel Quiz
                <Link to="/dashboard/table/quiz/" />
              </MenuItem> */}
              {/* <MenuItem
                id="table/webinar/"
                className={
                  splitted[2] === "table" && splitted[3] === "webinar"
                    ? "active"
                    : ""
                }
              >
                {" "}
                <b></b>
                <b></b>
                Tabel Webinar
                <Link to="/dashboard/table/webinar/" />
              </MenuItem> */}
            </SubMenu>
            {AuthData()?.role === "instructor" ? (
              ""
            ) : (
              <MenuItem
                icon={<FaHistory />}
                id="history-transaction"
                className={
                  splitted[2] === "dashboard" &&
                  splitted[3] === "history-transaction"
                    ? "active"
                    : ""
                }
                open={
                  splitted[2] === "dashboard" &&
                  splitted[3] === "history-transaction"
                    ? true
                    : false
                }
              >
                <b></b>
                <b></b>
                Riwayat Transaksi
                <Link to="/dashboard/history-transaction" />
              </MenuItem>
            )}
            {/* <SubMenu title={"Media"} icon={<MdLibraryAdd />}>
              <MenuItem id="overview">
                {" "}
                <b></b>
                <b></b>
                Library
                <Link to="/media/library" />
              </MenuItem>
              <MenuItem id="overview">
                {" "}
                <b></b>
                <b></b>
                Upload
                <Link to="/media/upload" />
              </MenuItem>
            </SubMenu> */}
            {/* <SubMenu title={"Komponen Lain"} icon={<FaBookmark />}>
              <MenuItem id="overview">
                {" "}
                <b></b>
                <b></b>
                Buat Learning Path
                <Link to="/learning-path/create" />
              </MenuItem>
              <MenuItem id="overview">
                {" "}
                <b></b>
                <b></b>
                Buat Pelatihan
                <Link to="/course/create" />
              </MenuItem>
              <MenuItem id="overview">
                {" "}
                <b></b>
                <b></b>
                Buat Topik
                <Link to="/topic/create" />
              </MenuItem>
              <MenuItem id="overview">
                {" "}
                <b></b>
                <b></b>
                Buat Lesson
                <Link to="/lesson/create" />
              </MenuItem>
              <MenuItem id="overview">
                {" "}
                <b></b>
                <b></b>
                Buat Tema Kuis
                <Link to="/tema-kuis/buat" />
              </MenuItem>
              <MenuItem id="overview">
                {" "}
                <b></b>
                <b></b>
                Buat Webinar
                <Link to="/webinar/create" />
              </MenuItem>
            </SubMenu> */}
          </Menu>
        </SidebarContent>
      </ProSidebar>
    </StyledWrapperMain>
  );
};

export default Aside;

const StyledWrapperMain = styled.section`
  .pro-sidebar-inner {
    background: #ffffff;
  }
  .pro-sidebar {
    color: #000;
    font-weight: bold;
  }
  svg {
    fill: #677ca7 !important;
  }
  .pro-sidebar .pro-menu > ul > .pro-sub-menu > .pro-inner-list-item {
    background-color: #fafafa;
  }
  .pro-sidebar {
    border-style: solid;
    border-width: 0px 1px 0px 0px;
    border-color: #00000011;
  }
`;
