import React, { useState } from "react";
import { Table, Card, Modal, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import "../assets/css/Dashboard/companyAnalytics.css";
import { getAnalyticsCourseStatsDataThunk } from "../redux/thunks/AnalyticsGroupThunks";
function PreTestList({ data, adminBnsp }) {
  const [show, setShow] = useState(false);
  const analyticsgroup = useSelector((state) => state.analyticsgroup);
  const dispatch = useDispatch();
  const handleShowDetail = (course_id, company_id, title) => {
    dispatch(
      getAnalyticsCourseStatsDataThunk({
        param: `?company_id=${company_id}&course_id=${course_id}`,
      })
    );
    setShow(true);
  };
  return (
    <>
      <Card className="card_container h-100">
        <div className="card_inside__padding pre-test__list">
          <div className="d-flex justify-content-between border_card">
            <div className="card_title__info mb-2">Pre Test List</div>
            {/* <div className="d-flex justify-content-end align-items-center mb-2 card_filter">
                Urutkan :{" "}
                <Dropdown className="ml-2">
                  <Dropdown.Toggle
                    className="px-3 py-1"
                    variant="success"
                    id="dropdown-basic"
                  >
                    Ascending
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item>Ascending</Dropdown.Item>
                    <Dropdown.Item>Descending</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>{" "}
              </div> */}
          </div>

          <Table responsive>
            <thead>
              <th className="pl-0">Pre Test Kelas</th>
              <th>Partisipan</th>
              <th>Rerata Perusahaan</th>
              <th>Rerata Seluruhnya</th>
              <th>Actions</th>
            </thead>
            <tbody>
              {data?.popular_pre_test?.length > 0 ? (
                data.popular_pre_test?.map((e, i) => {
                  return (
                    <tr key={i}>
                      <td className="pl-0">
                        <div>{e?.course}</div>
                      </td>
                      <td>
                        <span className="container-karyawan">
                          {" "}
                          {e?.participants}{" "}
                          {adminBnsp && adminBnsp?.usersDataId?.is_bnsp === 1
                            ? "Partisipan"
                            : "Karyawan"}
                        </span>
                      </td>
                      <td>
                        <div>
                          {" "}
                          <span className="container-avg__company">
                            {e?.avg_score_internal}
                          </span>
                        </div>
                      </td>
                      <td>
                        <span className="container-avg__all">
                          {e?.avg_score_global}
                        </span>
                      </td>
                      <td>
                        <Button
                          onClick={() =>
                            handleShowDetail(
                              e?.course_id,
                              data?.company_id,
                              "Sertifikasi",
                              "status"
                            )
                          }
                          className="detail_button__users"
                        >
                          Detail
                        </Button>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={4}>Data tidak ditemukan</td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      </Card>
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        scrollable={true}
        centered
        show={show}
        onHide={() => setShow(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Detail Partisipan Pre Test List</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table responsive striped hover>
            <thead>
              <th className="pl-0">
                Nama{" "}
                {adminBnsp && adminBnsp?.usersDataId?.is_bnsp === 1
                  ? "Partisipan"
                  : "Karyawan"}
              </th>
              <th className="pl-0">Posisi</th>
              <th className="w-50">Nilai</th>
              <th>Action</th>
            </thead>

            {analyticsgroup.analyticsCourseStatsData?.items?.map(
              (item, index) => {
                return (
                  <tr key={index}>
                    <td className="position_text bold w-75">
                      <div>{item.name}</div>
                    </td>
                    <td className="position_text">
                      <span>
                        {item?.position === "" ? "N/A" : item?.position}
                      </span>
                    </td>{" "}
                    <td className="position_text">
                      <span>{item?.max_score}</span>
                    </td>{" "}
                    <td className="position_text">
                      <div>
                        <Button
                          href={`/analytics/users/${item?.user_id}`}
                          className="detail_button__users"
                        >
                          Detail
                        </Button>
                      </div>
                    </td>
                  </tr>
                );
              }
            )}
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShow(!show)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default PreTestList;
