import { Link } from "@mui/material";
import { Card, Image } from "react-bootstrap";
import IconPlusWhite from "../../assets/images/icon-plus-white.svg";
import IconSearch from "../../assets/images/icon-search.svg";
import IconFilter from "../../assets/images/icon-filter.svg";
import IconEdit from "../../assets/images/icon-edit-pen.svg";
import IconTrash from "../../assets/images/icon-trash.svg";
import IconOrganisasi from "../../assets/images/icon-organisasi.svg";
import IconLocation from "../../assets/images/icon-location-gray.svg";
import IconPhone from "../../assets/images/icon-phone.svg";
import IconEmail from "../../assets/images/icon-email.svg";

export default function MyOrganization(props) {
  const { companies } = props;
  return (
    <Card className="p-3 card__pembelajaran-organisasi h-100 d-block">
      <div className="d-flex justify-content-between align-items-center mb-5">
        <h3 className="mb-0 title__card-man">Organisasi Anda</h3>
        <Link to={"/dashboard/management-organisasi/edit/edit-companies"}>
          <img src={IconEdit} alt="" />
        </Link>
      </div>
      <div className="d-flex" style={{ gap: 30 }}>
        <span className="">
          {companies.logo != "" || companies.logo != null ? (
            <Image src={companies.logo} width={82} height={90} alt="" />
          ) : (
            <IconOrganisasi
              width={82}
              height={90}
              style={{ color: "#306CCC" }}
            />
          )}
        </span>
        <div className="">
          <h3 className="mb-0 title__card-man">
            {companies.name != "" ? companies.name : "-"}
          </h3>
          <div className="d-flex">
            <span className="d-block mr-2">
              <img src={IconLocation} alt="" />
            </span>
            <div className="card__address-organisasi">
              <span className="d-block">
                {companies.address != "" ? companies.address : "-"}
              </span>
              <b className="my-4">Contact Person</b>
              <span className="d-block">
                {companies.cp_name != "" ? companies.cp_name : "-"}
              </span>
              <div className="d-flex">
                <span className="mr-2">
                  <img src={IconPhone} alt="" />
                  {companies.cp_phone != "" ? companies.cp_phone : "-"}
                </span>
                <span>
                  <img src={IconEmail} alt="" />
                  {companies.cp_email != "" ? companies.cp_email : "-"}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
}
