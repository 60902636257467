import {
  // get
  WEBINAR_DATA,
  WEBINAR_DATA_SUCCESS,
  WEBINAR_DATA_ID_SUCCESS,
  // post
  WEBINAR_DATA_POST,
  WEBINAR_DATA_POST_SUCCESS,
  WEBINAR_DATA_POST_FAIL,
  // put
  WEBINAR_DATA_PUT,
  WEBINAR_DATA_PUT_SUCCESS,
  WEBINAR_DATA_PUT_FAIL,
  // delete
  WEBINAR_DATA_DELETE,
  WEBINAR_DATA_DELETE_SUCCESS,
  WEBINAR_DATA_DELETE_FAIL,
  // fail
  WEBINAR_DATA_FAIL,
} from "../config/WebinarConfig";

// get
export const getWebinarData = (value) => ({
  type: WEBINAR_DATA,
  value,
});
export const getWebinarDataSuccess = (value) => ({
  type: WEBINAR_DATA_SUCCESS,
  value,
});
export const getWebinarDataIdSuccess = (value) => ({
  type: WEBINAR_DATA_ID_SUCCESS,
  value,
});
export const getWebinarDataFail = (value) => ({
  type: WEBINAR_DATA_FAIL,
  value,
});

// post
export const postWebinarData = (value) => ({
  type: WEBINAR_DATA_POST,
  value,
});
export const postWebinarDataSuccess = (value) => ({
  type: WEBINAR_DATA_POST_SUCCESS,
  value,
});
export const postWebinarDataFail = (value) => ({
  type: WEBINAR_DATA_POST_FAIL,
  value,
});

// put
export const putWebinarData = (value) => ({
  type: WEBINAR_DATA_PUT,
  value,
});
export const putWebinarDataSuccess = (value) => ({
  type: WEBINAR_DATA_PUT_SUCCESS,
  value,
});
export const putWebinarDataFail = (value) => ({
  type: WEBINAR_DATA_PUT_FAIL,
  value,
});

// delete
export const deleteWebinarData = (value) => ({
  type: WEBINAR_DATA_DELETE,
  value,
});
export const deleteWebinarDataSuccess = (value) => ({
  type: WEBINAR_DATA_DELETE_SUCCESS,
  value,
});
export const deleteWebinarDataFail = (value) => ({
  type: WEBINAR_DATA_DELETE_FAIL,
  value,
});

// fail handle
