import React from "react";
import { Card } from "react-bootstrap";
function Handouts({ items }) {
  return (
    <div className="course_front__deskripsi__wrapper">
      <div className="course_front__inner_title text-left">Handouts</div>

      <Card className="mb-4 padding_card">
        <div className="d-flex">
          {items?.length > 0 ? (
            items.map((e, i) => (
              <a href={e} key={i} className="mb-2 mr-3">
                {e}
              </a>
            ))
          ) : (
            <div className="covered_skill__list mb-0">Data Belum Tersedia</div>
          )}
        </div>
      </Card>
    </div>
  );
}

export default Handouts;
