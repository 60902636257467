import {
  //get
  LESSON_DATA,
  LESSON_DATA_ID_SUCCESS,
  LESSON_DATA_SUCCESS,
  LESSON_DATA_FAIL,
  //post
  LESSON_DATA_POST,
  LESSON_DATA_POST_SUCCESS,
  LESSON_DATA_POST_FAIL,
  //put
  LESSON_DATA_PUT,
  LESSON_DATA_PUT_SUCCESS,
  LESSON_DATA_PUT_FAIL,
  //delete
  LESSON_DATA_DELETE,
  LESSON_DATA_DELETE_SUCCESS,
  LESSON_DATA_DELETE_FAIL,
} from "../config/LessonConfig";

const intialState = {
  lessonDataPost: "",
  lessonDataPut: "",
  lessonDataDelete: "",
  lessonData: [],
  lessonDataId: [],
  isLoading: false,
};

export const LessonReducers = function (state = intialState, action) {
  switch (action.type) {
    //get
    case LESSON_DATA:
      return { ...state, isLoading: true };
    case LESSON_DATA_SUCCESS:
      return { ...state, isLoading: false, lessonData: action.value };
    case LESSON_DATA_ID_SUCCESS:
      return { ...state, isLoading: false, lessonDataId: action.value };
    case LESSON_DATA_FAIL:
      return {
        ...state,
        isLoading: false,
        lessonData: "error",
        lessonDataId: "error",
      };
    //post
    case LESSON_DATA_POST:
      return { ...state, isLoading: true, lessonDataPost: "" };
    case LESSON_DATA_POST_SUCCESS:
      return { ...state, isLoading: false, lessonDataPost: "success" };
    case LESSON_DATA_POST_FAIL:
      return { ...state, isLoading: false, lessonDataPost: "error" };

    //put
    case LESSON_DATA_PUT:
      return { ...state, isLoading: true, lessonDataPut: "" };
    case LESSON_DATA_PUT_SUCCESS:
      return { ...state, isLoading: false, lessonDataPut: "success" };
    case LESSON_DATA_PUT_FAIL:
      return { ...state, isLoading: false, lessonDataPut: "error" };

    //delete
    case LESSON_DATA_DELETE:
      return { ...state, isLoading: true, lessonDataDelete: "" };
    case LESSON_DATA_DELETE_SUCCESS:
      return { ...state, isLoading: false, lessonDataDelete: "success" };
    case LESSON_DATA_DELETE_FAIL:
      return { ...state, isLoading: false, lessonDataDelete: "error" };

    default:
      return state;
  }
};
