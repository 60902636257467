import React, { useEffect } from "react";
import { Row, Col, Container } from "react-bootstrap";
import TableCourse from "../../components/TableCourse";
import { AuthIsLogin } from "../../helper/auth";
import { useHistory } from "react-router-dom";

function CourseTable() {
  const history = useHistory();
  useEffect(() => {
    AuthIsLogin(history);
  }, []);
  return (
    <Container fluid>
      <Row className="justify-content-center align-items-center height-inherit">
        <Col xs={12} sm={12} md={12} lg={12}>
          <TableCourse type="course" />
        </Col>
      </Row>
    </Container>
  );
}

export default CourseTable;
