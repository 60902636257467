import React, { useState, useEffect } from "react";
import { Table, Card, Button, Modal } from "react-bootstrap";
// import { getAnalyticsUserDataThunk } from "../redux/thunks/AnalyticsUserThu<<<<<<< .merge_file_a03304
import { getAnalyticsCourseStatsDataThunk } from "../redux/thunks/AnalyticsGroupThunks";
import "../assets/css/Dashboard/companyAnalytics.css";
import Pagination from "./Layout/Pagination";
import { useDispatch, useSelector } from "react-redux";
function PartisipanTryOut({ data, adminBnsp }) {
  const [search, setSearch] = useState("");
  const [active, setActive] = useState(1);
  const [dataAdmin, setDataAdmin] = useState();
  const [dataDetail, setDataDetail] = useState();
  const [show, setShow] = useState(false);
  const [title, setTitle] = useState();
  const [size, setSize] = useState(5); //pagination size
  const [coursePagination, setCoursePagination] = useState();
  // state from redux
  const analyticsgroup = useSelector((state) => state.analyticsgroup);
  const users = useSelector((state) => state.users);

  // assign to state
  const completedCourse = analyticsgroup.analyticsCompletedData;

  const dispatch = useDispatch();

  // useEffect(() => {
  //   console.log(modalContent);
  // }, [modalContent]);

  useEffect(() => {
    if (analyticsgroup.analyticsCourseStatsData) {
      setDataDetail(analyticsgroup?.analyticsCourseStatsData?.data);
    }
  }, [analyticsgroup]);

  const handleShowDetail = (course_id, company_id, title) => {
    setTitle(title);
    dispatch(
      getAnalyticsCourseStatsDataThunk({
        param: `?company_id=${company_id}&course_id=${course_id}`,
      })
    );
    setShow(true);
  };

  useEffect(() => {
    setDataAdmin(users.usersDataId);
  }, [users]);

  function paginationSelect(newActive) {
    setActive(newActive);
    // dispatch(
    //   getCoursesData({
    //     param: `?size=${size}&page=${newActive}${
    //       search ? "&keyword=" + search : ""
    //     }`,
    //   })
    // );
  }

  return (
    <>
      <Card className="card_container h-100">
        <div className="card_inside__padding tryout-participant">
          <div className="d-flex justify-content-between align-items-center border_card">
            <div className="card_title__info mb-1">
              {adminBnsp && adminBnsp?.usersDataId?.is_bnsp === 1
                ? "Aptitude Test"
                : "Daftar Partisipan Tryout"}
            </div>
          </div>
          <Table className="text-nowrap">
            <thead>
              <tr role="row">
                <th>
                  {adminBnsp && adminBnsp?.usersDataId?.is_bnsp === 1
                    ? "Nama Aptitude Test"
                    : "Nama Tryout"}
                </th>
                <th>Kategori</th>
                <th>Partisipan</th>
                <th>Rerata Perusahaan</th>
                <th>Rerata Kesuluruhan</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {data?.popular_tryout.length > 0 ? (
                data.popular_tryout.map((item, i) => {
                  return (
                    <tr key={i} role="row" className="odd">
                      <td className="sorting_1">{item.course}</td>
                      <td>{item.categories === "" ? "-" : item.categories}</td>
                      <td>{item.participants}</td>
                      <td>{item.avg_score_internal}</td>
                      <td>{item.avg_score_global}</td>
                      <td>
                        <Button
                          onClick={() =>
                            handleShowDetail(
                              item?.course_id,
                              data?.company_id,
                              "Sertifikasi",
                              "status"
                            )
                          }
                          className="detail_button__users"
                        >
                          Detail
                        </Button>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={6}>Data Tidak Ditemukan</td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      </Card>

      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        scrollable={true}
        centered
        show={show}
        onHide={() => setShow(!show)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Detail Partisipan Tryout</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table responsive striped hover>
            <thead>
              <th className="pl-0">
                Nama{" "}
                {adminBnsp && adminBnsp?.usersDataId?.is_bnsp === 1
                  ? "Partisipan"
                  : "Karyawan"}
              </th>
              <th className="pl-0">Posisi</th>
              <th className="w-50">Status</th>
              <th>Action</th>
            </thead>

            {analyticsgroup.analyticsCourseStatsData?.items?.map(
              (item, index) => {
                return (
                  <tr key={index}>
                    <td className="position_text bold w-75">
                      <div>{item.name}</div>
                    </td>
                    <td className="position_text">
                      <span>
                        {item?.position === "" ? "N/A" : item?.position}
                      </span>
                    </td>{" "}
                    <td className="position_text">
                      {item?.passed === 0 ? (
                        <span className="container-avg__company-fail">
                          Tidak Lulus
                        </span>
                      ) : (
                        <span className="container-avg__company">Lulus</span>
                      )}
                    </td>
                    <td className="position_text">
                      <div>
                        <Button
                          href={`/analytics/users/${item?.user_id}`}
                          className="detail_button__users"
                        >
                          Detail
                        </Button>
                      </div>
                    </td>
                  </tr>
                );
              }
            )}
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShow(!show)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default PartisipanTryOut;
