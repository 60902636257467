import React from "react";
import { Card, Col } from "react-bootstrap";
import IconInfoMan from "../../assets/images/icon-info-blue.svg";

export default function InformationPenugasan() {
  return (
    <Col md={12} lg={6} className="mb-4 mb-lg-0">
      <Card className="p-3 card__pembelajaran">
        <div className="d-flex align-items-center">
          <span className="mr-3">
            <img src={IconInfoMan} width={24} height={24} alt="" />
          </span>
          <h3 className="mb-0 title__card-man">
            Pastikan pembelajaran terlaksana dengan fitur penugasan pelatihan.
          </h3>
        </div>
        <ul className="list__card-man">
          <li>Buat penugasan untuk mengelompokkan pembelajaran</li>
          <li>Pilih pelatihan yang sesuai dengan keinginan Anda</li>
          <li>Pilih anggota yang diwajibkan untuk menyelesaikan pelatihan</li>
          <li>Tetapkan tenggat waktu dan pantau penyelesaian pembelajaran </li>
        </ul>
      </Card>
    </Col>
  );
}
