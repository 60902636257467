import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Form, Row, Table } from "react-bootstrap";
import PaginationTemplate from "../Layout/Pagination";
import IconFilter from "../../assets/images/icon-filter.svg";
import IconSearch from "../../assets/images/icon-search.svg";
import { TaskServices } from "../../http/TaskHttp";
import { useSelector } from "react-redux";
export default function ContentCourses(props) {
  const {
    show,
    type,
    assigneeCourse,
    selectedCourseLength,
    courseSelectedList,
    changeSizeData,
    sizeCourseDefault,
  } = props;
  const resetForm = useRef();
  const [activeCourse, setActiveCourse] = useState(1);
  const [internalCourses, setInternalCourses] = useState(null);
  const [searchCourse, setSearchCourse] = useState("");
  const [showInternal, setShowInternal] = useState("internal");
  // const [sizeCourseDefault, setSizeCourseDefault] = useState(5);
  const users = useSelector((state) => state.users);
  const userDetailInfo = users.usersDataId;

  useEffect(() => {
    if (show && userDetailInfo?.company_id) {
      getCourseList(
        `${
          showInternal === "internal"
            ? `?company_id=${userDetailInfo.company_id}&`
            : "?"
        }size=${sizeCourseDefault}&page=${activeCourse}`
      );
    }
  }, [show, userDetailInfo, showInternal, activeCourse, sizeCourseDefault]);

  function selectTabActive(tab) {
    setShowInternal(tab);
    setSearchCourse("");
    resetForm.current.value = "";
  }

  function handleSearchCourse(e) {
    setSearchCourse(e.target.value);
  }

  function filterDataCourse() {
    getCourseList(
      `${
        showInternal === "internal"
          ? `?company_id=${userDetailInfo.company_id}&`
          : "?"
      }size=${sizeCourseDefault}&page=1${
        searchCourse ? `&keyword=${searchCourse}` : ""
      }`
    );
  }
  const getCourseList = async (param) => {
    try {
      const response = await TaskServices.getCourseByCompany(param);
      if (response) {
        setInternalCourses(response);
        // displayPopup(false, "Sukses membuat pelatihan", "Ok");
      }
    } catch (err) {
      console.log(err);
    }
  };

  function paginationSelectCourse(newActive) {
    setActiveCourse(newActive);
    getCourseList(
      `${
        showInternal === "internal"
          ? `?company_id=${userDetailInfo.company_id}&`
          : "?"
      }size=${sizeCourseDefault}&page=${newActive}`
    );
  }

  // function changeSizeData(e) {
  //   setSizeCourseDefault(e.target.value);
  //   getCourseList(
  //     `${
  //       showInternal === "internal"
  //         ? `?company_id=${userDetailInfo.company_id}&`
  //         : "?"
  //     }size=${e.target.value}&page=1`
  //   );
  // }

  return (
    <>
      <Row className="mt-3 mb-4">
        <Col
          lg={6}
          className={`wrapp__modal-show-data ${
            showInternal === "internal" ? "active" : ""
          }`}
          onClick={() => selectTabActive("internal")}
        >
          <h3 className="title__modal-show">Pelatihan Internal</h3>
        </Col>
        <Col
          lg={6}
          className={`wrapp__modal-show-data ${
            showInternal === "satukelas" ? "active" : ""
          }`}
          onClick={() => selectTabActive("satukelas")}
        >
          <h3 className="title__modal-show">Pelatihan Satukelas</h3>
        </Col>
        <Col lg={12} className="d-flex my-3">
          <div className="position-relative">
            <Form.Control
              placeholder="Cari"
              aria-label="Username"
              aria-describedby="basic-addon1"
              className="input__search-pelatihan"
              ref={resetForm}
              onChange={handleSearchCourse}
            />
            <div className="icon__search">
              <img src={IconSearch} alt="" />
            </div>
          </div>
          <Button
            onClick={filterDataCourse}
            variant="outline-secondary"
            id="button-addon2"
            className="ml-3 btn__filter-pelatihan"
          >
            <span className="mr-3">
              <img src={IconFilter} alt="" />
            </span>
            Filter
          </Button>
        </Col>
        <Col
          lg={12}
          className="d-flex justify-content-between align-items-center"
        >
          <div className="d-flex align-items-center">
            Terpilih <b className="mx-1">{selectedCourseLength}</b> dari{" "}
            <b className="mx-1">{internalCourses?.pagination?.totalItems}</b>{" "}
            Pelatihan
          </div>
          <div className="d-flex align-items-center">
            <span> Menampilkan</span>
            <select
              className="form-select mx-1"
              onChange={(e) => changeSizeData(e, "course")}
            >
              <option
                value="5"
                selected={sizeCourseDefault == 5 ? true : false}
              >
                5
              </option>
              <option
                value="10"
                selected={sizeCourseDefault == 10 ? true : false}
              >
                10
              </option>
              <option
                value="15"
                selected={sizeCourseDefault == 15 ? true : false}
              >
                15
              </option>
            </select>
            <span>data/halaman</span>
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg={12}>
          <Table responsive striped hover>
            <thead>
              <th>
                <Form.Check inline name="group1" type="checkbox" />
              </th>
              <th className="pl-0">Judul Pelatihan</th>
              <th className="pl-0">Tipe</th>
              <th>Kategori</th>
            </thead>
            <tbody>
              {internalCourses?.items?.length > 0 ? (
                internalCourses.items?.map((data, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        <Form.Check
                          onClick={() =>
                            assigneeCourse(
                              data.course_id,
                              data.title,
                              data.course_type
                            )
                          }
                          checked={
                            courseSelectedList.find(
                              (x) => x.id === data.course_id
                            )
                              ? true
                              : false
                          }
                          inline
                          name="group1"
                          value={data.course_id}
                          type="checkbox"
                        />
                      </td>
                      <td className="pl-0">{data.title}</td>
                      <td className="pl-0">{data.course_type}</td>
                      <td>{data.categories?.length}</td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={4} className="text-center">
                    Data Belum Tersedia
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </Col>
      </Row>
      <Row className="justify-content-between px-2">
        <div className="d-flex align-items-center">
          Terdapat{" "}
          <b className="mx-1">
            {internalCourses?.pagination?.totalItems} Pelatihan
          </b>{" "}
          tersedia
        </div>
        {internalCourses?.items?.length > 0 && (
          <div>
            <PaginationTemplate
              size={sizeCourseDefault}
              totalRows={internalCourses?.pagination?.totalItems}
              totalPages={internalCourses?.pagination?.totalPages}
              paginationSelect={paginationSelectCourse}
              active={activeCourse}
            />
          </div>
        )}
      </Row>
    </>
  );
}
