import {
  // get
  ANALYTICS_GROUP_DATA,
  ANALYTICS_GROUP_DATA_SUCCESS,
  ANALYTICS_GROUP_DATA_ID_SUCCESS,
  // fail
  ANALYTICS_GROUP_DATA_FAIL,
  // get assign
  ANALYTICS_ASSIGNED_DATA,
  ANALYTICS_ASSIGNED_DATA_SUCCESS,
  ANALYTICS_ASSIGNED_DATA_ID_SUCCESS,
  // fail assign
  ANALYTICS_ASSIGNED_DATA_FAIL,
  // get submitted
  ANALYTICS_SUBMITTED_DATA,
  ANALYTICS_SUBMITTED_DATA_SUCCESS,
  ANALYTICS_SUBMITTED_DATA_ID_SUCCESS,
  // fail submitted
  ANALYTICS_SUBMITTED_DATA_FAIL,

  // get
  ANALYTICS_COURSE_STATS_DATA,
  ANALYTICS_COURSE_STATS_DATA_SUCCESS,
  ANALYTICS_COURSE_STATS_DATA_ID_SUCCESS,
  // fail
  ANALYTICS_COURSE_STATS_DATA_FAIL,
  //remove
  REMOVE_ANALYTICS_GROUP_DATA_ID,
} from "../config/AnalyticsGroupConfig";

// get
export const getAnalyticsGroupData = (value) => ({
  type: ANALYTICS_GROUP_DATA,
  value,
});

export const getAnalyticsGroupDataSuccess = (value) => ({
  type: ANALYTICS_GROUP_DATA_SUCCESS,
  value,
});
export const getAnalyticsGroupDataIdSuccess = (value) => ({
  type: ANALYTICS_GROUP_DATA_ID_SUCCESS,
  value,
});

// fail handle
export const getAnalyticsGroupDataFail = (value) => ({
  type: ANALYTICS_GROUP_DATA_FAIL,
  value,
});

// get
export const getAnalyticsAssignedData = (value) => ({
  type: ANALYTICS_ASSIGNED_DATA,
  value,
});

export const getAnalyticsAssignedDataSuccess = (value) => ({
  type: ANALYTICS_ASSIGNED_DATA_SUCCESS,
  value,
});
export const getAnalyticsAssignedDataIdSuccess = (value) => ({
  type: ANALYTICS_ASSIGNED_DATA_ID_SUCCESS,
  value,
});

// fail handle
export const getAnalyticsAssignedDataFail = (value) => ({
  type: ANALYTICS_ASSIGNED_DATA_FAIL,
  value,
});

// get SUBMITTED
export const getAnalyticsSubmittedData = (value) => ({
  type: ANALYTICS_SUBMITTED_DATA,
  value,
});

export const getAnalyticsSubmittedDataSuccess = (value) => ({
  type: ANALYTICS_SUBMITTED_DATA_SUCCESS,
  value,
});
export const getAnalyticsSubmittedDataIdSuccess = (value) => ({
  type: ANALYTICS_SUBMITTED_DATA_ID_SUCCESS,
  value,
});

// fail SUBMIT
export const getAnalyticsSubmittedDataFail = (value) => ({
  type: ANALYTICS_SUBMITTED_DATA_FAIL,
  value,
});


// get
export const getAnalyticsCourseStatsData = (value) => ({
  type: ANALYTICS_COURSE_STATS_DATA,
  value,
});

export const getAnalyticsCourseStatsDataSuccess = (value) => ({
  type: ANALYTICS_COURSE_STATS_DATA_SUCCESS,
  value,
});
export const getAnalyticsCourseStatsDataIdSuccess = (value) => ({
  type: ANALYTICS_COURSE_STATS_DATA_ID_SUCCESS,
  value,
});

// fail handle
export const getAnalyticsCourseStatsDataFail = (value) => ({
  type: ANALYTICS_COURSE_STATS_DATA_FAIL,
  value,
});

//remove
export const removeAnalyticGroupDataId = (value) => ({
  type: REMOVE_ANALYTICS_GROUP_DATA_ID,
  value,
});
