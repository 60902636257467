import {
  //get
  INSTRUCTORS_DATA,
  INSTRUCTORS_DATA_ID_SUCCESS,
  INSTRUCTORS_DATA_SUCCESS,
  INSTRUCTORS_DATA_FAIL,
  REMOVE_INSTRUCTORS_DATA,
  INSTRUCTORS_LIVE_SEARCH_DATA,
  INSTRUCTORS_LIVE_SEARCH_DATA_SUCCESS,
  INSTRUCTORS_LIVE_SEARCH_DATA_FAIL,
} from "../config/InstructorsConfig";

const intialState = {
  instructorsData: [],
  instructorsDataId: [],
  instructorsLiveSearchData: null,
  isLoading: false,
  isLoadingLive: false,
};

export const InstructorsReducers = function (state = intialState, action) {
  switch (action.type) {
    //get
    case INSTRUCTORS_DATA:
      return { ...state, isLoading: true };
    case INSTRUCTORS_DATA_SUCCESS:
      return { ...state, isLoading: false, instructorsData: action.value };
    case INSTRUCTORS_DATA_ID_SUCCESS:
      return { ...state, isLoading: false, instructorsDataId: action.value };
    case INSTRUCTORS_DATA_FAIL:
      return {
        ...state,
        isLoading: false,
        instructorsData: "error",
        instructorsDataId: "error",
      };
    //get live search
    case INSTRUCTORS_LIVE_SEARCH_DATA:
      return { ...state, isLoadingLive: true, instructorsLiveSearchData: [] };
    case INSTRUCTORS_LIVE_SEARCH_DATA_SUCCESS:
      return {
        ...state,
        isLoadingLive: false,
        instructorsLiveSearchData: action.value,
      };
    case INSTRUCTORS_LIVE_SEARCH_DATA_FAIL:
      return {
        ...state,
        isLoadingLive: false,
        instructorsLiveSearchData: "error",
      };
    //remove
    case REMOVE_INSTRUCTORS_DATA:
      return { ...state, instructorsData: [] };

    default:
      return state;
  }
};
