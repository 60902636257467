import {
  // get
  COURSES_DATA,
  COURSES_DATA_SUCCESS,
  COURSES_DATA_ID_SUCCESS,
  COURSES_DATA_FAIL,
  //live search
  COURSES_LIVE_SEARCH_DATA,
  COURSES_LIVE_SEARCH_DATA_SUCCESS,
  COURSES_LIVE_SEARCH_DATA_FAIL,
  // post
  COURSES_DATA_POST,
  COURSES_DATA_POST_SUCCESS,
  COURSES_DATA_POST_FAIL,
  // put
  COURSES_DATA_PUT,
  COURSES_DATA_PUT_SUCCESS,
  COURSES_DATA_PUT_FAIL,
  // delete
  COURSES_DATA_DELETE,
  COURSES_DATA_DELETE_SUCCESS,
  COURSES_DATA_DELETE_FAIL,
  //remove
  REMOVE_COURSE_DATA_ID,
  REMOVE_COURSE_DATA,
  REMOVE_COURSE_DATA_PUT,
  // get completed
  COURSES_COMPLETED_DATA,
  COURSES_COMPLETED_DATA_SUCCESS,
  COURSES_COMPLETED_DATA_ID_SUCCESS,
  // fail completed
  COURSES_COMPLETED_DATA_FAIL,
} from "../config/CoursesConfig";

// get
export const getCoursesData = (value) => ({
  type: COURSES_DATA,
  value,
});
export const getCoursesDataSuccess = (value) => ({
  type: COURSES_DATA_SUCCESS,
  value,
});
export const getCoursesDataIdSuccess = (value) => ({
  type: COURSES_DATA_ID_SUCCESS,
  value,
});
export const getCoursesDataFail = (value) => ({
  type: COURSES_DATA_FAIL,
  value,
});

// live search
export const getCourseLiveSearchData = (value) => ({
  type: COURSES_LIVE_SEARCH_DATA,
  value,
});
export const getCourseLiveSearchDataSuccess = (value) => ({
  type: COURSES_LIVE_SEARCH_DATA_SUCCESS,
  value,
});
export const getCourseLiveSearchDataFail = (value) => ({
  type: COURSES_LIVE_SEARCH_DATA_FAIL,
  value,
});

// post
export const postCoursesData = (value) => ({
  type: COURSES_DATA_POST,
  value,
});
export const postCoursesDataSuccess = (value) => ({
  type: COURSES_DATA_POST_SUCCESS,
  value,
});
export const postCoursesDataFail = (value) => ({
  type: COURSES_DATA_POST_FAIL,
  value,
});

// put
export const putCoursesData = (value) => ({
  type: COURSES_DATA_PUT,
  value,
});
export const putCoursesDataSuccess = (value) => ({
  type: COURSES_DATA_PUT_SUCCESS,
  value,
});
export const putCoursesDataFail = (value) => ({
  type: COURSES_DATA_PUT_FAIL,
  value,
});

// delete
export const deleteCoursesData = (value) => ({
  type: COURSES_DATA_DELETE,
  value,
});
export const deleteCoursesDataSuccess = (value) => ({
  type: COURSES_DATA_DELETE_SUCCESS,
  value,
});
export const deleteCoursesDataFail = (value) => ({
  type: COURSES_DATA_DELETE_FAIL,
  value,
});

// remove

export const removeCourseDataId = (value) => ({
  type: REMOVE_COURSE_DATA_ID,
  value,
});
export const removeCourseData = (value) => ({
  type: REMOVE_COURSE_DATA,
  value,
});
export const removeCourseDataPut = (value) => ({
  type: REMOVE_COURSE_DATA_PUT,
  value,
});



// get COMPLETED
export const getCoursesCompletedData = (value) => ({
  type: COURSES_COMPLETED_DATA,
  value,
});

export const getCoursesCompletedDataSuccess = (value) => ({
  type: COURSES_COMPLETED_DATA_SUCCESS,
  value,
});
export const getCoursesCompletedDataIdSuccess = (value) => ({
  type: COURSES_COMPLETED_DATA_ID_SUCCESS,
  value,
});

// fail COMPLETED
export const getCoursesCompletedDataFail = (value) => ({
  type: COURSES_COMPLETED_DATA_FAIL,
});