import React from "react";
import { Image, Table } from "react-bootstrap";
import IconSearch from "../../assets/images/icon-search.svg";
import Debounce from "../../helper/Debounce";
import PaginationTemplate from "../Layout/Pagination";
import Filter from "./../../assets/images/filter.png";
import moment from "moment";
import Plus from "./../../assets/images/plus.png";
import DownloadSertif from "./../../assets/images/download-sertif.png";
import activeCloud from "./../../assets/images/cloudDownloadActive.svg";
import unactiveCloud from "./../../assets/images/cloudDownloadUnactive.svg";
import { Link } from "react-router-dom";

export default function ListUserDetail({ ...props }) {
  const {
    data,
    type,
    size,
    setSize,
    currentPage,
    paginationSelect,
    totalPages,
    totalRows,
    convertDate,
    setKeywordCourse,
    searchData,
    infoCourse,
  } = props;
  const onChangeKeyword = ({ target: { value } }) => {
    setKeywordCourse(value);
  };

  const seacrhData = Debounce(onChangeKeyword, 500);

  const tableHeadCertification = [
    "No",
    "Nama Anggota",
    "Waktu Terdaftar",
    "Nilai Tryout",
    "Nilai Ujian",
    "Status",
  ];
  const tableHeadOnDemand = [
    "No",
    "Nama Anggota",
    "Waktu Terdaftar",
    "Progress",
    "Waktu Selesai",
    "Perubahan Nilai",
  ];
  const tableHeadLiveClass = [
    "No",
    "Nama Anggota",
    "Waktu Terdaftar",
    "Waktu Pelaksanaan",
    "Feedback",
  ];
  const tableHeadCttCptt = [
    "No",
    "Nama Anggota",
    "Waktu Terdaftar",
    "Waktu Selesai",
    "Nilai",
    "Status",
    "Sertifikat",
  ];
  const tableHeadEnglish = [
    "No",
    "Nama Anggota",
    "Waktu Pendaftaran",
    "Waktu Selesai",
    "Nilai",
    "Progress",
    "Sertifikat",
  ];

  const tableHeadShow = (type) => {
    switch (type) {
      case "certificate":
        return tableHeadCertification;
      case "live":
        return tableHeadLiveClass;
      case "cttcptt":
        return tableHeadCttCptt;
      case "course":
        return infoCourse?.categories.includes("Bahasa Inggris")
          ? tableHeadEnglish
          : tableHeadOnDemand;
      default:
        break;
    }
  };

  const tableBodyShow = (type) => {
    switch (type) {
      case "certificate":
        return (
          data?.length > 0 &&
          data?.map((item, idx) => (
            <tr key={idx}>
              <td>{idx + 1}</td>
              <td>
                <Link
                  to={`/dashboard/analitik-pembelajaran/user/${item?.user_id}`}
                  className="_blue-text"
                >
                  {item?.user}
                </Link>
              </td>
              <td>
                {item?.enrolled_at
                  ? moment(item?.enrolled_at).format("DD MMM YYYY HH:mm")
                  : "-"}
              </td>
              <td>{item?.tryouts ? item?.tryouts : "-"}</td>
              <td>
                <div className="d-flex">
                  {item?.score?.length > 0
                    ? item.score.map((e, idex) => (
                        <div
                          className={`score-square ${
                            e > 75 ? "_lulus" : "_tidak-lulus"
                          }`}
                          key={idex}
                        >
                          {e}
                        </div>
                      ))
                    : "-"}
                </div>
              </td>
              <td>
                {item.status?.includes("%") ? (
                  <div className="status-percentage">
                    <div className="percentage">
                      <div
                        style={{ width: `${item.status}` }}
                        className="percentage-bar"
                      ></div>
                    </div>
                    <div className="current-percentage">{item.status}</div>
                  </div>
                ) : (
                  <div
                    className={`status-course _capitalize ${
                      item.status === "claimed"
                        ? "_claim"
                        : item.status === "lulus ujian"
                        ? "_lulus"
                        : item.status === "delivered"
                        ? "_send"
                        : "_tidak-lulus"
                    }`}
                  >
                    {item.status}
                  </div>
                )}
              </td>
            </tr>
          ))
        );
      case "live":
        return (
          data?.length > 0 &&
          data?.map((item, idx) => (
            <tr key={idx}>
              <td>{idx + 1}</td>
              <td>
                <Link
                  to={`/dashboard/analitik-pembelajaran/user/${item?.user_id}`}
                  className="_blue-text"
                >
                  {item?.user}
                </Link>
              </td>
              <td>{moment(item?.enrolled_at).format("DD MMM YYYY HH:mm")}</td>
              <td>{moment(item?.hosted_at).format("DD MMM YYYY HH:mm")}</td>
              <td>
                <div></div>
              </td>
            </tr>
          ))
        );
      case "cttcptt":
        return (
          data?.length > 0 &&
          data?.map((item, idx) => (
            <tr>
              <td>{idx + 1}</td>
              <td
                className="_ellipsis _blue-text"
                style={{
                  maxWidth: "250px",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                }}
              >
                {item?.user}
              </td>
              <td>
                {moment(item?.enrolled_at).format("DD MMM YYYY HH:mm") !==
                "Invalid date"
                  ? moment(item?.enrolled_at).format("DD MMM YYYY HH:mm")
                  : "-"}
              </td>
              <td>
                {moment(item?.completed_at).format("DD MMM YYYY HH:mm") !==
                "Invalid date"
                  ? moment(item?.completed_at).format("DD MMM YYYY HH:mm")
                  : "-"}
              </td>
              <td>{item?.score ? item?.score : "-"}</td>
              <td>
                {item.certificate_status?.includes("%") ? (
                  <div className="status-percentage">
                    <div className="percentage">
                      <div
                        style={{ width: `${item.certificate_status}` }}
                        className="percentage-bar"
                      ></div>
                    </div>
                    <div className="current-percentage">
                      {item.certificate_status}
                    </div>
                  </div>
                ) : (
                  <div
                    className={`status-course _capitalize ${
                      item.certificate_status == "claimed"
                        ? "_claimed"
                        : item.certificate_status == "not claimed"
                        ? "_tidak-lulus"
                        : item.certificate_status === "in delivery"
                        ? "_in-delivery"
                        : item.certificate_status == "claim_cptt/ctt"
                        ? "_claim-cptt"
                        : item.certificate_status == "delivered"
                        ? "_delivered"
                        : "_tidak-lulus"
                    }`}
                  >
                    {item.certificate_status === "delivered"
                      ? "Telah Sampai"
                      : item.certificate_status === "in delivery"
                      ? "Dalam Pengiriman"
                      : item.certificate_status === "claim_cptt/ctt"
                      ? "Klaim CTT"
                      : item.certificate_status === "not claimed"
                      ? "Belum Klaim"
                      : "Klaim Diterima"}
                  </div>
                )}
              </td>
              <td>
                <div>
                  {item.certificate_url ? (
                    <a
                      href={item.certificate_url}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Image src={activeCloud} width={24} height={24} />
                    </a>
                  ) : (
                    <div>
                      <Image src={unactiveCloud} width={24} height={24} />
                    </div>
                  )}
                </div>
              </td>
            </tr>
          ))
        );
      case "course":
        return infoCourse?.categories.includes("Bahasa Inggris")
          ? data?.length > 0 &&
              data?.map((item, idx) => (
                <tr>
                  <td>{idx + 1}</td>
                  <td>
                    <Link
                      to={`/dashboard/analitik-pembelajaran/user/${item?.user_id}`}
                      className="_blue-text"
                    >
                      {item?.user}
                    </Link>
                  </td>
                  <td>
                    {" "}
                    {moment(item?.enrolled_at).format("DD MMM YYYY HH:mm") !==
                    "Invalid date"
                      ? moment(item?.enrolled_at).format("DD MMM YYYY HH:mm")
                      : "-"}
                  </td>
                  <td>
                    {" "}
                    {moment(item?.completed_at).format("DD MMM YYYY HH:mm") !==
                    "Invalid date"
                      ? moment(item?.completed_at).format("DD MMM YYYY HH:mm")
                      : "-"}
                  </td>
                  <td>{item.score ? item.score : "-"}</td>
                  <td>
                    {item.progress !== 100 ? (
                      <div className="status-percentage">
                        <div className="percentage">
                          <div
                            style={{ width: `${item.progress}%` }}
                            className="percentage-bar"
                          ></div>
                        </div>
                        <div className="current-percentage">
                          {item.progress}%
                        </div>
                      </div>
                    ) : (
                      <div className={`status-course _capitalize _send`}>
                        Selesai
                      </div>
                    )}
                  </td>
                  <td>
                    {item.progress == 100 && item.certificate_url ? (
                      <a
                        href={item.certificate_url}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <Image src={activeCloud} width={24} height={24} />
                      </a>
                    ) : (
                      <div>
                        <Image src={unactiveCloud} width={24} height={24} />
                      </div>
                    )}
                  </td>
                </tr>
              ))
          : data?.length > 0 &&
              data?.map((item, idx) => (
                <tr key={idx}>
                  <td>{idx + 1}</td>
                  <td className="_blue-text">{item.user}</td>
                  <td className="_ellipsis">
                    {" "}
                    {moment(item?.enrolled_at).format("DD MMM YYYY HH:mm")}
                  </td>
                  <td>
                    <div className="status-current">
                      {item?.completed == 0 ? "Berlangsung" : "Selesai"}
                    </div>
                  </td>
                  <td>
                    {item?.completed_at
                      ? moment(item?.completed_at).format("DD MMM YYYY HH:mm")
                      : "-"}
                  </td>
                  <td>
                    <span>
                      <Image src={Plus} width={24} height={24} />
                    </span>
                    {item?.growth ? item?.growth : 0}%
                  </td>
                </tr>
              ));
      default:
        break;
    }
  };

  return (
    <div className="card-analitik">
      <div className="total-anggota_title _larger"></div>
      <div className="cta-table_analytic">
        <div className="position-relative">
          <div className="icon__search">
            <Image src={IconSearch} width={16} height={16} />
          </div>
          <input placeholder="Cari" onChange={seacrhData} />
        </div>
        <button onClick={searchData}>
          <span>
            <Image src={Filter} width={16} height={16} />
          </span>
          Filter
        </button>
      </div>
      <div className="text-right w-100 shown-info">
        Menampilkan
        <span>
          <select onChange={(e) => setSize(e.target.value)} defaultValue={size}>
            <option>5</option>
            <option>10</option>
            <option>20</option>
            <option>50</option>
            <option>100</option>
          </select>
        </span>
        data/halaman
      </div>
      <Table responsive striped hover>
        <thead>
          <tr>
            {tableHeadShow(type)?.map((item, indx) => (
              <th key={indx}>{item}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data?.length > 0 ? (
            tableBodyShow(type)
          ) : (
            <tr>
              <td colSpan={10} className="text-center">
                Tidak ada data
              </td>
            </tr>
          )}
        </tbody>
      </Table>
      <div className="d-flex align-items-center justify-content-center">
        <div>
          {data?.length > 0 && (
            <PaginationTemplate
              size={size}
              totalRows={totalRows}
              totalPages={totalPages}
              paginationSelect={paginationSelect}
              active={currentPage}
            />
          )}
        </div>
      </div>
    </div>
  );
}
