import React, { useState, useEffect } from "react";
import { Card, Accordion, Button } from "react-bootstrap";
import Swal from "sweetalert2";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import withReactContent from "sweetalert2-react-content";
import { useDispatch, useSelector } from "react-redux";
import { FaEdit } from "react-icons/fa";
import { RiDeleteBin2Line } from "react-icons/ri";
import "./../assets/css/Courses/AccordionCourse.css";
import { useParams } from "react-router-dom";
import {
  deleteTopicDataThunk,
  getTopicDataThunk,
} from "../redux/thunks/TopicThunks";
import {
  deleteLessonDataThunk,
  getLessonDataThunk,
} from "../redux/thunks/LessonThunks";
import {
  deleteWebinarDataThunk,
  getWebinarDataThunk,
} from "../redux/thunks/WebinarThunks";
import {
  deleteAssignmentDataThunk,
  getAssignmentDataThunk,
} from "../redux/thunks/AssignmentThunks";
import {
  deleteQuizDataThunk,
  getQuizDataThunk,
} from "../redux/thunks/QuizThunks";
import SkModal from "./common/SkModal";
import AddMateri from "./AddMateri";
import AddTopic from "./AddTopic";
import AddLesson from "./AddLesson";
import AddWebinar from "./AddWebinar";
import AddQuiz from "./AddQuiz";
import AddAssignment from "./AddAssignment";
import { AuthData } from "../helper/auth";
import MediaLibraryModal from "./common/MediaLibraryModal";
import {
  getCoursesDataThunk,
  putCoursesDataThunk,
} from "../redux/thunks/CoursesThunks";
import { removeCourseDataPut } from "../redux/actions/CoursesActions";
import Handouts from "./Handouts";

const MySwal = withReactContent(Swal);
function CorporateMaterial({ item, topicsList, fixedTitle }) {
  const [detailData, setDetailData] = useState([]);
  const [selected, setSelected] = useState([]);
  const [showAccordion, setShowAccordion] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [dataId, setDataId] = useState([]);
  const [selectedLibary, setSelectedLibary] = useState();
  const { course_id } = useParams();
  const topics = useSelector((state) => state.topics);
  const lessons = useSelector((state) => state.lessons);
  const quizzes = useSelector((state) => state.quizzes);
  const webinars = useSelector((state) => state.webinars);
  const assignments = useSelector((state) => state.assignments);
  const courses = useSelector((state) => state.courses);
  const users = useSelector((state) => state.users);

  const [modalContent, setModalContent] = useState({
    title: "Modal Title",
    show: false,
    componentBody: "",
  });
  const dispatch = useDispatch();

  const handleAdd = () => {
    setSelected("topic");
    setDetailData([]);
    handleAddTopic("topic");
  };

  useEffect(() => {
    setDataId(topics.topicDataId);
  }, [topics]);

  useEffect(() => {
    setDataId(lessons.lessonDataId);
  }, [lessons]);

  useEffect(() => {
    setDataId(quizzes.quizDataId);
  }, [quizzes]);

  useEffect(() => {
    setDataId(webinars.webinarDataId);
  }, [webinars]);

  useEffect(() => {
    setDataId(assignments.assignmentDataId);
  }, [assignments]);

  useEffect(() => {
    if (courses?.courseDataPut === "success") {
      dispatch(getCoursesDataThunk(course_id));
      dispatch(removeCourseDataPut());
    }
  }, [courses]);

  const handleEdit = (id, title, type) => {
    setSelected(type);
    const data = {
      id,
      title,
    };
    if (type === "topic") dispatch(getTopicDataThunk(id));
    else if (type === "lesson") dispatch(getLessonDataThunk(id));
    else if (type === "webinar") dispatch(getWebinarDataThunk(id));
    else if (type === "quiz") dispatch(getQuizDataThunk(id));
    else if (type === "assignment") dispatch(getAssignmentDataThunk(id));
    handleAddTopic(type);
    setDetailData(data);
  };

  const handleClose = () => {
    setModalShow(false);
  };

  const handleDelete = (id, type) => {
    MySwal.fire({
      title: `Anda yakin ingin menghapus ${type}?`,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Iya",
      cancelButtonText: "Tidak",
    }).then((value) => {
      if (value.isConfirmed) {
        if (type === "topic") dispatch(deleteTopicDataThunk(id));
        else if (type === "lesson") dispatch(deleteLessonDataThunk(id));
        else if (type === "webinar") dispatch(deleteWebinarDataThunk(id));
        else if (type === "quiz") dispatch(deleteQuizDataThunk(id));
        else if (type === "assignment") dispatch(deleteAssignmentDataThunk(id));
      }
    });
  };

  const handleAddMaterial = (topic_id) => {
    // show modal
    // and user can select material type: lesson, quiz, assignment, webinar
    setModalContent({
      ...modalContent,
      show: true,
      title: "Tambah Materi",
      body: (
        <AddMateri
          topic_id={topic_id}
          setModalContent={setModalContent}
          modalContent={modalContent}
        />
      ),
      topic_id: topic_id,
    });
  };
  const handleUpload = () => {
    setModalShow(true);
  };

  const handleAddTopic = (s) => {
    setModalContent({
      ...modalContent,
      show: true,
      title: "Tambah Topik",
      body: (
        <>
          {s === "topic" ? (
            <AddTopic
              id={detailData.id}
              defaultItems={dataId}
              handleCancel={handleClose}
              coorporate={true}
              fixedTitle={fixedTitle}
            />
          ) : null}
          {s === "lesson" ? (
            <AddLesson
              id={detailData.id}
              defaultItems={dataId}
              handleCancel={handleClose}
            />
          ) : null}
          {s === "webinar" ? (
            <AddWebinar
              id={detailData.id}
              defaultItems={dataId}
              handleCancel={handleClose}
              fixedTitle={fixedTitle}
            />
          ) : null}
          {s === "quiz" ? (
            <AddQuiz
              id={detailData.id}
              defaultItems={dataId}
              handleCancel={handleClose}
            />
          ) : null}
          {s === "assignment" ? (
            <AddAssignment
              id={detailData.id}
              defaultItems={dataId}
              handleCancel={handleClose}
            />
          ) : null}
        </>
      ),
    });
  };

  const libraryHandler = (newValue) => {
    setSelectedLibary(newValue);
  };

  const handleThumbnail = () => {
    const payload = {
      handouts: [selectedLibary],
    };
    dispatch(putCoursesDataThunk({ body: payload, id: course_id }));
    setModalShow(false);
  };

  return (
    <>
      <div className="course_front__kurikulum_title">
        Tambahkan Materi Perusahaan
      </div>
      <div
        className={`course_front__kurikulum_wrapper__accordion ${topicsList?.length > 0 ? "" : "p-0"
          }`}
      >
        {topicsList?.length > 0 ? (
          topicsList.map((items, index) => (
            <div key={index}>
              <Accordion className="course_front__kurikulum_wrapper">
                <Card className="mb-0">
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    className="p-0"
                    eventKey="0"
                    onClick={() => {
                      setShowAccordion(index);
                    }}
                  >
                    <Card.Header className="p-3 bg-greylight">
                      <div className="d-flex w-100 justify-content-between align-items-center text-left font-s font-weight-bold text-black text-decoration-none">
                        {items.title}

                        <span>
                          <Button
                            className="mr-2 px-3 text-white"
                            onClick={() =>
                              handleEdit(items.topic_id, items.title, "topic")
                            }
                            variant="info"
                          >
                            <FaEdit className="fill-white" />
                          </Button>
                          <Button
                            className="mr-3 px-3 text-white"
                            onClick={() =>
                              handleDelete(items.topic_id, "topic")
                            }
                            variant="danger"
                          >
                            <RiDeleteBin2Line className="fill-white" />
                          </Button>
                          {showAccordion === index ? (
                            <IoIosArrowDown />
                          ) : (
                            <IoIosArrowUp />
                          )}
                        </span>
                      </div>
                    </Card.Header>
                  </Accordion.Toggle>

                  {items?.materials?.length > 0 ? (
                    <>
                      {items.materials.map((detail, key) => (
                        <Accordion.Collapse key={key} eventKey="0">
                          <Card.Body>
                            <div className="accordion-course-main-div d-flex justify-content-between cursor-pointer">
                              <div className="d-flex">
                                <div className="blue-background d-flex align-items-center justify-content-center mr-3 rounded box text-center font-weight-bold white-font">
                                  <div>{key + 1}</div>
                                </div>
                                <div className="course_front__material_title">
                                  {detail.title}
                                </div>
                              </div>
                              <div className="d-flex flex-row">
                                <div className="mr-3">00:00</div>
                                <span>
                                  <Button
                                    className="mr-1 px-1 text-white"
                                    variant="info"
                                    onClick={() => {
                                      let type = detail.type.slice(0, -1);
                                      if (detail.type === "quizzes")
                                        type = "quiz";
                                      handleEdit(
                                        detail[`${type}_id`],
                                        detail.title,
                                        type
                                      );
                                    }}
                                  >
                                    <FaEdit className="fill-white" />
                                  </Button>
                                  <Button
                                    className="mr-1 px-1 text-white"
                                    variant="danger"
                                    onClick={() => {
                                      let type = detail.type.slice(0, -1);
                                      if (detail.type === "quizzes")
                                        type = "quiz";
                                      handleDelete(detail[`${type}_id`], type);
                                    }}
                                  >
                                    <RiDeleteBin2Line className="fill-white" />
                                  </Button>
                                </span>
                              </div>
                            </div>
                          </Card.Body>
                        </Accordion.Collapse>
                      ))}
                      <Accordion.Collapse eventKey="0">
                        <Card.Body>
                          <div className="accordion-course-main-div  cursor-pointer">
                            <Button
                              onClick={() => handleAddMaterial(items.topic_id)}
                            >
                              + Tambah Materi
                            </Button>
                          </div>
                        </Card.Body>
                      </Accordion.Collapse>
                    </>
                  ) : (
                    <Accordion.Collapse eventKey="0">
                      <Card.Body>
                        <div className="accordion-course-main-div d-flex justify-content-between cursor-pointer">
                          <Button
                            onClick={() => handleAddMaterial(items.topic_id)}
                          >
                            + Tambah Materi
                          </Button>
                        </div>
                      </Card.Body>
                    </Accordion.Collapse>
                  )}
                </Card>
              </Accordion>
            </div>
          ))
        ) : (
          <div className="padding_card__corp corp_material__data mb-0">
            Data Belum Tersedia
          </div>
        )}
      </div>
      {!modalShow && AuthData().role == "admin" ? (
        <div className="text-right w-100">
          <Button onClick={handleAdd}>+ Tambahkan Topik</Button>
        </div>
      ) : null}
      <div className="text-right mb-3 mt-3">
        {!modalShow && AuthData().role == "admin" ? (
          <>
            <Handouts items={courses?.courseDataId?.handouts} />
            <Button className="mr-3" onClick={handleUpload}>
              Upload File
            </Button>
          </>
        ) : null}
      </div>
      <MediaLibraryModal
        show={modalShow}
        setShow={setModalShow}
        title={"Pilih Dokumen/Gambar"}
        libraryHandler={libraryHandler}
        handleThumbnail={handleThumbnail}
        setSelectedLibary={setSelectedLibary}
      />
      <SkModal content={modalContent} />
    </>
  );
}

export default CorporateMaterial;
