import React from "react";
function CardAudience({ items }) {
  return (
    <>
      <h5 className="course_front__kurikulum_title">Target Audiens</h5>
      <div className="course_front__target_audience__wrapper">
        <ul className={`${items?.length > 0 ? "mb-0" : "p-0 mb-0" }`}>
          {items?.length > 0 ? (
            items.map((e, i) => <li key={i}>{e}</li>)
          ) : (
            <div>Data Belum Tersedia</div>
          )}
        </ul>
      </div>
    </>
  );
}

export default CardAudience;
