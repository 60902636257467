import React from "react";
import { Card } from "react-bootstrap";
import ReactECharts from "echarts-for-react";
import "../assets/css/Dashboard/companyAnalytics.css";
function StatusPostTest({ data }) {
  function option() {
    return {
      legend: {
        top: "bottom",
      },
      tooltip: {
        trigger: "item",
        formatter: "{a} <br/>{b} : {c} ({d}%)",
      },
      series: [
        {
          name: "Status Post Test",
          type: "pie",
          radius: [50, 100],
          center: ["50%", "50%"],
          roseType: "radius",
          itemStyle: {
            borderRadius: 8,
          },
          data: [
            // { value: 10, name: "Belum ditemukan" },
            { value: data?.chart_post_test?.passed?.nominal, name: "Lulus" },
            { value: data?.chart_post_test?.failed?.nominal, name: "Tidak Lulus" },
          ],
          color: ["#91CC75", "#EE4253"],
        },
      ],
    };
  }
  return (
    <>
      <Card className="card_container height-card__tryout">
        <div className="card_inside__padding ">
          <div className="d-flex justify-content-between border_card">
            <div className="card_title__info mb-1">Status Post Test</div>
          </div>
          <div className="mt-3">
            <ReactECharts option={option()} />
          </div>
        </div>
        {/* <div className="loading_status">
          <div className="loading_status__progress">
            <Spinner animation="border" />
            Sistem sedang
            <br />
            mengolah data
          </div>
        </div> */}
      </Card>
    </>
  );
}

export default StatusPostTest;
