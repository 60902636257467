import { PromiseService } from "../../services/PromiseService";
import { ANALYTICS_USER_DATA_LINK } from "../config/AnalyticsUserConfig";
import {
  //get
  getAnalyticsUserData,
  getAnalyticsUserDataSuccess,
  getAnalyticsUserDataIdSuccess,
  getAnalyticsUserDataFail,
} from "../actions/AnalyticsUserAction";


export const getAnalyticsUserDataThunk = (data) => async (dispatch) => {
  dispatch(getAnalyticsUserData());
  if (data) {
    let url = `${ANALYTICS_USER_DATA_LINK}/${data}`;
    if (data.param) url = `${ANALYTICS_USER_DATA_LINK}${data.param}`;
    try {
      const response = await PromiseService.getData(url);
      if (response.status === 200 && response.data) {
        if (data.param) dispatch(getAnalyticsUserDataSuccess(response.data));
        else dispatch(getAnalyticsUserDataIdSuccess(response.data));
      } else {
        dispatch(getAnalyticsUserDataFail(response));
      }
    } catch (error) {
      dispatch(getAnalyticsUserDataFail(error));
    }
  } else {
    try {
      const response = await PromiseService.getData(ANALYTICS_USER_DATA_LINK);
      if (response.status === 200 && response.data) {
        dispatch(getAnalyticsUserDataSuccess(response.data));
      } else {
        dispatch(getAnalyticsUserDataFail(response));
      }
    } catch (error) {
      dispatch(getAnalyticsUserDataFail(error));
    }
  }
};

