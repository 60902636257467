import React, { useEffect } from "react";
import { Row, Col, Container } from "react-bootstrap";
import TableLearningPath from "../../components/TableLearningPath";
import "../../assets/css/Dashboard/index.css";
import { AuthIsLogin, AuthData } from "../../helper/auth";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";

function LearningPathTable({ showModal }) {
  const history = useHistory();
  useEffect(() => {
    AuthIsLogin(history);
  }, []);
  return (
    <Container fluid>
      <Row className="justify-content-center align-items-center height-inherit">
        <Col xs={12} md={6}>
          <div className="course_create__title mb-2">List Learning Path</div>
        </Col>
        <Col
          xs={12}
          md={6}
          className="mb-4 text-center d-flex justify-content-end h-fit"
        >
          {AuthData()?.role === "instructor" && (
            <Link to="/learning-path/create/" className="add_button py-2">
              + Tambahkan Learning Path
            </Link>
          )}
        </Col>
        <Col xs={12} sm={12} md={12} lg={12}>
          <TableLearningPath showModal={showModal} />
        </Col>
      </Row>
    </Container>
  );
}

export default LearningPathTable;
