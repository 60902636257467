import React, { useState, useEffect } from "react";
import { Card, Accordion, Button, Form, Modal } from "react-bootstrap";
import Swal from "sweetalert2";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import withReactContent from "sweetalert2-react-content";
import { useDispatch, useSelector } from "react-redux";
import { FaEdit } from "react-icons/fa";
import { RiDeleteBin2Line } from "react-icons/ri";
import { convertHours } from "../helper/functions";
import "./../assets/css/Courses/AccordionCourse.css";
import {
  deleteTopicDataThunk,
  getTopicDataThunk,
} from "../redux/thunks/TopicThunks";
import {
  deleteLessonDataThunk,
  getLessonDataThunk,
} from "../redux/thunks/LessonThunks";
import {
  deleteWebinarDataThunk,
  getWebinarDataThunk,
} from "../redux/thunks/WebinarThunks";
import {
  deleteAssignmentDataThunk,
  getAssignmentDataThunk,
} from "../redux/thunks/AssignmentThunks";
import {
  deleteQuizDataThunk,
  getQuizDataThunk,
} from "../redux/thunks/QuizThunks";
import { removeTopicData } from "../redux/actions/TopicActions";
import SkModal from "./common/SkModal";
import AddMateri from "./AddMateri";
import AddTopic from "./AddTopic";
import AddLesson from "./AddLesson";
import AddWebinar from "./AddWebinar";
import AddQuiz from "./AddQuiz";
import AddAssignment from "./AddAssignment";
import { courseSingle } from "../helper/functions";
import { AuthData } from "../helper/auth";

const MySwal = withReactContent(Swal);
function AccordionTopicName({ item, topicsList, fixedTitle, getCourseData }) {
  // const location = useLocation();
  const [detailData, setDetailData] = useState([]);
  const [selected, setSelected] = useState("");
  const [show, setShow] = useState(false);
  const [showAccordion, setShowAccordion] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [isOne, setIsOne] = useState(false);
  const [dataId, setDataId] = useState([]);
  // const { course_id } = useParams();
  const topics = useSelector((state) => state.topics);
  const lessons = useSelector((state) => state.lessons);
  const quizzes = useSelector((state) => state.quizzes);
  const webinars = useSelector((state) => state.webinars);
  const assignments = useSelector((state) => state.assignments);
  // const users = useSelector((state) => state.users);
  const currCourseType = item.course_type;
  const currTopics = topicsList;
  useEffect(() => {}, [currCourseType]);
  const [modalContent, setModalContent] = useState({
    title: "Modal Title",
    show: false,
    componentBody: "",
  });
  // const history = useHistory();
  const dispatch = useDispatch();

  const handleAdd = () => {
    setSelected("topic");
    setDetailData([]);
    setShow(!show);
    setModalShow(!modalShow);
  };
  useEffect(() => {}, [selected]);
  useEffect(() => {
    if (courseSingle(currCourseType)) {
      if (currTopics.length > 0) setIsOne(true);
      else setIsOne(false);
    } else setIsOne(false);
  }, [item]);

  useEffect(() => {
    setDataId(topics.topicDataId);
    if (
      topics.topicDataDelete === "success" ||
      topics.topicDataPost === "success"
    ) {
      getCourseData();
      dispatch(removeTopicData());
    }
  }, [topics]);

  useEffect(() => {
    setDataId(lessons.lessonDataId);
  }, [lessons]);

  useEffect(() => {
    setDataId(quizzes.quizDataId);
  }, [quizzes]);

  useEffect(() => {
    setDataId(webinars.webinarDataId);
  }, [webinars]);

  useEffect(() => {
    setDataId(assignments.assignmentDataId);
  }, [assignments]);

  const handleUpload = () => {
    setModalContent({
      ...modalContent,
      show: true,
      title: "Upload File",
      body: (
        <Form.Group>
          <Form.Label className="fw-600">File</Form.Label>{" "}
          <Form.Group controlId="formFile" className="mb-3">
            <Form.Control type="file" />
          </Form.Group>
        </Form.Group>
      ),
    });
  };

  const handleEdit = (id, title, type) => {
    setSelected(type);
    const data = {
      id,
      title,
    };
    if (type === "topic") dispatch(getTopicDataThunk(id));
    else if (type === "lesson") dispatch(getLessonDataThunk(id));
    else if (type === "webinar") dispatch(getWebinarDataThunk(id));
    else if (type === "quiz") dispatch(getQuizDataThunk(id));
    else if (type === "assignment") dispatch(getAssignmentDataThunk(id));
    setModalShow(true);
    setDetailData(data);
  };

  const handleClose = () => {
    setModalShow(false);
  };

  const handleDelete = (id, type) => {
    MySwal.fire({
      title: `Anda yakin ingin menghapus ${type}?`,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Iya",
      cancelButtonText: "Tidak",
    }).then((value) => {
      if (value.isConfirmed) {
        if (type === "topic") dispatch(deleteTopicDataThunk(id));
        else if (type === "lesson") dispatch(deleteLessonDataThunk(id));
        else if (type === "webinar") dispatch(deleteWebinarDataThunk(id));
        else if (type === "quiz") dispatch(deleteQuizDataThunk(id));
        else if (type === "assignment") dispatch(deleteAssignmentDataThunk(id));
      }
    });
  };

  const handleAddMaterial = (topic_id) => {
    // show modal
    // and user can select material type: lesson, quiz, assignment, webinar
    setModalContent({
      ...modalContent,
      show: true,
      title: "Tambah Materi",
      body: (
        <AddMateri
          topic_id={topic_id}
          setModalContent={setModalContent}
          modalContent={modalContent}
          courseSingle={courseSingle(currCourseType)}
        />
      ),
      topic_id: topic_id,
    });
  };

  return (
    <>
      <div className="course_front__kurikulum_title">List Topik</div>
      <div
        className={`course_front__kurikulum_wrapper__accordion${
          currTopics?.length > 0 ? "" : "p-0"
        }`}
      >
        {currTopics?.length > 0 ? (
          currTopics.map((items, index) => (
            <div key={index}>
              <Accordion className="course_front__kurikulum_wrapper">
                <Card className="mb-0">
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    className="p-0"
                    eventKey="0"
                    onClick={() => {
                      setShowAccordion(index);
                    }}
                  >
                    <Card.Header className="p-3 bg-greylight border-radius__header">
                      <div className="d-flex w-100 justify-content-between align-items-center text-left font-s font-weight-bold text-black text-decoration-none">
                        {items.title}
                        {AuthData()?.role === "instructor" ? (
                          <span>
                            <Button
                              className="mr-2 px-3 text-white"
                              onClick={() =>
                                handleEdit(items.topic_id, items.title, "topic")
                              }
                              variant="info"
                            >
                              <FaEdit className="fill-white" />
                            </Button>
                            <Button
                              className="mr-3 px-3 text-white"
                              onClick={() =>
                                handleDelete(items.topic_id, "topic")
                              }
                              variant="danger"
                            >
                              <RiDeleteBin2Line className="fill-white" />
                            </Button>
                            {showAccordion === index ? (
                              <IoIosArrowDown />
                            ) : (
                              <IoIosArrowUp />
                            )}
                          </span>
                        ) : null}
                      </div>
                    </Card.Header>
                  </Accordion.Toggle>

                  {items?.materials?.length > 0 ? (
                    <>
                      {items.materials.map((detail, key) => (
                        <Accordion.Collapse key={key} eventKey="0">
                          <Card.Body className="p-0">
                            <div className="accordion-course-main-div d-flex justify-content-between cursor-pointer">
                              <div className="d-flex">
                                <div className="blue-background mr-3 rounded box text-center font-weight-bold white-font">
                                  {key + 1}
                                </div>
                                <div className="course_front__material_title">
                                  {detail.title}
                                </div>
                              </div>
                              <div className="d-flex flex-row">
                                <div className="mr-3">
                                  {detail?.duration === 0
                                    ? "00:00"
                                    : convertHours(detail?.duration, "preview")}
                                </div>
                                {AuthData()?.role === "instructor" ? (
                                  <span>
                                    <Button
                                      className="mr-1 px-1 text-white"
                                      variant="info"
                                      onClick={() => {
                                        let type = detail.type.slice(0, -1);
                                        if (detail.type === "quizzes")
                                          type = "quiz";
                                        handleEdit(
                                          detail[`${type}_id`],
                                          detail.title,
                                          type
                                        );
                                      }}
                                    >
                                      <FaEdit className="fill-white" />
                                    </Button>
                                    <Button
                                      className="mr-1 px-1 text-white"
                                      variant="danger"
                                      onClick={() => {
                                        let type = detail.type.slice(0, -1);
                                        if (detail.type === "quizzes")
                                          type = "quiz";
                                        handleDelete(
                                          detail[`${type}_id`],
                                          type
                                        );
                                      }}
                                    >
                                      <RiDeleteBin2Line className="fill-white" />
                                    </Button>
                                  </span>
                                ) : null}
                              </div>
                            </div>
                          </Card.Body>
                        </Accordion.Collapse>
                      ))}
                      {/* <Accordion.Collapse eventKey="0">
                        <Card.Body>
                          <div className="accordion-course-main-div d-flex justify-content-between cursor-pointer">
                            <Button
                              onClick={() => handleAddMaterial(items.topic_id)}
                            >
                              + Tambah Materi
                            </Button>
                          </div>
                        </Card.Body>
                      </Accordion.Collapse> */}
                    </>
                  ) : (
                    <>
                      {/* <Accordion.Collapse eventKey="0">
                      <Card.Body>
                        <div className="accordion-course-main-div d-flex justify-content-between cursor-pointer">
                          <Button
                            onClick={() => handleAddMaterial(items.topic_id)}
                          >
                            + Tambah Materi
                          </Button>
                        </div>
                      </Card.Body>
                    </Accordion.Collapse> */}
                    </>
                  )}
                  {AuthData()?.role === "instructor" ? (
                    <Accordion.Collapse eventKey="0">
                      <Card.Body className="p-0">
                        <div className="accordion-course-main-div d-flex justify-content-between cursor-pointer">
                          {courseSingle(currCourseType) ? (
                            currTopics.length >= 1 &&
                            currTopics[0].materials.length < 1 && (
                              <Button
                                onClick={() =>
                                  handleAddMaterial(items.topic_id)
                                }
                              >
                                + Tambah Materi
                              </Button>
                            )
                          ) : (
                            <Button
                              onClick={() => handleAddMaterial(items.topic_id)}
                            >
                              + Tambah Materi
                            </Button>
                          )}
                        </div>
                      </Card.Body>
                    </Accordion.Collapse>
                  ) : null}
                </Card>
              </Accordion>
            </div>
          ))
        ) : (
          <Card className="padding_card mb-0 topic_list__name">
            Data Belum Tersedia
          </Card>
        )}
      </div>
      <div className="text-right mb-3 mt-3">
        {modalShow
          ? null
          : !isOne &&
            AuthData()?.role === "instructor" && (
              <>
                <Button className="mr-3" onClick={handleUpload}>
                  Upload File
                </Button>
                <Button onClick={handleAdd}>+ Tambahkan Topik</Button>
              </>
            )}
      </div>
      <Modal
        show={modalShow}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        style={{ overflow: "auto" }}
      >
        <Modal.Header className="p-4">
          <Modal.Title id="contained-modal-title-vcenter">
            Edit Data
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-2">
          {selected === "topic" ? (
            <AddTopic
              id={detailData.id}
              defaultItems={dataId}
              handleCancel={handleClose}
              fixedTitle={courseSingle(currCourseType) ? fixedTitle : null}
            />
          ) : null}
          {selected === "lesson" ? (
            <AddLesson
              id={detailData.id}
              defaultItems={dataId}
              handleCancel={handleClose}
            />
          ) : null}
          {selected === "webinar" ? (
            <AddWebinar
              id={detailData.id}
              defaultItems={dataId}
              modalShow={modalShow}
              setModalShow={setModalShow}
              handleCancel={handleClose}
              fixedTitle={fixedTitle}
            />
          ) : null}
          {selected === "quiz" ? (
            <AddQuiz
              id={detailData.id}
              defaultItems={dataId}
              handleCancel={handleClose}
            />
          ) : null}
          {selected === "assignment" ? (
            <AddAssignment
              id={detailData.id}
              defaultItems={dataId}
              handleCancel={handleClose}
            />
          ) : null}
        </Modal.Body>
      </Modal>
      <SkModal content={modalContent} />
    </>
  );
}

export default AccordionTopicName;
