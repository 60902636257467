import ReactECharts from "echarts-for-react";
import React from "react";
import { Image } from "react-bootstrap";
import personOutline from "./../../assets/images/person-outline.svg";

export default function CompletionRate(props) {
  const { data, type, handleShow } = props;
  const colorPalete = ["#D6E2F5", "#306CCC"];
  let uncompletedMember =
    parseInt(data?.total_member) - parseInt(data?.completed_member);
  let completedMember = parseInt(data?.completed_member);
  const optionLiveClass = {
    tooltip: {
      show: false,
      trigger: "item",
    },
    legend: {
      show: false,
    },
    series: [
      {
        type: "pie",
        left: "-15%",
        radius: ["45%", "80%"],
        avoidLabelOverlap: false,
        label: {
          show: true,
          position: "center",
          formatter: `{a|${parseInt(data?.total_member)}\n} {b|Anggota}`, // Use '{a|text}' to display the static text
          rich: {
            a: {
              fontSize: 16, // Set font size as needed
              fontWeight: "700", // Set font weight as needed
              color: "#4F4F4F",
              fontFamily: "Poppins",
            },
            b: {
              fontSize: 10, // Set font size as needed
              fontWeight: "400", // Set font weight as needed
              color: "#4F4F4F",
              fontFamily: "Poppins",
            },
          },
        },
        labelLine: {
          show: false,
        },
        data: [
          { value: uncompletedMember, name: "Tidak Selesai" },
          { value: completedMember, name: "Selesai" },
        ],
        color: ["#306CCC", "#55AA73"],
      },
    ],
  };

  const optionCompletion = {
    tooltip: {
      show: false,
      trigger: "item",
    },
    legend: {
      show: false,
    },
    series: [
      {
        type: "pie",
        left: "0%",
        radius: ["45%", "80%"],
        avoidLabelOverlap: false,
        label: {
          show: true,
          position: "center",
          formatter: `{a|${parseInt(data?.completion_rate)}}%`, // Use '{a|text}' to display the static text
          rich: {
            a: {
              fontSize: 18, // Set font size as needed
              fontWeight: "700", // Set font weight as needed
              color: "#4F4F4F",
              fontFamily: "Poppins",
            },
          },
        },
        labelLine: {
          show: false,
        },
        data: [
          { value: data?.completed, name: "Selesai" },
          { value: data?.total_enrolled, name: "Diikuti" },
        ],
        color: colorPalete,
      },
    ],
  };
  return (
    <div
      className={`card-analitik _shadow ${
        type === "overview" ? "_overview" : ""
      }`}
    >
      <div className="total-anggota_title">
        {type === "overview"
          ? "Completion Rate"
          : "Completion Rate + Jumlah Anggota (All Student)"}
      </div>
      <div className="d-flex">
        {type === "overview" ? (
          <>
            <div className="h-100 d-flex justify-content-between flex-column">
              <div className="d-flex align-items-center justify-content-center _margin-graph">
                <div className="col-6 px-0">
                  <span className="sub-title__card-completion-rate">
                    Completion Rate menunjukkan perbandingan antara pelatihan
                    yang sudah diselesaikan dan diikuti
                  </span>
                </div>
                <div className="col-6 px-0">
                  <ReactECharts
                    style={{ height: "190px", width: "190px" }}
                    option={optionCompletion}
                    theme={"light"}
                  />
                </div>
              </div>
              <div className="text-center">
                <button
                  className="btn btn-outline-primary btn__add-card-inside-padding"
                  onClick={handleShow}
                >
                  + Buat Penugasan
                </button>
              </div>
            </div>
          </>
        ) : (
          <>
            <ReactECharts
              style={{ height: "150px", width: "150px" }}
              option={optionLiveClass}
              theme={"light"}
            />
            <div
              className="d-flex legend-wrap"
              style={{ gap: "10px", width: "fit-content" }}
            >
              <div className="type-legend blue">
                <Image src={personOutline} />
              </div>
              <div>
                <div className="webinar-count_total">
                  {data?.completed_member} Selesai
                </div>
                <div className="type-course_title">
                  {data?.completion_rate.toFixed(0)}% sudah selesai
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
