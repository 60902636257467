import React, { useState, useRef, useEffect } from "react";
import { Button, Form, Row, Col, Card } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  postCoursesDataThunk,
  putCoursesDataThunk,
} from "../redux/thunks/CoursesThunks";
import { getUsersDataThunk } from "../redux/thunks/UsersThunks";
import { getInstructorsLiveSearchDataThunk } from "../redux/thunks/InstructorsThunks";
import { getSkillTypesDataThunk } from "../redux/thunks/SkillTypesThunks";
import { getCategoriesDataThunk } from "../redux/thunks/CategoriesThunks";
import { getSubCategoriesDataThunk } from "../redux/thunks/SubCategoriesThunks";
import { getLearningLevelsDataThunk } from "../redux/thunks/LearningLevelsThunks";
import { getPositionsDataThunk } from "../redux/thunks/PositionsThunks";
import { getSectorsDataThunk } from "../redux/thunks/SectorsThunks";
import { getIndustriesDataThunk } from "../redux/thunks/IndustriesThunks";
import { getSubIndustriesDataThunk } from "../redux/thunks/SubIndustriesThunks";
import { getProductsDataThunk } from "../redux/thunks/ProductsThunks";
import { getSchemesDataThunk } from "../redux/thunks/SchemesThunks";
import { liveSearchDiscussTopicsDataThunk } from "../redux/thunks/DiscussTopicsThunks";
import { getCourseTypesDataThunk } from "../redux/thunks/CourseTypesThunks";
import { getDeliveryMethodDataThunk } from "../redux/thunks/DeliveryMethodThunks";
// import { getCompaniesDataThunk } from "../redux/thunks/CompaniesThunks";
import {
  // getCoursesDataThunk,
  getCourseLiveSearchDataThunk,
} from "../redux/thunks/CoursesThunks";
import {
  removeCourseDataId,
  removeCourseData,
} from "../redux/actions/CoursesActions";
import { removeLearningPathData } from "../redux/actions/LearningPathActions";
import { removeInstructorData } from "../redux/actions/InstructorsActions";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import "../assets/css/Dashboard/index.css";
import DropdownMultiselect from "react-multiselect-dropdown-bootstrap";
import MultipleValueTextInput from "react-multivalue-text-input";
import styled from "styled-components";
import { SkValidate } from "./common/SkValidate";
import { AuthData } from "../helper/auth";
import {
  CreateOption,
  CreateResult,
  CreateSelected,
} from "../helper/functions";
import { SELECT_DEFAULT_STYLES } from "../helper/cssStyledComponent";
import "../assets/css/Courses/AddCourses.css";
import { removeDiscussLiveTopics } from "../redux/actions/DiscussTopicsActions";
import MediaLibraryModal from "./common/MediaLibraryModal";
import {
  certificateTemplateInput,
  coord_certificate,
  template_name,
} from "../helper/constant";
import { loadingFinish, loadingStart } from "../redux/reducers/CommonReducers";
import { CertificateService } from "../http/CertificateHttp";

const MySwal = withReactContent(Swal);

function AddCourse({ id, defaultItems, handleCancel, updateData, companyId }) {
  const refTest = useRef(null);
  const [isThumbnailName, setIsThumbnailName] = useState(null);
  const [isHandoutName, setIsHandoutName] = useState(null);
  const [data, setData] = useState({ ...defaultData });
  const [trigger, setTrigger] = useState(false);
  const [render, setRender] = useState(id ? false : true);
  const [loaded, setLoaded] = useState(id ? false : true);
  const [dataState, setDataState] = useState("");
  const [product, setProduct] = useState(null);
  const [scheme, setScheme] = useState(null);
  const [deliveryMethod, setDeliveryMethod] = useState(null);
  const [skillTypeId, setSkillTypeId] = useState([""]);
  const [categoryId, setCategoryId] = useState([""]);
  const [subCategoryId, setSubCategoryId] = useState([""]);
  const [learningLevelId, setLearningLevelId] = useState([""]);
  const [positionId, setPositionId] = useState([""]);
  const [sectorId, setSectorId] = useState([""]);
  const [industryId, setIndustryId] = useState([""]);
  const [subIndustryId, setSubIndustryId] = useState([""]);
  const [payloadCertificate, setPayloadCertificate] = useState();
  const [categoryTreeTotal, setCategoryTreeTotal] = useState(1);
  const [pathwayTotal, setPathwayTotal] = useState(1);
  const [subIndustryAuto, setSubIndustryAuto] = useState(true);

  const [searchInstructor, setSearchInstructor] = useState("");
  const [searchDT, setSearchDT] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const [selectedLibary, setSelectedLibary] = useState();
  const dispatch = useDispatch();
  const courses = useSelector((state) => state.courses);
  const course_types = useSelector((state) => state.course_types);
  const instructors = useSelector((state) => state.instructors);
  const users = useSelector((state) => state.users);
  const skill_types = useSelector((state) => state.skill_types);
  const categories = useSelector((state) => state.categories);
  const learning_levels = useSelector((state) => state.learning_levels);
  const positions = useSelector((state) => state.positions);
  const sectors = useSelector((state) => state.sectors);
  const industries = useSelector((state) => state.industries);
  const sub_industries = useSelector((state) => state.sub_industries);
  const products = useSelector((state) => state.products);
  const schemes = useSelector((state) => state.schemes);
  const sub_categories = useSelector((state) => state.sub_categories);
  const discussion_topics = useSelector((state) => state.discussion_topics);
  const delivery_method = useSelector((state) => state.delivery_method);
  // const companies = useSelector((state) => state.companies);
  const courseTypesData = course_types.courseTypesData;
  const instructorsLiveSearchData = instructors.instructorsLiveSearchData;
  const discussTopicsLiveSearchData =
    discussion_topics.discussTopicsLiveSearchData;
  // const usersData = users.usersData;
  const courseLiveSearchData = courses.courseLiveSearchData;
  // const companiesData = companies.companiesData;

  useEffect(() => {
    if (data.thumbnail) {
      let tmp = data.thumbnail.split("/");
      if (tmp.length > 0) {
        let getFileName = tmp[tmp.length - 1];
        setIsThumbnailName(getFileName);
      }
    }

    if (data.handouts?.length > 0) {
      let tmp = data.handouts[0].split("/");
      if (tmp.length > 0) {
        let getFileName = tmp[tmp.length - 1];
        setIsHandoutName(getFileName);
      }
    }
  }, [data]);

  const handleChange = (e) => {
    const newData = { ...data };
    newData[e.target.id] =
      e.target.id === "price" || e.target.id === "points"
        ? parseInt(e.target.value)
        : e.target.value;
    setData(newData);
  };

  const handleTemplateCertificate = (e) => {
    let tmp = JSON.parse(e.target.value);
    let template_coord = { ...coord_certificate[tmp.coord_id - 1].coord };
    let payload = { ...template_coord };
    payload.template_url = tmp.url;
    payload.color_code = tmp.color_code;
    // console.log(payload);
    setPayloadCertificate(payload);
  };

  const handleChangeMultiSelect = (type, newValue) => {
    const newData = { ...data };
    newData[type] = newValue;
    setData(newData);
  };
  const handleChangeMultiInput = (type, newValue, allItems) => {
    const newData = { ...data };
    newData[type].push(newValue);
    setData(newData);
  };
  const handleRemoveMultiInput = (type, newValue) => {
    const newData = { ...data };
    newData[type] = newData[type].filter((e) => e !== newValue);
    setData(newData);
  };

  const handleThumbnail = () => {
    const newData = { ...data };
    if (dataState === "handouts") {
      newData[dataState] =
        newData[dataState] != ""
          ? [`${newData[dataState]}`, `${selectedLibary}`]
          : [selectedLibary];
    } else newData[dataState] = selectedLibary;
    setData(newData);
    setModalShow(false);
    setDataState("");
  };

  const liveSearch = (e) => {
    setSearchValue(e.target.value);
  };
  const liveSearchInstructor = (e) => {
    setSearchInstructor(e.target.value);
  };
  const liveSearchDT = (e) => {
    setSearchDT(e.target.value);
  };

  const onKeyDownHanlder = (e) => {
    if (e.keyCode == 13) {
      if (searchValue)
        dispatch(
          getCourseLiveSearchDataThunk(`?size=10&keyword=${searchValue}`)
        );
      else dispatch(removeCourseData());
    }
  };

  const onKeyDownHanlderInstructor = (e) => {
    if (e.keyCode == 13) {
      if (searchInstructor)
        dispatch(
          getInstructorsLiveSearchDataThunk(`?keyword=${searchInstructor}`)
        );
      else dispatch(removeInstructorData());
    }
  };

  const onKeyDownHanlderDT = (e) => {
    if (e.keyCode == 13) {
      if (searchDT)
        dispatch(liveSearchDiscussTopicsDataThunk(`?keyword=${searchDT}`));
      else dispatch(removeDiscussLiveTopics());
    }
  };

  useEffect(() => {
    if (data.industry_id) {
      dispatch(getSubIndustriesDataThunk());
    }
  }, [data?.industry_id]);

  useEffect(() => {
    const subData = sub_categories?.subCategoriesData?.items;
    if (defaultItems?.sub_category_id && subData?.length > 0) {
      const newSkillTypeId = [];
      const newCategoryId = [];
      const newSubCategoryId = [];
      const tempSubCategoryId = defaultItems.sub_category_id.split(";");
      setCategoryTreeTotal(tempSubCategoryId.length);
      for (let i = 0; i < tempSubCategoryId.length; i++) {
        const res = subData.find(
          (x) => x.sub_category_id === tempSubCategoryId[i]
        );
        if (res) {
          newSkillTypeId.push(res.skill_type_id);
          newCategoryId.push(res.category_id);
          newSubCategoryId.push(res.sub_category_id);
        }
      }
      setSkillTypeId(newSkillTypeId);
      setCategoryId(newCategoryId);
      setSubCategoryId(newSubCategoryId);
    }
  }, [defaultItems, sub_categories]);

  useEffect(() => {
    const psp = defaultItems?.pathway_specifications?.length;
    const psa = defaultItems?.pathway_positions?.length;
    const pll = defaultItems?.pathway_learning_levels?.length;

    const pathwayLength =
      psp > psa ? (psp > pll ? psp : pll) : psa > pll ? psa : pll;
    if (pathwayLength > 0) {
      setPathwayTotal(pathwayLength);
      const newLearningLevelId = [];
      const newPositionId = [];
      const newSubIndustryId = [];
      const newIndustryId = [];
      const newSectorId = [];
      for (let i = 0; i < pathwayLength; i++) {
        if (defaultItems.pathway_specifications[i]) {
          newSectorId.push(defaultItems.pathway_specifications[i]?.sector_id);
          newIndustryId.push(
            defaultItems.pathway_specifications[i]?.industry_id
          );
          newSubIndustryId.push(
            defaultItems.pathway_specifications[i]?.sub_industry_id
          );
        }
        if (defaultItems.pathway_positions[i]) {
          newPositionId.push(defaultItems.pathway_positions[i]?.position_id);
        }
        if (defaultItems.pathway_learning_levels[i]) {
          newLearningLevelId.push(
            defaultItems.pathway_learning_levels[i]?.learning_level_id
          );
        }
      }
      // console.log("defaultItems.pathway_specifications[i]", newSubIndustryId);
      setLearningLevelId(newLearningLevelId);
      setPositionId(newPositionId);
      setSectorId(newSectorId);
      setIndustryId(newIndustryId);
      setSubIndustryId(newSubIndustryId);
    }
  }, [defaultItems]);

  useEffect(() => {
    if (products?.productsData?.items?.length > 0) {
      setProduct(products.productsData.items);
    }
  }, [products]);

  useEffect(() => {
    if (schemes?.schemesData?.items?.length > 0) {
      setScheme(schemes.schemesData.items);
    }
  }, [schemes]);

  useEffect(() => {
    if (delivery_method?.deliveryMethodData?.items?.length > 0) {
      setDeliveryMethod(delivery_method.deliveryMethodData.items);
    }
  }, [delivery_method]);

  useEffect(() => {
    setSubIndustryAuto(true);
  }, [industryId]);

  function removeReduxState() {
    dispatch(removeInstructorData());
    dispatch(removeLearningPathData());
    if (payloadCertificate === undefined) {
      dispatch(removeCourseDataId());
    }
  }

  function selectCourseHandler(value) {
    const newData = { ...data };
    let userExists = -1;
    if (newData.prerequisites?.length > 0) {
      userExists = newData.prerequisites
        .map(function (e) {
          return e.course_id;
        })
        .indexOf(value.course_id);
    }
    if (userExists === -1) {
      newData.prerequisites.push({
        course_id: value.course_id,
        course: value.title,
      });
    } else {
      newData.prerequisites.splice(userExists, 1);
    }
    setData(newData);
  }

  function removeCourseHandler(index) {
    const newData = { ...data };
    newData.prerequisites.splice(index, 1);
    setData(newData);
  }
  function selectInstructorHandler(value) {
    const newData = { ...data };
    let instructorExists = -1;
    if (newData.instructors?.length > 0) {
      instructorExists = newData.instructors
        .map(function (e) {
          return e.user_id;
        })
        .indexOf(value.user_id);
    }
    if (instructorExists === -1) {
      newData.instructors.push({
        user_id: value.user_id,
        name: value.name,
      });
    } else {
      newData.instructors.splice(instructorExists, 1);
    }
    setData(newData);
  }
  function removeInstructorHandler(index) {
    const newData = { ...data };
    newData.instructors.splice(index, 1);
    setData(newData);
  }

  function selectDTHandler(value) {
    const newData = { ...data };
    let DTExists = -1;
    if (newData.discussion_topics?.length > 0) {
      DTExists = newData.discussion_topics
        .map(function (e) {
          return e.discussion_topic_id;
        })
        .indexOf(value.discussion_topic_id);
    }
    if (DTExists === -1) {
      newData.discussion_topics.push({
        discussion_topic_id: value.discussion_topic_id,
        name: value.title,
      });
    } else {
      newData.discussion_topics.splice(DTExists, 1);
    }
    setData(newData);
  }
  function removeDTHandler(index) {
    const newData = { ...data };
    newData.discussion_topics.splice(index, 1);
    setData(newData);
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    const isValid = SkValidate(form, constraints);
    if (isValid) {
      setTrigger(true);
      const payload = { ...data };
      //prerequisites
      const newPrerequisites = payload.prerequisites.map(function (item) {
        return { course_id: item.course_id };
      });
      payload.prerequisites = newPrerequisites;
      //discussion_topics
      const newDiscussTopics = payload.discussion_topics?.map(function (item) {
        return item["discussion_topic_id"];
      });
      payload.discussion_topics = newDiscussTopics;
      payload.pathway_positions = positionId;
      payload.sub_category_id = subCategoryId;
      payload.pathway_learning_levels = learningLevelId;
      payload.pathway_specifications = subIndustryId;

      if (id) {
        if (
          payload.delivery_methods?.length > 0 &&
          payload.delivery_methods[0]?.delivery_method_id
        ) {
          delete payload["delivery_methods"];
        }
        if (payload.schemes?.length > 0 && payload.schemes[0]?.scheme_id) {
          delete payload["schemes"];
        }
        if (payload.products?.length > 0 && payload.products[0]?.product_id) {
          delete payload["products"];
        }
        const newData = {
          body: payload,
          id: id,
        };
        dispatch(putCoursesDataThunk(newData));
      } else {
        const newData = { ...payload };
        if (AuthData().role === "admin")
          newData.company_id = users.usersDataId?.company_id;

        // console.log("payload", payload);
        dispatch(postCoursesDataThunk(payload));
        // console.log(payload, "payload");
      }
    }
  };
  // const handleClose = () => {
  //   setModalShow(false);
  //   setSelectedLibary("");
  // };
  const handleShow = (data) => {
    setModalShow(true);
    setDataState(data);
  };
  const libraryHandler = (newValue) => {
    setSelectedLibary(newValue);
  };

  useEffect(() => {
    dispatch(getUsersDataThunk());
    // dispatch(getInstructorsDataThunk());
    // dispatch(getDiscussTopicsDataThunk());
    // dispatch(getLearningPathDataThunk());
    // dispatch(getIndustriesDataThunk());
    dispatch(getProductsDataThunk({ param: "?size=20" }));
    dispatch(getSchemesDataThunk({ param: "?size=30" }));
    dispatch(getDeliveryMethodDataThunk({ param: "?size=20" }));
    // dispatch(getSubCategoriesDataThunk());
    // dispatch(getCoursesDataThunk());
    // dispatch(getCompaniesDataThunk());
  }, []);

  useEffect(() => {
    if (courses?.courseDataId) setLoaded(true);
  }, [courses?.courseDataId]);

  useEffect(() => {
    if (data?.title && data?.title !== "") setRender(true);
    if (data?.handouts?.length > 0 && !render) {
      const newData = { ...data };
      newData.handouts = [];
      data.handouts.map((item) => {
        newData.handouts.push(item.url);
      });
      setData(newData);
    }
  }, [data]);

  useEffect(() => {
    let newDefaultData = { ...defaultData };
    if (defaultItems) {
      if (defaultItems.course_id) {
        Object.keys(newDefaultData).forEach(function (key) {
          if (newDefaultData[key] == null || newDefaultData[key] == 0) {
            newDefaultData[key] = defaultItems[key];
          }
        });
        setData(newDefaultData);
      }
    }
  }, [defaultItems]);

  useEffect(() => {
    if (courses.courseDataPost === "error" && trigger) {
      setTrigger(false);
      displayPopup(false, "Terjadi masalah saat submit data", "Ok");
    }
    if (courses.courseDataPut === "error" && trigger) {
      setTrigger(false);
      displayPopup(false, "Terjadi masalah saat edit data", "Ok");
    }
    if (courses.courseDataPut === "success" && trigger) {
      displayPopup(false, "Sukses edit pelatihan", "Ok");
      setTrigger(false);
      setData({ ...defaultData });
      updateData(1);
      handleCancel();
      removeReduxState();
    }
    if (courses.courseDataPost.code === 201 && trigger) {
      if (payloadCertificate === undefined) {
        displayPopup(false, "Sukses membuat pelatihan", "Ok");
      } else {
        autoGenerateTemplate(
          payloadCertificate,
          courses.courseDataPost?.data?.course_id
        );
      }
      defaultData = {
        // audiences: [],
        categories: [],
        course_type_id: "",
        description: "",
        handouts: [],
        instructors: [],
        learning_paths: [],
        level: "",
        objectives: [],
        points: 0,
        discussion_topics: [],
        prerequisites: [],
        // price: 0,
        requirements: [],
        skills_covered: [],
        summary: "",
        thumbnail: "",
        title: "",
        products: [],
        schemes: [],
        delivery_methods: [],
        sub_category_id: "",
        pathway_learning_levels: "",
        pathway_positions: "",
        pathway_specifications: "",
      };
      setData({ ...defaultData });
      setSearchValue("");
      setSearchInstructor("");
      setSearchDT("");
      if (payloadCertificate === undefined) {
        removeReduxState();
        dispatch(removeCourseData());
      }
      // history.push("/dashboard/table/course/");
    }
  }, [courses]);

  function addCategoryTreeHandler() {
    setCategoryTreeTotal(categoryTreeTotal + 1);
    setSkillTypeId([...skillTypeId, ""]);
    setCategoryId([...categoryId, ""]);
    setSubCategoryId([...subCategoryId, ""]);
  }

  function addPathwayHandler() {
    setPathwayTotal(pathwayTotal + 1);
    setLearningLevelId([...learningLevelId, ""]);
    setPositionId([...positionId, ""]);
    setSectorId([...sectorId, ""]);
    setIndustryId([...industryId, ""]);
    setSubIndustryId([...subIndustryId, ""]);
  }

  function removeCategoryTreeHandler(i) {
    setCategoryTreeTotal(categoryTreeTotal - 1);
    const newSkillTypeId = [...skillTypeId];
    const newCategoryId = [...categoryId];
    const newSubCategoryId = [...subCategoryId];
    newSkillTypeId.splice(i, 1);
    newCategoryId.splice(i, 1);
    newSubCategoryId.splice(i, 1);
    setSkillTypeId(newSkillTypeId);
    setCategoryId(newCategoryId);
    setSubCategoryId(newSubCategoryId);
  }

  // function removePathwayHandler(i) {
  //   setPathwayTotal(pathwayTotal - 1);

  //   const newLearningLevelId = [...learningLevelId];
  //   const newPositionId = [...positionId];
  //   const newSectorId = [...sectorId];
  //   const newIndustryId = [...industryId];
  //   const newSubIndustryId = [...subIndustryId];
  //   newLearningLevelId.splice(i, 1);
  //   newPositionId.splice(i, 1);
  //   newSectorId.splice(i, 1);
  //   newIndustryId.splice(i, 1);
  //   newSubIndustryId.splice(i, 1);
  //   setLearningLevelId(newLearningLevelId);
  //   setPositionId(newPositionId);
  //   setSectorId(newSectorId);
  //   setIndustryId(newIndustryId);
  //   setSubIndustryId(newSubIndustryId);
  // }

  function handleChangeCT(e, index) {
    let newData = [];
    switch (e.target.id.split("_")[0]) {
      case "skillTypeId":
        newData = [...skillTypeId];
        newData.splice(index, 1, e.target.value);
        setSkillTypeId(newData);
        break;
      case "categoryId":
        newData = [...categoryId];
        newData.splice(index, 1, e.target.value);
        setCategoryId(newData);
        break;
      case "subCategoryId":
        newData = [...subCategoryId];
        newData.splice(index, 1, e.target.value);
        setSubCategoryId(newData);
        break;
      case "learningLevelId":
        newData = [...learningLevelId];
        newData.splice(index, 1, e.target.value);
        setLearningLevelId(newData);
        break;
      case "positionId":
        newData = [...positionId];
        newData.splice(index, 1, e.target.value);
        setPositionId(newData);
        break;
      case "sectorId":
        newData = [...sectorId];
        newData.splice(index, 1, e.target.value);
        setSectorId(newData);
        break;
      case "industryId":
        newData = [...industryId];
        newData.splice(index, 1, e.target.value);
        setIndustryId(newData);
        break;
      case "subIndustryId":
        newData = [...subIndustryId];
        newData.splice(index, 1, e.target.value);
        setSubIndustryId(newData);
        break;
      default:
        newData = [];
        break;
    }
  }

  const autoGenerateTemplate = async (payload, id) => {
    loadingStart();
    let tmp = { ...payload };
    tmp.course_id = id;
    try {
      const response = await CertificateService.postCertificateTemplate(tmp);
      if (response) {
        removeReduxState();
        displayPopup(false, "Sukses membuat pelatihan", "Ok");
      }
    } catch (error) {
      displayPopup(false, "Terjadi masalah saat submit data", "Ok");
    }
    loadingFinish();
  };

  function handleSubIndustry(indexCT) {
    const subIndustryItem = sub_industries?.subIndustriesData?.items;
    if (subIndustryItem?.length > 0 && subIndustryAuto) {
      for (let i = 0; i < subIndustryItem.length; i++) {
        if (industryId[indexCT] === subIndustryItem[i].industry_id) {
          const newId = {
            target: {
              value: subIndustryItem[i].sub_industry_id,
              id: "subIndustryId_",
            },
          };
          setSubIndustryAuto(false);
          handleChangeCT(newId, indexCT);
          break;
        }
      }
    }
  }

  // function uploadData() {
  //   if (CheckIfEmpty(data)) {
  //     displayPopup(false, "Mohon diisi semua", "Ok");
  //   } else {
  //     setTrigger(true);
  //     if (id) {
  //       let req = {};
  //       req.body = data;
  //       req.id = id;
  //       dispatch(putCoursesData(req));
  //     } else dispatch(postCoursesData(data));
  //     console.log(data);
  //   }
  // }

  function displayPopup(cancel, title, confirm) {
    MySwal.fire({
      title: title,
      showCancelButton: cancel,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: confirm,
    }).then((value) => {});
  }

  // console.log(discussTopicsLiveSearchData, "discussTopicsLiveSearchData");

  /**
   * @function to get current user role
   * @param nothing
   * @returns string
   */
  const getCurrentRole = () => {
    let currRole = users.usersDataId?.role;
    return currRole;
  };

  const handleGetData = (e, type) => {
    let callAPI = false;
    switch (type) {
      case "skill_type":
        callAPI = true;
        if (skill_types?.skillTypesData === null) {
          dispatch(getSkillTypesDataThunk());
        }
        break;
      case "learning_level":
        callAPI = true;
        if (learning_levels?.learningLevelsData === null) {
          dispatch(getLearningLevelsDataThunk());
        }
        break;
      case "position":
        callAPI = true;
        if (positions?.positionsData === null) {
          dispatch(getPositionsDataThunk());
        }
        break;
      case "sector":
        callAPI = true;
        if (sectors?.sectorsData === null) {
          dispatch(getSectorsDataThunk());
        }
        break;
      case "industry":
        callAPI = true;
        if (industries?.industriesData === null) {
          dispatch(getIndustriesDataThunk());
        }
        break;
      case "sub_industry":
        callAPI = true;
        if (sub_industries?.subIndustriesData === null) {
          dispatch(getSubIndustriesDataThunk());
        }
        break;
      case "course_type":
        callAPI = true;
        if (courseTypesData?.length === 0) {
          dispatch(getCourseTypesDataThunk());
        }
        break;
      case "kategori":
        callAPI = true;
        if (categories?.categoriesData === null) {
          dispatch(getCategoriesDataThunk());
        }
        break;
      case "sub_kategori":
        callAPI = true;
        if (sub_categories?.subCategoriesData === null) {
          dispatch(getSubCategoriesDataThunk());
        }
        break;
      default:
        callAPI = false;
        break;
    }
  };

  // useEffect(() => {
  //   console.log(data?.course_type_id, template_name);
  // }, [data, template_name]);
  return (
    <StyledWrapperMain>
      <Card className="card_container mb-5">
        <div className="card_inside__padding ">
          {render && loaded ? (
            <Form
              onSubmit={handleSubmit}
              // onKeyDown={(e) => {
              //   e.key === "Enter" && e.preventDefault();
              // }}
            >
              {/* title */}
              <Form.Group>
                <Form.Label className="title_label__input"> Judul* </Form.Label>
                <Form.Control
                  onChange={handleChange}
                  className="mb-3"
                  type="text"
                  id="title"
                  name="title"
                  value={data.title}
                  placeholder="Masukkan Judul Pelatihan"
                />
                <div className="messages"></div>
              </Form.Group>

              <Row>
                <Col sm={12} md={12}>
                  {/* type */}
                  <Form.Group>
                    <Form.Label className="title_label__input">
                      Tipe Pelatihan*
                    </Form.Label>
                    <Form.Control
                      required
                      as="select"
                      type="select"
                      name="course_type"
                      id="course_type_id"
                      value={data.course_type_id}
                      onChange={handleChange}
                      disabled={id}
                      onClick={(e) => handleGetData(e, "course_type")}
                    >
                      <option hidden value="" key="blankChoice">
                        Pilih Tipe Pelatihan...
                      </option>
                      {courseTypesData.items
                        ? courseTypesData.items.map((item, index) => {
                            if (AuthData().role === "admin") {
                              if (item.title.toLowerCase() === "pelatihan") {
                                return (
                                  <option key={index} value={item.type_id}>
                                    {item.title}
                                  </option>
                                );
                              }
                            } else {
                              return (
                                <option key={index} value={item.type_id}>
                                  {item.title}
                                </option>
                              );
                            }
                          })
                        : null}
                    </Form.Control>

                    <div className="messages"></div>
                  </Form.Group>
                  {certificateTemplateInput.includes(data.course_type_id) && (
                    <Form.Group>
                      <Form.Label className="title_label__input">
                        Template Sertifikasi
                      </Form.Label>
                      <Form.Control
                        required
                        as="select"
                        type="select"
                        name="certificate_template"
                        id="certificate_template"
                        onChange={handleTemplateCertificate}
                      >
                        <option hidden value="" key="blankChoice">
                          Pilih Template Sertifikat...
                        </option>
                        {/* {template_name?.filter(e => e.id === data?.course_type_id)} */}
                        {template_name
                          ?.filter((e) => e.id === data?.course_type_id)[0]
                          .list.map((item, index) => {
                            return (
                              <option key={index} value={JSON.stringify(item)}>
                                {item.name}
                              </option>
                            );
                          })}
                      </Form.Control>
                    </Form.Group>
                  )}
                </Col>

                {/* <Col sm={12} md={6}> */}
                {/* Level */}
                {/* <Form.Group>
                    <Form.Label className="title_label__input">
                      {" "}
                      Level*{" "}
                    </Form.Label>
                    <Form.Control
                      required
                      as="select"
                      type="select"
                      name="level"
                      id="level"
                      value={data.level}
                      onChange={handleChange}
                    >
                      <option hidden value="" key="blankChoice">
                        Pilih Level...
                      </option>
                      <option value={"beginner"}>Beginner</option>
                      <option value={"advanced"}>Advanced</option>
                      <option value={"professional"}>Professional</option>
                    </Form.Control>

                    <div className="messages"></div>
                  </Form.Group> */}
                {/* </Col> */}
                {/* <Col md={12}>
               {data?.course_type_id === "ct33978343" ||
               data?.course_type_id === "ct08103261" ||
               data?.course_type_id === "ct10000007" ||
               data?.course_type_id === "ct84970430" ? (
                 <Form.Group>
                   <Form.Label className="title_label__input">
                     Jumlah Seats
                   </Form.Label>
                   <Form.Control
                     onChange={handleChange}
                     className="mb-3"
                     type="text"
                     id="total_seats"
                     name="total_seats"
                     value={data.total_seats}
                     placeholder="Masukkan Jumlah Seats"
                   />
                 </Form.Group>
               ) : null}
             </Col> */}
              </Row>

              <Row>
                <Col>
                  {/* instructures */}
                  <Form.Group>
                    <Form.Label className="title_label__input">
                      {" "}
                      Instruktur*{" "}
                    </Form.Label>
                    <div className="selected-item-wrapper">
                      {data.instructors?.length > 0
                        ? data.instructors.map((value, index) => {
                            return (
                              <p
                                key={index}
                                className="selected-item"
                                onClick={() => removeInstructorHandler(index)}
                              >
                                {value.name}
                              </p>
                            );
                          })
                        : null}
                    </div>
                    <Form.Control
                      className="mb-3 mt-2"
                      type="text"
                      name="instructors"
                      placeholder="Masukan Nama Instruktur"
                      onChange={liveSearchInstructor}
                      value={searchInstructor}
                      onKeyDown={onKeyDownHanlderInstructor}
                      autoComplete="off"
                      autoCorrect="off"
                      spellCheck="off"
                      disabled={instructors?.isLoadingLive}
                    />
                    {searchInstructor || instructorsLiveSearchData?.items ? (
                      <div className="search-list">
                        {instructors?.isLoadingLive ||
                        instructorsLiveSearchData?.length <= 0 ? (
                          <div>
                            <p>Loading...</p>
                          </div>
                        ) : (
                          <ul className="list-wrapper">
                            {instructorsLiveSearchData?.items?.length > 0 ? (
                              instructorsLiveSearchData?.items.map(
                                (value, index) => {
                                  const isExist = data.instructors.some(
                                    (option) => option.user_id === value.user_id
                                  );
                                  return (
                                    <li
                                      className={`list-item ${
                                        isExist ? "selected-list-item" : ""
                                      }`}
                                      key={index}
                                      onClick={() =>
                                        selectInstructorHandler(value)
                                      }
                                    >
                                      {value.name}
                                    </li>
                                  );
                                }
                              )
                            ) : (
                              <p>not found</p>
                            )}
                          </ul>
                        )}
                      </div>
                    ) : null}
                    <div className="messages"></div>
                  </Form.Group>
                </Col>
                {getCurrentRole() === "admin" && (
                  <Col>
                    <>
                      {/* prerequisites */}
                      <Form.Group>
                        <Form.Label className="title_label__input">
                          Kategori
                        </Form.Label>
                        <div className="width-fill mb-3">
                          <DropdownMultiselect
                            ref={refTest}
                            options={CreateOption(
                              categoriesCourse,
                              "categories",
                              "categories_id",
                              "name"
                            )}
                            name="categories"
                            placeholder="Pilih Kategori..."
                            handleOnChange={(selected) => {
                              handleChangeMultiSelect(
                                "categories",
                                CreateResult(selected, "array")
                              );
                            }}
                            selected={CreateSelected(
                              data.categories,
                              "categories",
                              categoriesCourse
                            )}
                          />
                        </div>
                        <div className="messages"></div>
                      </Form.Group>
                    </>
                    {/* learning path */}
                    {/* {getCurrentRole() === "instructor" && (
                    <Form.Group>
                      <Form.Label className="title_label__input">
                        Learning Path{" "}
                      </Form.Label>
                      {learningPathData.items?.length > 0 ? (
                        <DropdownMultiselect
                          id="learningpath"
                          options={CreateOption(
                            learningPathData.items,
                            "learning_paths",
                            "path_id",
                            "title"
                          )}
                          name="learning_paths"
                          placeholder="Pilih Learning Paths..."
                          handleOnChange={(selected) => {
                            handleChangeMultiSelect(
                              "learning_paths",
                              CreateResult(selected, "json", "path")
                            );
                          }}
                          selected={CreateSelected(
                            data.learning_paths,
                            "path",
                            learningPathData.items
                          )}
                        />
                      ) : null}

                      <div className="messages"></div>
                    </Form.Group>
                  )} */}
                  </Col>
                )}
              </Row>

              {/* description */}
              <Form.Group className="mb-3">
                <div>
                  <Form.Label className="title_label__input">
                    Deskripsi*
                  </Form.Label>
                </div>
                <Form.Control
                  onChange={handleChange}
                  as="textarea"
                  rows={5}
                  id="description"
                  name="description"
                  value={data.description.replace(/\u2013|\u2014/g, "-")}
                  placeholder="Masukkan Deskripsi"
                />
                <div className="messages"></div>
              </Form.Group>

              {/* summary */}
              <Form.Group>
                <Form.Label className="title_label__input">
                  Ringkasan
                </Form.Label>
                <Form.Control
                  onChange={handleChange}
                  className="mb-3"
                  type="text"
                  as="textarea"
                  id="summary"
                  name="summary"
                  value={data.summary.replace(/\u2013|\u2014/g, "-")}
                  placeholder="Masukkan Ringkasan Pelatihan"
                />
                <div className="messages"></div>
              </Form.Group>

              {/* skilled coverd */}
              <Form.Group>
                <Form.Label className="title_label__input">
                  Keahlian yang tercakup
                </Form.Label>
                <MultipleValueTextInput
                  onItemAdded={(item, allItems) => {
                    handleChangeMultiInput("skills_covered", item, allItems);
                  }}
                  onItemDeleted={(item, allItems) => {
                    handleRemoveMultiInput("skills_covered", item, allItems);
                  }}
                  label="Keahlian yang tercakup (pisahkan dengan koma atau enter) :"
                  name="skills_covered"
                  placeholder="Masukan Keahlian yang tercakup"
                  values={data.skills_covered}
                />
                <div className="messages"></div>
              </Form.Group>

              {getCurrentRole() === "instructor" && (
                <>
                  {/* target audiences */}
                  {/* <Form.Group>
                    <Form.Label className="title_label__input">
                      {" "}
                      Target Audiens{" "}
                    </Form.Label>
                    <MultipleValueTextInput
                      onItemAdded={(item, allItems) => {
                        handleChangeMultiInput("audiences", item);
                      }}
                      onItemDeleted={(item, allItems) => {
                        handleRemoveMultiInput("audiences", item);
                      }}
                      label="Audiens Pelatihan (pisahkan dengan koma atau enter) :"
                      name="audiences"
                      placeholder="Masukan Audiens"
                      values={data.audiences ? data.audiences : null}
                    />
                    <div className="messages"></div>
                  </Form.Group> */}
                </>
              )}

              {/* objectives */}
              <Form.Group>
                <Form.Label className="title_label__input">
                  {" "}
                  Objektif{" "}
                </Form.Label>
                <MultipleValueTextInput
                  onItemAdded={(item, allItems) => {
                    handleChangeMultiInput("objectives", item);
                  }}
                  onItemDeleted={(item, allItems) => {
                    handleRemoveMultiInput("objectives", item);
                  }}
                  label="Objektif Pelatihan (pisahkan dengan koma atau enter):"
                  name="objectives"
                  placeholder="Masukan Objektif"
                  values={data.objectives ? data.objectives : null}
                />
                <div className="messages"></div>
              </Form.Group>
              {getCurrentRole() === "instructor" && (
                <>
                  {/* requirements */}
                  {/* <Form.Group>
                    <Form.Label className="title_label__input">
                      {" "}
                      Persyaratan{" "}
                    </Form.Label>
                    <MultipleValueTextInput
                      onItemAdded={(item, allItems) => {
                        handleChangeMultiInput("requirements", item);
                      }}
                      onItemDeleted={(item, allItems) => {
                        handleRemoveMultiInput("requirements", item);
                      }}
                      label="Persyaratan Pelatihan (pisahkan dengan koma atau enter):"
                      name="requirements"
                      placeholder="Masukan Persyaratan"
                      values={data.requirements ? data.requirements : null}
                    />
                    <div className="messages"></div>
                  </Form.Group> */}
                </>
              )}

              {/* handouts */}
              <Form.Group>
                <Form.Label className="title_label__input">Handouts</Form.Label>
                <Form.Control
                  className="mb-3"
                  type="text"
                  as="textarea"
                  id="handouts"
                  name="handouts"
                  value={isHandoutName}
                  placeholder="Masukkan handouts"
                  onClick={() => handleShow("handouts")}
                />
                <div className="messages"></div>
              </Form.Group>
              {getCurrentRole() === "instructor" && (
                <>
                  {/* prerequisites */}

                  <Form.Group>
                    <Form.Label className="title_label__input">
                      Kategori
                    </Form.Label>
                    <div className="width-fill">
                      <label>Kategori Pelatihan</label>
                      <DropdownMultiselect
                        ref={refTest}
                        options={CreateOption(
                          categoriesCourse,
                          "categories",
                          "categories_id",
                          "name"
                        )}
                        name="categories"
                        placeholder="Pilih Kategori..."
                        handleOnChange={(selected) => {
                          handleChangeMultiSelect(
                            "categories",
                            CreateResult(selected, "array")
                          );
                        }}
                        selected={CreateSelected(
                          data.categories,
                          "categories",
                          categoriesCourse
                        )}
                      />
                    </div>
                    <div className="messages"></div>
                  </Form.Group>
                </>
              )}
              {getCurrentRole() === "instructor" && (
                <Form.Group>
                  <Form.Label className="title_label__input">
                    Prerequisites/Prasyarat Pelatihan
                  </Form.Label>
                  <div className="selected-item-wrapper">
                    {data.prerequisites?.length > 0
                      ? data.prerequisites.map((value, index) => {
                          return (
                            <p
                              key={index}
                              className="selected-item"
                              onClick={() => removeCourseHandler(index)}
                            >
                              {value.course}
                            </p>
                          );
                        })
                      : null}
                  </div>
                  <Form.Control
                    className="mb-3"
                    type="text"
                    name="prerequisites"
                    placeholder="Masukan Prasyarat Pelatihan"
                    onChange={liveSearch}
                    value={searchValue}
                    onKeyDown={onKeyDownHanlder}
                    autoComplete="off"
                    autoCorrect="off"
                    spellCheck="off"
                    disabled={courses?.isLoadingLive}
                  />
                  {searchValue || courseLiveSearchData.items ? (
                    <div className="search-list">
                      {courses?.isLoadingLive ||
                      courseLiveSearchData.length <= 0 ? (
                        <div>
                          <p>Loading...</p>
                        </div>
                      ) : (
                        <ul className="list-wrapper">
                          {courseLiveSearchData.items?.length > 0 ? (
                            courseLiveSearchData.items.map((value, index) => {
                              const isExist = data.prerequisites.some(
                                (option) => option.course_id === value.course_id
                              );
                              return (
                                <li
                                  className={`list-item ${
                                    isExist ? "selected-list-item" : ""
                                  }`}
                                  key={index}
                                  onClick={() => selectCourseHandler(value)}
                                >
                                  {value.title}
                                </li>
                              );
                            })
                          ) : (
                            <p>not found</p>
                          )}
                        </ul>
                      )}
                    </div>
                  ) : null}
                  <div className="messages"></div>
                </Form.Group>
              )}
              {/* <Row>
                <Col sm={12} md={6}>
                  price
                  <Form.Group>
                    <Form.Label className="title_label__input">
                      Harga (isi 0 jika gratis)
                    </Form.Label>
                    <Form.Control
                      onChange={handleChange}
                      className="mb-3"
                      type="number"
                      id="price"
                      name="price"
                      value={
                        typeof data.price === "string"
                          ? data.price === "Free"
                            ? 0
                            : parseInt(data.price.replace(/\D/g, ""))
                          : data.price
                      }
                      min="0"
                      step="100000"
                    />

                    <div className="messages"></div>
                  </Form.Group>
                </Col>
                {getCurrentRole() === "instructor" && (
                  <Col sm={12} md={6}>
                    Points
                    <Form.Group>
                      <Form.Label className="title_label__input">
                        Points
                      </Form.Label>
                      <Form.Control
                        onChange={handleChange}
                        className="mb-3"
                        type="number"
                        id="points"
                        name="points"
                        value={
                          typeof data.points === "string"
                            ? data.points === "Free"
                              ? 0
                              : parseInt(data.points.replace(/\D/g, ""))
                            : data.points
                        }
                        min="0"
                        step="100"
                      />

                      <div className="messages"></div>
                    </Form.Group>
                  </Col>
                )}
              </Row> */}

              {/* thumbnail */}
              <Form.Group>
                <Form.Label className="title_label__input">
                  {" "}
                  Thumbnail{" "}
                </Form.Label>
                <Form.Control
                  className="mb-3"
                  type="text"
                  id="thumbnail"
                  name="thumbnail"
                  value={isThumbnailName}
                  placeholder="Masukkan thumbnail"
                  onClick={() => handleShow("thumbnail")}
                />
                {/* <div className="messages"></div> */}
              </Form.Group>

              {/* show tagging if instructor */}
              {getCurrentRole() === "instructor" && (
                <>
                  {/* Produk */}
                  {product ? (
                    <Form.Group>
                      <Form.Label className="title_label__input">
                        Produk{" "}
                      </Form.Label>{" "}
                      <DropdownMultiselect
                        ref={refTest}
                        options={CreateOption(
                          product,
                          "products",
                          "product_id",
                          "title"
                        )}
                        name="products"
                        placeholder="Pilih Product..."
                        handleOnChange={(selected) => {
                          handleChangeMultiSelect(
                            "products",
                            CreateResult(selected, "array")
                          );
                        }}
                        selected={CreateSelected(
                          data.products,
                          "product",
                          product
                        )}
                      />
                    </Form.Group>
                  ) : null}

                  {/* Skema */}
                  {scheme ? (
                    <Form.Group>
                      <Form.Label className="title_label__input">
                        Skema{" "}
                      </Form.Label>{" "}
                      <DropdownMultiselect
                        options={CreateOption(
                          scheme,
                          "schemes",
                          "scheme_id",
                          "title"
                        )}
                        name="schemes"
                        placeholder="Pilih Skema..."
                        handleOnChange={(selected) => {
                          handleChangeMultiSelect(
                            "schemes",
                            CreateResult(selected, "array")
                          );
                        }}
                        selected={CreateSelected(
                          data.schemes,
                          "scheme",
                          scheme
                        )}
                      />
                    </Form.Group>
                  ) : null}

                  {/* Delivery Method */}
                  {deliveryMethod ? (
                    <Form.Group>
                      <Form.Label className="title_label__input">
                        Delivery Method{" "}
                      </Form.Label>{" "}
                      <DropdownMultiselect
                        options={CreateOption(
                          deliveryMethod,
                          "delivery_methods",
                          "delivery_method_id",
                          "title"
                        )}
                        name="delivery_methods"
                        placeholder="Pilih Delivery Method..."
                        handleOnChange={(selected) => {
                          handleChangeMultiSelect(
                            "delivery_methods",
                            CreateResult(selected, "array")
                          );
                        }}
                        selected={CreateSelected(
                          data.delivery_methods,
                          "delivery_method",
                          deliveryMethod
                        )}
                      />
                    </Form.Group>
                  ) : null}

                  {/* Topik Bahasan */}
                  <Form.Group>
                    <Form.Label className="title_label__input">
                      Topik Bahasan
                    </Form.Label>
                    <div className="selected-item-wrapper">
                      {data.discussion_topics?.length > 0
                        ? data.discussion_topics.map((value, index) => {
                            return (
                              <p
                                key={index}
                                className="selected-item"
                                onClick={() => removeDTHandler(index)}
                              >
                                {value.name
                                  ? value.name
                                  : value.discussion_topic}
                              </p>
                            );
                          })
                        : null}
                    </div>
                    <Form.Control
                      className="mb-3"
                      type="text"
                      name="discussion_topics"
                      placeholder="Masukan Persyaratan Pelatihan"
                      onChange={liveSearchDT}
                      value={searchDT}
                      onKeyDown={onKeyDownHanlderDT}
                      autoComplete="off"
                      autoCorrect="off"
                      spellCheck="off"
                      disabled={discussion_topics?.isLoadingLive}
                    />
                    {searchDT || discussTopicsLiveSearchData?.items ? (
                      <div className="search-list">
                        {discussion_topics?.isLoadingLive ||
                        discussTopicsLiveSearchData?.length <= 0 ? (
                          <div>
                            <p>Loading...</p>
                          </div>
                        ) : (
                          <ul className="list-wrapper">
                            {discussTopicsLiveSearchData?.items?.length > 0 ? (
                              discussTopicsLiveSearchData?.items?.map(
                                (value, index) => {
                                  const isExist = data?.discussion_topics?.some(
                                    (option) =>
                                      option.discussion_topic_id ===
                                      value.discussion_topic_id
                                  );
                                  return (
                                    <li
                                      className={`list-item ${
                                        isExist ? "selected-list-item" : ""
                                      }`}
                                      key={index}
                                      onClick={() => selectDTHandler(value)}
                                    >
                                      {value.title}
                                    </li>
                                  );
                                }
                              )
                            ) : (
                              <p>not found</p>
                            )}
                          </ul>
                        )}
                      </div>
                    ) : null}
                    <div className="messages"></div>
                  </Form.Group>

                  {/* Category Tree */}
                  <Form.Label className="title_label__input">
                    Category Tree
                  </Form.Label>
                  <Row className="mb-5">
                    {[...Array(categoryTreeTotal)].map((valueCT, indexCT) => {
                      return (
                        <>
                          <Col md={4}>
                            {/* Tipe Skill */}
                            <Form.Group>
                              <Form.Label className="title_label__input">
                                Tipe Skill
                              </Form.Label>
                              <Form.Control
                                required
                                as="select"
                                type="select"
                                name={`skillTypeId_${indexCT}`}
                                id={`skillTypeId_${indexCT}`}
                                onChange={(e) => handleChangeCT(e, indexCT)}
                                value={skillTypeId[indexCT]}
                                onClick={(e) => handleGetData(e, "skill_type")}
                              >
                                <option hidden value key="blankChoice">
                                  Pilih Tipe Skill...
                                </option>
                                {skill_types?.skillTypesData?.items?.map(
                                  (item, index) => {
                                    return (
                                      <option
                                        value={item.skill_type_id}
                                        key={index}
                                      >
                                        {item.title}
                                      </option>
                                    );
                                  }
                                )}
                              </Form.Control>
                            </Form.Group>
                          </Col>
                          <Col md={4}>
                            {/* Kategori */}
                            <Form.Group>
                              <Form.Label className="title_label__input">
                                Kategori
                              </Form.Label>
                              <Form.Control
                                required
                                as="select"
                                type="select"
                                name={`categoryId_${indexCT}`}
                                id={`categoryId_${indexCT}`}
                                onClick={(e) => handleGetData(e, "kategori")}
                                onChange={(e) => handleChangeCT(e, indexCT)}
                                value={categoryId[indexCT]}
                              >
                                <option hidden value key="blankChoice">
                                  Pilih Kategori...
                                </option>
                                {categories?.categoriesData?.items?.map(
                                  (item, index) => {
                                    return item.skill_type_id ===
                                      skillTypeId[indexCT] ? (
                                      <option
                                        value={item.category_id}
                                        key={index}
                                      >
                                        {item.title}
                                      </option>
                                    ) : null;
                                  }
                                )}
                              </Form.Control>
                            </Form.Group>
                          </Col>
                          <Col md={4}>
                            {/* Sub Kategori */}
                            <Form.Group>
                              <Form.Label className="title_label__input">
                                {" "}
                                Sub Kategori
                              </Form.Label>{" "}
                              <Form.Control
                                required
                                as="select"
                                type="select"
                                name={`subCategoryId_${indexCT}`}
                                id={`subCategoryId_${indexCT}`}
                                value={subCategoryId[indexCT]}
                                onChange={(e) => handleChangeCT(e, indexCT)}
                                onClick={(e) =>
                                  handleGetData(e, "sub_kategori")
                                }
                              >
                                <option hidden value key="blankChoice">
                                  Pilih Sub Kategori...
                                </option>
                                {sub_categories?.subCategoriesData?.items?.map(
                                  (item, index) => {
                                    return item.category_id ===
                                      categoryId[indexCT] ? (
                                      <option
                                        value={item.sub_category_id}
                                        key={index}
                                      >
                                        {item.title}
                                      </option>
                                    ) : null;
                                  }
                                )}
                              </Form.Control>
                            </Form.Group>
                            {categoryTreeTotal > 1 ? (
                              <Button
                                onClick={() =>
                                  removeCategoryTreeHandler(indexCT)
                                }
                                className="m-2"
                                variant="danger"
                              >
                                - Remove Category Tree
                              </Button>
                            ) : null}
                          </Col>
                        </>
                      );
                    })}
                    <Col md={12} className="d-flex justify-content-end mt-3">
                      <Button onClick={addCategoryTreeHandler} className="m-2">
                        + Add Category Tree
                      </Button>
                    </Col>
                  </Row>

                  {/* Pathway */}
                  {/* <Form.Label className="title_label__input">
                    Pathway
                  </Form.Label> */}
                  {/* <Row>
                    {[...Array(pathwayTotal)].map((value, indexCT) => {
                      handleSubIndustry(indexCT);
                      return (
                        <>
                          <Col md={6}>
                            Tingkat Pembelajaran 
                            <Form.Group>
                              <Form.Label className="title_label__input">
                                Tingkat Pembelajaran
                              </Form.Label>{" "}
                              <Form.Control
                                required
                                as="select"
                                type="select"
                                name={`learningLevelId_${indexCT}`}
                                id={`learningLevelId_${indexCT}`}
                                value={learningLevelId[indexCT]}
                                onChange={(e) => handleChangeCT(e, indexCT)}
                                onClick={(e) => handleGetData(e, "learning_level")}
                              >
                                <option hidden value key="blankChoice">
                                  Pilih Tingkat Pembelajaran...
                                </option>
                                {learning_levels?.learningLevelsData?.items?.map(
                                  (item, index) => {
                                    return (
                                      <option
                                        value={item.learning_level_id}
                                        key={index}
                                      >
                                        {item.title}
                                      </option>
                                    );
                                  }
                                )}
                              </Form.Control>
                            </Form.Group>
                          </Col>

                          <Col md={6}>
                            Posisi
                            <Form.Group>
                              <Form.Label className="title_label__input">
                                Posisi
                              </Form.Label>{" "}
                              <Form.Control
                                required
                                as="select"
                                type="select"
                                name={`positionId_${indexCT}`}
                                id={`positionId_${indexCT}`}
                                value={positionId[indexCT]}
                                onChange={(e) => handleChangeCT(e, indexCT)}
                                onClick={(e) => handleGetData(e, "position")}
                              >
                                <option hidden value key="blankChoice">
                                  Pilih Posisi...
                                </option>
                                {positions?.positionsData?.items?.map(
                                  (item, index) => {
                                    return (
                                      <option
                                        value={item.position_id}
                                        key={index}
                                      >
                                        {item.title}
                                      </option>
                                    );
                                  }
                                )}
                              </Form.Control>
                            </Form.Group>
                          </Col>

                          <Col md={6}>
                            Sektor
                            <Form.Group>
                              <Form.Label className="title_label__input">
                                {" "}
                                Sektor
                              </Form.Label>{" "}
                              <Form.Control
                                required
                                as="select"
                                type="select"
                                name={`sectorId_${indexCT}`}
                                id={`sectorId_${indexCT}`}
                                value={sectorId[indexCT]}
                                onChange={(e) => handleChangeCT(e, indexCT)}
                                onClick={(e) => handleGetData(e, "sector")}
                              >
                                <option hidden value key="blankChoice">
                                  Pilih Sektor...
                                </option>
                                {sectors?.sectorsData?.items?.map(
                                  (item, index) => {
                                    return (
                                      <option
                                        value={item.sector_id}
                                        key={index}
                                      >
                                        {item.title}
                                      </option>
                                    );
                                  }
                                )}
                              </Form.Control>
                            </Form.Group>
                          </Col>

                          <Col md={6}>
                            Industri
                            <Form.Group>
                              <Form.Label className="title_label__input">
                                {" "}
                                Industri
                              </Form.Label>{" "}
                              <Form.Control
                                required
                                as="select"
                                type="select"
                                name={`industryId_${indexCT}`}
                                id={`industryId_${indexCT}`}
                                value={industryId[indexCT]}
                                onChange={(e) => handleChangeCT(e, indexCT)}
                                onClick={(e) => handleGetData(e, "industry")}
                              >
                                <option hidden value key="blankChoice">
                                  Pilih Industri...
                                </option>
                                {industries?.industriesData?.items?.map(
                                  (item, index) => {
                                    return item.sector_id ===
                                      sectorId[indexCT] ? (
                                      <option
                                        value={item.industry_id}
                                        key={index}
                                      >
                                        {item.title}
                                      </option>
                                    ) : null;
                                  }
                                )}
                              </Form.Control>
                            </Form.Group>
                          </Col> */}

                  {/* <Col md={4}> */}
                  {/* Sub Industri */}
                  {/* <Form.Group>
                              <Form.Label className="title_label__input">
                                {" "}
                                Sub Industri
                              </Form.Label>{" "}
                              <Form.Control
                                required
                                as="select"
                                type="select"
                                name={`subIndustryId_${indexCT}`}
                                id={`subIndustryId_${indexCT}`}
                                value={subIndustryId[indexCT]}
                                onChange={(e) => handleChangeCT(e, indexCT)}
                                onClick={(e) => handleGetData(e, "sub_industry")}

                              >
                                <option hidden value key="blankChoice">
                                  Pilih Sub Industri...
                                </option>
                                {sub_industries?.subIndustriesData?.items?.map(
                                  (item, index) => {
                                    return item.industry_id ===
                                      industryId[indexCT] ? (
                                      <option
                                        value={item.sub_industry_id}
                                        key={index}
                                      >
                                        {item.title}
                                      </option>
                                    ) : null;
                                  }
                                )}
                              </Form.Control>
                            </Form.Group> */}
                  {/* {pathwayTotal > 1 ? (
                              <Button
                                onClick={() => removePathwayHandler(indexCT)}
                                variant="danger"
                              >
                                - Remove Pathway
                              </Button>
                            ) : null} */}
                  {/* </Col> */}
                  {/* </>
              );
                    })}

              <Col md={12} className="d-flex justify-content-end mt-3">
                <Button onClick={addPathwayHandler} className="m-2">
                  + Add Pathway
                </Button>
              </Col>
            </Row> */}
                </>
              )}

              <div className="d-flex justify-content-end">
                {handleCancel ? (
                  <Button
                    type="submit"
                    variant="light"
                    className="my-4 mx-2"
                    onClick={() => {
                      setData({ ...defaultData });
                      removeReduxState();
                      handleCancel();
                    }}
                  >
                    Batal
                  </Button>
                ) : null}
                <Button type="submit" className="my-4 mx-2">
                  {id ? "Edit" : "+ Tambahkan"}
                </Button>
              </div>
            </Form>
          ) : (
            <div>
              <Button
                type="submit"
                variant="warning"
                className="my-4 mx-2"
                onClick={() => {
                  setData({ ...defaultData });
                  removeReduxState();
                  handleCancel();
                }}
              >
                Mohon Ulang
              </Button>
            </div>
          )}
        </div>
      </Card>
      <MediaLibraryModal
        show={modalShow}
        setShow={setModalShow}
        title={"Pilih Gambar"}
        libraryHandler={libraryHandler}
        handleThumbnail={handleThumbnail}
        setSelectedLibary={setSelectedLibary}
      />
    </StyledWrapperMain>
  );
}

export default AddCourse;

let defaultData = {
  // audiences: [],
  categories: [],
  course_type_id: "",
  description: "",
  handouts: [],
  instructors: [],
  learning_paths: [],
  level: "",
  objectives: [],
  points: 0,
  discussion_topics: [],
  prerequisites: [],
  // price: 0,
  requirements: [],
  skills_covered: [],
  summary: "",
  thumbnail: "",
  title: "",
  products: [],
  schemes: [],
  delivery_methods: [],
  sub_category_id: "",
  pathway_learning_levels: "",
  pathway_positions: "",
  pathway_specifications: "",
  // total_seats: 200,
};

const StyledWrapperMain = styled.section`
  ${SELECT_DEFAULT_STYLES}
`;

const constraints = {
  // audiences: {
  //   presence: true,
  // },
  // categories: {
  //   presence: true,
  // },
  // course_type_id: {
  //   presence: true,
  //   length: { minimum: 3 },
  // },

  description: {
    presence: true,
    length: {
      minimum: 3,
    },
  },
  // handouts: {
  //   url: true,
  // },
  // instructors: {
  //   presence: true,
  //   length: {
  //     minimum: 3,
  //   },
  // },
  // learning_paths: {
  //   presence: true,
  //   length: {
  //     minimum: 3,
  //   },
  // },
  // level: {
  //   presence: true,
  //   length: { minimum: 3 },
  // },
  // objectives: {
  //   presence: true,
  // },
  // price: {
  //   presence: true,
  //   numericality: true,
  // },
  // points: {
  //   presence: true,
  //   numericality: true,
  // },
  // requirements: {
  //   presence: true,
  // },
  // skills_covered: {
  //   presence: true,
  // },
  summary: {
    // presence: true,
    length: { minimum: 3, maximum: 1000 },
  },
  title: {
    presence: true,
    length: { minimum: 3, maximum: 100 },
  },
  thumbnail: {
    // presence: true,
    length: { minimum: 3 },
    // url: true,
  },
};

const categoriesCourse = [
  {
    categories_id: "Keuangan",
    name: "Keuangan",
  },
  {
    categories_id: "Perpajakan",
    name: "Perpajakan",
  },
  {
    categories_id: "Akuntansi",
    name: "Akuntansi",
  },
  {
    categories_id: "Pemasaran",
    name: "Pemasaran",
  },
  {
    categories_id: "Penjualan",
    name: "Penjualan",
  },
  {
    categories_id: "Manajemen SDM",
    name: "Manajemen SDM",
  },
  {
    categories_id: "Pengembangan Diri",
    name: "Pengembangan Diri",
  },
  {
    categories_id: "Manajemen Risiko",
    name: "Manajemen Risiko",
  },
  {
    categories_id: "Manajemen Aset",
    name: "Manajemen Aset",
  },
  {
    categories_id: "Pengembangan Bisnis",
    name: "Pengembangan Bisnis",
  },
  {
    categories_id: "Legal",
    name: "Legal",
  },
  {
    categories_id: "Hubungan Eksternal",
    name: "Hubungan Eksternal",
  },
  {
    categories_id: "Soft Skills",
    name: "Soft Skills",
  },
  {
    categories_id: "Digital Marketing",
    name: "Digital Marketing",
  },
  {
    categories_id: "IT",
    name: "IT",
  },
  {
    categories_id: "Internal",
    name: "Internal",
  },
  {
    categories_id: "Transferable Skills",
    name: "Transferable Skills",
  },
  {
    categories_id: "Manajemen Produksi",
    name: "Manajemen Produksi",
  },
  {
    categories_id: "Bahasa Inggris",
    name: "Bahasa Inggris",
  },
];
