export const ANALYTICS_GROUP_DATA_LINK = "analytics/groups";
export const ANALYTICS_ASSIGNMENT_DATA_LINK = "analytics/assigned_courses";
export const ANALYTICS_SUBMITTED_DATA_LINK = "analytics/submitted_assignments";

export const ANALYTICS_COURSE_STATS_DATA_LINK = "analytics/course_stats";

//get
export const ANALYTICS_GROUP_DATA = "ANALYTICS_GROUP_DATA";
export const ANALYTICS_GROUP_DATA_SUCCESS = "ANALYTICS_GROUP_DATA_SUCCESS";
export const ANALYTICS_GROUP_DATA_ID_SUCCESS =
  "ANALYTICS_GROUP_DATA_ID_SUCCESS";
export const ANALYTICS_GROUP_DATA_FAIL = "ANALYTICS_GROUP_DATA_FAIL";

//get assigned
export const ANALYTICS_ASSIGNED_DATA = "ANALYTICS_ASSIGNED_DATA";
export const ANALYTICS_ASSIGNED_DATA_SUCCESS =
  "ANALYTICS_ASSIGNED_DATA_SUCCESS";
export const ANALYTICS_ASSIGNED_DATA_ID_SUCCESS =
  "ANALYTICS_ASSIGNED_DATA_ID_SUCCESS";
export const ANALYTICS_ASSIGNED_DATA_FAIL = "ANALYTICS_ASSIGNED_DATA_FAIL";

//get submitted
export const ANALYTICS_SUBMITTED_DATA = "ANALYTICS_SUBMITTED_DATA";
export const ANALYTICS_SUBMITTED_DATA_SUCCESS =
  "ANALYTICS_SUBMITTED_DATA_SUCCESS";
export const ANALYTICS_SUBMITTED_DATA_ID_SUCCESS =
  "ANALYTICS_SUBMITTED_DATA_ID_SUCCESS";
export const ANALYTICS_SUBMITTED_DATA_FAIL = "ANALYTICS_SUBMITTED_DATA_FAIL";

//get STATS
export const ANALYTICS_COURSE_STATS_DATA = "ANALYTICS_COURSE_STATS_DATA";
export const ANALYTICS_COURSE_STATS_DATA_SUCCESS =
  "ANALYTICS_COURSE_STATS_DATA_SUCCESS";
export const ANALYTICS_COURSE_STATS_DATA_ID_SUCCESS =
  "ANALYTICS_COURSE_STATS_DATA_ID_SUCCESS";
export const ANALYTICS_COURSE_STATS_DATA_FAIL =
  "ANALYTICS_COURSE_STATS_DATA_FAIL";

export const REMOVE_ANALYTICS_GROUP_DATA_ID = "REMOVE_ANALYTICS_GROUP_DATA_ID";
