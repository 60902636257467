import React, { useState, useEffect } from "react";
import { Button, Form } from "react-bootstrap";
import { FaCheck } from "react-icons/fa";
import { GiCancel } from "react-icons/gi";
import { useDispatch, useSelector } from "react-redux";
import {
  postLessonDataThunk,
  getLessonDataThunk,
  putLessonDataThunk,
} from "../redux/thunks/LessonThunks";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useParams } from "react-router-dom";
const MySwal = withReactContent(Swal);

function UpdateLesson() {
  const { id } = useParams();
  const dispatch = useDispatch();

  const [data, setData] = useState({
    content: "",
    title: "",
    topic_id: "",
    url_audio: "",
    url_pdf: "",
    url_video: "",
  });
  const [trigger, setTrigger] = useState(false);

  const handleChange = (e) => {
    const newData = { ...data };
    newData[e.target.id] = e.target.value;
    setData(newData);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const lessons = useSelector((state) => state.lessons);

  useEffect(() => {
    if (lessons.lessonDataPost === "error" && trigger) {
      setTrigger(false);
      displayPopup(false, "Terjadi masalah saat submit data", "Ok");
    }
    setData(lessons.lessonDataId);
  }, [lessons]);

  useEffect(() => {
    dispatch(getLessonDataThunk(id));
  }, []);

  function uploadData() {
    if (checkIfEmpty(data)) {
      displayPopup(false, "Mohon diisi semua", "Ok");
    } else {
      setTrigger(true);
      let req = {};
      req.body = data;
      req.id = id;
      dispatch(putLessonDataThunk(req));
    }
  }

  function displayPopup(cancel, title, confirm) {
    MySwal.fire({
      title: title,
      showCancelButton: cancel,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: confirm,
    }).then((value) => {
      console.log(value, "value");
    });
  }

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group>
        <Form.Label className="fw-600">ID Topik</Form.Label>
        <Form.Control
          onChange={handleChange}
          className="mb-3 br-8"
          type="text"
          id="topic_id"
          value={data.topic_id}
        />
      </Form.Group>
      <Form.Group>
        <Form.Label className="fw-600">Judul Pelajaran</Form.Label>
        <Form.Control
          onChange={handleChange}
          className="mb-3 br-8"
          type="text"
          id="title"
          value={data.title}
        />
      </Form.Group>

      <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
        <div>
          <Form.Label className="fw-600">Konten</Form.Label>
        </div>
        <Form.Control
          onChange={handleChange}
          as="textarea"
          rows={3}
          id="content"
          value={data.content}
          className="mb-3 br-8"
        />
      </Form.Group>

      <Form.Group>
        <Form.Label className="fw-600">URL Audio</Form.Label>
        <Form.Control
          onChange={handleChange}
          className="mb-3 br-8"
          type="text"
          id="url_audio"
          value={data.url_audio}
        />
      </Form.Group>
      <Form.Group>
        <Form.Label className="fw-600">URL PDF</Form.Label>
        <Form.Control
          onChange={handleChange}
          className="mb-3 br-8"
          type="text"
          id="url_pdf"
          value={data.url_pdf}
        />
      </Form.Group>
      <Form.Group>
        <Form.Label className="fw-600">URL Video</Form.Label>
        <Form.Control
          onChange={handleChange}
          className="mb-3 br-8"
          type="text"
          id="url_video"
          value={data.url_video}
        />
      </Form.Group>
      <div className="d-flex justify-content-end">
        <Button type="submit" variant="light" className="my-4 mx-2">
          <GiCancel className="pr-2 " />
          Batal
        </Button>
        <Button type="submit" onClick={uploadData} className="my-4 mx-2">
          {" "}
          <FaCheck className="pr-2 fill-white" />
          Simpan
        </Button>
      </div>
    </Form>
  );
}

export default UpdateLesson;

function checkIfEmpty(data) {
  return (
    !data.content.length > 0 ||
    !data.title.length > 0 ||
    !data.topic_id.length > 0 ||
    !data.url_audio.length > 0 ||
    !data.url_pdf.length > 0 ||
    !data.url_video.length > 0
  );
}
