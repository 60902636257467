import { satukelasApi } from "../constant/api";

export const AuthUsersDetail = {
  getUserId(id) {
    return satukelasApi.get(`users/${id}`);
  },

  getMembership(id) {
    return satukelasApi.get(`v2/company_dashboard_membership/${id}`);
  },
  getKuotaMembers(id) {
    return satukelasApi.get(`v2/company_dashboard_overview/${id}`);
  },

  getAdminData(param) {
    return satukelasApi.get(`users?role=admin${param ? param : ""}`);
  },

  getCompanyID(id) {
    return satukelasApi.get(`companies/${id}`);
  },
  putCompany(id, payload) {
    return satukelasApi.put(`companies/${id}`, payload);
  },

  postLogoCompany(data) {
    return satukelasApi.post(`assets/files?bucket=profile_pictures`, data);
  },
};
