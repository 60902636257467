import { Card, Col } from "react-bootstrap";
import ReactECharts from "echarts-for-react";
export default function KuotaAnggota(props) {
    const {totalMembers, result, colorPalete, kuotaMembers} = props;
    const optionPenugasan = {
        tooltip: {
          trigger: "item",
          show: false,
        },
    
        series: [
          {
            type: "pie",
            radius: ["50%", "80%"],
            label: {
              show: true,
              position: "center",
              formatter: `{a|${result}%}`,
              rich: {
                a: {
                  fontSize: 12,
                  fontWeight: "700",
                  color: "#4F4F4F",
                  fontFamily: "Poppins",
                },
              },
            },
            labelLine: {
              show: false,
            },
            data: [
              totalMembers.value,
              result >= 100 ? 0 : kuotaMembers.value - totalMembers.value,
            ],
          },
        ],
        color: colorPalete,
      };
    return (
        <Card className=" position-relative" style={{ height: "145px" }}>
        <Col
          md={12}
          lg={12}
          className="d-flex d-flex flex-wrap mb-2 align-items-center h-100"
        >
          <Col
            md={12}
            lg={8}
            className="d-flex justify-content-center px-0"
          >
            <div className="">
              <h3 className="card__title-anggota-organisasi mb-0">
                Kuota Anggota
              </h3>
              <h3 className="card__sub-title-anggota-organisasi">
                <span>{totalMembers.value}/</span>
                {kuotaMembers.value ? kuotaMembers.value : 0} User
              </h3>
              <span className="card__desc-anggota-organisasi">
                <b>
                  {Math.round(
                    (totalMembers.value / kuotaMembers.value) * 100
                  )}
                  %
                </b>{" "}
                Telah terisi
              </span>
            </div>
          </Col>
          <Col md={12} lg={4} className="">
            <div>
              <ReactECharts
                style={{ height: "100px", width: "100px" }}
                option={optionPenugasan}
                theme={"light"}
              />
            </div>
          </Col>
        </Col>
      </Card>
    )
}