import React, { useEffect } from "react";
import { Row, Col, Container } from "react-bootstrap";
// import TableWebinar from "../../components/TableWebinar";
import TableCourse from "../../components/TableCourse";
import { AuthIsLogin } from "../../helper/auth";
import { useHistory } from "react-router-dom";
import "../../assets/css/Dashboard/index.css";

function WebinarTable() {
  const history = useHistory();
  useEffect(() => {
    AuthIsLogin(history, false);
  }, []);
  return (
    <Container fluid>
      <Row className="justify-content-center align-items-center height-inherit">
        <Col xs={12} sm={12} md={12} lg={12}>
          <TableCourse type="webinar" />
          {/* <TableWebinar /> */}
        </Col>
      </Row>
    </Container>
  );
}

export default WebinarTable;
