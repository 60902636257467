import {
  // get
  ASSIGNMENT_DATA,
  ASSIGNMENT_DATA_SUCCESS,
  ASSIGNMENT_DATA_ID_SUCCESS,
  // post
  ASSIGNMENT_DATA_POST,
  ASSIGNMENT_DATA_POST_SUCCESS,
  ASSIGNMENT_DATA_POST_FAIL,
  // put
  ASSIGNMENT_DATA_PUT,
  ASSIGNMENT_DATA_PUT_SUCCESS,
  ASSIGNMENT_DATA_PUT_FAIL,
  // delete
  ASSIGNMENT_DATA_DELETE,
  ASSIGNMENT_DATA_DELETE_SUCCESS,
  ASSIGNMENT_DATA_DELETE_FAIL,
  // fail
  ASSIGNMENT_DATA_FAIL,
} from "../config/AssignmentConfig";

// get
export const getAssignmentData = (value) => ({
  type: ASSIGNMENT_DATA,
  value,
});
export const getAssignmentDataSuccess = (value) => ({
  type: ASSIGNMENT_DATA_SUCCESS,
  value,
});
export const getAssignmentDataIdSuccess = (value) => ({
  type: ASSIGNMENT_DATA_ID_SUCCESS,
  value,
});
export const getAssignmentDataFail = (value) => ({
  type: ASSIGNMENT_DATA_FAIL,
  value,
});

// post
export const postAssignmentData = (value) => ({
  type: ASSIGNMENT_DATA_POST,
  value,
});
export const postAssignmentDataSuccess = (value) => ({
  type: ASSIGNMENT_DATA_POST_SUCCESS,
  value,
});
export const postAssignmentDataFail = (value) => ({
  type: ASSIGNMENT_DATA_POST_FAIL,
  value,
});

// put
export const putAssignmentData = (value) => ({
  type: ASSIGNMENT_DATA_PUT,
  value,
});
export const putAssignmentDataSuccess = (value) => ({
  type: ASSIGNMENT_DATA_PUT_SUCCESS,
  value,
});
export const putAssignmentDataFail = (value) => ({
  type: ASSIGNMENT_DATA_PUT_FAIL,
  value,
});

// delete
export const deleteAssignmentData = (value) => ({
  type: ASSIGNMENT_DATA_DELETE,
  value,
});
export const deleteAssignmentDataSuccess = (value) => ({
  type: ASSIGNMENT_DATA_DELETE_SUCCESS,
  value,
});
export const deleteAssignmentDataFail = (value) => ({
  type: ASSIGNMENT_DATA_DELETE_FAIL,
  value,
});

// fail handle
