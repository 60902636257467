import React, { useEffect, useState } from "react";
import { Breadcrumb, Button, Card, Col, Form, Image } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import MySwal from "sweetalert2";
import { SkValidate } from "../../../components/common/SkValidate";
import { AuthUsersDetail } from "../../../http/AuthUsersDetail";
import {
  getCitiesDataThunk,
  getProvincesDataThunk,
  getSubdistrictsDataThunk,
} from "../../../redux/thunks/OrdersThunks";
import {
  getUsersDataThunk,
  putUsersDataThunk,
} from "../../../redux/thunks/UsersThunks";
import "../../../assets/css/Dashboard/AddMember.css";
import IconOverviewMan from "../../../assets/images/icon-overview.svg";
import IconConfirmSucces from "../../../assets/images/icon-confirm-succes.svg";
import IconEye from "../../../assets/images/icon-eye.svg";
import IconOffEye from "../../../assets/images/icon-eye-off.svg";
const sha256 = require("js-sha256");
function EditMember() {
  const [active, setActive] = useState(1);
  const [size, setSize] = useState(10);
  const [trigger, setTrigger] = useState(false);
  const provinces = useSelector((state) => state?.orders?.ordersProvinces);
  const cities = useSelector((state) => state?.orders?.ordersCities);
  const subDistricts = useSelector(
    (state) => state?.orders?.ordersSubdistricts
  );
  const users = useSelector((state) => state.users);
  const dispatch = useDispatch();
  const history = useHistory();
  const [showPw, setShowPw] = useState(false);
  const userId = history.location.pathname.split("/")[3];
  const [editInput, setEditInput] = useState({
    address_city: "",
    address_province: "",
    address_street: "",
    address_subdistrict: "",
    address_ward: "",
    avatar: "",
    company: "",
    company_id: "",
    description: "",
    division: "",
    education_level: "",
    email: "",
    favorite_topics: [],
    is_bnsp: 0,
    major: "",
    name: "",
    nationality: "",
    occupation: "",
    password: "",
    phone: "",
    position: "",
    role: "",
    sex: "",
    social_media: [],
    university: "",
    zip_code: "",
    id: userId,
  });
  const [matchedPassword, setMatchedPassword] = useState({
    password: editInput.password,
    confirmPassword: "",
  });

  useEffect(async () => {
    try {
      const response = await AuthUsersDetail.getUserId(userId);
      setEditInput({
        address_city: response.address_city,
        address_province: response.address_province,
        address_street: response.address_street,
        address_subdistrict: response.address_subdistrict,
        address_ward: response.address_ward,
        avatar: "",
        company: "",
        company_id: response.company_id,
        description: "",
        division: response.division,
        education_level: "",
        email: response.email,
        favorite_topics: [],
        is_bnsp: 0,
        major: "",
        name: response.name,
        nationality: "",
        occupation: "",
        password: "",
        phone: response.phone,
        position: response.position,
        role: response.role,
        sex: response.sex,
        social_media: [],
        university: "",
        zip_code: "",
      });
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    if (users.usersDataPut.status == "error" && trigger) {
      setTrigger(false);
      let msg =
        users.usersDataPut.value?.error?.response?.data.detail ??
        users.usersDataPut.value?.error?.response?.data;
      displayPopup(true, msg, false);
    }
    if (users.usersDataPut === "success" && trigger) {
      setTrigger(false);
      customDisplayPopup();
    }
    if (trigger) {
      dispatch(
        getUsersDataThunk({
          param: `?size=${size}&page=${active}&role=student`,
        })
      );
    }
  }, [users.usersDataPut, trigger]);

  useEffect(() => {
    dispatch(getProvincesDataThunk());
    dispatch(getCitiesDataThunk(editInput.address_province));
    dispatch(getSubdistrictsDataThunk(editInput.address_city));
  }, [editInput.address_province, editInput.address_city]);

  const handleEditPerson = (e) => {
    e.preventDefault();
    const form = e.target;

    const isValid = SkValidate(form, constraints);
    if (isValid) {
      let data = { body: { ...editInput } };
      data.body.password = sha256(editInput.password);
      data.body.company_id = users.usersDataId.company_id;
      data.id = userId;
      setTrigger(true);
      dispatch(putUsersDataThunk(data));
    }
  };

  const handleChangeInput = (e) => {
    const { name, value } = e.target;

    setEditInput({ ...editInput, [name]: value });
    setMatchedPassword({
      ...matchedPassword,
      [name]: value,
    });
  };

  function displayPopup(cancel, title, confirm, confirmMsg) {
    MySwal.fire({
      title: title,
      cancelButtonText: "Ok",
      showCancelButton: cancel,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      showConfirmButton: confirm,
      confirmButtonText: confirmMsg,
    }).then((value) => {
      if (confirm) {
        history.push("/dashboard/management-organisasi");
      }
    });
  }

  function customDisplayPopup() {
    MySwal.fire({
      title: "Selamat!",
      html: `Anda Telah Berhasil Mengubah Informasi atas nama <b>${editInput.name}</b>`,
      imageUrl: `${IconConfirmSucces}`,
      imageWidth: 122,
      imageHeight: 135,
      imageAlt: "Custom image",
      showConfirmButton: false,
      showCancelButton: true,
      cancelButtonText: `<p style='margin-bottom:0;width:188px'>Tutup</p>`,
      cancelButtonColor: "#55AA73",
    }).then(async (result) => {
      if (
        (result.isDismissed && result.dismiss == "cancel") ||
        result.dismiss == "backdrop"
      ) {
        history.push("/dashboard/management-organisasi");
      }
    });
  }

  function togglePassword() {
    setShowPw(!showPw);
  }

  return (
    <div className="px-3">
      <h3 className="title__page-addmember mb-4">Tambah Anggota</h3>
      <Breadcrumb>
        <Breadcrumb.Item href="#">
          <img
            src={IconOverviewMan}
            alt=""
            className="icon__breadcrumb"
            style={{ color: "#A6A6A6" }}
            height={24}
            width={24}
          />
        </Breadcrumb.Item>
        <Breadcrumb.Item
          href="/dashboard/management-organisasi"
          className="breadcrumb__list-nav-addmember"
        >
          Manajemen Organisasi
        </Breadcrumb.Item>
        <Breadcrumb.Item active className="breadcrumb__list-nav-addmember">
          Ubah Data Anggota
        </Breadcrumb.Item>
      </Breadcrumb>
      <Card>
        <Card.Body>
          <Form onSubmit={handleEditPerson}>
            <Col md={12} className="mb-4 d-flex flex-wrap">
              <Col lg={6}>
                <h3 className="title__form-addmember">
                  Silahkan Ubah Informasi Anggota
                </h3>
              </Col>
              <Col lg={12}>
                <span className="subtitle__form-addmember">
                  Isi form dibawah ini untuk mengubah informasi anggota
                </span>
              </Col>
            </Col>
            <Col md={12} className="mb-4 d-flex flex-wrap">
              <Col lg={6}>
                <h3 className="title__form-addmember">Informasi Pribadi</h3>
                <span className="subtitle__form-addmember">
                  Masukan Informasi Pribadi Karyawan.
                </span>
              </Col>
              <Col lg={6}>
                <Form.Group className="mb-3" controlId="formGroupKaryawan">
                  <Form.Label className="title__form-addmember">
                    Role
                  </Form.Label>
                  <Form.Control
                    autoComplete="off"
                    as="select"
                    name="role"
                    value={editInput.role}
                    onChange={handleChangeInput}
                  >
                    <option
                      value={"student"}
                      selected={editInput.role == "student"}
                    >
                      Student
                    </option>
                    <option
                      value={"admin"}
                      selected={editInput.role == "admin"}
                    >
                      Admin
                    </option>
                  </Form.Control>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formGroupKaryawan">
                  <Form.Label className="title__form-addmember">
                    Nama Karyawan <span className="text-danger ml-2">*</span>
                  </Form.Label>
                  <Form.Control
                    autoComplete="off"
                    type="text"
                    placeholder="Masukan Nama Karyawan"
                    name="name"
                    value={editInput.name}
                    onChange={handleChangeInput}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="">
                  <Form.Label className="title__form-addmember">
                    Jenis Kelamin <span className="text-danger ml-2">*</span>
                  </Form.Label>
                  <div>
                    <Form.Check
                      inline
                      type="radio"
                      label="Laki-laki"
                      name="sex"
                      value={"laki-laki"}
                      checked={editInput.sex.toLowerCase() == "laki-laki"}
                      onChange={handleChangeInput}
                    />
                    <Form.Check
                      inline
                      type="radio"
                      label="Perempuan"
                      name="sex"
                      value={"perempuan"}
                      checked={editInput.sex.toLowerCase() == "perempuan"}
                      onChange={handleChangeInput}
                    />
                  </div>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formGroupKaryawan">
                  <Form.Label className="title__form-addmember">
                    No.Handphone <span className="text-danger ml-2">*</span>
                  </Form.Label>
                  <Form.Control
                    autoComplete="off"
                    type="text"
                    placeholder="Masukan No.Handphone"
                    name="phone"
                    value={editInput.phone}
                    onChange={handleChangeInput}
                  />
                </Form.Group>
              </Col>
            </Col>
            <Col md={12} className="mb-4 d-flex flex-wrap">
              <Col lg={6}>
                <h3 className="title__form-addmember">Informasi Alamat</h3>
                <span className="subtitle__form-addmember">
                  Masukan Informasi Alamat Karyawan.
                </span>
              </Col>
              <Col lg={6}>
                <Form.Group className="mb-3" controlId="formGroupKaryawan">
                  <Form.Label className="title__form-addmember">
                    Alamat <span className="text__opsional">(Opsional)</span>
                  </Form.Label>
                  <Form.Control
                    autoComplete="off"
                    type="text"
                    placeholder="Masukan Alamat Karyawan"
                    name="address_street"
                    value={editInput.address_street}
                    onChange={handleChangeInput}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formGroupKaryawan">
                  <Form.Label className="title__form-addmember">
                    Provinsi <span className="text__opsional">(Opsional)</span>
                  </Form.Label>
                  <Form.Control
                    autoComplete="off"
                    as="select"
                    onChange={handleChangeInput}
                    name="address_province"
                  >
                    <option value="-1">Pilih Provinsi</option>
                    {provinces != "error" &&
                      provinces?.map((data) => (
                        <option
                          key={data.province_id}
                          value={data.province_id}
                          selected={
                            editInput.address_province == data.province_id
                          }
                        >
                          {data.province}
                        </option>
                      ))}
                  </Form.Control>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formGroupKaryawan">
                  <Form.Label className="title__form-addmember">
                    Kota/Kabupaten{" "}
                    <span className="text__opsional">(Opsional)</span>
                  </Form.Label>
                  <Form.Control
                    autoComplete="off"
                    as="select"
                    name="address_city"
                    onChange={handleChangeInput}
                  >
                    <option value="-1">Pilih Kab/Kota</option>
                    {cities != "error" &&
                      cities?.map((data) => (
                        <option
                          key={data.city_id}
                          value={data.city_id}
                          selected={editInput.address_city == data.city_id}
                        >
                          {data.city_name}
                        </option>
                      ))}
                  </Form.Control>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formGroupKaryawan">
                  <Form.Label className="title__form-addmember">
                    Kecamatan <span className="text__opsional">(Opsional)</span>
                  </Form.Label>
                  <Form.Control
                    autoComplete="off"
                    as="select"
                    name="address_subdistrict"
                    onChange={handleChangeInput}
                  >
                    <option value="-1">Pilih Kecamatan</option>
                    {subDistricts != "error" &&
                      subDistricts?.map((data) => (
                        <option
                          key={data.subdistrict_id}
                          value={data.subdistrict_id}
                          selected={
                            editInput.address_subdistrict == data.subdistrict_id
                          }
                        >
                          {data.subdistrict_name}
                        </option>
                      ))}
                  </Form.Control>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formGroupKaryawan">
                  <Form.Label className="title__form-addmember">
                    Kelurahan <span className="text__opsional">(Opsional)</span>
                  </Form.Label>
                  <Form.Control
                    autoComplete="off"
                    type="text"
                    placeholder="Masukan Kelurahan/Desa"
                    name="address_ward"
                    value={editInput.address_ward}
                    onChange={handleChangeInput}
                  ></Form.Control>
                </Form.Group>
              </Col>
            </Col>
            <Col md={12} className="mb-4 d-flex flex-wrap">
              <Col lg={6}>
                <h3 className="title__form-addmember">Informasi Pekerjaan</h3>
                <span className="subtitle__form-addmember">
                  Tambahkan Informasi Pekerjaan Karyawan Anda.
                </span>
              </Col>
              <Col lg={6}>
                <Form.Group className="mb-3" controlId="formGroupKaryawan">
                  <Form.Label className="title__form-addmember">
                    Jabatan <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    autoComplete="off"
                    type="text"
                    placeholder="Masukan Jabatan Karyawan"
                    name="position"
                    value={editInput.position}
                    onChange={handleChangeInput}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formGroupKaryawan">
                  <Form.Label className="title__form-addmember">
                    Divisi <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    autoComplete="off"
                    type="text"
                    placeholder="Masukan Divisi Karyawan"
                    name="division"
                    value={editInput.division}
                    onChange={handleChangeInput}
                  />
                </Form.Group>
              </Col>
            </Col>
            <Col md={12} className="mb-4 d-flex flex-wrap">
              <Col lg={6}>
                <h3 className="title__form-addmember">Informasi Akun</h3>
                <span className="subtitle__form-addmember">
                  Tambahkan Informasi Akun Karyawan Anda.
                </span>
              </Col>
              <Col lg={6}>
                <Form.Group className="mb-3" controlId="formGroupKaryawan">
                  <Form.Label className="title__form-addmember">
                    Email <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    autoComplete="new-password"
                    type="email"
                    placeholder="Masukan Email"
                    name="email"
                    value={editInput.email}
                    onChange={handleChangeInput}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formGroupKaryawan">
                  <Form.Label className="title__form-addmember">
                    Kata Sandi <span className="text-danger">*</span>
                  </Form.Label>
                  <div className="position-relative">
                    <Form.Control
                      autoComplete="new-password"
                      type={`${showPw ? "text" : "password"}`}
                      placeholder="Masukan Kata Sandi"
                      name="password"
                      onChange={handleChangeInput}
                    />
                    <div
                      className="position-absolute"
                      style={{
                        cursor: "pointer",
                        right: "10px",
                        top: "15px",
                      }}
                      onClick={() => togglePassword()}
                    >
                      <Image src={showPw ? IconEye : IconOffEye} />
                    </div>
                  </div>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formGroupKaryawan">
                  <Form.Label className="title__form-addmember">
                    Konfirmasi Kata Sandi <span className="text-danger">*</span>
                  </Form.Label>
                  <div className="position-relative">
                    <Form.Control
                      autoComplete="off"
                      type={`${showPw ? "text" : "password"}`}
                      placeholder="Konfirmasi Kata Sandi"
                      name="confirmPassword"
                      onChange={handleChangeInput}
                    />
                    <div
                      className="position-absolute"
                      style={{
                        cursor: "pointer",
                        right: "10px",
                        top: "15px",
                      }}
                      onClick={() => togglePassword()}
                    >
                      <Image src={showPw ? IconEye : IconOffEye} />
                    </div>
                  </div>
                  {matchedPassword.password !=
                    matchedPassword.confirmPassword && (
                    <span className="text-danger">Password Tidak Sama</span>
                  )}
                </Form.Group>
              </Col>
            </Col>
            <Col md={12} className="mb-4 d-flex justify-content-end">
              <Link
                to="/dashboard/management-organisasi"
                className="btn__back-addmember mr-3 p-2"
              >
                Kembali
              </Link>
              <Button
                variant="primary"
                type="submit"
                className="btn__submit-addmember"
              >
                Submit
              </Button>
            </Col>
          </Form>
        </Card.Body>
      </Card>
    </div>
  );
}
const constraints = {
  email: {
    presence: true,
    length: {
      minimum: 3,
      maximum: 100,
    },
    email: true,
  },
  password: {
    presence: true,
    length: {
      minimum: 6,
      maximum: 100,
    },
  },
  name: {
    presence: false,
    length: {
      minimum: 3,
    },
  },
  birthday: {
    presence: false,
    length: {
      minimum: 5,
    },
  },
};

export default EditMember;
