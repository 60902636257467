import React, { useState } from "react";
import { Table, Card, Button } from "react-bootstrap";
import moment from "moment";
import { useLocation } from "react-router-dom";
import SkModal from "./common/SkModal";
import "../assets/css/Dashboard/companyAnalytics.css";
import { CORPORATE_MATERIALS_ENDPOINT } from "../helper/constant";

function MateriCompany({ data }) {
  const [modalContent, setModalContent] = useState({
    title: "Modal Title",
    show: false,
    componentBody: "",
  });
  let address = useLocation();
  let splitted = address.pathname.split("/");
  const handleDetail = (title) => {
    setModalContent({
      ...modalContent,
      show: true,
      title: `Detail ${title}`,
      body: (
        <Table>
          <thead>
            <th>Nama</th>
          </thead>
          <tbody>
            <tr>
              <td>Belum ada</td>
            </tr>
          </tbody>
        </Table>
      ),
    });
  };
  return (
    <>
      <Card className="card_container">
        <div className="card_inside__padding ">
          <div className="d-flex flex-column justify-content-between border_card">
            <div className="card_title__info mb-1">
              Materi Perusahaan yang di Unduh
            </div>
            <div className="card_subtitle mb-3">
              {data?.downloaded_files?.length} Total Materi
            </div>
          </div>
          {data?.downloaded_files?.length > 0 ? (
            <Table responsive>
              <thead>
                <th className="pl-0 w-70">Materi Perusahaan</th>
                <th className="pl-0 w-70">Download Terakhir</th>
                {splitted[2] !== "analytic" ? null : (
                  <>
                    <th>Jml. Download</th>

                    <th>Actions</th>
                  </>
                )}
                <th>Download</th>
              </thead>
              {data?.downloaded_files?.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>{item?.filekey}</td>
                    <td>
                      {moment(item?.last_download).format("DD MMMM YYYY")}
                    </td>
                    <td>
                      <Button
                        className="button_detail__users"
                        href={CORPORATE_MATERIALS_ENDPOINT + `${item?.filekey}`}
                      >
                        Download
                      </Button>
                    </td>
                  </tr>
                );
              })}
            </Table>
          ) : (
            "Data tidak ditemukan"
          )}
        </div>
      </Card>
      <SkModal content={modalContent} />
    </>
  );
}

export default MateriCompany;
