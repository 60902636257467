import { satukelasApi } from "../constant/api";

export const TaskServices = {
  getTaskManagement(param) {
    return satukelasApi.get(`v2/company_assignments${param}`);
  },
  postTaskManagement(payload) {
    return satukelasApi.post(`v2/company_assignments`, payload);
  },
  putTaskManagement(payload) {
    return satukelasApi.put(
      `v2/company_assignments/${payload.company_assignment_id}`,
      payload.company_assignment
    );
  },
  deleteTaskManagement(id) {
    return satukelasApi.delete(`v2/company_assignments${id}`);
  },
  removeTaskManagement(payload) {
    return satukelasApi.post(`v2/company_assignments/remove`, payload);
  },
  getAssigneeTaskManagement(id) {
    return satukelasApi.get(`v2/company_assignments_assignees${id}`);
  },
  getChartTaskManagement(id) {
    return satukelasApi.get(`v2/company_assignments_chart${id}`);
  },
  getCourseTaskManagement(id) {
    return satukelasApi.get(`v2/company_assignments_courses${id}`);
  },
  putCourseTaskManagement(payload) {
    return satukelasApi.put(
      `v2/company_assignments_courses/add/${payload.company_assignment_id}`,
      payload.company_assignment
    );
  },
  deleteCourseTaskManagement(payload) {
    return satukelasApi.put(
      `v2/company_assignments_courses/remove/${payload.company_assignment_id}`,
      payload.company_assignment
    );
  },
  putPersonTaskManagement(payload) {
    return satukelasApi.put(
      `v2/company_assignments_assignees/add/${payload.company_assignment_id}`,
      payload.company_assignment
    );
  },
  deletePersonTaskManagement(payload) {
    return satukelasApi.put(
      `v2/company_assignments_assignees/remove/${payload.company_assignment_id}`,
      payload.company_assignment
    );
  },
  getCourseByCompany(param) {
    return satukelasApi.get(`courses${param ? param : ""}`);
  },
  getCourseByCompany(param) {
    return satukelasApi.get(`courses${param ? param : ""}`);
  },
};
