import React, { useEffect } from "react";
import { Container, Row } from "react-bootstrap";
import CompanyAnalytics from "../../components/CompanyAnalytics";
import { AuthIsLogin } from "../../helper/auth";
import { useHistory } from "react-router-dom";
import "../../assets/css/Dashboard/index.css";

function Analytic() {
  // const [statusUser, setStatusUser] = useState("perusahaan");
  const history = useHistory();
  useEffect(() => {
    AuthIsLogin(history, true);
  }, []);
  // const handleChange = (e) => {
  //   let status = e.target.value;
  //   setStatusUser(status);
  // };
  return (
    <Container fluid>
      <Row>
        {/* <Col md={8}>
          <h2 className="h2 calendar-title">Analitik </h2>
        </Col> */}

        <CompanyAnalytics />
      </Row>
    </Container>
  );
}

export default Analytic;
