import React, { useState, useEffect } from "react";
import {
  Button,
  Table,
  Container,
  Row,
  Col,
  Card,
} from "react-bootstrap";
import { FaDownload, FaRegEye } from "react-icons/fa";
import {  AuthIsLogin } from "../../helper/auth";
import { useHistory } from "react-router-dom";
import Pagination from "../../components/Layout/Pagination";
import "../../assets/css/Courses/manageUser.css";
import { useDispatch, useSelector } from "react-redux";
import { getAnalyticsSubmittedDataThunk } from "../../redux/thunks/AnalyticsGroupThunks";
import moment from "moment";

function StudentHomeWork() {
  const dispatch = useDispatch();
  const [active, setActive] = useState(1);
  const [size, setSize] = useState(10); //pagination size
  const [analyticsGroupSubmitPagination, setanalyticsGroupSubmitPagination] =
    useState();
  const [submittedData, setSubmittedData] = useState();
  const [render, setRender] = useState(false);
  const [dimensions, setDimensions] = useState({
    width: 0,
    height: 0,
  });
  const analyticsgroup = useSelector((state) => state.analyticsgroup);
  const users = useSelector((state) => state.users);
  const history = useHistory();
  const analyticsGroupSubmit = analyticsgroup.analyticsSubmittedData;

  useEffect(() => {
    if (users.usersDataId?.user_id) {
      dispatch(
        getAnalyticsSubmittedDataThunk({
          param: `?company_id=${users.usersDataId.company_id}&size=${size}&page=${active}`,
        })
      );
    }
  }, [users]);

  useEffect(() => {
    setSubmittedData(analyticsGroupSubmit?.items);
    setanalyticsGroupSubmitPagination(analyticsGroupSubmit?.pagination);
  }, [analyticsgroup]);

  useEffect(() => {
    AuthIsLogin(history, true);
  }, []);

  const handleVisit = (value) => {
    history.push(`/preview-file/${value.split("/")[3]}`);
  };

  // useEffect(() => {
  //   setAssignmentData(assignments.assignmentData.items);
  //   setAssignmentDataId(assignments.assignmentDataId);
  //   setanalyticsGroupSubmitPagination(assignments.assignmentData.pagination);
  // }, [assignments]);

  // useEffect(() => {
  //   if (assignments.assignmentDataDelete === "error" && trigger) {
  //     setTrigger(false);
  //     displayPopup(false, "Terjadi masalah saat menghapus data", "Ok");
  //   }
  //   if (assignments.assignmentDataDelete === "success" && trigger) {
  //     setTrigger(false);
  //     dispatch(
  //       getAssignmentData({
  //         param: `?size=${size}&page=${active}${
  //           search ? "&keyword=" + search : ""
  //         }`,
  //       })
  //     );
  //   }
  // }, [assignments]);

  function paginationSelect(newActive) {
    setActive(newActive);
    dispatch(
      getAnalyticsSubmittedDataThunk({
        param: `?company_id=${users.usersDataId.company_id}&size=${size}&page=${newActive}`,
      })
    );
  }

  useEffect(() => {
    setRender(true);
    window.addEventListener("resize", handleResize, false);
  }, []);

  useEffect(() => {
    handleResize();
  }, [render]);

  const handleResize = () => {
    setDimensions({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };

  return (
    <Container fluid>
      <Row className="justify-content-center align-items-center height-inherit">
        <Col md={6}>
          <div className=" pt-2 pb-">
            <div className="course_create__title mb-3">PR Student</div>
            {/* <Breadcrumb>
              <Breadcrumb.Item href="#">Advanced Element</Breadcrumb.Item>
              <Breadcrumb.Item href="#">List homework</Breadcrumb.Item>
            </Breadcrumb> */}
          </div>
        </Col>
        <Col
          md={6}
          className="mb-1 text-right d-flex justify-content-end h-fit"
        >
          {/* <div className="d-flex justify-content-end align-items-center mb-2 card_filter">
            Filter by :{" "}
            <Dropdown className="ml-2">
              <Dropdown.Toggle
                className="px-3 py-1"
                variant="success"
                id="dropdown-basic"
              >
                Urutan
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item>Ascending</Dropdown.Item>
                <Dropdown.Item>Descending</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div> */}
        </Col>
        <Col xs={12} sm={12} md={12} lg={12}>
          {" "}
          <Card className="card_container">
            <div className="card_inside__padding ">
              <div className="d-flex justify-content-between mb-3">
                {/* <Form.Control
                  style={{ flex: 0.5 }}
                  className="satukelas_input"
                  type="text"
                  id="search"
                  name="search"
                  placeholder="Cari Pelatihan"
                  value={search}
                  onKeyDown={keydownHandler}
                  onChange={onChangeSearch}
                /> */}
                {analyticsGroupSubmitPagination ? (
                  <Pagination
                    size={size}
                    totalRows={analyticsGroupSubmitPagination.totalItems}
                    totalPages={analyticsGroupSubmitPagination.totalPages}
                    paginationSelect={paginationSelect}
                    active={active}
                  />
                ) : null}
              </div>
              <Table responsive striped bordered hover className="mb-3">
                <thead>
                  <tr>
                    <th>Nama</th>
                    <th className="w-40">Judul Assignment</th>
                    <th>Submitted at</th>
                    <th className="text-center">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {submittedData?.length > 0 ? (
                    submittedData?.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td className="align-middle">{item.name}</td>
                          <td className="text-nowrap align-middle">
                            {item?.assignment} - {item?.course}
                          </td>
                          <td className="text-nowrap align-middle ">
                            {moment(item?.submitted_at).format("DD MMMM YYYY")}
                          </td>
                          <td className="text-center align-middle">
                            <Button
                              href={item?.student_upload}
                              disabled={!item?.student_upload}
                              className="p-1 btn btn-sm btn-primary manage-button__style"
                            >
                              <FaDownload className=" fill-white size-download" />
                            </Button>

                            <Button
                              className="p-1 btn btn-sm btn-primary manage-button__style-trash"
                              disabled={!item?.student_upload}
                              onClick={() => handleVisit(item?.student_upload)}
                            >
                              <FaRegEye className=" fill-white size-download" />{" "}
                            </Button>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={6} className="text-center">
                        Data Belum Tersedia
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
          </Card>
        </Col>
      </Row>

      {/* {id === 0 ? (
            <>
              {changePage === 0 ? (
                <Form>
                  <div className="modal-manage__task-title">Pelatihan</div>
                  <Form.Group controlId="exampleForm.ControlSelect1">
                    <Form.Control
                      className="modal-manage__task-selector mb-3"
                      as="select"
                    >
                      <option id="1">On Demand</option>
                      <option id="2">Webinar</option>
                    </Form.Control>
                  </Form.Group>
                  <div className="modal-manage__task-title">Pilih Konten</div>
                  <div className="modal-manage__task-filter__content">
                    <Button className="modal-manage__task-filter mb-2">
                      Pelatihan
                    </Button>
                    <Button className="modal-manage__task-filter non-active mb-2">
                      Materi
                    </Button>
                    <Button className="modal-manage__task-filter non-active mb-2">
                      Learning Path
                    </Button>
                    <Button className="modal-manage__task-filter non-active mb-2">
                      Sertifikasi
                    </Button>
                  </div>
                  <div className="modal-manage__task-title">Tipe Materi</div>
                  <Form.Group controlId="exampleForm.ControlSelect1">
                    <Form.Control
                      className="modal-manage__task-selector"
                      as="select"
                    >
                      <option>1</option>
                      <option>2</option>
                      <option>3</option>
                      <option>4</option>
                      <option>5</option>
                    </Form.Control>
                  </Form.Group>
                  <Button
                    onClick={() => handlePage(1)}
                    className="modal-manage__task-button"
                    variant="primary"
                  >
                    Selanjutnya
                  </Button>
                </Form>
              ) : (
                <Form>
                  <div className="modal-manage__task-title">Deadline</div>
                  <Form.Group>
                    <Form.Control
                      className="mb-3 add-input__user"
                      type="date"
                      id="date"
                    />
                  </Form.Group>

                  <Button
                    onClick={handleClose}
                    className="modal-manage__task-button"
                    variant="primary"
                  >
                    Konfirmasi
                  </Button>
                </Form>
              )}
            </>
          ) : id === 1 ? (
            <Form>
              <div className="modal-manage__task-title">Masukkan User Baru</div>

              <Form>
                <Form.Group>
                  <Form.Label className="fw-600"> Nama </Form.Label>{" "}
                  <Form.Control
                    className="mb-3 add-input__user"
                    type="text"
                    id="title"
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label className="fw-600"> Foto </Form.Label>{" "}
                  <Form.Group controlId="formFile" className="mb-3">
                    <Form.Control type="file" />
                  </Form.Group>
                </Form.Group>
                <Form.Group>
                  <Form.Label className="fw-600"> Perusahaan </Form.Label>
                  <Form.Control
                    className="mb-3 add-input__user"
                    type="text"
                    id="thumbnail"
                  />
                </Form.Group>
                <Row className="mb-4">
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Label className="fw-600 "> Email </Form.Label>{" "}
                      <Form.Control
                        className="mb-3 add-input__user"
                        id="description"
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Label className="fw-600"> Password </Form.Label>{" "}
                      <Form.Control
                        className="mb-3 add-input__user"
                        type="password"
                        id="description"
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Button
                  className="modal-manage__task-button"
                  variant="primary"
                  onClick={handleClose}
                >
                  Masukkan
                </Button>
              </Form>
            </Form>
          ) : (
            <Form>
              <div className="modal-manage__task-title">Pilih File</div>

              <Form.Group controlId="formFile" className="mb-3">
                <Form.Control type="file" />
              </Form.Group>

              <Button
                className="modal-manage__task-button"
                variant="primary"
                onClick={handleClose}
              >
                Unggah
              </Button>
            </Form>
          )} */}
      {/* {value?.split(".")[4] === "pdf" ? (
            <>
              {render ? loadPDF() : null}
              <div className="lesson-pdf-control__wrapper">
                <div className="wrap-button">
                  <Button
                    className="lesson-pdf-btn"
                    onClick={() => onClickButton("Prev")}
                  >
                    {`<`}
                  </Button>
                  <Button
                    className="lesson-pdf-btn next"
                    onClick={() => onClickButton("Next")}
                  >
                    {`>`}
                  </Button>
                </div>
                <div className="text-center lesson-pdf-number">
                  Halaman {pageNumber}/{numPages}
                </div>
              </div>
            </>
          ) : ( */}
      {/* )} */}
    </Container>
  );
}

export default StudentHomeWork;
