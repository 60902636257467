import { PromiseService } from "../../services/PromiseService";
import { QUIZ_DATA_LINK } from "../config/QuizConfig";
import {
  //get
  getQuizData,
  getQuizDataSuccess,
  getQuizDataIdSuccess,
  getQuizDataFail,
  //post
  postQuizData,
  postQuizDataSuccess,
  postQuizDataFail,
  // put
  putQuizData,
  putQuizDataSuccess,
  putQuizDataFail,
  // delete
  deleteQuizData,
  deleteQuizDataSuccess,
  deleteQuizDataFail,
} from "../actions/QuizActions";

export const postQuizDataThunk = (data, type) => async (dispatch) => {
  dispatch(postQuizData());
  const body = data;
  const url = type === "random"  ? `${QUIZ_DATA_LINK}/generate` : QUIZ_DATA_LINK 
  try {
    const response = await PromiseService.postData(url, body);
    if (response.status === 201 && response.data) {
      dispatch(postQuizDataSuccess(response.data));
    } else {
      dispatch(postQuizDataFail(response));
    }
  } catch (error) {
    dispatch(postQuizDataFail(error));
  }
};

export const getQuizDataThunk = (data) => async (dispatch) => {
  dispatch(getQuizData());
  if (data) {
    let url = `${QUIZ_DATA_LINK}/${data}`;
    if (data.param) url = `${QUIZ_DATA_LINK}${data.param}`;
    try {
      const response = await PromiseService.getData(url);
      if (response.status === 200 && response.data) {
        if (data.param) dispatch(getQuizDataSuccess(response.data));
        else dispatch(getQuizDataIdSuccess(response.data));
      } else {
        dispatch(getQuizDataFail(response));
      }
    } catch (error) {
      dispatch(getQuizDataFail(error));
    }
  } else {
    try {
      const response = await PromiseService.getData(QUIZ_DATA_LINK);
      if (response.status === 200 && response.data) {
        dispatch(getQuizDataSuccess(response.data));
      } else {
        dispatch(getQuizDataFail(response));
      }
    } catch (error) {
      dispatch(getQuizDataFail(error));
    }
  }
};

// put
export const putQuizDataThunk = (data) => async (dispatch) => {
  dispatch(putQuizData(data));
  const body = data.body;
  const id = data.id;
  try {
    const response = await PromiseService.putData(
      `${QUIZ_DATA_LINK}/${id}`,
      body
    );
    if (response.status === 201 && response.data) {
      dispatch(putQuizDataSuccess(response.data));
    } else {
      dispatch(putQuizDataFail(response));
    }
  } catch (error) {
    dispatch(putQuizDataFail(error));
  }
};

// delete
export const deleteQuizDataThunk = (data) => async (dispatch) => {
  dispatch(deleteQuizData());
  const id = data;
  try {
    const response = await PromiseService.deleteData(`${QUIZ_DATA_LINK}/${id}`);
    if (response.status === 200 && response.data) {
      dispatch(deleteQuizDataSuccess(response.data));
    } else {
      dispatch(deleteQuizDataFail(response));
    }
  } catch (error) {
    dispatch(deleteQuizDataFail(error));
  }
};
