import React, { useState, useEffect } from "react";
import {
  Button,
  Table,
  Modal,
  Row,
  Col,
  Form,
  Dropdown,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { FaEdit } from "react-icons/fa";
import { RiDeleteBin2Line } from "react-icons/ri";
import { VscPreview } from "react-icons/vsc";
import { useDispatch, useSelector } from "react-redux";
import {
  getCoursesDataThunk,
  putCoursesDataThunk,
  deleteCoursesDataThunk,
} from "../redux/thunks/CoursesThunks";
import { removeCourseDataPut } from "../redux/actions/CoursesActions";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import AddCourse from "./AddCourse";
import Pagination from "./Layout/Pagination";
import "../assets/css/Dashboard/index.css";
import { Link } from "react-router-dom";
import { AuthData } from "../helper/auth";
const MySwal = withReactContent(Swal);

function TableCourse({ type }) {
  const [active, setActive] = useState(1);
  const [size, setSize] = useState(10); //pagination size
  const [search, setSearch] = useState(""); //pagination size
  const [coursePagination, setCoursePagination] = useState();
  const [trigger, setTrigger] = useState(false);
  const [statusPublish, setStatusPublish] = useState(0);
  const [courseData, setCourseData] = useState([]);
  const [courseDataId, setCourseDataId] = useState([]);
  const [detailData, setDetailData] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [display, setDisplay] = useState(false);
  const [dateFrom, setDateFrom] = useState("");
  const [dateTo, setDateTo] = useState("");
  const courses = useSelector((state) => state.courses);
  const users = useSelector((state) => state.users);
  const dispatch = useDispatch();

  useEffect(() => {
    if (type === "webinar") {
      dispatch(
        getCoursesDataThunk({
          param: `?course_type=webinar,konsultasi&size=${size}&page=${active}&disable_time_filter=1`,
        })
      );
    } else {
      dispatch(getCoursesDataThunk({ param: `?size=${size}&page=${active}` }));
    }
  }, [type]);

  useEffect(() => {
    setCourseData(courses.courseData.items);
    setCourseDataId(courses.courseDataId);
    setCoursePagination(courses.courseData.pagination);
  }, [courses]);

  useEffect(() => {
    if (courses.courseDataDelete === "error" && trigger) {
      setTrigger(false);
      displayPopup(false, "Terjadi masalah saat menghapus data", "Ok");
    }
    if (courses.courseDataDelete === "success" && trigger) {
      setTrigger(false);
      if (type === "webinar") {
        dispatch(
          getCoursesDataThunk({
            param: `?course_type=webinar,konsultasi&size=${size}&page=${active}&disable_time_filter=1`,
          })
        );
      } else {
        dispatch(
          getCoursesDataThunk({ param: `?size=${size}&page=${active}` })
        );
      }
    }
    if (courses.courseDataPut === "success" && trigger) {
      setTrigger(false);
      if (statusPublish === 0) {
        displayPopup(false, "Sukses Meng-Unpublish Course! ", "Oke");
      } else {
        displayPopup(false, "Sukses Mem-publish Course! ", "Oke");
      }
      dispatch(removeCourseDataPut());
      if (type === "webinar") {
        dispatch(
          getCoursesDataThunk({
            param: `?course_type=webinar,konsultasi&size=${size}&page=${active}&disable_time_filter=1`,
          })
        );
      } else {
        dispatch(
          getCoursesDataThunk({ param: `?size=${size}&page=${active}` })
        );
      }
    }
  }, [courses, type]);

  function paginationSelect(newActive) {
    setActive(newActive);
    if (type === "webinar") {
      dispatch(
        getCoursesDataThunk({
          param: `?course_type=webinar,konsultasi&size=${size}&page=${newActive}&disable_time_filter=1`,
        })
      );
    } else {
      dispatch(
        getCoursesDataThunk({ param: `?size=${size}&page=${newActive}` })
      );
    }
  }

  // useEffect(() => {
  //   console.log("===============================>", courseDataId);
  //   if (courseDataId && display) setModalShow(true);
  // }, [display]);

  const handleDelete = (id) => {
    MySwal.fire({
      title: "Anda yakin ingin menghapus ?",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Iya",
      cancelButtonText: "Tidak",
    }).then((value) => {
      if (value.isConfirmed) {
        setTrigger(true);
        dispatch(deleteCoursesDataThunk(id));
      }
    });
  };
  const handleShow = (
    id_course,
    title,
    summary,
    price,
    lessons_counts,
    course_types,
    index
  ) => {
    const data = {
      id_course,
      title,
      summary,
      price,
      lessons_counts,
      course_types,
      index,
    };
    dispatch(getCoursesDataThunk(id_course));
    setModalShow(true);
    setDetailData(data);
  };
  const handleClose = () => {
    setModalShow(false);
    setDisplay(false);
  };
  // const handleEdit = (data) => {
  //   setTrigger(true);
  //   dispatch(putCoursesData(data));
  //   document
  //     .querySelectorAll(
  //       "#form-edit input, #form-edit select, #form-edit button, #form-edit textarea"
  //     )
  //     .forEach((elem) => (elem.disabled = true));
  // };
  function displayPopup(cancel, title, confirm, id) {
    MySwal.fire({
      title: title,
      showCancelButton: cancel,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: confirm,
    }).then((value) => {
      if (value.isConfirmed && id) {
        if (id.is_published === 1) {
          handleUnpublish(id.course_id);
          setStatusPublish(0);
        } else {
          publishCourse(id.course_id);
          setStatusPublish(1);
        }
      }
    });
  }
  function publishCourse(id) {
    const payload = {
      id: id,
      body: {
        is_published: 1,
      },
    };

    setTrigger(true);
    dispatch(putCoursesDataThunk(payload));
  }
  function handleUnpublish(id) {
    const payloadUnpublish = {
      id: id,
      body: {
        is_published: 0,
      },
    };

    setTrigger(true);
    dispatch(putCoursesDataThunk(payloadUnpublish));
  }
  function keydownHandler(e) {
    if (e.keyCode == 13) {
      setActive(1);
      if (type === "webinar") {
        dispatch(
          getCoursesDataThunk({
            param: `?course_type=webinar,konsultasi&size=${size}&page=${active}${
              search ? "&keyword=" + search : ""
            }&disable_time_filter=1`,
          })
        );
      } else {
        dispatch(
          getCoursesDataThunk({
            param: `?size=${size}&page=${active}${
              search ? "&keyword=" + search : ""
            }`,
          })
        );
      }
    }
  }

  function filterHandler() {
    setActive(1);
    dispatch(
      getCoursesDataThunk({
        param: `?course_type=webinar,konsultasi&size=${size}&page=${active}&date_from=${dateFrom}&date_to=${dateTo}`,
      })
    );

    setDateFrom("");
    setDateTo("");
  }
  function onChangeSearch(e) {
    const newValue = e.target.value;
    setSearch(newValue);
  }
  const renderEditTooltip = (props) => (
    <Tooltip id="edit-button-tooltip" {...props}>
      Edit {type === "webinar" ? "Webinar" : "Pelatihan"}
    </Tooltip>
  );
  const renderDeleteTooltip = (props) => (
    <Tooltip id="delete-button-tooltip-" {...props}>
      Hapus {type === "webinar" ? "Webinar" : "Pelatihan"}
    </Tooltip>
  );
  const renderPreviewTooltip = (props) => (
    <Tooltip id="delete-button-tooltip-" {...props}>
      Lihat {type === "webinar" ? "Webinar" : "Pelatihan"}
    </Tooltip>
  );
  return (
    <>
      <Row>
        <Col xs={12} sm={12} md={6}>
          <div className="pt-2 pb-0 pb-md-3">
            <div className="course_create__title">
              List {type === "webinar" ? "Webinar" : "Pelatihan"}
            </div>
            {/* <h6 className="text-muted fw-400">
              {courseData ? `${courseData.length} Hasil` : null}
            </h6> */}
          </div>
        </Col>
        <Col xs={12} sm={12} md={6} className="text-right align-self-center">
          <Link
            to="/course/create/"
            className=" btn btn-primary add_button py-2 mb-3 mt-1"
          >
            + Tambahkan {type === "webinar" ? "Webinar" : "Pelatihan"}
          </Link>
        </Col>
      </Row>
      <div className=" bg-white br-16 p-6 bx-shdw mb-4">
        <Row className="justify-content-between">
          <div className="col-8">
            <Col xs={12} lg={6} className="mb-3">
              <Form.Control
                style={{ flex: 0.5 }}
                className="satukelas_input"
                type="text"
                id="search"
                name="search"
                placeholder={`Cari ${
                  type === "webinar" ? "Webinar" : "Pelatihan"
                }`}
                value={search}
                onKeyDown={keydownHandler}
                onChange={onChangeSearch}
              />
            </Col>
            <b className="mb-2 px-3 fw-bolder">Filter</b>
            <div className="col-12 px-0 d-flex align-items-center">
              <Col xs={12} lg={4}>
                <Form.Control
                  style={{ flex: 0.5 }}
                  className="satukelas_input"
                  type="date"
                  id="date_from"
                  name="date_from"
                  value={dateFrom}
                  onChange={(e) => setDateFrom(e.target.value)}
                />
              </Col>
              <Col lg={1}>
                <Form.Label className="mb-0">Ke</Form.Label>
              </Col>
              <Col xs={12} lg={4}>
                <Form.Control
                  style={{ flex: 0.5 }}
                  className="satukelas_input"
                  type="date"
                  id="date_to"
                  name="date_to"
                  value={dateTo}
                  onChange={(e) => setDateTo(e.target.value)}
                />
              </Col>
              <Col lg={3}>
                <Button onClick={filterHandler}>Cari</Button>
              </Col>
            </div>
          </div>
          <Col xs={12} lg={4}>
            {coursePagination ? (
              <Pagination
                size={size}
                totalRows={coursePagination.totalItems}
                totalPages={coursePagination.totalPages}
                paginationSelect={paginationSelect}
                active={active}
              />
            ) : null}
          </Col>
        </Row>
        <Table responsive striped bordered hover className="mt-3">
          <thead>
            <tr>
              <th className="w-25">Judul</th>
              <th>Lesson</th>
              <th>Tipe Course</th>
              <th>Author</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {courseData?.length > 0 ? (
              courseData.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>{item.title}</td>
                    <td>{item.lessons_count}</td>
                    <td>{item.course_type}</td>
                    <td>{item.company ? item.company : "Satukelas"}</td>
                    <td>
                      <Dropdown>
                        <Dropdown.Toggle id="dropdown-basic">
                          {item.is_published === 1 ? (
                            <>Published</>
                          ) : (
                            <> Save as draft</>
                          )}
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item
                            className="mb-0"
                            active={item.is_published === 0 ? true : false}
                          >
                            <div
                              onClick={() =>
                                displayPopup(
                                  true,
                                  "Apakah Anda Yakin Untuk Meng-Unpublish Course?",
                                  "Ya",
                                  item
                                )
                              }
                            >
                              Save as draft
                            </div>
                          </Dropdown.Item>
                          <Dropdown.Item
                            className="mb-0"
                            active={item.is_published === 1 ? true : false}
                          >
                            <div
                              disabled={item.is_published}
                              onClick={() =>
                                displayPopup(
                                  true,
                                  "Apakah Anda Yakin Untuk Mem-Publish Course?",
                                  "Ya",
                                  item
                                )
                              }
                            >
                              {item.is_published ? "Published" : "Publish Now"}
                            </div>
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </td>
                    <td className="display-grid">
                      {AuthData().role === "instructor" ||
                      item.company_id === users.usersDataId?.company_id ? (
                        <OverlayTrigger
                          delay={{ show: 250, hide: 400 }}
                          overlay={renderEditTooltip}
                        >
                          <Button
                            className="mb-2 button_edit__size"
                            onClick={() =>
                              handleShow(
                                item.course_id,
                                item.title,
                                item.summary,
                                item.price,
                                item.lessons_count,
                                item.course_type,
                                index
                              )
                            }
                            variant="info"
                          >
                            <FaEdit className="fill-white" />
                          </Button>
                        </OverlayTrigger>
                      ) : null}

                      {item.company_id !== "" ? (
                        <OverlayTrigger
                          delay={{ show: 250, hide: 400 }}
                          overlay={renderDeleteTooltip}
                        >
                          <Button
                            className="btn-remove__course mb-2"
                            variant="danger"
                          >
                            <RiDeleteBin2Line
                              onClick={() => handleDelete(item.course_id)}
                              className="fill-white"
                            />
                          </Button>
                        </OverlayTrigger>
                      ) : null}
                      <>
                        <OverlayTrigger
                          delay={{ show: 250, hide: 400 }}
                          overlay={renderPreviewTooltip}
                        >
                          <Link
                            to={`/dashboard/course/${item.course_id}/`}
                            style={{ textDecoration: "none" }}
                            className="btn-edit__course"
                          >
                            <VscPreview className="fill-white" />
                          </Link>
                        </OverlayTrigger>
                      </>
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={7} className="text-center">
                  Tidak ada pelatihan
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
      <Modal
        show={modalShow}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        style={{ overflow: "auto" }}
      >
        <Modal.Header className="px-4">
          <Modal.Title id="contained-modal-title-vcenter">
            Edit Data
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-4">
          <AddCourse
            id={detailData.id_course}
            companyId={users.usersDataId?.company_id}
            defaultItems={courseDataId}
            handleCancel={handleClose}
            updateData={paginationSelect}
          />
        </Modal.Body>
      </Modal>
    </>
  );
}

export default TableCourse;
