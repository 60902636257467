import { Card } from "react-bootstrap";
import React from "react";
import * as echarts from "echarts";
import ReactECharts from "echarts-for-react";
import "../assets/css/Dashboard/companyAnalytics.css";
import moment from "moment";

function AkumulasiCourse({ analytics }) {
  // define some constanst for chart
  const colorList = [
    [
      {
        offset: 0,
        color: "#80FFA5",
      },
      {
        offset: 1,
        color: "#80FFA5AA",
      },
    ],
    [
      {
        offset: 0,
        color: "#00DDFF",
      },
      {
        offset: 1,
        color: "#00DDFFAA",
      },
    ],
    [
      {
        offset: 0,
        color: "#37A2FF",
      },
      {
        offset: 1,
        color: "#37A2FFAA",
      },
    ],
    [
      {
        offset: 0,
        color: "#FF0087",
      },
      {
        offset: 1,
        color: "#FF0087AA",
      },
    ],
    [
      {
        offset: 0,
        color: "#316DF9",
      },
      {
        offset: 1,
        color: "#316DF9AA",
      },
    ],
    [
      {
        offset: 0,
        color: "#3FBB3C",
      },
      {
        offset: 1,
        color: "#3FBB3CAA",
      },
    ],
    [
      {
        offset: 0,
        color: "#FFC825",
      },
      {
        offset: 1,
        color: "#FFC825AA",
      },
    ],
    [
      {
        offset: 0,
        color: "#F94151",
      },
      {
        offset: 1,
        color: "#F94151AA",
      },
    ],
  ];

  // get date of nth week
  const generateWeekDate = (analytics, i) => {
    return moment(analytics.analyticsCompletedData.data.Konsultasi?.datelist[i].start).format('MMM D')
  }

  // get array of nth week with date
  const generateWeeklist = (analytics) => {
    const result = [];
    for (let i = 0; i < 8; i++) {
      const e = `Week ${analytics.analyticsCompletedData.data?.Konsultasi?.weeklist[i]}, ${generateWeekDate(analytics, i)}`
      result.push(e)
    }
    return result;
  }
  
  // initeate chart
  function dataCharts() {
    const series = [];
    
    const weeklist = generateWeeklist(analytics);
    for (
      let i = 0;
      i < analytics.analyticsCompletedData?.course_types?.length;
      i++
    ) {
      const key = analytics.analyticsCompletedData?.course_types[i];
      series.push({
        name: key,
        type: "line",
        stack: "Total",
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, colorList[i]),
        },
        emphasis: {
          focus: "series",
        },
        data: analytics.analyticsCompletedData.data[key]?.counts,
      });
    }
    return {
      color: [
        "#80FFA5",
        "#00DDFF",
        "#37A2FF",
        "#FF0087",
        "#316DF9",
        "#3FBB3C",
        "#FFC825",
        "#F94151",
      ],
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "cross",
          label: {
            backgroundColor: "#6a7985",
          },
        },
      },
      legend: {
        data: analytics.analyticsCompletedData?.course_types,
      },
      grid: {
        left: "3%",
        right: "4%",
        bottom: "3%",
        containLabel: true,
      },
      xAxis: [
        {
          type: "category",
          boundaryGap: false,
          data: weeklist,
        },
      ],
      yAxis: [
        {
          type: "value",
          axisLabel: { show: false },
        },
      ],
      series: series,
    };
  }
  
  return (
    <>
      <Card className="card_container">
        <div className="card_inside__padding ">
          <div className="d-flex justify-content-between align-items-center border_card">
            <div className="card_title__info mb-1">
              Akumulasi Course Selesai
            </div>
          </div>
          {analytics.analyticsCompletedData?.data ? (
            <ReactECharts style={{ height: "500px" }} option={dataCharts()} />
          ) : null}
        </div>
      </Card>
    </>
  );
}

export default AkumulasiCourse;
