import React from "react";
import { ListGroup, Card } from "react-bootstrap";
import { FaCheckCircle } from "react-icons/fa";
import "../assets/css/Courses/CardBenefit.css";
function BenefitCard({ items }) {
  return (
    <>
      <div className="course_front__kurikulum_title">Tujuan Pembelajaran</div>
      <Card className="course_front__benefit_container">
        <ListGroup>
          {items?.length > 0 ? (
            items.map((e, i) => (
              <div
                key={i}
                className="d-flex align-items-start course_front__benefit_item__wrapper"
              >
                <FaCheckCircle className="mr-2 course_front__benefit_icon" />
                <div className="course_front__benefit_item">
                  <div
                    key={i}
                    className="d-flex align-items-start course_front__benefit_item__wrapper"
                  >
                    {/* <span className="icon-check green-check"></span> */}
                    <div className="course_front__benefit_item">{e}</div>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className="benefit_card__course mb-0">Data belum tersedia</div>
          )}
        </ListGroup>
      </Card>
    </>
  );
}

export default BenefitCard;
