export const env = process.env.REACT_APP_ENV
  ? process.env.REACT_APP_ENV
  : "development";

export const ENDPOINT =
  env === "development"
    ? "https://api.satukelas.space/api/"
    : "https://api.satukelas.com/api/";


export const CORPORATE_MATERIALS_ENDPOINT =
  "https://satukelas-corporate-materials.ap-south-1.linodeobjects.com/";

export const certificateTemplateInput =
  env === "development"
    ? ["ct41731283", "ct47515291", "ct08103261"]
    : ["ct10000001", "ct10000006", "ct10000007"];


export const template_name = [
  {
    type: "Pelatihan",
    id: env === "development" ? "ct41731283" : "ct10000001",
    list: [
      {
        id: 1,
        name: "Partisipan Kursus - HR",
        url: "https://satukelas-certificate-templates.ap-south-1.linodeobjects.com/2760040856202885.jpg",
        coord_id: 3,
        color_code: "#311D5C",
      },
      {
        id: 2,
        name: "Partisipan Kursus - Marketing",
        url: "https://satukelas-certificate-templates.ap-south-1.linodeobjects.com/2541123054085770.jpg",
        coord_id: 1,
        color_code: "#165D8C",
      },
      {
        id: 3,
        name: "Partisipan Kursus - Pajak",
        url: "https://satukelas-certificate-templates.ap-south-1.linodeobjects.com/1407880405956564.jpg",
        coord_id: 3,
        color_code: "#342E0A",
      },
      {
        id: 4,
        name: "Partisipan Kursus - Softskill",
        url: "https://satukelas-certificate-templates.ap-south-1.linodeobjects.com/1407880405956564.jpg",
        coord_id: 3,
        color_code: "#472A22",
      },
    ],
  },
  {
    type: "Webinar",
    id: env === "development" ? "ct08103261" : "ct10000007",
    list: [
      {
        name: "Partisipan Webinar - HR",
        url: "https://satukelas-certificate-templates.ap-south-1.linodeobjects.com/1729534915608989.jpg",
        coord_id: 3,
        color_code: "#311D5C",
      },
      {
        name: "Partisipan Webinar - Marketing",
        url: "https://satukelas-certificate-templates.ap-south-1.linodeobjects.com/2242431808998801.jpg",
        coord_id: 3,
        color_code: "#165D8C",
      },
      {
        name: "Partisipan Webinar - Pajak",
        url: "https://satukelas-certificate-templates.ap-south-1.linodeobjects.com/1520444081982612.jpg",
        coord_id: 3,
        color_code: "#342E0A",
      },
      {
        name: "Partisipan Webinar - Softskill",
        url: "https://satukelas-certificate-templates.ap-south-1.linodeobjects.com/6459773719042985.jpg",
        coord_id: 3,
        color_code: "#472A22",
      },
    ],
  },
  {
    type: "Sertifikasi",
    id: env === "development" ? "ct47515291" : "ct10000006",
    list: [
      {
        name: "Preview Kompetensi - HR",
        url: "https://satukelas-certificate-templates.ap-south-1.linodeobjects.com/1177110410788600.jpg",
        coord_id: 2,
        color_code: "#311D5C",
      },
      {
        name: "Preview Kompetensi - Marketing",
        url: "https://satukelas-certificate-templates.ap-south-1.linodeobjects.com/2531967089206264.jpg",
        coord_id: 1,
        color_code: "#165D8C",
      },
      {
        name: "Preview Kompetensi - Pajak",
        url: "https://satukelas-certificate-templates.ap-south-1.linodeobjects.com/2648519944832012.jpg",
        coord_id: 2,
        color_code: "#342E0A",
      },
      {
        name: "Preview Kompetensi - Softskill",
        url: "https://satukelas-certificate-templates.ap-south-1.linodeobjects.com/7975297701796717.jpg",
        coord_id: 2,
        color_code: "#472A22",
      },
    ],
  },
];

export const coord_certificate = [
  {
    id: 1,
    coord: {
      course_coord_x: 1291,
      course_coord_y: 1360,
      date_coord_x: 1564,
      date_coord_y: 2173,
      dynamic_logo: 0,
      name_coord_x: 1291,
      name_coord_y: 1013,
      score_coord_x: 1893,
      score_coord_y: 1690,
    },
  },
  {
    id: 2,
    coord: {
      course_coord_x: 1291,
      course_coord_y: 1360,
      date_coord_x: 1560,
      date_coord_y: 1974,
      name_coord_x: 1291,
      name_coord_y: 1013,
      score_coord_x: 1893,
      score_coord_y: 1715,
    },
  },
  {
    id: 3,
    coord: {
      course_coord_x: 1291,
      course_coord_y: 1396,
      date_coord_x: 1551,
      date_coord_y: 1974,
      dynamic_logo: 0,
      name_coord_x: 1291,
      name_coord_y: 1031,
      score_coord_x: 1893,
      score_coord_y: 1770,
    },
  },
  {
    id: 4,
    coord: {
      course_coord_x: 1210,
      course_coord_y: 1295,
      date_coord_x: 1485,
      date_coord_y: 1890,
      dynamic_logo: 0,
      name_coord_x: 1220,
      name_coord_y: 930,
      score_coord_x: 2617,
      score_coord_y: 1727,
    },
  },
];

export const learning_paths = [
  {
    title: "Brevet AB",
    total_course: 12,
  },
  {
    title: "Brevet C",
    total_course: 20,
  },
  {
    title: "Mari Hitung Lapor",
    total_course: 8,
  },
];
export const devlearning_paths = [
  {
    title: "IT Path Korporasi",
  },
  {
    title: "Managerial Skill & Leadership Improvement",
  },
  {
    title: "Public Speaker Path",
  },
];
export const course_list = [
  {
    title: "Transfer Pricing (Transaksi Hubungan Istimewa)",
    category: "Keuangan",
    partisipan: "56",
    start_date: "2021/05/01",
    link: "https://student.satukelas.com/course/transfer-pricing-transaksi-hubungan-istimewa/co35986451/",
  },
  {
    title: "Tetap Produktif di New Normal",
    category: "Keuangan",
    partisipan: "66",
    start_date: "2021/05/01",
    link: "https://student.satukelas.com/course/tetap-produktif-di-new-normal/co99518850/",
  },
  {
    title: "Teknik Penyusunan Transfer Pricing Document",
    category: "Keuangan",
    partisipan: "65",
    start_date: "2021/05/01",
    link: "https://student.satukelas.com/course/teknik-penyusunan-transfer-pricing-document/co19600347/",
  },
  {
    title: "Tax Planning PPh Orang Pribadi",
    category: "Keuangan",
    partisipan: "30",
    start_date: "2021/05/01",
    link: "https://student.satukelas.com/course/tax-planning-pph-orang-pribadi/co74085056/",
  },
  {
    title: "Tax Planning PPh Badan 2",
    category: "Keuangan",
    partisipan: "12",
    start_date: "2021/05/01",
    link: "https://student.satukelas.com/course/tax-planning-pph-badan-2/co53910980/",
  },
  {
    title: "Tax Planning PPh Badan 1",
    category: "Keuangan",
    partisipan: "65",
    start_date: "2021/05/01",
    link: "https://student.satukelas.com/course/tax-planning-pph-badan-1/co20518187/",
  },
];

export const gradients = [
  { start: "rgba(128, 255, 165)", end: "rgba(1, 191, 236)" },
  { start: "rgba(0, 255, 165)", end: "rgba(1, 191, 236)" },
  { start: "rgba(128, 0, 165)", end: "rgba(1, 191, 236)" },
  { start: "rgba(128, 255, 0)", end: "rgba(1, 191, 236)" },
  { start: "rgba(0, 0, 165)", end: "rgba(1, 191, 236)" },
  { start: "rgba(0, 255, 0)", end: "rgba(1, 191, 236)" },
  { start: "rgba(128, 128, 165)", end: "rgba(1, 191, 236)" },
];

export const webinar_list = [
  { webinar_title: "Aspek perpajakan pada Industri Pembiayaan" },
  {
    webinar_title:
      "Efective melakukan pengujian kewajaran dan kelaziman Transfer Pricing",
  },
  {
    webinar_title:
      "Selling with Happiness - Optimizing Your Selling Skill with NLP",
  },
  {
    webinar_title:
      "Comperehensif Tax Management yang lebih Update Regulasi dan loopholes",
  },
  { webinar_title: "Magic of Neuro Selling" },
  { webinar_title: "Design Thinking Fast" },
  {
    webinar_title:
      "Apakah Ethic Menjadi Tolak Ukur Profesionalisme dan Leadership?",
  },
  {
    webinar_title:
      "Happy and Productive: Pengembangan Mindset Untuk Meningkat Produktifitas",
  },
  {
    webinar_title:
      "PSAK Berbasis IFRS -PSAK 10 (IAS 21) The Effects of Changes in Foreign Exch",
  },
  {
    webinar_title:
      "PSAK Berbasis IFRS -PSAK 8 (IAS 10) Even After Reporting Period.",
  },
];
export const webinar = [
  {
    webinar_title: "Aspek perpajakan pada Industri Pembiayaan",
    status_webinar: "Sudah Berakhir",
  },
  {
    webinar_title:
      "Happy and Productive: Pengembangan Mindset Untuk Meningkat Produktifitas",
    status_webinar: "Akan Datang",
  },
  {
    webinar_title: "Magic of Neuro Selling",
    status_webinar: "Akan Datang",
  },
  {
    webinar_title: "Design Thinking Fast",
    status_webinar: "Akan Datang",
  },
  {
    webinar_title:
      "PSAK Berbasis IFRS -PSAK 8 (IAS 10) Even After Reporting Period.",
    status_webinar: "Sudah Berakhir",
  },
];
