import React, { useEffect, useState } from "react";
import {
  Table,
  Container,
  Row,
  Col,
  Button,
  Modal,
} from "react-bootstrap";
import { getAnalyticsUserDataThunk } from "../redux/thunks/AnalyticsUserThunks";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import SkModal from "./common/SkModal";
import "../assets/css/Dashboard/companyAnalytics.css";
import MateriCompany from "./MateriCompany";
import FollowingWebinar from "./FollowingWebinar";
import FollowingConsultation from "./FollowingConsultation";
import FollowingTryout from "./FollowingTryout";
import FollowingCertification from "./FollowingCertification";
import DetailStudent from "./DetailStudent";
import FollowingCourse from "./FollowingCourse";
import AkumulasiLessonStudent from "./AkumulasiLessonStudent";
import StatusPostTestUsers from "./StatusPostTestUsers";
import StatusPreTestUsers from "./StatusPreTestUsers";

function DetailEmployee() {
  const dispatch = useDispatch();
  const [modalContent, setModalContent] = useState({
    title: "Modal Title",
    show: false,
    componentBody: "",
  });
  const [dataUserAnalytics, setDataUserAnalytics] = useState([]);
  const [modalDetail, setModalDetail] = useState(false);
  let address = useLocation();
  const analyticsuser = useSelector((state) => state.analyticsuser);
  let splitted = address.pathname.split("/");
  useEffect(() => {
    dispatch(getAnalyticsUserDataThunk({ param: `?user_id=${splitted[3]}` }));
  }, []);
  useEffect(() => {
    setDataUserAnalytics(analyticsuser.analyticsUserData);
  });
  return (
    <Container>
      <Link to="/dashboard/analytic" className="mb-3">
        Halaman Sebelumnya
      </Link>
      <Row>
        <Col md={4} className="mb-4">
          <DetailStudent data={dataUserAnalytics} />
        </Col>
        <Col md={8} className="mb-4">
          {" "}
          <AkumulasiLessonStudent data={dataUserAnalytics} />
        </Col>
        <Col md={4} className="mb-4">
          <FollowingCourse data={dataUserAnalytics} />
        </Col>
        <Col md={4} className="mb-4">
          <FollowingCertification data={dataUserAnalytics} />
        </Col>
        <Col md={4} className="mb-4">
          <FollowingTryout
            data={dataUserAnalytics}
            tryOut={analyticsuser?.analyticsUserData?.stats_tryout}
          />
        </Col>
        <Col md={6} className="mb-4">
          <FollowingWebinar data={dataUserAnalytics} />
        </Col>
        <Col md={6} className="mb-4">
          <FollowingConsultation data={dataUserAnalytics} />
        </Col>
        {/* <Col md={5} className="mb-4">
          <DataTryout />
        </Col>
        <Col md={7} className="mb-4">
          <DataCourse />
        </Col> */}
        <Col md={12} className="mb-4">
          <MateriCompany data={dataUserAnalytics} />
        </Col>

        <Col md={6} className="mb-4">
          {" "}
          <StatusPreTestUsers data={dataUserAnalytics} />
        </Col>

        <Col md={6} className="mb-4">
          <StatusPostTestUsers data={dataUserAnalytics} />
        </Col>

        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          scrollable={true}
          show={modalDetail}
          onHide={() => setModalDetail(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title>Detail Informasi</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Table responsive striped hover>
              <thead>
                <th className="pl-0">Mulai</th>
                <th className="pl-0">Terakhir Akses</th>
                <th>Nilai Pre Test</th>
                <th>Nilai Post Test</th>
              </thead>
            </Table>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setModalDetail(!modalDetail)}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        <SkModal content={modalContent} />
      </Row>
    </Container>
  );
}

export default DetailEmployee;

function option() {
  return {
    tooltip: {
      trigger: "axis",
    },
    legend: {
      data: ["Pelatihan yang Selesai"],
    },
    grid: {
      left: "3%",
      right: "4%",
      bottom: "3%",
      containLabel: true,
    },

    xAxis: {
      type: "category",
      boundaryGap: false,
      data: [
        "Week 1",
        "Week 2",
        "Week 3",
        "Week 4",
        "Week 5",
        "Week 6",
        "Week 7",
        "Week 8",
      ],
    },
    yAxis: {
      type: "value",
    },
    series: [
      {
        name: "Pelatihan yang Selesai",
        type: "line",
        stack: "Total",
        // data: [120, 132, 101, 134, 90, 230, 210],
        data: [0],
      },
    ],
  };
}

let xAxisData = [
  "Week 1",
  "Week 2",
  "Week 3",
  "Week 4",
  "Week 5",
  "Week 6",
  "Week 7",
  "Week 8",
];
let data1 = [0];
let data2 = [0];
var emphasisStyle = {
  itemStyle: {
    shadowBlur: 10,
    shadowColor: "rgba(0,0,0,0.3)",
  },
};

// for (let i = 0; i < 12; i++) {
//   data1.push(+(Math.random() * 2).toFixed(2));
//   data2.push(+(Math.random() * 5).toFixed(2));
// }
function option3() {
  return {
    legend: {
      // data: ["Lulus", "Tidak Lulus"],
      data: ["Belum ada data"],
      // left: "10%",
    },
    // brush: {
    //   toolbox: ["rect", "polygon", "lineX", "lineY", "keep", "clear"],
    //   xAxisIndex: 0,
    // },
    // toolbox: {
    //   feature: {
    //     magicType: {
    //       type: ["stack"],
    //     },
    //     dataView: {},
    //   },
    // },
    // tooltip: {
    //   trigger: "axis",
    // },
    xAxis: {
      data: xAxisData,
      // axisLine: { onZero: true },
      // splitLine: { show: false },
      // splitArea: { show: false },
    },
    yAxis: {},
    grid: {
      bottom: 100,
    },
    series: [
      {
        name: "Lulus",
        type: "bar",
        stack: "one",
        emphasis: emphasisStyle,
        data: data1,
        color: "#91CC75",
      },
      {
        name: "Tidak Lulus",
        type: "bar",
        stack: "one",
        emphasis: emphasisStyle,
        data: data2,
        color: "#EE4253",
      },
    ],
  };
}
