import React, { useState, useEffect } from "react";
import { Table, Modal, Row, Col, Card, Form, Button } from "react-bootstrap";
import { FaEdit } from "react-icons/fa";
import { RiDeleteBin2Line } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import {
  getLearningPathDataThunk,
  deleteLearningPathDataThunk,
} from "../redux/thunks/LearningPathThunks";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import AddLearningPath from "./AddLearningPath";
import Pagination from "./Layout/Pagination";
import "../assets/css/Dashboard/index.css";
import { AuthData } from "../helper/auth";
const MySwal = withReactContent(Swal);

function TableLearningPath({ showModal }) {
  const [active, setActive] = useState(1);
  const [size, setSize] = useState(10); //pagination size
  const [learningPathPagination, setLearningPathPagination] = useState();
  const [trigger, setTrigger] = useState(false);
  const [search, setSearch] = useState(""); //pagination size
  const [learningPathData, setLearningPathData] = useState([]);
  const [learningPathDataId, setLearningPathDataId] = useState([]);
  const [detailData, setDetailData] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [display, setDisplay] = useState(false);
  const learning_paths = useSelector((state) => state.learning_paths);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      getLearningPathDataThunk({ param: `?size=${size}&page=${active}` })
    );
  }, []);
  useEffect(() => {
    setLearningPathData(learning_paths.learningPathDataGet.items);
    setLearningPathDataId(learning_paths.learningPathDataGetId);
    setLearningPathPagination(learning_paths.learningPathDataGet.pagination);
  }, [learning_paths]);

  useEffect(() => {
    if (learning_paths.learningPathDataDelete === "error" && trigger) {
      setTrigger(false);
      displayPopup(false, "Terjadi masalah saat submit data", "Ok");
    }
    if (learning_paths.learningPathDataDelete === "success" && trigger) {
      setTrigger(false);
      dispatch(
        getLearningPathDataThunk({ param: `?size=${size}&page=${active}` })
      );
    }
  }, [learning_paths]);

  function paginationSelect(newActive) {
    setActive(newActive);
    dispatch(
      getLearningPathDataThunk({ param: `?size=${size}&page=${newActive}` })
    );
  }
  useEffect(() => {
    if (showModal) {
      setModalShow(showModal);
    }
  }, [showModal]);

  // useEffect(() => {
  //   console.log("===============================>", learningPathDataId);
  //   if (learningPathDataId && display) setModalShow(true);
  // }, [display]);

  const handleDelete = (id) => {
    MySwal.fire({
      title: "Anda yakin ingin menghapus ?",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Iya",
      cancelButtonText: "Tidak",
    }).then((value) => {
      if (value.isConfirmed) {
        setTrigger(true);
        dispatch(deleteLearningPathDataThunk(id));
      }
    });
  };
  const handleShow = (path_id, title, summary, description, index) => {
    const data = {
      path_id,
      title,
      summary,
      description,
      index,
    };
    dispatch(getLearningPathDataThunk(path_id));
    setModalShow(true);
    setDetailData(data);
  };
  const handleClose = () => {
    setModalShow(false);
    setDisplay(false);
  };
  // const handleEdit = (data) => {
  //   setTrigger(true);
  //   dispatch(putLearningPathData(data));
  //   document
  //     .querySelectorAll(
  //       "#form-edit input, #form-edit select, #form-edit button, #form-edit textarea"
  //     )
  //     .forEach((elem) => (elem.disabled = true));
  // };
  function displayPopup(cancel, title, confirm) {
    MySwal.fire({
      title: title,
      showCancelButton: cancel,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: confirm,
    }).then((value) => { });
  }
  function keydownHandler(e) {
    if (e.keyCode == 13) {
      setActive(1);
      dispatch(
        getLearningPathDataThunk({
          param: `?size=${size}&page=1${search ? "&keyword=" + search : ""}`,
        })
      );
    }
  }
  function onChangeSearch(e) {
    const newValue = e.target.value;
    setSearch(newValue);
  }
  return (
    <>
      <Card className="card_container mb-4">
        <div className="card_inside__padding ">
          <Row>
            <Col xs={12} lg={5} className="mb-3">
              <Form.Control
                style={{ flex: 0.5 }}
                className="satukelas_input"
                type="text"
                id="search"
                name="search"
                placeholder="Cari Pelatihan"
                value={search}
                onKeyDown={keydownHandler}
                onChange={onChangeSearch}
              />
            </Col>
            <Col xs={12} lg={5} className="offset-lg-2">
              {learningPathPagination ? (
                <Pagination
                  size={size}
                  totalRows={learningPathPagination.totalItems}
                  totalPages={learningPathPagination.totalPages}
                  paginationSelect={paginationSelect}
                  active={active}
                />
              ) : null}
            </Col>
          </Row>
          <Table responsive striped bordered hover>
            <thead>
              <tr>
                <th className="w-25">Judul</th>
                <th>Summary</th>
                <th>Oleh</th>
                <th>Deskripsi</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {learningPathData?.length > 0
                ? learningPathData.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{item.title}</td>
                      <td>{item.summary} </td>
                      <td> PT. Demo </td>
                      <td>{item.description}</td>
                      <td>
                        {AuthData()?.role === "instructor" && (
                          <>
                            <Button
                              onClick={() =>
                                handleShow(
                                  item.path_id,
                                  item.title,
                                  item.summary,
                                  item.description,
                                  index
                                )
                              }
                              variant="info"
                              className="mr-2 btn-edit__course"
                            >
                              <FaEdit className="fill-white" />
                            </Button>
                            <Button
                              variant="danger"
                              className="btn-remove__course"
                            >
                              <RiDeleteBin2Line
                                onClick={() => handleDelete(item.path_id)}
                                className="fill-white"
                              />
                            </Button>
                          </>
                        )}
                      </td>
                    </tr>
                  );
                })
                : null}
            </tbody>
          </Table>
        </div>
      </Card>

      <Modal
        show={modalShow}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        style={{ overflow: "auto" }}
      >
        <Modal.Header className="p-4">
          <Modal.Title id="contained-modal-title-vcenter">
            Edit Data
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-4">
          <AddLearningPath
            id={detailData.path_id}
            defaultItems={learningPathDataId}
            handleCancel={handleClose}
          />
        </Modal.Body>
      </Modal>
    </>
  );
}

export default TableLearningPath;
