import React, { useEffect } from "react";
import {
  Table,
  ListGroup,
  Container,
  Card,
  Row,
  Col,
  ProgressBar,
} from "react-bootstrap";
import ReactECharts from "echarts-for-react";
import {  FaStar } from "react-icons/fa";
import { AuthIsLogin } from "../../helper/auth";
import { useHistory } from "react-router-dom";
import "../../assets/css/Dashboard/index.css";

function Statistik() {
  const history = useHistory();
  useEffect(() => {
    AuthIsLogin(history, true);
  }, []);
  return (
    <Container fluid>
      <Row>
        <Col md={12} className="mb-4">
          <Card className="banner_card">
            <div className="card_inside__padding ">
              <Row>
                <Col xl={3} lg={2} className="text-center">
                  {/* <img
                    src="assets/images/pngs/profit.png"
                    alt="img"
                    className="w-95"
                  /> */}
                </Col>
                <Col xl={9} lg={10} className="pl-lg-0">
                  <Row>
                    <Col xl={7} lg={6}>
                      <div className="text-left text-white mt-xl-4">
                        <h3 className="font-weight-semibold">Halo Reza</h3>
                        <h4 className="font-weight-normal">
                          Ini adalah dashboard-overview
                        </h4>
                        <p className="mb-lg-0 text-white-50">
                          kesimpulan dari semua aktifitas belajar karyawan anda
                          ada disini, anda juga bisa langsung menekan tombol
                          dikanan atas untuk menambahkan user, report cepat, dll
                          atau klik tombol disamping untuk rinciannya
                        </p>
                      </div>
                    </Col>
                    <Col xl={5} lg={6} className="text-lg-center mt-xl-4">
                      <h5 className="font-weight-semibold mb-1 text-white">
                        Anda adalah admin dari
                      </h5>
                      <h1 className="mb-4 fw-800 text-white">
                        PT. Berkah Sumber Rahayu
                      </h1>
                      <div className="btn-list mb-xl-0"></div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </Card>
        </Col>
        <Col lg={3} className="mb-4">
              <Card className="card_container">
                <div className="card_inside__padding ">
                  <Row>
                    <Col>
                      <div className="dash1">
                        <p className="mb-2">Total Students</p>
                        <h3 className="mb-1 number-font fw-700">83</h3>
                        {/* <span className="fs-12 text-muted">
                          <i className="fe fe-trending-up"></i>{" "}
                          <strong> 2.6%</strong>{" "}
                          <span className="text-muted fs-12 ml-0 mt-1">
                            than last week
                          </span>
                        </span> */}
                      </div>
                    </Col>
                    <Col md="auto">
                      <p className="data-attributes mb-0 mt-3">
                        <svg className="peity" height="48" width="48">
                          <path
                            d="M 24 0 A 24 24 0 1 1 0.6017301076362322 29.340502414951548 L 4.501441756363526 28.45041867912629 A 20 20 0 1 0 24 4"
                            fill="#ec5444"
                          ></path>
                          <path
                            d="M 0.6017301076362322 29.340502414951548 A 24 24 0 0 1 23.999999999999996 0 L 23.999999999999996 4 A 20 20 0 0 0 4.501441756363526 28.45041867912629"
                            fill="#e3e8f7"
                          ></path>
                        </svg>
                      </p>
                    </Col>
                  </Row>
                </div>
              </Card>
            </Col>
            <Col lg={3} className="mb-4">
              <Card className="card_container">
                <div className="card_inside__padding ">
                  <Row>
                    <Col>
                      <div className="dash1">
                        <p className="mb-2">Active Time</p>
                        <h3 className="mb-1 number-font fw-700">1500 hours</h3>
                        <span className="fs-12 text-muted">
                          <i className="fe fe-trending-up"></i>{" "}
                          <strong> 2.6%</strong>{" "}
                          <span className="text-muted fs-12 ml-0 mt-1">
                            than last week
                          </span>
                        </span>
                      </div>
                    </Col>
                    <Col md="auto">
                      <p className="data-attributes mb-0 mt-3">
                        <svg className="peity" height="48" width="48">
                          <path
                            d="M 24 0 A 24 24 0 1 1 0.6017301076362322 29.340502414951548 L 4.501441756363526 28.45041867912629 A 20 20 0 1 0 24 4"
                            fill="#ec5444"
                          ></path>
                          <path
                            d="M 0.6017301076362322 29.340502414951548 A 24 24 0 0 1 23.999999999999996 0 L 23.999999999999996 4 A 20 20 0 0 0 4.501441756363526 28.45041867912629"
                            fill="#e3e8f7"
                          ></path>
                        </svg>
                      </p>
                    </Col>
                  </Row>
                </div>
              </Card>
            </Col>
            <Col lg={3} className="mb-4">
              <Card className="card_container">
                <div className="card_inside__padding ">
                  <Row>
                    <Col>
                      <div className="dash1">
                        <p className="mb-2">Passed Certification</p>
                        <h3 className="mb-1 number-font fw-700">13</h3>
                        <span className="fs-12 text-muted">
                          {/* <i className="fe fe-trending-up"></i>{" "}
                          <strong> 2.6%</strong>{" "}
                          <span className="text-muted fs-12 ml-0 mt-1">
                            than last week
                          </span> */}
                        </span>
                      </div>
                    </Col>
                    <Col md="auto">
                      <p className="data-attributes mb-0 mt-3">
                        <svg className="peity" height="48" width="48">
                          <path
                            d="M 24 0 A 24 24 0 1 1 0.6017301076362322 29.340502414951548 L 4.501441756363526 28.45041867912629 A 20 20 0 1 0 24 4"
                            fill="#ec5444"
                          ></path>
                          <path
                            d="M 0.6017301076362322 29.340502414951548 A 24 24 0 0 1 23.999999999999996 0 L 23.999999999999996 4 A 20 20 0 0 0 4.501441756363526 28.45041867912629"
                            fill="#e3e8f7"
                          ></path>
                        </svg>
                      </p>
                    </Col>
                  </Row>
                </div>
              </Card>
            </Col>
        {[...Array(1)].map((item, i) => {
          return (
            <Col key={i} lg={3} className="mb-4">
              <Card className="card_container">
                <div className="card_inside__padding ">
                  <Row>
                    <Col>
                      <div className="dash1">
                        <p className="mb-2">Total Enrolled Course</p>
                        <h3 className="mb-1 number-font fw-700">124</h3>
                        <span className="fs-12 text-muted">
                          <i className="fe fe-trending-up"></i>{" "}
                          <strong> 27%</strong>{" "}
                          <span className="text-muted fs-12 ml-0 mt-1">
                            than last week
                          </span>
                        </span>
                      </div>
                    </Col>
                    <Col md="auto">
                      <p className="data-attributes mb-0 mt-3">
                        <svg className="peity" height="48" width="48">
                          <path
                            d="M 24 0 A 24 24 0 1 1 0.6017301076362322 29.340502414951548 L 4.501441756363526 28.45041867912629 A 20 20 0 1 0 24 4"
                            fill="#ec5444"
                          ></path>
                          <path
                            d="M 0.6017301076362322 29.340502414951548 A 24 24 0 0 1 23.999999999999996 0 L 23.999999999999996 4 A 20 20 0 0 0 4.501441756363526 28.45041867912629"
                            fill="#e3e8f7"
                          ></path>
                        </svg>
                      </p>
                    </Col>
                  </Row>
                </div>
              </Card>
            </Col>
          );
        })}
        <Col md={8} className="mb-4">
          <Card className="card_container">
            <div className="card_inside__padding ">
              <div className="d-flex justify-content-between border_card">
                <div className="card_subtitle mb-4">
                  Learners with Time Sent Monthlywise
                </div>
              </div>

              <ReactECharts option={option2()} />
            </div>
          </Card>
        </Col>
        <Col md={4} className="mb-4">
          <Card className="card_container">
            <div className="card_inside__padding ">
              <div className="d-flex justify-content-between border_card">
                <div className="card_subtitle mb-4">Course Progress</div>
              </div>
              <ReactECharts option={option()} />
            </div>
          </Card>
        </Col>
        <Col md={4} className="mb-4">
          <Card className="card_container">
            <div className="card_inside__padding ">
              <div className="d-flex justify-content-between border_card">
                <div className="card_subtitle mb-4">Popular Courses</div>
              </div>

              <Table responsive>
                <tr>
                  <td>
                    <div className="software-cat">
                    </div>
                  </td>
                  <td>
                    <h6 className="mb-1 font-weight-semibold">Pph 21</h6>
                  </td>
                  <td className="text-center">
                    <span className="fs-12 text-yellow">
                      <FaStar className="size" />
                      <FaStar className="size" />
                      <FaStar className="size" />
                      <FaStar className="size" />
                      <FaStar className="size" />
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className="software-cat">
                    </div>
                  </td>
                  <td>
                    <h6 className="mb-1 font-weight-semibold">Budgeting – A Forecasting Technique</h6>
                  </td>
                  <td className="text-center">
                    <span className="fs-12 text-yellow">
                      <FaStar className="size" />
                      <FaStar className="size" />
                      <FaStar className="size" />
                      <FaStar className="size" />
                      <FaStar className="size" />
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className="software-cat">
                    </div>
                  </td>
                  <td>
                    <h6 className="mb-1 font-weight-semibold">Pelatihan Dasar Management</h6>
                  </td>
                  <td className="text-center">
                    <span className="fs-12 text-yellow">
                      <FaStar className="size" />
                      <FaStar className="size" />
                      <FaStar className="size" />
                      <FaStar className="size" />
                      <FaStar className="size" />
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className="software-cat">
                    </div>
                  </td>
                  <td>
                    <h6 className="mb-1 font-weight-semibold">Fixed & Intangible Asset Accounting</h6>
                  </td>
                  <td className="text-center">
                    <span className="fs-12 text-yellow">
                      <FaStar className="size" />
                      <FaStar className="size" />
                      <FaStar className="size" />
                      <FaStar className="size" />
                      <FaStar className="size" />
                    </span>
                  </td>
                </tr>
                {[...Array(1)].map((item, i) => {
                  return (
                    <tr key={i}>
                      <td>
                        <div className="software-cat">
                          {/* <img
                            src="assets/images/pngs/0-2.png"
                            alt="img"
                            className="ang-bg br-5"
                          /> */}
                        </div>
                      </td>
                      <td>
                        <h6 className="mb-1 font-weight-semibold">PPN Barang Mewah</h6>

                        {/* <small className="fs-12 text-muted mr-2">
                          <FaCalendar className="size" />9 Months
                        </small>
                        <small className="fs-12 text-muted ">
                          <FaClock className="size" />2 Hours
                        </small> */}
                      </td>
                      <td className="text-center">
                        {/* <h6 className="mb-0 font-weight-bold">$34</h6> */}
                        <span className="fs-12 text-yellow">
                          <FaStar className="size" />

                          <FaStar className="size" />
                          <FaStar className="size" />
                          <FaStar className="size" />
                          <FaStar className="size" />
                        </span>
                      </td>
                    </tr>
                  );
                })}
              </Table>
            </div>
          </Card>
        </Col>
        <Col md={4} className="mb-4">
          <Card className="card_container">
            <div className="card_inside__padding ">
              <div className="d-flex justify-content-between border_card">
                <div className="card_subtitle mb-4">Students Overview</div>
              </div>
              <div className="my-3">
                <p className="mb-23">
                  Excellent <span className="float-right">10%</span>
                </p>
                <ProgressBar className="h-2" now={10} />
              </div>
              <div className="my-3">
                <p className="mb-23">
                  Good <span className="float-right">40%</span>
                </p>
                <ProgressBar className="h-2" now={40} />
              </div>
              <div className="my-3">
                <p className="mb-23">
                  Avarage <span className="float-right">30%</span>
                </p>
                <ProgressBar className="h-2" now={30} />
              </div>
              {[...Array(1)].map((item, i) => {
                return (
                  <div key={i} className="my-3">
                    <p className="mb-23">
                      Low <span className="float-right">20%</span>
                    </p>
                    <ProgressBar className="h-2" now={20} />
                  </div>
                );
              })}
            </div>
          </Card>
        </Col>
        <Col md={4} className="mb-4">
          <Card className="card_container">
            <div className="card_inside__padding ">
              <div className="d-flex justify-content-between border_card">
                <div className="card_subtitle mb-4">Top Instructor</div>
              </div>
              <ListGroup className="d-flex align-items-center border-bottom p-3">
                <span className="avatar avatar-md brround cover-image"></span>
                <div className="wrapper w-100 ml-3">
                  <div className="mb-0 d-flex mt-2">
                    <div>
                      <h5 className="mb-0">LM Instruktur</h5>
                      <p className="mb-0 fs-13 text-muted">Perpajakan</p>
                    </div>
                    <div className="ml-auto">
                      <p className="fs-13 text-muted mb-0">Daily:2,5 hours</p>
                    </div>
                  </div>
                </div>
              </ListGroup>
              {[...Array(1)].map((item, i) => {
                return (
                  <ListGroup key={i} className="d-flex align-items-center border-bottom p-3">
                    <span className="avatar avatar-md brround cover-image"></span>
                    <div className="wrapper w-100 ml-3">
                      <div className="mb-0 d-flex mt-2">
                        <div>
                          <h5 className="mb-0">Formasi Gabriel</h5>
                          <p className="mb-0 fs-13 text-muted">Keuangan</p>
                        </div>
                        <div className="ml-auto">
                          <p className="fs-13 text-muted mb-0">Daily:2 hours</p>
                        </div>
                      </div>
                    </div>
                  </ListGroup>
                );
              })}
            </div>
          </Card>
        </Col>
        <Col md={12} className="mb-4">
          <Card className="card_container">
            <div className="card_inside__padding ">
              <div className="d-flex justify-content-between border_card">
                <div className="card_subtitle mb-4">Course Overview</div>
              </div>
              <Table>
                <thead>
                  <tr>
                    <th>Course Name</th>
                    <th>Category Name</th>
                    <th>Duration</th>
                    {/* <th>Amount</th> */}
                    <th>Course Type</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Pph 21</td>
                    <td>Perpajakan</td>
                    <td>3 Months</td>
                    {/* <td className="font-weight-semibold fs-15">$89</td> */}
                    <td>Pelatihan</td>
                    <td>
                      <a
                        className="btn btn-success btn-sm text-white mb-1"
                        data-toggle="tooltip"
                        data-original-Header="View"
                      >
                        Active
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>Budgeting – A Forecasting Technique</td>
                    <td>Keuangan</td>
                    <td>3 Months</td>
                    {/* <td className="font-weight-semibold fs-15">$89</td> */}
                    <td>Pelatihan</td>
                    <td>
                      <a
                        className="btn btn-success btn-sm text-white mb-1"
                        data-toggle="tooltip"
                        data-original-Header="View"
                      >
                        Active
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>Fixed & Intangible Asset Accounting</td>
                    <td>Keuangan</td>
                    <td>3 Months</td>
                    {/* <td className="font-weight-semibold fs-15">$89</td> */}
                    <td>Pelatihan</td>
                    <td>
                      <a
                        className="btn btn-success btn-sm text-white mb-1"
                        data-toggle="tooltip"
                        data-original-Header="View"
                      >
                        Active
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>PPN Barang Mewah</td>
                    <td>Perpajakan</td>
                    <td>3 Months</td>
                    {/* <td className="font-weight-semibold fs-15">$89</td> */}
                    <td>Pelatihan</td>
                    <td>
                      <a
                        className="btn btn-success btn-sm text-white mb-1"
                        data-toggle="tooltip"
                        data-original-Header="View"
                      >
                        Active
                      </a>
                    </td>
                  </tr>
                  {[...Array(1)].map((item, i) => {
                    return (
                      <tr key={i}>
                        <td>Pelatihan Dasar Management</td>
                        <td>Keuangan</td>
                        <td>3 Months</td>
                        {/* <td className="font-weight-semibold fs-15">$89</td> */}
                        <td>Pelatihan</td>
                        <td>
                          <a
                            className="btn btn-success btn-sm text-white mb-1"
                            data-toggle="tooltip"
                            data-original-Header="View"
                          >
                            Active
                          </a>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          </Card>
        </Col>
        <Col md={12} className="mb-4">
          <Card className="card_container">
            <div className="card_inside__padding ">
              <div className="d-flex justify-content-between border_card">
                <div className="card_subtitle mb-4">Karyawan yang Lulus</div>
              </div>
              <Table>
                <thead>
                  <tr role="row">
                    <th
                      className="sorting_asc"
                      tabIndex="0"
                      aria-controls="exportexample"
                      rowspan="1"
                      colSpan="1"
                      aria-sort="ascending"
                      aria-label="Nama: activate to sort column descending"
                    >
                      Nama
                    </th>
                    <th
                      className="sorting"
                      tabIndex="0"
                      aria-controls="exportexample"
                      rowspan="1"
                      colSpan="1"
                      aria-label="Posisi: activate to sort column ascending"
                    >
                      Posisi
                    </th>

                    <th
                      className="sorting"
                      tabIndex="0"
                      aria-controls="exportexample"
                      rowspan="1"
                      colSpan="1"
                      aria-label="Kelas: activate to sort column ascending"
                    >
                      Kelas
                    </th>
                    <th
                      className="sorting"
                      tabIndex="0"
                      aria-controls="exportexample"
                      rowspan="1"
                      colSpan="1"
                      aria-label="Tanggal Mulai: activate to sort column ascending"
                    >
                      Tanggal Mulai
                    </th>
                    <th
                      className="sorting"
                      tabIndex="0"
                      aria-controls="exportexample"
                      rowspan="1"
                      colSpan="1"
                      aria-label="Tanggal Selesai: activate to sort column ascending"
                    >
                      Tanggal Selesai
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr role="row" className="odd">
                    <td className="sorting_1">Thomas Dwi Agung Wibowo</td>
                    <td>Keuangan</td>
                    <td>56</td>
                    <td>2021/06/01</td>
                    <td>2021/08/01</td>
                  </tr>
                  <tr role="row" className="odd">
                    <td className="sorting_1">Hanif Reza Saputra</td>
                    <td>Keuangan</td>
                    <td>20</td>
                    <td>2021/06/01</td>
                    <td>2021/07/01</td>
                  </tr>
                  {[...Array(1)].map((item, i) => {
                    return (
                      <tr key={i} role="row" className="odd">
                        <td className="sorting_1">Faiq Muhammad Azka</td>
                        <td>Keuangan</td>
                        <td>20</td>
                        <td>2021/06/01</td>
                        <td>2021/07/01</td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default Statistik;
function option2() {
  return {
    dataset: {
      source: [
        ["score", "amount", "product"],
        [89.3, 58212, "Januari"],
        [57.1, 78254, "Febuari"],
        [74.4, 41032, "Maret"],
        [50.1, 12755, "April"],
        [89.7, 20145, "Mei"],
        [68.1, 79146, "Juni"],
        [19.6, 91852, "Juli"],
        [10.6, 101852, "Agustus"],
        [32.7, 20112, "September"],
        [32.7, 20112, "Oktober"],
        [32.7, 20112, "November"],
        [32.7, 20112, "Desember"],
      ],
    },
    grid: { containLabel: true },
    xAxis: { name: "amount" },
    yAxis: { type: "category" },
    visualMap: {
      orient: "horizontal",
      left: "center",
      min: 10,
      max: 100,
      // Map the score column to color
      dimension: 0,
      inRange: {
        color: ["#65B581", "#FFCE34", "#FD665F"],
      },
    },
    series: [
      {
        type: "bar",
        encode: {
          // Map the "amount" column to X axis.
          x: "amount",
          // Map the "product" column to Y axis
          y: "product",
        },
      },
    ],
  };
}
function option() {
  return {
    series: [
      {
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b}: {c} ({d}%)",
        },
        legend: {
          data: ["1", "2", "3", "4", "5", "6", "7", "8"],
        },
        type: "pie",
        radius: ["45%", "60%"],
        labelLine: {
          length: 30,
        },
        data: [
          { value: 1048, name: "1" },
          { value: 335, name: "2" },
          { value: 310, name: "3" },
          { value: 251, name: "4" },
          { value: 234, name: "5" },
          { value: 147, name: "6" },
          { value: 135, name: "7" },
          { value: 102, name: "8" },
        ],
      },
    ],
  };
}
