import { Button, Card, Col, Form, Row, Table } from "react-bootstrap";
import PaginationTemplate from "../Layout/Pagination";
import { Link } from "@mui/material";
import IconPlusWhite from "../../assets/images/icon-plus-white.svg";
import IconSearch from "../../assets/images/icon-search.svg";
import IconFilter from "../../assets/images/icon-filter.svg";
import IconEdit from "../../assets/images/icon-edit-pen.svg";
import IconTrash from "../../assets/images/icon-trash.svg";
export default function AdminList(props) {
  const {
    dataAdmin,
    keywordAdmin,
    handleDeleteUser,
    setKeywordAdmin,
    filterDataAdmin,
    handleCheck,
    adminData,
    handleViewDetail,
  } = props;
  return (
    <Card className="p-3">
      <Row className="mt-3 mb-4">
        <b className="d-block pl-3">Daftar Admin</b>
        <Col lg={12} className="d-flex my-3 justify-content-between">
          <div className="d-flex align-items-center">
            <div className="position-relative">
              <Form.Control
                placeholder="Cari"
                aria-label="Username"
                aria-describedby="basic-addon1"
                className="input__search-pelatihan"
                onChange={(e) => setKeywordAdmin(e.target.value)}
              />
              <div className="icon__search">
                <img src={IconSearch} alt="" />
              </div>
            </div>
            <Button
              variant="outline-secondary"
              id="button-addon2"
              className="ml-3 btn__filter-pelatihan"
              onClick={filterDataAdmin}
            >
              <span className="mr-3">
                <img src={IconFilter} alt="" />
              </span>
              Filter
            </Button>
          </div>
          <div className="d-flex gap-3">
            <Button
              variant="danger"
              className="d-flex align-items-center btn__delete-penugasan mr-3"
              onClick={() => handleDeleteUser("admin")}
            >
              <span className="mr-3">
                <img src={IconTrash} alt="" />
              </span>
              Hapus
            </Button>
            <Link
              className="d-flex align-items-center btn__add-penugasan-organisasi"
              to={"/dashboard/management-organisasi/add-member"}
            >
              <span className="">
                <img src={IconPlusWhite} alt="" />
              </span>
              Tambah Admin
            </Link>
          </div>
        </Col>
      </Row>
      <Table responsive striped hover>
        <thead>
          <tr>
            <th>
              <Form.Check
                inline
                name="allselect"
                type="checkbox"
                checked={
                  adminData?.filter((data) => data?.isChecked !== true).length <
                  1
                }
                onChange={(e) => handleCheck(e, "admin")}
              />
            </th>
            <th className="pl-0">Nama Admin</th>
            <th className="pl-0">Email</th>
            <th className="pl-0">Divisi</th>
            <th className="pl-0">Posisi</th>
          </tr>
        </thead>
        <tbody>
          {adminData?.length > 0 ? (
            adminData?.map((data, index) => (
              <tr key={index}>
                <td>
                  <Form.Check
                    inline
                    name={data.user_id}
                    type="checkbox"
                    checked={data?.isChecked || false}
                    username={data.name}
                    onChange={(e) => handleCheck(e, "admin")}
                  />
                </td>
                <td className="pl-0">{data.name}</td>
                <td className="pl-0">{data.email}</td>
                <td className="pl-0">{data.division}</td>
                <td className="pl-0 d-flex justify-content-between">
                  <span> {data.position}</span>
                  <span
                    role={"button"}
                    onClick={() => handleViewDetail(data.user_id)}
                  >
                    <img src={IconEdit} alt="" />
                  </span>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={4} className="text-center">
                Data Belum Tersedia
              </td>
            </tr>
          )}
        </tbody>
      </Table>
      <PaginationTemplate
        size={5}
        totalRows={1}
        totalPages={1}
        paginationSelect={1}
        active={1}
      />
    </Card>
  );
}
