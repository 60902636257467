import React, { useState, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";

function SkModal({ content }) {
  const [show, setShow] = useState(false);
  useEffect(() => {
    setShow(content.show);
  }, [content]);
  const handleClose = () => setShow(false);
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        scrollable={true}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{content?.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{content?.body}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          {/* <Button variant="primary" onClick={handleClose}>
                        Save Changes
                    </Button> */}
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default SkModal;
