import React, { useState, useEffect } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import { Modal } from "react-bootstrap";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { Container } from "react-bootstrap";
import { slugify } from "../helper/functions";
import "../assets/css/Personal/MyCalendar.css";

const localizer = momentLocalizer(moment);

function TimelineCalendar({ data, dateChanges }) {
  const [events, setEvents] = useState(listEvent);
  const [show, setShow] = useState(false);
  const [showEvents, setShowEvents] = useState(false);

  // const handleSelect = ({ start, end }) => {
  //   const title =
  //     typeof window !== "undefined" ? window.prompt("New Event name") : "";
  //   const colors = typeof window !== "undefined" ? window.prompt("Color") : "";
  //   setColor(colors);
  //   if (title) {
  //     setEvents((events) => [...events, { start, end, title }]);
  //   }
  // };
  useEffect(() => {
    if (data) {
      setEvents([]);
      for (let i = 0; i < data.length; i++) {
        const start = new Date(data[i]?.time_starts);
        const end = new Date(data[i]?.time_ends);
        const title = data[i]?.title;
        const detail = data[i]?.course_id;
        const course_slug = slugify(data[i]?.course);
        setEvents((events) => [...events, { start, end, title, detail, course_slug }]);
      }
    }
  }, [data]);
  const handleShowInfo = (e) => {
    setShow(!show);
    setShowEvents(e);
  };
  return (
    <>
      {/* <div className="dashboard__section_title mb-3">Kalendar Saya</div> */}
      <Calendar
        popup
        selectable
        localizer={localizer}
        defaultDate={new Date()}
        defaultView="month"
        events={events}
        style={{ height: "100vh" }}
        onSelectEvent={handleShowInfo}
        onRangeChange={(e, a) => dateChanges(e, a)}
        // onSelectSlot={handleSelect}
        // eventPropGetter={(event, start, end, isSelected) => {
        //   let newStyle = {
        //     backgroundColor: `${color}`,
        //   };
        //   if (event.isMine) {
        //     newStyle.backgroundColor = "lightgreen";
        //   }
        //   return {
        //     className: "",
        //     style: newStyle,
        //   };
        // }}
      />
      <Modal show={show} onHide={() => setShow(!show)}>
        <div className="event_name">
          <div className="fw-700">Nama Event: {showEvents.title}</div>
          <div className="fw-700">Acara Dimulai: {JSON.parse(JSON.stringify(moment(showEvents.start).format("DD/MM/YYYY hh:mm")))}</div>
          <div className="fw-700">Acara Selesai: {JSON.parse(JSON.stringify(moment(showEvents.end).format("DD/MM/YYYY hh:mm")))}</div>
        </div>
      </Modal>
    </>
  );
}
export default TimelineCalendar;

const listEvent = [
  // {
  //   start: moment().toDate(),
  //   end: moment().add(2, "hours").toDate(),
  //   title: "Webinar Pph 21",
  //   style: "blue",
  // },
  // {
  //   start: moment("2021-08-01").toDate(),
  //   end: moment("2021-08-03 06:00:00").toDate(),
  //   title: "Webinar PPN",
  //   style: "blue",
  // },
];
