import React, { useState, useEffect } from "react";
import { Button, Table, Modal, Form, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { FaEdit } from "react-icons/fa";
import { RiDeleteBin2Line } from "react-icons/ri";
import {
  getLessonDataThunk,
  deleteLessonDataThunk,
} from "../redux/thunks/LessonThunks";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import AddLesson from "./AddLesson";
import Pagination from "./Layout/Pagination";

const MySwal = withReactContent(Swal);

function TableLesson() {
  const dispatch = useDispatch();

  const [active, setActive] = useState(1);
  const [trigger, setTrigger] = useState(false);
  const [size, setSize] = useState(10); //pagination size
  const [lessonData, setLessonData] = useState(null);
  const [lessonPagination, setLessonPagination] = useState(null);
  const [lessonDataId, setLessonDataId] = useState([]);
  const [detailData, setDetailData] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const lessons = useSelector((state) => state.lessons);

  useEffect(() => {
    dispatch(getLessonDataThunk({ param: `?size=${size}&page=${active}` }));
  }, []);

  useEffect(() => {
    setLessonData(lessons.lessonData.items);
    setLessonDataId(lessons.lessonDataId);
    setLessonPagination(lessons.lessonData.pagination);
  }, [lessons]);

  useEffect(() => {
    if (lessons.lessonDataDelete === "error" && trigger) {
      setTrigger(false);
      displayPopup(false, "Terjadi masalah saat menghapus data", "Ok");
    }
    if (lessons.lessonDataDelete === "success" && trigger) {
      setTrigger(false);
      dispatch(getLessonDataThunk({ param: `?size=${size}&page=${active}` }));
    }
  }, [lessons]);

  function paginationSelect(newActive) {
    setActive(newActive);
    dispatch(getLessonDataThunk({ param: `?size=${size}&page=${newActive}` }));
  }

  const handleDelete = (id) => {
    MySwal.fire({
      title: "Anda yakin ingin menghapus ?",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Iya",
      cancelButtonText: "Tidak",
    }).then((value) => {
      if (value.isConfirmed) {
        setTrigger(true);
        dispatch(deleteLessonDataThunk(id));
      }
    });
  };

  const handleShow = (lesson_id, title, topic, topic_id) => {
    const data = { lesson_id, title, topic, topic_id };
    dispatch(getLessonDataThunk(data.lesson_id));
    setModalShow(true);
    setDetailData(data);
  };
  const handleClose = () => {
    setModalShow(false);
  };

  function displayPopup(cancel, title, confirm) {
    MySwal.fire({
      title: title,
      showCancelButton: cancel,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: confirm,
    }).then((value) => {});
  }

  return (
    <>
      <Row>
        <Col>
          <div className=" pt-2 pb-3">
            <div className="course_create__title">Daftar Pelajaran</div>
            <h6 className="fw-400 text-muted">
              {lessonData ? `${lessonData.length} Hasil` : null}
            </h6>
          </div>
        </Col>
        <Col className="text-right align-self-center">
          <Link to="/lesson/create/" className="btn btn-primary px-3 py-3">
            + Tambahkan Pelajaran
          </Link>
        </Col>
      </Row>
      <div className=" bg-white br-16 p-6 mb-4 bx-shdw">
        {lessonPagination ? (
          <Pagination
            size={size}
            totalRows={lessonPagination.totalItems}
            totalPages={lessonPagination.totalPages}
            paginationSelect={paginationSelect}
            active={active}
          />
        ) : null}
        <Table responsive bordered hover>
          <thead>
            <tr>
              <th className="w-25">Lesson ID</th>
              <th>Title</th>
              <th>Topic</th>
              <th>Topic ID</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {lessonData?.map((item, index) => {
              return (
                <tr key={index}>
                  <td>{item.lesson_id}</td>
                  <td>{item.title}</td>
                  <td>{item.topic}</td>
                  <td>{item.topic_id}</td>
                  <td>
                    <Button
                      className="mb-2"
                      variant="info"
                      onClick={() =>
                        handleShow(
                          item.lesson_id,
                          item.title,
                          item.topic,
                          item.topic_id
                        )
                      }
                    >
                      <FaEdit className="fill-white" />
                    </Button>
                    <Button variant="danger">
                      <RiDeleteBin2Line
                        onClick={() => handleDelete(item.lesson_id)}
                        className="fill-white"
                      />
                    </Button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
      <Modal
        show={modalShow}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        style={{ overflow: "auto" }}
      >
        <Modal.Header className="px-4">
          <Modal.Title id="contained-modal-title-vcenter">
            Edit Data
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-4">
          <AddLesson
            id={detailData.lesson_id}
            defaultItems={lessonDataId}
            handleCancel={handleClose}
            updateData={paginationSelect}
          />
        </Modal.Body>
      </Modal>
    </>
  );
}
export default TableLesson;
