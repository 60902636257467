import React, { useEffect } from "react";
import { Row, Col, Container } from "react-bootstrap";
import "../../assets/css/Dashboard/index.css";
import AddWebinar from "../../components/AddWebinar";
import { AuthIsLogin } from "../../helper/auth";
import { useHistory } from "react-router-dom";

function CreateLesson() {
  const history = useHistory();
  useEffect(() => {
    AuthIsLogin(history);
  }, []);
  return (
    <Container>
      <Row className="justify-content-center align-items-center height-inherit">
        <Col sm={12} className=" mb-2">
          <div className="">
            <div className="course_create__title">Buat Webinar</div>
          </div>
        </Col>
        <Col sm={12} className=" br-16 mb-4">
          <AddWebinar />
        </Col>
      </Row>
    </Container>
  );
}

export default CreateLesson;
