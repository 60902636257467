import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import AddLesson from "./AddLesson";
import AddWebinar from "./AddWebinar";
import AddAssignment from "./AddAssignment";
import AddQuiz from "./AddQuiz";
import { useSelector, useDispatch } from "react-redux";
import { getTopicDataThunk } from "../redux/thunks/TopicThunks";

function AddMateri({ topic_id, setModalContent, modalContent, courseSingle }) {
  const dispatch = useDispatch();
  const [materialType, setMaterialType] = useState(["lesson", "quiz", "assignment", "webinar"]);

  const [selectedMaterial, setSelectedMaterial] = useState("lesson");
  const courses = useSelector((state) => state.courses);
  const handleChangeMaterial = (e) => {
    const tipe = e.target.value;
    setSelectedMaterial(tipe);
  };
  const handleClose = () => {
    setModalContent({ ...modalContent, show: false });
  };
  useEffect(() => {
    if (topic_id) dispatch(getTopicDataThunk(topic_id));
  }, [topic_id]);

  useEffect(() => {
    if (courses.courseDataId?.course_type?.toLowerCase() == "webinar" || courses.courseDataId?.course_type?.toLowerCase() == "konsultasi") {
      setMaterialType(["webinar"]);
      setSelectedMaterial("webinar");
    } else if (courses.courseDataId?.course_type?.toLowerCase() == "sertifikasi" || courses.courseDataId?.course_type?.toLowerCase() == "try out") {
      setMaterialType(["quiz"]);
      setSelectedMaterial("quiz");
    } else {
      setMaterialType(["lesson", "quiz", "assignment"]);
    }
  }, [courses]);

  return (
    <>
      <Form.Group>
        <Form.Label className="fw-600"> Tipe Materi </Form.Label>
        <Form.Control required as="select" type="select" name="" onChange={handleChangeMaterial} className="text-capitalize">
          {materialType.map((item, i) => {
            return <option value={item}> {item} </option>;
          })}
        </Form.Control>
      </Form.Group>
      {/* create lesson  */}
      {selectedMaterial === "lesson" && <AddLesson topic_id={topic_id} handleCancel={handleClose} />}

      {/* create webinar */}
      {selectedMaterial === "webinar" && <AddWebinar topic_id={topic_id} handleCancel={handleClose} fixedTitle={courses.courseDataId?.title} />}

      {/* create assignment */}
      {selectedMaterial === "assignment" && <AddAssignment topic_id={topic_id} handleCancel={handleClose} />}

      {/* create quiz */}
      {selectedMaterial === "quiz" && <AddQuiz topic_id={topic_id} handleCancel={handleClose} fixedTitle={courseSingle ? courses.courseDataId?.title : null} />}
    </>
  );
}

export default AddMateri;
