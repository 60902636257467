import React from "react";
import { Image } from "react-bootstrap";
import Up from "./../../assets/images/up.png";
import "./../../assets/css/Dashboard/AnalyticsPembelajaran.css";

export default function CapaianBelajar(props) {
  const { data, type, shadow } = props;
  // console.log(data);
  return (
    <div
      className={`card-analitik ${shadow ? "_shadow" : ""} ${
        type === "company" ? "_right" : ""
      }`}
    >
      {type === "company" && <div className="ornament-card"></div>}
      <div
        className={`total-anggota_title ${type === "company" ? "mb-0" : ""}`}
      >
        Capaian Belajar
      </div>
      <div className="d-flex total-reward">
        <span>
          <Image src={Up} width={30} height={30} />
        </span>
        {data?.growth && Math.sign(data?.growth) === 1 ? "+" : ""}
        {data?.growth ? data?.growth.toFixed(2) : "0"}
      </div>
      <div className="total-reward_desc">
        Nilai didapatkan dari rata-rata presentase hasil Post test dan pre test
      </div>
    </div>
  );
}
