import React from "react";
import { Button, Row, Col, Container, Form } from 'react-bootstrap'
import '../../assets/css/Account/index.css'

function EmailVerified() {
  return (
    <Container className="height-full">
      <Row className="justify-content-center align-items-center height-inherit">
        <Col xs={10} sm={10} md={4} lg={5} className="col-login bg-white rounded p-6 ">
          <div className="text-center pt-2 pb-4">
            <h3 className="fw-600">
              Verifikasi Email
            </h3>
          </div>
          <div>
            <Form>

              <Form.Group>
                <div className="position-absolute p-1 px-3"><svg xmlns="http://www.w3.org/2000/svg" height="24"
                  viewBox="0 0 24 24" width="24">
                  <path d="M0 0h24v24H0V0z" fill="none" />
                  <path d="M20 8l-8 5-8-5v10h16zm0-2H4l8 4.99z" opacity=".3" />
                  <path
                    d="M4 20h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2zM20 6l-8 4.99L4 6h16zM4 8l8 5 8-5v10H4V8z" />
                </svg>
                </div>
                <Form.Control className="mb-3 input100" type="email" placeholder="Email" />
              </Form.Group>
              <Button type="submit" className="mb-3 w-100">Verifikasi</Button>
            </Form>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default EmailVerified;
