import React from "react";
import { Card } from "react-bootstrap";
import ReactECharts from "echarts-for-react";
import "../assets/css/Dashboard/companyAnalytics.css";

function StatusTryOut({ data }) {
  function option() {
    return {
      legend: {
        top: "bottom",
      },
      tooltip: {
        trigger: "item",
        formatter: "{a} <br/>{b} : {c} ({d}%)",
      },
      series: [
        {
          name: "Status Tryout",
          type: "pie",
          radius: [50, 100],
          center: ["50%", "50%"],
          roseType: "radius",
          itemStyle: {
            borderRadius: 8,
          },
          data: [
            { value: data.chart_tryout.passed.nominal, name: "Lulus" },
            { value: data.chart_tryout.failed.nominal, name: "Tidak Lulus" },
          ],
          color: ["#91CC75", "#EE4253"],
        },
      ],
    };
  }
  return (
    <>
      <Card className="card_container height-card__tryout">
        <div className="card_inside__padding ">
          <div className="d-flex justify-content-between border_card">
            <div className="card_title__info mb-1">Status Try Out</div>
          </div>

          <ReactECharts option={option()} />
        </div>
      </Card>
    </>
  );
}

export default StatusTryOut;
