import {
  //get
  QUIZ_DATA,
  QUIZ_DATA_ID_SUCCESS,
  QUIZ_DATA_SUCCESS,
  QUIZ_DATA_FAIL,
  //post
  QUIZ_DATA_POST,
  QUIZ_DATA_POST_SUCCESS,
  QUIZ_DATA_POST_FAIL,
  //put
  QUIZ_DATA_PUT,
  QUIZ_DATA_PUT_SUCCESS,
  QUIZ_DATA_PUT_FAIL,
  //delete
  QUIZ_DATA_DELETE,
  QUIZ_DATA_DELETE_SUCCESS,
  QUIZ_DATA_DELETE_FAIL,
} from "../config/QuizConfig";

const intialState = {
  quizDataPost: "",
  quizDataPut: "",
  quizDataDelete: "",
  quizData: [],
  quizDataId: [],
  isLoading: false,
};

export const QuizReducers = function (state = intialState, action) {
  switch (action.type) {
    //get
    case QUIZ_DATA:
      return { ...state, isLoading: true };
    case QUIZ_DATA_SUCCESS:
      return { ...state, isLoading: false, quizData: action.value };
    case QUIZ_DATA_ID_SUCCESS:
      return { ...state, isLoading: false, quizDataId: action.value };
    case QUIZ_DATA_FAIL:
      return {
        ...state,
        isLoading: false,
        quizData: "error",
        quizDataId: "error",
      };
    //post
    case QUIZ_DATA_POST:
      return { ...state, isLoading: true, quizDataPost: "" };
    case QUIZ_DATA_POST_SUCCESS:
      return { ...state, isLoading: false, quizDataPost: "success" };
    case QUIZ_DATA_POST_FAIL:
      return { ...state, isLoading: false, quizDataPost: "error" };

    //put
    case QUIZ_DATA_PUT:
      return { ...state, isLoading: true, quizDataPut: "" };
    case QUIZ_DATA_PUT_SUCCESS:
      return { ...state, isLoading: false, quizDataPut: "success" };
    case QUIZ_DATA_PUT_FAIL:
      return { ...state, isLoading: false, quizDataPut: "error" };

    //delete
    case QUIZ_DATA_DELETE:
      return { ...state, isLoading: true, quizDataDelete: "" };
    case QUIZ_DATA_DELETE_SUCCESS:
      return { ...state, isLoading: false, quizDataDelete: "success" };
    case QUIZ_DATA_DELETE_FAIL:
      return { ...state, isLoading: false, quizDataDelete: "error" };

    default:
      return state;
  }
};
