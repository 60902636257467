import React from "react";
import { Card, Col } from "react-bootstrap";
import ReactECharts from "echarts-for-react";

export default function SummaryChartPenugasan(props) {
  const { chartDataReady, totalStatistic } = props;
  const optionPenugasan = {
    tooltip: {
      trigger: "item",
    },
    series: [
      {
        type: "pie",
        radius: ["50%", "80%"],
        label: {
          show: true,
          position: "center",
          formatter: `{a|${totalStatistic}\n} {b|penugasan}`, // Use '{a|text}' to display the static text
          rich: {
            a: {
              fontSize: 20, // Set font size as needed
              fontWeight: "700", // Set font weight as needed
              color: "#4F4F4F",
              fontFamily: "Poppins",
            },
            b: {
              fontSize: 12, // Set font size as needed
              fontWeight: "400", // Set font weight as needed
              color: "#4F4F4F",
              fontFamily: "Poppins",
            },
          },
        },
        labelLine: {
          show: false,
        },
        data: chartDataReady,
      },
    ],
    // color: colorPalete,
  };
  return (
    <Col md={12} lg={6}>
      <Card className="d-flex flex-row h-100">
        <Col
          md={12}
          lg={5}
          className="d-flex align-items-center justify-content-center"
        >
          <ReactECharts
            style={{ height: "190px", width: "190px" }}
            option={optionPenugasan}
            theme={"light"}
          />
        </Col>
        <Col
          md={12}
          lg={7}
          className="d-flex align-items-center justify-content-center flex-wrap px-0"
        >
          <Col md={12} lg={6} className="pl-0 pr-4">
            <div className="p-2 wrapp__chart-status-card-ongoing">
              <h5>
                {chartDataReady?.length > 0 ? chartDataReady[1]?.value : 0}{" "}
                Pelatihan
              </h5>
              <span>Berlangsung</span>
            </div>
          </Col>
          <Col md={12} lg={6} className="pl-0 pr-4">
            <div className="p-2 wrapp__chart-status-card-done">
              <h5>
                {" "}
                {chartDataReady?.length > 0 ? chartDataReady[2]?.value : 0}{" "}
                Pelatihan
              </h5>
              <span>Selesai</span>
            </div>
          </Col>
          <Col md={12} lg={6} className="pl-0 pr-4">
            <div className="p-2 wrapp__chart-status-card-notdone">
              <h5>
                {" "}
                {chartDataReady?.length > 0 ? chartDataReady[0]?.value : 0}{" "}
                Pelatihan
              </h5>
              <span>Belum Selesai</span>
            </div>
          </Col>
          <Col md={12} lg={6} className="pl-0 pr-4">
            <div className="p-2 wrapp__chart-status-card-late">
              <h5>
                {" "}
                {chartDataReady?.length > 0 ? chartDataReady[3]?.value : 0}{" "}
                Pelatihan
              </h5>
              <span>Terlambat</span>
            </div>
          </Col>
        </Col>
      </Card>
    </Col>
  );
}
