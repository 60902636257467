import {
  //get
  QUIZ_THEME_DATA,
  QUIZ_THEME_DATA_ID_SUCCESS,
  QUIZ_THEME_DATA_SUCCESS,
  QUIZ_THEME_DATA_FAIL,
  //live search
  QUIZ_THEME_LIVE_SEARCH_DATA,
  QUIZ_THEME_LIVE_SEARCH_DATA_SUCCESS,
  QUIZ_THEME_LIVE_SEARCH_DATA_FAIL,
  //post
  QUIZ_THEME_DATA_POST,
  QUIZ_THEME_DATA_POST_SUCCESS,
  QUIZ_THEME_DATA_POST_FAIL,
  //remove
  QUIZ_THEME_LIVE_SEARCH_REMOVE,
} from "../config/QuizThemeConfig";

const intialState = {
  quizThemeDataPost: "",
  quizThemeData: "",
  quizThemeDataId: "",
  quizThemeLiveSearchData: [],
  isLoading: false,
  isLoadingLive: false,
};

export const QuizThemeReducers = function (state = intialState, action) {
  switch (action.type) {
    //get
    case QUIZ_THEME_DATA:
      return { ...state, isLoading: true };
    case QUIZ_THEME_DATA_SUCCESS:
      return { ...state, isLoading: false, quizThemeData: action.value };
    case QUIZ_THEME_DATA_ID_SUCCESS:
      return { ...state, isLoading: false, quizThemeDataId: action.value };
    case QUIZ_THEME_DATA_FAIL:
      return {
        ...state,
        isLoading: false,
        quizThemeData: "error",
        quizThemeDataId: "error",
      }; //get live search
    case QUIZ_THEME_LIVE_SEARCH_DATA:
      return { ...state, isLoadingLive: true, quizThemeLiveSearchData: [] };
    case QUIZ_THEME_LIVE_SEARCH_DATA_SUCCESS:
      return {
        ...state,
        isLoadingLive: false,
        quizThemeLiveSearchData: action.value,
      };
    case QUIZ_THEME_LIVE_SEARCH_DATA_FAIL:
      return {
        ...state,
        isLoadingLive: false,
        quizThemeLiveSearchData: "error",
      };
    //post
    case QUIZ_THEME_DATA_POST:
      return { ...state, isLoading: true, quizThemeDataPost: "" };
    case QUIZ_THEME_DATA_POST_SUCCESS:
      return { ...state, isLoading: false, quizThemeDataPost: "success" };
    case QUIZ_THEME_DATA_POST_FAIL:
      return { ...state, isLoading: false, quizThemeDataPost: "error" };
    //remove
    case QUIZ_THEME_LIVE_SEARCH_REMOVE:
      return { ...state, isLoadingLive: false, quizThemeLiveSearchData: [] };
    default:
      return state;
  }
};
