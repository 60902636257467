import React from "react";
import ReactECharts from "echarts-for-react";
import { Link } from "react-router-dom";
import { Image } from "react-bootstrap";
import IconInfoGray from "../../assets/images/icon-info-gray.svg";

export default function ATPICertificate(props) {
  const { optionLiveClass, data, isLoaded, courseId, title } = props;
  const [triangle, setTriangle] = React.useState(false);
  return (
    <div className={`card-analitik _shadow`}>
      <div
        className="total-anggota_title d-flex align-items-center justify-content-between"
        style={{
          borderBottom: "2px solid #E3E3E3",
          paddingBottom: "10px",
        }}
      >
        Prasyarat Sertifikasi
        <div
          className="position-relative"
          onMouseEnter={() => setTriangle(true)}
          onMouseLeave={() => setTriangle(false)}
          role="button"
        >
          {triangle && (
            <>
              <span className="position-absolute info__status">
                Nilai tidak terpenuhi
              </span>
              <div class="arrow-up__hover position-absolute"></div>
            </>
          )}
          <Image src={IconInfoGray} width={12} height={12} alt="" />
        </div>
      </div>
      <div className="atpi-wrap">
        <div className="me-3">
          <div className="atpi-desc">
            <span>
              <b>{data?.qualified}</b>
            </span>{" "}
            dari{" "}
            <span>
              <b>{data?.total_member}</b>
            </span>{" "}
            Anggota anda telah memenuhi syarat untuk{" "}
            <span>
              <b>klaim sertifikasi ATPI.</b>
            </span>
          </div>
          <div className="atpi-desc_two mb-0">
            Untuk informasi lebih lengkap klik
          </div>
          <Link
            to={`/dashboard/analitik-pembelajaran/ctt-cptt/${courseId}${
              title?.toLowerCase().includes("brevet ab") ||
              title?.toLowerCase().includes("brevet pajak ab")
                ? "?type=brevet-ab"
                : "?type=brevet-c"
            }`}
          >
            tautan ini
          </Link>
        </div>
        {optionLiveClass && isLoaded && (
          <div className="ml-2">
            <ReactECharts
              style={{ height: "110px", width: "110px" }}
              option={optionLiveClass}
              theme={"light"}
            />
          </div>
        )}
      </div>
    </div>
  );
}
