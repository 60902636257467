import { PromiseService } from "../../services/PromiseService";
import { COMPANIES_DATA_LINK } from "../config/CompaniesConfig";
import {
  //get
  getCompaniesData,
  getCompaniesDataSuccess,
  getCompaniesDataIdSuccess,
  getCompaniesDataFail,
  //post
  postCompaniesData,
  postCompaniesDataSuccess,
  postCompaniesDataFail,
  // put
  putCompaniesData,
  putCompaniesDataSuccess,
  putCompaniesDataFail,
  // delete
  deleteCompaniesData,
  deleteCompaniesDataSuccess,
  deleteCompaniesDataFail,
} from "../actions/CompaniesActions";

export const postCompaniesDataThunk = (data) => async (dispatch) => {
  dispatch(postCompaniesData());
  const body = data;
  try {
    const response = await PromiseService.postData(COMPANIES_DATA_LINK, body);
    if (response.status === 201 && response.data) {
      dispatch(postCompaniesDataSuccess(response.data));
    } else {
      dispatch(postCompaniesDataFail(response));
    }
  } catch (error) {
    dispatch(postCompaniesDataFail(error));
  }
};

export const getCompaniesDataThunk = (data) => async (dispatch) => {
  dispatch(getCompaniesData());
  if (data) {
    let url = `${COMPANIES_DATA_LINK}/${data}`;
    if (data.param) url = `${COMPANIES_DATA_LINK}${data.param}`;
    try {
      const response = await PromiseService.getData(url);
      if (response.status === 200 && response.data) {
        if (data.param) dispatch(getCompaniesDataSuccess(response.data));
        else dispatch(getCompaniesDataIdSuccess(response.data));
      } else {
        dispatch(getCompaniesDataFail(response));
      }
    } catch (error) {
      dispatch(getCompaniesDataFail(error));
    }
  } else {
    try {
      const response = await PromiseService.getData(COMPANIES_DATA_LINK);
      if (response.status === 200 && response.data) {
        dispatch(getCompaniesDataSuccess(response.data));
      } else {
        dispatch(getCompaniesDataFail(response));
      }
    } catch (error) {
      dispatch(getCompaniesDataFail(error));
    }
  }
};

// put
export const putCompaniesDataThunk = (data) => async (dispatch) => {
  dispatch(putCompaniesData());
  const body = data.body;
  const id = data.id;
  try {
    const response = await PromiseService.putData(
      `${COMPANIES_DATA_LINK}/${id}`,
      body
    );
    if (response.status === 201 && response.data) {
      dispatch(putCompaniesDataSuccess(response.data));
    } else {
      dispatch(putCompaniesDataFail(response));
    }
  } catch (error) {
    dispatch(putCompaniesDataFail(error));
  }
};

// delete
export const deleteCompaniesDataThunk = (data) => async (dispatch) => {
  dispatch(deleteCompaniesData());
  const id = data;
  try {
    const response = await PromiseService.deleteData(
      `${COMPANIES_DATA_LINK}/${id}`
    );
    if (response.status === 200 && response.data) {
      dispatch(deleteCompaniesDataSuccess(response.data));
    } else {
      dispatch(deleteCompaniesDataFail(response));
    }
  } catch (error) {
    dispatch(deleteCompaniesDataFail(error));
  }
};
