import React from "react";
import IconInfoGray from "../../assets/images/icon-info-gray.svg";
import { Image } from "react-bootstrap";
export default function StatusCertificate({ ...props }) {
  const { data, typeStatus, legendTwoColumn } = props;
  const dataCertif = data?.certification_status;
  const [triangle, setTriangle] = React.useState(false);
  const legendIndicator = (status, title) => {
    return (
      <div className="legend-graph__data">
        <div className={`legend-graph__param ${status}`}></div>
        <div>{title}</div>
      </div>
    );
  };
  return (
    <div className={`card-analitik _shadow`}>
      <div
        className="total-anggota_title d-flex justify-content-between align-items-center"
        style={{
          borderBottom: "2px solid #E3E3E3",
          paddingBottom: "10px",
        }}
      >
        {typeStatus === "ctt"
          ? "Status Klaim Sertifikat CTT"
          : typeStatus === "cptt"
          ? "Status Klaim Sertifikat CPTT"
          : "Status Sertifikat Brevet"}
        <div
          className="position-relative"
          onMouseEnter={() => setTriangle(true)}
          onMouseLeave={() => setTriangle(false)}
          role="button"
        >
          {triangle && (
            <>
              <span className="position-absolute info__status">
                Nilai tidak terpenuhi
              </span>
              <div class="arrow-up__hover position-absolute"></div>
            </>
          )}
          <Image src={IconInfoGray} width={12} height={12} alt="" />
        </div>
      </div>
      <div>
        <div className="graph-sertificate">
          <div className="graph-sertificate__data _not-claim d-flex align-items-center justify-content-center text-white">
            {dataCertif?.not_claimed}
          </div>
          <div className="graph-sertificate__data _claim d-flex align-items-center justify-content-center text-white">
            {dataCertif?.claimed}
          </div>
          <div className="graph-sertificate__data _send d-flex align-items-center justify-content-center text-white">
            {dataCertif?.in_delivery}
          </div>
          <div className="graph-sertificate__data _done d-flex align-items-center justify-content-center text-white">
            {dataCertif?.delivered}
          </div>
        </div>
        {legendTwoColumn ? (
          <div className="d-flex">
            <div className="legend-graph mr-4">
              {legendIndicator("_not-claim", "Belum klaim")}
              {legendIndicator("_claim", "Klaim diterima")}
            </div>
            <div className="legend-graph">
              {legendIndicator("_send", "Sertifikat dalam pengiriman")}
              {legendIndicator("_done", "Sertifikat telah sampai")}
            </div>
          </div>
        ) : (
          <div className="legend-graph">
            {legendIndicator("_not-claim", "Belum klaim")}
            {legendIndicator("_claim", "Klaim diterima")}
            {legendIndicator("_send", "Sertifikat dalam pengiriman")}
            {legendIndicator("_done", "Sertifikat telah sampai")}
          </div>
        )}
      </div>
    </div>
  );
}
