import Template from "../components/Layout/Template";
import Login from "../pages/Account/Login";
import Register from "../pages/Account/Register";
import EmailVerified from "../pages/Account/EmailVerified";
import ForgotPassword from "../pages/Account/ForgotPassword";
import HistoryAssignment from "../pages/Dashboard/HistoryAssignment";
import Overview from "../pages/Dashboard/Overview";
import CourseTable from "../pages/Dashboard/CourseTable";
import LearningPathTable from "../pages/Dashboard/LearningPathTable";
import TopicTable from "../pages/Dashboard/TopicTable";
import WebinarTable from "../pages/Dashboard/WebinarTable";
import LessonTable from "../pages/Dashboard/LessonTable";
import QuizTable from "../pages/Dashboard/QuizTable";
import CreateCourse from "../pages/Course/CreateCourse";
import PreviewCourse from "../pages/Course/PreviewCourse";
import CreateLesson from "../pages/Lessons/CreateLesson";
import EditLesson from "../pages/Lessons/EditLesson";
import CreateTopic from "../pages/Topic/CreateTopic";
import CreateWebinar from "../pages/Webinars/CreateWebinar";
import CreateQuizTheme from "../pages/QuizTheme/CreateQuizTheme";
import HandleRedirect from "../pages/Others/HandleRedirect";
import CreateLearningPath from "../pages/LearningPath/CreateLearningPath";
import PreviewLearningPath from "../pages/LearningPath/PreviewLearningPath";
import Path from "../pages/Dashboard/Path";
import Report from "../pages/Dashboard/Report";
import Profile from "../pages/Personal/Profile";
import ManageUser from "../pages/Dashboard/ManageUser";
import Statistik from "../pages/Dashboard/Statistik";
import MediaLibrary from "../pages/Account/MediaLibrary";
import UploadLibrary from "../pages/Account/UploadLibrary";
import LearningCalendar from "../pages/Dashboard/LearningCalendar";
import Analytic from "../pages/Dashboard/Analytic";
import Setting from "../components/Setting";
import StudentHomeWork from "../pages/Dashboard/StudentHomeWork";
// import ChatPage from "../pages/Dashboard/ChatPage";
import DetailEmployee from "../components/DetailEmployee";
import DetailClass from "../components/DetailClass";
import MyCompanyProfil from "../components/MyCompanyProfil";
import HistoryTransaction from "../components/HistoryTransaction";
import PreviewFile from "../pages/Dashboard/PreviewFile";
import ResetPassword from "../pages/Account/ResetPassword";
import ManagementPenugasan from "../pages/Dashboard/ManagementPenugasan";
import ManagementPenugasanDetail from "../pages/Dashboard/ManagementPenugasanDetail";
import DataAnalyticPembelajaran from "../pages/Dashboard/DataAnalyticPembelajaran";
import AnalyticUser from "../pages/Dashboard/AnayticUser";
import AnalyticCourse from "../pages/Dashboard/AnalyticCourse";
import ManagementOrganisasi from "../pages/Dashboard/ManagementOrganisasi/index";
import AddMember from "../pages/Dashboard/ManagementOrganisasi/AddMember";
import EditMember from "../pages/Dashboard/ManagementOrganisasi/EditMember";
import EditCompanies from "../pages/Dashboard/ManagementOrganisasi/EditCompanies";
import AnalyticCttCptt from "../pages/Dashboard/AnalyticCttCptt";

const RouteData = [
  {
    exact: true,
    path: ["/"],
    isSidebar: true,
    isHeader: true,
    redirect: "/dashboard/",
    component: Analytic,
  },
  {
    exact: true,
    path: ["/dashboard/"],
    isSidebar: true,
    isHeader: true,
    component: Analytic,
  },
  {
    exact: true,
    path: ["/dashboard/overview/"],
    isSidebar: true,
    isHeader: true,
    component: Overview,
  },
  {
    exact: true,
    path: ["/dashboard/learning-path/"],
    isSidebar: true,
    isHeader: true,
    component: Path,
  },
  {
    exact: true,
    path: ["/dashboard/report/"],
    isSidebar: true,
    isHeader: true,
    component: Report,
  },
  {
    exact: true,
    path: ["/dashboard/settings/"],
    isSidebar: true,
    isHeader: true,
    component: Setting,
  },
  {
    exact: true,
    path: ["/dashboard/home-work/"],
    isSidebar: true,
    isHeader: true,
    component: StudentHomeWork,
  },
  {
    exact: true,
    path: ["/company-profile/"],
    isSidebar: false,
    isHeader: true,
    component: MyCompanyProfil,
  },
  // {
  //   exact: true,
  //   path: ["/dashboard/chat"],
  //   isSidebar: true,
  //   isHeader: true,
  //   component: ChatPage,
  // },
  {
    exact: true,
    path: ["/dashboard/profile/"],
    isSidebar: true,
    isHeader: true,
    component: Profile,
  },
  {
    exact: true,
    path: ["/dashboard/table/course/"],
    isSidebar: true,
    isHeader: true,
    component: CourseTable,
  },
  {
    exact: true,
    path: ["/dashboard/kalender-pembelajaran"],
    isSidebar: true,
    isHeader: true,
    component: LearningCalendar,
  },
  {
    exact: true,
    path: ["/dashboard/course/:course_id/"],
    isSidebar: true,
    isHeader: true,
    component: PreviewCourse,
  },
  {
    exact: true,
    path: ["/dashboard/history/"],
    isSidebar: true,
    isHeader: true,
    component: HistoryAssignment,
  },
  {
    exact: true,
    path: ["/dashboard/learning-path/preview/"],
    isSidebar: true,
    isHeader: true,
    component: PreviewLearningPath,
  },
  {
    exact: true,
    path: ["/dashboard/table/learning-path/"],
    isSidebar: true,
    isHeader: true,
    component: LearningPathTable,
  },
  {
    exact: true,
    path: ["/dashboard/table/topic/"],
    isSidebar: true,
    isHeader: true,
    component: TopicTable,
  },
  {
    exact: true,
    path: ["/dashboard/table/lesson/"],
    isSidebar: true,
    isHeader: true,
    component: LessonTable,
  },
  {
    exact: true,
    path: ["/dashboard/table/quiz/"],
    isSidebar: true,
    isHeader: true,
    component: QuizTable,
  },
  {
    exact: true,
    path: ["/dashboard/table/webinar/"],
    isSidebar: true,
    isHeader: true,
    component: WebinarTable,
  },
  {
    exact: true,
    path: ["/course/create/"],
    isSidebar: true,
    isHeader: true,
    component: CreateCourse,
  },
  {
    exact: true,
    path: ["/learning-path/create/"],
    isSidebar: true,
    isHeader: true,
    component: CreateLearningPath,
  },
  {
    exact: true,
    path: ["/lesson/create/"],
    isSidebar: true,
    isHeader: true,
    component: CreateLesson,
  },
  {
    exact: true,
    path: ["/lesson/edit/:id/"],
    isSidebar: true,
    isHeader: true,
    component: EditLesson,
  },
  {
    exact: true,
    path: ["/topic/create/"],
    isSidebar: true,
    isHeader: true,
    component: CreateTopic,
  },
  {
    exact: true,
    path: ["/webinar/create/"],
    isSidebar: true,
    isHeader: true,
    component: CreateWebinar,
  },
  {
    exact: true,
    path: ["/webinar/edit/:id/"],
    isSidebar: true,
    isHeader: true,
    component: CreateWebinar,
  },
  {
    exact: true,
    path: ["/dashboard/kelola-user/"],
    isSidebar: true,
    isHeader: true,
    component: ManageUser,
  },
  {
    exact: true,
    path: ["/dashboard/statistik"],
    isSidebar: true,
    isHeader: true,
    component: Statistik,
  },
  {
    exact: true,
    path: ["/dashboard/analytic/"],
    isSidebar: true,
    isHeader: true,
    component: Analytic,
  },
  {
    exact: true,
    path: ["/analytics/users/:user_id"],
    isSidebar: true,
    isHeader: true,
    component: DetailEmployee,
  },
  {
    exact: true,
    path: ["/analytic/class-detail/"],
    isSidebar: true,
    isHeader: true,
    component: DetailClass,
  },
  {
    exact: true,
    path: ["/media/library/"],
    isSidebar: true,
    isHeader: true,
    component: MediaLibrary,
  },
  {
    exact: true,
    path: ["/media/upload/"],
    isSidebar: true,
    isHeader: true,
    component: UploadLibrary,
  },
  {
    exact: true,
    path: ["/preview-file/:id/"],
    isSidebar: false,
    isHeader: true,
    component: PreviewFile,
  },
  {
    exact: true,
    path: ["/dashboard/history-transaction"],
    isSidebar: true,
    isHeader: true,
    component: HistoryTransaction,
  },
  {
    exact: true,
    path: ["/dashboard/management-penugasan"],
    isSidebar: true,
    isHeader: true,
    component: ManagementPenugasan,
  },
  {
    exact: true,
    path: ["/dashboard/management-penugasan/penugasan-pelatihan/:assign_id"],
    isSidebar: true,
    isHeader: true,
    component: ManagementPenugasanDetail,
  },
  {
    exact: true,
    path: ["/dashboard/analitik-pembelajaran"],
    isSidebar: true,
    isHeader: true,
    component: DataAnalyticPembelajaran,
  },
  {
    exact: true,
    path: ["/dashboard/analitik-pembelajaran/user/:user_id"],
    isSidebar: true,
    isHeader: true,
    component: AnalyticUser,
  },
  {
    exact: true,
    path: ["/dashboard/analitik-pembelajaran/course/:type/:course_id"],
    isSidebar: true,
    isHeader: true,
    component: AnalyticCourse,
  },
  {
    exact: true,
    path: ["/dashboard/analitik-pembelajaran/ctt-cptt/:course_id"],
    isSidebar: true,
    isHeader: true,
    component: AnalyticCttCptt,
  },
  {
    exact: true,
    path: ["/dashboard/management-organisasi"],
    isSidebar: true,
    isHeader: true,
    component: ManagementOrganisasi,
  },
  {
    exact: true,
    path: ["/dashboard/management-organisasi/add-member"],
    isSidebar: true,
    isHeader: true,
    component: AddMember,
  },
  {
    exact: true,
    path: ["/dashboard/management-organisasi/:user_id"],
    isSidebar: true,
    isHeader: true,
    component: EditMember,
  },
  {
    exact: true,
    path: ["/dashboard/management-organisasi/edit/edit-companies"],
    isSidebar: true,
    isHeader: true,
    component: EditCompanies,
  },
  {
    exact: true,
    path: ["/login"],
    isSidebar: false,
    isHeader: false,
    component: Login,
  },
  {
    exact: true,
    path: ["/register/"],
    isSidebar: false,
    isHeader: false,
    component: Register,
  },
  {
    exact: true,
    path: ["/email-verified/"],
    isSidebar: false,
    isHeader: false,
    component: EmailVerified,
  },
  {
    exact: true,
    path: ["/forgot-password/"],
    isSidebar: false,
    isHeader: false,
    component: ForgotPassword,
  },
  {
    exact: true,
    path: ["/verify_reset_password"],
    isSidebar: false,
    isHeader: false,
    component: ResetPassword,
  },
  {
    exact: true,
    path: ["/handler/:auth/:destination"],
    isSidebar: false,
    isHeader: false,
    component: HandleRedirect,
  },
];

export default RouteData;
