import {
  //get
  TOPIC_DATA,
  TOPIC_DATA_ID_SUCCESS,
  TOPIC_DATA_SUCCESS,
  TOPIC_DATA_FAIL,
  //post
  TOPIC_DATA_POST,
  TOPIC_DATA_POST_SUCCESS,
  TOPIC_DATA_POST_FAIL,
  //put
  TOPIC_DATA_PUT,
  TOPIC_DATA_PUT_SUCCESS,
  TOPIC_DATA_PUT_FAIL,
  //delete
  TOPIC_DATA_DELETE,
  TOPIC_DATA_DELETE_SUCCESS,
  TOPIC_DATA_DELETE_FAIL,
  //remove
  REMOVE_TOPIC_DATA,
} from "../config/TopicConfig";

const intialState = {
  topicDataPost: "",
  topicDataPut: "",
  topicDataDelete: "",
  topicData: [],
  topicDataId: [],
  isLoading: false,
};

export const TopicReducers = function (state = intialState, action) {
  switch (action.type) {
    //get
    case TOPIC_DATA:
      return { ...state, isLoading: true };
    case TOPIC_DATA_SUCCESS:
      return { ...state, isLoading: false, topicData: action.value };
    case TOPIC_DATA_ID_SUCCESS:
      return { ...state, isLoading: false, topicDataId: action.value };
    case TOPIC_DATA_FAIL:
      return {
        ...state,
        isLoading: false,
        topicData: "error",
        topicDataId: "error",
      };
    //post
    case TOPIC_DATA_POST:
      return { ...state, isLoading: true, topicDataPost: "" };
    case TOPIC_DATA_POST_SUCCESS:
      return { ...state, isLoading: false, topicDataPost: "success" };
    case TOPIC_DATA_POST_FAIL:
      return { ...state, isLoading: false, topicDataPost: "error" };

    //put
    case TOPIC_DATA_PUT:
      return { ...state, isLoading: true, topicDataPut: "" };
    case TOPIC_DATA_PUT_SUCCESS:
      return { ...state, isLoading: false, topicDataPut: "success" };
    case TOPIC_DATA_PUT_FAIL:
      return { ...state, isLoading: false, topicDataPut: "error" };

    //delete
    case TOPIC_DATA_DELETE:
      return { ...state, isLoading: true, topicDataDelete: "" };
    case TOPIC_DATA_DELETE_SUCCESS:
      return { ...state, isLoading: false, topicDataDelete: "success" };
    case TOPIC_DATA_DELETE_FAIL:
      return { ...state, isLoading: false, topicDataDelete: "error" };

    case REMOVE_TOPIC_DATA:
      return {
        ...state,
        topicDataPost: "",
        topicDataDelete: "",
        topicDataPut: "",
      };
    default:
      return state;
  }
};
