import React, { useEffect, useState } from "react";
import { Button, Row, Col, Container, Form, Card } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getLoginDataThunk } from "../../redux/thunks/LoginThunks";
import { Clone } from "../../helper/functions";
import { AuthLogin, AuthIsNotLogin } from "../../helper/auth";
import "../../assets/css/Account/index.css";
import { SkValidate } from "../../components/common/SkValidate";
import { FaUserAlt, FaLock, FaEye, FaEyeSlash } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);
const sha256 = require("js-sha256");

function Login() {
  const [trigger, setTrigger] = useState(false);
  const [show, setShow] = useState(false);
  const [data, setData] = useState({
    password: "",
    username: "",
  });
  const login = useSelector((state) => state.login);
  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    AuthIsNotLogin(history);
  }, []);

  useEffect(() => {
    if (trigger && login.loginData) {
      if (login.loginData.status === "success") {
        let newData = Clone(data);
        newData.userId = login.loginData.data.user_id;
        newData.role = login.loginData.data.role;
        AuthLogin(newData);
        history.push("/dashboard/overview");
      } else {
        setTrigger(false);
        displayPopup(login.loginData.data, "error");
      }
    }
  }, [login.loginData]);

  const onChangeHandler = (e) => {
    const newData = { ...data };
    newData[e.target.id] =
      e.target.id === "price" ? parseInt(e.target.value) : e.target.value;
    setData(newData);
  };
  const constraints = {
    email: {
      presence: true,
      length: {
        maximum: 100,
      },
      email: true,
    },
    password: {
      presence: true,
      length: {
        minimum: 6,
        maximum: 100,
      },
    },
  };
  function onSubmitHandler(e) {
    e.preventDefault();
    const form = e.target;

    const isValid = SkValidate(form, constraints);
    if (isValid) {
      let body = Clone(data);
      body.password = sha256(data.password);
      setTrigger(true);
      dispatch(getLoginDataThunk(body));
    }
  }

  function displayPopup(title, icon) {
    MySwal.fire({
      title: title,
      showConfirmButton: false,
      icon: icon,
      showCloseButton: true,
      timer: 1500,
    }).then((value) => {
      // console.log(nextPage);
    });
  }

  const handleShowPassword = () => {
    setShow(!show);
    show
      ? (document.getElementById("password").type = "password")
      : (document.getElementById("password").type = "text");
  };

  return (
    <Container className="height-full">
      <Row className="justify-content-center align-items-center height-inherit">
        <Col xs={12} sm={12} md={5} lg={4}>
          <div className="text-center">
            <div className="login-title">Masuk</div>
          </div>
          <Card className="p-4">
            <Form onSubmit={onSubmitHandler}>
              <Form.Group>
                <div className="login-label">Email</div>
                <div className="position-absolute p-3 d-flex">
                  <FaUserAlt />
                </div>
                <Form.Control
                  className="mb-3 input100"
                  type="text"
                  placeholder="Masukkan Email Anda"
                  id="username"
                  name="email"
                  value={data.username}
                  onChange={onChangeHandler}
                />
                <div className="messages"></div>
              </Form.Group>
              <Form.Group>
                <div className="login-label">Kata Sandi</div>
                <div className="position-absolute p-3 d-flex">
                  <FaLock />
                </div>
                <Form.Control
                  className="input100"
                  type="password"
                  placeholder="Masukkan Kata Sandi Anda"
                  id="password"
                  name="password"
                  value={data.password}
                  onChange={onChangeHandler}
                />

                <div className="messages"></div>
              </Form.Group>
              <div className="mb-4" onClick={handleShowPassword}>
                {show ? (
                  <div className="text_show__password">
                    <FaEyeSlash className="mr-2" />{" "}
                    <div className="text_info__password">
                      Sembunyikan Password
                    </div>
                  </div>
                ) : (
                  <div className="text_show__password">
                    <FaEye className="mr-2" />{" "}
                    <div className="text_info__password">Lihat Password</div>
                  </div>
                )}
              </div>
              <div className="mb-3 ">
                <div className="text-right ">
                  <a
                    href="/forgot-password/"
                    className="text-decoration-none login-forgot-text"
                  >
                    Lupa Password?
                  </a>
                </div>
              </div>
              <Button type="submit" className="w-100 login-button">
                <div className="login-text">Masuk</div>
              </Button>
              <div className="text-center login-register-text">
                Bukan Anggota?{" "}
                <span>
                  <a
                    href="/register/"
                    className="text-decoration-none fw-700 login-register-text__span"
                  >
                    Daftar Sekarang!
                  </a>
                </span>
              </div>
            </Form>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default Login;
