import React, { useEffect, useState } from "react";
import { Row, Col, Container } from "react-bootstrap";
import AccordionTopicName from "../../components/AccordionTopicName";
import BannerTopCard from "../../components/BannerTopCard";
import BenefitCard from "../../components/BenefitCard";
import DeskripsiCard from "../../components/DeskripsiCard";
import CardAudience from "../../components/CardAudience";
import PrerequisitesCourse from "../../components/PrerequisitesCourse";
import CardCoveredSkill from "../../components/CardCoveredSkill";
import RequirementCard from "../../components/RequirementCard";
import InfoCard from "../../components/InfoCard";
import CorporateMaterial from "../../components/CorporateMaterial";
import {
  getCoursesDataThunk,
} from "../../redux/thunks/CoursesThunks";
import { useSelector, useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { AuthIsLogin } from "../../helper/auth";
import { useHistory } from "react-router-dom";
import "./../../assets/css/Courses/CourseFront.css";
import "../../assets/css/Dashboard/index.css";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
const MySwal = withReactContent(Swal);

function PreviewCourse() {
  const [render, setRender] = useState(false);
  const [topics, setTopics] = useState({ general: [], company: [] });
  const courses = useSelector((state) => state.courses);
  const course = courses.courseDataId;
  const topicsState = useSelector((state) => state.topics);
  const lessons = useSelector((state) => state.lessons);
  const quizzes = useSelector((state) => state.quizzes);
  const webinars = useSelector((state) => state.webinars);
  const assignments = useSelector((state) => state.assignments);
  const users = useSelector((state) => state.users);
  const { course_id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    if (course.topics) {
      let topicsTemp = { general: [], company: [] };
      for (let i = 0; i < course.topics.length; i++) {
        if (course.topics[i].company_id) {
          topicsTemp.company.push(course.topics[i]);
        } else topicsTemp.general.push(course.topics[i]);
      }
      setTopics(topicsTemp);
      if (users.usersDataId?.company_id) {
        if (
          courses.courseDataId?.company_id !== users.usersDataId?.company_id
        ) {
          setRender(true);
        } else setRender(false);
      } else setRender(true);
    }
  }, [courses]);

  useEffect(() => {
    if (!courses.courseDataId?.company_id === users.usersDataId.company_id) {
    }
  }, [courses, users]);

  useEffect(() => {
    AuthIsLogin(history);
  }, []);

  useEffect(() => {
    dispatch(getCoursesDataThunk(course_id));
  }, [, topicsState, lessons, quizzes, webinars, assignments]);

  function getCourseData() {
    dispatch(getCoursesDataThunk(course_id));
  }
  
  return (
    <Container>
      <Link to="#" className="mb-3" onClick={() => history.goBack()}>
        Halaman Sebelumnya
      </Link>
      <Row>
        <Col md={8} className="mb-4">
          {/* banner.. */}
          <BannerTopCard item={course} className="mb-4" />
          {/* objectives.. */}
          <BenefitCard items={course?.objectives} />
          {/* topics.. */}
          {course?.course_type && render ? (
            <AccordionTopicName
              item={course}
              topicsList={topics.general}
              className="mb-3"
              fixedTitle={course?.title}
              getCourseData={getCourseData}
            />
          ) : null}
          {/* corporate materials.. */}
          {course?.course_type === "Pelatihan" && topics.company?.length > 0 ? (
            <CorporateMaterial
              item={course}
              topicsList={topics.company}
              className="mb-3"
            />
          ) : null}
          <CardCoveredSkill items={course?.skills_covered} />{" "}
          <DeskripsiCard item={course?.description} />
          <RequirementCard items={course?.requirements} />
          {/* audiences.. */}
          <CardAudience items={course?.audiences} />
          {/* prerequisite course.. */}
          <PrerequisitesCourse items={course?.prerequisites} />
        </Col>
        <Col md={4}>
          {/* side info.. */}
          <InfoCard item={course} />
        </Col>
      </Row>
    </Container>
  );
}

export default PreviewCourse;
