import React from "react";
import { Button, Modal } from "react-bootstrap";
import Library from "../Library";

function MediaLibraryModal({
  setSelectedLibary,
  title,
  handleThumbnail,
  libraryHandler,
  show,
  setShow,
}) {
  const handleClose = () => {
    setSelectedLibary("");
    setShow(false);
  };
  return (
    <>
      <Modal
        show={show}
        dialogClassName="modal-90w"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        style={{ overflow: "auto" }}
        className="modal-media-library"
      >
        <Modal.Header className="px-4">
          <Modal.Title id="contained-modal-title-vcenter">{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-4">
          <Library libraryHandler={libraryHandler} handleClose={handleClose} />
        </Modal.Body>
        <Modal.Footer className="px-4">
          <Button variant="danger" onClick={handleClose}>
            Batal
          </Button>
          <Button variant="primary" onClick={handleThumbnail}>
            Pilih
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default MediaLibraryModal;
