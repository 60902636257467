import React from "react";
import { Card } from "react-bootstrap";
function RequirementCard({ items }) {
  return (
    <div className="requirement__wrapper">
      <div className="course_front__inner_title">Persyaratan</div>
      <Card className="mb-4 padding_card">
        <div className="course_front__persyaratan mb-0">
          <ul className={`${items?.length > 0 ? "list" : "p-0 mb-0"}`}>
            {items?.length > 0 ? (
              items.map((e, i) => <li key={i}>{e}</li>)
            ) : (
              <div>Data Belum Tersedia</div>
            )}
          </ul>
        </div>
      </Card>
    </div>
  );
}

export default RequirementCard;
