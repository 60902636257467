import React, { useState, useEffect } from "react";
import { Button, Col } from "react-bootstrap";
// import { getAnalyticsUserDataThunk } from "../redux/thunks/AnalyticsUserThu<<<<<<< .merge_file_a03304
import {
  getAnalyticsGroupDataThunk,
  // getAnalyticsCompletedDataThunk,
} from "../redux/thunks/AnalyticsGroupThunks";
import "../assets/css/Dashboard/companyAnalytics.css";
import { useDispatch, useSelector } from "react-redux";
import ClassList from "./ClassList";
import PopularCourse from "./PopularCourse";
import PartisipasiSertifikasi from "./PartisipasiSertifikasi";
import PostTestList from "./PostTestList";
import PreTestList from "./PreTestList";
import StatusSertifikasi from "./StatusSertifikasi";
import ActiveUsers from "./ActiveUsers";
import AkumulasiCourse from "./AkumulasiCourse";
import StatusPostTest from "./StatusPostTest";
import StatusTryOut from "./StatusTryOut";
import PartisipanTryOut from "./PartisipanTryOut";
import WebinarList from "./WebinarList";
import StudentList from "./StudentList";
import TopBannerAnalytics from "./TopBannerAnalytics";
import StatusPreTest from "./StatusPreTest";
import MateriCompany from "./MateriCompany";
import { Document, Page, PDFDownloadLink, StyleSheet, Text, View } from "@react-pdf/renderer";
import moment from "moment";
import { getCoursesCompletedDataThunk } from "../redux/thunks/CoursesThunks";
function CompanyAnalytics() {
  const [dataAdmin, setDataAdmin] = useState();
  const [dataDetail, setDataDetail] = useState();
  const [dataAnalytics, setDataAnalytics] = useState();
  const [loaded, setLoaded] = useState(false);
  // state from redux
  const analyticsgroup = useSelector((state) => state.analyticsgroup);
  const users = useSelector((state) => state.users);

  const dispatch = useDispatch();
  // get date of nth week
  const generateWeekDate = (analyticsgroup, i) => {
    return moment(analyticsgroup.analyticsCompletedData?.data?.Konsultasi?.datelist[i].start).format("MMM D");
  };

  // get array of nth week with date
  const generateWeeklist = (analyticsgroup) => {
    const result = [];
    for (let i = 0; i < 8; i++) {
      const e = `Week ${analyticsgroup.analyticsCompletedData?.data?.Konsultasi?.weeklist[i]}, ${generateWeekDate(analyticsgroup, i)}`;
      result.push(e);
    }
    return result;
  };

  const weeklist = generateWeeklist(analyticsgroup);
  const series = [];
  for (let i = 0; i < analyticsgroup.analyticsCompletedData?.course_types?.length; i++) {
    const key = analyticsgroup.analyticsCompletedData?.course_types[i];
    series.push({
      data: analyticsgroup.analyticsCompletedData?.data[key]?.counts,
    });
  }
  // generate docs
  const MyDocs = (dataAnalytics) => (
    <Document title={`Analitik Data ${dataAnalytics?.name}`}>
      <Page style={styles.body}>
        {/* admin list info and company name */}
        <View style={styles.titleheader}>
          <Text style={styles.text}>{`Admin : ${dataAnalytics?.admins.map((item, index) => {
            return item?.name;
          })}`}</Text>
          <Text style={styles.text}>Perusahaan : {dataAnalytics?.name}</Text>
        </View>
        {/* employee list */}
        <View style={styles.sectionedge}>
          <Text style={styles.title}>Daftar {users && users?.usersDataId?.is_bnsp === 1 ? "Partisipan" : "Karyawan"}</Text>
          <View style={styles.tabletitle}>
            <View style={styles.row}>
              <View style={styles.colheader}>
                <Text>Nama {users && users?.usersDataId?.is_bnsp == 1 ? "Partisipan" : "Karyawan"}</Text>
              </View>
              <View style={styles.colheader}>
                <Text>Posisi</Text>
              </View>
            </View>

            {dataAnalytics?.students?.map((item, index) => {
              return (
                <View key={index} style={styles.row}>
                  <View style={styles.col}>
                    <Text style={styles.text}>{item.name}</Text>
                  </View>
                  <View style={styles.col}>
                    <Text style={styles.text}>{item.position === "" ? "-" : item.position}</Text>
                  </View>
                </View>
              );
            })}
          </View>
        </View>
        {/* Statistic Active Users and Membership Slot */}
        <View style={[styles.rowbetween, styles.sectionedge]}>
          <View>
            <Text>Jumlah User Aktif</Text>
            <Text>{dataAnalytics?.active_users.length}</Text>
          </View>
          <View>
            <Text>Slot Membership</Text>
            <Text>{`${dataAnalytics?.students?.length}/${dataAnalytics?.max_students}`}</Text>
          </View>
        </View>
        {/* Accumulation Course  */}
        <View style={styles.sectionedge}>
          <View>
            <Text style={[styles.sectionedge, styles.title]}>Akumulasi Course Selesai</Text>
          </View>
          <View style={[styles.tabletitle, styles.sectionedge]}>
            <View style={styles.row}>
              <View style={[styles.col, styles.width]}>
                <Text style={styles.titleheaderacumulation}>Minggu ke-</Text>
              </View>
              {analyticsgroup.analyticsCompletedData?.course_types.map((item, index) => {
                return (
                  <View key={index} style={styles.colaccumulation}>
                    <Text style={styles.titleheaderacumulation}>{item}</Text>
                  </View>
                );
              })}
            </View>
            <View style={styles.row}>
              <View style={[styles.col, styles.width]}>
                {weeklist.length > 0 &&
                  weeklist.map((item, index) => {
                    return (
                      <Text key={index} style={styles.titleweeklist}>
                        {item}
                      </Text>
                    );
                  })}
              </View>

              {series.length > 0 &&
                series?.map((item, index) => {
                  return (
                    <View key={index} style={styles.colaccumulation}>
                      {item?.data?.map((e, i) => {
                        return <Text style={styles.text}>{e}</Text>;
                      })}
                    </View>
                  );
                })}
            </View>
          </View>
        </View>
        {/* Class List */}
        <Text style={styles.title}>{`Daftar Kelas ${dataAnalytics?.name}`}</Text>
        <View style={[styles.tabletitle, styles.sectionedge]}>
          <View style={styles.row}>
            <View style={styles.colheader}>
              <Text>Nama Pelatihan</Text>
            </View>
            <View style={styles.colheader}>
              <Text>Kategori</Text>
            </View>
            <View style={styles.colheader}>
              <Text>Partisipan</Text>
            </View>
          </View>
          {dataAnalytics &&
            dataAnalytics?.popular_pelatihan?.map((item, index) => {
              return (
                <View key={index} style={styles.row}>
                  <View style={styles.col}>
                    <Text style={styles.text}>{item.course}</Text>
                  </View>
                  <View style={styles.col}>
                    <Text style={styles.text}>{item.categories === "" ? "-" : item.categories}</Text>
                  </View>
                  <View style={styles.col}>
                    <Text style={styles.text}>{item.participants}</Text>
                  </View>
                </View>
              );
            })}
        </View>
        {/* 10 Popular Class List */}
        <Text style={styles.title}>{`10 Kelas Populer ${dataAnalytics?.name}`}</Text>
        <View style={[styles.tabletitle, styles.sectionedge]}>
          <View style={styles.row}>
            <View style={styles.colheader}>
              <Text>Nama Pelatihan</Text>
            </View>

            <View style={styles.colheader}>
              <Text>Partisipan</Text>
            </View>
          </View>
          {dataAnalytics?.popular_courses?.length > 0
            ? dataAnalytics?.popular_courses?.slice(0, 10).map((item, index) => {
                return (
                  <View key={index} style={styles.row}>
                    <View style={styles.col}>
                      <Text style={styles.text}>{item.course}</Text>
                    </View>

                    <View style={styles.col}>
                      <Text style={styles.text}>{item.participants}</Text>
                    </View>
                  </View>
                );
              })
            : null}
        </View>
        <View style={styles.sectionedge}>
          <Text style={[styles.sectionedge, styles.title]}>Partisipan Sertifikasi</Text>
          <View style={styles.tabletitle}>
            <View style={styles.row}>
              <View style={styles.colheader}>
                <Text>Nama Pelatihan</Text>
              </View>
              <View style={styles.colheader}>
                <Text>Partisipan</Text>
              </View>
              <View style={styles.colheader}>
                <Text>Rata Rata Perusahaan</Text>
              </View>
              <View style={styles.colheader}>
                <Text>Rata Rata Keseluruhan</Text>
              </View>
            </View>
            {dataAnalytics?.popular_sertifikasi?.map((item, index) => {
              return (
                <View key={index} style={styles.row}>
                  <View style={styles.col}>
                    <Text style={styles.text}>{item.course}</Text>
                  </View>
                  <View style={styles.col}>
                    <Text style={styles.text}>{item.participants}</Text>
                  </View>
                  <View style={styles.col}>
                    <Text style={styles.text}>{item.avg_score_internal}</Text>
                  </View>
                  <View style={styles.col}>
                    <Text style={styles.text}>{item.avg_score_global}</Text>
                  </View>
                </View>
              );
            })}
          </View>
        </View>

        <View style={styles.sectionedge}>
          <Text style={[styles.sectionedge, styles.title]}>Pre Test List</Text>
          <View style={styles.tabletitle}>
            <View style={styles.row}>
              <View style={styles.colheader}>
                <Text>Pre Test Kelas</Text>
              </View>
              <View style={styles.colheader}>
                <Text>Partisipan</Text>
              </View>
              <View style={styles.colheader}>
                <Text>Rata Rata Perusahaan</Text>
              </View>
              <View style={styles.colheader}>
                <Text>Rata Rata Keseluruhan</Text>
              </View>
            </View>
            {dataAnalytics?.popular_pre_test?.map((item, index) => {
              return (
                <View key={index} style={styles.row}>
                  <View style={styles.col}>
                    <Text style={styles.text}>{item.course}</Text>
                  </View>
                  <View style={styles.col}>
                    <Text style={styles.text}>{item.participants}</Text>
                  </View>
                  <View style={styles.col}>
                    <Text style={styles.text}>{item.avg_score_internal}</Text>
                  </View>
                  <View style={styles.col}>
                    <Text style={styles.text}>{item.avg_score_global}</Text>
                  </View>
                </View>
              );
            })}
          </View>
        </View>
        <View style={styles.sectionedge}>
          <Text style={[styles.sectionedge, styles.title]}>Summary Pre Test List</Text>
          <View>
            <View>
              <Text style={styles.text}>Lulus</Text>
              {dataAnalytics?.chart_pre_test && <Text style={styles.text}>{dataAnalytics?.chart_pre_test.passed.nominal}</Text>}
            </View>
            <View>
              <Text style={styles.text}>Tidak Lulus</Text>
              {dataAnalytics?.chart_pre_test && <Text style={styles.text}>{dataAnalytics?.chart_pre_test.failed.nominal}</Text>}
            </View>
          </View>
        </View>
        <View style={styles.sectionedge}>
          <Text style={[styles.sectionedge, styles.title]}>Post Test List</Text>
          <View style={styles.tabletitle}>
            <View style={styles.row}>
              <View style={styles.colheader}>
                <Text>Post Test Kelas</Text>
              </View>
              <View style={styles.colheader}>
                <Text>Partisipan</Text>
              </View>
              <View style={styles.colheader}>
                <Text>Rata Rata Perusahaan</Text>
              </View>
              <View style={styles.colheader}>
                <Text>Rata Rata Keseluruhan</Text>
              </View>
            </View>
            {dataAnalytics?.popular_post_test?.map((item, index) => {
              return (
                <View key={index} style={styles.row}>
                  <View style={styles.col}>
                    <Text style={styles.text}>{item.course}</Text>
                  </View>
                  <View style={styles.col}>
                    <Text style={styles.text}>{item.participants}</Text>
                  </View>
                  <View style={styles.col}>
                    <Text style={styles.text}>{item.avg_score_internal}</Text>
                  </View>
                  <View style={styles.col}>
                    <Text style={styles.text}>{item.avg_score_global}</Text>
                  </View>
                </View>
              );
            })}
          </View>
        </View>
        <View style={styles.sectionedge}>
          <Text style={[styles.sectionedge, styles.title]}>Summary Post Test List</Text>
          <View>
            <View>
              <Text style={styles.text}>Lulus</Text>
              {dataAnalytics?.chart_post_test && <Text style={styles.text}>{dataAnalytics?.chart_post_test.passed.nominal}</Text>}
            </View>
            <View>
              <Text style={styles.text}>Tidak Lulus</Text>
              {dataAnalytics?.chart_post_test && <Text style={styles.text}>{dataAnalytics?.chart_post_test.failed.nominal}</Text>}
            </View>
          </View>
        </View>
        <View style={styles.sectionedge}>
          <Text style={[styles.sectionedge, styles.title]}>Partisipan Try Out</Text>
          <View style={styles.tabletitle}>
            <View style={styles.row}>
              <View style={styles.colheader}>
                <Text>Try Out</Text>
              </View>
              <View style={styles.colheader}>
                <Text>Partisipan</Text>
              </View>
              <View style={styles.colheader}>
                <Text>Rata Rata Perusahaan</Text>
              </View>
              <View style={styles.colheader}>
                <Text>Rata Rata Keseluruhan</Text>
              </View>
            </View>
            {dataAnalytics?.popular_tryout?.map((item, index) => {
              return (
                <View key={index} style={styles.row}>
                  <View style={styles.col}>
                    <Text style={styles.text}>{item.course}</Text>
                  </View>
                  <View style={styles.col}>
                    <Text style={styles.text}>{item.participants}</Text>
                  </View>
                  <View style={styles.col}>
                    <Text style={styles.text}>{item.avg_score_internal}</Text>
                  </View>
                  <View style={styles.col}>
                    <Text style={styles.text}>{item.avg_score_global}</Text>
                  </View>
                </View>
              );
            })}
          </View>
        </View>
        <View style={styles.sectionedge}>
          <Text style={[styles.sectionedge, styles.title]}>Daftar Webinar</Text>
          <View style={styles.tabletitle}>
            <View style={styles.row}>
              <View style={styles.colheader}>
                <Text>Webinar</Text>
              </View>
              <View style={styles.colheader}>
                <Text>Partisipan</Text>
              </View>
            </View>
            {dataAnalytics?.popular_webinar?.map((item, index) => {
              return (
                <View key={index} style={styles.row}>
                  <View style={styles.col}>
                    <Text style={styles.text}>{item.course}</Text>
                  </View>
                  <View style={styles.col}>
                    <Text style={styles.text}>{item.participants}</Text>
                  </View>
                </View>
              );
            })}
          </View>
        </View>
      </Page>
    </Document>
  );

  useEffect(() => {
    if (users.usersDataId.company_id) {
      dispatch(
        getCoursesCompletedDataThunk({
          param: `?company_id=${users?.usersDataId.company_id}`,
        })
      );
      dispatch(
        getAnalyticsGroupDataThunk({
          param: `?company_id=${users?.usersDataId.company_id}`,
        })
      );
    }
  }, [users.usersDataId.company_id]);

  useEffect(() => {
    if (analyticsgroup.analyticsCourseStatsData) {
      setDataDetail(analyticsgroup?.analyticsCourseStatsData?.data);
    }
  }, [analyticsgroup]);

  useEffect(() => {
    if (analyticsgroup.analyticsGroupData) {
      setDataAnalytics(analyticsgroup.analyticsGroupData);
    }
  }, [analyticsgroup]);

  useEffect(() => {
    setDataAdmin(users.usersDataId);
  }, [users]);

  useEffect(() => {
    setLoaded(true);
  }, []);

  return (
    <>
      <Col md={12}>
        {loaded ? (
          <div className="d-flex justify-content-end">
            {/* Download PDF */}
            <PDFDownloadLink
              style={{
                height: "100%",
                width: "auto",
                marginBottom: "24px",
              }}
              document={MyDocs(dataAnalytics, analyticsgroup)}
              fileName={`Analytics ${dataAnalytics?.name}.pdf`}>
              {({ blob, url, loading, error }) => {
                if (error) console.log("error", error);
                return (
                  // loading for wait the data is ready
                  loading ? "Data sedang diproses..." : <Button className="button_export__pdf">Export Data</Button>
                );
              }}
            </PDFDownloadLink>
          </div>
        ) : null}
      </Col>
      <Col md={12} className="mb-4">
        <TopBannerAnalytics data={dataAdmin} adminBnsp={users} />
      </Col>
      <Col md={8} className="mb-4">
        {/* Student List */}
        <StudentList data={dataAnalytics} adminBnsp={users} />
      </Col>

      <Col md={4} className="mb-4">
        {/* Active Users */}
        <ActiveUsers data={dataAnalytics} userExpire={users} />
      </Col>

      <Col md={12} className="mb-4">
        {/* Accumulation Finished Course */}
        {analyticsgroup.analyticsGroupData && <AkumulasiCourse weeklist={weeklist} series={series} analytics={analyticsgroup} />}
      </Col>
      <Col md={12} className="mb-4">
        {/* Enrolled Class from Student in Company */}
        <ClassList data={dataAnalytics} adminBnsp={users} />
      </Col>
      <Col md={12} className="mb-4">
        {/* Popular Class in company */}
        <PopularCourse data={dataAnalytics} />
      </Col>
      <Col md={4} className="mb-4">
        {/* Chart Data for Certification Status */}
        {dataAnalytics && <StatusSertifikasi data={dataAnalytics} />}
      </Col>
      <Col md={8} className="mb-4">
        {/* Table List for Participant Certification */}
        {dataAnalytics && <PartisipasiSertifikasi data={dataAnalytics} />}
      </Col>
      <Col md={8} className="mb-4">
        {/* Table List Pre Test */}
        <PreTestList data={dataAnalytics} adminBnsp={users} />
      </Col>
      <Col md={4} className="mb-4">
        {/* Chart Data for Pre Test Status */}
        <StatusPreTest data={dataAnalytics} adminBnsp={users} />
      </Col>
      <Col md={4} className="mb-4">
        {/* Chart Data for Post Test Status */}
        <StatusPostTest data={dataAnalytics} />
      </Col>
      <Col md={8} className="mb-4">
        {/* Table List Post Test */}
        <PostTestList data={dataAnalytics} adminBnsp={users} />
      </Col>
      <Col md={8} className="mb-4">
        {/* Table List Try Out Participant */}
        {dataAnalytics && <PartisipanTryOut data={dataAnalytics} adminBnsp={users} />}
      </Col>
      <Col md={4} className="mb-4">
        {/* Chart Data for Try Out Status */}
        {dataAnalytics && <StatusTryOut data={dataAnalytics} />}
      </Col>

      <Col md={12} className="mb-4">
        {/* Table List Webinar */}
        <WebinarList data={dataAnalytics} adminBnsp={users} />
      </Col>
      {users && users?.usersDataId?.is_bnsp === 1 ? null : (
        <Col md={12} className="mb-5">
          {/* Company Materi List */}
          <MateriCompany />
        </Col>
      )}
    </>
  );
}

export default CompanyAnalytics;

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  titleheaderacumulation: {
    fontSize: 8,
    textAlign: "left",
    fontWeight: 700,
  },
  titleweeklist: {
    fontSize: 11,
    textAlign: "left",
    fontWeight: 700,
    marginBottom: 8,
  },
  title: {
    fontSize: 11,
    textAlign: "left",
    // fontFamily: "Alatsi",
    marginBottom: 16,
  },
  text: {
    fontSize: 11,
    marginBottom: 8,
  },
  tabletitle: {
    display: "flex",
    flexDirection: "column",
    fontSize: 11,
  },
  colheader: {
    flex: 4,
    borderTop: 1,
    borderBottom: 1,
    padding: 8,
    fontWeight: "bold",
  },
  col: {
    flex: 4,
    borderTop: 1,
    borderBottom: 1,
    padding: 8,
  },
  colaccumulation: {
    flex: 1,
    borderTop: 1,
    borderBottom: 1,
    padding: 8,
  },
  row: {
    display: "flex",
    minHeight: 30,
    flexDirection: "row",
  },
  rowbetween: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    fontSize: 11,
  },
  width: {
    width: 100,
  },
  sectionedge: {
    marginBottom: 16,
  },
  column: {
    display: "flex",
    flexDirection: "row",
    width: 125,
    justifyContent: "space-between",
  },
  list: {
    display: "flex",
    flexDirection: "column   ",
  },
  titleheader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 16,
  },
});
