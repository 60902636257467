import { PromiseService } from "../../services/PromiseService";
import { INSTRUCTORS_DATA_LINK } from "../config/InstructorsConfig";
import {
  //get
  getInstructorsData,
  getInstructorsDataSuccess,
  getInstructorsDataIdSuccess,
  getInstructorsDataFail,

  // live search
  getInstructorsLiveSearchData,
  getInstructorsLiveSearchDataSuccess,
  getInstructorsLiveSearchDataFail,
} from "../actions/InstructorsActions";

//get live search

export const getInstructorsLiveSearchDataThunk = (data) => async (dispatch) => {
  dispatch(getInstructorsLiveSearchData(data));
  let url = `${INSTRUCTORS_DATA_LINK}${data}`;
  try {
    const response = await PromiseService.getData(url);
    if (response.status === 200 && response.data) {
      dispatch(getInstructorsLiveSearchDataSuccess(response.data));
    } else {
      dispatch(getInstructorsLiveSearchDataFail(response));
    }
  } catch (error) {
    dispatch(getInstructorsLiveSearchDataFail(error));
  }
};

export const getInstructorsDataThunk = (data) => async (dispatch) => {
  dispatch(getInstructorsData());
  if (data) {
    let url = `${INSTRUCTORS_DATA_LINK}/${data}`;
    if (data.param) url = `${INSTRUCTORS_DATA_LINK}${data.param}`;
    try {
      const response = await PromiseService.getData(url);
      if (response.status === 200 && response.data) {
        if (data.param) dispatch(getInstructorsDataSuccess(response.data));
        else dispatch(getInstructorsDataIdSuccess(response.data));
      } else {
        dispatch(getInstructorsDataFail(response));
      }
    } catch (error) {
      dispatch(getInstructorsDataFail(error));
    }
  } else {
    try {
      const response = await PromiseService.getData(INSTRUCTORS_DATA_LINK);
      if (response.status === 200 && response.data) {
        dispatch(getInstructorsDataSuccess(response.data));
      } else {
        dispatch(getInstructorsDataFail(response));
      }
    } catch (error) {
      dispatch(getInstructorsDataFail(error));
    }
  }
};
