import React from "react";
import { Card } from "react-bootstrap";
function DeskripsiCard({ item }) {
  return (
    <div className="course_front__deskripsi__wrapper">
      <div className="course_front__inner_title">Deskripsi</div>
      <Card className="mb-4 padding_card">
        <div className="course_front__deskripsi mb-0">
          {item ? (
            <div
              dangerouslySetInnerHTML={{
                __html: item,
              }}
            />
          ) : (
            "Data Belum Tersedia"
          )}
        </div>
      </Card>
    </div>
  );
}

export default DeskripsiCard;
