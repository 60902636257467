import React, { useEffect } from "react";
import { Row, Col, Container } from "react-bootstrap";
import "../../assets/css/Dashboard/index.css";
import UpdateLesson from "../../components/UpdateLesson";
import { AuthIsLogin } from "../../helper/auth";
import { useHistory } from "react-router-dom";

function EditLesson() {
  const history = useHistory();
  useEffect(() => {
    AuthIsLogin(history);
  }, []);
  return (
    <Container>
      <Row className="justify-content-center align-items-center height-inherit">
        <Col xs={12} sm={12} md={9} lg={9} className="p-0">
          <div className=" pt-2 pb-3">
            <h4 className="fw-600">Edit Pelajaran</h4>
          </div>
        </Col>

        <Col
          xs={12}
          sm={12}
          md={9}
          lg={9}
          className=" bg-white br-16 p-6 bx-shdw mb-4"
        >
          <UpdateLesson />
        </Col>
      </Row>
    </Container>
  );
}

export default EditLesson;
