import * as React from "react";
import { useEffect, useRef, useState } from "react";
import dayjs from "dayjs";
import Badge from "@mui/material/Badge";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { PickersDay } from "@mui/x-date-pickers/PickersDay";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { DayCalendarSkeleton } from "@mui/x-date-pickers/DayCalendarSkeleton";
import mascot from "../assets/images/not-found.svg";
import "../assets/css/Dashboard/Overview.css";
import { Link } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { AnalyticLearningService } from "../http/AnalyticLearningHttp";
import moment from "moment";
import { slugify } from "../helper/functions";

export default function DateCalendarServerRequest(props) {
  const { data } = props;
  const users = useSelector((state) => state.users);
  let companyId = users?.usersDataId?.company_id;
  const initialValue = dayjs(new Date());
  const requestAbortController = useRef(null);
  const [currentLiveCourseData, setCurrentLiveCourseData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [highlightedDays, setHighlightedDays] = useState([]);
  const [calendarData, setCalendarData] = useState(null);
  const [selectedDate, setSelectedDate] = useState(initialValue);

  useEffect(() => {
    // let companyId = users?.usersDataId?.company_id;
    // if (companyId) {
    //   getCalendarCourse(`/${companyId}?month=5&year=2024`);
    // }
  }, [users]);

  async function fetchData(date, { signal, param }) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await AnalyticLearningService.getCalendarCourse(param);
        if (response) {
          setCalendarData(response);
          const daysWithCourses = Object.keys(response?.calendar).filter(
            (day) =>
              response?.calendar[day]?.mandatory_courses.length > 0 ||
              response?.calendar[day]?.consultations.length > 0 ||
              response?.calendar[day]?.webinars.length > 0
          );
          let currentLiveCourse = Object.keys(response?.calendar).filter(
            (day) => moment().format("YYYY-MM-DD") === day
          );
          if (currentLiveCourse.length > 0) {
            let arrThisDay = [];
            let onThisDay = response?.calendar[currentLiveCourse];
            let tmp = {};
            if (onThisDay?.consultations?.length > 0) {
              for (let i = 0; i < onThisDay.consultations.length; i++) {
                tmp = {
                  ...tmp,
                  name: onThisDay.consultations[i].webinar,
                  time: moment(onThisDay.consultations[i].time_starts)
                    .subtract(7, "hours")
                    .format("HH:mm"),
                  category: "live",
                  type: "Jadwal Live Consultation",
                  id: onThisDay.consultations[i].id,
                };
                arrThisDay.push(tmp);
              }
            }

            if (onThisDay?.webinars?.length > 0) {
              for (let i = 0; i < onThisDay.webinars.length; i++) {
                tmp = {
                  ...tmp,
                  name: onThisDay.webinars[i].webinar,
                  time: moment(onThisDay.webinars[i].time_starts)
                    .subtract(7, "hours")
                    .format("HH:mm"),
                  type: "Jadwal Webinar",
                  category: "live",
                  id: onThisDay.webinars[i].id,
                };
                arrThisDay.push(tmp);
              }
            }

            if (onThisDay?.mandatory_courses?.length > 0) {
              for (let i = 0; i < onThisDay.mandatory_courses.length; i++) {
                tmp = {
                  ...tmp,
                  name: onThisDay.mandatory_courses[i].course,
                  time: onThisDay.mandatory_courses[i].deadline_hour,
                  type: "Deadline Penugasan",
                  category: "mandatory_course",
                  id: onThisDay.mandatory_courses[i].company_assignment_id,
                };
                arrThisDay.push(tmp);
              }
            }
            setCurrentLiveCourseData(arrThisDay);
          }
          let arr = daysWithCourses.map((day) =>
            parseInt(moment(day).format("D"))
          );
          resolve({ daysToHighlight: arr });
        }
      } catch (err) {
        console.log(err);
        reject(err);
      }
    });
  }

  function ServerDay(props) {
    const { day, outsideCurrentMonth, onDateClick, ...other } = props;

    const handleClick = () => {
      if (onDateClick) {
        onDateClick(moment(day["$d"]).format("YYYY-MM-DD"));
      }
    };
    const isSelected =
      !props.outsideCurrentMonth &&
      props.highlightedDays.indexOf(props.day.date()) >= 0;

    const badgeContent = isSelected ? (
      <div
        // onClick={() => onDateClick(day)} // Invoke the callback with the date when the badge is clicked
        dangerouslySetInnerHTML={{
          __html: `<svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 6 6" fill="none">
            <circle cx="3" cy="3" r="3" fill="#55AA73"/>
          </svg>`,
        }}
      />
    ) : undefined;

    return (
      <Badge
        key={props.day.toString()}
        overlap="circular"
        badgeContent={badgeContent}
      >
        <PickersDay
          {...other}
          onClick={handleClick}
          outsideCurrentMonth={outsideCurrentMonth}
          day={day}
        />
      </Badge>
    );
  }

  const fetchHighlightedDays = (date, param) => {
    const controller = new AbortController();
    fetchData(date, {
      signal: controller.signal,
      param: param,
    })
      .then(({ daysToHighlight }) => {
        setHighlightedDays(daysToHighlight);
        setIsLoading(false);
      })
      .catch((error) => {
        if (error.name !== "AbortError") {
          throw error;
        }
      });

    requestAbortController.current = controller;
  };

  useEffect(() => {
    if (companyId) {
      const currentDate = new Date();
      const currentMonth = currentDate.getMonth() + 1; // getMonth() returns 0-11, so add 1 for 1-12
      const currentYear = currentDate.getFullYear();
      let param = `/${companyId}?month=${currentMonth}&year=${currentYear}`;
      fetchHighlightedDays(selectedDate, param);
      return () => requestAbortController.current?.abort();
    }
  }, [companyId]);

  const handleMonthChange = (date) => {
    if (requestAbortController.current) {
      requestAbortController.current.abort();
    }
    let selectedMonth = moment(date["$d"]).format("M");
    let selectedYear = moment(date["$d"]).format("YYYY");
    setIsLoading(true);
    setHighlightedDays([]);

    let param = `/${companyId}?month=${selectedMonth}&year=${selectedYear}`;
    fetchHighlightedDays(date, param);
  };

  const handleDateClick = (clickedDate) => {
    setSelectedDate(clickedDate);
    let currentLiveCourse = Object.keys(calendarData?.calendar).filter(
      (day) => clickedDate === day
    );
    if (currentLiveCourse.length > 0) {
      let arrThisDay = [];
      let onThisDay = calendarData?.calendar[currentLiveCourse];
      let tmp = {};
      if (onThisDay.consultations.length > 0) {
        for (let i = 0; i < onThisDay.consultations.length; i++) {
          tmp = {
            ...tmp,
            name: onThisDay.consultations[i].webinar,
            time: moment(onThisDay.consultations[i].time_starts)
              .subtract(7, "hours")
              .format("HH:mm"),
            category: "live",
            type: "Jadwal Live Consultation",
            id: onThisDay.consultations[i].id,
          };
          arrThisDay.push(tmp);
        }
      }

      if (onThisDay.webinars.length > 0) {
        for (let i = 0; i < onThisDay.webinars.length; i++) {
          tmp = {
            ...tmp,
            name: onThisDay.webinars[i].webinar,
            time: moment(onThisDay.webinars[i].time_starts)
              .subtract(7, "hours")
              .format("HH:mm"),
            type: "Jadwal Webinar",
            category: "live",
            id: onThisDay.webinars[i].id,
          };
          arrThisDay.push(tmp);
        }
      }

      if (onThisDay.mandatory_courses.length > 0) {
        for (let i = 0; i < onThisDay.mandatory_courses.length; i++) {
          tmp = {
            ...tmp,
            name: onThisDay.mandatory_courses[i].course,
            time: onThisDay.mandatory_courses[i].deadline_hour,
            type: "Deadline Penugasan",
            category: "mandatory_course",
            id: onThisDay.mandatory_courses[i].company_assignment_id,
          };
          arrThisDay.push(tmp);
        }
      }
      setCurrentLiveCourseData(arrThisDay);
    }
    // You can store or process the clicked date as needed
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Row>
        <Col lg={5}>
          <DateCalendar
            defaultValue={selectedDate}
            loading={isLoading}
            onMonthChange={handleMonthChange}
            renderLoading={() => <DayCalendarSkeleton />}
            slots={{
              day: (dayProps) => (
                <ServerDay {...dayProps} onDateClick={handleDateClick} />
              ),
            }}
            slotProps={{
              day: {
                highlightedDays,
              },
            }}
          />
        </Col>
        <Col lg={7}>
          <div className="h-100 d-flex flex-column">
            <h3 className="title__schedule my-3">
              Jadwal {moment(selectedDate).format("DD MMMM YYYY")}
            </h3>
            {currentLiveCourseData?.length > 0 ? (
              currentLiveCourseData.map((item) => {
                console.log(item);
                return (
                  <div className="wrapp__subtitle-schedule mb-2">
                    <div className="time-date__subtitle-schedule">
                      <span className="border-right pr-2">{item.time}</span>{" "}
                      <span className="pl-2">{item.type}</span>
                    </div>
                    <span className="sub-title__schedule">
                      <Link
                        to={
                          item.category === "live"
                            ? "#"
                            : `/dashboard/management-penugasan/penugasan-pelatihan/${item.id}`
                        }
                      >
                        {item.name}
                      </Link>
                    </span>
                  </div>
                );
              })
            ) : (
              <div className="w-100 my-5 text-center">
                <div className="mb-2">
                  <img src={mascot} alt="mascot" width="104" height="122" />
                </div>
                <div className="text-notfound">Tidak ada jadwal hari ini</div>
                <div className="subtext-notfound">
                  Jadwal akan tampil di sini
                </div>
              </div>
            )}
          </div>
        </Col>
      </Row>
    </LocalizationProvider>
  );
}
