import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Loading from "./Loading";
import Layout from "./Layout";
import LayoutNoSidebar from "./LayoutNoSidebar";
import "../../assets/css/Layout/template.css";
import { getUsersDataThunk } from "../../redux/thunks/UsersThunks";
import { AuthIsLogin, AuthData } from "../../helper/auth";
// import "../../assets/css/Layout/feather.css";

function Template({ InnerComponent, isSidebar, isHeader }) {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const courses = useSelector((state) => state.courses.isLoading);
  const course_types = useSelector((state) => state.course_types.isLoading);
  const learning_paths = useSelector((state) => state.learning_paths.isLoading);
  const lessons = useSelector((state) => state.lessons.isLoading);
  const login = useSelector((state) => state.login.isLoading);
  const quiz_themes = useSelector((state) => state.quiz_themes.isLoading);
  const quizzes = useSelector((state) => state.quizzes.isLoading);
  const users = useSelector((state) => state.users.isLoading);
  const webinars = useSelector((state) => state.webinars.isLoading);
  const topics = useSelector((state) => state.topics.isLoading);
  const assets = useSelector((state) => state.assets.isLoading);
  const logins = useSelector((state) => state.login);
  const analyticsgroup = useSelector((state) => state.analyticsgroup.isLoading);

  useEffect(() => {
    if (AuthData()) dispatch(getUsersDataThunk(AuthData().userId));
  }, []);

  useEffect(() => {
    if (
      !courses &&
      !course_types &&
      !learning_paths &&
      !lessons &&
      !login &&
      !quizzes &&
      !quiz_themes &&
      !users &&
      !webinars &&
      !assets &&
      !topics &&
      !analyticsgroup
    )
      setIsLoading(false);
    else setIsLoading(true);
  }, [
    courses,
    course_types,
    learning_paths,
    lessons,
    login,
    quizzes,
    quiz_themes,
    users,
    webinars,
    assets,
    topics,
    analyticsgroup,
  ]);

  return (
    <>
      <Loading isLoading={isLoading} />
      {isSidebar ? (
        <Layout isSidebar={isSidebar} InnerComponent={InnerComponent} />
      ) : isHeader ? (
        <LayoutNoSidebar InnerComponent={InnerComponent} />
      ) : (
        <InnerComponent />
      )}
    </>
  );
}

export default Template;
