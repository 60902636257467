import React from "react";
import { Card, Button } from "react-bootstrap";
import { FaUserAlt } from "react-icons/fa";
import "../assets/css/Dashboard/companyAnalytics.css";
function PopularCourse({ data }) {
  return (
    <>
      <Card className="card_container">
        <div className="card_inside__padding ">
          <div className="d-flex justify-content-between align-items-center border_card">
            <div className="card_title__info mb-1">10 Pelatihan Populer</div>
          </div>
          {data && data.popular_courses.length > 0 ? (
            data.popular_courses?.slice(0, 10).map((e, i) => {
              return (
                <div className="certificate-wrapper" key={i}>
                  <div className="wrapper-table__list">
                    <div className="d-flex w-100">
                      <div className="img-certificate mr-3">
                        {e.thumbnail ? (
                          <img className="img-avatar__user" src={e.thumbnail} />
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="certificate-title__wrap">
                        <div className="certificate-title__main">
                          {e.course}
                        </div>
                        <div className="certificate-subtitle__secondary">
                          Jumlah Enroll Pelatihan: {e.participants}
                        </div>
                      </div>
                    </div>
                    <Button
                      href={`/dashboard/course/${e.course_id}`}
                      className="button-detail"
                    >
                      Lihat
                    </Button>
                  </div>
                </div>
              );
            })
          ) : (
            <div className="certificate-wrapper">
              <div className="wrapper-table__list">
                <div className="img-certificate mr-3"></div>
                <div className="certificate-title__wrap">
                  <div className="certificate-title__main">N/A</div>
                  <div className="certificate-subtitle__secondary">
                    <FaUserAlt className="mr-2 size" />
                    N/A
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </Card>
    </>
  );
}

export default PopularCourse;
