import React, { useState, useEffect } from "react";
import { Table, Card, Button, Modal } from "react-bootstrap";
import * as echarts from "echarts";
import { getAnalyticsCourseStatsDataThunk } from "../redux/thunks/AnalyticsGroupThunks";
import "../assets/css/Dashboard/companyAnalytics.css";
// import Pagination from "./Layout/Pagination";
import { useDispatch, useSelector } from "react-redux";

function ClassList({ data, adminBnsp }) {
  const [datas, setDatas] = useState();

  useEffect(() => {
    setDatas(data);
  }, [datas]);

  // const [instance, updateInstance] = usePDF({
  //   document: (
  // <Document title={`Analitik Data ${datas?.name}`}>
  //   <Page style={styles.body}>
  //     <Text style={styles.title}>{`Pelatihan Populer ${datas?.name}`}</Text>
  //     <View style={styles.tabletitle}>
  //       <View>
  //         <Text>Nama Pelatihan</Text>
  //       </View>
  //       <View style={styles.column}>
  //         <View>
  //           <Text>Kategori</Text>
  //         </View>
  //         <View>
  //           <Text>Partisipan</Text>
  //         </View>
  //       </View>
  //     </View>
  //     {datas?.popular_pelatihan?.map((item, index) => {
  //       return (
  //         <View style={styles.tabletitle}>
  //           <View>
  //             <Text>Nama Pelatihan</Text>
  //           </View>
  //           <View style={styles.column}>
  //             <View>
  //               <Text>Kategori</Text>
  //             </View>
  //             <View>
  //               <Text>Partisipan</Text>
  //             </View>
  //           </View>
  //         </View>
  //       );
  //     })}
  //     {/* <View style={styles.table}>
  //           <View style={styles.list}>
  //             <Text style={styles.text} fixed>
  //               {item.course}
  //             </Text>

  //             <Text style={styles.text} fixed>
  //               {item.categories}
  //             </Text>
  //           </View>
  //     </View> */}
  //   </Page>
  // </Document>
  //   ),
  // });
  // useEffect(() => {
  //   console.log(instance);
  // }, [instance]);
  // const [search, setSearch] = useState("");
  // const [active, setActive] = useState(1);
  // const [dataDetail, setDataDetail] = useState();
  const [show, setShow] = useState(false);
  const [title, setTitle] = useState();
  // const [companyid, setCompanyId] = useState("");
  // const [coursePagination, setCoursePagination] = useState();
  // state from redux
  const analyticsgroup = useSelector((state) => state.analyticsgroup);

  // assign to state
  // const completedCourse = analyticsgroup.analyticsCompletedData;

  const dispatch = useDispatch();

  const handleShowDetail = (course_id, company_id, title) => {
    setTitle(title);
    dispatch(
      getAnalyticsCourseStatsDataThunk({
        param: `?company_id=${company_id}&course_id=${course_id}`,
      })
    );
    setShow(true);
  };

  // function keydownHandler(e) {
  //   if (e.keyCode == 13) {
  //     setActive(1);
  //     // dispatch(
  //     //   getCoursesData({
  //     //     param: `?size=${size}&page=1${search ? "&keyword=" + search : ""}`,
  //     //   })
  //     // );
  //   }
  // }

  // function onChangeSearch(e) {
  //   const newValue = e.target.value;
  //   setSearch(newValue);
  // }

  // function paginationSelect(newActive) {
  //   setActive(newActive);
  //   // dispatch(
  //   //   getCoursesData({
  //   //     param: `?size=${size}&page=${newActive}${
  //   //       search ? "&keyword=" + search : ""
  //   //     }`,
  //   //   })
  //   // );
  // }

  return (
    <>
      <Card className="card_container class-list">
        <div className="card_inside__padding ">
          {" "}
          <div className="d-flex justify-content-between ">
            <div className="card_title__info mb-1">Daftar Kelas</div>

            {/* <Button href={instance.url} download="test.pdf">
              Download
            </Button> */}
            {/* <div className="d-flex justify-content-end align-items-center mb-2 card_filter">
              Urutkan :{" "}
              <Dropdown className="ml-2">
                <Dropdown.Toggle
                  className="px-3 py-1"
                  variant="success"
                  id="dropdown-basic"
                >
                  Ascending
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item>Ascending</Dropdown.Item>
                  <Dropdown.Item>Descending</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>{" "}
            </div> */}
          </div>
          <div className="d-flex flex-row justify-content-between mb-3">
            {/* <Form.Control
              style={{ flex: 0.5 }}
              className="satukelas_input"
              type="text"
              id="search"
              name="search"
              placeholder="Cari Pelatihan"
              value={search}
              onKeyDown={keydownHandler}
              onChange={onChangeSearch}
            />
            {data?.popular_pelatihan ? (
              <Pagination
                size={size}
                totalRows={data.popular_pelatihan?.length}
                totalPages={1}
                paginationSelect={paginationSelect}
                active={active}
              />
            ) : null} */}
          </div>
          <div className="table-responsive">
            <Table className="text-nowrap">
              <thead>
                <tr role="row">
                  <th
                    className="sorting_asc w-75"
                    tabIndex="0"
                    aria-controls="exportexample"
                    aria-sort="ascending"
                    aria-label="Nama: activate to sort column descending"
                  >
                    Nama Pelatihan
                  </th>
                  <th
                    className="sorting"
                    tabIndex="0"
                    aria-controls="exportexample"
                    aria-label="Departemen: activate to sort column ascending"
                  >
                    Kategori
                  </th>
                  <th
                    className="sorting"
                    tabIndex="0"
                    aria-controls="exportexample"
                    aria-label="Kelas: activate to sort column ascending"
                  >
                    Partisipan
                  </th>

                  <th
                    className="sorting"
                    tabIndex="0"
                    aria-controls="exportexample"
                    aria-label="Tanggal Selesai: activate to sort column ascending"
                  >
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {data?.popular_pelatihan?.map((item, index) => {
                  return (
                    <tr key={index} role="row" className="odd">
                      <td className="sorting_1">{item.course}</td>
                      <td>{item.categories}</td>
                      <td>{item.participants}</td>
                      {/* <td>{item.start_date}</td> */}
                      <td>
                        <Button
                          onClick={() =>
                            handleShowDetail(
                              item?.course_id,
                              data?.company_id,
                              "Kelas"
                            )
                          }
                          className="detail_button__users"
                        >
                          Detail
                        </Button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </div>
      </Card>
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        scrollable={true}
        centered
        show={show}
        onHide={() => setShow(!show)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Detail Partisipan Kelas</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table responsive striped hover>
            <thead>
              <th className="pl-0">
                Nama{" "}
                {adminBnsp && adminBnsp?.usersDataId?.is_bnsp === 1
                  ? "Partisipan"
                  : "Karyawan"}
              </th>
              <th className="pl-0">Posisi</th>
              {/* {value === undefined ? null : <th className="w-50">Status</th>} */}
              <th>Action</th>
            </thead>

            {analyticsgroup.analyticsCourseStatsData?.items?.map(
              (item, index) => {
                return (
                  <tr key={index}>
                    <td className="position_text bold w-75">
                      <div>{item.name}</div>
                    </td>
                    <td className="position_text">
                      <span>
                        {item?.position === "" ? "N/A" : item?.position}
                      </span>
                    </td>{" "}
                    {/* {value !== "status" || value !== "status" ? null : (
                        <td className="position_text">
                          <span className="container-avg__company">Status</span>
                        </td>
                      )} */}
                    <td className="position_text">
                      <div>
                        <Button
                          href={`/analytics/users/${item?.user_id}`}
                          className="detail_button__users"
                        >
                          Detail
                        </Button>
                      </div>
                    </td>
                  </tr>
                );
              }
            )}
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShow(!show)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ClassList;

function option() {
  return {
    legend: {
      top: "bottom",
    },

    series: [
      {
        name: "Status Post Test",
        type: "pie",
        radius: [50, 100],
        center: ["50%", "50%"],
        roseType: "area",
        itemStyle: {
          borderRadius: 8,
        },
        data: [
          { value: 10, name: "Belum ditemukan" },
          // { value: 10, name: "Lulus" },
          // { value: 5, name: "Tidak Lulus" },
        ],
        color: ["#91CC75", "#EE4253"],
      },
    ],
  };
}

const defaultSeries = {
  name: "",
  type: "line",
  stack: "Total",
  smooth: true,
  lineStyle: {
    width: 0,
  },
  showSymbol: false,
  areaStyle: {
    opacity: 0.8,
    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
      {
        offset: 0,
        color: "rgba(128, 255, 165)",
      },
      {
        offset: 1,
        color: "rgba(1, 191, 236)",
      },
    ]),
  },
  emphasis: {
    focus: "series",
  },
  data: [],
};

function option2() {
  return {
    dataset: {
      source: [
        ["score", "amount", "product"],
        [89.3, 58212, "Januari"],
        [57.1, 78254, "Febuari"],
        [74.4, 41032, "Maret"],
        [50.1, 12755, "April"],
        [89.7, 20145, "Mei"],
        [68.1, 79146, "Juni"],
        [19.6, 91852, "Juli"],
        [10.6, 101852, "Agustus"],
        [32.7, 20112, "September"],
        [32.7, 20112, "Oktober"],
        [32.7, 20112, "November"],
        [32.7, 20112, "Desember"],
      ],
    },
    grid: { containLabel: true },
    xAxis: { name: "amount" },
    yAxis: { type: "category" },
    visualMap: {
      orient: "horizontal",
      left: "center",
      min: 10,
      max: 100,
      // Map the score column to color
      dimension: 0,
      inRange: {
        color: ["#65B581", "#FFCE34", "#FD665F"],
      },
    },
    series: [
      {
        type: "bar",
        encode: {
          // Map the "amount" column to X axis.
          x: "amount",
          // Map the "product" column to Y axis
          y: "product",
        },
      },
    ],
  };
}
