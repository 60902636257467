export const TOPIC_DATA_LINK = "topics";

//get
export const TOPIC_DATA = "TOPIC_DATA";
export const TOPIC_DATA_SUCCESS = "TOPIC_DATA_SUCCESS";
export const TOPIC_DATA_ID_SUCCESS = "TOPIC_DATA_ID_SUCCESS";
export const TOPIC_DATA_FAIL = "TOPIC_DATA_FAIL";

//post
export const TOPIC_DATA_POST = "TOPIC_DATA_POST";
export const TOPIC_DATA_POST_SUCCESS = "TOPIC_DATA_POST_SUCCESS";
export const TOPIC_DATA_POST_FAIL = "TOPIC_DATA_POST_FAIL";

// put
export const TOPIC_DATA_PUT = "TOPIC_DATA_PUT";
export const TOPIC_DATA_PUT_SUCCESS = "TOPIC_DATA_PUT_SUCCESS";
export const TOPIC_DATA_PUT_FAIL = "TOPIC_DATA_PUT_FAIL";

// delete
export const TOPIC_DATA_DELETE = "TOPIC_DATA_DELETE";
export const TOPIC_DATA_DELETE_SUCCESS = "TOPIC_DATA_DELETE_SUCCESS";
export const TOPIC_DATA_DELETE_FAIL = "TOPIC_DATA_DELETE_FAIL";

// remove
export const REMOVE_TOPIC_DATA = "REMOVE_TOPIC_DATA";
