import { PromiseService } from "../../services/PromiseService";
import { ORDERS_DATA_LINK } from "../config/OrdersConfig";
import {
  //get provinces
  getProvincesData,
  getProvincesDataSuccess,
  getProvincesDataFail,
  //get cities
  getCitiesData,
  getCitiesDataSuccess,
  getCitiesDataFail,
  //get subdistricts
  getSubdistrictsData,
  getSubdistrictsDataSuccess,
  getSubdistrictsDataFail,
  //get couriers
  getCouriersData,
  getCouriersDataSuccess,
  getCouriersDataFail,
  //get price
  getPricesData,
  getPricesDataSuccess,
  getPricesDataFail,
  //post order
  postOrdersData,
  postOrdersDataSuccess,
  postOrdersDataFail,
} from "../actions/OrdersActions";

export const getProvincesDataThunk = (data) => async (dispatch) => {
  dispatch(getProvincesData());
  try {
    const response = await PromiseService.getData(
      `${ORDERS_DATA_LINK}/provinces`
    );
    if (response.status === 200 && response.data) {
      dispatch(getProvincesDataSuccess(response.data));
    } else {
      dispatch(getProvincesDataFail(response));
    }
  } catch (error) {
    dispatch(getProvincesDataFail(error));
  }
};

export const getCitiesDataThunk = (data) => async (dispatch) => {
  dispatch(getCitiesData());
  const id = data;
  try {
    const response = await PromiseService.getData(
      `${ORDERS_DATA_LINK}/cities?province_id=${id}`
    );
    if (response.status === 200 && response.data) {
      dispatch(getCitiesDataSuccess(response.data));
    } else {
      dispatch(getCitiesDataFail(response));
    }
  } catch (error) {
    dispatch(getCitiesDataFail(error));
  }
};

export const getSubdistrictsDataThunk = (data) => async (dispatch) => {
  dispatch(getSubdistrictsData());
  const id = data;
  try {
    const response = await PromiseService.getData(
      `${ORDERS_DATA_LINK}/subdistricts?city_id=${id}`
    );
    if (response.status === 200 && response.data) {
      dispatch(getSubdistrictsDataSuccess(response.data));
    } else {
      dispatch(getSubdistrictsDataFail(response));
    }
  } catch (error) {
    dispatch(getSubdistrictsDataFail(error));
  }
};

export const getCouriersDataThunk = (data) => async (dispatch) => {
  dispatch(getCouriersData());
  try {
    const response = await PromiseService.getData(
      `${ORDERS_DATA_LINK}/couriers`
    );
    if (response.status === 200 && response.data) {
      dispatch(getCouriersDataSuccess(response.data));
    } else {
      dispatch(getCouriersDataFail(response));
    }
  } catch (error) {
    dispatch(getCouriersDataFail(error));
  }
};

export const getPricesDataThunk = (data) => async (dispatch) => {
  dispatch(getPricesData());
  const param = `price?subdistrict_origin_id=2103&subdistrict_destination_id=${
    data?.address_subdistrict?.split("_")[0]
  }&courier=${data?.courier}&weight=100`;
  try {
    const response = await PromiseService.getData(
      `${ORDERS_DATA_LINK}/${param}`
    );
    if (response.status === 200 && response.data) {
      dispatch(
        getPricesDataSuccess(response.data?.rajaongkir?.results[0].costs)
      );
    } else {
      dispatch(getPricesDataFail(response));
    }
  } catch (error) {
    dispatch(getPricesDataFail(error));
  }
};

export const postOrdersDataThunk = (data) => async (dispatch) => {
  dispatch(postOrdersData());
  const body = data;
  try {
    const response = await PromiseService.postData(ORDERS_DATA_LINK, body);
    if (response.status === 201 || (response.status === 200 && response.data)) {
      dispatch(postOrdersDataSuccess(response.data));
    } else {
      dispatch(postOrdersDataFail(response));
    }
  } catch (error) {
    dispatch(postOrdersDataFail(error));
  }
};
