import React, { useState, useEffect } from "react";
import { Button, Form } from "react-bootstrap";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useDispatch, useSelector } from "react-redux";
import {
  postTopicDataThunk,
  putTopicDataThunk,
  getTopicDataThunk,
} from "../redux/thunks/TopicThunks";
import { Clone } from "../helper/functions";
import { useParams } from "react-router-dom";
const MySwal = withReactContent(Swal);

function AddTopic({ id, defaultItems, handleCancel, coorporate, fixedTitle }) {
  /** Global variables start*/
  const defaultData = {
    title: fixedTitle ? fixedTitle : "",
    course_id: "",
    sequence: "",
  };
  const [data, setData] = useState(defaultData);
  const [trigger, setTrigger] = useState(false);
  const { course_id } = useParams();
  const dispatch = useDispatch();
  const topics = useSelector((state) => state.topics);
  const courses = useSelector((state) => state.courses);
  const users = useSelector((state) => state.users);
  /** Global variables ends*/

  /**
   * function to seed initial data
   * when user edits topic
   */
  useEffect(() => {
    if (course_id) {
      const newData = { ...data };
      newData.course_id = course_id;
      setData(newData);
    }
  }, []);

  /**
   * function to handle alert message
   * after user edits topic
   */
  useEffect(() => {
    if (topics.topicDataPut === "error" && trigger) {
      setTrigger(false);
      displayPopup(false, "Terjadi masalah saat edit data", "Ok");
    }
    if (topics.topicDataPut === "success" && trigger) {
      setTrigger(false);
      setData(defaultData);
      dispatch(getTopicDataThunk());
      handleCancel();
    }
  }, [topics]);

  /**
   * function to seed initial data
   * when user add topic with default title for course
   * type webinar/sertifikasi
   */
  useEffect(() => {
    let newDefaultData = Clone(defaultData);
    if (defaultItems) {
      if (defaultItems.topic_id) {
        Object.keys(newDefaultData).forEach(function (key) {
          if (newDefaultData[key] == null || newDefaultData[key] == 0) {
            newDefaultData[key] = defaultItems[key];
          }
        });
        setData(newDefaultData);
      }
    }
  }, [defaultItems]);

  /**
   * function to handle form submit
   * takes object as param
   * returns nothing
   */
  const handleSubmit = (e) => {
    e.preventDefault();
    uploadData();
  };

  /**
   * function to handle form changes
   * takes object as param
   * returns nothing
   */
  const handleChange = (e) => {
    const newData = { ...data };
    newData[e.target.id] = e.target.value;
    setData(newData);
  };

  /**
   * function to sanitize & upload data to API using redux
   * no params
   * returns nothing
   */
  function uploadData() {
    const topicsTemp = courses.courseDataId?.topics;

    const seq = fixedTitle
      ? topicsTemp?.length > 0
        ? topicsTemp[topicsTemp.length - 1].sequence + 1
        : 0
      : parseInt(data.sequence);

    const newData = {
      ...data,
      sequence: seq,
    };

    if (coorporate) newData.company_id = users.usersDataId?.company_id;
    setTrigger(true);

    if (id) {
      let req = {};
      req.body = newData;
      req.id = id;
      dispatch(putTopicDataThunk(req));
    } else dispatch(postTopicDataThunk(newData));

    handleCancel();
  }

  /**
   * helper function to display alert
   * takes bool;string;bool as params
   * returns nothing
   */
  function displayPopup(cancel, title, confirm) {
    MySwal.fire({
      title: title,
      showCancelButton: cancel,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: confirm,
    }).then((value) => {
      console.log(value, "value");
    });
  }

  return (
    <Form onSubmit={handleSubmit}>
      {/* Course ID */}
      <Form.Group>
        <Form.Label className="title_label__input">ID Pelatihan*</Form.Label>
        <Form.Control
          onChange={handleChange}
          className="mb-3"
          type="text"
          id="course_id"
          disabled={course_id}
          value={course_id ? course_id : data.course_id}
          required
        />
      </Form.Group>

      {/* Topic Title */}
      <Form.Group>
        <Form.Label className="title_label__input">Judul*</Form.Label>
        <Form.Control
          onChange={handleChange}
          className="mb-3"
          type="text"
          id="title"
          value={data.title}
          required
          disabled={fixedTitle}
        />
      </Form.Group>

      {/* Sequence */}
      <Form.Group>
        <Form.Label className="title_label__input">
          Sequence (Urutan ditampilkan)*
        </Form.Label>
        <Form.Control
          onChange={handleChange}
          className="mb-3"
          type="text"
          id="sequence"
          value={data.sequence}
          required
          disabled={fixedTitle}
        />
      </Form.Group>

      <div className="d-flex justify-content-end">
        {handleCancel ? (
          <Button
            type="submit"
            variant="light"
            onClick={() => {
              setData(defaultData);
              handleCancel();
            }}
            className="my-4 mx-2"
          >
            Batal
          </Button>
        ) : null}
        <Button type="submit" className="my-4 mx-2 px-3 py-2">
          {id ? "Edit" : "+ Buat"}
        </Button>
      </div>
    </Form>
  );
}

export default AddTopic;
