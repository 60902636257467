import {
  //get
  COURSES_DATA,
  COURSES_DATA_ID_SUCCESS,
  COURSES_DATA_SUCCESS,
  COURSES_DATA_FAIL,
  //live search
  COURSES_LIVE_SEARCH_DATA,
  COURSES_LIVE_SEARCH_DATA_SUCCESS,
  COURSES_LIVE_SEARCH_DATA_FAIL,
  //post
  COURSES_DATA_POST,
  COURSES_DATA_POST_SUCCESS,
  COURSES_DATA_POST_FAIL,
  //put
  COURSES_DATA_PUT,
  COURSES_DATA_PUT_SUCCESS,
  COURSES_DATA_PUT_FAIL,
  //delete
  COURSES_DATA_DELETE,
  COURSES_DATA_DELETE_SUCCESS,
  COURSES_DATA_DELETE_FAIL,
  //remove
  REMOVE_COURSE_DATA_ID,
  REMOVE_COURSE_DATA,
  REMOVE_COURSE_DATA_PUT,

  // get completed
  COURSES_COMPLETED_DATA,
  COURSES_COMPLETED_DATA_SUCCESS,
  COURSES_COMPLETED_DATA_ID_SUCCESS,
  // fail completed
  COURSES_COMPLETED_DATA_FAIL,
} from "../config/CoursesConfig";

const intialState = {
  courseDataPost: "",
  courseDataPut: "",
  courseDataDelete: "",
  courseData: [],
  courseLiveSearchData: [],
  courseDataId: [],
  isLoading: false,
  isLoadingLive: false,
  coursesCompletedData: null,
  coursesCompletedDataId: null,
};

export const CoursesReducers = function (state = intialState, action) {
  switch (action.type) {
    //get
    case COURSES_DATA:
      return { ...state, isLoading: true };
    case COURSES_DATA_SUCCESS:
      return { ...state, isLoading: false, courseData: action.value };
    case COURSES_DATA_ID_SUCCESS:
      return { ...state, isLoading: false, courseDataId: action.value };
    case COURSES_DATA_FAIL:
      return {
        ...state,
        isLoading: false,
        courseData: "error",
        courseDataId: "error",
      };
    //get live search
    case COURSES_LIVE_SEARCH_DATA:
      return { ...state, isLoadingLive: true, courseLiveSearchData: [] };
    case COURSES_LIVE_SEARCH_DATA_SUCCESS:
      return {
        ...state,
        isLoadingLive: false,
        courseLiveSearchData: action.value,
      };
    case COURSES_LIVE_SEARCH_DATA_FAIL:
      return {
        ...state,
        isLoadingLive: false,
        courseLiveSearchData: "error",
      };

    case COURSES_COMPLETED_DATA:
      return {
        ...state,
        isLoading: true,
        coursesCompletedData: null,
        coursesCompletedDataId: null,
      };
    case COURSES_COMPLETED_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        coursesCompletedData: action.value,
      };
    case COURSES_COMPLETED_DATA_ID_SUCCESS:
      return {
        ...state,
        isLoading: false,
        coursesCompletedDataId: action.value,
      };
    case COURSES_COMPLETED_DATA_FAIL:
      return {
        ...state,
        isLoading: false,
        coursesCompletedData: "error",
        coursesCompletedDataId: "error",
      };


    //post
    case COURSES_DATA_POST:
      return {
        ...state,
        isLoading: true,
        courseDataPost: "",
        courseDataPut: "",
        courseDataDelete: "",
      };
    case COURSES_DATA_POST_SUCCESS:
      return { ...state, isLoading: false, courseDataPost: action.value };
    case COURSES_DATA_POST_FAIL:
      return { ...state, isLoading: false, courseDataPost: "error" };

    //put
    case COURSES_DATA_PUT:
      return {
        ...state,
        isLoading: true,
        courseDataPost: "",
        courseDataPut: "",
        courseDataDelete: "",
      };
    case COURSES_DATA_PUT_SUCCESS:
      return { ...state, isLoading: false, courseDataPut: "success" };
    case COURSES_DATA_PUT_FAIL:
      return { ...state, isLoading: false, courseDataPut: "error" };

    //delete
    case COURSES_DATA_DELETE:
      return {
        ...state,
        isLoading: true,
        courseDataPost: "",
        courseDataPut: "",
        courseDataDelete: "",
      };
    case COURSES_DATA_DELETE_SUCCESS:
      return { ...state, isLoading: false, courseDataDelete: "success" };
    case COURSES_DATA_DELETE_FAIL:
      return { ...state, isLoading: false, courseDataDelete: "error" };

    //remove
    case REMOVE_COURSE_DATA_ID:
      return {
        ...state,
        courseDataId: [],
        courseDataPost: "",
        courseDataPut: "",
        courseDataDelete: "",
      };
    case REMOVE_COURSE_DATA:
      return {
        ...state,
        courseData: [],
        courseLiveSearchData: [],
      };
    case REMOVE_COURSE_DATA_PUT:
      return { ...state, courseDataPut: "" };

    default:
      return state;
  }
};
