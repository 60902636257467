import React from "react";
import { Col, Image, Row } from "react-bootstrap";
import DetailThumb from "./../../assets/images/detail-thumb.png";

export default function InfoCourse(props) {
  const { data } = props;
  return (
    <div className="card-analitik">
      <Row>
        <Col lg={5}>
          <div>
            <Image
              src={
                data?.course?.thumbnail ? data?.course?.thumbnail : DetailThumb
              }
              className="w-100"
              style={{ objectFit: "contain" }}
            />
          </div>
        </Col>
        <Col lg={7}>
          <div className="label-analytic">
            {data?.course?.categories?.length > 0
              ? data?.course?.categories[0]
              : ""}
          </div>
          <div className="title-course">{data?.course?.title}</div>
          <div className="desc-course">{data?.course?.description}</div>
          <div className="instructur-wrap">
            <div className="profile-img">
              <Image
                src={
                  data?.course?.instructors?.length > 0 &&
                  data?.course?.instructors[0].avatar
                    ? data?.course?.instructors[0].avatar
                    : "https://old.satukelas.com/wp-content/uploads/2021/02/png-satukelas-296x63.png"
                }
                width={48}
                height={48}
              />
            </div>
            <div className="profile-info">
              <div className="name">
                {data?.course?.instructors?.length > 0
                  ? data?.course?.instructors[0].name
                  : "Satukelas"}
              </div>
              <div className="role">
                {data?.course?.instructors?.length > 0
                  ? data?.course?.instructors[0].division
                    ? data?.course?.instructors[0].division
                    : "-"
                  : "Instruktur"}
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
}
