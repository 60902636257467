import React from "react";
import { Card } from "react-bootstrap";
import ReactECharts from "echarts-for-react";
import "../assets/css/Dashboard/companyAnalytics.css";
import moment from "moment";

function AkumulasiLessonStudent({ data }) {
  // get date of nth week
  const generateWeekDate = (data, i) => {
    return moment(data?.completed_lessons?.datelist[i]?.start).format("MMM D");
  };
  // get array of nth week with date
  const generateWeeklist = (data) => {
    const result = [];
    if (data?.completed_lessons?.weeklist.length > 0) {
      for (let i = 0; i < 8; i++) {
        const e = `Week ${
          data?.completed_lessons?.weeklist[i]
        }, ${generateWeekDate(data, i)}`;
        result.push(e);
      }
      return result;
    } else {
      const e = "Tidak tersedia";
      result.push(e);
      return result;
    }
  };

  function option() {
    const weeklist = generateWeeklist(data);

    return {
      tooltip: {
        trigger: "axis",
      },
      legend: {
        data: ["Lesson yang Selesai"],
      },
      grid: {
        left: "3%",
        right: "4%",
        bottom: "3%",
        containLabel: true,
      },

      xAxis: {
        type: "category",
        boundaryGap: false,
        data: weeklist,
      },
      yAxis: {
        type: "value",
      },
      series: [
        {
          name: "Lesson yang Selesai",
          type: "line",
          stack: "Total",
          data: data?.completed_lessons?.counts,
        },
      ],
    };
  }

  return (
    <Card className="card_container">
      <div className="card_inside__padding ">
        <div className="d-flex justify-content-between align-items-center border_card">
          <div className="card_title__info mb-1 mb-3">
            Akumulasi Lesson Selesai
          </div>
        </div>
        <div className="mt-4">
          {/* {data?.completed_lessons?.counts?.length > 0 ? ( */}
          <ReactECharts
            style={{ height: "400px", paddingBottom: "16px" }}
            option={option()}
          />
          {/* // ) : (
          //   <ReactECharts
          //     style={{ height: "400px", paddingBottom: "16px" }}
          //     option={option2()}
          //   />
          // )} */}
        </div>
      </div>
    </Card>
  );
}

export default AkumulasiLessonStudent;
