import {
  //get
  ANALYTICS_USER_DATA,
  ANALYTICS_USER_DATA_ID_SUCCESS,
  ANALYTICS_USER_DATA_SUCCESS,
  ANALYTICS_USER_DATA_FAIL,
} from "../config/AnalyticsUserConfig";

const intialState = {
  analyticsUserData: [],
  analyticsUserDataId: [],
  isLoading: false,
};
export const AnalyticsUserReducers = function (state = intialState, action) {
  switch (action.type) {
    //get
    case ANALYTICS_USER_DATA:
      return { ...state, isLoading: true };
    case ANALYTICS_USER_DATA_SUCCESS:
      return { ...state, isLoading: false, analyticsUserData: action.value };
    case ANALYTICS_USER_DATA_ID_SUCCESS:
      return { ...state, isLoading: false, analyticsUserDataId: action.value };
    case ANALYTICS_USER_DATA_FAIL:
      return {
        ...state,
        isLoading: false,
        analyticsUserData: "error",
        analyticsUserDataId: "error",
      };

    default:
      return state;
  }
};
