import { PromiseService } from "../../services/PromiseService";
import { ASSIGNMENT_DATA_LINK } from "../config/AssignmentConfig";
import {
  //get
  getAssignmentData,
  getAssignmentDataSuccess,
  getAssignmentDataIdSuccess,
  getAssignmentDataFail,
  //post
  postAssignmentData,
  postAssignmentDataSuccess,
  postAssignmentDataFail,
  // put
  putAssignmentData,
  putAssignmentDataSuccess,
  putAssignmentDataFail,
  // delete
  deleteAssignmentData,
  deleteAssignmentDataSuccess,
  deleteAssignmentDataFail,
} from "../actions/AssignmentActions";

export const postAssignmentDataThunk = (data) => async (dispatch) => {
  const body = data;
  dispatch(postAssignmentData(data));
  try {
    const response = await PromiseService.postData(ASSIGNMENT_DATA_LINK, body);
    if (response.status === 201 && response.data) {
      dispatch(postAssignmentDataSuccess(response.data));
    } else {
      dispatch(postAssignmentDataFail(response));
    }
  } catch (error) {
    dispatch(postAssignmentDataFail(error));
  }
};

export const getAssignmentDataThunk = (data) => async (dispatch) => {
  dispatch(getAssignmentData());
  if (data) {
    let url = `${ASSIGNMENT_DATA_LINK}/${data}`;
    if (data.param) url = `${ASSIGNMENT_DATA_LINK}${data.param}`;
    try {
      const response = await PromiseService.getData(url);
      if (response.status === 200 && response.data) {
        if (data.param) dispatch(getAssignmentDataSuccess(response.data));
        else dispatch(getAssignmentDataIdSuccess(response.data));
      } else {
        dispatch(getAssignmentDataFail(response));
      }
    } catch (error) {
      dispatch(getAssignmentDataFail(error));
    }
  } else {
    try {
      const response = await PromiseService.getData(ASSIGNMENT_DATA_LINK);
      if (response.status === 200 && response.data) {
        dispatch(getAssignmentDataSuccess(response.data));
      } else {
        dispatch(getAssignmentDataFail(response));
      }
    } catch (error) {
      dispatch(getAssignmentDataFail(error));
    }
  }
};

// put
export const putAssignmentDataThunk = (data) => async (dispatch) => {
  const body = data.body;
  const id = data.id;
  dispatch(putAssignmentData());
  try {
    const response = await PromiseService.putData(
      `${ASSIGNMENT_DATA_LINK}/${id}`,
      body
    );
    if (response.status === 201 && response.data) {
      dispatch(putAssignmentDataSuccess(response.data));
    } else {
      dispatch(putAssignmentDataFail(response));
    }
  } catch (error) {
    dispatch(putAssignmentDataFail(error));
  }
};

// delete
export const deleteAssignmentDataThunk = (data) => async (dispatch) => {
  const id = data;
  dispatch(deleteAssignmentData());
  try {
    const response = await PromiseService.deleteData(
      `${ASSIGNMENT_DATA_LINK}/${id}`
    );
    if (response.status === 200 && response.data) {
      dispatch(deleteAssignmentDataSuccess(response.data));
    } else {
      dispatch(deleteAssignmentDataFail(response));
    }
  } catch (error) {
    dispatch(deleteAssignmentDataFail(error));
  }
};
