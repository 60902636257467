import ReactECharts from "echarts-for-react";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import "react-big-calendar/lib/css/react-big-calendar.css";
import {
  Card,
  Col,
  Container,
  Row,
  Table,
  Modal,
  Button,
  Image,
} from "react-bootstrap";
import Form from "react-bootstrap/Form";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { Link, useHistory } from "react-router-dom";
import "../../assets/css/Dashboard/index.css";
import successIcon from "../../assets/images/success-icon.svg";
import warnIcon from "../../assets/images/warn-icon.svg";
import errorIcon from "../../assets/images/error-icon.svg";
import IconLocation from "../../assets/images/icon-location.svg";
import IconCircle from "../../assets/images/circle.svg";
import IconCircleUp from "../../assets/images/circle-up.svg";
import IconMembership from "../../assets/images/icon-membership.svg";
import IconDocument from "../../assets/images/icon-document.svg";
import IconUserActive from "../../assets/images/icon-user-active.svg";
import IconCirleRightUp from "../../assets/images/circle-right-up.svg";
import IconFilter from "../../assets/images/icon-filter.svg";
import IconSearch from "../../assets/images/icon-search.svg";
import IconArrowUp from "../../assets/images/icon-arrow.svg";
import IconArrowDown from "../../assets/images/icon-arrow-down.svg";
import IconInfoGray from "../../assets/images/icon-info.svg";
import DateCalendarServerRequest from "../../components/DateCalender";
import {
  ListCertificate,
  ListCourseEducation,
  ListCourses,
  ListJudulPelatihan,
  ListPeformanceTest,
} from "../../helper/DummyData";
import { AuthIsLogin } from "../../helper/auth";
import { getCompaniesDataThunk } from "../../redux/thunks/CompaniesThunks";
import PaginationTemplate from "../../components/Layout/Pagination";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { AuthUsersDetail } from "../../http/AuthUsersDetail";
import { AnalyticLearningService } from "../../http/AnalyticLearningHttp";
import CompletionRate from "../../components/AnalyticsCompany/CompletionRate";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import ContentCourses from "../../components/ManagementTask/ContentCourses";
import ContentPerson from "../../components/ManagementTask/ContentPerson";
import { TaskServices } from "../../http/TaskHttp";
import ResponseModal from "../../components/common/ResponseModal";
import TableAnalyticData from "../../components/AnalyticsCompany/TableAnalyticData";

function Overview({ adminBnsp }) {
  const colorPalete = ["#D6E2F5", "#306CCC"];
  const maxCourses = "";
  const history = useHistory();
  const dispatch = useDispatch();
  const [isLoaded, setIsLoaded] = useState(false);
  const [show, setShow] = useState(false);
  const [showInternal, setShowInternal] = useState("internal");
  const [overviewData, setOverviewData] = useState(null);
  const [leaderboardData, setLeaderboardData] = useState(null);
  const [popularCourseData, setPopularCourseData] = useState(null);
  const [createdCourse, setCreatedCourse] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [membershipActive, setMembershipActive] = useState(null);
  const [courseData, setCourseData] = useState(null);
  const [data, setData] = useState();

  const users = useSelector((state) => state.users);
  const [typeActive, setTypeActive] = useState("all");
  const [selectAll, setSelectAll] = useState(false);
  const [isCanDelete, setIsCanDelete] = useState(true);
  const [specificId, setSpecificId] = useState([]);
  const [activeTask, setActiveTask] = useState(1);
  const [sizeDefault, setSizeDefault] = useState(5);
  const [sizeCourseDefault, setSizeCourseDefault] = useState(5);
  const [selectedTaskLength, setSelectedTaskLength] = useState(0);
  const [selectedPersonLength, setSelectedPersonLength] = useState(0);
  const [sizeUserDefault, setSizeUserDefault] = useState(5);
  const [showModalDeadline, setShowModalDeadline] = useState(false);
  const [titleAssignee, setTitleAssignee] = useState("");
  const [statusModal, setStatusModal] = useState("select-course");
  const [listTask, setListTask] = useState(null);
  const [chartDataReady, setChartDataReady] = useState([]);
  const [searchCourse, setSearchCourse] = useState("");
  const [totalStatistic, setTotalStatistic] = useState(0);
  const [internalCourses, setInternalCourses] = useState(null);
  const [modalContentResponse, setModalContentResponse] = useState({
    title: "",
    subtitle: "",
    icon: "",
    ctaContent: "",
  });
  const [userListByCompany, setUserListByCompany] = useState(null);
  const [courseSelectedList, setCourseSelectedList] = useState([]);
  const [personSelectedList, setPersonSelectedList] = useState([]);
  const [selectedCourseLength, setSelectedCourseLength] = useState(0);
  const userDetailInfo = users.usersDataId;
  const userDataList = users.usersData;

  // console.log(userDetailInfo);
  const [certificateList, setCertificateList] = useState(null);
  const requestAbortController = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [highlightedDays, setHighlightedDays] = useState([1, 2, 15]);
  const initialValue = dayjs(new Date());
  const [modalOpen, setModalOpen] = useState(false);
  const [dateAssignee, setDateAssignee] = useState(initialValue);

  useEffect(() => {
    if (userDataList) {
      setUserListByCompany(userDataList);
    }
    // setUserListByCompany
  }, [userDataList]);

  useEffect(() => {
    if (userDetailInfo?.company_id) {
      getPenugasanList(
        `?company_id=${userDetailInfo.company_id}&size=${sizeDefault}&page=${activeTask}`
      );
      getChartPenugasan(`/${userDetailInfo.company_id}`);
    }
  }, [userDetailInfo]);

  function assigneeCourse(id, title, category) {
    let courseSelected = [...courseSelectedList];
    if (courseSelected.find((x) => x.id == id)) {
      let idx = courseSelected.findIndex((x) => x.id == id);
      courseSelected.splice(idx, 1);
      setCourseSelectedList(courseSelected);
      setSelectedCourseLength(selectedCourseLength - 1);
    } else {
      let tmp = { id, title, category };
      courseSelected.push(tmp);
      setCourseSelectedList(courseSelected);
      setSelectedCourseLength(selectedCourseLength + 1);
    }
  }

  const modalContent = () => {
    return (
      <div>
        <div className="penugasan-status__icon">
          {modalContentResponse.icon}
        </div>
        <div className="penugasan-main__title">
          {modalContentResponse.title}
        </div>
        <div
          className="penugasan-main__subtitle"
          dangerouslySetInnerHTML={{ __html: modalContentResponse.subtitle }}
        />
        <div className="penugasan-cta__action">
          {modalContentResponse.action}
        </div>
      </div>
    );
  };

  function assigneePerson(id, name, division) {
    let personSelected = [...personSelectedList];
    if (personSelected.find((x) => x.id == id)) {
      let idx = personSelected.findIndex((x) => x.id == id);
      personSelected.splice(idx, 1);
      setPersonSelectedList(personSelected);
      setSelectedPersonLength(selectedCourseLength - 1);
    } else {
      setSelectedPersonLength(selectedPersonLength + 1);
      let tmp = { id, name, division };
      personSelected.push(tmp);
      setPersonSelectedList(personSelected);
    }
  }

  const postPenugasan = async () => {
    let courseList = courseSelectedList.map((x) => x.id).toString();
    let personList = personSelectedList.map((x) => x.id).toString();
    let payload = {
      assignees: personList,
      courses: courseList,
      deadline: moment(dateAssignee).format("YYYY-MM-DD HH:mm"),
      title: titleAssignee,
    };
    try {
      const response = await TaskServices.postTaskManagement(payload);
      if (response) {
        setShow(false);
        setStatusModal("select-course");
        getPenugasanList(
          `?company_id=${userDetailInfo.company_id}&size=${sizeDefault}&page=1`
        );
        setCourseSelectedList([]);
        setPersonSelectedList([]);
        setModalOpen(!modalOpen);
        setModalContentResponse({
          title: "Selamat!",
          subtitle: `Anda Telah Berhasil Membuat Penugasan dengan nama <b>${titleAssignee}</b>`,
          icon: <Image src={successIcon} />,
          action: (
            <>
              <Button
                className="btn-action__confirm success"
                onClick={() => setModalOpen(false)}
              >
                Tutup
              </Button>
            </>
          ),
        });
      }
    } catch (error) {
      setModalOpen(!modalOpen);
      setModalContentResponse({
        title: "Menugaskan Pelatihan Gagal",
        subtitle: `Anda gagal untuk menambahkan penugasan baru silahkan periksa kembali informasi yang Anda masukkan`,
        icon: <Image src={errorIcon} />,
        action: (
          <>
            <Button
              className="btn-action__confirm error"
              onClick={() => setModalOpen(false)}
            >
              Tutup
            </Button>
          </>
        ),
      });
    }
  };

  const getPenugasanList = async (param) => {
    try {
      const response = await TaskServices.getTaskManagement(param);
      if (response) {
        setListTask(response);
      }
    } catch (error) {}
  };
  const getChartPenugasan = async (id) => {
    try {
      const response = await TaskServices.getChartTaskManagement(id);
      if (response) {
        let seriesData = [
          {
            value: response.not_completed,
            itemStyle: {
              color: "#ebb80f",
            },
          },
          {
            value: response.ongoing,
            itemStyle: {
              color: "#306CCC",
            },
          },
          {
            value: response.completed,
            itemStyle: {
              color: "#55aa73",
            },
          },
          {
            value: response.overdue,
            itemStyle: {
              color: "#cb3a31",
            },
          },
        ];
        setChartDataReady(seriesData);
        setTotalStatistic(response.total);
      }
    } catch (error) {}
  };

  const deleteAssignment = async () => {
    setModalOpen(false);
    let payload = {
      assignments: specificId,
    };
    if (typeActive == "all") {
      const selectedIds = document.querySelectorAll(
        "._list-task input:checked"
      );
      const selectedValues = Array.from(selectedIds).map(
        (checkbox) => checkbox.value
      );
      payload.assignments = selectedValues;
    }
    try {
      const response = await TaskServices.removeTaskManagement(payload);
      if (response) {
        setModalContentResponse({
          title: "Selamat!",
          subtitle: `Anda Telah Berhasil Menghapus ${selectedTaskLength} dari List Penugasan`,
          icon: <Image src={successIcon} />,
          action: (
            <>
              <Button
                className="btn-action__confirm success"
                onClick={() => {
                  setModalOpen(false);
                  setSelectedTaskLength(0);
                }}
              >
                Tutup
              </Button>
            </>
          ),
        });
        setModalOpen(true);
        getPenugasanList(
          `?company_id=${userDetailInfo.company_id}&size=${sizeDefault}&page=${activeTask}`
        );
        setIsCanDelete(true);
      }
    } catch (err) {
      setModalOpen(!modalOpen);
      setModalContentResponse({
        title: "Menghapus Pelatihan Gagal",
        subtitle: `Anda gagal untuk menghapus penugasan silahkan coba ulangi lagi`,
        icon: <Image src={errorIcon} />,
        action: (
          <>
            <Button
              className="btn-action__confirm error"
              onClick={() => setModalOpen(false)}
            >
              Tutup
            </Button>
          </>
        ),
      });
      console.log(err);
    }
  };

  const deleteDataAssignmentConfirmation = () => {
    setModalOpen(!modalOpen);
    setModalContentResponse({
      title: "Apakah Anda Yakin untuk Menghapus Penugasan?",
      subtitle: `Anda akan menghapus ${selectedTaskLength} penugasan dari daftar Penugasan`,
      icon: <Image src={warnIcon} />,
      action: (
        <>
          <Button
            className="btn-action__confirm warn"
            onClick={deleteAssignment}
          >
            Ya
          </Button>
          <Button
            className="btn-action__confirm muted"
            onClick={() => setModalOpen(false)}
          >
            Tidak
          </Button>
        </>
      ),
    });
  };

  function handleChange(e) {
    if (e.target.id === "date_assignee") {
      setDateAssignee(e.target.value);
    } else {
      setTitleAssignee(e.target.value);
    }
  }

  useEffect(() => {
    setIsLoaded(true);
  }, []);

  useEffect(() => {
    AuthIsLogin(history, true);
    setLoaded(true);
  }, []);

  useEffect(() => {
    if (
      users?.usersDataId &&
      users?.usersDataId?.company_id !== undefined &&
      users?.usersDataId?.company_id !== ""
    ) {
      let companyId = users?.usersDataId?.company_id;
      getOverviewData(companyId);
      getPopularCourseData(`${companyId}?size=5`);
      getListCourses(`${companyId}`);
      getCreated(`${companyId}`);
      getListCertificate(`${companyId}?size=5`);
      getLeaderboardData(`${companyId}`);
      dispatch(getCompaniesDataThunk(companyId));
    }
  }, [users?.usersDataId?.company_id]);

  useEffect(() => {
    setData(users.usersDataId);
  }, [users]);

  useEffect(() => {
    if (users) {
      let membershipList = users.usersDataId?.memberships;
      for (let i = 0; i < membershipList?.length; i++) {
        if (membershipList[i].status === "active") {
          setMembershipActive(membershipList[i]);
        }
      }
    }
  }, [users]);

  function renderListInfoCertificate(index, item) {
    // switch (index) {
    //   case 0:
    //     return (
    //       <div className="d-flex align-items-center">
    //         <div
    //           style={{
    //             width: "100%",
    //             height: "2px",
    //             position: "relative",
    //             backgroundColor: "#4F4F4F",
    //           }}
    //         >
    //           <div
    //             className="position-absolute"
    //             style={{
    //               width: "10%",
    //               height: "5px",
    //               backgroundColor: "#306CCC",
    //               top: "-2px",
    //             }}
    //           ></div>
    //         </div>
    //         <b className="ml-3">15%</b>
    //       </div>
    //     );
    //   case 1:
    //     return (
    //       <div className="d-flex gap-3 align-items-center">
    //         <div className="wrapp__info-not-passed">
    //           <h3 className="mb-0 text-center">Belum Selesai</h3>
    //         </div>
    //         <img
    //           src={IconInfoGray}
    //           width={12}
    //           height={12}
    //           alt=""
    //           className="ml-3"
    //         />
    //       </div>
    //     );
    //   case 2:
    //     return (
    //       <div className="d-flex gap-3 align-items-center">
    //         <div className="wrapp__info-passed">
    //           <h3 className="mb-0 text-center">Selesai</h3>
    //         </div>
    //       </div>
    //     );
    //   case 3:
    //     return (
    //       <div className="d-flex align-items-center">
    //         <div className="wrapp__info-klaim">
    //           <h3 className="mb-0 text-center">Klaim CPTT/CTT</h3>
    //         </div>
    //       </div>
    //     );
    //   case 4:
    //     return (
    //       <div className="d-flex gap-3 align-items-center">
    //         <div className="wrapp__info-klaim">
    //           <h3 className="mb-0 text-center">Sertifikat dalam pengiriman</h3>
    //         </div>
    //       </div>
    //     );
    //   default:
    //     return null;
    // }
    return !item.status ? (
      "-"
    ) : item.status?.includes("%") ? (
      <div className="status-percentage">
        <div className="percentage">
          <div
            style={{ width: `${parseInt(item.status)}%` }}
            className="percentage-bar"
          ></div>
        </div>
        <div className="current-percentage">{parseInt(item.status)}%</div>
      </div>
    ) : (
      <div
        className={`status-course _capitalize ${
          item.status == "claimed"
            ? "_claim"
            : item.status == "lulus ujian"
            ? "_lulus"
            : item.status == "delivered"
            ? "_send"
            : "_tidak-lulus"
        }`}
      >
        {item.status}
      </div>
    );
  }

  function handleShow() {
    setShow(!show);
  }

  function handleShowModalInternal() {
    return statusModal == "select-course" ? (
      <ContentCourses
        show={show}
        type="add"
        assigneeCourse={assigneeCourse}
        selectedCourseLength={courseSelectedList?.length}
        courseSelectedList={courseSelectedList}
        changeSizeData={changeSizeData}
        sizeCourseDefault={sizeCourseDefault}
      />
    ) : statusModal == "select-person" ? (
      <ContentPerson
        show={show}
        type="add"
        assigneePerson={assigneePerson}
        statusModal={statusModal}
        selectedPersonLength={personSelectedList?.length}
        personSelectedList={personSelectedList}
        changeSizeData={changeSizeData}
        sizeUserDefault={sizeUserDefault}
      />
    ) : (
      <>
        <Row>
          <Col lg={7} className="mb-4">
            <h3
              className="title__modal-show text-left mb-2"
              style={{ color: "#4F4F4F" }}
            >
              Nama Penugasan
            </h3>
            <Form.Control
              placeholder="Masukan Nama Penugasan"
              aria-label="Username"
              aria-describedby="basic-addon1"
              id="title_assignee"
              onChange={handleChange}
              className="input__search-pelatihan w-100"
            />
          </Col>
          <Col lg={5}>
            <h3
              className="title__modal-show text-left"
              style={{ color: "#4F4F4F" }}
            >
              Waktu Deadline
            </h3>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              {/* <DemoContainer components={["DateTimePicker", "DateTimePicker"]}> */}
              <DateTimePicker
                label="Tentukan Waktu Deadline"
                value={dateAssignee}
                onChange={(newValue) => setDateAssignee(newValue)}
                slotProps={{ textField: { size: "small" } }}
              />
              {/* </DemoContainer> */}
            </LocalizationProvider>
          </Col>
          <Col lg={12}>
            <div
              className="mb-3"
              style={{ borderBottom: "1px solid #C7C7C7" }}
            ></div>
          </Col>
        </Row>
        {assignTask()}
      </>
    );
  }

  function assignTask() {
    return (
      <>
        <Row>
          <Col lg={12} className="d-flex">
            <div className="w-100">
              <div className="mb-3">
                <h3 className="title__modal-pelatihan">Daftar Pelatihan</h3>
                <span className="subtitle__modal-pelatihan">
                  {courseSelectedList?.length} pelatihan ditugaskan
                </span>
              </div>
              <Table responsive striped hover className="table-assign_outline">
                <thead className="table-assign_outline">
                  <th>No.</th>
                  <th className="pl-0">Judul Pelatihan</th>
                  <th className="pl-0">Kategori</th>
                </thead>
                <tbody>
                  {courseSelectedList ? (
                    courseSelectedList.map((data, index) => (
                      <tr key={index}>
                        <td className="text-center">
                          <b>{index + 1}</b>
                        </td>
                        <td className="pl-0">{data.title}</td>
                        <td className="pl-0">{data.category}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={3} className="text-center">
                        Data Belum Tersedia
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
            <div className="w-100">
              <div className="mb-3">
                <h3 className="title__modal-pelatihan">Daftar Anggota</h3>
                <span className="subtitle__modal-pelatihan">
                  {personSelectedList?.length} Anggota ditugaskan
                </span>
              </div>
              <Table responsive striped hover className="table-assign_outline">
                <thead className="table-assign_outline">
                  <th>No.</th>
                  <th className="pl-0">Nama Anggota</th>
                  <th className="pl-0">Divisi</th>
                </thead>
                <tbody>
                  {personSelectedList ? (
                    personSelectedList.map((data, index) => (
                      <tr key={index}>
                        <td className="text-center">
                          <b>{index + 1}</b>
                        </td>
                        <td className="pl-0">{data.name}</td>
                        <td className="pl-0">{data.division}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={3} className="text-center">
                        Data Belum Tersedia
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
          </Col>
          <Col lg={6}></Col>
        </Row>
      </>
    );
  }

  function nextStatus(id) {
    if (id == 1) {
      setStatusModal("select-course");
    } else if (id == 2) {
      setStatusModal("select-person");
    } else if (id == 3) {
      setStatusModal("select-task");
    } else if (id == 4) {
      postPenugasan();
    } else if (id == 0) {
      setShow(false);
    }
  }

  function changeSizeData(e, type) {
    if (type === "task") {
      setSizeDefault(e.target.value);
      getPenugasanList(
        `?company_id=${userDetailInfo.company_id}&size=${e.target.value}&page=1`
      );
    } else if (type === "course") {
      setSizeCourseDefault(e.target.value);
    } else {
      setSizeUserDefault(e.target.value);
    }
  }

  async function getOverviewData(id) {
    try {
      const response = await AuthUsersDetail.getKuotaMembers(id);

      if (response) {
        setOverviewData(response);
      }
    } catch (error) {
      console.error(error);
    }
  }

  const getListCourses = async (param) => {
    try {
      const response = await AnalyticLearningService.getEnrolledCourse(param);
      if (response) {
        setCourseData(response);
        // setCourseList(response);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const getCreated = async (param) => {
    try {
      const response = await AnalyticLearningService.getCreatedCourse(param);
      if (response) {
        // console.log(response);
        setCreatedCourse(response);
        // setCourseData(response);
        // setCourseList(response);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getListCertificate = async (param) => {
    try {
      const response = await AnalyticLearningService.getEnrolledCertificate(
        param
      );
      if (response) setCertificateList(response);
    } catch (err) {
      console.log(err);
    }
  };

  const getLeaderboardData = async (param) => {
    try {
      const response = await AnalyticLearningService.getLeaderboard(param);
      if (response) {
        setLeaderboardData(response);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getPopularCourseData = async (param) => {
    try {
      const response = await AnalyticLearningService.getPopularCourse(param);
      if (response) {
        setPopularCourseData(response);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Container fluid>
      <Row className="mt-5 mt-lg-0">
        <div className="h-100 d-flex flex-wrap mb-md-5 col-12">
          <Col
            lg={4}
            className="d-lg-block col-12 px-0 pl-lg-0 pr-md-3 mb-3 mb-lg-0"
          >
            <Card className="card__name-overview p-3 position-relative h-100 d-flex align-items-center">
              <div className="h-100">
                <h3>
                  Hello, <b>{users.usersDataId.username}</b>
                </h3>
                <span className="text__name-overview">
                  Admin, {users.usersDataId.company_name}
                </span>
                <div className="text__address-overview">
                  <img src={IconLocation} alt="" />{" "}
                  <span>{overviewData?.address}</span>
                </div>
              </div>
              <div className="circle-down__overview">
                <img src={IconCircle} alt="" />
              </div>
              <div className="circle-up__overview">
                <img src={IconCircleUp} alt="" />
              </div>
            </Card>
          </Col>
          <Col lg={8} className="col-12 h-lg-100 d-flex flex-wrap px-0">
            <Col
              lg={4}
              className="pl-0 col-12 mb-3 mb-lg-0 px-0 pl-lg-0 pr-md-3"
            >
              <Card className="card_container card__wrapp-overview position-relative">
                <div className="card_inside__padding ">
                  <h3 className="title__card-inside-padding">Total Anggota</h3>
                  <div className="d-flex my-3">
                    <div className="mr-3">
                      <img src={IconMembership} alt="" />
                    </div>
                    <div>
                      <h1 className="number__card-inside-padding">
                        {overviewData?.members ? overviewData?.members : 0}
                      </h1>
                      <div className="sub-number__card-inside-padding">
                        membership
                      </div>
                    </div>
                  </div>
                  <div className="text-right">
                    <Link
                      to="/dashboard/management-penugasan"
                      className="link__card-inside-padding"
                    >
                      Lihat Selengkapnya
                    </Link>
                  </div>
                </div>
                <div className="circle__right-up">
                  <img src={IconCirleRightUp} alt="" />
                </div>
              </Card>
            </Col>
            <Col
              lg={4}
              className="pl-0 col-12 mb-3 mb-lg-0 px-0 pl-lg-0 pr-md-3"
            >
              <Card className="card_container card__wrapp-overview position-relative">
                <div className="card_inside__padding ">
                  <h3 className="title__card-inside-padding">
                    Pelatihan Diikuti
                  </h3>
                  <div className="d-flex my-3">
                    <div className="mr-3">
                      <img src={IconDocument} alt="" />
                    </div>
                    <div>
                      <h1 className="number__card-inside-document">
                        {courseData?.total_enrolled}
                      </h1>
                      <div className="sub-number__card-inside-padding">
                        pelatihan
                      </div>
                    </div>
                  </div>
                  <div className="text-right">
                    <Link to="#" className="link__card-inside-padding">
                      Lihat Selengkapnya
                    </Link>
                  </div>
                </div>
                <div className="circle__right-up">
                  <img src={IconCirleRightUp} alt="" />
                </div>
              </Card>
            </Col>
            <Col
              lg={4}
              className="pl-0 col-12 mb-3 mb-lg-0 px-0 pl-lg-0 pr-md-3"
            >
              <Card className="card_container card__wrapp-overview position-relative">
                <div className="card_inside__padding ">
                  <h3 className="title__card-inside-padding">User Aktif</h3>
                  <div className="d-flex my-3">
                    <div className="mr-3">
                      <img src={IconUserActive} alt="" />
                    </div>
                    <div>
                      <h1 className="number__card-user-active mb-0">
                        {overviewData?.active_users
                          ? overviewData?.active_users
                          : 0}
                      </h1>
                      <div className="">
                        <span className="sub-number__card-user-active">
                          <span>user</span> sedang aktif saat ini
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="circle__right-up">
                  <img src={IconCirleRightUp} alt="" />
                </div>
              </Card>
            </Col>
          </Col>
        </div>
        <div className="col-12 px-lg-0 mb-md-2">
          <Col lg={12} className="col-12 px-0 px-lg-3 mt-3 mt-lg-0">
            <Card className="card_inside__padding h-lg-100 card__wrapp-calender position-relative">
              <DateCalendarServerRequest />
            </Card>
          </Col>
        </div>
        <div className="d-flex flex-wrap mb-md-1 col-12 mt-3 ">
          <Col lg={8} className="col-12 px-0 pr-lg-3 mb-3 mb-lg-0">
            <Card className="card_inside__padding table__list-courses">
              <h3 className="title__card-inside-padding">Pelatihan Populer</h3>
              <div className="overflow_card">
                <Table striped hover>
                  <thead>
                    <tr>
                      <th>No</th>
                      <th>Judul Pelatihan</th>
                      <th>Diikuti Oleh</th>
                      <th>Diselesaikan</th>
                      <th>Peforma Test</th>
                    </tr>
                  </thead>
                  <tbody>
                    {popularCourseData?.popular_courses?.length > 0 ? (
                      popularCourseData?.popular_courses?.map((data, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>
                            <div
                              className=" text-truncate"
                              style={{ maxWidth: "120px" }}
                            >
                              <Link to="#">{data.title}</Link>
                            </div>
                          </td>
                          <td>{data.participant ? data.participant : 0}</td>
                          <td>{data.valid_users ? data.valid_users : 0}</td>
                          <td>
                            {data.growth ? (
                              data.growth.toString()?.includes("-") ? (
                                <img
                                  src={IconArrowDown}
                                  style={{
                                    marginRight: "5px",
                                    rotate: "180deg",
                                  }}
                                  alt=""
                                />
                              ) : (
                                <img
                                  src={IconArrowUp}
                                  style={{ marginRight: "5px" }}
                                  alt=""
                                />
                              )
                            ) : (
                              ""
                            )}
                            {data.growth ? data.growth.toFixed(2) : "0"}%
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={5} className="text-center">
                          Data Belum Tersedia
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
            </Card>
          </Col>
          <Col lg={4} className="px-0 col-12 mb-3 mb-lg-0">
            {isLoaded && courseData && (
              <CompletionRate
                data={courseData}
                type="overview"
                handleShow={handleShow}
              />
            )}
          </Col>
        </div>
        <Col lg={12} className="mt-3 col-12">
          <Card className="card_inside__padding table__list-certificate">
            <h3 className="title__card-inside-padding">
              Program Sertifikasi yang diikuti
            </h3>
            <div className="overflow_card">
              <TableAnalyticData
                head=""
                data={certificateList}
                active={1}
                size={5}
                type="certificate"
                isOverview={true}
              />
            </div>
            <div className="text-center w-100">
              <Link to="/dashboard/analitik-pembelajaran">
                Lihat Selengkapnya
              </Link>
            </div>
          </Card>
        </Col>

        <Col xs={12} className="mt-5 col-12 d-flex flex-wrap">
          <Col lg={6} className="col-12 pl-0 mb-3 mb-lg-0">
            <Card className="card_inside__padding table__list-education">
              <h3 className="title__card-inside-padding mb-3">
                Pelatihan PT {users.usersDataId.company_name}
              </h3>
              <div className="mb-3">
                <Link className="manage-course" to="#">
                  Kelola Pelatihan
                </Link>
              </div>
              <div className="d-flex justify-content-between mb-3 flex-wrap">
                <div className="border-md-right mb-2 mb-lg-0">
                  <h3 className="title__education-card">
                    <span>
                      {" "}
                      {(
                        (createdCourse?.created_courses_count * 102400) /
                        1048576
                      ).toFixed(2)}{" "}
                      /
                    </span>
                    {((createdCourse?.max_courses * 102400) / 1048576).toFixed(
                      2
                    )}{" "}
                    GB
                  </h3>
                  <span className="subtitle__education-card mr-2">
                    Penyimpanan terpakai
                  </span>
                </div>
                <div className="border-md-right">
                  <h3 className="title__education-card">
                    <span>{createdCourse?.created_courses_count}</span>
                  </h3>
                  <span className="subtitle__education-card mr-2">
                    Pelatihan dibuat
                  </span>
                </div>
                <div className="">
                  <h3 className="title__education-card">
                    <span>
                      {createdCourse?.created_courses_durations
                        ? createdCourse?.created_courses_durations
                        : 0}{" "}
                      Menit
                    </span>
                  </h3>
                  <span className="subtitle__education-card">
                    Durasi pelatihan
                  </span>
                </div>
              </div>
              <div className="overflow_card">
                <Table striped hover>
                  <thead>
                    <tr>
                      <th>No</th>
                      <th>Judul Pelatihan</th>
                      <th>Durasi</th>
                    </tr>
                  </thead>
                  <tbody>
                    {createdCourse?.created_courses?.length > 0 ? (
                      createdCourse?.created_courses?.map((data, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>
                            <Link
                              to={`/dashboard/analitik-pembelajaran/course/course/${data?.course_id}`}
                            >
                              {data.title}
                            </Link>
                          </td>
                          <td>{data.duration}</td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={3} className="text-center">
                          Data Belum Tersedia
                        </td>
                      </tr>
                    )}
                    {/* <tr>
                      <td colSpan={4} className="text-center">
                        <Link to="#">Kelola Pelatihan</Link>
                      </td>
                    </tr> */}
                  </tbody>
                </Table>
              </div>
            </Card>
          </Col>
          <Col lg={6} className="px-0 col-12">
            <Card className="card_inside__padding table__list-leaderboard">
              <h3 className="title__card-inside-padding">Leaderboard</h3>
              <div className="overflow_card">
                <Table striped hover>
                  <thead>
                    <tr>
                      <th>No</th>
                      <th>Nama</th>
                      <th>Pelatihan Selesai</th>
                      <th>Performa Test</th>
                    </tr>
                  </thead>
                  <tbody>
                    {leaderboardData?.leaderboard?.length > 0 ? (
                      leaderboardData?.leaderboard?.map((data, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>
                            <Link to="#">{data.name}</Link>
                          </td>
                          <td>{data.completed_courses}</td>
                          <td>
                            {data.growth ? (
                              data.growth.toString()?.includes("-") ? (
                                <img
                                  src={IconArrowDown}
                                  style={{
                                    marginRight: "5px",
                                    rotate: "180deg",
                                  }}
                                  alt=""
                                />
                              ) : (
                                <img
                                  src={IconArrowUp}
                                  style={{ marginRight: "5px" }}
                                  alt=""
                                />
                              )
                            ) : (
                              ""
                            )}
                            {data.growth ? data.growth.toFixed(2) : "0"}%
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={5} className="text-center">
                          Data Belum Tersedia
                        </td>
                      </tr>
                    )}
                    {/* <tr>
                      <td colSpan={4} className="text-center">
                        <Link to="/dashboard/management-organisasi">
                          Lihat Selengkapnya
                        </Link>
                      </td>
                    </tr> */}
                  </tbody>
                </Table>
              </div>
            </Card>
          </Col>
        </Col>
      </Row>

      <ResponseModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        modalContent={modalContent()}
      />

      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        scrollable={true}
        centered
        show={show}
        onHide={() => setShow(!show)}
      >
        <Modal.Header className="d-block">
          <h3 className="title__modal-pelatihan">
            {statusModal == "select-course"
              ? "Pilih Pelatihan"
              : statusModal == "select-task"
              ? "Buat Penugasan"
              : "Pilih Anggota"}
          </h3>
          <span className="subtitle__modal-pelatihan">
            Tentukan Pelatihan yang akan Anda tugaskan
          </span>
        </Modal.Header>
        <Modal.Body>{handleShowModalInternal()}</Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn__back-show"
            onClick={() =>
              nextStatus(
                statusModal == "select-course"
                  ? 0
                  : statusModal == "select-person"
                  ? 1
                  : 2
              )
            }
          >
            Kembali
          </button>
          <button
            onClick={() =>
              nextStatus(
                statusModal == "select-course"
                  ? 2
                  : statusModal == "select-person"
                  ? 3
                  : statusModal === "select-task"
                  ? 4
                  : 1
              )
            }
            className="btn btn__cotinue-show"
          >
            {statusModal == "select-task" ? "Simpan" : "Lanjut"}
          </button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}

export default Overview;
const listEvent = [
  {
    start: moment().toDate(),
    end: moment().add(10, "days").toDate(),
    title: "Webinar Brevet",
  },
];
