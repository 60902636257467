import {
  //get
  ANALYTICS_GROUP_DATA,
  ANALYTICS_GROUP_DATA_ID_SUCCESS,
  ANALYTICS_GROUP_DATA_SUCCESS,
  ANALYTICS_GROUP_DATA_FAIL,
  // get assigned
  ANALYTICS_ASSIGNED_DATA,
  ANALYTICS_ASSIGNED_DATA_SUCCESS,
  ANALYTICS_ASSIGNED_DATA_ID_SUCCESS,
  // fail assigned
  ANALYTICS_ASSIGNED_DATA_FAIL,

  // get submitted
  ANALYTICS_SUBMITTED_DATA,
  ANALYTICS_SUBMITTED_DATA_SUCCESS,
  ANALYTICS_SUBMITTED_DATA_ID_SUCCESS,
  // fail submitted
  ANALYTICS_SUBMITTED_DATA_FAIL,

  // get
  ANALYTICS_COURSE_STATS_DATA,
  ANALYTICS_COURSE_STATS_DATA_SUCCESS,
  ANALYTICS_COURSE_STATS_DATA_ID_SUCCESS,
  // fail
  ANALYTICS_COURSE_STATS_DATA_FAIL,
  //remove
  REMOVE_ANALYTICS_GROUP_DATA_ID,
} from "../config/AnalyticsGroupConfig";

const intialState = {
  analyticsGroupData: null,
  analyticsGroupDataId: null,
  analyticsAssignedData: null,
  analyticsAssignedDataId: null,
  analyticsSubmittedData: null,
  analyticsSubmittedDataId: null,
  analyticsCourseStatsData: null,
  analyticsCourseStatsDataId: null,
  isLoading: false,
};
export const AnalyticsGroupReducers = function (state = intialState, action) {
  switch (action.type) {
    //get
    case ANALYTICS_GROUP_DATA:
      return {
        ...state,
        isLoading: true,
        analyticsGroupData: null,
        analyticsGroupDataId: null,
      };
    case ANALYTICS_GROUP_DATA_SUCCESS:
      return { ...state, isLoading: false, analyticsGroupData: action.value };
    case ANALYTICS_GROUP_DATA_ID_SUCCESS:
      return { ...state, isLoading: false, analyticsGroupDataId: action.value };
    case ANALYTICS_GROUP_DATA_FAIL:
      return {
        ...state,
        isLoading: false,
        analyticsGroupData: "error",
        analyticsGroupDataId: "error",
      };
    case ANALYTICS_ASSIGNED_DATA:
      return {
        ...state,
        isLoading: true,
        analyticsAssignedData: null,
        analyticsAssignedDataId: null,
      };
    case ANALYTICS_ASSIGNED_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        analyticsAssignedData: action.value,
      };
    case ANALYTICS_ASSIGNED_DATA_ID_SUCCESS:
      return {
        ...state,
        isLoading: false,
        analyticsAssignedDataId: action.value,
      };
    case ANALYTICS_ASSIGNED_DATA_FAIL:
      return {
        ...state,
        isLoading: false,
        analyticsAssignedData: "error",
        analyticsAssignedDataId: "error",
      };

    case ANALYTICS_SUBMITTED_DATA:
      return {
        ...state,
        isLoading: true,
        analyticsSubmittedData: null,
        analyticsSubmittedDataId: null,
      };
    case ANALYTICS_SUBMITTED_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        analyticsSubmittedData: action.value,
      };
    case ANALYTICS_SUBMITTED_DATA_ID_SUCCESS:
      return {
        ...state,
        isLoading: false,
        analyticsSubmittedDataId: action.value,
      };
    case ANALYTICS_SUBMITTED_DATA_FAIL:
      return {
        ...state,
        isLoading: false,
        analyticsSubmittedData: "error",
        analyticsSubmittedDataId: "error",
      };


    case ANALYTICS_COURSE_STATS_DATA:
      return {
        ...state,
        isLoading: true,
        analyticsCourseStatsData: null,
        analyticsCourseStatsDataId: null,
      };
    case ANALYTICS_COURSE_STATS_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        analyticsCourseStatsData: action.value,
      };
    case ANALYTICS_COURSE_STATS_DATA_ID_SUCCESS:
      return {
        ...state,
        isLoading: false,
        analyticsCourseStatsDataId: action.value,
      };
    case ANALYTICS_COURSE_STATS_DATA_FAIL:
      return {
        ...state,
        isLoading: false,
        analyticsCourseStatsData: "error",
        analyticsCourseStatsDataId: "error",
      };
    case REMOVE_ANALYTICS_GROUP_DATA_ID:
      return {
        ...state,
        analyticsGroupDataId: null,
      };
    default:
      return state;
  }
};
