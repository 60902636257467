import React, { useState, useEffect } from "react";
import { Row, Col, Button, Form } from "react-bootstrap";
import { SkValidate } from "./common/SkValidate";
import { Clone, secondToHms, hmsToSecond } from "../helper/functions";
import { useDispatch, useSelector } from "react-redux";
import { getQuizThemeDataThunk } from "../redux/thunks/QuizThemeThunks";
// import { getQuizThemeData } from "../redux/actions/QuizThemeActions";
import {
  postQuizDataThunk,
  putQuizDataThunk,
  // getQuizDataThunk,
} from "../redux/thunks/QuizThunks";
import { getQuizThemeLiveSearchDataThunk } from "../redux/thunks/QuizThemeThunks";
import { removeQuizThemeLive } from "../redux/actions/QuizThemeActions";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useHistory } from "react-router-dom";
const MySwal = withReactContent(Swal);

function AddQuiz({ topic_id, id, defaultItems, handleCancel, fixedTitle }) {
  const [searchValue, setSearchValue] = useState([""]);
  const defaultData = {
    duration: "00:00:00",
    passing_grade: 75,
    title: fixedTitle ? fixedTitle : "",
    structure: [{ quiz_theme_id: {}, questions_count: null }],
    topic_id: topic_id,
  };
  const defaultConstraints = {
    topic_id: {
      presence: true,
      length: { minimum: 3 },
    },
    title: {
      presence: true,
      length: { minimum: 3 },
    },
    // questions_count: {
    //   presence: true,
    //   numericality: true,
    // },
    duration: {
      format: {
        pattern: /^([0-1]\d|2[0-3]):([0-5]\d):([0-5]\d)$/,
        message: "can only contain number with format hh:mm:ss",
      },
    },
  };

  const dispatch = useDispatch();
  const quizzes = useSelector((state) => state.quizzes);
  const topics = useSelector((state) => state.topics);
  const quiz_themes = useSelector((state) => state.quiz_themes);
  const quizThemeData = quiz_themes.quizThemeData;
  const quizThemeDataLive = quiz_themes.quizThemeLiveSearchData;
  const quizType = [
    "Pilih Secara Random",
    // "Pilih Secara Manual"
  ];
  const [selectedQuizType, setSelectedQuizType] = useState(
    "Pilih Secara Random"
  );
  const [data, setData] = useState(defaultData);
  const [trigger, setTrigger] = useState(false);
  const history = useHistory();
  const [constraints, setConstraints] = useState(defaultConstraints);

  useEffect(() => {
    if (quizzes.quizDataPost === "error" && trigger) {
      setTrigger(false);
      displayPopup(false, "Terjadi masalah saat submit data", "Ok");
    }
    if (quizzes.quizDataPut === "error" && trigger) {
      setTrigger(false);
      displayPopup(false, "Terjadi masalah saat edit data", "Ok");
    }
    if (quizzes.quizDataPut === "success" && trigger) {
      handleCancel();
    }
    if (quizzes.quizDataPost === "success" && trigger) {
      if (handleCancel) handleCancel();
      else history.push("/dashboard/table/quiz/");
    }
  }, [quizzes]);

  useEffect(() => {
    dispatch(getQuizThemeDataThunk());
  }, []);

  function handleChangeMulti(e, i) {
    const newData = { ...data };
    const id = e.target.id;
    const structure = [...newData.structure];
    // if (i <= structure?.length) {
    structure[i][id] = parseInt(e.target.value);
    newData.structure = structure;
    // } else {
    //   const newStructure = { [id]: e.target.value };
    //   newData.structure.push(newStructure);
    // }

    setData(newData);
  }

  function resetState() {
    dispatch(removeQuizThemeLive());
    const newSearchValue = [...searchValue];
    for (let i = 0; i < newSearchValue.length; i++) {
      newSearchValue[i] = "";
    }
    setSearchValue(newSearchValue);
  }

  useEffect(() => {
    if (selectedQuizType === "Pilih Secara Manual") {
      const exerciseConstraints = {
        ...defaultConstraints,
        question_ids: {
          presence: { allowEmpty: false },
        },
      };
      setConstraints(exerciseConstraints);
    } else {
      const exerciseConstraints = {
        ...defaultConstraints,
        // quiz_theme_id: {
        //   presence: true,
        // },
        // questions_count: {
        //   presence: true,
        //   numericality: {
        //     greaterThan: 0,
        //   },
        // },
      };
      setConstraints(exerciseConstraints);
    }
  }, [selectedQuizType]);

  const liveSearch = (e, index) => {
    const newSearchValue = [...searchValue];
    newSearchValue[index] = e.target.value;
    setSearchValue(newSearchValue);
  };

  function removeCourseHandler(index) {
    const newData = { ...data };
    const structure = [...newData.structure];
    structure[index].quiz_theme_id = {};
    newData.structure = structure;
    setData(newData);
  }

  const onKeyDownHanlder = (e, index) => {
    if (e.keyCode == 13) {
      if (searchValue[index]) {
        dispatch(
          getQuizThemeLiveSearchDataThunk(
            `?size=10&keyword=${searchValue[index]}`
          )
        );
      } else dispatch(removeQuizThemeLive());
    }
  };
  useEffect(() => {
    let newDefaultData = Clone(defaultData);
    if (defaultItems) {
      if (defaultItems.quiz_id) {
        Object.keys(newDefaultData).forEach(function (key) {
          if (newDefaultData[key] == null || newDefaultData[key] == 0) {
            newDefaultData[key] = defaultItems[key];
          }
        });
        newDefaultData.duration = secondToHms(defaultItems.duration);
        newDefaultData.passing_grade = defaultItems.passing_grade;
        setData(newDefaultData);
      }
    }
  }, [defaultItems]);

  function selectQuizThemeHandler(value) {
    const newData = { ...data };
    let userExists = -1;
    if (newData.structure?.length > 0) {
      userExists = newData.structure
        .map(function (e) {
          return e.quiz_theme_id?.theme_id;
        })
        .indexOf(value?.quiz_theme_id?.theme_id);
    }
    setData(newData);
  }

  const countQuestion = data.structure;
  const dataCount = Object.values(countQuestion);
  const handleSubmitForm = (e) => {
    e.preventDefault();
    const form = e.target;
    const isValid = SkValidate(form, constraints);
    const timeInSecond = hmsToSecond(data.duration);
    if (isValid) {
      if (timeInSecond > 0) {
        setTrigger(true);
        if (id) {
          let req = {};
          req.body = {
            duration: timeInSecond,
            passing_grade: data.passing_grade,
            title: data.title,
            topic_id: data.topic_id,
          };
          req.id = id;
          dispatch(putQuizDataThunk(req));
        } else {
          const materialTemp = topics.topicDataId?.materials;
          const seq =
            materialTemp?.length > 0
              ? materialTemp[materialTemp.length - 1].sequence + 1
              : 0;
          const newData = {
            ...data,
            sequence: seq,
            duration: timeInSecond,
          };
          if (selectedQuizType === "Pilih Secara Manual") {
            // dispatch(postQuizDataThunk(newData));
          } else if (selectedQuizType === "Pilih Secara Random") {
            for (let i = 0; i < newData?.structure.length; i++) {
              newData.structure[i].quiz_theme_id =
                newData.structure[i].quiz_theme_id?.theme_id;
            }
            dispatch(postQuizDataThunk(newData, "random"));
          }
        }
      } else {
        displayPopup(false, "Durasi tidak boleh 0!", "oke");
      }
    }
  };
  useEffect(() => {}, [data]);
  const handleChange = (e) => {
    const newData = { ...data };
    if (e.target.id === "passing_grade")
      newData[e.target.id] = parseInt(e.target.value);
    else {
      newData[e.target.id] = e.target.value;
    }
    setData(newData);
  };

  const handleRemove = (idx, indexCount) => {
    const newData = { ...data };
    const newSearchValue = [...searchValue];
    newData.structure.splice(indexCount, 1);
    newSearchValue.splice(indexCount, 1);
    setData(newData);
    setSearchValue(newSearchValue);
  };

  const handleChangeQuizType = (e) => {
    const tipe = e.target.value;
    setSelectedQuizType(tipe);
  };

  function displayPopup(cancel, title, confirm) {
    MySwal.fire({
      title: title,
      showCancelButton: cancel,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: confirm,
    }).then((value) => {
      console.log(value, "value");
    });
  }
  // const rowAdd = () => {
  //   return (

  //   );
  // };
  const handleClone = () => {
    const newData = { ...data };
    const newSearchValue = [...searchValue];
    newData.structure.push({ quiz_theme_id: {}, questions_count: null });
    newSearchValue.push("");
    setData(newData);
    setSearchValue(newSearchValue);
  };

  function selectCourseHandler(value, index) {
    const newData = { ...data };
    const structure = [...newData.structure];
    structure[index].quiz_theme_id = {
      theme_id: value.theme_id,
      title: value.title,
    };
    newData.structure = structure;
    setData(newData);
    resetState();
  }

  return (
    <>
      <Form
        onSubmit={handleSubmitForm}
        id="form-assignment"
        onKeyDown={(e) => {
          e.key === "Enter" && e.preventDefault();
        }}
      >
        {/* Topic ID */}
        <Form.Group>
          <Form.Label className="title_label__input">ID Topik</Form.Label>
          <Form.Control
            type="text"
            id="topic_id"
            name="topic_id"
            placeholder="Masukkan ID Topik"
            value={data.topic_id}
            onChange={handleChange}
            disabled={topic_id || id}
          />
          <div className="messages"></div>
        </Form.Group>

        {/* Title */}
        <Form.Group>
          <Form.Label className="title_label__input">Judul Quiz*</Form.Label>
          <Form.Control
            type="text"
            id="title"
            name="title"
            placeholder="Masukkan Judul Quiz"
            value={data.title}
            onChange={handleChange}
            disabled={fixedTitle}
          />
          <div className="messages"></div>
        </Form.Group>

        <Row>
          <Col sm={12} md={6}>
            {/* Duration */}
            <Form.Group>
              <Form.Label className="title_label__input">
                Durasi* (hh:mm:ss)
              </Form.Label>
              <Form.Control
                type="text"
                id="duration"
                name="duration"
                placeholder="Masukkan Duration"
                value={data.duration}
                onChange={handleChange}
              />
              <div className="messages"></div>
            </Form.Group>
          </Col>
          <Col sm={12} md={6}>
            {/* Passing Grade */}
            <Form.Group>
              <Form.Label className="title_label__input">
                Passing Grade* (%)
              </Form.Label>
              <Form.Control
                type="text"
                id="passing_grade"
                name="passing_grade"
                placeholder="Masukkan Passing Grade"
                value={data?.passing_grade ? data.passing_grade : 0}
                onChange={handleChange}
              />
              <div className="messages"></div>
            </Form.Group>
          </Col>
        </Row>

        {/* tipe quiz */}
        {!id ? (
          <>
            {" "}
            <Form.Group>
              <Form.Label className="title_label__input">
                {" "}
                Pilih Soal{" "}
              </Form.Label>
              <Form.Control
                required
                as="select"
                type="select"
                name=""
                onChange={handleChangeQuizType}
              >
                {quizType.map((item, i) => {
                  return (
                    <option value={item} key={i}>
                      {" "}
                      {item}{" "}
                    </option>
                  );
                })}
              </Form.Control>
            </Form.Group>
            {/* tipe quiz: exercise */}
            {selectedQuizType === "Pilih Secara Manual" ? (
              // question_ids
              <Form.Group>
                <Form.Label className="title_label__input">ID Soal*</Form.Label>
                <Form.Control
                  type="text"
                  id="question_ids"
                  name="question_ids"
                  placeholder="Masukkan ID Soal"
                  value={data.question_ids}
                  onChange={handleChange}
                />
                <div className="messages"></div>
              </Form.Group>
            ) : (
              <>
                {/* tipe quiz: exam */}
                {/* <>{renderThemeQuiz()}</> */}
                {data.structure.map((value, index) => (
                  <div className="row-add">
                    <Row>
                      <Col sm={12} lg={6}>
                        {/* quiz_theme_id */}
                        {/* type */}
                        <Form.Group>
                          <Form.Label className="title_label__input">
                            Tema Soal{" "}
                            <small>(tekan enter untuk mencari tema)</small>
                          </Form.Label>
                          {value.quiz_theme_id?.title ? (
                            <div className="selected-item-wrapper">
                              <p
                                className="selected-item"
                                onClick={() => removeCourseHandler(index)}
                              >
                                {value.quiz_theme_id.title}
                              </p>
                            </div>
                          ) : null}
                          <Form.Control
                            className="mb-3"
                            type="text"
                            name="structure"
                            id=""
                            placeholder="Cari Tema Soal"
                            onChange={(e) => liveSearch(e, index)}
                            value={searchValue[index]}
                            onKeyDown={(e) => onKeyDownHanlder(e, index)}
                            autoComplete="off"
                            autoCorrect="off"
                            spellCheck="off"
                            onFocus={resetState}
                            disabled={quiz_themes?.isLoadingLive}
                          />
                          {searchValue[index] && quizThemeDataLive.items ? (
                            <div className="search-list">
                              {quiz_themes?.isLoadingLive ||
                              quizThemeDataLive.length <= 0 ? (
                                <div>
                                  <p>Loading...</p>
                                </div>
                              ) : (
                                <ul className="list-wrapper">
                                  {quizThemeDataLive.items?.length > 0 ? (
                                    quizThemeDataLive.items.map(
                                      (sVal, sIdx) => {
                                        const isExist = data.structure.some(
                                          (option) =>
                                            option.quiz_theme_id?.theme_id ===
                                            sVal.theme_id
                                        );
                                        return (
                                          <li
                                            className={`list-item ${
                                              isExist
                                                ? "selected-list-item"
                                                : ""
                                            }`}
                                            key={sIdx}
                                            onClick={() =>
                                              selectCourseHandler(sVal, index)
                                            }
                                          >
                                            {sVal.title}
                                          </li>
                                        );
                                      }
                                    )
                                  ) : (
                                    <p>not found</p>
                                  )}
                                </ul>
                              )}
                            </div>
                          ) : null}
                          <div className="messages"></div>
                        </Form.Group>
                      </Col>
                      <Col sm={12} lg={6}>
                        {/* questions_count */}
                        <Form.Group>
                          <Form.Label className="title_label__input">
                            Jumlah Soal*
                          </Form.Label>
                          <Form.Control
                            type="number"
                            min="1"
                            onWheel={(e) => e.target.blur()}
                            id="questions_count"
                            name="questions_count"
                            placeholder="Masukkan Jumlah Soal"
                            value={data.structure[index]?.questions_count}
                            onChange={(e) => handleChangeMulti(e, index)}
                          />
                          <div className="messages"></div>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Button
                      onClick={() => handleRemove(value, index)}
                      className={`${
                        data.structure.length > 1 ? "d-block" : "d-none"
                      } btn btn-primary btn-close__quiz`}
                    >
                      x
                    </Button>
                  </div>
                ))}

                <Row>
                  <Col className="offset-11">
                    <Button onClick={handleClone}>+</Button>
                  </Col>
                </Row>
              </>
            )}
          </>
        ) : null}

        <div className="d-flex justify-content-end">
          {handleCancel ? (
            <Button
              type="submit"
              variant="light"
              className="my-4 mx-2"
              onClick={() => {
                setData(defaultData);
                handleCancel();
              }}
            >
              Batal
            </Button>
          ) : null}
          <Button type="submit" className="my-4 mx-2">
            {id ? "Edit" : <>{"+ Tambahkan"}</>}
          </Button>
        </div>
      </Form>
    </>
  );
}

export default AddQuiz;
