import React, { useEffect } from "react";
import { Row, Col, Container } from "react-bootstrap";
import AddQuizTheme from "../../components/AddQuizTheme";
import { AuthIsLogin } from "../../helper/auth";
import { useHistory } from "react-router-dom";
import "../../assets/css/Dashboard/index.css";

function CreateQuizTheme() {
  const history = useHistory();
  useEffect(() => {
    AuthIsLogin(history);
  }, []);
  return (
    <Container>
      <Row className="justify-content-center align-items-center height-inherit">
        <Col className="p-0">
          <div className=" pt-2 pb-3">
            <div className="course_create__title">Buat Tema Kuis</div>
          </div>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12}>
          <AddQuizTheme />
        </Col>
      </Row>
    </Container>
  );
}

export default CreateQuizTheme;
