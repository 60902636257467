import React from "react";
import { Table, Card } from "react-bootstrap";
import { FaUserAlt } from "react-icons/fa";
import { Link } from "react-router-dom";
import "../assets/css/Dashboard/companyAnalytics.css";
function StudentList({ data, adminBnsp }) {
  return (
    <>
      {" "}
      <Card className="card_container h-100">
        <div className="card_inside__padding webinar-list">
          <div className="d-flex justify-content-between align-items-center border_card">
            <div className="card_title__info mb-2">
              Daftar{" "}
              {adminBnsp && adminBnsp?.usersDataId?.is_bnsp === 1
                ? "Partisipan"
                : "Karyawan"}
            </div>
          </div>

          <Table responsive striped hover>
            <thead>
              <th className="pl-0">Foto</th>
              <th className="w-50">
                Nama{" "}
                {adminBnsp && adminBnsp?.usersDataId?.is_bnsp === 1
                  ? "Partisipan"
                  : "Karyawan"}
              </th>
              <th className="w-25">Posisi</th>
              <th>Action</th>
            </thead>

            {data &&
              data?.students?.map((item, index) => {
                return (
                  <tr key={index}>
                    <td className="pl-0">
                      <div className="software-cat">
                        <div className="img-certificate mr-3">
                          {item?.avatar === "" ? (
                            <FaUserAlt />
                          ) : (
                            <img
                              className="img-avatar__user"
                              src={item.avatar}
                            />
                          )}
                        </div>
                      </div>
                    </td>
                    <td className="position_text bold">
                      <div>{item.name}</div>
                    </td>
                    <td className="position_text">
                      <span>
                        {item?.position === "" ? "N/A" : item?.position}
                      </span>
                    </td>
                    <td className="position_text">
                      <div>
                        <Link
                          to={`/analytics/users/${item?.user_id}`}
                          className="detail_button__users"
                        >
                          Detail
                        </Link>
                      </div>
                    </td>
                  </tr>
                );
              })}
          </Table>
        </div>
      </Card>
    </>
  );
}

export default StudentList;
