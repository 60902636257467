import { satukelasApi } from "../constant/api";

export const AnalyticLearningService = {
  getTotalRombel(id) {
    return satukelasApi.get(`v2/company_dashboard_total_study_group/${id}`);
  },
  getTotalUserCreated(id) {
    return satukelasApi.get(`v2/company_dashboard_total_users_created/${id}`);
  },
  getEnrolledCourse(id) {
    return satukelasApi.get(`v2/company_dashboard_enrolled_courses/${id}`);
  },
  getCreatedCourse(id) {
    return satukelasApi.get(`v2/company_dashboard_created_courses/${id}`);
  },
  getCalendarCourse(param) {
    return satukelasApi.get(`v2/company_dashboard_calendar/${param}`);
  },

  getSummaryCompletedCourse(id) {
    return satukelasApi.get(
      `v2/company_dashboard_summary_completed_courses/${id}`
    );
  },
  getTotalCompleteCourse(id) {
    return satukelasApi.get(
      `v2/company_dashboard_total_completed_courses/${id}`
    );
  },
  getTotalCompleteCertification(id) {
    return satukelasApi.get(
      `v2/company_dashboard_total_completed_certification/${id}`
    );
  },
  getStatusCertification(id) {
    return satukelasApi.get(`v2/company_dashboard_certification_status/${id}`);
  },
  getEnrolledLiveClasses(param) {
    return satukelasApi.get(
      `v2/company_dashboard_enrolled_live_class_list/${param}`
    );
  },

  getEnrolledLiveClassesSummary(param) {
    return satukelasApi.get(
      `v2/company_dashboard_enrolled_live_class_summary/${param}`
    );
  },
  getEnrolledCourseList(param) {
    return satukelasApi.get(
      `v2/company_dashboard_enrolled_courses_list/${param}`
    );
  },
  getEnrolledCertificate(param) {
    return satukelasApi.get(`v2/company_dashboard_certifications/${param}`);
  },
  getPopularCourse(param) {
    return satukelasApi.get(`v2/company_dashboard_popular_courses/${param}`);
  },
  getLeaderboard(param) {
    return satukelasApi.get(`v2/company_dashboard_leaderboard/${param}`);
  },

  getInfoUserCompany(user_id) {
    return satukelasApi.get(`v2/users/${user_id}`);
  },
  getAnalyticCertification(company_id, course_id, param) {
    return satukelasApi.get(
      `v2/company_dashboard_course/${company_id}/${course_id}${param}`
    );
  },
  getATPIDetail(company_id, type) {
    return satukelasApi.get(
      `v2/company_dashboard_atpi_detail/${company_id}/${type}`
    );
  },
  getATPIRequirement(param) {
    return satukelasApi.get(`v2/company_dashboard_atpi_requirements${param}`);
  },
  getIndividualStatisticUser(user_id) {
    return satukelasApi.get(`v2/company_dashboard_user_summary/${user_id}`);
  },
  getATPIListMember(param) {
    return satukelasApi.get(`v2/company_dashboard_atpi_list${param}`);
  },
};
