import React, { useEffect } from "react";
import { Row, Col, Container } from "react-bootstrap";
import TableTopic from "../../components/TableTopic";
import "../../assets/css/Dashboard/index.css";
import { Link } from "react-router-dom";
import { AuthIsLogin } from "../../helper/auth";
import { useHistory } from "react-router-dom";

function TopicTable() {
  const history = useHistory();
  useEffect(() => {
    AuthIsLogin(history, true);
  }, []);
  return (
    <Container fluid>
      <Row className="justify-content-center align-items-center height-inherit">
        <Col >
          <div className=" pt-2 pb-3">
            <div className="course_create__title">Daftar Topik</div>
          </div>
        </Col>
        <Col md="auto" className="text-end">
          <Link to="/topic/create/" className="btn btn-primary px-3 py-2">
            + Tambahkan Topik
          </Link>
        </Col>
        <Col
          xs={12}
          sm={12}
          md={12}
          lg={12}
        >
          <TableTopic />
        </Col>
      </Row>
    </Container>
  );
}

export default TopicTable;
