import { PromiseService } from "../../services/PromiseService";
import { SUB_CATEGORIES_DATA_LINK } from "../config/SubCategoriesConfig";
import {
  //get
  getSubCategoriesData,
  getSubCategoriesDataSuccess,
  getSubCategoriesDataIdSuccess,
  getSubCategoriesDataFail,
  //post
  postSubCategoriesData,
  postSubCategoriesDataSuccess,
  postSubCategoriesDataFail,
  // put
  putSubCategoriesData,
  putSubCategoriesDataSuccess,
  putSubCategoriesDataFail,
  // delete
  deleteSubCategoriesData,
  deleteSubCategoriesDataSuccess,
  deleteSubCategoriesDataFail,
} from "../actions/SubCategoriesActions";

// get
export const getSubCategoriesDataThunk = (data) => async (dispatch) => {
  dispatch(getSubCategoriesData(data));
  if (data) {
    let url = `${SUB_CATEGORIES_DATA_LINK}/${data}`;
    if (data.param) url = `${SUB_CATEGORIES_DATA_LINK}${data.param}`;
    try {
      const response = await PromiseService.getData(url);
      if (response.status === 200 && response.data) {
        if (data.param) dispatch(getSubCategoriesDataSuccess(response.data));
        else dispatch(getSubCategoriesDataIdSuccess(response.data));
      } else {
        dispatch(getSubCategoriesDataFail(response));
      }
    } catch (error) {
      dispatch(getSubCategoriesDataFail(error));
    }
  } else {
    try {
      const response = await PromiseService.getData(`${SUB_CATEGORIES_DATA_LINK}?size=100`);
      if (response.status === 200 && response.data) {
        dispatch(getSubCategoriesDataSuccess(response.data));
      } else {
        dispatch(getSubCategoriesDataFail(response));
      }
    } catch (error) {
      dispatch(getSubCategoriesDataFail(error));
    }
  }
};

// post
export const postSubCategoriesDataThunk = (data) => async (dispatch) => {
  dispatch(postSubCategoriesData(data));
  const body = data;
  try {
    const response = await PromiseService.postData(
      SUB_CATEGORIES_DATA_LINK,
      body
    );
    if (response.status === 201 && response.data) {
      dispatch(postSubCategoriesDataSuccess(response.data));
    } else {
      dispatch(postSubCategoriesDataFail(response));
    }
  } catch (error) {
    dispatch(postSubCategoriesDataFail(error));
  }
};

// put
export const putSubCategoriesDataThunk = (data) => async (dispatch) => {
  dispatch(putSubCategoriesData(data));
  const body = data.body;
  const id = data.id;
  try {
    const response = await PromiseService.putData(
      `${SUB_CATEGORIES_DATA_LINK}/${id}`,
      body
    );
    if (response.status === 201 && response.data) {
      dispatch(putSubCategoriesDataSuccess(response.data));
    } else {
      dispatch(putSubCategoriesDataFail(response));
    }
  } catch (error) {
    dispatch(putSubCategoriesDataFail(error));
  }
};

// delete
export const deleteSubCategoriesDataThunk = (data) => async (dispatch) => {
  dispatch(deleteSubCategoriesData(data));
  const id = data;
  try {
    const response = await PromiseService.deleteData(
      `${SUB_CATEGORIES_DATA_LINK}/${id}`
    );
    if (response.status === 200 && response.data) {
      dispatch(deleteSubCategoriesDataSuccess(response.data));
    } else {
      dispatch(deleteSubCategoriesDataFail(response));
    }
  } catch (error) {
    dispatch(deleteSubCategoriesDataFail(error));
  }
};
