import React from "react";
import { RiErrorWarningFill } from "react-icons/ri";
import { Alert } from "react-bootstrap";
import { Link } from "react-router-dom";
import "../assets/css/Courses/PrerequisitesCourse.css";

function PrerequisitesCourse({ items }) {
  return (
    <>
      <div className="course_front__kurikulum_title">Prasyarat Pelatihan</div>
      <div className="course_front__prereq__container">
        {items?.length > 0 ? (
          <Alert key="0" variant="warning" className="mb-4">
            <div className="fw-500 d-flex">
              <RiErrorWarningFill className="course_front__prereq__warning_icon mr-2" />
              Harap dicatat bahwa pelatihan ini memiliki prasyarat berikut yang
              harus diselesaikan sebelum dapat diakses
            </div>
          </Alert>
        ) : (
          ""
        )}

        <div>
          {items?.length > 0 ? (
            items.map((e, i) => (
              <Link
                key={i}
                to={`/dashboard/course/${e.course_id}`}
                className="course_front__prerequisite_item"
              >
                <div className="course_front__prerequisite_img__wrapper"></div>
                <div className="course_front__prerequisite__course">
                  {e.course}
                </div>
              </Link>
            ))
          ) : (
            <div className="course_front__prerequistes__wrapper">
              <div className="">Data Belum Tersedia</div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
export default PrerequisitesCourse;
