import { PromiseService } from "../../services/PromiseService";
import { WEBINAR_DATA_LINK } from "../config/WebinarConfig";
import {
  //get
  getWebinarData,
  getWebinarDataSuccess,
  getWebinarDataIdSuccess,
  getWebinarDataFail,
  //post
  postWebinarData,
  postWebinarDataSuccess,
  postWebinarDataFail,
  // put
  putWebinarData,
  putWebinarDataSuccess,
  putWebinarDataFail,
  // delete
  deleteWebinarData,
  deleteWebinarDataSuccess,
  deleteWebinarDataFail,
} from "../actions/WebinarActions";

export const postWebinarDataThunk = (data) => async (dispatch) => {
  dispatch(postWebinarData());
  const body = data;
  try {
    const response = await PromiseService.postData(WEBINAR_DATA_LINK, body);
    if (response.status === 201 && response.data) {
      dispatch(postWebinarDataSuccess(response.data));
    } else {
      dispatch(postWebinarDataFail(response));
    }
  } catch (error) {
    dispatch(postWebinarDataFail(error));
  }
};

export const getWebinarDataThunk = (data) => async (dispatch) => {
  dispatch(getWebinarData());
  if (data) {
    let url = `${WEBINAR_DATA_LINK}/${data}`;
    if (data.param) url = `${WEBINAR_DATA_LINK}${data.param}`;
    try {
      const response = await PromiseService.getData(url);
      if (response.status === 200 && response.data) {
        if (data.param) dispatch(getWebinarDataSuccess(response.data));
        else dispatch(getWebinarDataIdSuccess(response.data));
      } else {
        dispatch(getWebinarDataFail(response));
      }
    } catch (error) {
      dispatch(getWebinarDataFail(error));
    }
  } else {
    try {
      const response = await PromiseService.getData(WEBINAR_DATA_LINK);
      if (response.status === 200 && response.data) {
        dispatch(getWebinarDataSuccess(response.data));
      } else {
        dispatch(getWebinarDataFail(response));
      }
    } catch (error) {
      dispatch(getWebinarDataFail(error));
    }
  }
};

// put
export const putWebinarDataThunk = (data) => async (dispatch) => {
  dispatch(putWebinarData());
  const body = data.body;
  const id = data.id;
  try {
    const response = await PromiseService.putData(`${WEBINAR_DATA_LINK}/${id}`, body);
    if (response.status === 201 && response.data) {
      dispatch(putWebinarDataSuccess(response.data));
    } else {
      dispatch(putWebinarDataFail(response));
    }
  } catch (error) {
    dispatch(putWebinarDataFail(error));
  }
};

// delete
export const deleteWebinarDataThunk = (data) => async (dispatch) => {
  dispatch(deleteWebinarData());
  const id = data;
  try {
    const response = await PromiseService.deleteData(`${WEBINAR_DATA_LINK}/${id}`);
    if (response.status === 200 && response.data) {
      dispatch(deleteWebinarDataSuccess(response.data));
    } else {
      dispatch(deleteWebinarDataFail(response));
    }
  } catch (error) {
    dispatch(deleteWebinarDataFail(error));
  }
};
