import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import ReactECharts from "echarts-for-react";
import moment from "moment";
import React, { useEffect, useState } from "react";
import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Container,
  Form,
  Image,
  Modal,
  Row,
  Table,
} from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import "../../assets/css/Dashboard/Management.css";
import errorIcon from "../../assets/images/error-icon.svg";
import IconEdit from "../../assets/images/icon-edit-pen.svg";
import IconOverviewMan from "../../assets/images/icon-overview.svg";
import IconPlusWhite from "../../assets/images/icon-plus-white.svg";
import successIcon from "../../assets/images/success-icon.svg";
import ContentCourses from "../../components/ManagementTask/ContentCourses";
import ContentPerson from "../../components/ManagementTask/ContentPerson";
import ResponseModal from "../../components/common/ResponseModal";
import { TaskServices } from "../../http/TaskHttp";
const MySwal = withReactContent(Swal);

function ManagementPenugasanDetail() {
  const { assign_id } = useParams();
  const colorPalete = ["#306CCC", "#EBB80F"];
  const [personList, setPersonList] = useState(null);
  const [courseList, setCourseList] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [detailData, setDetailData] = useState(null);
  const [percentageData, setPercentageData] = useState(null);
  const [infoTask, setInfoTask] = useState({
    title: "",
    deadline: "",
  });
  const [modalContentResponse, setModalContentResponse] = useState({
    title: "",
    subtitle: "",
    icon: "",
    ctaContent: "",
  });
  const [typeActive, setTypeActive] = useState("all-course");
  // const [dateAssignee, setDateAssignee] = useState("");
  const [courseSelectedList, setCourseSelectedList] = useState([]);
  const [chartDataReady, setChartDataReady] = useState([]);
  const [selectedCourseLength, setSelectedCourseLength] = useState(0);
  const [show, setShow] = useState(false);
  const [disableCourse, setDisableCourse] = useState(true);
  const [disablePerson, setDisablePerson] = useState(true);
  const [showEditInformasi, setShowEditInformasi] = useState(false);
  const [selectedPersonLength, setSelectedPersonLength] = useState(0);
  const [personSelectedList, setPersonSelectedList] = useState([]);
  const [showPerson, setShowPerson] = useState(false);
  const [typeModal, setTypeModal] = useState("course");
  const [selectCourseAll, setSelectCourseAll] = useState(false);
  const [selectPersonAll, setSelectPersonAll] = useState(false);
  const [sizeUserDefault, setSizeUserDefault] = useState(5);
  const [sizeCourseDefault, setSizeCourseDefault] = useState(5);
  const optionPenugasan = {
    tooltip: {
      trigger: "item",
    },

    series: [
      {
        type: "pie",
        radius: ["50%", "80%"],
        label: {
          show: true,
          position: "center",
          lineHeight: 0,
          formatter: `{a|${percentageData}%\n}`, // Use '{a|text}' to display the static text
          rich: {
            a: {
              fontSize: 20, // Set font size as needed
              fontWeight: "700", // Set font weight as needed
              color: "#4F4F4F",
              fontFamily: "Poppins",
            },
          },
        },
        labelLine: {
          show: false,
        },
        data: chartDataReady,
      },
    ],
    color: colorPalete,
  };

  useEffect(() => {
    if (detailData) {
      const newData = { ...detailData };
      setInfoTask({
        title: newData?.title,
        deadline: newData?.deadline,
      });
    }
  }, [detailData]);

  useEffect(() => {
    getCourseAssignee(assign_id);
    getPersonAssignee(assign_id);
    getDetailAssigne(assign_id);
  }, []);

  const getDetailAssigne = async (id) => {
    try {
      const response = await TaskServices.getTaskManagement(`/${id}`);
      if (response) {
        setDetailData(response);
        setInfoTask({
          title: response?.title,
          deadline: response?.deadline,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const editInformasiPenugasan = async () => {
    let payload = {
      company_assignment_id: assign_id,
      company_assignment: {
        deadline: moment(infoTask.deadline).format("YYYY-MM-DD HH:mm"),
        title: infoTask.title,
      },
    };
    try {
      const response = await TaskServices.putTaskManagement(payload);
      if (response) {
        setModalOpen(!modalOpen);
        setModalContentResponse({
          title: "Selamat!",
          subtitle: `Anda Telah Berhasil Mengedit Informasi Penugasan</b>`,
          icon: <Image src={successIcon} />,
          action: (
            <>
              <Button
                className="btn-action__confirm success"
                onClick={() => setModalOpen(false)}
              >
                Tutup
              </Button>
            </>
          ),
        });
        getCourseAssignee(assign_id);
        getPersonAssignee(assign_id);
        getDetailAssigne(assign_id);
        setShowEditInformasi(false);
      }
    } catch (err) {
      console.log(err);
      setModalOpen(!modalOpen);
      setModalContentResponse({
        title: "Mengedit Informasi Penugasan Gagal",
        subtitle: `Anda gagal untuk mengedit informasi penugasan silahkan ulangi beberapa saat lagi`,
        icon: <Image src={errorIcon} />,
        action: (
          <>
            <Button
              className="btn-action__confirm error"
              onClick={() => setModalOpen(false)}
            >
              Tutup
            </Button>
          </>
        ),
      });
    }
  };

  function changeSizeData(e, type) {
    if (type === "course") {
      setSizeCourseDefault(e.target.value);
    } else {
      setSizeUserDefault(e.target.value);
    }
  }

  const getCourseAssignee = async (id) => {
    try {
      const response = await TaskServices.getCourseTaskManagement(`/${id}`);
      if (response) {
        setCourseList(response);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const courseAssign = async () => {
    let courseList = courseSelectedList.map((x) => x.id);
    let payload = {
      company_assignment_id: assign_id,
      company_assignment: { courses: courseList },
    };
    try {
      const response = await TaskServices.putCourseTaskManagement(payload);
      if (response) {
        getCourseAssignee(assign_id);
        getPersonAssignee(assign_id);
        getDetailAssigne(assign_id);
        setCourseSelectedList([]);
        setShow(false);
        setModalOpen(!modalOpen);
        setModalContentResponse({
          title: "Selamat!",
          subtitle: `Anda Telah Berhasil Mengedit Jumlah Course Pada Penugasan Ini</b>`,
          icon: <Image src={successIcon} />,
          action: (
            <>
              <Button
                className="btn-action__confirm success"
                onClick={() => setModalOpen(false)}
              >
                Tutup
              </Button>
            </>
          ),
        });
      }
    } catch (err) {
      setModalOpen(!modalOpen);
      setModalContentResponse({
        title: "Mengedit Course Penugasan Gagal",
        subtitle: `Anda gagal untuk mengedit course di penugasan silahkan ulangi beberapa saat lagi`,
        icon: <Image src={errorIcon} />,
        action: (
          <>
            <Button
              className="btn-action__confirm error"
              onClick={() => setModalOpen(false)}
            >
              Tutup
            </Button>
          </>
        ),
      });
      console.log(err);
    }
  };

  const modalContent = () => {
    return (
      <div>
        <div className="penugasan-status__icon">
          {modalContentResponse.icon}
        </div>
        <div className="penugasan-main__title">
          {modalContentResponse.title}
        </div>
        <div
          className="penugasan-main__subtitle"
          dangerouslySetInnerHTML={{ __html: modalContentResponse.subtitle }}
        />
        <div className="penugasan-cta__action">
          {modalContentResponse.action}
        </div>
      </div>
    );
  };

  const personAssign = async () => {
    let personList = personSelectedList.map((x) => x.id);
    let payload = {
      company_assignment_id: assign_id,
      company_assignment: { assignees: personList },
    };
    try {
      const response = await TaskServices.putPersonTaskManagement(payload);
      if (response) {
        setModalOpen(!modalOpen);
        setModalContentResponse({
          title: "Selamat!",
          subtitle: `Anda Telah Berhasil Mengedit List Anggota Pada Penugasan Ini</b>`,
          icon: <Image src={successIcon} />,
          action: (
            <>
              <Button
                className="btn-action__confirm success"
                onClick={() => setModalOpen(false)}
              >
                Tutup
              </Button>
            </>
          ),
        });
        getCourseAssignee(assign_id);
        getPersonAssignee(assign_id);
        getDetailAssigne(assign_id);
        setPersonSelectedList([]);
        setShowPerson(false);
      }
    } catch (err) {
      console.log(err);
      setModalOpen(!modalOpen);
      setModalContentResponse({
        title: "Mengedit List Anggota Penugasan Gagal",
        subtitle: `Anda gagal untuk mengedit list anggota di penugasan silahkan ulangi beberapa saat lagi`,
        icon: <Image src={errorIcon} />,
        action: (
          <>
            <Button
              className="btn-action__confirm error"
              onClick={() => setModalOpen(false)}
            >
              Tutup
            </Button>
          </>
        ),
      });
    }
  };

  const getPersonAssignee = async (id) => {
    try {
      const response = await TaskServices.getAssigneeTaskManagement(`/${id}`);
      if (response) {
        setPersonList(response);
        setPercentageData(response?.stats?.completed_members_percent);
        let totalCount =
          response?.stats?.total_members - response?.stats?.completed_members;
        let seriesData = [
          {
            value: response?.stats?.completed_members,
          },
          {
            value: totalCount,
          },
        ];
        setChartDataReady(seriesData);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleAdd = (type) => {
    setTypeModal(type);
    if (type == "course") {
      setShow(!show);
    } else {
      setShowPerson(!showPerson);
    }
  };

  function handleShowModalInternal() {
    return (
      <ContentCourses
        show={show}
        type="edit"
        assigneeCourse={assigneeCourse}
        selectedCourseLength={selectedCourseLength}
        courseSelectedList={courseSelectedList}
        changeSizeData={changeSizeData}
        sizeCourseDefault={sizeCourseDefault}
      />
    );
  }

  function assigneePerson(id, name, division) {
    let personSelected = [...personSelectedList];
    setSelectedPersonLength(selectedPersonLength + 1);
    let tmp = { id, name, division };
    personSelected.push(tmp);
    setPersonSelectedList(personSelected);
  }

  function handleShowModalPerson() {
    return (
      <ContentPerson
        show={showPerson}
        type="edit"
        assigneePerson={assigneePerson}
        selectedPersonLength={selectedPersonLength}
        changeSizeData={changeSizeData}
        sizeUserDefault={sizeUserDefault}
      />
    );
  }

  function assigneeCourse(id, title, category) {
    let courseSelected = [...courseSelectedList];
    if (courseSelected.find((x) => x.id == id)) {
      let idx = courseSelected.findIndex((x) => x.id == id);
      courseSelected.splice(idx, 1);
      setCourseSelectedList(courseSelected);
      setSelectedCourseLength(selectedCourseLength - 1);
    } else {
      let tmp = { id, title, category };
      courseSelected.push(tmp);
      setCourseSelectedList(courseSelected);
      setSelectedCourseLength(selectedCourseLength + 1);
    }
  }

  function handleChange(e) {
    const newData = { ...infoTask };
    newData[e.target.id] = e.target.value;
    setInfoTask(newData);
  }

  function handleSelectData(e, type) {
    setTypeActive(type);
    if (type === "course") {
      let courseSelected = [...courseSelectedList];
      if (courseSelected.find((x) => x.id == e.target.value)) {
        let idx = courseSelected.findIndex((x) => x.id == e.target.value);
        courseSelected.splice(idx, 1);
        setCourseSelectedList(courseSelected);
      } else {
        let tmp = { id: e.target.value };
        courseSelected.push(tmp);
        setCourseSelectedList(courseSelected);
      }
      setDisableCourse(false);
    } else {
      let personSelected = [...personSelectedList];
      if (personSelected.find((x) => x.id == e.target.value)) {
        let idx = personSelected.findIndex((x) => x.id == e.target.value);
        personSelected.splice(idx, 1);
        setPersonSelectedList(personSelected);
      } else {
        let tmp = { id: e.target.value };
        personSelected.push(tmp);
        setPersonSelectedList(personSelected);
      }
      setDisablePerson(false);
    }
  }

  const handleSelectAllData = (e, type) => {
    setTypeActive(type);
    e.preventDefault();
    if (type === "all-person") {
      setSelectPersonAll(!selectPersonAll);
    } else {
      setSelectCourseAll(!selectCourseAll);
    }
  };

  const deleteSelectedCourse = async () => {
    let courseList = courseSelectedList.map((x) => x.id);
    let payload = {
      company_assignment_id: assign_id,
      company_assignment: { courses: courseList },
    };
    try {
      const response = await TaskServices.deleteCourseTaskManagement(payload);
      if (response) {
        setModalOpen(!modalOpen);
        setModalContentResponse({
          title: "Selamat!",
          subtitle:
            "Anda Telah Berhasil Menghapus Course Terpilih dari List Penugasan",
          icon: <Image src={successIcon} />,
          action: (
            <>
              <Button
                className="btn-action__confirm success"
                onClick={() => setModalOpen(false)}
              >
                Tutup
              </Button>
            </>
          ),
        });
        getCourseAssignee(assign_id);
        setCourseSelectedList([]);
      }
    } catch (err) {
      setModalOpen(!modalOpen);
      setModalContentResponse({
        title: "Menghapus Course Terpilih Gagal",
        subtitle: `Anda gagal untuk menghapus course terpilih silahkan coba ulangi lagi`,
        icon: <Image src={errorIcon} />,
        action: (
          <>
            <Button
              className="btn-action__confirm error"
              onClick={() => setModalOpen(false)}
            >
              Tutup
            </Button>
          </>
        ),
      });
    }
  };

  const deleteSelectedPerson = async () => {
    let personList = personSelectedList.map((x) => x.id);
    let payload = {
      company_assignment_id: assign_id,
      company_assignment: { assignees: personList },
    };
    try {
      const response = await TaskServices.deletePersonTaskManagement(payload);
      if (response) {
        getPersonAssignee(assign_id);
        setPersonSelectedList([]);
        setModalOpen(!modalOpen);
        setModalContentResponse({
          title: "Selamat!",
          subtitle: "Anda Telah Berhasil Menghapus Anggota Terpilih",
          icon: <Image src={successIcon} />,
          action: (
            <>
              <Button
                className="btn-action__confirm success"
                onClick={() => setModalOpen(false)}
              >
                Tutup
              </Button>
            </>
          ),
        });
      }
    } catch (err) {
      console.log(err);
      setModalOpen(!modalOpen);
      setModalContentResponse({
        title: "Menghapus Anggota Terpilih Gagal",
        subtitle: `Anda gagal untuk anggota terpilih dari penugasan silahkan coba ulangi lagi`,
        icon: <Image src={errorIcon} />,
        action: (
          <>
            <Button
              className="btn-action__confirm error"
              onClick={() => setModalOpen(false)}
            >
              Tutup
            </Button>
          </>
        ),
      });
    }
  };

  const handleEditInformasiPenugasan = () => {
    setShowEditInformasi(true);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Container>
        <h3 className="title__page-man mb-4">{detailData?.title}</h3>
        <Breadcrumb>
          <Breadcrumb.Item href="#">
            <img src={IconOverviewMan} alt="" className="icon__breadcrumb" />
          </Breadcrumb.Item>
          <Breadcrumb.Item className="breadcrumb__list-nav">
            Manajemen Penugasan
          </Breadcrumb.Item>{" "}
          <Breadcrumb.Item className="breadcrumb__list-nav">
            Penugasan Pelatihan
          </Breadcrumb.Item>
          <Breadcrumb.Item active className="breadcrumb__list-nav">
            {detailData?.title}
          </Breadcrumb.Item>
        </Breadcrumb>
        <Row>
          <Col md={12} lg={6} className="mb-4 mb-lg-0">
            <Card className=" _card-detail">
              <div className="d-flex justify-content-between">
                <div className="big-title_detail">{detailData?.title}</div>
                <div>
                  <span onClick={handleEditInformasiPenugasan} className="mr-2">
                    <img src={IconEdit} alt="" />
                  </span>
                </div>
              </div>
              <div
                className="d-flex flex-row align-items-center h-100"
                style={{ gap: "24px" }}
              >
                <div className="card-status_deadline _deadline">
                  <div className="border-status _deadline-border"></div>
                  <div>
                    <div className="detail-text">
                      Deadline{" "}
                      <span>({detailData?.remaining_days} Hari Lagi)</span>
                    </div>
                    <div className="info-text">
                      {moment(detailData?.deadline).format(
                        "DD MMM YYYY hh:mm:ss"
                      )}
                    </div>
                  </div>
                </div>
                <div className="author-info">
                  <div className="detail-text">Dibuat Oleh</div>
                  <div className="info-text">Admin - {detailData?.admin}</div>
                </div>
                <div className="border-separator_detail"></div>
                <div className="date-assignee">
                  <div className="detail-text">Tanggal</div>
                  <div className="info-text">
                    {moment(detailData?.created_at).format("DD MMM YYYY hh:mm")}
                  </div>
                </div>
              </div>
            </Card>
          </Col>
          <Col md={12} lg={6}>
            <Card className="d-flex flex-row h-100 _card-detail">
              <div>
                <div className="label-detail_penugasan">Berlangsung</div>
                <div className="title-detail_penugasan">
                  <span>{personList?.stats?.completed_members}</span> dari{" "}
                  <span>{personList?.stats?.total_members}</span> Anggota
                </div>
                <div className="desc-detail_penugasan">
                  Telah menyelesaikan semua pelatihan yang ditugaskan
                </div>
              </div>
              <div className="position-relative">
                {chartDataReady.length > 0 && (
                  <ReactECharts
                    style={{ height: "140px", width: "140px" }}
                    option={optionPenugasan}
                    theme={"light"}
                  />
                )}
              </div>
            </Card>
          </Col>
          <Col md={12} lg={12} className="mt-4">
            <Card className="p-3">
              <Row className="mt-3 mb-4">
                <Col
                  lg={6}
                  className="d-flex my-3 justify-content-between w-100"
                >
                  <Card className="p-3 w-100 card-outline_enabled">
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="d-flex align-items-center">
                        <div>
                          <h3 className="title__modal-pelatihan">
                            Daftar Pelatihan
                          </h3>
                          <span className="subtitle__modal-pelatihan">
                            {courseList?.pagination?.totalItems} pelatihan
                            ditugaskan
                          </span>
                        </div>
                      </div>
                      <div className="d-flex gap-3">
                        <Button
                          variant="danger"
                          disabled={disableCourse}
                          className="d-flex align-items-center btn__delete-penugasan mr-3"
                          onClick={deleteSelectedCourse}
                        >
                          Hapus
                        </Button>
                        <Button
                          variant="danger"
                          onClick={() => handleAdd("course")}
                          className="d-flex align-items-center btn__add-penugasan"
                        >
                          <span className="mr-2">
                            <img src={IconPlusWhite} alt="" />
                          </span>
                          Tambah
                        </Button>
                      </div>
                    </div>
                    <Table responsive striped hover>
                      <thead>
                        <tr>
                          <th>
                            <Form.Check
                              inline
                              name="all-course"
                              type="checkbox"
                              onClick={(e) =>
                                handleSelectAllData(e, "all-course")
                              }
                            />
                          </th>
                          <th className="pl-0">Judul Penugasan</th>
                          <th className="pl-0">Kategori</th>
                        </tr>
                      </thead>
                      <tbody>
                        {courseList?.items?.length > 0 ? (
                          courseList?.items?.map((data, index) => (
                            <tr key={index}>
                              <td>
                                <Form.Check
                                  inline
                                  name="group1"
                                  type="checkbox"
                                  onChange={(e) =>
                                    handleSelectData(e, "course")
                                  }
                                  className="_list-task"
                                  value={data.course_id}
                                  checked={
                                    typeActive !== "all-course"
                                      ? courseSelectedList.find(
                                          (x) => x.id == data.course_id
                                        )
                                      : selectCourseAll
                                  }
                                />
                              </td>
                              <td className="pl-0">
                                <Link to="#">{data.title}</Link>
                              </td>
                              <td className="pl-0">{data.categories}</td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan={3} className="text-center">
                              Data Belum Tersedia
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                    {/* {courseList?.items?.length > 0 && (
                      <PaginationTemplate
                        size={5}
                        totalRows={courseList?.pagination?.totalItems}
                        totalPages={courseList?.pagination?.totalPages}
                        paginationSelect={1}
                        active={1}
                      />
                    )} */}
                  </Card>
                </Col>
                <Col
                  lg={6}
                  className="d-flex my-3 justify-content-between w-100"
                >
                  <Card className="p-3 w-100 card-outline_enabled">
                    <div className="d-flex mb-3 justify-content-between align-items-center">
                      <div className="d-flex align-items-center">
                        <div>
                          <h3 className="title__modal-pelatihan">
                            Daftar Anggota
                          </h3>
                          <span className="subtitle__modal-pelatihan">
                            {personList?.items?.length} anggota ditugaskan
                          </span>
                        </div>
                      </div>
                      <div className="d-flex gap-3">
                        <Button
                          variant="danger"
                          disabled={disablePerson}
                          onClick={deleteSelectedPerson}
                          className="d-flex align-items-center btn__delete-penugasan mr-3"
                        >
                          Hapus
                        </Button>
                        <Button
                          variant="danger"
                          className="d-flex align-items-center btn__add-penugasan"
                          onClick={() => handleAdd("person")}
                        >
                          <span className="mr-2">
                            <img src={IconPlusWhite} alt="" />
                          </span>
                          Tambah
                        </Button>
                      </div>
                    </div>
                    <Table responsive striped hover>
                      <thead>
                        <tr>
                          <th>
                            <Form.Check
                              onChange={(e) =>
                                handleSelectAllData(e, "all-person")
                              }
                              inline
                              name="group1"
                              type="checkbox"
                            />
                          </th>
                          <th className="pl-0">Nama Anggota</th>
                          <th className="pl-0">Progress</th>
                        </tr>
                      </thead>
                      <tbody>
                        {personList?.items?.length > 0 ? (
                          personList?.items?.map((data, index) => (
                            <tr key={index}>
                              <td>
                                <Form.Check
                                  inline
                                  name="group1"
                                  type="checkbox"
                                  onChange={(e) =>
                                    handleSelectData(e, "person")
                                  }
                                  className="_list-task"
                                  value={data.user_id}
                                  // checked={selectAll}
                                />
                              </td>
                              <td className="pl-0">
                                <Link to="#">{data.name}</Link>
                              </td>
                              <td className="pl-0">
                                {data.completed}/{data.courses}
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan={4} className="text-center">
                              Data Belum Tersedia
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                    {/* {personList?.items?.length > 0 && (
                      <PaginationTemplate
                        size={5}
                        totalRows={personList?.pagination?.totalItems}
                        totalPages={personList?.pagination?.totalPages}
                        paginationSelect={1}
                        active={1}
                      />
                    )} */}
                  </Card>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Container>
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        scrollable={true}
        centered
        show={show}
        onHide={() => setShow(!show)}
      >
        <Modal.Header className="d-block">
          <h3 className="title__modal-pelatihan">Tambah Pelatihan</h3>
          <span className="subtitle__modal-pelatihan">
            Pilih Pelatihan yang akan Anda tugaskan
          </span>
        </Modal.Header>
        <Modal.Body>{handleShowModalInternal()}</Modal.Body>
        <Modal.Footer>
          <button className="btn btn__back-show" onClick={() => setShow(!show)}>
            Kembali
          </button>
          <button onClick={courseAssign} className="btn btn__cotinue-show">
            Simpan
          </button>
        </Modal.Footer>
      </Modal>
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        scrollable={true}
        centered
        show={showEditInformasi}
        onHide={() => setShowEditInformasi(!showEditInformasi)}
      >
        <Modal.Header className="d-block">
          <h3 className="title__modal-pelatihan">Ubah Informasi Penugasan</h3>
          <span className="subtitle__modal-pelatihan">
            Tentukan nama penugasan dan waktu deadline
          </span>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col lg={12} className="mb-4">
              <h3
                className="title__modal-show text-left"
                style={{ color: "#4F4F4F" }}
              >
                Nama Penugasan
              </h3>
              <Form.Control
                placeholder="Masukan Nama Penugasan"
                aria-label="Username"
                aria-describedby="basic-addon1"
                id="title"
                value={infoTask.title}
                onChange={handleChange}
                className="input__search-pelatihan w-100"
              />
            </Col>
            <Col lg={12}>
              <h3
                className="title__modal-show text-left"
                style={{ color: "#4F4F4F" }}
              >
                Waktu Deadline
              </h3>
              <Form.Control
                placeholder="Cari"
                aria-label="Username"
                type="datetime-local"
                value={infoTask.deadline}
                // onClick={showDeadlinePicker}
                onChange={handleChange}
                id="deadline"
                // value={
                //   dateAssignee ? moment(dateAssignee).format("YYYY-MM-DD") : ""
                // }
                aria-describedby="basic-addon1"
                className="input__search-pelatihan w-100"
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn__back-show"
            onClick={() => setShowEditInformasi(!showEditInformasi)}
          >
            Kembali
          </button>
          <button
            onClick={editInformasiPenugasan}
            className="btn btn__cotinue-show"
          >
            Simpan
          </button>
        </Modal.Footer>
      </Modal>
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        scrollable={true}
        centered
        show={showPerson}
        onHide={() => setShowPerson(!showPerson)}
      >
        <Modal.Header className="d-block">
          <h3 className="title__modal-pelatihan">Tambah Anggota</h3>
          <span className="subtitle__modal-pelatihan">
            Pilih Anggota yang akan Anda tugaskan
          </span>
        </Modal.Header>
        <Modal.Body>{handleShowModalPerson()}</Modal.Body>
        <Modal.Footer>
          <button className="btn btn__back-show" onClick={() => setShow(!show)}>
            Kembali
          </button>
          <button onClick={personAssign} className="btn btn__cotinue-show">
            Simpan
          </button>
        </Modal.Footer>
      </Modal>
      <ResponseModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        modalContent={modalContent()}
      />
    </LocalizationProvider>
  );
}

export default ManagementPenugasanDetail;
