import React from "react";
import { Card } from "react-bootstrap";
import "../assets/css/Courses/CardPrice.css";

function InfoCard({ item }) {
  return (
    <Card className="course_front__price_card__container">
      <div className="price_card__level">{item?.level}</div>
      <div className="price_card__price">{item?.price}</div>

      {/* <div className="price_card__status">Status : Lorem ipsum</div>
      <div className="price_card__status rating">
        Rating : <span className="font-weight-bold">4,3</span>{" "}
      </div> */}

      {/* <div className="price_card__check">
        <FaCheck className="mr-3 fill-white" />
        Lorem ipsum dolor sit amet, consectetur adipiscing
      </div>
      <div className="price_card__check">
        <FaCheck className="mr-3 fill-white" /> Lorem ipsum dolor sit amet,
        consectetur adipiscing
      </div> */}

      {/* <Button block className="price_card__btn_primary">
        Mulai Pelatihan
      </Button>
      <Button block className="price_card__btn_disabled" disabled>
        Selesai Pelatihan
      </Button> */}
    </Card>
  );
}

export default InfoCard;
