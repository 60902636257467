import React from "react";
import { FaCopyright } from "react-icons/fa";
import Header from "./Header";
import "../../assets/css/Layout/main.css";

const Main = ({
  collapsed,
  rtl,
  image,
  isSidebar,
  handleToggleSidebar,
  handleCollapsedChange,
  handleRtlChange,
  handleImageChange,
  InnerComponent,
}) => {
  return (
    <main>
      {/* <div className="btn-toggle" onClick={() => handleToggleSidebar(true)}>
        <FaBars />
      </div> */}
      <header>
        <Header
          collapsed={collapsed}
          isSidebar={isSidebar}
          handleToggleSidebar={handleToggleSidebar}
          handleCollapsedChange={handleCollapsedChange}
        />
      </header>
      <InnerComponent />
      <footer>
        <div className="satukelas_copyright">
          satukelas{" "}
          <span className="black">
            <FaCopyright /> 2021{" "}
          </span>
          powered by -{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://siapkonek.com"
          >
            Koneksi Group
          </a>
        </div>
      </footer>
    </main>
  );
};

export default Main;
