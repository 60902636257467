import React from "react";
import { Card, Row, Col } from "react-bootstrap";
import "../assets/css/Dashboard/companyAnalytics.css";
function TopBannerAnalytics({ data, adminBnsp }) {
  return (
    <>
      <Card className="banner_card">
        <div className="card_inside__padding ">
          <Row>
            {/* <Col xl={3} lg={2} className="text-center">
                <img src={dataAdmin?.avatar} alt="profile-photo" />
              </Col> */}
            <Col xl={12} lg={12} className="pl-lg-0">
              <Row>
                <Col xl={8} lg={8}>
                  <div className="text-left text-white mt-xl-4">
                    <h3 className="font-weight-semibold">Halo {data?.name}</h3>
                    <h4 className="font-weight-normal">
                      Saldo anda Rp. {data?.balance}
                    </h4>
                    <p className="mb-lg-0 text-white-50">
                      kesimpulan dari semua aktifitas belajar{" "}
                      {adminBnsp && adminBnsp?.usersDataId?.is_bnsp === 1
                        ? "partisipan"
                        : "karyawan"}{" "}
                      anda ada disini, anda juga bisa langsung menekan tombol
                      dikanan atas untuk menambahkan user, report cepat, dll
                      atau klik tombol disamping untuk rinciannya
                    </p>
                  </div>
                </Col>
                <Col xl={4} lg={4} className="text-right mt-xl-4">
                  <h5 className="font-weight-semibold mb-1 text-white">
                    Anda adalah admin dari
                  </h5>
                  <h1 className="mb-4 fw-800 text-white">
                    {data?.company_name}
                  </h1>
                  <div className="btn-list mb-xl-0"></div>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </Card>
    </>
  );
}

export default TopBannerAnalytics;
