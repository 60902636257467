import React, { useState, useEffect } from "react";
import { postAssetsDataThunk } from "../redux/thunks/AssetsThunks";
import { removeAssetsDataPost } from "../redux/actions/AssetsActions";
import { useDispatch, useSelector } from "react-redux";
import { Button, Form, Row, Col } from "react-bootstrap";
import "../assets/css/Account/fileuploader.css";
import { Capitalize } from "../helper/functions";
import Dropzone from "react-dropzone";

function FileUploader({ handleClose }) {
  const [fileUpload, setFileUpload] = useState();
  const [isSuccess, setIsSuccess] = useState(true);
  const [bucket, setBucket] = useState("lessons");
  const [payload, setPayload] = useState({ url: "?bucket=lessons", body: "" });

  const dispatch = useDispatch();
  const assets = useSelector((state) => state.assets);
  const assetsDataPost = assets.assetsDataPost;
  const formData = new FormData();

  function handleChange(e) {
    let obj = payload;
    obj.url = `?bucket=${e.target.value}`;
    setPayload(obj);
    setBucket(e.target.value);
  }

  function handleUpload() {
    if (payload.body != "" && payload.url != "")
      dispatch(postAssetsDataThunk(payload));
  }

  useEffect(() => {
    if (assetsDataPost?.status === 201) {
      dispatch(removeAssetsDataPost());
      handleClose();
      setIsSuccess(true);
    } else {
      if (!assetsDataPost?.error?.response) setIsSuccess(false);
    }
  }, [assetsDataPost]);

  function handleDropzone(files) {
    setFileUpload(files[0]);
    let obj = payload;
    formData.append("upfile", files[0]);
    obj.body = formData;

    setPayload(obj);
  }
  // const props = {
  //   action: (file) => {
  //     return new Promise((resolve) => {
  //       setFileInfo(file);
  //       let obj = payload;
  //       formData.append("upfile", file);
  //       obj.body = formData;
  //       setPayload(obj);
  //     });
  //   },
  //   multiple: false,
  //   onStart(file) {
  //     console.log("onStart", file.name);
  //   },
  //   onSuccess(ret) {
  //     console.log("onSuccess", ret);
  //   },
  //   onError(err) {
  //     console.log("onError", err);
  //   },
  //   beforeUpload(item, all) {
  //     setAllItems(all);
  //   },
  // };
  
  return (
    <Row className="height-inherit justify-content-center align-content-center">
      <Col xs={12} sm={12} md={12} lg={12}>
        {/* {!isSuccess ? (
          <div className="text-center">
            <p style={{ color: "red" }}>gagal mengunggah file</p>
          </div>
        ) : null} */}
        {/* <Card className="card_container py-5">
          <div className="card_inside__padding"> */}
        {/* <div className="title_upload">Unggah Berkas Anda</div> */}
        <div className="my-4">
          <Dropzone onDrop={handleDropzone}>
            {({ getRootProps, getInputProps }) => (
              <div className="container dropzone-style">
                <div
                  {...getRootProps({
                    className: "dropzone",
                  })}
                >
                  <input {...getInputProps()} />
                  <p>Drag 'n' drop some files here, or click to select files</p>
                </div>
              </div>
            )}
          </Dropzone>
          <div>{fileUpload !== undefined ? fileUpload?.path : null}</div>
        </div>
        {/* <div className="subtitle_upload">Pilih File untuk di unggah</div> */}
        {/* <Upload {...props} style={{ width: "100%" }}>
          <Button className="file-upload-btn py-3 mt-5 mb-4 btn-primary">
            {fileInfo
              ? allItems.length > 1
                ? `${allItems.length} berkas terpilih`
                : fileInfo.name
              : "Pilih Berkas Kamu"}
          </Button>
        </Upload> */}

        <Form>
          <div className="d-flex justify-content-between">
            <Form.Group className="d-flex align-items-center">
              <div className="mb-0 w-100">Pilih Folder</div>
              <Form.Control
                required
                as="select"
                type="select"
                name="bucket"
                id="bucket"
                value={bucket}
                onChange={handleChange}
              >
                <option hidden value key="blankChoice">
                  Pilih Bucket...
                </option>
                {[
                  "lessons",
                  "thumbnails",
                  "assignments",
                  "certificates",
                  "corporate_materials",
                  "profile_pictures",
                  "jamboards",
                ].map((item, index) => {
                  return (
                    <option key={index} value={item}>
                      {Capitalize(item.replace(/_/g, " "))}
                    </option>
                  );
                })}
              </Form.Control>
            </Form.Group>
            <div className="d-flex justify-content-end">
              <Button
                variant="success"
                className="file-upload-btn my-2 px-3"
                onClick={handleUpload}
              >
                Unggah
              </Button>
            </div>
          </div>
        </Form>
        {/* </div>
        </Card> */}
      </Col>
    </Row>
  );
}
export default FileUploader;
