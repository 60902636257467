import React, { useState, useEffect } from "react";
import { Button, Form, Card } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { postQuizThemeDataThunk } from "../redux/thunks/QuizThemeThunks";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
const MySwal = withReactContent(Swal);

function AddQuizTheme() {
  const [data, setData] = useState({
    title: "",
    topic_id: "",
  });

  const [trigger, setTrigger] = useState(false);
  const [valueType, setValueType] = useState([]);
  const handleChange = (e) => {
    const newData = { ...data };
    newData[e.target.id] = e.target.value;
    setData(newData);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
  };
  const quiz_themes = useSelector((state) => state.quiz_themes);
  const dispatch = useDispatch();
  useEffect(() => {
    if (quiz_themes.quizThemeDataPost === "error" && trigger) {
      setTrigger(false);
      displayPopup(false, "Terjadi masalah saat submit data", "Ok");
    }
  }, [quiz_themes]);

  function uploadData() {
    if (checkIfEmpty(data)) {
      displayPopup(false, "Mohon diisi semua", "Ok");
    } else {
      setTrigger(true);
      dispatch(postQuizThemeDataThunk(data));
    }
  }

  function displayPopup(cancel, title, confirm) {
    MySwal.fire({
      title: title,
      showCancelButton: cancel,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: confirm,
    }).then((value) => {
      console.log(value, "value");
    });
  }
  return (
    <Card className="card_container mb-5">
      <div className="card_inside__padding ">
        <Form onSubmit={handleSubmit}>
          <Form.Group>
            <Form.Label className="title_label__input">ID Topik</Form.Label>
            <Form.Control
              onChange={handleChange}
              className="mb-3"
              type="text"
              id="topic_id"
              value={data.topic_id}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label className="title_label__input">Judul</Form.Label>
            <Form.Control
              onChange={handleChange}
              className="mb-3"
              type="text"
              id="title"
              value={data.title}
            />
          </Form.Group>
          <div className="d-flex justify-content-end">
            <Button
              type="submit"
              variant="light"
              className="my-4 mx-2 px-3 py-2"
            >
              Batal
            </Button>
            <Button
              type="submit"
              onClick={uploadData}
              className="my-4 mx-2 px-3 py-2"
            >
              {" "}
              + Tambahkan
            </Button>
          </div>
        </Form>
      </div>
    </Card>
  );
}

export default AddQuizTheme;
function checkIfEmpty(data) {
  return !data.topic_id.length > 0 || !data.title.length > 0;
}
