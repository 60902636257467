import { PromiseService } from "../../services/PromiseService";
import { CATEGORIES_DATA_LINK } from "../config/CategoriesConfig";
import {
  //get
  getCategoriesData,
  getCategoriesDataSuccess,
  getCategoriesDataIdSuccess,
  getCategoriesDataFail,
  //post
  postCategoriesData,
  postCategoriesDataSuccess,
  postCategoriesDataFail,
  // put
  putCategoriesData,
  putCategoriesDataSuccess,
  putCategoriesDataFail,
  // delete
  deleteCategoriesData,
  deleteCategoriesDataSuccess,
  deleteCategoriesDataFail,
} from "../actions/CategoriesActions";

// get
export const getCategoriesDataThunk = (data) => async (dispatch) => {
  dispatch(getCategoriesData(data));
  if (data) {
    let url = `${CATEGORIES_DATA_LINK}/${data}`;
    if (data.param) url = `${CATEGORIES_DATA_LINK}${data.param}`;
    try {
      const response = await PromiseService.getData(url);
      if (response.status === 200 && response.data) {
        if (data.param) dispatch(getCategoriesDataSuccess(response.data));
        else dispatch(getCategoriesDataIdSuccess(response.data));
      } else {
        dispatch(getCategoriesDataFail(response));
      }
    } catch (error) {
      dispatch(getCategoriesDataFail(error));
    }
  } else {
    try {
      const response = await PromiseService.getData(`${CATEGORIES_DATA_LINK}?size=100`);
      if (response.status === 200 && response.data) {
        dispatch(getCategoriesDataSuccess(response.data));
      } else {
        dispatch(getCategoriesDataFail(response));
      }
    } catch (error) {
      dispatch(getCategoriesDataFail(error));
    }
  }
};

// post
export const postCategoriesDataThunk = (data) => async (dispatch) => {
  dispatch(postCategoriesData(data));
  const body = data;
  try {
    const response = await PromiseService.postData(CATEGORIES_DATA_LINK, body);
    if (response.status === 201 && response.data) {
      dispatch(postCategoriesDataSuccess(response.data));
    } else {
      dispatch(postCategoriesDataFail(response));
    }
  } catch (error) {
    dispatch(postCategoriesDataFail(error));
  }
};

// put
export const putCategoriesDataThunk = (data) => async (dispatch) => {
  dispatch(putCategoriesData(data));
  const body = data.body;
  const id = data.id;
  try {
    const response = await PromiseService.putData(
      `${CATEGORIES_DATA_LINK}/${id}`,
      body
    );
    if (response.status === 201 && response.data) {
      dispatch(putCategoriesDataSuccess(response.data));
    } else {
      dispatch(putCategoriesDataFail(response));
    }
  } catch (error) {
    dispatch(putCategoriesDataFail(error));
  }
};

// delete
export const deleteCategoriesDataThunk = (data) => async (dispatch) => {
  dispatch(deleteCategoriesData(data));
  const id = data;
  try {
    const response = await PromiseService.deleteData(
      `${CATEGORIES_DATA_LINK}/${id}`
    );
    if (response.status === 200 && response.data) {
      dispatch(deleteCategoriesDataSuccess(response.data));
    } else {
      dispatch(deleteCategoriesDataFail(response));
    }
  } catch (error) {
    dispatch(deleteCategoriesDataFail(error));
  }
};
