export const INSTRUCTORS_DATA_LINK = "users/instructors";

//get
export const INSTRUCTORS_DATA = "INSTRUCTORS_DATA";
export const INSTRUCTORS_DATA_SUCCESS = "INSTRUCTORS_DATA_SUCCESS";
export const INSTRUCTORS_DATA_ID_SUCCESS = "INSTRUCTORS_DATA_ID_SUCCESS";
export const INSTRUCTORS_DATA_FAIL = "INSTRUCTORS_DATA_FAIL";

//live search
export const INSTRUCTORS_LIVE_SEARCH_DATA = "INSTRUCTORS_LIVE_SEARCH_DATA";
export const INSTRUCTORS_LIVE_SEARCH_DATA_SUCCESS = "INSTRUCTORS_LIVE_SEARCH_DATA_SUCCESS";
export const INSTRUCTORS_LIVE_SEARCH_DATA_FAIL = "INSTRUCTORS_LIVE_SEARCH_DATA_FAIL";


//remove
export const REMOVE_INSTRUCTORS_DATA = "REMOVE_INSTRUCTORS_DATA";
