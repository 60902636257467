import React, { useEffect, useState } from "react";
import InfoCourse from "../../components/AnalyticsCompany/InfoCourse";
import Home from "../../assets/images/breadcrumb.svg";
import { Breadcrumb, Col, Container, Image, Row } from "react-bootstrap";
import "../../assets/css/main.css";
import { AnalyticLearningService } from "../../http/AnalyticLearningHttp";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import StudyGroupTab from "../../components/AnalyticsCompany/StudyGroupTab";
import StatusCertificate from "../../components/AnalyticsCompany/StatusCertificate";
import BadgeBlue from "./../../assets/images/badge-blue.svg";
import IconInfoGray from "./../../assets/images/icon-info-gray.svg";
import ListUserDetail from "../../components/AnalyticsCompany/ListUserDetail";
import { convertDate } from "../../helper/functions";

export default function AnalyticCttCptt() {
  const [typeTab, setTypeTab] = useState("CTT");
  const [activePageAnggota, setActivePageAnggota] = useState("all");
  const [dataCourse, setDataCourse] = useState([]);
  const [keywordCourse, setKeywordCourse] = useState("");
  const [size, setSize] = useState(10);
  const [activeCourse, setActiveCourse] = useState(1);
  const [memberList, setMemberList] = useState(null);
  const [requirementCertif, setRequirementCertif] = useState(null);
  const [dataDetailCertif, setDataDetailCertif] = useState([]);
  const [triangle, setTriangle] = React.useState(false);
  const users = useSelector((state) => state.users);
  const usersDataId = users.usersDataId;
  const companyId = usersDataId?.company_id;
  const { course_id } = useParams();

  useEffect(() => {
    if (usersDataId?.company_id) {
      let url = window.location.href;
      let currentType = url.split("type")[1].split("=")[1];
      getAnalyticCttCptt(companyId, course_id, "");

      if (currentType === "brevet-ab") {
        getDetailCTTCPTT(companyId, "CTT");
        getRequirementATPI(`/${companyId}?certificate_name=CTT`);
        setTypeTab("CTT");
      } else {
        getDetailCTTCPTT(companyId, "CPTT");
        getRequirementATPI(`/${companyId}?certificate_name=CPTT`);
        setTypeTab("CPTT");
      }
    }
  }, [usersDataId]);

  // useEffect(() => {
  //   if (usersDataId?.company_id) {
  //     getAnalyticCttCptt(companyId, course_id, "");
  //     getDetailCTTCPTT(companyId, "CTT");
  //     getRequirementATPI(`/${companyId}?certificate_name=${typeTab}`);
  //   }
  // }, [usersDataId]);

  useEffect(() => {
    if (usersDataId?.company_id) {
      getListMemberCTTCPTT(
        `${companyId}?size=${size}&page=${activeCourse}&keyword=${keywordCourse}&type=${typeTab}`
      );
    }
  }, [usersDataId, size]);

  const changeTab = (type) => {
    getDetailCTTCPTT(companyId, type);
    getRequirementATPI(`/${companyId}?certificate_name=${type}`);
    getListMemberCTTCPTT(
      `${companyId}?size=${size}&page=${activeCourse}&keyword=${keywordCourse}&type=${type}`
    );
    setTypeTab(type);
  };

  const getDetailCTTCPTT = async (company_id, type) => {
    try {
      const response = await AnalyticLearningService.getATPIDetail(
        company_id,
        type
      );
      if (response) setDataDetailCertif(response);
    } catch (err) {
      console.log(err);
    }
  };

  const getRequirementATPI = async (param) => {
    try {
      const response = await AnalyticLearningService.getATPIRequirement(param);
      if (response) setRequirementCertif(response);
    } catch (err) {
      console.log(err);
    }
  };

  const getAnalyticCttCptt = async (company_id, course_id, param) => {
    try {
      const response = await AnalyticLearningService.getAnalyticCertification(
        company_id,
        course_id,
        param ? param : ""
      );
      if (response) setDataCourse(response);
    } catch (err) {
      console.log(err);
    }
  };

  const getListMemberCTTCPTT = async (param) => {
    try {
      const response = await AnalyticLearningService.getATPIListMember(
        `/${param}`
      );
      if (response) setMemberList(response);
    } catch (err) {
      console.log(err);
    }
  };

  const searchData = (e) => {
    e.preventDefault();
    getListMemberCTTCPTT(
      `${companyId}?size=${size}&page=${activeCourse}&keyword=${keywordCourse}&type=${typeTab}`
    );
  };

  const paginationCTTCPTT = (newActive) => {
    setActiveCourse(newActive);
    getListMemberCTTCPTT(
      `${companyId}?size=${size}&page=${newActive}&keyword=${keywordCourse}&type=${typeTab}`
    );
  };

  return (
    <Container fluid>
      <h3 className="title__page-man mb-4">{typeTab.toUpperCase()}</h3>
      <Breadcrumb>
        <Breadcrumb.Item href="#">
          <img src={Home} alt="home" />
        </Breadcrumb.Item>
        <Breadcrumb.Item className="breadcrumb__list-nav">
          Data & Analitik Pembelajaran
        </Breadcrumb.Item>
        <Breadcrumb.Item active className="breadcrumb__list-nav">
          {typeTab.toUpperCase()}
        </Breadcrumb.Item>
      </Breadcrumb>
      <Row>
        <Col lg={12} className="wrapp__ctt-cptt">
          <Col
            lg={12}
            className="d-flex flex-row justify-content-between bg-white"
          >
            <Col
              lg={6}
              role="button"
              onClick={() => changeTab("CTT")}
              className={`pt-4`}
            >
              <p
                className={`text__type-ctt-cpt ${
                  typeTab === "CTT" && "active"
                }`}
              >
                CTT (Certified Tax Technician)
              </p>
              <div
                className={`w-100 ${typeTab === "CTT" && "border__active"}`}
              ></div>
            </Col>
            <Col
              lg={6}
              role="button"
              onClick={() => changeTab("CPTT")}
              className={`pt-4`}
            >
              <p
                className={`text__type-ctt-cpt ${
                  typeTab === "CPTT" && "active"
                }`}
              >
                CPTT (Certified Professional Tax Technician)
              </p>
              <div
                className={`w-100 ${typeTab === "CPTT" && "border__active"}`}
              ></div>
            </Col>
          </Col>
        </Col>
        <Col lg={12}>
          <InfoCourse data={dataDetailCertif} />
        </Col>
        <Col lg={12} className="card-analitik">
          <div className="d-flex align-items-center mb-3">
            <StudyGroupTab
              tab={activePageAnggota}
              setTab={setActivePageAnggota}
              totalItems={""}
              data={""}
              endpoint={getAnalyticCttCptt}
              setShow={""}
              setUserDataModal={""}
              type="course"
            />
          </div>
          <div className="d-flex">
            <Col lg={6}>
              <div className="card-analitik _shadow">
                <div
                  className="total-anggota_title d-flex"
                  style={{ borderBottom: "1px solid #D9D9D9" }}
                >
                  <p>Anggota Memenuhi Prasyarat</p>
                  <div
                    className="position-relative"
                    onMouseEnter={() => setTriangle(true)}
                    onMouseLeave={() => setTriangle(false)}
                    role="button"
                  >
                    {triangle && (
                      <>
                        <span
                          className="position-absolute info__status"
                          style={{ left: "105px" }}
                        >
                          Nilai tidak terpenuhi
                        </span>
                        <div
                          class="arrow-up__hover position-absolute"
                          style={{ left: "175px" }}
                        ></div>
                      </>
                    )}
                    <span
                      className="position-relative"
                      style={{ left: "175px" }}
                    >
                      <Image src={IconInfoGray} width={12} height={12} />
                    </span>
                  </div>
                </div>
                <div className="d-flex" style={{ gap: "16px" }}>
                  <div>
                    <div className="certification-number _orange">
                      {requirementCertif?.qualified}
                    </div>
                  </div>
                  <div>
                    <div className="certification-number _blue">
                      dari {requirementCertif?.total_member}
                    </div>
                  </div>
                </div>
                <div className="mt-4">
                  <span className="certification__desc">
                    Anggota memenuhi Prasyarat untuk klaim Sertifikasi{" "}
                    <b>
                      {typeTab === "CTT"
                        ? "CTT (Certified Tax Technician)"
                        : "CPTT (Certified Professional Tax Technician)"}
                    </b>
                  </span>
                </div>
                <div
                  className="position-absolute"
                  style={{ top: "0", right: "0" }}
                >
                  <Image src={BadgeBlue} width={120} height={120} />
                </div>
              </div>
            </Col>
            <Col lg={6}>
              <StatusCertificate
                data={dataDetailCertif}
                typeStatus={typeTab}
                legendTwoColumn={true}
              />
            </Col>
          </div>
          <Col lg={12}>
            <ListUserDetail
              data={memberList?.items}
              type={"cttcptt"}
              paginationSelect={paginationCTTCPTT}
              size={size}
              setSize={setSize}
              currentPage={activeCourse}
              totalRows={memberList?.pagination?.totalItems}
              totalPages={memberList?.pagination?.totalPages}
              convertDate={convertDate}
              setKeywordCourse={setKeywordCourse}
              searchData={searchData}
            />
          </Col>
        </Col>
      </Row>
    </Container>
  );
}
