import Axios from "axios";
import { ENDPOINT } from "../helper/constant";
import { AuthData } from "../helper/auth";

const timeout = 60000;
const timeoutImg = 300000;

export const HTTP_SERVICE = {
  get(url, request) {
    return new Promise(async (resolve, reject) => {
      Axios(ENDPOINT + url, {
        method: "get",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Basic " + AuthData().authorization,
        },
        timeout,
      }).then(
        (result) => {
          resolve(result);
        },
        function (error) {
          // console.log("error HTTP SERVICE");
          reject(error);
        }
      );
    });
  },
  post(url, request, authType) {
    return new Promise(async (resolve, reject) => {
      let authorization = "";
      if (authType) {
        authorization = Buffer.from(
          `${request.username}:${request.password}`
        ).toString("base64");
      } else {
        authorization = AuthData().authorization;
      }

      //token
      Axios(ENDPOINT + url, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Basic " + authorization,
        },
        data: JSON.stringify(request),
        timeout,
      }).then(
        (result) => {
          resolve(result);
        },
        function (error) {
          // console.log("error HTTP SERVICE");
          reject(error);
        }
      );
    });
  },
  postFormData(url, request) {
    return new Promise(async (resolve, reject) => {
      //token
      Axios(ENDPOINT + url, {
        method: "post",
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Basic " + AuthData().authorization,
        },
        data: request,
        timeoutImg,
      }).then(
        (result) => {
          resolve(result);
        },
        function (error) {
          // console.log("error HTTP SERVICE");
          reject(error);
        }
      );
    });
  },

  delete(url, request) {
    return new Promise(async (resolve, reject) => {
      //token
      Axios(ENDPOINT + url, {
        method: "delete",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Basic " + AuthData().authorization,
        },
        timeout,
      }).then(
        (result) => {
          resolve(result);
        },
        function (error) {
          // console.log("error HTTP SERVICE");
          reject(error);
        }
      );
    });
  },

  put(url, request) {
    return new Promise(async (resolve, reject) => {
      //token
      Axios(ENDPOINT + url, {
        method: "put",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Basic " + AuthData().authorization,
        },
        data: JSON.stringify(request),
        timeout,
      }).then(
        (result) => {
          resolve(result);
        },
        function (error) {
          // console.log("error HTTP SERVICE");
          reject(error);
        }
      );
    });
  },
};
