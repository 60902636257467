import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import FileUploader from "../../components/FileUploader";
import { AuthIsLogin } from "../../helper/auth";
import { useHistory } from "react-router-dom";

function UploadLibrary() {
  const history = useHistory();
  useEffect(() => {
    AuthIsLogin(history);
  }, []);
  return (
    <Container className="h-100">
      <FileUploader />
    </Container>
  );
}

export default UploadLibrary;
