import { PromiseService } from "../../services/PromiseService";
import { SUB_INDUSTRIES_DATA_LINK } from "../config/SubIndustriesConfig";
import {
  //get
  getSubIndustriesData,
  getSubIndustriesDataSuccess,
  getSubIndustriesDataIdSuccess,
  getSubIndustriesDataFail,
  //post
  postSubIndustriesData,
  postSubIndustriesDataSuccess,
  postSubIndustriesDataFail,
  // put
  putSubIndustriesData,
  putSubIndustriesDataSuccess,
  putSubIndustriesDataFail,
  // delete
  deleteSubIndustriesData,
  deleteSubIndustriesDataSuccess,
  deleteSubIndustriesDataFail,
} from "../actions/SubIndustriesActions";

// get
export const getSubIndustriesDataThunk = (data) => async (dispatch) => {
  dispatch(getSubIndustriesData(data));
  if (data) {
    let url = `${SUB_INDUSTRIES_DATA_LINK}/${data}`;
    if (data.param) url = `${SUB_INDUSTRIES_DATA_LINK}${data.param}`;
    try {
      const response = await PromiseService.getData(url);
      if (response.status === 200 && response.data) {
        if (data.param) dispatch(getSubIndustriesDataSuccess(response.data));
        else dispatch(getSubIndustriesDataIdSuccess(response.data));
      } else {
        dispatch(getSubIndustriesDataFail(response));
      }
    } catch (error) {
      dispatch(getSubIndustriesDataFail(error));
    }
  } else {
    try {
      const response = await PromiseService.getData(`${SUB_INDUSTRIES_DATA_LINK}?size=100`);
      if (response.status === 200 && response.data) {
        dispatch(getSubIndustriesDataSuccess(response.data));
      } else {
        dispatch(getSubIndustriesDataFail(response));
      }
    } catch (error) {
      dispatch(getSubIndustriesDataFail(error));
    }
  }
};

// post
export const postSubIndustriesDataThunk = (data) => async (dispatch) => {
  dispatch(postSubIndustriesData(data));
  const body = data;
  try {
    const response = await PromiseService.postData(SUB_INDUSTRIES_DATA_LINK, body);
    if (response.status === 201 && response.data) {
      dispatch(postSubIndustriesDataSuccess(response.data));
    } else {
      dispatch(postSubIndustriesDataFail(response));
    }
  } catch (error) {
    dispatch(postSubIndustriesDataFail(error));
  }
};

// put
export const putSubIndustriesDataThunk = (data) => async (dispatch) => {
  dispatch(putSubIndustriesData(data));
  const body = data.body;
  const id = data.id;
  try {
    const response = await PromiseService.putData(
      `${SUB_INDUSTRIES_DATA_LINK}/${id}`,
      body
    );
    if (response.status === 201 && response.data) {
      dispatch(putSubIndustriesDataSuccess(response.data));
    } else {
      dispatch(putSubIndustriesDataFail(response));
    }
  } catch (error) {
    dispatch(putSubIndustriesDataFail(error));
  }
};

// delete
export const deleteSubIndustriesDataThunk = (data) => async (dispatch) => {
  dispatch(deleteSubIndustriesData(data));
  const id = data;
  try {
    const response = await PromiseService.deleteData(
      `${SUB_INDUSTRIES_DATA_LINK}/${id}`
    );
    if (response.status === 200 && response.data) {
      dispatch(deleteSubIndustriesDataSuccess(response.data));
    } else {
      dispatch(deleteSubIndustriesDataFail(response));
    }
  } catch (error) {
    dispatch(deleteSubIndustriesDataFail(error));
  }
};
