import {
  // get
  LESSON_DATA,
  LESSON_DATA_SUCCESS,
  LESSON_DATA_ID_SUCCESS,
  // post
  LESSON_DATA_POST,
  LESSON_DATA_POST_SUCCESS,
  LESSON_DATA_POST_FAIL,
  // put
  LESSON_DATA_PUT,
  LESSON_DATA_PUT_SUCCESS,
  LESSON_DATA_PUT_FAIL,
  // delete
  LESSON_DATA_DELETE,
  LESSON_DATA_DELETE_SUCCESS,
  LESSON_DATA_DELETE_FAIL,
  // fail
  LESSON_DATA_FAIL,
} from "../config/LessonConfig";

// get
export const getLessonData = (value) => ({
  type: LESSON_DATA,
  value,
});
export const getLessonDataSuccess = (value) => ({
  type: LESSON_DATA_SUCCESS,
  value,
});
export const getLessonDataIdSuccess = (value) => ({
  type: LESSON_DATA_ID_SUCCESS,
  value,
});
export const getLessonDataFail = (value) => ({
  type: LESSON_DATA_FAIL,
  value,
});

// post
export const postLessonData = (value) => ({
  type: LESSON_DATA_POST,
  value,
});
export const postLessonDataSuccess = (value) => ({
  type: LESSON_DATA_POST_SUCCESS,
  value,
});
export const postLessonDataFail = (value) => ({
  type: LESSON_DATA_POST_FAIL,
  value,
});

// put
export const putLessonData = (value) => ({
  type: LESSON_DATA_PUT,
  value,
});
export const putLessonDataSuccess = (value) => ({
  type: LESSON_DATA_PUT_SUCCESS,
  value,
});
export const putLessonDataFail = (value) => ({
  type: LESSON_DATA_PUT_FAIL,
  value,
});

// delete
export const deleteLessonData = (value) => ({
  type: LESSON_DATA_DELETE,
  value,
});
export const deleteLessonDataSuccess = (value) => ({
  type: LESSON_DATA_DELETE_SUCCESS,
  value,
});
export const deleteLessonDataFail = (value) => ({
  type: LESSON_DATA_DELETE_FAIL,
  value,
});

// fail handle
