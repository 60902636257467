import React, { useEffect } from "react";
import { Row, Col, Container } from "react-bootstrap";
import TableLesson from "../../components/TableLesson";
import { AuthIsLogin } from "../../helper/auth";
import { useHistory } from "react-router-dom";
import "../../assets/css/Dashboard/index.css";
function LessonTable() {
  const history = useHistory();
  useEffect(() => {
    AuthIsLogin(history);
  }, []);
  return (
    <Container fluid>
      <Row className="justify-content-center align-items-center height-inherit">
        <Col xs={12} sm={12} md={12} lg={12}>
          <TableLesson />
        </Col>
      </Row>
    </Container>
  );
}

export default LessonTable;
