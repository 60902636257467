import {
  // get
  QUIZ_THEME_DATA,
  QUIZ_THEME_DATA_SUCCESS,
  QUIZ_THEME_DATA_ID_SUCCESS,
  //live search
  QUIZ_THEME_LIVE_SEARCH_DATA,
  QUIZ_THEME_LIVE_SEARCH_DATA_SUCCESS,
  QUIZ_THEME_LIVE_SEARCH_DATA_FAIL,
  // post
  QUIZ_THEME_DATA_POST,
  QUIZ_THEME_DATA_POST_SUCCESS,
  QUIZ_THEME_DATA_POST_FAIL,
  // fail
  QUIZ_THEME_DATA_FAIL,
  //remove
  QUIZ_THEME_LIVE_SEARCH_REMOVE,
} from "../config/QuizThemeConfig";

// get
export const getQuizThemeData = (value) => ({
  type: QUIZ_THEME_DATA,
  value,
});
export const getQuizThemeDataSuccess = (value) => ({
  type: QUIZ_THEME_DATA_SUCCESS,
  value,
});
export const getQuizThemeDataIdSuccess = (value) => ({
  type: QUIZ_THEME_DATA_ID_SUCCESS,
  value,
});
export const getQuizThemeDataFail = (value) => ({
  type: QUIZ_THEME_DATA_FAIL,
  value,
});
// live search
export const getQuizThemeLiveSearchData = (value) => ({
  type: QUIZ_THEME_LIVE_SEARCH_DATA,
  value,
});
export const getQuizThemeLiveSearchDataSuccess = (value) => ({
  type: QUIZ_THEME_LIVE_SEARCH_DATA_SUCCESS,
  value,
});
export const getQuizThemeLiveSearchDataFail = (value) => ({
  type: QUIZ_THEME_LIVE_SEARCH_DATA_FAIL,
  value,
});
// post
export const postQuizThemeData = (value, random = false) => ({
  type: QUIZ_THEME_DATA_POST,
  value,
  random: random,
});
export const postQuizThemeDataSuccess = (value) => ({
  type: QUIZ_THEME_DATA_POST_SUCCESS,
  value,
});
export const postQuizThemeDataFail = (value) => ({
  type: QUIZ_THEME_DATA_POST_FAIL,
  value,
});

// remove

export const removeQuizThemeLive = (value) => ({
  type: QUIZ_THEME_LIVE_SEARCH_REMOVE,
  value,
});
