import { Button, Card, Col, Form, Image, Row, Table } from "react-bootstrap";
import PaginationTemplate from "../Layout/Pagination";
import IconPlusWhite from "../../assets/images/icon-plus-white.svg";
import IconSearch from "../../assets/images/icon-search.svg";
import IconFilter from "../../assets/images/icon-filter.svg";
import IconEdit from "../../assets/images/icon-edit-pen.svg";
import IconTrash from "../../assets/images/icon-trash.svg";
export default function RombelList(props) {
  const {
    setKeywordStudyTab,
    filterStudyGroup,
    handleDeleteRombel,
    setShow,
    setUserDataModal,
    usersData,
    studyGroupList,
    handleCheck,
    handleEditRombel,
  } = props;
  return (
    <>
    <Card className="p-3">
      <Row className="mt-3 mb-4">
        <b className="d-block pl-3">Daftar Rombongan Belajar</b>
        <Col lg={12} className="d-flex my-3 justify-content-between">
          <div className="d-flex align-items-center">
            <div className="position-relative">
              <Form.Control
                placeholder="Cari"
                aria-label="Username"
                aria-describedby="basic-addon1"
                className="input__search-pelatihan"
                onChange={(e) => setKeywordStudyTab(e.target.value)}
              />
              <div className="icon__search">
                <img src={IconSearch} alt="" />
              </div>
            </div>
            <Button
              variant="outline-secondary"
              id="button-addon2"
              className="ml-3 btn__filter-pelatihan"
              onClick={filterStudyGroup}
            >
              <span className="mr-3">
                <img src={IconFilter} alt="" />
              </span>
              Filter
            </Button>
          </div>
          <div className="d-flex gap-3">
            <Button
              variant="danger"
              className="d-flex align-items-center btn__delete-penugasan mr-3"
              onClick={handleDeleteRombel}
            >
              <span className="mr-3">
                <img src={IconTrash} alt="" />
              </span>
              Hapus
            </Button>
            <div
              className="d-flex align-items-center btn__add-penugasan-organisasi"
              onClick={() => {
                setShow(true);
                setUserDataModal(usersData.items);
              }}
            >
              <span className="">
                <img src={IconPlusWhite} alt="" />
              </span>
              Tambah Rombel
            </div>
          </div>
        </Col>
      </Row>
      <Table responsive striped hover>
        <thead>
          <tr>
            <th style={{ width: "50px" }}>
              <Form.Check
                inline
                name="allselect"
                type="checkbox"
                checked={
                  studyGroupList?.filter((data) => data?.isChecked !== true)
                    .length < 1
                }
                onChange={(e) => handleCheck(e, "study_group_list")}
              />
            </th>
            <th className="pl-0">Nama Rombongan Belajar</th>
            <th className="pl-0 text-center">Jumlah Anggota</th>
            <th className="pl-0"></th>
          </tr>
        </thead>
        <tbody>
          {studyGroupList ?
          studyGroupList?.length > 0 ? (
            studyGroupList?.map((data, index) => (
              <tr key={index}>
                <td>
                  <Form.Check
                    inline
                    name={data.study_group_id}
                    type="checkbox"
                    checked={data?.isChecked || false}
                    username={data.group_name}
                    onChange={(e) => handleCheck(e, "study_group_list")}
                  />
                </td>
                <td className="pl-0">{data.group_name}</td>
                <td className="pl-0 text-center">{data.members_count}</td>
                <td className="pl-0">
                  <Image
                    src={IconEdit}
                    onClick={() => handleEditRombel(data.study_group_id, data.group_name)}
                  />
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={4} className="text-center">
                Data Belum Tersedia
              </td>
            </tr>
          ) : 
          <tr>
              <td colSpan={4} className="text-center">
               Memuat Data...
              </td>
            </tr>
          }
        </tbody>
      </Table>
      <PaginationTemplate
        size={5}
        totalRows={1}
        totalPages={1}
        paginationSelect={1}
        active={1}
      />
    </Card>
    </>
  );
}
