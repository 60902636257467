import { Provider } from "react-redux";
// import { PersistGate } from 'redux-persist/es/integration/react';
import ReduxStore from "./redux/store";
import React, { useEffect } from "react";
// import ReactGA from "react-ga";
import Routes from "./helper/routes";
import { oneSignalID, gaTrackingID } from "./helper/credentials";

// const OneSignal = typeof window !== "undefined" ? window.OneSignal : [];

// ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID)

function App() {
  useEffect(() => {
    const OneSignal = window.OneSignal;

    OneSignal && OneSignal.push(() => {
      OneSignal.init({
        appId: oneSignalID,
      });
      OneSignal.on('notificationDisplay', function (e) {
        console.log(e);
      });
    });
  }, []);

  return (
    <Provider store={ReduxStore.store}>
      <Routes />
    </Provider>
  );
}

export default App;
