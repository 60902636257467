import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Template from "../components/Layout/Template";
// import ScrollToTop from "./ScrollToTop";
import RouteData from "./route-data";

export default function Routes() {
  return (
    <>
      <Switch>
        {RouteData.map((d, i) => (
          <Route key={i} exact={d.exact} path={d.path}>
            {d.redirect ? (
              <Redirect to={d.redirect} />
            ) : (
              <Template
                isSidebar={d.isSidebar}
                isHeader={d.isHeader}
                InnerComponent={d.component}
              />
            )}
          </Route>
        ))}
      </Switch>
    </>
  );
}
