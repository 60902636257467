import React, { useState } from "react";
import { Button, Row, Col, Container, Form, Card } from "react-bootstrap";
import { FaMailBulk } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import "../../assets/css/Account/index.css";
import {
  loadingFinish,
  loadingStart,
} from "../../redux/reducers/CommonReducers";
import { SkValidate } from "../../components/common/SkValidate";
import { AuthService } from "../../http/AuthHttp";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
const MySwal = withReactContent(Swal);

function ForgotPassword() {
  const [data, setData] = useState({
    email: "",
  });
  const dispatch = useDispatch();
  const constraints = {
    email: {
      presence: true,
      email: true,
    },
  };

  const onChangeHandler = (e) => {
    const newData = { ...data };
    newData[e.target.id] = e.target.value;
    setData(newData);
  };
  const onSubmitHandler = async (e) => {
    e.preventDefault();
    dispatch(loadingStart());
    const form = e.target;
    const isValid = SkValidate(form, constraints);
    if (isValid) {
      let param = `?email=${data?.email}`;
      try {
        const response = await AuthService.getResetPassword(param);
        if (response) {
          displayPopup(
            false,
            `Berhasil mengirimkan konfirmasi ke email`,
            true,
            "Oke"
          );
        }
      } catch (error) {
        displayPopup(false, `Gagal mengirim email`, true, "Oke");
      }
    }
    dispatch(loadingFinish());
  };

  function displayPopup(cancel, title, confirm, confirmMsg) {
    MySwal.fire({
      title: title,
      showCancelButton: cancel,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      showConfirmButton: confirm,
      confirmButtonText: confirmMsg,
    }).then((value) => {});
  }

  return (
    <Container className="height-full">
      <Row className="justify-content-center align-items-center height-inherit">
        <Col xs={12} sm={12} md={5} lg={4} className="col-login rounded  ">
          <div className="text-center">
            <div className="login-title">Lupa Password</div>
          </div>
          <Card className="p-4">
            <Form onSubmit={onSubmitHandler}>
              <Form.Group className="mb-5">
                <div className="login-label">Email</div>
                <div className="position-absolute p-3 d-flex">
                  <FaMailBulk />
                </div>
                <Form.Control
                  className="mb-1 input100"
                  type="text"
                  placeholder="Masukkan Email"
                  id="email"
                  name="email"
                  onChange={onChangeHandler}
                />
                <div className="messages"></div>
              </Form.Group>
              <Button type="submit" className="w-100 login-button">
                Kirim
              </Button>
              <div className="text-center mb-4">
                <span>
                  <a href="/login" className="redirect_to">
                    Lupakan ini, saya ingin masuk
                  </a>
                </span>
              </div>
            </Form>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default ForgotPassword;
