import React, { useState, useEffect } from "react";
import { Button, Row, Col, Container, Form, Card } from "react-bootstrap";
import { CheckIfEmpty, ValidateEmail, Clone } from "../../helper/functions";
import "../../assets/css/Account/index.css";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { postUsersDataThunk } from "../../redux/thunks/UsersThunks";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { AuthIsNotLogin } from "../../helper/auth";
import { FaUserAlt, FaBuilding, FaEnvelope, FaLock } from "react-icons/fa";
const MySwal = withReactContent(Swal);
const sha256 = require("js-sha256");

function Register() {
  const [data, setData] = useState({
    company: "",
    email: "",
    name: "",
    password: "",
    repassword: "",
    username: "",
    terms: false,
  });
  const [trigger, setTrigger] = useState(false);
  const users = useSelector((state) => state.users);
  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    AuthIsNotLogin(history);
  }, []);

  useEffect(() => {
    if (users.usersDataPost.status === "error" && trigger) {
      setTrigger(false);
      displayPopup(false, "Registrasi Gagal!", "Oke");
    }
    if (users.usersDataPost === "success" && trigger) {
      setTrigger(false);
      displayPopup(false, "Registrasi Berhasil!", "Oke", "login");
    }
  }, [users.usersDataPost]);

  const onChangeHandler = (e) => {
    const newData = { ...data };
    const id = e.target.id;
    if (id === "terms") {
      newData[id] = !data.terms;
    } else newData[id] = e.target.value;
    setData(newData);
  };
  function onSubmitHandler(e) {
    e.preventDefault();
    if (!CheckIfEmpty(data) && data.terms) {
      if (ValidateEmail(data.email)) {
        if (data.password === data.repassword) {
          let body = Clone(data);
          delete body.terms;
          delete body.repassword;
          body.password = sha256(data.password);
          body.role = "student";
          dispatch(postUsersDataThunk(body));
          setTrigger(true);
        } else displayPopup(false, "Password tidak sama!", "Oke");
      } else displayPopup(false, "Format Email salah!", "Oke");
    } else
      displayPopup(
        false,
        "Mohon semua data diisi dan Terima 'terms and condition'!",
        "Oke"
      );
  }

  function displayPopup(cancel, title, confirm, nextPage) {
    MySwal.fire({
      title: title,
      showCancelButton: cancel,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: confirm,
    }).then((value) => {
      if (nextPage) history.push(`/${nextPage}`);
    });
  }

  return (
    <Container>
      <Row className="justify-content-center align-items-center height-inherit">
        <Col xs={12} sm={12} md={5} lg={5} className="wrapper-register">
          <div className="text-center">
            <div className="login-title">Daftar</div>
          </div>
          <Card className="p-5">
            <Form>
              <div>
                <Form.Group>
                  <div className="login-label">Username</div>
                  <div className="position-absolute p-3 d-flex">
                    <FaUserAlt />
                  </div>
                  <Form.Control
                    className="mb-3 input100"
                    type="username"
                    placeholder="Masukkan Username Anda"
                    id="username"
                    value={data.username}
                    onChange={onChangeHandler}
                  />
                </Form.Group>
                <Form.Group>
                  <div className="login-label">Nama</div>
                  <div className="position-absolute p-3 d-flex">
                    <FaUserAlt />
                  </div>
                  <Form.Control
                    className="mb-3 input100"
                    type="name"
                    placeholder="Nama Lengkap"
                    id="name"
                    value={data.name}
                    onChange={onChangeHandler}
                  />
                </Form.Group>
              </div>
              <Form.Group>
                {" "}
                <div className="login-label">Perusahaan</div>
                <div className="position-absolute p-3 d-flex">
                  <FaBuilding />
                </div>
                <Form.Control
                  className="mb-3 input100"
                  type="company"
                  placeholder="Masukkan Perusahaan Anda"
                  id="company"
                  value={data.company}
                  onChange={onChangeHandler}
                />
              </Form.Group>
              <Form.Group>
                <div className="login-label">Email</div>
                <div className="position-absolute p-3 d-flex">
                  <FaEnvelope />
                </div>
                <Form.Control
                  className="mb-3 input100"
                  type="email"
                  placeholder="Masukkan Email Anda"
                  id="email"
                  value={data.email}
                  onChange={onChangeHandler}
                />
              </Form.Group>
              <Form.Group>
                <div className="login-label">Kata Sandi</div>
                <div className="position-absolute p-3 d-flex">
                  <FaLock />
                </div>
                <Form.Control
                  className="mb-3 input100"
                  type="password"
                  placeholder="Masukkan Kata Sandi Anda"
                  id="password"
                  value={data.password}
                  onChange={onChangeHandler}
                />
              </Form.Group>{" "}
              <Form.Group>
                <div className="login-label">Konfirmasi Kata Sandi</div>
                <div className="position-absolute p-3">
                  <FaLock />
                </div>
                <Form.Control
                  className="mb-3 input100"
                  type="password"
                  placeholder="Konfirmasi Kata Sandi"
                  id="repassword"
                  value={data.repassword}
                  onChange={onChangeHandler}
                />
              </Form.Group>
              <Form.Check
                className="mb-4"
                type="checkbox"
                label="Agree the terms and condition"
                id="terms"
                value={data.terms}
                onChange={onChangeHandler}
              />
              <Button
                type="submit"
                className="login-button w-100"
                onClick={onSubmitHandler}
              >
                {" "}
                <div className="login-text"> Daftar</div>
              </Button>
              <div className="text-center login-register-text">
                Sudah punya akun?{" "}
                <span>
                  <a
                    href="/login"
                    className="text-decoration-none fw-700 login-register-text__span"
                  >
                    Masuk
                  </a>
                </span>
              </div>
            </Form>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default Register;
