import React, { useEffect } from "react";
import { Row, Col, Container } from "react-bootstrap";
import AddLearningPath from "../../components/AddLearningPath";
import { AuthIsLogin } from "../../helper/auth";
import { useHistory } from "react-router-dom";
import "../../assets/css/Dashboard/index.css";

function CreateLearningPath() {
  const history = useHistory();
  useEffect(() => {
    AuthIsLogin(history);
  }, []);
  return (
    <Container>
      <Row className="justify-content-center align-items-center height-inherit">
        <Col>
          <div className=" pt-2 pb-3">
            <div className="course_create__title">Buat Learning Path</div>
          </div>
        </Col>
        <Col
          xs={12}
          sm={12}
          md={12}
          lg={12}
        >
          <AddLearningPath />
        </Col>
      </Row>
    </Container>
  );
}

export default CreateLearningPath;
