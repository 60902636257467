import {
  //get
  ASSIGNMENT_DATA,
  ASSIGNMENT_DATA_ID_SUCCESS,
  ASSIGNMENT_DATA_SUCCESS,
  ASSIGNMENT_DATA_FAIL,
  //post
  ASSIGNMENT_DATA_POST,
  ASSIGNMENT_DATA_POST_SUCCESS,
  ASSIGNMENT_DATA_POST_FAIL,
  //put
  ASSIGNMENT_DATA_PUT,
  ASSIGNMENT_DATA_PUT_SUCCESS,
  ASSIGNMENT_DATA_PUT_FAIL,
  //delete
  ASSIGNMENT_DATA_DELETE,
  ASSIGNMENT_DATA_DELETE_SUCCESS,
  ASSIGNMENT_DATA_DELETE_FAIL,
} from "../config/AssignmentConfig";

const intialState = {
  assignmentDataPost: "",
  assignmentDataPut: "",
  assignmentDataDelete: "",
  assignmentData: [],
  assignmentDataId: [],
  isLoading: false,
};
export const AssignmentReducers = function (state = intialState, action) {
  switch (action.type) {
    //get
    case ASSIGNMENT_DATA:
      return { ...state, isLoading: true };
    case ASSIGNMENT_DATA_SUCCESS:
      return { ...state, isLoading: false, assignmentData: action.value };
    case ASSIGNMENT_DATA_ID_SUCCESS:
      return { ...state, isLoading: false, assignmentDataId: action.value };
    case ASSIGNMENT_DATA_FAIL:
      return {
        ...state,
        isLoading: false,
        assignmentData: "error",
        assignmentDataId: "error",
      };
    //post
    case ASSIGNMENT_DATA_POST:
      return { ...state, isLoading: true, assignmentDataPost: "" };
    case ASSIGNMENT_DATA_POST_SUCCESS:
      return { ...state, isLoading: false, assignmentDataPost: "success" };
    case ASSIGNMENT_DATA_POST_FAIL:
      return { ...state, isLoading: false, assignmentDataPost: "error" };

    //put
    case ASSIGNMENT_DATA_PUT:
      return { ...state, isLoading: true, assignmentDataPut: "" };
    case ASSIGNMENT_DATA_PUT_SUCCESS:
      return { ...state, isLoading: false, assignmentDataPut: "success" };
    case ASSIGNMENT_DATA_PUT_FAIL:
      return { ...state, isLoading: false, assignmentDataPut: "error" };

    //delete
    case ASSIGNMENT_DATA_DELETE:
      return { ...state, isLoading: true, assignmentDataDelete: "" };
    case ASSIGNMENT_DATA_DELETE_SUCCESS:
      return { ...state, isLoading: false, assignmentDataDelete: "success" };
    case ASSIGNMENT_DATA_DELETE_FAIL:
      return { ...state, isLoading: false, assignmentDataDelete: "error" };

    default:
      return state;
  }
};

