import { satukelasApi } from "../constant/api";

export const StudyGroupServices = {
  postStudyGroup(payload) {
    return satukelasApi.post(`v2/study_groups/add_members`, payload);
  },
  putStudyGroup(payload) {
    return satukelasApi.put(
      `v2/study_groups/${payload.study_group_id}`,
      payload.study_group
    );
  },
  getStudyGroup(param) {
    return satukelasApi.get(`v2/study_groups${param}`);
  },
  getStudyGroupMember(param) {
    return satukelasApi.get(`v2/study_groups_members${param}`);
  },
  removeStudyGroupMember(payload) {
    return satukelasApi.post(`v2/study_groups/remove_members`, payload);
  },
};
