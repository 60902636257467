export const env = (process.env.REACT_APP_ENV) ? process.env.REACT_APP_ENV : "development";

// connectycube
export const ccAppId = env === "development" ? 5433 : 5433;
export const ccAuthKey =
  env === "development" ? "XRTCwBTOC3HaKds" : "XRTCwBTOC3HaKds";
export const ccAuthSecret =
  env === "development" ? "CGdhdRJgfOCTzEF" : "CGdhdRJgfOCTzEF";
export const ccUID = env === "development" ? 4957569 : 4957569;
export const ccCredEmail =
  env === "development" ? "student@student.com" : "student@student.com";
export const ccCredPassword =
  env === "development" ? "student@student.com" : "student@student.com";


// onesignal
export const oneSignalID = (env==="development") ? "2b0e561a-ede8-432d-b91d-73af13ff28ed" : "0e8eedea-22a8-405d-8368-b43a96fd4d46"

// ga
export const gaTrackingID = (env==="development") ? "UA-208623727-1" : "UA-208623727-1"
