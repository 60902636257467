import React from "react";
import { Card } from "react-bootstrap";
import "../assets/css/Dashboard/companyAnalytics.css";

function FollowingWebinar({ data }) {
  return (
    <Card className="card_container">
      <div className="card_inside__padding ">
        <div className="d-flex flex-column justify-content-between border_card">
          <div className="card_title__info mb-1">Webinar yang Diikuti</div>
          <div className="card_subtitle mb-1">
            {" "}
            {data?.stats_webinar?.length !== 0 ? (
              <div className="card_subtitle mb-1">
                Total: {data?.stats_webinar?.length}
              </div>
            ) : (
              "N/A"
            )}
          </div>
        </div>

        {data?.stats_webinar?.length !== 0 ? (
          data?.stats_webinar?.map((item, index) => {
            return (
              <div key={index} className="certificate-wrapper">
                <div className="wrapper-table__list">
                  <div className="d-flex">
                    {/* <div className="img-certificate mr-3"></div> */}
                    <div className="certificate-title__wrap">
                      <div className="certificate-title__main">
                        {item?.course}
                      </div>
                      <div className="certificate-subtitle__secondary">
                        {/* <FaUserAlt className="mr-2 size" /> */}
                        Total Materi : {item?.total_materials}
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="my-2">
                  {" "}
                  <span className="container-avg__company">Akan Datang</span>
                </div> */}
              </div>
            );
          })
        ) : (
          <div className="certificate-title__main">N/A</div>
        )}
      </div>
    </Card>
  );
}

export default FollowingWebinar;
