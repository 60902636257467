import React, { useState } from "react";
import { Button, Card, Table } from "react-bootstrap";
import "../assets/css/Dashboard/companyAnalytics.css";
import SkModal from "./common/SkModal";
import moment from "moment";

function FollowingTryout({ data, tryOut }) {
  const [modalContent, setModalContent] = useState({
    title: "Modal Title",
    show: false,
    componentBody: "",
  });
  const handleDetail = (value) => {
    setModalContent({
      ...modalContent,
      show: true,
      title: `Detail Course`,
      body: (
        <Table>
          <thead>
            <th className="pl-0">Mulai</th>
            <th className="pl-0">Terakhir Akses</th>
            <th>Attempt</th>
            <th>Nilai</th>
          </thead>

          <tbody>
            {value?.length > 0 ? (
              value?.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>{moment(item?.attempted_at).format("DD MMMM YYYY")}</td>
                    <td>Belum ada</td>
                    <td>Percobaan ke-{index + 1}</td>
                    <td>{item?.score}</td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={4}>Data tidak ada</td>
              </tr>
            )}
          </tbody>
        </Table>
      ),
    });
  };
  return (
    <>
      <Card className="card_container">
        <div className="card_inside__padding ">
          <div className="d-flex flex-column justify-content-between border_card">
            <div className="card_title__info mb-1">Tryout yang Diikuti</div>
            <div className="card_subtitle mb-1">
              {" "}
              {data?.stats_tryout?.length !== 0 ? (
                <div className="card_subtitle mb-1">
                  Total: {data?.stats_tryout?.length}
                </div>
              ) : (
                "N/A"
              )}
            </div>
          </div>
          {data?.stats_tryout?.length !== 0 ? (
            data?.stats_tryout?.map((item, index) => {
              return (
                <div key={index} className="certificate-wrapper">
                  <div className="wrapper-table__list">
                    <div className="certificate-title__wrap">
                      <div className="certificate-title__main">
                        {item?.course}
                      </div>
                      <div className="certificate-subtitle__secondary">
                        {/* <FaUserAlt className="mr-2 size" /> */}
                        Total Materi : {item?.total_materials}
                      </div>
                    </div>
                  </div>
                  <div className="my-2">
                    {" "}
                    <Button
                      onClick={() => handleDetail(item?.attempts)}
                      className="button_detail__users"
                    >
                      Detail
                    </Button>
                  </div>
                </div>
              );
            })
          ) : (
            <div className="certificate-title__main">N/A</div>
          )}
        </div>
      </Card>
      <SkModal content={modalContent} />
    </>
  );
}

export default FollowingTryout;
