export const paymentsMethod = {
  bca: [
    {
      title: "Melalui ATM",
      steps: [
        "Masukkan kartu dan ketikkan nomor PIN di ATM BCA",
        "Pilih menu “Transaksi Lainnya”, lalu pilih “Transfer”",
        "Pilih “ke Rekening BCA Virtual Account”",
        "Masukkan nomor virtual account, kemudian tekan “Benar”",
        "Masukkan nominal yang akan dibayarkan, lalu tekan “Benar” lagi",
        "Setelah layar menampilkan informasi pembayaran, silakan cek kembali nomor virtual account, total pembayaran, serta informasi lainnya",
        "Jika seluruhnya sesuai, maka tekan “Ya”",
        "Pembayaran pun selesai",
      ],
    },
    {
      title: "Melalui BCA Mobile",
      steps: [
        "Login ke aplikasi BCA Mobile, lalu pilih “m-BCA” dan masukkan kode akses",
        "Pilih “m-Transfer”, lalu lanjut ke “BCA Virtual Account”",
        "Masukkan nomor virtual account lalu masukkan jumlah yang akan dibayarkan",
        "Masukkan PIN m-BCA",
        "Pembayaran selesai dilakukan",
      ],
    },
    {
      title: "Klik BCA Individu",
      steps: [
        "Login ke aplikasi Klik BCA Individu, masukkan user ID dan PIN",
        "Pilih menu “Transfer Dana”, selanjutnya pilih “Transfer ke BCA Virtual Account”",
        "Masukkan nomor virtual account yang merupakan tujuan pembayaran Anda",
        "Masukkan jumlah pembayaran dan lakukan validasi untuk memastikan semuanya selesai",
        "Masukkan kode yang diperoleh dari KEYBCA APPLI 1, kemudian klik “Kirim”",
        "Transaksi selesai",
      ],
    },
    {
      title: "Klik BCA Bisnis",
      steps: [
        "Pastikan Anda telah login ke akun Klik BCA Bisnis",
        "Pilih menu “Transfer Dana”, “Daftar Transfer”, lalu “Tambah”",
        "Masukkan kode virtual account BCA, pilih menu “Kirim”, lalu pilih “Transfer Dana”",
        "Klik “ke BCA Virtual Account”, lalu pilih rekening sumber dana dan nomor virtual account",
        "Masukkan jumlah yang akan dibayarkan, pilih “Kirim”",
        "Lakukan validasi sampai data berhasil di-input lalu pilih “Simpan”",
        "Pilih “Transfer Dana”, lalu “Otorisasi Transaksi” untuk melanjutkan transaksi sampai selesai",
      ],
    },
  ],
  mandiri: [
    {
      title: "Melalui ATM",
      steps: [
        "Masukkan kartu ATM dan PIN ATM.",
        "Pilih menu Bayar / Beli.",
        "Pilih opsi Lainnya > Multipayment.",
        "Masukkan kode biller perusahaan(biasanya sudah tercantum di instruksi pembayaran).",
        "Masukkan nomor Virtual account > Benar.",
        "Masukkan angka yang diminta untuk memilih tagihan > Ya.",
        "Layar akan menampilkan konfirmasi.Jika sesuai, pilih Ya.",
        "Selesai.",
      ],
    },
    {
      title: "M-Banking",
      steps: [
        "Buka aplikasi m-banking.",
        "Masukkan username dan password.",
        "Pilih menu Bayar > One Time > Multipayment.",
        "Pilih Penyedia Jasa yang digunakan > Lanjut.",
        "Masukkan nomor Virtual account > Lanjut.",
        "Layar akan menampilkan konfirmasi. Jika sudah sesuai, masukkan PIN transaksi dan akhiri dengan OK.",
        "Selesai.",
      ],
    },
    {
      title: "I-Banking",
      steps: [
        "Akses situs Mandiri Online, masukkan username dan password.",
        "Pilih menu Pembayaran > Multipayment.",
        "Pilih Penyedia Jasa yang digunakan.",
        "Masukkan nomor virtual account Mandiri dan nominal yang akan dibayarkan > Lanjut.",
        "Layar akan menampilkan konfirmasi. Apabila sesuai, lanjutkan dengan pilih Konfirmasi.",
        "Masukkan PIN token.",
        "Selesai.",
      ],
    },
  ],
  permata: [
    {
      title: "ATM Permata",
      steps: [
        "Pada menu utama, pilih transaksi lainnya",
        "Pilih bayar Transfer",
        "Pilih lainnya",
        "Pilih pembayaran Virtual Akun",
        "Masukkan Nomor Virtual Akun dan proses",
        "Pada halaman konfirmasi, akan ada jumlah yang harus dibayarkan, Nomor akun dan nama merchant. Jika informasinya sudah benar, silahkan proses",
        "Pilih sumber dana dan proses",
        "Transaksi anda selesai",
      ],
    },
    {
      title: "PermataMobile X",
      steps: [
        "Buka Permata Mobile X dan masuk",
        "Pilih bayar tagihan",
        "Pilih Virtual Akun",
        "Masukkan Nomor Virtual Akun anda",
        "Rincian tagihan anda akan muncul di layar",
        "Total tagihan akan tampil di layar. Pilih metode pembayaran",
        "Konfirmasi transaksi anda",
        "Masukkan kode OTP dari SMS yang anda terima",
        "Transaksi anda selesai",
      ],
    },
    {
      title: "Internet Banking",
      steps: [
        "Buka https://new.permatanet.com dan masuk",
        "Pilih pembayaran",
        "Pilih pembayaran tagihan",
        "Pilih Virtual Akun",
        "Pilih metode pembayaran anda",
        "Pilih Masukkan Daftar Tagihan Baru",
        "Masukkan virual akun anda",
        "Cek rincian informasi anda dan proses",
        "Masukkan kode OTP dari SMS yang anda terima",
        "Transaksi anda selesai",
      ],
    },
  ],
  bni: [
    {
      title: "ATM BNI",
      steps: [
        "Pada menu utama, pilih Menu Lainnya / Transfer",
        "Pilih Rekening Tabungan ",
        "Pilih Virtual Account Billing",
        "Pilih Rekening Debet",
        "Masukan Nomor Virtual Account",
        "Jika informasi telah sesuai tekan Ya / Masukan Password / Kode Token",
        "Transaksi anda selesai",
      ],
    },
    {
      title: "BNI Mobile Banking",
      steps: [
        "Pilih menu Transfer",
        "Pilih menu pembayaran Virtual Akun dan pilih akun debit",
        "Masukkan Nomor Virtual Akun pada menu Masukkan Baru",
        "Konfirmasi pembayaran",
        "Masukkan kata sandi anda",
        "Transaksi anda selesai",
      ],
    },
    {
      title: "Internet Banking",
      steps: [
        "Buka https://ibank.bni.co.id",
        "Masukkan ID Pengguna dan Kata Sandi",
        "Pilih menu Transfer",
        "Pilih pembayaran Virtual Akun",
        "Masukkan Nomor Virtual Akun. Pilih tipe akun yang anda gunakan untuk Transfer. Tekan lanjut",
        "Konfirmasi Ulang untuk transaksi",
        "Masukkan kode OTP dari SMS yang anda terima",
        "Transaksi anda selesai",
      ],
    },
  ],
};
