import {
  // get
  ANALYTICS_USER_DATA,
  ANALYTICS_USER_DATA_SUCCESS,
  ANALYTICS_USER_DATA_ID_SUCCESS,
  // fail
  ANALYTICS_USER_DATA_FAIL,
} from "../config/AnalyticsUserConfig";

// get
export const getAnalyticsUserData = (value) => ({
  type: ANALYTICS_USER_DATA,
  value,
});

export const getAnalyticsUserDataSuccess = (value) => ({
  type: ANALYTICS_USER_DATA_SUCCESS,
  value,
});
export const getAnalyticsUserDataIdSuccess = (value) => ({
  type: ANALYTICS_USER_DATA_ID_SUCCESS,
  value,
});
export const getAnalyticsUserDataFail = (value) => ({
  type: ANALYTICS_USER_DATA_FAIL,
  value,
});

// fail handle
