import {
  // get
  QUIZ_DATA,
  QUIZ_DATA_SUCCESS,
  QUIZ_DATA_ID_SUCCESS,
  // post
  QUIZ_DATA_POST,
  QUIZ_DATA_POST_SUCCESS,
  QUIZ_DATA_POST_FAIL,
  // put
  QUIZ_DATA_PUT,
  QUIZ_DATA_PUT_SUCCESS,
  QUIZ_DATA_PUT_FAIL,
  // delete
  QUIZ_DATA_DELETE,
  QUIZ_DATA_DELETE_SUCCESS,
  QUIZ_DATA_DELETE_FAIL,
  // fail
  QUIZ_DATA_FAIL,
} from "../config/QuizConfig";

// get
export const getQuizData = (value) => ({
  type: QUIZ_DATA,
  value,
});
export const getQuizDataSuccess = (value) => ({
  type: QUIZ_DATA_SUCCESS,
  value,
});
export const getQuizDataIdSuccess = (value) => ({
  type: QUIZ_DATA_ID_SUCCESS,
  value,
});
export const getQuizDataFail = (value) => ({
  type: QUIZ_DATA_FAIL,
  value,
});

// post
export const postQuizData = (value, random = false) => ({
  type: QUIZ_DATA_POST,
  value,
  random:random,
});
export const postQuizDataSuccess = (value) => ({
  type: QUIZ_DATA_POST_SUCCESS,
  value,
});
export const postQuizDataFail = (value) => ({
  type: QUIZ_DATA_POST_FAIL,
  value,
});

// put
export const putQuizData = (value) => ({
  type: QUIZ_DATA_PUT,
  value,
});
export const putQuizDataSuccess = (value) => ({
  type: QUIZ_DATA_PUT_SUCCESS,
  value,
});
export const putQuizDataFail = (value) => ({
  type: QUIZ_DATA_PUT_FAIL,
  value,
});

// delete
export const deleteQuizData = (value) => ({
  type: QUIZ_DATA_DELETE,
  value,
});
export const deleteQuizDataSuccess = (value) => ({
  type: QUIZ_DATA_DELETE_SUCCESS,
  value,
});
export const deleteQuizDataFail = (value) => ({
  type: QUIZ_DATA_DELETE_FAIL,
  value,
});

// fail handle
