import React, { useEffect } from "react";
import { AuthIsLogin } from "../../helper/auth";
import { useHistory } from "react-router-dom";

function PreviewLearningPath() {
  const history = useHistory();
  useEffect(() => {
    AuthIsLogin(history);
  }, []);
  return <>PreviewLearningPath</>;
}

export default PreviewLearningPath;
