import React from "react";
import { Card, Row, Col } from "react-bootstrap";
import ReactECharts from "echarts-for-react";
import { dataTool } from "echarts";
import moment from "moment";
function ActiveUsers({ data, userExpire }) {
  const userNotActive = data?.students?.length - data?.active_users?.length;
  const userActive = dataTool?.active_users?.length;

  const option3 = {
    tooltip: {
      trigger: "item",
    },
    series: [
      {
        type: "pie",
        radius: ["40%", "70%"],
        label: {
          show: false,
          position: "center",
        },
        emphasis: {
          label: {
            show: true,
          },
        },
        labelLine: {
          show: false,
        },
        data: [
          { value: userNotActive, name: "User Tidak Aktif" },
          { value: userActive, name: "User Aktif" },
        ],
        color: ["#F7F8CF", "#FBFF3F"],
      },
    ],
  };

  const membership = {
    tooltip: {
      trigger: "item",
    },
    series: [
      {
        type: "pie",
        radius: ["40%", "70%"],
        label: {
          show: false,
          position: "center",
        },
        emphasis: {
          label: {
            show: true,
          },
        },
        labelLine: {
          show: false,
        },
        data: [
          { value: data?.max_students, name: "Tidak Terisi" },
          { value: data?.students?.length, name: "Terisi" },
        ],
      },
    ],
  };

  function membershipSlot(slot, total) {
    return ((total / slot) * 100).toFixed(2);
  }

  return (
    <>
      <Card className="card_container mb-4 active_user__container">
        <div className="card_inside__padding ">
          <Row>
            <Col>
              <div className="dash1">
                <p className="mb-2">Jumlah Aktif User</p>
                <h3 className="mb-1 number-font fw-700">{data?.active_users?.length === 0 ? "N/A" : data?.active_users?.length}</h3>
                <span className="fs-12 ">
                  <strong> {((data?.active_users.length / data?.students.length) * 100).toFixed(2)}%</strong> <span className="fs-12 ml-0 mt-1">User Aktif</span>
                </span>
              </div>
            </Col>
            <Col md="auto">
              <ReactECharts style={{ height: "100px", width: "100px" }} option={option3} />
            </Col>
          </Row>
        </div>
      </Card>
      <Card className="card_container mb-4 memberships_container">
        <div className="card_inside__padding ">
          <Row>
            <Col>
              <div className="dash1">
                <p className="mb-2 ">Slot Membership</p>
                <h3 className="mb-1 number-font fw-700">
                  {data?.students?.length}/{data?.max_students}
                </h3>
                <span className="fs-12 text-muted">
                  <i className="fe fe-trending-up"></i> <strong> {membershipSlot(data?.max_students, data?.students?.length)}%</strong> <span className="text-muted fs-12 ml-0 mt-1">Telah Terisi</span>
                </span>
              </div>
            </Col>
            <Col md="auto">
              <ReactECharts style={{ height: "100px", width: "100px" }} option={membership} />
            </Col>
          </Row>
        </div>
      </Card>
      <Card className="card_container memberships_container">
        <div className="card_inside__padding ">
          <Row>
            <Col>
              <div className="dash1">
                <p className="mb-2">Info Membership</p>
                {userExpire?.usersDataId?.memberships?.length > 0 ? (
                  <>
                    <h3 className="mb-1 number-font fw-700">{userExpire?.usersDataId?.memberships[0]?.membership_title}</h3>
                    <span className="fs-12 text-muted">
                      <i className="fe fe-trending-up"></i> <strong>Aktif Sampai</strong> <span className="text-muted fs-12 ml-0 mt-1">{moment(userExpire?.usersDataId?.memberships[0]?.expired_at).format("DD MMMM YYYY")}</span>
                    </span>
                  </>
                ) : null}
              </div>
            </Col>
          </Row>
        </div>
      </Card>
    </>
  );
}

export default ActiveUsers;
