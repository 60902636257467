import React, { useState, useEffect } from "react";
import { Button, Table, Modal, Form, Card } from "react-bootstrap";
import { FaEdit } from "react-icons/fa";
import { RiDeleteBin2Line } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import {
  getTopicDataThunk,
  putTopicDataThunk,
  deleteTopicDataThunk,
} from "../redux/thunks/TopicThunks";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import AddTopic from "./AddTopic";
const MySwal = withReactContent(Swal);

function TableTopic() {
  const [trigger, setTrigger] = useState(false);
  const [topicData, setTopicData] = useState([]);
  const [topicDataId, setTopicDataId] = useState([]);
  const [detailData, setDetailData] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [display, setDisplay] = useState(false);
  const topics = useSelector((state) => state.topics);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getTopicDataThunk());
  }, []);

  useEffect(() => {
    setTopicData(topics.topicData.items);
    setTopicDataId(topics.topicDataId);
  }, [topics]);

  useEffect(() => {
    if (topics.topicDataPut === "error" && trigger) {
      setTrigger(false);
      displayPopup(false, "Terjadi masalah saat submit data", "Ok");
    }
  }, [topics]);

  // useEffect(() => {
  //   console.log("===============================>", topicDataId);
  //   if (topicDataId && display) setModalShow(true);
  // }, [display]);

  const handleDelete = (id) => {
    MySwal.fire({
      title: "Anda yakin ingin menghapus ?",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Iya",
      cancelButtonText: "Tidak",
    }).then((value) => {
      if (value.isConfirmed) dispatch(deleteTopicDataThunk(id));
    });
  };
  const handleShow = (id_course, courses, title, id_topic, index) => {
    const data = {
      id_course,
      courses,
      title,
      id_topic,
      index,
    };
    dispatch(getTopicDataThunk(id_topic));
    setModalShow(true);
    setDetailData(data);
  };
  const handleChange = (e) => {
    const newData = { ...detailData };
    newData[e.target.id] = e.target.value;
    setDetailData(newData);
  };
  const handleClose = () => {
    setModalShow(false);
    setDisplay(false);
  };
  const handleEdit = (data) => {
    setTrigger(true);
    dispatch(putTopicDataThunk(data));
    document
      .querySelectorAll(
        "#form-edit input, #form-edit select, #form-edit button, #form-edit textarea"
      )
      .forEach((elem) => (elem.disabled = true));
  };
  function displayPopup(cancel, title, confirm) {
    MySwal.fire({
      title: title,
      showCancelButton: cancel,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: confirm,
    }).then((value) => {
      console.log(value, "value");
    });
  }
  return (
    <>
      <Card className="card_container">
        <div className="card_inside__padding ">
          <Table responsive bordered hover>
            <thead>
              <tr>
                <th className="w-25">Course ID</th>
                <th>Course</th>
                <th>Title</th>
                <th>Topic ID</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {topicData
                ? topicData.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>{item.course_id}</td>
                        <td>{item.course} </td>
                        <td>{item.title}</td>
                        <td>{item.topic_id}</td>
                        <td>
                          <Button
                            onClick={() =>
                              handleShow(
                                item.course_id,
                                item.course,
                                item.title,
                                item.topic_id,
                                index
                              )
                            }
                            variant="info"
                            className="mb-2"
                          >
                            <FaEdit className="fill-white " />
                          </Button>
                          <Button variant="danger">
                            <RiDeleteBin2Line
                              onClick={() => handleDelete(item.topic_id)}
                              className="fill-white"
                            />
                          </Button>
                        </td>
                      </tr>
                    );
                  })
                : null}
            </tbody>
          </Table>
        </div>
      </Card>
      <Modal
        show={modalShow}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        style={{ overflow: "auto" }}
      >
        <Modal.Header className="p-4">
          <Modal.Title id="contained-modal-title-vcenter">
            Edit Data
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-4">
          <AddTopic
            id={detailData.id_topic}
            defaultItems={topicDataId}
            handleCancel={handleClose}
          />
        </Modal.Body>
      </Modal>
    </>
  );
}

export default TableTopic;
