import React, { useState, useEffect } from "react";
import { Table, Card, Button, Modal } from "react-bootstrap";
// import { getAnalyticsUserDataThunk } from "../redux/thunks/AnalyticsUserThu<<<<<<< .merge_file_a03304
import { getAnalyticsCourseStatsDataThunk } from "../redux/thunks/AnalyticsGroupThunks";
import { useDispatch, useSelector } from "react-redux";
function WebinarList({ data, adminBnsp }) {
  const [active, setActive] = useState(1);
  const [dataAdmin, setDataAdmin] = useState();
  const [dataDetail, setDataDetail] = useState();
  const [show, setShow] = useState(false);
  const [title, setTitle] = useState();
  // const [size, setSize] = useState(5); //pagination size
  // state from redux
  const analyticsgroup = useSelector((state) => state.analyticsgroup);
  const users = useSelector((state) => state.users);

  const dispatch = useDispatch();

  useEffect(() => {
    if (analyticsgroup.analyticsCourseStatsData) {
      setDataDetail(analyticsgroup?.analyticsCourseStatsData?.data);
    }
  }, [analyticsgroup]);

  const handleShowDetail = (course_id, company_id, title) => {
    setTitle(title);
    dispatch(
      getAnalyticsCourseStatsDataThunk({
        param: `?company_id=${company_id}&course_id=${course_id}`,
      })
    );
    setShow(true);
  };

  useEffect(() => {
    setDataAdmin(users.usersDataId);
  }, [users]);

  // function keydownHandler(e) {
  //   if (e.keyCode == 13) {
  //     setActive(1);
  //     // dispatch(
  //     //   getCoursesData({
  //     //     param: `?size=${size}&page=1${search ? "&keyword=" + search : ""}`,
  //     //   })
  //     // );
  //   }
  // }

  // function onChangeSearch(e) {
  //   const newValue = e.target.value;
  //   setSearch(newValue);
  // }

  // function paginationSelect(newActive) {
  //   setActive(newActive);
  //   // dispatch(
  //   //   getCoursesData({
  //   //     param: `?size=${size}&page=${newActive}${
  //   //       search ? "&keyword=" + search : ""
  //   //     }`,
  //   //   })
  //   // );
  // }

  return (
    <>
      <Card className="card_container">
        <div className="card_inside__padding webinar-list">
          <div className="d-flex justify-content-between">
            <div className="card_title__info mb-1">Daftar Webinar</div>
            {/* <div className="d-flex justify-content-end align-items-center mb-2 card_filter">
              Urutkan :{" "}
              <Dropdown className="ml-2">
                <Dropdown.Toggle
                  className="px-3 py-1"
                  variant="success"
                  id="dropdown-basic"
                >
                  Ascending
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item>Ascending</Dropdown.Item>
                  <Dropdown.Item>Descending</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>{" "}
            </div> */}
          </div>
          {/* <div className="d-flex flex-row justify-content-between mb-3">
            <Form.Control
              style={{ flex: 0.5 }}
              className="satukelas_input"
              type="text"
              id="search"
              name="search"
              placeholder="Cari Webinar"
              value={search}
              onKeyDown={keydownHandler}
              onChange={onChangeSearch}
            />
            {course_list ? (
              <Pagination
                size={size}
                totalRows={course_list.length}
                totalPages={1}
                paginationSelect={paginationSelect}
                active={active}
              />
            ) : null}
          </div> */}
          <div className="table-responsive">
            <Table className="text-nowrap">
              <thead>
                <tr role="row">
                  <th
                    className="sorting_asc w-50"
                    tabIndex="0"
                    aria-controls="exportexample"
                    aria-sort="ascending"
                    aria-label="Nama: activate to sort column descending"
                  >
                    Nama Webinar
                  </th>
                  <th
                    className="sorting"
                    tabIndex="0"
                    aria-controls="exportexample"
                    aria-label="Departemen: activate to sort column ascending"
                  >
                    Kategori
                  </th>
                  <th
                    className="sorting"
                    tabIndex="0"
                    aria-controls="exportexample"
                    aria-label="Kelas: activate to sort column ascending"
                  >
                    Partisipan
                  </th>
                  <th
                    className="sorting"
                    tabIndex="0"
                    aria-controls="exportexample"
                    aria-label="Tanggal Selesai: activate to sort column ascending"
                  >
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {data?.popular_webinar?.length > 0 ? (
                  data?.popular_webinar?.map((item, index) => {
                    return (
                      <tr key={index} role="row" className="odd">
                        <td className="sorting_1">{item.course}</td>
                        <td>
                          {item.categories === "" ? "-" : item.categories}
                        </td>
                        <td>{item.participants}</td>

                        <td>
                          <Button
                            onClick={() =>
                              handleShowDetail(
                                item?.course_id,
                                data?.company_id,
                                "Webinar"
                              )
                            }
                          >
                            Detail
                          </Button>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={4}>Data Tidak Ditemukan</td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
        </div>
      </Card>

      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        scrollable={true}
        centered
        show={show}
        onHide={() => setShow(!show)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Detail Partisipan Webinar</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table responsive striped hover>
            <thead>
              <th className="pl-0">
                Nama{" "}
                {adminBnsp && adminBnsp?.usersDataId?.is_bnsp === 1
                  ? "Partisipan"
                  : "Karyawan"}
              </th>
              <th className="pl-0">Posisi</th>
              {/* {value === undefined ? null : <th className="w-50">Status</th>} */}
              <th>Action</th>
            </thead>

            {analyticsgroup.analyticsCourseStatsData?.items?.length > 0 ? (
              analyticsgroup.analyticsCourseStatsData?.items?.map(
                (item, index) => {
                  return (
                    <tr key={index}>
                      <td className="position_text bold w-75">
                        <div>{item.name}</div>
                      </td>
                      <td className="position_text">
                        <span>
                          {item?.position === "" ? "N/A" : item?.position}
                        </span>
                      </td>{" "}
                      {/* {value !== "status" || value !== "status" ? null : (
                        <td className="position_text">
                          <span className="container-avg__company">Status</span>
                        </td>
                      )} */}
                      <td className="position_text">
                        <div>
                          <Button
                            href={`/analytics/users/${item?.user_id}`}
                            className="detail_button__users"
                          >
                            Detail
                          </Button>
                        </div>
                      </td>
                    </tr>
                  );
                }
              )
            ) : (
              <tr>
                <td colSpan={3}>Data Tidak Ditemukan</td>
              </tr>
            )}
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShow(!show)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      {/* <SkModal content={modalContent} /> */}
    </>
  );
}

export default WebinarList;
