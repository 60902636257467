import {
  //get
  LEARNING_PATH_DATA,
  LEARNING_PATH_DATA_ID_SUCCESS,
  LEARNING_PATH_DATA_SUCCESS,
  LEARNING_PATH_DATA_FAIL,
  //post
  LEARNING_PATH_DATA_POST,
  LEARNING_PATH_DATA_POST_SUCCESS,
  LEARNING_PATH_DATA_POST_FAIL,
  //put
  LEARNING_PATH_DATA_PUT,
  LEARNING_PATH_DATA_PUT_SUCCESS,
  LEARNING_PATH_DATA_PUT_FAIL,
  //delete
  LEARNING_PATH_DATA_DELETE,
  LEARNING_PATH_DATA_DELETE_SUCCESS,
  LEARNING_PATH_DATA_DELETE_FAIL,
  //remove
  REMOVE_LEARNING_PATH_DATA,
} from "../config/LearningPathConfig";

const intialState = {
  learningPathDataPost: "",
  learningPathDataPut: "",
  learningPathDataDelete: "",
  learningPathDataGet: [],
  learningPathDataGetId: [],
  isLoading: false,
};

export const LearningPathReducers = function (state = intialState, action) {
  switch (action.type) {
    //get
    case LEARNING_PATH_DATA:
      return { ...state, isLoading: true };
    case LEARNING_PATH_DATA_SUCCESS:
      return { ...state, isLoading: false, learningPathDataGet: action.value };
    case LEARNING_PATH_DATA_ID_SUCCESS:
      return {
        ...state,
        isLoading: false,
        learningPathDataGetId: action.value,
      };
    case LEARNING_PATH_DATA_FAIL:
      return {
        ...state,
        isLoading: false,
        learningPathDataGet: "error",
        learningPathDataGetId: "error",
      };
    //post
    case LEARNING_PATH_DATA_POST:
      return { ...state, isLoading: true, learningPathDataPost: "" };
    case LEARNING_PATH_DATA_POST_SUCCESS:
      return { ...state, isLoading: false, learningPathDataPost: "success" };
    case LEARNING_PATH_DATA_POST_FAIL:
      return { ...state, isLoading: false, learningPathDataPost: "error" };

    //put
    case LEARNING_PATH_DATA_PUT:
      return { ...state, isLoading: true, learningPathDataPut: "" };
    case LEARNING_PATH_DATA_PUT_SUCCESS:
      return { ...state, isLoading: false, learningPathDataPut: "success" };
    case LEARNING_PATH_DATA_PUT_FAIL:
      return { ...state, isLoading: false, learningPathDataPut: "error" };

    //delete
    case LEARNING_PATH_DATA_DELETE:
      return { ...state, isLoading: true, learningPathDataDelete: "" };
    case LEARNING_PATH_DATA_DELETE_SUCCESS:
      return { ...state, isLoading: false, learningPathDataDelete: "success" };
    case LEARNING_PATH_DATA_DELETE_FAIL:
      return { ...state, isLoading: false, learningPathDataDelete: "error" };

    //remove
    case REMOVE_LEARNING_PATH_DATA:
      return { ...state, learningPathDataGet: [] };
      
    default:
      return state;
  }
};
