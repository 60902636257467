import React, { useEffect, useState } from "react";
import { Button, Form, Nav, Tab, Tabs } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import {
  getAssetsDataThunk,
  deleteAssetsDataThunk,
} from "../redux/thunks/AssetsThunks";
import { removeAssetsDataPost } from "../redux/actions/AssetsActions";
import { Capitalize } from "../helper/functions";
import FileUploader from "./FileUploader";
import Pagination from "./Layout/Pagination";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { FaTrashAlt } from "react-icons/fa";
import ImageViewer from "./ImageViewer";

const MySwal = withReactContent(Swal);

function Library({ libraryHandler, handleClose }) {
  const [images, setImages] = useState(null);
  const [prevImage, setPrevImage] = useState(null);
  const [trigger, setTrigger] = useState(false);
  // const [selectedPDF, setSelectedPDF] = useState();
  const [bucket, setBucket] = useState("lessons");
  const [lessonPagination, setLessonPagination] = useState(null);
  const [active, setActive] = useState(1);
  const [size, setSize] = useState(20); //pagination size
  const [search, setSearch] = useState("");
  const [type, setType] = useState("all");
  const [page, setPage] = useState("library");

  const dispatch = useDispatch();
  const assets = useSelector((state) => state.assets);
  const assetsData = assets.assetsData;

  useEffect(() => {
    const param = `?size=${size}&page=${active}&bucket=${bucket}&file_type=${
      type === "all" ? "" : type
    }`;
    dispatch(getAssetsDataThunk(param));
  }, [bucket, type]);

  useEffect(() => {
    if (assetsData.items) {
      const data = assetsData.items;
      let results = [];
      for (let i = 0; i < data.length; i++) {
        results.push({
          src: data[i].url,
          thumbnail: data[i].url,
          thumbnailWidth: parseInt(data[i].width),
          thumbnailHeight: parseInt(data[i].height),
          caption: data[i].file_name,
          type: data[i].file_type,
          ext: data[i].extension,
          isSelected: false,
        });
      }
      setImages(results);
    }
    if (assets.assetsDataDelete === "success" && trigger) {
      const param = `?size=${size}&page=1&bucket=${bucket}&file_type=${
        type === "all" ? "" : type
      }`;
      dispatch(getAssetsDataThunk(param));
      dispatch(removeAssetsDataPost());
      setTrigger(false);
      setPrevImage(null);
      setActive(1);
    }
    setLessonPagination(assets.assetsData?.pagination);
  }, [assets]);

  function paginationSelect(newActive) {
    const param = `?size=${size}&page=${newActive}&bucket=${bucket}&file_type=${
      type === "all" ? "" : type
    }`;
    setActive(newActive);
    dispatch(getAssetsDataThunk(param));
  }

  function onSelectedImage(index, image) {
    var imagesNew = images.slice();
    var img = imagesNew[index];
    if (img.hasOwnProperty("isSelected")) img.isSelected = !img.isSelected;
    else img.isSelected = true;
    if (libraryHandler) {
      libraryHandler(img.src);
    }
    if (prevImage || prevImage == 0) {
      imagesNew[prevImage].isSelected = false;
    }
    if (!img.isSelected) setPrevImage(null);
    else setPrevImage(index);
    setImages(imagesNew);
  }

  // function onSelectPDF(index, image) {
  //   if (prevImage === index && selectedPDF !== -1) setSelectedPDF(-1);
  //   else setSelectedPDF(index);
  //   if (libraryHandler) {
  //     libraryHandler(image.src);
  //   }
  //   setPrevImage(index);
  // }

  function handleChangeBucket(e) {
    setBucket(e.target.value);
    setActive(1);
  }
  function handleChangeType(e) {
    setType(e.target.value);
    setActive(1);
  }

  function handlePageChange(e) {
    setPage(e.target.value);
  }

  function deleteDataHandler() {
    if (assetsData.items)
      dispatch(
        deleteAssetsDataThunk(
          `${assetsData.items[prevImage].file_key}?bucket=${bucket}`
        )
      );
  }

  function displayPopup(cancel, title, confirm) {
    MySwal.fire({
      title: title,
      showCancelButton: cancel,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: confirm,
    }).then((value) => {
      if (value.isConfirmed) {
        setTrigger(true);
        deleteDataHandler();
      }
    });
  }

  function searchHandler() {
    setActive(1);
    dispatch(
      getAssetsDataThunk(
        `?size=${size}&page=1&bucket=${bucket}&file_type=${
          type === "all" ? "" : type
        }${search ? "&keyword=" + search : ""}`
      )
    );
  }
  function onChangeSearch(e) {
    const newValue = e.target.value;
    setSearch(newValue);
  }

  return (
    <StyledWrapperMain>
      <Tabs defaultActiveKey="uploader" id="uncontrolled-tab-example">
        <Tab eventKey="uploader" title="Uploader">
          <FileUploader handleClose={handleClose} />
        </Tab>
        <Tab eventKey="library" title="Library">
          <>
            <Form>
              <Form.Group className="selector_bucket">
                <div className="mb-0 w-100 d-flex align-items-center">
                  Pilih Folder
                </div>
                <Form.Control
                  required
                  as="select"
                  type="select"
                  name="bucket"
                  id="bucket"
                  value={bucket}
                  onChange={handleChangeBucket}
                >
                  <option hidden value key="blankChoice">
                    Pilih Bucket...
                  </option>
                  {[
                    "lessons",
                    "thumbnails",
                    "assignments",
                    "certificates",
                    "corporate_materials",
                    "profile_pictures",
                    "jamboards",
                  ].map((item, index) => {
                    return (
                      <option key={index} value={item}>
                        {Capitalize(item.replace(/_/g, " "))}
                      </option>
                    );
                  })}
                </Form.Control>

                <div className="mb-0 w-75 d-flex align-items-center">
                  Pilih Tipe
                </div>
                <Form.Control
                  required
                  as="select"
                  type="select"
                  name="type"
                  id="type"
                  value={type}
                  onChange={handleChangeType}
                >
                  <option hidden value key="blankChoice">
                    Pilih Tipe...
                  </option>
                  {["all", "image", "document"].map((item, index) => {
                    return (
                      <option key={index} value={item}>
                        {Capitalize(item.replace(/_/g, " "))}
                      </option>
                    );
                  })}
                </Form.Control>
                <Form.Control
                  className="satukelas_input"
                  type="text"
                  id="search"
                  name="search"
                  placeholder="Cari File/Gambar"
                  value={search}
                  onChange={onChangeSearch}
                />
                <Button style={{ width: "200px" }} onClick={searchHandler}>
                  Cari
                </Button>
              </Form.Group>
              <div className="d-flex justify-content-between mt-4 mb-2">
                {type === "image" ? (
                  <Button
                    variant="danger"
                    disabled={!prevImage && prevImage != 0}
                    onClick={() =>
                      displayPopup(
                        true,
                        "Apakah anda yakin akan menghapus item terpilih?",
                        "Ya"
                      )
                    }
                  >
                    <FaTrashAlt className="mr-2 size-trash" />
                    Delete Selected
                  </Button>
                ) : null}
                {lessonPagination ? (
                  <Pagination
                    size={size}
                    totalRows={lessonPagination.totalItems}
                    totalPages={lessonPagination.totalPages}
                    paginationSelect={paginationSelect}
                    active={active}
                  />
                ) : null}
              </div>
            </Form>
            <div className="content_uploader">
              <>
                {images ? (
                  <ImageViewer
                    images={images}
                    selectingImage={onSelectedImage}
                  />
                ) : null}
              </>
              {/* {type === "image" ? (
                <>
                  {images ? (
                    <Gallery
                      images={images}
                      onSelectImage={onSelectedImage}
                      lightboxWidth={1536}
                      id="library"
                    />
                  ) : null}
                </>
              ) : (
                <div className="d-flex flex-wrap justify-content-center">
                  {images
                    ? images.map((value, index) => {
                        return (
                          <PDFLoader
                            url={value.src}
                            title={value.caption}
                            onSelected={() => onSelectPDF(index, value)}
                            selected={selectedPDF}
                            index={index}
                          />
                        );
                      })
                    : null}
                </div>
              )} */}
            </div>
          </>
        </Tab>
      </Tabs>

      {/* <Form.Group className="d-flex flex-row">
          <Form.Control
            required
            as="select"
            type="select"
            name="page"
            id="page"
            value={page}
            onChange={handlePageChange}
          >
            <option hidden value key="blankChoice">
              Pilih Halaman...
            </option>
            {["uploader", "library"].map((item, index) => {
              return (
                <option key={index} value={item}>
                  {Capitalize(item.replace(/_/g, " "))}
                </option>
              );
            })}
          </Form.Control>
        </Form.Group> */}
      {/* {page === "library" ? (
        
      ) : (
       
      )} */}
    </StyledWrapperMain>
  );
}

export default Library;

function generateImages(data) {
  let results = [];
  for (let i = 0; i < data.length; i++) {
    results.push({
      src: data.url,
      thumbnail: data.url,
      thumbnailWidth: data.width,
      thumbnailHeight: data.height,
      caption: data.bucket,
    });
  }
}

const StyledWrapperMain = styled.section`
  svg {
    width: 50px;
    height: 50px;
    fill: #28eebf;
  }
`;
