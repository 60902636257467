import React from "react";
import { Card } from "react-bootstrap";
function CardCoveredSkill({ items }) {
  return (
    <div className="course_front__deskripsi__wrapper">
      <div className="course_front__inner_title">Keterampilan Tercakup</div>

      <Card className="mb-4 padding_card">
        <div className="d-flex">
          {items?.length > 0
            ? items.map((e, i) => (
                <div key={i} className="skill_covered mb-2 mr-3">
                  {e}
                </div>
              ))
            : <div className="covered_skill__list mb-0">Data Belum Tersedia</div>}
        </div>
      </Card>
    </div>
  );
}

export default CardCoveredSkill;
