import React from "react";
import { Image } from "react-bootstrap";
import Tax from "./../../assets/images/tax.png";

export default function AverageNilai(props) {
  const { shadow, data } = props;
  return (
    <div className={`card-analitik ${shadow ? "_shadow" : ""}`}>
      <div className="total-anggota_title">Nilai Rata-Rata</div>
      <div className="avg-item">
        <div className="avg-img">
          <Image src={Tax} width={40} height={40} />
        </div>
        <div className="avg-info">
          <div className="avg-title">
            {data?.avg_post_test.toFixed(2) ? data.avg_post_test.toFixed(2) : 0}
          </div>
          <div className="avg-subtitle">Post Test</div>
        </div>
      </div>
      <div className="avg-item">
        <div className="avg-img">
          <Image src={Tax} width={40} height={40} />
        </div>
        <div className="avg-info">
          <div className="avg-title">
            {data?.avg_pre_test.toFixed(2) ? data.avg_pre_test.toFixed(2) : 0}
          </div>
          <div className="avg-subtitle">Pre Test</div>
        </div>
      </div>
      <div className="avg-item">
        <div className="avg-img">
          <Image src={Tax} width={40} height={40} />
        </div>
        <div className="avg-info">
          <div className="avg-title">
            {data?.avg_tryout.toFixed(2) ? data.avg_tryout.toFixed(2) : 0}
          </div>
          <div className="avg-subtitle">Try Out</div>
        </div>
      </div>
    </div>
  );
}
