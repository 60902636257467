import React from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import IconPlusGray from "../../assets/images/icon-plus-gray.svg";

export default function StudyGroupTab(props) {
  const {
    tab,
    setTab,
    totalItems,
    data,
    endpoint,
    setShow,
    setUserDataModal,
    type,
    conditionalCall,
    size,
    activeCourse,
    keywordCourse,
  } = props;
  const { course_id } = useParams();
  const users = useSelector((state) => state.users);
  const usersData = users.usersDataId;
  const companyId = usersData?.company_id;
  return (
    <>
      <div
        role={"button"}
        className={`d-flex align-items-center justify-content-center btn__choose-all pb-3 mr-3 col-1 ${
          tab === "all" ? "active" : ""
        }`}
        onClick={() => setTab("all")}
      >
        <span>All Student</span>
        <span className="notif__jml ml-2">
          {totalItems?.pagination?.totalItems}
        </span>
      </div>
      <div
        className="d-flex align-items-center w-100 wrapp__list-study-group"
        style={{ gap: "10px", overflowX: "auto" }}
      >
        {data?.length > 0 &&
          data?.map((item, index) => (
            <div
              key={index}
              role={"button"}
              className={`d-flex align-items-center justify-content-center btn__choose-all pb-3 mr-3 w-100 ${
                tab === `${item.group_name}` ? "active" : ""
              }`}
              onClick={() => {
                endpoint(
                  companyId,
                  course_id,
                  `?study_group_id=${item.study_group_id}`
                );
                setTab(item.group_name);
                // if (keywordCourse && size && activeCourse) {
                conditionalCall(
                  type,
                  `&size=${size}&page=${activeCourse}&keyword=${keywordCourse}&study_group_id=${item.study_group_id}`
                );
                // }
              }}
            >
              <span>{item.group_name}</span>
              <span className="notif__jml ml-2">{item.members_count}</span>
            </div>
          ))}
      </div>
      <div
        role={"button"}
        className={`d-flex align-items-center btn__choose-all pb-3 mr-3`}
        onClick={() => {
          setShow(true);
          setUserDataModal(totalItems.items);
        }}
      >
        <img src={IconPlusGray} alt="" />
      </div>
    </>
  );
}
