import React from "react";
import { Row, Col, Image } from "react-bootstrap";
import {
  FaClock,
  FaShareAlt,
  FaRegBookmark,
  FaTag,
  FaBookOpen,
} from "react-icons/fa";
import "./../assets/css/Courses/CourseDetailTitle.css";
import { convertHours } from "../helper/functions";
function BannerTopCard({ id, item, publishCourse }) {
  return (
    <>
      {id ? (
        <div>
          <Row className="align-items-baseline">
            <Col>
              <div className="course_front__title_detail">{item?.title}</div>
            </Col>
            <Col className="d-flex justify-content-end">
              <div className="btn_satukelas_share mr-2">
                <FaShareAlt />
              </div>
              <div className="btn_satukelas_bookmark">
                <FaRegBookmark className="fill-white" />
              </div>
            </Col>
          </Row>
          <div className="fw-600  color-gray mb-4 font-s">
            <Image className="course_detail__tutor_avatar" roundedCircle />
            <span className="text-black">
              {item?.instructors ? item.instructors[0].name : "Admin In-House"}
            </span>
          </div>
        </div>
      ) : (
        <div className="course_detail__container">
          <div className="course_detail__wrapper">
            <div className="course_detail__learning_path__wrapper">
              {item?.learning_paths &&
                item.learning_paths.map((e, i) => (
                  <div key={i} className="course_detail__learning_path">
                    {e?.title}
                  </div>
                ))}
            </div>
            <div className="course_detail__course_title">{item?.title}</div>
            <div className="course_detail__info__wrapper">
              <div className="course_detail__info__item">
                <FaClock className="course_detail__icon" />
                {item?.total_duration
                  ? convertHours(item?.total_duration)
                  : "-"}
              </div>
              <div className="course_detail__info__item">
                {item.lessons_count ? (
                  <>
                    <FaBookOpen className="course_detail__icon" />
                    {item.lessons_count} Pelajaran
                  </>
                ) : (
                  <>
                    {" "}
                    <FaBookOpen className="course_detail__icon" /> -
                  </>
                )}
              </div>
              <div className="course_detail__info__item d-flex">
                <FaTag className="course_detail__icon mt-1" />
                {item?.skills_covered?.length > 0
                  ? item.skills_covered.map((e, i) => (
                    <div key={i}>{e + ","}</div>
                  ))
                  : "-"}
              </div>
            </div>

            <div className="course_detail__summary__wrapper">
              {item?.summary ? item.summary : ""}
            </div>

            <div className="course_detail__tutor__wrapper d-flex">
              {item?.instructors?.length > 0 ? (
                item.instructors.map((e, i) => (
                  <div key={i} className="course_detail__tutor__item mr-3">
                    <Image
                      className="course_detail__tutor_avatar"
                      roundedCircle
                    />
                    <div className="course_detail__tutor_name">{e.name}</div>
                  </div>
                ))
              ) : (
                <div className="course_detail__tutor__item mr-3">
                  <Image
                    className="course_detail__tutor_avatar"
                    roundedCircle
                  />
                  <div className="course_detail__tutor_name">
                    Admin In-House
                  </div>
                </div>
              )}
            </div>
            {/* <div className="course_detail__cta_wrapper">
              <Button className="btn_satukelas_primary">
                DAPATKAN SEKARANG
              </Button>
              <div className="btn_satukelas_share">
                <FaShareAlt />
              </div>
              <div className="btn_satukelas_bookmark">
                <FaRegBookmark className="fill-white" />
              </div>
            </div> */}
          </div>
        </div>
      )}
    </>
  );
}

export default BannerTopCard;
