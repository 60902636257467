import React, { useEffect, useState } from "react";
import { Row, Col, Container, Button } from "react-bootstrap";
import { Document, Page } from "react-pdf";
import { Link, useParams } from "react-router-dom";

function PreviewFile() {
  const { id } = useParams();
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [render, setRender] = useState(false);
  const [dimensions, setDimensions] = useState({
    width: 0,
    height: 0,
  });

  function onClickButton(param) {
    if (param === "Prev") {
      let newPageNumber = pageNumber;
      if (newPageNumber <= 1) newPageNumber = numPages;
      else newPageNumber--;
      setPageNumber(newPageNumber);
    } else if (param === "Next") {
      let newPageNumber = pageNumber;
      if (newPageNumber >= numPages) newPageNumber = 1;
      else newPageNumber++;
      setPageNumber(newPageNumber);
    }
  }
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  useEffect(() => {
    setRender(true);
    window.addEventListener("resize", handleResize, false);
  }, []);

  useEffect(() => {
    handleResize();
  }, [render]);

  const handleResize = () => {
    setDimensions({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };
  function loadPDF() {
    const width = dimensions.width * 0.6;
    return (
      <Document
        file={`https://satukelas-assignments.ap-south-1.linodeobjects.com/${id}`}
        onLoadSuccess={onDocumentLoadSuccess}
      >
        <Page
          pageNumber={pageNumber}
          renderMode="canvas"
          renderAnnotationLayer={false}
          width={width}
        />
      </Document>
    );
  }
  return (
    <Container>
      <Row className="justify-content-center">
        <Col lg={9}>
          <>
            <Link to="/dashboard/home-work" className="mb-3">
              Halaman Sebelumnya
            </Link>
            {id.split(".")[1] === "pdf" ? (
              <>
                {render ? loadPDF() : null}
                <div>
                  <div className="my-4 d-flex justify-content-between">
                    <Button
                      className="lesson-pdf-btn"
                      onClick={() => onClickButton("Prev")}
                    >
                      {`<`}
                    </Button>
                    <Button
                      className="lesson-pdf-btn next"
                      onClick={() => onClickButton("Next")}
                    >
                      {`>`}
                    </Button>
                  </div>
                  <div className="text-center lesson-pdf-number">
                    Halaman {pageNumber}/{numPages}
                  </div>
                </div>
              </>
            ) : (
              <div className="text-center mt-5">
                <img
                  src={`https://satukelas-assignments.ap-south-1.linodeobjects.com/${id}`}
                />
              </div>
            )}
          </>
        </Col>
      </Row>
    </Container>
  );
}

export default PreviewFile;
