import { PromiseService } from "../../services/PromiseService";
import {
  ANALYTICS_GROUP_DATA_LINK,
  ANALYTICS_ASSIGNMENT_DATA_LINK,
  ANALYTICS_SUBMITTED_DATA_LINK,
  ANALYTICS_COURSE_STATS_DATA_LINK,
} from "../config/AnalyticsGroupConfig";
import {
  //get
  getAnalyticsGroupData,
  getAnalyticsGroupDataSuccess,
  getAnalyticsGroupDataIdSuccess,
  getAnalyticsGroupDataFail,
  //assigned
  getAnalyticsAssignedData,
  getAnalyticsAssignedDataSuccess,
  getAnalyticsAssignedDataIdSuccess,
  getAnalyticsAssignedDataFail,
  // submitted
  getAnalyticsSubmittedData,
  getAnalyticsSubmittedDataSuccess,
  getAnalyticsSubmittedDataIdSuccess,
  getAnalyticsSubmittedDataFail,

  getAnalyticsCourseStatsData,
  getAnalyticsCourseStatsDataSuccess,
  getAnalyticsCourseStatsDataIdSuccess,
  getAnalyticsCourseStatsDataFail,
} from "../actions/AnalyticsGroupAction";

export const getAnalyticsGroupDataThunk = (data) => async (dispatch) => {
  dispatch(getAnalyticsGroupData());
  if (data) {
    let url = `${ANALYTICS_GROUP_DATA_LINK}/${data}`;
    if (data.param) url = `${ANALYTICS_GROUP_DATA_LINK}${data.param}`;
    try {
      const response = await PromiseService.getData(url);
      if (response.status === 200 && response.data) {
        if (data.param) dispatch(getAnalyticsGroupDataSuccess(response.data));
        else dispatch(getAnalyticsGroupDataIdSuccess(response.data));
      } else {
        dispatch(getAnalyticsGroupDataFail(response));
      }
    } catch (error) {
      dispatch(getAnalyticsGroupDataFail(error));
    }
  } else {
    try {
      const response = await PromiseService.getData(ANALYTICS_GROUP_DATA_LINK);
      if (response.status === 200 && response.data) {
        dispatch(getAnalyticsGroupDataSuccess(response.data));
      } else {
        dispatch(getAnalyticsGroupDataFail(response));
      }
    } catch (error) {
      dispatch(getAnalyticsGroupDataFail(error));
    }
  }
};

export const getAnalyticsAssignedDataThunk = (data) => async (dispatch) => {
  dispatch(getAnalyticsAssignedData());
  if (data) {
    let url = `${ANALYTICS_ASSIGNMENT_DATA_LINK}/${data}`;
    if (data.param) url = `${ANALYTICS_ASSIGNMENT_DATA_LINK}${data.param}`;
    try {
      const response = await PromiseService.getData(url);
      if (response.status === 200 && response.data) {
        if (data.param)
          dispatch(getAnalyticsAssignedDataSuccess(response.data));
        else dispatch(getAnalyticsAssignedDataIdSuccess(response.data));
      } else {
        dispatch(getAnalyticsAssignedDataFail(response));
      }
    } catch (error) {
      dispatch(getAnalyticsAssignedDataFail(error));
    }
  } else {
    try {
      const response = await PromiseService.getData(
        ANALYTICS_ASSIGNMENT_DATA_LINK
      );
      if (response.status === 200 && response.data) {
        dispatch(getAnalyticsAssignedDataSuccess(response.data));
      } else {
        dispatch(getAnalyticsAssignedDataFail(response));
      }
    } catch (error) {
      dispatch(getAnalyticsAssignedDataFail(error));
    }
  }
};



export const getAnalyticsSubmittedDataThunk = (data) => async (dispatch) => {
  dispatch(getAnalyticsSubmittedData());
  if (data) {
    let url = `${ANALYTICS_SUBMITTED_DATA_LINK}/${data}`;
    if (data.param) url = `${ANALYTICS_SUBMITTED_DATA_LINK}${data.param}`;
    try {
      const response = await PromiseService.getData(url);
      if (response.status === 200 && response.data) {
        if (data.param)
          dispatch(getAnalyticsSubmittedDataSuccess(response.data));
        else dispatch(getAnalyticsSubmittedDataIdSuccess(response.data));
      } else {
        dispatch(getAnalyticsSubmittedDataFail(response));
      }
    } catch (error) {
      dispatch(getAnalyticsSubmittedDataFail(error));
    }
  } else {
    try {
      const response = await PromiseService.getData(
        ANALYTICS_SUBMITTED_DATA_LINK
      );
      if (response.status === 200 && response.data) {
        dispatch(getAnalyticsSubmittedDataSuccess(response.data));
      } else {
        dispatch(getAnalyticsSubmittedDataFail(response));
      }
    } catch (error) {
      dispatch(getAnalyticsSubmittedDataFail(error));
    }
  }
};

export const getAnalyticsCourseStatsDataThunk = (data) => async (dispatch) => {
  dispatch(getAnalyticsCourseStatsData(data));
  if (data) {
    let url = `${ANALYTICS_COURSE_STATS_DATA_LINK}/${data}`;
    if (data.param) url = `${ANALYTICS_COURSE_STATS_DATA_LINK}${data.param}`;
    try {
      const response = await PromiseService.getData(url);
      if (response.status === 200 && response.data) {
        if (data.param)
          dispatch(getAnalyticsCourseStatsDataSuccess(response.data));
        else dispatch(getAnalyticsCourseStatsDataIdSuccess(response.data));
      } else {
        dispatch(getAnalyticsCourseStatsDataFail(response));
      }
    } catch (error) {
      dispatch(getAnalyticsCourseStatsDataFail(error));
    }
  } else {
    try {
      const response = await PromiseService.getData(
        ANALYTICS_COURSE_STATS_DATA_LINK
      );
      if (response.status === 200 && response.data) {
        dispatch(getAnalyticsCourseStatsDataSuccess(response.data));
      } else {
        dispatch(getAnalyticsCourseStatsDataFail(response));
      }
    } catch (error) {
      dispatch(getAnalyticsCourseStatsDataFail(error));
    }
  }
};
