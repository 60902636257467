export const QUIZ_THEME_DATA_LINK = "quiz_themes";

//get
export const QUIZ_THEME_DATA = "QUIZ_THEME_DATA";
export const QUIZ_THEME_DATA_SUCCESS = "QUIZ_THEME_DATA_SUCCESS";
export const QUIZ_THEME_DATA_ID_SUCCESS = "QUIZ_THEME_DATA_ID_SUCCESS";
export const QUIZ_THEME_DATA_FAIL = "QUIZ_THEME_DATA_FAIL";
//live search
export const QUIZ_THEME_LIVE_SEARCH_DATA = "QUIZ_THEME_LIVE_SEARCH_DATA";
export const QUIZ_THEME_LIVE_SEARCH_DATA_SUCCESS =
  "QUIZ_THEME_LIVE_SEARCH_DATA_SUCCESS";
export const QUIZ_THEME_LIVE_SEARCH_DATA_FAIL =
  "QUIZ_THEME_LIVE_SEARCH_DATA_FAIL";
//post
export const QUIZ_THEME_DATA_POST = "QUIZ_THEME_DATA_POST";
export const QUIZ_THEME_DATA_POST_SUCCESS = "QUIZ_THEME_DATA_POST_SUCCESS";
export const QUIZ_THEME_DATA_POST_FAIL = "QUIZ_THEME_DATA_POST_FAIL";

//remove live search

export const QUIZ_THEME_LIVE_SEARCH_REMOVE = "QUIZ_THEME_LIVE_SEARCH_REMOVE";
