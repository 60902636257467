import React, { useState, useEffect } from "react";
import { Button, Form } from "react-bootstrap";
import DropdownMultiselect from "react-multiselect-dropdown-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getCoursesDataThunk } from "../redux/thunks/CoursesThunks";
import {
  getLearningPathDataThunk,
  putLearningPathDataThunk,
  postLearningPathDataThunk,
} from "../redux/thunks/LearningPathThunks";
import {
  CreateOption,
  CreateResult,
  CreateSelected,
  CheckIfEmpty,
  Clone,
} from "../helper/functions";
import styled from "styled-components";
import { SELECT_DEFAULT_STYLES } from "../helper/cssStyledComponent";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useHistory } from "react-router-dom";
const MySwal = withReactContent(Swal);

function AddLearningPath({ id, defaultItems, handleCancel }) {
  const [data, setData] = useState(defaultData);
  const [trigger, setTrigger] = useState(false);

  const handleChange = (e) => {
    const newData = { ...data };
    newData[e.target.id] = e.target.value;
    setData(newData);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
  };
  const handleChangeMultiSelect = (type, newValue) => {
    const newData = { ...data };
    newData[type] = newValue;
    setData(newData);
  };

  function uploadData() {
    if (CheckIfEmpty(data)) {
      displayPopup(false, "Mohon diisi semua", "Ok");
    } else {
      setTrigger(true);
      if (id) {
        let req = {};
        req.body = data;
        req.id = id;
        dispatch(putLearningPathDataThunk(req));
      } else dispatch(postLearningPathDataThunk(data));
    }
  }

  const dispatch = useDispatch();
  const history = useHistory();
  const courses = useSelector((state) => state.courses);
  const learning_paths = useSelector((state) => state.learning_paths);
  const courseData = courses.courseData;
  const LearningPathData = learning_paths.LearningPathData;

  useEffect(() => {
    dispatch(getCoursesDataThunk());
    dispatch(getLearningPathDataThunk());
  }, []);

  useEffect(() => {
    if (learning_paths.learningPathDataPost === "error" && trigger) {
      setTrigger(false);
      displayPopup(false, "Terjadi masalah saat submit data", "Ok");
    }
    if (learning_paths.learningPathDataPut === "error" && trigger) {
      setTrigger(false);
      displayPopup(false, "Terjadi masalah saat edit data", "Ok");
    }
    if (learning_paths.learningPathDataPut === "success" && trigger) {
      setTrigger(false);
      setData(defaultData);
      dispatch(getLearningPathDataThunk());
      handleCancel();
    }
    if (learning_paths.learningPathDataPost === "success" && trigger) {
      history.push("/dashboard/learning-path-table");
    }
  }, [learning_paths]);

  useEffect(() => {
    let newDefaultData = Clone(defaultData);
    if (defaultItems) {
      if (defaultItems.path_id) {
        Object.keys(newDefaultData).forEach(function (key) {
          if (newDefaultData[key] == null || newDefaultData[key] == 0) {
            newDefaultData[key] = defaultItems[key];
          }
        });
        setData(newDefaultData);
      }
    }
  }, [defaultItems]);

  function displayPopup(cancel, title, confirm) {
    MySwal.fire({
      title: title,
      showCancelButton: cancel,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: confirm,
    }).then((value) => {});
  }

  return (
    <StyledWrapperMain>
      {/* <Card className="card_container mb-4"> */}
      <div className="card_inside__padding ">
        <Form onSubmit={handleSubmit}>
          {/* title */}
          <Form.Group>
            <Form.Label className="title_label__input">Judul*</Form.Label>
            <Form.Control
              onChange={handleChange}
              id="title"
              value={data.title}
              className="mb-3"
              type="text"
            />
          </Form.Group>

          {/* summary */}
          <Form.Group>
            <Form.Label className="title_label__input">Ringkasan*</Form.Label>
            <Form.Control
              onChange={handleChange}
              id="summary"
              value={data.summary}
              className="mb-3"
              type="text"
            />
          </Form.Group>

          {/* course */}
          <Form.Group>
            <Form.Label className="title_label__input">Pelatihan*</Form.Label>{" "}
            {courseData.items ? (
              <DropdownMultiselect
                options={CreateOption(
                  courseData.items,
                  "course_ids",
                  "course_id",
                  "title"
                )}
                name="course_ids"
                placeholder="Pilih Pelatihan..."
                handleOnChange={(selected) => {
                  handleChangeMultiSelect(
                    "course_ids",
                    CreateResult(selected, "json", "course")
                  );
                }}
                selected={CreateSelected(data.course_ids, "course")}
              />
            ) : null}
          </Form.Group>

          {/* description */}
          <Form.Group className="mb-3">
            <div>
              <Form.Label className="title_label__input">Deskripsi*</Form.Label>
            </div>
            <Form.Control
              onChange={handleChange}
              id="description"
              value={data.description}
              as="textarea"
              rows={3}
            />
          </Form.Group>
          <div className="d-flex justify-content-end">
            {handleCancel ? (
              <Button
                type="submit"
                variant="light"
                className="my-4 mx-2"
                onClick={() => {
                  setData(defaultData);
                  handleCancel();
                }}
              >
                Batal
              </Button>
            ) : null}
            <Button
              type="submit"
              className="my-4 mx-2 px-3 py-2"
              onClick={uploadData}
            >
              {id ? "Edit" : <>{"+ Tambahkan"}</>}
            </Button>
          </div>
        </Form>
      </div>
      {/* </Card> */}
    </StyledWrapperMain>
  );
}

export default AddLearningPath;

const defaultData = {
  course_ids: [],
  description: "",
  summary: "",
  title: "",
};
const StyledWrapperMain = styled.section`
  ${SELECT_DEFAULT_STYLES}
`;
