import { Button, Card, Col } from "react-bootstrap";
import IconInfoWhite from "../../assets/images/icon-info-white.svg";
import IconCircleUp from "../../assets/images/circle-right-up.svg";
import IconPhoneRed from "../../assets/images/icon-phone-red.svg";
export default function MyMembership(props) 
{
    const { dataOverview, handleRedirectStudent } = props
    return <Card
    className="d-flex flex-wrap mb-2 align-items-center card__membership-organisasi position-relative"
    style={{ height: "145px" }}
  >
    <div className="col-12 d-flex flex-wrap align-items-center">
      <Col md={12} lg={5} className="text-center">
        <h3 className="card__title-membership-organisasi mb-0">
          {dataOverview.membership_expired}
        </h3>
        <span className="card__sub-title-membership-organisasi">
          Membership
        </span>
      </Col>
      <Col md={12} lg={7} className="px-0">
        <span className="card__title-right-membership-organisasi">
          Akan berakhir dalam kurun waktu <b> 30 Hari</b>
        </span>
        <Button
          onClick={handleRedirectStudent}
          variant="danger"
          className="d-flex align-items-center btn__add-membership mt-3"
        >
          <span className="">
            <img src={IconPhoneRed} alt="" />
          </span>
          Hubungi Sales
        </Button>
      </Col>
    </div>
    <span className="position-absolute info__right-up">
      <img src={IconInfoWhite} width={16} height={16} alt="" />
    </span>
    <span className="position-absolute circle__right-up">
      <img
        src={IconCircleUp}
        style={{ width: "120px", height: "105px" }}
        alt=""
      />
    </span>
  </Card>;
}