import { PromiseService } from "../../services/PromiseService";
import { TOPIC_DATA_LINK } from "../config/TopicConfig";
import {
  //get
  getTopicData,
  getTopicDataSuccess,
  getTopicDataIdSuccess,
  getTopicDataFail,
  //post
  postTopicData,
  postTopicDataSuccess,
  postTopicDataFail,
  // put
  putTopicData,
  putTopicDataSuccess,
  putTopicDataFail,
  // delete
  deleteTopicData,
  deleteTopicDataSuccess,
  deleteTopicDataFail,
} from "../actions/TopicActions";

export const postTopicDataThunk = (data) => async (dispatch) => {
  dispatch(postTopicData());
  const body = data;
  try {
    const response = await PromiseService.postData(TOPIC_DATA_LINK, body);
    if (response.status === 201 && response.data) {
      dispatch(postTopicDataSuccess(response.data));
    } else {
      dispatch(postTopicDataFail(response));
    }
  } catch (error) {
    dispatch(postTopicDataFail(error));
  }
};

export const getTopicDataThunk = (data) => async (dispatch) => {
  dispatch(getTopicData(data));
  if (data) {
    let url = `${TOPIC_DATA_LINK}/${data}`;
    if (data.param) url = `${TOPIC_DATA_LINK}${data.param}`;
    try {
      const response = await PromiseService.getData(url);
      if (response.status === 200 && response.data) {
        if (data.param) dispatch(getTopicDataSuccess(response.data));
        else dispatch(getTopicDataIdSuccess(response.data));
      } else {
        dispatch(getTopicDataFail(response));
      }
    } catch (error) {
      dispatch(getTopicDataFail(error));
    }
  } else {
    try {
      const response = await PromiseService.getData(TOPIC_DATA_LINK);
      if (response.status === 200 && response.data) {
        dispatch(getTopicDataSuccess(response.data));
      } else {
        dispatch(getTopicDataFail(response));
      }
    } catch (error) {
      dispatch(getTopicDataFail(error));
    }
  }
};

// put
export const putTopicDataThunk = (data) => async (dispatch) => {
  dispatch(putTopicData());
  const body = data.body;
  const id = data.id;
  try {
    const response = await PromiseService.putData(
      `${TOPIC_DATA_LINK}/${id}`,
      body
    );
    if (response.status === 201 && response.data) {
      dispatch(putTopicDataSuccess(response.data));
    } else {
      dispatch(putTopicDataFail(response));
    }
  } catch (error) {
    dispatch(putTopicDataFail(error));
  }
};

// delete
export const deleteTopicDataThunk = (data) => async (dispatch) => {
  dispatch(deleteTopicData());
  const id = data;
  try {
    const response = await PromiseService.deleteData(
      `${TOPIC_DATA_LINK}/${id}`
    );
    if (response.status === 200 && response.data) {
      dispatch(deleteTopicDataSuccess(response.data));
    } else {
      dispatch(deleteTopicDataFail(response));
    }
  } catch (error) {
    dispatch(deleteTopicDataFail(error));
  }
};
