import { PromiseService } from "../../services/PromiseService";
import { INDUSTRIES_DATA_LINK } from "../config/IndustriesConfig";
import {
  //get
  getIndustriesData,
  getIndustriesDataSuccess,
  getIndustriesDataIdSuccess,
  getIndustriesDataFail,
  //post
  postIndustriesData,
  postIndustriesDataSuccess,
  postIndustriesDataFail,
  // put
  putIndustriesData,
  putIndustriesDataSuccess,
  putIndustriesDataFail,
  // delete
  deleteIndustriesData,
  deleteIndustriesDataSuccess,
  deleteIndustriesDataFail,
} from "../actions/IndustriesActions";

// get
export const getIndustriesDataThunk = (data) => async (dispatch) => {
  dispatch(getIndustriesData(data));
  if (data) {
    let url = `${INDUSTRIES_DATA_LINK}/${data}`;
    if (data.param) url = `${INDUSTRIES_DATA_LINK}${data.param}`;
    try {
      const response = await PromiseService.getData(url);
      if (response.status === 200 && response.data) {
        if (data.param) dispatch(getIndustriesDataSuccess(response.data));
        else dispatch(getIndustriesDataIdSuccess(response.data));
      } else {
        dispatch(getIndustriesDataFail(response));
      }
    } catch (error) {
      dispatch(getIndustriesDataFail(error));
    }
  } else {
    try {
      const response = await PromiseService.getData(`${INDUSTRIES_DATA_LINK}?size=100`);
      if (response.status === 200 && response.data) {
        dispatch(getIndustriesDataSuccess(response.data));
      } else {
        dispatch(getIndustriesDataFail(response));
      }
    } catch (error) {
      dispatch(getIndustriesDataFail(error));
    }
  }
};

// post
export const postIndustriesDataThunk = (data) => async (dispatch) => {
  dispatch(postIndustriesData(data));
  const body = data;
  try {
    const response = await PromiseService.postData(INDUSTRIES_DATA_LINK, body);
    if (response.status === 201 && response.data) {
      dispatch(postIndustriesDataSuccess(response.data));
    } else {
      dispatch(postIndustriesDataFail(response));
    }
  } catch (error) {
    dispatch(postIndustriesDataFail(error));
  }
};

// put
export const putIndustriesDataThunk = (data) => async (dispatch) => {
  dispatch(putIndustriesData(data));
  const body = data.body;
  const id = data.id;
  try {
    const response = await PromiseService.putData(
      `${INDUSTRIES_DATA_LINK}/${id}`,
      body
    );
    if (response.status === 201 && response.data) {
      dispatch(putIndustriesDataSuccess(response.data));
    } else {
      dispatch(putIndustriesDataFail(response));
    }
  } catch (error) {
    dispatch(putIndustriesDataFail(error));
  }
};

// delete
export const deleteIndustriesDataThunk = (data) => async (dispatch) => {
  dispatch(deleteIndustriesData(data));
  const id = data;
  try {
    const response = await PromiseService.deleteData(
      `${INDUSTRIES_DATA_LINK}/${id}`
    );
    if (response.status === 200 && response.data) {
      dispatch(deleteIndustriesDataSuccess(response.data));
    } else {
      dispatch(deleteIndustriesDataFail(response));
    }
  } catch (error) {
    dispatch(deleteIndustriesDataFail(error));
  }
};
