import React from "react";
import { Modal } from "react-bootstrap";

export default function ResponseModal(props) {
  const { modalOpen, setModalOpen, modalContent } = props;
  return (
    <Modal
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      scrollable={true}
      centered
      className="custom-modal__response"
      show={modalOpen}
      onHide={() => setModalOpen(!modalOpen)}
    >
      <Modal.Body>{modalContent}</Modal.Body>
    </Modal>
  );
}
