import React, { useState, useEffect } from "react";
import { Row, Col, Button, Form, Card } from "react-bootstrap";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useDispatch, useSelector } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import {
  postWebinarDataThunk,
  putWebinarDataThunk,
  // getWebinarDataThunk,
} from "../redux/thunks/WebinarThunks";
import { SkValidate } from "./common/SkValidate";
import { useHistory } from "react-router-dom";
import { Clone } from "../helper/functions";
import moment from "moment";
import DatePicker from "react-datepicker";

const MySwal = withReactContent(Swal);

function AddWebinar({ topic_id, id, defaultItems, modalShow, setModalShow, handleCancel, fixedTitle }) {
  const defaultData = {
    topic_id: topic_id,
    title: fixedTitle ? fixedTitle : "",
    meeting_link: "",
    duration: 0,
    total_seats: 0,
  };
  const webinars = useSelector((state) => state.webinars);
  const dispatch = useDispatch();
  const [trigger, setTrigger] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [startDateApi, setStartDateApi] = useState();
  const [endDate, setEndDate] = useState(null);
  const [endDateApi, setEndDateApi] = useState();

  const topics = useSelector((state) => state.topics);
  const history = useHistory();
  const [data, setData] = useState(defaultData);
  const handleChange = (e) => {
    const newData = { ...data };
    e.target.value = e.target.id === "meeting_id" ? e.target.value.replace(/ /g, "") : e.target.value;
    newData[e.target.id] = e.target.value;
    setData(newData);
  };

  useEffect(() => {
    if (defaultItems?.time_ends) setEndDateApi(moment(defaultItems.time_ends).toDate());
    if (defaultItems?.time_starts) setStartDateApi(moment(defaultItems.time_starts).toDate());
  }, [defaultItems]);

  useEffect(() => {
    if (webinars.webinarDataPost === "error" && trigger) {
      setTrigger(false);
      displayPopup(false, "Terjadi masalah saat submit data", "Ok");
    }
    if (webinars.webinarDataPut === "error" && trigger) {
      setTrigger(false);
      displayPopup(false, "Terjadi masalah saat edit data", "Ok");
    }
    if (webinars.webinarDataPut === "success" && trigger) {
      handleCancel();
    }
    if (webinars.webinarDataPost === "success" && trigger) {
      if (topic_id) handleCancel();
      else history.push("/dashboard/table/webinar");
    }
  }, [webinars]);

  useEffect(() => {
    let newDefaultData = Clone(defaultData);
    if (defaultItems) {
      if (defaultItems.webinar_id) {
        Object.keys(newDefaultData).forEach(function (key) {
          if (newDefaultData[key] == null || newDefaultData[key] == 0) {
            newDefaultData[key] = defaultItems[key];
          }
        });
        setData(newDefaultData);
      }
    }
  }, [defaultItems]);

  const handleSubmitForm = (e) => {
    e.preventDefault();
    const form = e.target;
    const isValid = SkValidate(form, constraints);
    if (isValid) {
      setTrigger(true);
      if (id) {
        if (modalShow !== true) {
          return null;
        } else {
          let req = {};
          let payload = Clone(data);
          const newStartTime = moment(startDateApi).format("YYYY-MM-DD HH:mm");
          const newEndTime = moment(endDateApi).format("YYYY-MM-DD HH:mm");
          payload.time_starts = newStartTime + ":00";
          payload.time_ends = newEndTime + ":00";
          payload.duration = parseInt(payload.duration);
          payload.total_seats = parseInt(payload.total_seats);
          req.body = payload;
          req.id = id;
          dispatch(putWebinarDataThunk(req));
        }
      } else {
        const materialTemp = topics.topicDataId?.materials;
        const seq = materialTemp?.length > 0 ? materialTemp[materialTemp.length - 1].sequence + 1 : 0;
        const newData = {
          ...data,
          sequence: seq,
        };
        let payload = Clone(newData);
        const newStartTime = moment(startDate).format("YYYY-MM-DD HH:mm");
        const newEndTime = moment(endDate).format("YYYY-MM-DD HH:mm");
        payload.time_starts = newStartTime + ":00";
        payload.time_ends = newEndTime + ":00";
        payload.duration = parseInt(payload.duration);
        payload.total_seats = parseInt(payload.total_seats);

        if (payload.time_starts !== "Invalid date:00" && payload.time_ends !== "Invalid date:00") {
          dispatch(postWebinarDataThunk(payload));
        } else {
          displayPopup(false, "Waktu tidak boleh kosong", "Ok");
        }
      }
    }
  };

  function displayPopup(cancel, title, confirm) {
    MySwal.fire({
      title: title,
      showCancelButton: cancel,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: confirm,
    }).then((value) => {});
  }
  const constraints = {
    topic_id: {
      presence: true,
      length: {
        minimum: 3,
      },
    },
    title: {
      presence: true,
      length: {
        minimum: 3,
      },
    },
    meeting_link: {
      presence: true,
      length: {
        minimum: 3,
      },
    },
  };

  const ExampleCustomTimeInput = ({ date, value, onChange }) => <input value={value} onChange={(e) => onChange(e.target.value)} style={{ border: "solid 1px pink" }} />;
  return (
    <>
      <div className="">
        {/* <div className="course_create__title">
          {id ? "Edit Webinar" : "Buat Webinar"}
        </div> */}
      </div>
      <div className="">
        <Form onSubmit={handleSubmitForm} id="form-webinar">
          {/* Topic ID */}
          <Form.Group>
            <Form.Label className="title_label__input">Topik ID*</Form.Label>
            <Form.Control onChange={handleChange} type="text" id="topic_id" name="topic_id" value={data.topic_id} disabled={topic_id || id} placeholder="Masukkan ID Topik" />
            <div className="messages"></div>
          </Form.Group>

          {/* Title */}
          <Form.Group>
            <Form.Label className="title_label__input">Judul*</Form.Label>
            <Form.Control onChange={handleChange} type="text" id="title" name="title" value={data.title} placeholder="Masukkan Judul Webinar" disabled={fixedTitle} />
            <div className="messages"></div>
          </Form.Group>
          {/* Kapasitas Seats */}
          <Form.Group>
            <Form.Label className="title_label__input">Jumlah Seats*</Form.Label>
            <Form.Control onChange={handleChange} type="text" id="total_seats" name="total_seats" value={data.total_seats} placeholder="Masukkan Jumlah Seats" />
            <div className="messages"></div>
          </Form.Group>

          <Row>
            <Col sm={12}>
              {/* Meeting ID */}
              <Form.Group>
                <Form.Label className="title_label__input">ID Meeting*</Form.Label>
                <Form.Control onChange={handleChange} type="text" id="meeting_link" name="meeting_link" value={data.meeting_link} placeholder="Masukkan ID Meeting" />
                <div className="messages"></div>
              </Form.Group>
            </Col>
            {/* <Col sm={12} md={6}> */}
            {/* Password */}
            {/* <Form.Group>
                <Form.Label className="title_label__input">Password*</Form.Label>
                <Form.Control onChange={handleChange} type="text" id="passcode" name="passcode" value={data.passcode} placeholder="Masukkan Password" />
                <div className="messages"></div>
              </Form.Group>
            </Col> */}
          </Row>

          <Row>
            <Col sm={12} md={6}>
              {/* Time Starts */}
              <Form.Group>
                <Form.Label className="title_label__input">Waktu Mulai*</Form.Label>
                <DatePicker selected={id ? startDateApi : startDate} onChange={id ? (date) => setStartDateApi(date) : (date) => setStartDate(date)} showTimeInput customTimeInput={<ExampleCustomTimeInput />} dateFormat="yyyy-MM-dd HH:mm" />
                {/* <Form.Control
                    onChange={handleChange}
                    type="datetime-local"
                    id="time_starts"
                    name="time_starts"
                    value={
                      id
                        ? moment(data.time_starts)
                            .subtract(7, "hours")
                            .format("YYYY-MM-DDTHH:mm")
                        : moment(data.time_starts).format("YYYY-MM-DDTHH:mm")
                    }
                    placeholder="Masukkan Waktu Mulai"
                  /> */}
                <div className="messages"></div>
              </Form.Group>
            </Col>
            <Col sm={12} md={6}>
              {/* Time Ends */}
              <Form.Group>
                <Form.Label className="title_label__input">Waktu Selesai*</Form.Label>
                <DatePicker selected={id ? endDateApi : endDate} onChange={id ? (date) => setEndDateApi(date) : (date) => setEndDate(date)} showTimeInput customTimeInput={<ExampleCustomTimeInput />} dateFormat="yyyy-MM-dd HH:mm" />
                {/* <Form.Control
                    onChange={handleChange}
                    type="datetime-local"
                    id="time_ends"
                    name="time_ends"
                    value={moment(data.time_ends).format("YYYY-MM-DDTHH:mm")}
                    placeholder="Masukkan Waktu Selesai"
                  /> */}
                <div className="messages"></div>
              </Form.Group>
            </Col>
          </Row>

          {/* Duration */}
          {/* <Form.Group>
              <Form.Label className="title_label__input">
                Perkiraan Durasi* (detik)
              </Form.Label>
              <Form.Control
                onChange={handleChange}
                type="text"
                id="duration"
                name="duration"
                value={data.duration}
                placeholder="Masukkan Perkiraan Durasi"
              />
              <div className="messages"></div>
            </Form.Group> */}

          <div className="d-flex justify-content-end">
            {/* <Button type="submit" variant="light" onClick={handleCancel} className="my-4 mx-2">
              Batal
            </Button> */}
            <Button type="submit" className="my-4 mx-2">
              {id ? "Edit" : <>{"+ Tambahkan"}</>}
            </Button>
          </div>
        </Form>
      </div>
    </>
  );
}

export default AddWebinar;
