import React from "react";
import "../assets/css/Courses/imageViewer.css";

const fixedSize = 125;
function ImageViewer({ images, selectingImage }) {

  return (
    <div className="image-viewer-container">
      {images.length > 0 ? (
        <>
          {images.map((item, index) => {
            const ratio = getRatio(
              item.thumbnailHeight ? item.thumbnailHeight : 1,
              item.thumbnailWidth ? item.thumbnailWidth : 1
            ).split(":");
            let extension = "otherfile";
            if (item.ext.includes("doc")) extension = "docsfile";
            if (item.ext.includes("xls")) extension = "excelfile";
            if (item.ext.includes("pdf")) extension = "pdffile";
            return (
              <div
                className={`image-viewer-inner ${item.isSelected ? "image-viewer-inner-selected" : ""
                  }`}
                onClick={() => selectingImage(index, item)}
              >
                <div
                  style={{
                    textAlign: "center",
                    color: item.isSelected ? "#fff" : "transparent",
                    padding: "2px 0 2px 0",
                    letterSpacing: "4px",
                    fontWeight: "bold",
                    fontSize: "0.8em",
                    borderTopRightRadius: "8px",
                    borderTopLeftRadius: "8px",
                  }}
                >
                  SELECTED
                </div>
                {item.type === "image" ? (
                  <img
                    style={{
                      height: `${ratio[0] * fixedSize}px`,
                      width: `${ratio[1] * fixedSize}px`,
                    }}
                    src={item.thumbnail}
                  />
                ) : (
                  <div className="docs-wrapper">
                    <span className={`icon-file ${extension}`}></span>
                    <div className="docs-title">{item.caption}</div>
                  </div>
                )}
              </div>
            );
          })}
        </>
      ) : (
        <div>
          <div>Data tidak tersedia</div>
        </div>
      )}
    </div>
  );
}

export default ImageViewer;

function getRatio(a, b) {
  if (a > b) return `${a / b}:${b / b}`;
  else return `${a / a}:${b / a}`;
}
