import React, { useState, useEffect } from "react";
import { Button, Form } from "react-bootstrap";
import { SkValidate } from "./common/SkValidate";
import { useDispatch, useSelector } from "react-redux";
import {
  postAssignmentDataThunk,
  putAssignmentDataThunk,
  // getAssignmentDataThunk,
} from "../redux/thunks/AssignmentThunks";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useHistory } from "react-router-dom";
import { Clone } from "../helper/functions";
import MediaLibraryModal from "./common/MediaLibraryModal";

const MySwal = withReactContent(Swal);

function AddAssignment({ topic_id, id, defaultItems, handleCancel }) {
  const defaultData = {
    description: "",
    duration: 0,
    pass_points: 0,
    title: "",
    topic_id: topic_id,
    total_points: 0,
    tutor_upload: "",
  };
  const history = useHistory();
  const dispatch = useDispatch();
  const assignments = useSelector((state) => state.assignments);
  const topics = useSelector((state) => state.topics);
  const [trigger, setTrigger] = useState(false);
  const [data, setData] = useState(defaultData);
  const [modalShow, setModalShow] = useState(false);
  const [selectedLibary, setSelectedLibary] = useState();

  const constraints = {
    title: {
      presence: true,
      length: {
        minimum: 3,
      },
    },
    description: {
      presence: true,
      length: {
        minimum: 3,
      },
    },
    tutor_upload: {
      url: true,
    },
    // total_points: {
    //   presence: true,
    //   numericality: true,
    // },
    // pass_points: {
    //   presence: true,
    //   numericality: true,
    // },
    // duration: {
    //   presence: true,
    //   numericality: true,
    // },
  };

  const libraryHandler = (newValue) => {
    setSelectedLibary(newValue);
  };

  useEffect(() => {
    if (assignments.assignmentDataPost === "error" && trigger) {
      setTrigger(false);
      displayPopup(false, "Terjadi masalah saat submit data", "Ok");
    }
    if (assignments.assignmentDataPut === "error" && trigger) {
      setTrigger(false);
      displayPopup(false, "Terjadi masalah saat edit data", "Ok");
    }
    if (assignments.assignmentDataPut === "success" && trigger) {
      handleCancel();
    }
    if (assignments.assignmentDataPost === "success" && trigger) {
      if (topic_id) handleCancel();
      else history.push("/dashboard/table/assignment/");
    }
  }, [assignments]);

  useEffect(() => {
    let newDefaultData = Clone(defaultData);
    if (defaultItems) {
      if (defaultItems.assignment_id) {
        Object.keys(newDefaultData).forEach(function (key) {
          if (newDefaultData[key] == null || newDefaultData[key] == 0) {
            newDefaultData[key] = defaultItems[key];
          }
        });
        setData(newDefaultData);
      }
    }
  }, [defaultItems]);

  function displayPopup(cancel, title, confirm) {
    MySwal.fire({
      title: title,
      showCancelButton: cancel,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: confirm,
    }).then((value) => {
      console.log(value, "value");
    });
  }

  const handleSubmitForm = (e) => {
    e.preventDefault();
    const form = e.target;
    const isValid = SkValidate(form, constraints);
    if (isValid) {
      setTrigger(true);

      if (id) {
        let req = {};
        req.body = data;
        req.id = id;
        dispatch(putAssignmentDataThunk(req));
      } else {
        const materialTemp = topics.topicDataId?.materials;
        const seq =
          materialTemp?.length > 0
            ? materialTemp[materialTemp.length - 1].sequence + 1
            : 0;
        const newData = {
          ...data,
          sequence: seq,
        };
        dispatch(postAssignmentDataThunk(newData));
      }
    }
  };

  const handleShow = () => {
    setModalShow(true);
  };

  const handleChange = (e) => {
    const newData = { ...data };
    newData[e.target.id] =
      e.target.id === "total_points" ||
        e.target.id === "pass_points" ||
        e.target.id === "duration"
        ? parseInt(e.target.value)
        : e.target.value;
    setData(newData);
  };

  // const handleClose = () => {
  //   setModalShow(false);
  //   setSelectedLibary("");
  // };

  const handleThumbnail = () => {
    const newData = { ...data };
    newData["tutor_upload"] = selectedLibary;
    setData(newData);
    setModalShow(false);
  };

  return (
    <>
      <Form onSubmit={handleSubmitForm} id="form-assignment">
        {/* Topic ID */}
        <Form.Group>
          <Form.Label className="fw-600">Topik ID*</Form.Label>
          <Form.Control
            disabled={topic_id || id}
            onChange={handleChange}
            type="text"
            id="topic_id"
            name="topic_id"
            value={data.topic_id}
            placeholder="Masukkan ID Topik"
          />
          <div className="messages"></div>
        </Form.Group>

        {/* Title */}
        <Form.Group>
          <Form.Label className="fw-600">Judul Assignment*</Form.Label>
          <Form.Control
            onChange={handleChange}
            type="text"
            id="title"
            name="title"
            value={data.title}
            placeholder="Masukkan Judul Assignment"
          />
          <div className="messages"></div>
        </Form.Group>

        {/* Description */}
        <Form.Group>
          <Form.Label className="fw-600">Deskripsi*</Form.Label>
          <Form.Control
            onChange={handleChange}
            as="textarea"
            rows={3}
            id="description"
            name="description"
            value={data.description}
            placeholder="Masukkan Deskripsi Assignment"
          />
          <div className="messages"></div>
        </Form.Group>

        {/* Tutor Upload */}
        <Form.Group>
          <Form.Label className="fw-600">Upload File</Form.Label>
          <Form.Control
            type="text"
            id="tutor_upload"
            name="tutor_upload"
            placeholder="Upload File"
            value={data.tutor_upload}
            onClick={handleShow}
          />
          <div className="messages"></div>
        </Form.Group>

        {/* Total Points */}
        {/* <Form.Group>
          <Form.Label className="fw-600">Total Poin</Form.Label>
          <Form.Control
            onChange={handleChange}
            type="text"
            id="total_points"
            name="total_points"
            value={data.total_points}
            placeholder="Masukkan Total Points"
          />
          <div className="messages"></div>
        </Form.Group> */}

        {/* Passing Grade Points */}
        {/* <Form.Group>
          <Form.Label className="fw-600">Passing Grade Poin</Form.Label>
          <Form.Control
            onChange={handleChange}
            type="text"
            id="pass_points"
            name="pass_points"
            value={data.pass_points}
            placeholder="Masukkan Passing Grade Poin"
          />
          <div className="messages"></div>
        </Form.Group> */}

        {/* Durasi */}
        {/* <Form.Group>
          <Form.Label className="fw-600">Durasi</Form.Label>
          <Form.Control
            onChange={handleChange}
            type="text"
            id="duration"
            name="duration"
            value={data.duration}
            placeholder="Masukkan Durasi"
          />
          <div className="messages"></div>
        </Form.Group> */}

        <div className="d-flex justify-content-end">
          {handleCancel ? (
            <Button
              type="submit"
              variant="light"
              className="my-4 mx-2"
              onClick={() => {
                setData(defaultData);
                handleCancel();
              }}
            >
              Batal
            </Button>
          ) : null}
          <Button type="submit" className="my-4 mx-2">
            {id ? "Edit" : <>{"+ Tambahkan"}</>}
          </Button>
        </div>
      </Form>
      <MediaLibraryModal
        show={modalShow}
        setShow={setModalShow}
        title={"Pilih Dokumen/Gambar"}
        libraryHandler={libraryHandler}
        handleThumbnail={handleThumbnail}
        setSelectedLibary={setSelectedLibary}
      />
    </>
  );
}

export default AddAssignment;
