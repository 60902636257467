import React, { useEffect, useState } from "react";
import { Breadcrumb, Col, Container, Image, Row, Table } from "react-bootstrap";
import Person from "./../../assets/images/person.svg";
import Calendar from "./../../assets/images/calendar.png";
import ClockSmall from "./../../assets/images/clock.png";
import edit from "./../../assets/images/edit.svg";
import IconOverviewMan from "../../assets/images/icon-overview.svg";
import IconSearch from "../../assets/images/icon-search.svg";
import { useParams } from "react-router-dom";
import Home from "../../assets/images/breadcrumb.svg";
import Plus from "./../../assets/images/plus.png";
import webinarIcon from "./../../assets/images/webinar-icon.svg";
import consulIcon from "./../../assets/images/consultation-icon.svg";
import done from "./../../assets/images/done.svg";
import onprogress from "./../../assets/images/onprogress.svg";
import Filter from "./../../assets/images/filter.png";
import ReactECharts from "echarts-for-react";
import "./../../assets/css/Dashboard/AnalyticsPembelajaran.css";
import PaginationTemplate from "../../components/Layout/Pagination";
import CapaianBelajar from "../../components/AnalyticsCompany/CapaianBelajar";
import { AnalyticLearningService } from "../../http/AnalyticLearningHttp";
import { useSelector } from "react-redux";
import moment from "moment";

function AnalyticUser() {
  const [tabActive, setTabActive] = useState(1);
  const { user_id, type } = useParams();
  const [courseList, setCourseList] = useState(null);
  const [userDetail, setUserDetail] = useState(null);
  const [liveClassData, setLiveClassData] = useState(null);
  const [courseData, setCourseData] = useState(null);
  const [growthIndividual, setGrowthIndividual] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [keywordCourse, setKeywordCourse] = useState("");
  const users = useSelector((state) => state.users);
  const [sizeData, setSizeData] = useState(5);
  const [active, setActive] = useState(1);
  const usersData = users.usersDataId;
  const companyId = usersData?.company_id;

  const tableHeadCertification = [
    "No",
    "Judul Pelatihan",
    "Kategori",
    "Waktu Terdaftar",
    "Waktu Selesai",
    "Progress",
  ];
  const tableHeadOnDemand = [
    "No",
    "Judul Pelatihan",
    "Waktu Pendaftaran",
    "Progress",
    "Waktu Selesai",
    "Perubahan Nilai",
  ];
  const tableHeadLiveClass = [
    "No",
    "Judul Pelatihan",
    "Tipe",
    "Waktu Pendaftaran",
    "Waktu Pelaksanaan",
  ];

  const optionLiveClass = {
    tooltip: {
      trigger: "item",
    },
    legend: {
      orient: "vertical",
      right: "5%",
      top: "40%",
      bottom: "45%",
      show: false,
    },
    series: [
      {
        name: "Live Class",
        type: "pie",
        left: "0%",
        radius: ["45%", "80%"],
        avoidLabelOverlap: false,
        label: {
          show: true,
          position: "center",
          formatter: `{a|${liveClassData?.total}\n} {b|Pelatihan}`, // Use '{a|text}' to display the static text
          rich: {
            a: {
              fontSize: 16, // Set font size as needed
              fontWeight: "700", // Set font weight as needed
              color: "#4F4F4F",
              fontFamily: "Poppins",
            },
            b: {
              fontSize: 10, // Set font size as needed
              fontWeight: "400", // Set font weight as needed
              color: "#4F4F4F",
              fontFamily: "Poppins",
            },
          },
        },
        labelLine: {
          show: false,
        },
        data: liveClassData?.data,
        color: ["#55AA73", "#306CCC"],
      },
    ],
  };

  const optionCourse = {
    tooltip: {
      trigger: "item",
    },
    legend: {
      orient: "vertical",
      right: "5%",
      top: "40%",
      bottom: "45%",
      show: false,
    },
    series: [
      {
        name: "Course",
        type: "pie",
        left: "0%",
        radius: ["45%", "80%"],
        avoidLabelOverlap: false,
        label: {
          show: true,
          position: "center",
          formatter: `{a|${courseData?.total}\n} {b|Pelatihan}`, // Use '{a|text}' to display the static text
          rich: {
            a: {
              fontSize: 16, // Set font size as needed
              fontWeight: "700", // Set font weight as needed
              color: "#4F4F4F",
              fontFamily: "Poppins",
            },
            b: {
              fontSize: 10, // Set font size as needed
              fontWeight: "400", // Set font weight as needed
              color: "#4F4F4F",
              fontFamily: "Poppins",
            },
          },
        },
        labelLine: {
          show: false,
        },
        data: courseData?.data,
        color: ["#306CCC", "#55AA73"],
      },
    ],
  };

  useEffect(() => {
    setIsLoaded(true);
  }, []);

  useEffect(() => {
    if (user_id) individualStatistic(user_id);
  }, []);

  useEffect(() => {
    if (companyId && user_id) {
      getInfoUserCompanyData(user_id);

      if (tabActive === 1) {
        getListCertificate(
          `${companyId}?user_id=${user_id}&size=${sizeData}&page=${active}`
        );
      } else if (tabActive === 2) {
        getListCourses(
          `${companyId}?user_id=${user_id}&size=${sizeData}&page=${active}`
        );
      } else {
        getListLiveClass(
          `${companyId}?user_id=${user_id}&size=${sizeData}&page=${active}`
        );
      }
    }
  }, [tabActive, companyId]);

  const clickTab = (id) => {
    setTabActive(id);
    setActive(1);
    setSizeData(5);
    setKeywordCourse("");
  };

  const getInfoUserCompanyData = async (id) => {
    try {
      const response = await AnalyticLearningService.getInfoUserCompany(id);
      if (response) {
        setUserDetail(response);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const searchData = (e) => {
    setKeywordCourse(e.target.value);
  };

  const getListCertificate = async (param) => {
    try {
      const response = await AnalyticLearningService.getEnrolledCertificate(
        param
      );
      if (response) {
        setCourseList(response);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const individualStatistic = async (id) => {
    try {
      const response = await AnalyticLearningService.getIndividualStatisticUser(
        id
      );
      if (response) {
        let tmp = {
          data: [
            {
              name: "Live Konsultasi",
              value: response.live_classes?.live_consultation,
            },
            {
              name: "Webinar",
              value: response.live_classes?.webinar,
            },
          ],
          total: response?.live_class_total,
        };
        setLiveClassData(tmp);
        let tmpCourse = {
          data: [
            {
              name: "Telah Selesai",
              value: response.courses?.completed,
            },
            {
              name: "Berlangsung",
              value: response.courses?.ongoing,
            },
          ],
          total: response?.course_total,
        };
        setCourseData(tmpCourse);
        setGrowthIndividual(response);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getListCourses = async (param) => {
    try {
      const response = await AnalyticLearningService.getEnrolledCourseList(
        param
      );
      if (response) {
        setCourseList(response);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getListLiveClass = async (param) => {
    try {
      const response = await AnalyticLearningService.getEnrolledLiveClasses(
        param
      );
      if (response) {
        setCourseList(response);
      }
    } catch (err) {
      console.log(err);
    }
  };

  // const getSummaryLiveClass = async (id) => {
  //   try {
  //     const response =
  //       await AnalyticLearningService.getEnrolledLiveClassesSummary(id);
  //     if (response) {
  //       let tmp = {
  //         data: [
  //           {
  //             name: "Live Konsultasi",
  //             value: response.data?.live_consultation,
  //           },
  //           {
  //             name: "Webinar",
  //             value: response.data?.webinar,
  //           },
  //         ],
  //         total: response?.total_live_class,
  //       };
  //       setLiveClassData(tmp);
  //     }
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };

  const tableHeadShow = (tabActive) => {
    switch (tabActive) {
      case 1:
        return tableHeadCertification;
      case 2:
        return tableHeadOnDemand;
      case 3:
        return tableHeadLiveClass;
      default:
        break;
    }
  };

  const tableBodyShow = (tabActive) => {
    switch (tabActive) {
      case 1:
        return (
          courseList?.items?.length > 0 &&
          courseList?.items?.map((item, idx) => (
            <tr key={idx}>
              <td>{idx + 1}</td>
              <td className="_blue-text">{item.certificate}</td>
              <td>{item?.group_name}</td>
              <td>
                {item?.enrolled_at
                  ? moment(item?.enrolled_at).format("DD MMM YYYY HH:mm")
                  : "-"}
              </td>
              <td>20 Nov 2023 14:00</td>
              <td>
                {item.status?.includes("%") ? (
                  <div className="status-percentage">
                    <div className="percentage">
                      <div
                        style={{ width: `${parseInt(item.status)}%` }}
                        className="percentage-bar"
                      ></div>
                    </div>
                    <div className="current-percentage">
                      {parseInt(item.status)}%
                    </div>
                  </div>
                ) : (
                  <div
                    className={`status-course _capitalize ${
                      item.status == "claimed"
                        ? "_claim"
                        : item.status == "lulus ujian"
                        ? "_lulus"
                        : item.status == "delivered"
                        ? "_send"
                        : "_tidak-lulus"
                    }`}
                  >
                    {item.status}
                  </div>
                )}
              </td>
            </tr>
          ))
        );
      case 2:
        return (
          courseList?.items?.length > 0 &&
          courseList?.items?.map((item, idx) => (
            <tr key={idx}>
              <td>{idx + 1}</td>
              <td className="_blue-text">{item.course}</td>
              <td className="_ellipsis">
                {" "}
                {moment(item?.enrolled_at).format("DD MMM YYYY HH:mm")}
              </td>
              <td>
                <div className="status-current">
                  {item?.completed == 0 ? "Berlangsung" : "Selesai"}
                </div>
              </td>
              <td>
                {item?.completed_at
                  ? moment(item?.completed_at).format("DD MMM YYYY HH:mm")
                  : "-"}
              </td>
              <td>
                <span>
                  <Image src={Plus} width={24} height={24} />
                </span>
                {item?.growth ? item?.growth : 0}%
              </td>
            </tr>
          ))
        );
      case 3:
        return (
          courseList?.items?.length > 0 &&
          courseList?.items?.map((item, idx) => (
            <tr key={idx}>
              <td>{idx + 1}</td>
              <td
                className="_ellipsis _blue-text"
                style={{
                  maxWidth: "250px",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                }}
              >
                {item?.course}
              </td>
              <td>{item?.course_type}</td>
              <td>{moment(item?.enrolled_at).format("DD MMM YYYY HH:mm")}</td>
              <td>{moment(item?.hosted_at).format("DD MMM YYYY HH:mm")}</td>
            </tr>
          ))
        );
      default:
        break;
    }
  };

  function paginationChange(newActive) {
    setActive(newActive);
    dynamicCallEndpoint(tabActive, newActive);
  }

  const searchCourse = (type) => {
    dynamicCallEndpoint(type, "");
  };

  const changeSizeData = (e, type) => {
    setSizeData(e.target.value);
    dynamicCallEndpoint(type, "", e.target.value);
  };

  const dynamicCallEndpoint = (tabActive, page, size) => {
    let params = `${companyId}?size=${size ? size : sizeData}&page=${
      page ? page : 1
    }&user_id=${user_id}${keywordCourse ? `&keyword=${keywordCourse}` : ""}`;
    if (tabActive === 1) {
      getListCertificate(`${params}`);
    } else if (tabActive === 2) {
      getListCourses(`${params}`);
    } else {
      getListLiveClass(`${params}`);
    }
  };

  const renderGraphicPie = (
    leftIcon,
    leftLabel,
    rightIcon,
    rightLabel,
    data,
    option
  ) => {
    return (
      <div className="d-flex">
        <div className="mr-2">
          <ReactECharts
            style={{ height: "160px", width: "160px" }}
            option={option}
            theme={"light"}
          />
        </div>
        <div className="d-flex w-100" style={{ gap: "24px" }}>
          <div className="d-flex legend-wrap" style={{ gap: "10px" }}>
            <div className="type-legend blue">
              <Image src={leftIcon} />
            </div>
            <div>
              <div className="webinar-count_total">{data?.data[0]?.value}</div>
              <div className="type-course_title">{rightLabel}</div>
            </div>
          </div>
          <div className="d-flex legend-wrap" style={{ gap: "10px" }}>
            <div className="type-legend green">
              <Image src={rightIcon} />
            </div>
            <div>
              <div className="webinar-count_total">{data?.data[1]?.value}</div>
              <div className="type-course_title">{leftLabel}</div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <Container fluid>
      <h3 className="title__page-man mb-4">Detail Anggota</h3>
      <Breadcrumb>
        <Breadcrumb.Item href="#">
          <img src={Home} alt="home" />
        </Breadcrumb.Item>
        <Breadcrumb.Item className="breadcrumb__list-nav">
          Data & Analitik Pembelajaran
        </Breadcrumb.Item>{" "}
        <Breadcrumb.Item active className="breadcrumb__list-nav">
          {userDetail?.name}
        </Breadcrumb.Item>
      </Breadcrumb>
      <Row>
        <Col lg={9}>
          <div className="card-analitik _profile">
            <div className="d-flex align-items-center" style={{ gap: "32px" }}>
              <div className="profile">
                <Image
                  src={userDetail?.avatar ? userDetail?.avatar : Person}
                  height={140}
                  width={100}
                />
              </div>
              <div className="user-info">
                <div
                  className="position-absolute"
                  style={{ right: "20px", top: "20px" }}
                >
                  <Image src={edit} width={16} height={16} />
                </div>

                <div className="company-name">{userDetail?.company}</div>
                <div className="user-name">{userDetail?.name}</div>
                <div className="email-user">{userDetail?.email}</div>
                <div className="registered-info">
                  <div
                    className="d-flex align-items-center"
                    style={{ gap: "12px" }}
                  >
                    <div className="icon">
                      <Image src={Calendar} width={16} height={16} />
                    </div>
                    <div className="content-registered">
                      <div className="text">Terdaftar Sejak</div>
                      <div className="date">
                        {moment(userDetail?.created_at).format("DD MMMM YYYY")}
                      </div>
                    </div>
                  </div>
                  <div
                    className="d-flex align-items-center"
                    style={{ gap: "12px" }}
                  >
                    <div className="icon">
                      <Image src={ClockSmall} width={16} height={16} />
                    </div>
                    <div className="content-registered">
                      <div className="text">Terakhir Login</div>
                      <div className="date">
                        {moment(userDetail?.last_login).format("DD MMMM YYYY")}{" "}
                        | {moment(userDetail?.last_login).format("HH:mm")}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="membership-wrap">
                  <div className="membership-name">
                    {userDetail?.membership_title}
                  </div>
                  <div className="membership-date">
                    Hingga:{" "}
                    {moment(userDetail?.membership_expired).format(
                      "DD MMM YYYY"
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
        <Col lg={3}>
          <CapaianBelajar data={growthIndividual} type="person" />
        </Col>
        <Col lg={6}>
          <div className="card-analitik">
            <div className="registered-course__title">
              Live Class yang Diikuti
            </div>
            {liveClassData &&
              isLoaded &&
              renderGraphicPie(
                webinarIcon,
                "Webinar",
                consulIcon,
                "Live Consultation",
                liveClassData,
                optionLiveClass
              )}
          </div>
        </Col>
        <Col lg={6}>
          <div className="card-analitik">
            <div className="registered-course__title">
              Pelatihan yang Diikuti
            </div>
            {courseData &&
              isLoaded &&
              renderGraphicPie(
                done,
                "Berlangsung",
                onprogress,
                "Telah Selesai",
                courseData,
                optionCourse
              )}
          </div>
        </Col>
        <Col lg={12}>
          <div className="tab-analitik">
            <div
              onClick={() => clickTab(1)}
              className={`tab-item_analitik ${tabActive === 1 ? "active" : ""}`}
            >
              Program Sertifikasi
            </div>
            <div
              onClick={() => clickTab(2)}
              className={`tab-item_analitik ${tabActive === 2 ? "active" : ""}`}
            >
              On Demand
            </div>
            <div
              onClick={() => clickTab(3)}
              className={`tab-item_analitik ${tabActive === 3 ? "active" : ""}`}
            >
              Live Class
            </div>
          </div>
          <div className="card-analitik">
            <div className="total-anggota_title _larger">
              Program{" "}
              {tabActive === 1
                ? "Sertifikasi"
                : tabActive === 2
                ? "On Demand"
                : "Live Class"}{" "}
              yang diikuti
            </div>
            <div className="cta-table_analytic">
              <div className="position-relative">
                <div className="icon__search">
                  <img src={IconSearch} width={16} height={16} alt="" />
                </div>
                <input placeholder="Cari" onChange={searchData} />
              </div>
              <button onClick={() => searchCourse(tabActive)}>
                <span>
                  <Image src={Filter} width={16} height={16} />
                </span>
                Filter
              </button>
            </div>
            <div className="text-right w-100 shown-info">
              Menampilkan{" "}
              <span>
                <select onChange={(e) => changeSizeData(e, tabActive)}>
                  <option value="5">5</option>
                  <option value="10">10</option>
                  <option value="15">15</option>
                </select>
              </span>{" "}
              data/halaman
            </div>
            <Table responsive striped hover>
              <thead>
                <tr>
                  {tableHeadShow(tabActive).map((item, index) => (
                    <th key={index}>{item}</th>
                  ))}
                </tr>
              </thead>
              <tbody>{tableBodyShow(tabActive)}</tbody>
            </Table>
            <div className="d-flex align-items-center justify-content-between">
              <div className="footer-info">
                Terdapat{" "}
                <span>
                  {courseList?.pagination?.totalItems
                    ? courseList?.pagination?.totalItems
                    : 0}{" "}
                  Pelatihan
                </span>{" "}
                tersedia
              </div>
              {courseList?.items?.length > 0 && (
                <div>
                  <PaginationTemplate
                    size={sizeData}
                    totalRows={courseList?.pagination?.totalItems}
                    totalPages={courseList?.pagination?.totalPages}
                    paginationSelect={paginationChange}
                    active={active}
                  />
                </div>
              )}
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
}
export default AnalyticUser;
