import React, { useState, useEffect } from "react";
import { Button, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  postLessonDataThunk,
  putLessonDataThunk,
  // getLessonDataThunk,
} from "../redux/thunks/LessonThunks";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { SkValidate } from "./common/SkValidate";
import { useHistory } from "react-router-dom";
import { Clone, hmsToSecond, secondToHms } from "../helper/functions";
import MediaLibraryModal from "./common/MediaLibraryModal";

const MySwal = withReactContent(Swal);

function AddLesson({ topic_id, id, defaultItems, handleCancel }) {
  const defaultData = {
    content: "",
    title: "",
    topic_id: topic_id,
    material_type: "video_dyntube",
    material_url: "",
    duration: "00:00:00",
  };
  const dispatch = useDispatch();
  const lessons = useSelector((state) => state.lessons);
  const topics = useSelector((state) => state.topics);
  const [trigger, setTrigger] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [errorDuration, setErrorDuration] = useState(false);
  const [selectedLibary, setSelectedLibary] = useState();
  const [data, setData] = useState(defaultData);
  const lessonType = ["video_dyntube", "audio", "pdf", "video_h5p"];
  const history = useHistory();


  const handleChange = (e) => {
    const newData = { ...data };
    newData[e.target.id] = e.target.value;
    setData(newData);
  };

  useEffect(() => {
    if (lessons.lessonDataPost === "error" && trigger) {
      setTrigger(false);
      displayPopup(false, "Terjadi masalah saat submit data", "Ok");
    }
    if (lessons.lessonDataPut === "error" && trigger) {
      setTrigger(false);
      displayPopup(false, "Terjadi masalah saat edit data", "Ok");
    }
    if (lessons.lessonDataPut === "success" && trigger) {
      handleCancel();
    }
    if (lessons.lessonDataPost === "success" && trigger) {
      if (topic_id || id) handleCancel();
      else history.push("/dashboard/table/lesson/");
    }
  }, [lessons]);

  useEffect(() => {
    let newDefaultData = Clone(defaultData);
    if (defaultItems) {
      if (defaultItems.lesson_id) {
        Object.keys(newDefaultData).forEach(function (key) {
          if (newDefaultData[key] == null || newDefaultData[key] == 0) {
            newDefaultData[key] = defaultItems[key];
          }
        });
        // set duration from second to hms
        newDefaultData.duration = secondToHms(defaultItems.duration);
        setData(newDefaultData);
      }
    }
  }, [defaultItems]);

  function displayPopup(cancel, title, confirm) {
    MySwal.fire({
      title: title,
      showCancelButton: cancel,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: confirm,
    }).then((value) => {
      console.log(value, "value");
    });
  }

  const handleSubmitForm = (e) => {
    e.preventDefault();
    const form = e.target;
    const isValid = SkValidate(form, constraints);
    // set duration from hms to second
    const timeInSecond = hmsToSecond(data.duration);
    if (timeInSecond > 0) {
      setErrorDuration(false);
    } else {
      setErrorDuration(true);
    }

    if (timeInSecond > 0 && isValid) {
      setTrigger(true);
      if (id) {
        // edit lesson
        let req = {};
        req.body = { ...data, duration: timeInSecond };
        req.id = id;
        dispatch(putLessonDataThunk(req));
      } else {
        // create new lesson
        const materialTemp = topics.topicDataId?.materials;
        const seq =
          materialTemp?.length > 0
            ? materialTemp[materialTemp.length - 1].sequence + 1
            : 0;
        const newData = {
          ...data,
          sequence: seq,
          duration: timeInSecond,
        };
        dispatch(postLessonDataThunk(newData));
      }
    }
  };

  const handleThumbnail = () => {
    const newData = { ...data };
    newData["material_url"] = selectedLibary;
    setData(newData);
    setModalShow(false);
  };
  // const handleClose = () => {
  //   setModalShow(false);
  //   setSelectedLibary("");
  // };

  const handleShow = () => {
    if (data.material_type === "pdf") setModalShow(true);
  };
  const libraryHandler = (newValue) => {
    setSelectedLibary(newValue);
  };

  return (
    <div className="">
      <Form onSubmit={handleSubmitForm} id="form-lesson">
        {/* id topic */}
        <Form.Group>
          <Form.Label className="title_label__input">ID Topik*</Form.Label>
          <Form.Control
            onChange={handleChange}
            disabled={topic_id || id}
            className="mb-3 br-8"
            type="text"
            id="topic_id"
            name="topic_id"
            value={data.topic_id}
          />
          <div className="messages"></div>
        </Form.Group>

        {/* judul lesson */}
        <Form.Group>
          <Form.Label className="title_label__input">Judul Lesson*</Form.Label>
          <Form.Control
            onChange={handleChange}
            type="text"
            id="title"
            name="title"
            value={data.title}
          />
          <div className="messages"></div>
        </Form.Group>

        {/* content */}
        <Form.Group className="mb-3">
          <div>
            <Form.Label className="title_label__input">Deskripsi</Form.Label>
          </div>
          <Form.Control
            onChange={handleChange}
            as="textarea"
            rows={3}
            id="content"
            name="content"
            value={data.content}
          />
          <div className="messages"></div>
        </Form.Group>

        {/* tipe lesson */}
        <Form.Group>
          <Form.Label className="title_label__input">Tipe Lesson*</Form.Label>
          <Form.Control
            required
            as="select"
            type="select"
            id="material_type"
            name="material_type"
            onChange={handleChange}
          >
            {lessonType.map((item, i) => {
              return (
                <option value={item} key={i}>
                  {item}
                </option>
              );
            })}
          </Form.Control>
          <div className="messages"></div>
        </Form.Group>

        {/* url lesson */}
        <Form.Group>
          <Form.Label className="title_label__input">URL Lesson*</Form.Label>
          <Form.Control
            onChange={(e) => {
              if (data.material_type !== "pdf") handleChange(e);
            }}
            type="text"
            id="material_url"
            name="material_url"
            value={data.material_url}
            onClick={handleShow}
          />
          <div className="messages"></div>
        </Form.Group>

        {/* duration */}
        <Form.Group className={errorDuration && 'has-error'}>
          <Form.Label className="title_label__input">
            Duration (hh:mm:ss)
          </Form.Label>
          <Form.Control
            onChange={handleChange}
            type="text"
            id="duration"
            name="duration"
            value={data.duration}
          />
          {/* Manual Validation for check duration must more than 00:00:00 */}
          {errorDuration && data.duration === "00:00:00" &&
            <div className="message">
              <p class="help-block error">Duration must be more than 00:00:00</p>
            </div>
          }
        </Form.Group>

        <div className="d-flex justify-content-end">
          {handleCancel ? (
            <Button
              type="submit"
              variant="light"
              className="my-4 mx-2"
              onClick={() => {
                setData(defaultData);
                handleCancel();
              }}
            >
              Batal
            </Button>
          ) : null}
          <Button type="submit" className="my-4 mx-2">
            {id ? "Edit" : <>{"+ Tambahkan"}</>}
          </Button>
        </div>
      </Form>
      <MediaLibraryModal
        show={modalShow}
        setShow={setModalShow}
        title={"Pilih PDF"}
        libraryHandler={libraryHandler}
        handleThumbnail={handleThumbnail}
        setSelectedLibary={setSelectedLibary}
      />
    </div >
  );
}

export default AddLesson;

const constraints = {
  content: {
    length: {
      minimum: 3,
    },
  },
  title: {
    presence: true,
    length: {
      minimum: 3,
    },
  },
  topic_id: {
    presence: true,
    length: {
      minimum: 3,
    },
  },
  material_type: {
    presence: true,
    length: {
      minimum: 3,
    },
  },
  material_url: {
    presence: true,
    length: {
      minimum: 3,
    },
    url: true,
  }
};
