import React, { useEffect, useRef, useState } from "react";
import dayjs from "dayjs";
import moment from "moment";
import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Container,
  Form,
  Image,
  Modal,
  Row,
  Table,
} from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import errorIcon from "../../assets/images/error-icon.svg";
import IconFilter from "../../assets/images/icon-filter.svg";
import IconOverviewMan from "../../assets/images/icon-overview.svg";
import IconPlus from "../../assets/images/icon-plus.svg";
import IconSearch from "../../assets/images/icon-search.svg";
import IconTrash from "../../assets/images/icon-trash.svg";
import successIcon from "../../assets/images/success-icon.svg";
import warnIcon from "../../assets/images/warn-icon.svg";
import PaginationTemplate from "../../components/Layout/Pagination";
import InformationPenugasan from "../../components/ManagementPenugasan/InformationPenugasan";
import SummaryChartPenugasan from "../../components/ManagementPenugasan/SummaryChartPenugasan";
import ContentCourses from "../../components/ManagementTask/ContentCourses";
import ContentPerson from "../../components/ManagementTask/ContentPerson";
import ResponseModal from "../../components/common/ResponseModal";
import { TaskServices } from "../../http/TaskHttp";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import "../../assets/css/Dashboard/Management.css";

function ManagementPenugasan() {
  const colorPalete = ["#306CCC", "#EBB80F", "#CB3A31", "#55AA73"];
  const dispatch = useDispatch();
  const checkInput = useRef();
  const [typeActive, setTypeActive] = useState("all");
  const [selectAll, setSelectAll] = useState(false);
  const [isCanDelete, setIsCanDelete] = useState(true);
  const [specificId, setSpecificId] = useState([]);
  const [activeTask, setActiveTask] = useState(1);
  const [sizeDefault, setSizeDefault] = useState(5);
  const [sizeCourseDefault, setSizeCourseDefault] = useState(5);
  const [selectedTaskLength, setSelectedTaskLength] = useState(0);
  const [selectedPersonLength, setSelectedPersonLength] = useState(0);
  const [sizeUserDefault, setSizeUserDefault] = useState(5);
  const [show, setShow] = useState(false);
  const [showModalDeadline, setShowModalDeadline] = useState(false);
  const [showInternal, setShowInternal] = useState("internal");
  const [titleAssignee, setTitleAssignee] = useState("");
  const [statusModal, setStatusModal] = useState("select-course");
  const [listTask, setListTask] = useState(null);
  const [chartDataReady, setChartDataReady] = useState([]);
  const [searchCourse, setSearchCourse] = useState("");
  const [totalStatistic, setTotalStatistic] = useState(0);
  const [internalCourses, setInternalCourses] = useState(null);
  const [modalContentResponse, setModalContentResponse] = useState({
    title: "",
    subtitle: "",
    icon: "",
    ctaContent: "",
  });
  const [userListByCompany, setUserListByCompany] = useState(null);
  const [courseSelectedList, setCourseSelectedList] = useState([]);
  const [personSelectedList, setPersonSelectedList] = useState([]);
  const [selectedCourseLength, setSelectedCourseLength] = useState(0);
  const users = useSelector((state) => state.users);
  const userDetailInfo = users.usersDataId;
  const userDataList = users.usersData;

  // console.log(userDetailInfo);

  const requestAbortController = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [highlightedDays, setHighlightedDays] = useState([1, 2, 15]);
  const initialValue = dayjs(new Date());
  const [modalOpen, setModalOpen] = useState(false);
  const [dateAssignee, setDateAssignee] = useState(initialValue);

  useEffect(() => {
    if (userDataList) {
      setUserListByCompany(userDataList);
    }
    // setUserListByCompany
  }, [userDataList]);

  useEffect(() => {
    if (userDetailInfo?.company_id) {
      getPenugasanList(
        `?company_id=${userDetailInfo.company_id}&size=${sizeDefault}&page=${activeTask}`
      );
      getChartPenugasan(`/${userDetailInfo.company_id}`);
    }
  }, [userDetailInfo]);

  function assigneeCourse(id, title, category) {
    let courseSelected = [...courseSelectedList];
    if (courseSelected.find((x) => x.id == id)) {
      let idx = courseSelected.findIndex((x) => x.id == id);
      courseSelected.splice(idx, 1);
      setCourseSelectedList(courseSelected);
      setSelectedCourseLength(selectedCourseLength - 1);
    } else {
      let tmp = { id, title, category };
      courseSelected.push(tmp);
      setCourseSelectedList(courseSelected);
      setSelectedCourseLength(selectedCourseLength + 1);
    }
  }

  function assigneePerson(id, name, division) {
    let personSelected = [...personSelectedList];
    if (personSelected.find((x) => x.id == id)) {
      let idx = personSelected.findIndex((x) => x.id == id);
      personSelected.splice(idx, 1);
      setPersonSelectedList(personSelected);
      setSelectedPersonLength(selectedCourseLength - 1);
    } else {
      setSelectedPersonLength(selectedPersonLength + 1);
      let tmp = { id, name, division };
      personSelected.push(tmp);
      setPersonSelectedList(personSelected);
    }
  }

  const postPenugasan = async () => {
    let courseList = courseSelectedList.map((x) => x.id).toString();
    let personList = personSelectedList.map((x) => x.id).toString();
    let payload = {
      assignees: personList,
      courses: courseList,
      deadline: dateAssignee.format("YYYY-MM-DD HH:mm"),
      title: titleAssignee,
    };
    try {
      const response = await TaskServices.postTaskManagement(payload);
      if (response) {
        setShow(false);
        setStatusModal("select-course");
        getPenugasanList(
          `?company_id=${userDetailInfo.company_id}&size=${sizeDefault}&page=1`
        );
        setCourseSelectedList([]);
        setPersonSelectedList([]);
        setModalOpen(!modalOpen);
        setModalContentResponse({
          title: "Selamat!",
          subtitle: `Anda Telah Berhasil Membuat Penugasan dengan nama <b>${titleAssignee}</b>`,
          icon: <Image src={successIcon} />,
          action: (
            <>
              <Button
                className="btn-action__confirm success"
                onClick={() => setModalOpen(false)}
              >
                Tutup
              </Button>
            </>
          ),
        });
      }
    } catch (error) {
      setModalOpen(!modalOpen);
      setModalContentResponse({
        title: "Menugaskan Pelatihan Gagal",
        subtitle: `Anda gagal untuk menambahkan penugasan baru silahkan periksa kembali informasi yang Anda masukkan`,
        icon: <Image src={errorIcon} />,
        action: (
          <>
            <Button
              className="btn-action__confirm error"
              onClick={() => setModalOpen(false)}
            >
              Tutup
            </Button>
          </>
        ),
      });
    }
  };

  const getPenugasanList = async (param) => {
    try {
      const response = await TaskServices.getTaskManagement(param);
      if (response) {
        setListTask(response);
      }
    } catch (error) {}
  };
  const getChartPenugasan = async (id) => {
    try {
      const response = await TaskServices.getChartTaskManagement(id);
      if (response) {
        let seriesData = [
          {
            value: response.not_completed,
            itemStyle: {
              color: "#ebb80f",
            },
          },
          {
            value: response.ongoing,
            itemStyle: {
              color: "#306CCC",
            },
          },
          {
            value: response.completed,
            itemStyle: {
              color: "#55aa73",
            },
          },
          {
            value: response.overdue,
            itemStyle: {
              color: "#cb3a31",
            },
          },
        ];
        setChartDataReady(seriesData);
        setTotalStatistic(response.total);
      }
    } catch (error) {}
  };

  const deleteAssignment = async () => {
    setModalOpen(false);
    let payload = {
      assignments: specificId,
    };
    if (typeActive == "all") {
      const selectedIds = document.querySelectorAll(
        "._list-task input:checked"
      );
      const selectedValues = Array.from(selectedIds).map(
        (checkbox) => checkbox.value
      );
      payload.assignments = selectedValues;
    }
    try {
      const response = await TaskServices.removeTaskManagement(payload);
      if (response) {
        setModalContentResponse({
          title: "Selamat!",
          subtitle: `Anda Telah Berhasil Menghapus ${selectedTaskLength} dari List Penugasan`,
          icon: <Image src={successIcon} />,
          action: (
            <>
              <Button
                className="btn-action__confirm success"
                onClick={() => {
                  setModalOpen(false);
                  setSelectedTaskLength(0);
                }}
              >
                Tutup
              </Button>
            </>
          ),
        });
        setModalOpen(true);
        getPenugasanList(
          `?company_id=${userDetailInfo.company_id}&size=${sizeDefault}&page=${activeTask}`
        );
        setIsCanDelete(true);
      }
    } catch (err) {
      setModalOpen(!modalOpen);
      setModalContentResponse({
        title: "Menghapus Pelatihan Gagal",
        subtitle: `Anda gagal untuk menghapus penugasan silahkan coba ulangi lagi`,
        icon: <Image src={errorIcon} />,
        action: (
          <>
            <Button
              className="btn-action__confirm error"
              onClick={() => setModalOpen(false)}
            >
              Tutup
            </Button>
          </>
        ),
      });
    }
  };

  const deleteDataAssignmentConfirmation = () => {
    setModalOpen(!modalOpen);
    setModalContentResponse({
      title: "Apakah Anda Yakin untuk Menghapus Penugasan?",
      subtitle: `Anda akan menghapus ${selectedTaskLength} penugasan dari daftar Penugasan`,
      icon: <Image src={warnIcon} />,
      action: (
        <>
          <Button
            className="btn-action__confirm warn"
            onClick={deleteAssignment}
          >
            Ya
          </Button>
          <Button
            className="btn-action__confirm muted"
            onClick={() => setModalOpen(false)}
          >
            Tidak
          </Button>
        </>
      ),
    });
  };

  const modalContent = () => {
    return (
      <div>
        <div className="penugasan-status__icon">
          {modalContentResponse.icon}
        </div>
        <div className="penugasan-main__title">
          {modalContentResponse.title}
        </div>
        <div
          className="penugasan-main__subtitle"
          dangerouslySetInnerHTML={{ __html: modalContentResponse.subtitle }}
        />
        <div className="penugasan-cta__action">
          {modalContentResponse.action}
        </div>
      </div>
    );
  };

  function handleChange(e) {
    if (e.target.id === "date_assignee") {
      setDateAssignee(e.target.value);
    } else {
      setTitleAssignee(e.target.value);
    }
  }

  function handleShowModalInternal() {
    return statusModal == "select-course" ? (
      <ContentCourses
        show={show}
        type="add"
        assigneeCourse={assigneeCourse}
        selectedCourseLength={courseSelectedList?.length}
        courseSelectedList={courseSelectedList}
        changeSizeData={changeSizeData}
        sizeCourseDefault={sizeCourseDefault}
      />
    ) : statusModal == "select-person" ? (
      <ContentPerson
        show={show}
        type="add"
        assigneePerson={assigneePerson}
        statusModal={statusModal}
        selectedPersonLength={personSelectedList?.length}
        personSelectedList={personSelectedList}
        changeSizeData={changeSizeData}
        sizeUserDefault={sizeUserDefault}
      />
    ) : (
      <>
        <Row>
          <Col lg={7} className="mb-4">
            <h3
              className="title__modal-show text-left mb-2"
              style={{ color: "#4F4F4F" }}
            >
              Nama Penugasan
            </h3>
            <Form.Control
              placeholder="Masukan Nama Penugasan"
              aria-label="Username"
              aria-describedby="basic-addon1"
              id="title_assignee"
              onChange={handleChange}
              className="input__search-pelatihan w-100"
            />
          </Col>
          <Col lg={5}>
            <h3
              className="title__modal-show text-left"
              style={{ color: "#4F4F4F" }}
            >
              Waktu Deadline
            </h3>
            {/* <DemoContainer components={["DateTimePicker", "DateTimePicker"]}> */}
            <DateTimePicker
              label="Tentukan Waktu Deadline"
              value={dateAssignee}
              onChange={(newValue) => setDateAssignee(newValue)}
              slotProps={{ textField: { size: "small" } }}
            />
            {/* </DemoContainer> */}
          </Col>
          <Col lg={12}>
            <div
              className="mb-3"
              style={{ borderBottom: "1px solid #C7C7C7" }}
            ></div>
          </Col>
        </Row>
        {assignTask()}
      </>
    );
  }

  function assignTask() {
    return (
      <>
        <Row>
          <Col lg={12} className="d-flex">
            <div className="w-100">
              <div className="mb-3">
                <h3 className="title__modal-pelatihan">Daftar Pelatihan</h3>
                <span className="subtitle__modal-pelatihan">
                  {courseSelectedList?.length} pelatihan ditugaskan
                </span>
              </div>
              <Table responsive striped hover className="table-assign_outline">
                <thead className="table-assign_outline">
                  <th>No.</th>
                  <th className="pl-0">Judul Pelatihan</th>
                  <th className="pl-0">Kategori</th>
                </thead>
                <tbody>
                  {courseSelectedList ? (
                    courseSelectedList.map((data, index) => (
                      <tr key={index}>
                        <td className="text-center">
                          <b>{index + 1}</b>
                        </td>
                        <td className="pl-0">{data.title}</td>
                        <td className="pl-0">{data.category}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={3} className="text-center">
                        Data Belum Tersedia
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
            <div className="w-100">
              <div className="mb-3">
                <h3 className="title__modal-pelatihan">Daftar Anggota</h3>
                <span className="subtitle__modal-pelatihan">
                  {personSelectedList?.length} Anggota ditugaskan
                </span>
              </div>
              <Table responsive striped hover className="table-assign_outline">
                <thead className="table-assign_outline">
                  <th>No.</th>
                  <th className="pl-0">Nama Anggota</th>
                  <th className="pl-0">Divisi</th>
                </thead>
                <tbody>
                  {personSelectedList ? (
                    personSelectedList.map((data, index) => (
                      <tr key={index}>
                        <td className="text-center">
                          <b>{index + 1}</b>
                        </td>
                        <td className="pl-0">{data.name}</td>
                        <td className="pl-0">{data.division}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={3} className="text-center">
                        Data Belum Tersedia
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
          </Col>
          <Col lg={6}></Col>
        </Row>
      </>
    );
  }

  function handleSearchCourse(e) {
    setSearchCourse(e.target.value);
  }

  function nextStatus(id) {
    if (id == 1) {
      setStatusModal("select-course");
    } else if (id == 2) {
      setStatusModal("select-person");
    } else if (id == 3) {
      setStatusModal("select-task");
    } else if (id == 4) {
      postPenugasan();
    } else if (id == 0) {
      setShow(false);
    }
  }

  function changeSizeData(e, type) {
    if (type === "task") {
      setSizeDefault(e.target.value);
      getPenugasanList(
        `?company_id=${userDetailInfo.company_id}&size=${e.target.value}&page=1`
      );
    } else if (type === "course") {
      setSizeCourseDefault(e.target.value);
    } else {
      setSizeUserDefault(e.target.value);
    }
  }

  const selectAllAssigneeTask = (type, id) => {
    setTypeActive(type);
    if (type === "all") {
      setIsCanDelete(false);
      setSelectAll(!selectAll);
      let countCheck = document.getElementsByClassName("_list-task").length;
      setSelectedTaskLength(countCheck);
    } else {
      const newData = [...specificId];
      let lengthData = newData.length;
      if (newData.find((x) => x == id)) {
        let idx = newData.findIndex((x) => x == id);
        newData.splice(idx, 1);
        setSpecificId(newData);
        setSelectedTaskLength(lengthData - 1);
      } else {
        newData.push(id);
        setSpecificId(newData);
        setSelectedTaskLength(lengthData + 1);
      }
      setIsCanDelete(false);
    }
  };

  function paginationSelectTask(newActive) {
    setActiveTask(newActive);
    getPenugasanList(
      `?company_id=${userDetailInfo.company_id}&size=${sizeDefault}&page=${newActive}`
    );
  }

  // const handleMonthChange = (date) => {
  //   if (requestAbortController.current) {
  //     requestAbortController.current.abort();
  //   }

  //   setIsLoading(true);
  //   setHighlightedDays([]);
  // };
  // const handleDateClick = (date) => {
  //   // Update the state with the selected date
  //   // setSelectedDate(date);
  //   const formattedDate = moment(date).format("YYYY-MM-DD");
  //   setDateAssignee(date);
  // };

  const handleSearchAssignee = (e) => {
    e.preventDefault();
    getPenugasanList(
      `?company_id=${userDetailInfo.company_id}&size=${sizeDefault}&page=1&keyword=${searchCourse}`
    );
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Container>
        <h3 className="title__page-man mb-4">Penugasan Pelatihan</h3>
        <Breadcrumb>
          <Breadcrumb.Item href="#">
            <img src={IconOverviewMan} alt="" className="icon__breadcrumb" />
          </Breadcrumb.Item>
          <Breadcrumb.Item active className="breadcrumb__list-nav">
            Manajemen Penugasan
          </Breadcrumb.Item>
        </Breadcrumb>

        <Row>
          <InformationPenugasan />
          {chartDataReady?.length > 0 && (
            <SummaryChartPenugasan
              chartDataReady={chartDataReady}
              totalStatistic={totalStatistic}
            />
          )}

          <Col md={12} lg={12} className="mt-4">
            <Card className="p-3">
              <Row className="mt-3 mb-4">
                <Col lg={12} className="d-flex my-3 justify-content-between">
                  <div className="d-flex align-items-center">
                    <div className="position-relative">
                      <Form.Control
                        placeholder="Cari"
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                        onChange={handleSearchCourse}
                        className="input__search-pelatihan"
                      />
                      <div className="icon__search">
                        <img src={IconSearch} alt="" />
                      </div>
                    </div>
                    <Button
                      onClick={handleSearchAssignee}
                      variant="outline-secondary"
                      id="button-addon2"
                      className="ml-3 btn__filter-pelatihan"
                    >
                      <span className="mr-3">
                        <img src={IconFilter} alt="" />
                      </span>
                    </Button>
                  </div>
                  <div className="d-flex gap-3">
                    <Button
                      variant="danger"
                      disabled={isCanDelete}
                      onClick={deleteDataAssignmentConfirmation}
                      className="d-flex align-items-center btn__delete-penugasan mr-3"
                    >
                      <span className="mr-3">
                        <img src={IconTrash} alt="" />
                      </span>
                      Hapus
                    </Button>
                    <Button
                      variant="danger"
                      className="d-flex align-items-center btn__add-penugasan"
                      onClick={() => setShow(!show)}
                    >
                      <span className="mr-3">
                        <img src={IconPlus} alt="" />
                      </span>
                      Buat Penugasan
                    </Button>
                  </div>
                </Col>
                <Col
                  lg={12}
                  className="d-flex justify-content-between align-items-center"
                >
                  <div>
                    {selectedTaskLength == 0 ? (
                      ""
                    ) : (
                      <div className="d-flex align-items-center">
                        Terpilih <b className="mx-1">{selectedTaskLength}</b>{" "}
                        dari
                        <b className="mx-1">
                          {listTask?.pagination?.totalItems}
                        </b>{" "}
                        Penugasan
                      </div>
                    )}
                  </div>
                  <div className="d-flex align-items-center">
                    <span> Menampilkan</span>
                    <select
                      onChange={(e) => changeSizeData(e, "task")}
                      className="form-select mx-1"
                    >
                      <option value="5">5</option>
                      <option value="10">10</option>
                      <option value="15">15</option>
                    </select>
                    <span>data/halaman</span>
                  </div>
                </Col>
              </Row>
              <Table responsive striped hover>
                <thead>
                  <tr>
                    <th>
                      <Form.Check
                        onClick={() => selectAllAssigneeTask("all")}
                        inline
                        name="group1"
                        type="checkbox"
                      />
                    </th>
                    <th className="pl-0">Judul Penugasan</th>
                    <th className="pl-0">Jumlah Pelatihan</th>
                    <th className="pl-0">Assigned To</th>
                    <th className="pl-0">Tanggal Penugasan</th>
                    <th className="pl-0">Deadline</th>
                    <th className="pl-0">Status</th>
                  </tr>
                </thead>
                <tbody>
                  {listTask?.items?.length > 0 ? (
                    listTask?.items?.map((data, index) => (
                      <tr key={index}>
                        <td>
                          <Form.Check
                            inline
                            name="group1"
                            type="checkbox"
                            className="_list-task"
                            value={data.company_assignment_id}
                            checked={
                              typeActive == "individu"
                                ? specificId.find(
                                    (x) => x == data.company_assignment_id
                                  )
                                : selectAll
                            }
                            onClick={() =>
                              selectAllAssigneeTask(
                                "individu",
                                data.company_assignment_id
                              )
                            }
                          />
                        </td>
                        <td className="pl-0">
                          <Link
                            to={`/dashboard/management-penugasan/penugasan-pelatihan/${data.company_assignment_id}`}
                          >
                            {data.title}
                          </Link>
                        </td>
                        <td className="pl-0">{data.courses}</td>
                        <td className="pl-0">{data.assignees}</td>
                        <td className="pl-0">
                          {moment(data.created_at).format("DD MMM YYYY")}
                        </td>
                        <td className="pl-0">
                          {moment(data.deadline).format("DD MMM YYYY")}
                        </td>
                        <td className={`pl-0`}>
                          <h3
                            className={`status__list-data status__list-${
                              data.status == "belum selesai"
                                ? "belum_selesai"
                                : data.status == "berlangsung"
                                ? "berlangsung"
                                : data.status == "terlambat"
                                ? "terlambat"
                                : "selesai"
                            } `}
                          >
                            {data.status == "belum selesai"
                              ? "Belum Selesai"
                              : data.status == "berlangsung"
                              ? "Berlangsung"
                              : data.status == "terlambat"
                              ? "Terlambat"
                              : "Selesai"}
                          </h3>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={7} className="text-center">
                        Data Belum Tersedia
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
              {listTask?.items?.length > 0 && (
                <PaginationTemplate
                  size={sizeDefault}
                  totalRows={listTask?.pagination?.totalItems}
                  totalPages={listTask?.pagination?.totalPages}
                  paginationSelect={paginationSelectTask}
                  active={activeTask}
                />
              )}
            </Card>
          </Col>
        </Row>
        <ResponseModal
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          modalContent={modalContent()}
        />

        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          scrollable={true}
          centered
          show={show}
          onHide={() => setShow(!show)}
        >
          <Modal.Header className="d-block">
            <h3 className="title__modal-pelatihan">
              {statusModal == "select-course"
                ? "Pilih Pelatihan"
                : statusModal == "select-task"
                ? "Buat Penugasan"
                : "Pilih Anggota"}
            </h3>
            <span className="subtitle__modal-pelatihan">
              Tentukan Pelatihan yang akan Anda tugaskan
            </span>
          </Modal.Header>
          <Modal.Body>{handleShowModalInternal()}</Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn__back-show"
              onClick={() =>
                nextStatus(
                  statusModal == "select-course"
                    ? 0
                    : statusModal == "select-person"
                    ? 1
                    : 2
                )
              }
            >
              Kembali
            </button>
            <button
              onClick={() =>
                nextStatus(
                  statusModal == "select-course"
                    ? 2
                    : statusModal == "select-person"
                    ? 3
                    : statusModal === "select-task"
                    ? 4
                    : 1
                )
              }
              className="btn btn__cotinue-show"
            >
              {statusModal == "select-task" ? "Simpan" : "Lanjut"}
            </button>
          </Modal.Footer>
        </Modal>
        {/* <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          scrollable={true}
          centered
          show={showModalDeadline}
          onHide={showDeadlinePicker}
        >
          <Modal.Body>
            {" "}
            <div className="mb-4">
              <h3
                className="title__modal-show text-left"
                style={{ color: "#4F4F4F" }}
              >
                Waktu Deadline
              </h3>
              <Form.Control
                placeholder="Masukkan Deadline Tugas"
                aria-label="Username"
                // type="date"
                onClick={showDeadlinePicker}
                onChange={handleChange}
                aria-describedby="basic-addon1"
                className="input__search-pelatihan w-100"
              />
            </div>
            <DateCalendar
              // defaultValue={initialValue}
              loading={isLoading}
              onMonthChange={handleMonthChange}
              renderLoading={() => <DayCalendarSkeleton />}
              onChange={handleDateClick}
            />
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn__back-show" onClick={showDeadlinePicker}>
              Kembali
            </button>
            <button
              className="btn btn__cotinue-show"
              onClick={showDeadlinePicker}
            >
              Simpan
            </button>
          </Modal.Footer>
        </Modal> */}
      </Container>
    </LocalizationProvider>
  );
}

export default ManagementPenugasan;
