export const LESSON_DATA_LINK = "lessons";

//get
export const LESSON_DATA = "LESSON_DATA";
export const LESSON_DATA_SUCCESS = "LESSON_DATA_SUCCESS";
export const LESSON_DATA_ID_SUCCESS = "LESSON_DATA_ID_SUCCESS";
export const LESSON_DATA_FAIL = "LESSON_DATA_FAIL";

//post
export const LESSON_DATA_POST = "LESSON_DATA_POST";
export const LESSON_DATA_POST_SUCCESS = "LESSON_DATA_POST_SUCCESS";
export const LESSON_DATA_POST_FAIL = "LESSON_DATA_POST_FAIL";

// put
export const LESSON_DATA_PUT = "LESSON_DATA_PUT";
export const LESSON_DATA_PUT_SUCCESS = "LESSON_DATA_PUT_SUCCESS";
export const LESSON_DATA_PUT_FAIL = "LESSON_DATA_PUT_FAIL";

// delete
export const LESSON_DATA_DELETE = "LESSON_DATA_DELETE";
export const LESSON_DATA_DELETE_SUCCESS = "LESSON_DATA_DELETE_SUCCESS";
export const LESSON_DATA_DELETE_FAIL = "LESSON_DATA_DELETE_FAIL";
