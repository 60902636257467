import React, { useEffect, useState } from "react";
import ReactECharts from "echarts-for-react";
import moment from "moment";
import {
  Breadcrumb,
  Carousel,
  Col,
  Container,
  Image,
  Row,
} from "react-bootstrap";
import { useSelector } from "react-redux";
import Home from "../../assets/images/breadcrumb.svg";
import IconSearch from "../../assets/images/icon-search.svg";
import CapaianBelajar from "../../components/AnalyticsCompany/CapaianBelajar";
import { AnalyticLearningService } from "../../http/AnalyticLearningHttp";
import "./../../assets/css/Dashboard/AnalyticsPembelajaran.css";
import FileSend from "./../../assets/images/File send.png";
import Badge from "./../../assets/images/badge.png";
import FileList from "./../../assets/images/file-list.png";
import File from "./../../assets/images/file.png";
import Filter from "./../../assets/images/filter.png";
import Person from "./../../assets/images/person.png";
import TableAnalyticData from "../../components/AnalyticsCompany/TableAnalyticData";

function DataAnalyticPembelajaran() {
  const [totalRombel, setTotalRombel] = useState(0);
  const [totalUserCreated, setTotalUserCreated] = useState(0);
  const [enrolledCourse, setEnrolledCourse] = useState(null);
  const [graphData, setGraphData] = useState(null);
  const [legendData, setLegendData] = useState(null);
  const [summaryData, setSummaryData] = useState(null);
  const [statusCertification, setStatusCertification] = useState(null);
  const [dataLiveClass, setDataLiveClass] = useState(null);
  const [listCertification, setListCertification] = useState(null);
  const [dataCourses, setDataCourses] = useState(null);
  const [sizeData, setSizeData] = useState(5);
  const [sizeDataCourse, setSizeDataCourse] = useState(5);
  const [sizeCertification, setSizeCertification] = useState(5);
  const [active, setActive] = useState(1);
  const [activeCourse, setActiveCourse] = useState(1);
  const [activeCertificate, setActiveCertificate] = useState(1);
  const [keywordLiveClass, setKeywordLiveClass] = useState("");
  const [keywordCertificate, setKeywordCertificate] = useState("");
  const [keywordCourse, setKeywordCourse] = useState("");
  const [sertificationData, setSertificationData] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const users = useSelector((state) => state.users);
  const usersData = users.usersDataId;
  const companyId = usersData?.company_id;
  const hours = ["20-24", "16-20", "12-16", "08-12", "04-08", "00-04"];
  const days = ["M", "S", "S", "R", "K", "J", "S"];
  const paleteColor = ["#1E4380", "#2A5EB3", "#6290DA", "#97B5E6"];
  const data = [
    [0, 0, 5],
    [10, 1, 1],
    [0, 2, 0],
    [0, 3, 0],
    [0, 4, 0],
    [0, 5, 0],
    [0, 6, 0],
    [0, 7, 0],
    [0, 8, 0],
    [0, 9, 0],
    [0, 10, 0],
    [0, 11, 2],
    [0, 12, 4],
    [0, 13, 1],
    [0, 14, 1],
    [0, 15, 3],
    [0, 16, 4],
    [0, 17, 6],
    [0, 18, 4],
    [0, 19, 4],
    [0, 20, 3],
    [0, 21, 3],
    [0, 22, 2],
    [0, 23, 5],
    [1, 0, 7],
    [1, 1, 0],
    [1, 2, 0],
    [1, 3, 0],
    [1, 4, 0],
    [1, 5, 0],
    [1, 6, 0],
    [1, 7, 0],
    [1, 8, 0],
    [1, 9, 0],
    [1, 10, 5],
    [1, 11, 2],
    [1, 12, 2],
    [1, 13, 6],
    [1, 14, 9],
    [1, 15, 11],
    [1, 16, 6],
    [1, 17, 7],
    [1, 18, 8],
    [1, 19, 12],
    [1, 20, 5],
    [1, 21, 5],
    [1, 22, 7],
    [1, 23, 2],
    [2, 0, 1],
    [2, 1, 1],
    [2, 2, 0],
    [2, 3, 0],
    [2, 4, 0],
    [2, 5, 0],
    [2, 6, 0],
    [2, 7, 0],
    [2, 8, 0],
    [2, 9, 0],
    [2, 10, 3],
    [2, 11, 2],
    [2, 12, 1],
    [2, 13, 9],
    [2, 14, 8],
    [2, 15, 10],
    [2, 16, 6],
    [2, 17, 5],
    [2, 18, 5],
    [2, 19, 5],
    [2, 20, 7],
    [2, 21, 4],
    [2, 22, 2],
    [2, 23, 4],
    [3, 0, 7],
    [3, 1, 3],
    [3, 2, 0],
    [3, 3, 0],
    [3, 4, 0],
    [3, 5, 0],
    [3, 6, 0],
    [3, 7, 0],
    [3, 8, 1],
    [3, 9, 0],
    [3, 10, 5],
    [3, 11, 4],
    [3, 12, 7],
    [3, 13, 14],
    [3, 14, 13],
    [3, 15, 12],
    [3, 16, 9],
    [3, 17, 5],
    [3, 18, 5],
    [3, 19, 10],
    [3, 20, 6],
    [3, 21, 4],
    [3, 22, 4],
    [3, 23, 1],
    [4, 0, 1],
    [4, 1, 3],
    [4, 2, 0],
    [4, 3, 0],
    [4, 4, 0],
    [4, 5, 1],
    [4, 6, 0],
    [4, 7, 0],
    [4, 8, 0],
    [4, 9, 2],
    [4, 10, 4],
    [4, 11, 4],
    [4, 12, 2],
    [4, 13, 4],
    [4, 14, 4],
    [4, 15, 14],
    [4, 16, 12],
    [4, 17, 1],
    [4, 18, 8],
    [4, 19, 5],
    [4, 20, 3],
    [4, 21, 7],
    [4, 22, 3],
    [4, 23, 0],
    [5, 0, 2],
    [5, 1, 1],
    [5, 2, 0],
    [5, 3, 3],
    [5, 4, 0],
    [5, 5, 0],
    [5, 6, 0],
    [5, 7, 0],
    [5, 8, 2],
    [5, 9, 0],
    [5, 10, 4],
    [5, 11, 1],
    [5, 12, 5],
    [5, 13, 10],
    [5, 14, 5],
    [5, 15, 7],
    [5, 16, 11],
    [5, 17, 6],
    [5, 18, 0],
    [5, 19, 5],
    [5, 20, 3],
    [5, 21, 4],
    [5, 22, 2],
    [5, 23, 0],
    [6, 0, 1],
    [6, 1, 0],
    [6, 2, 0],
    [6, 3, 0],
    [6, 4, 0],
    [6, 5, 0],
    [6, 6, 0],
    [6, 7, 0],
    [6, 8, 0],
    [6, 9, 0],
    [6, 10, 1],
    [6, 11, 0],
    [6, 12, 2],
    [6, 13, 1],
    [6, 14, 3],
    [6, 15, 4],
    [6, 16, 0],
    [6, 17, 0],
    [6, 18, 0],
    [6, 19, 0],
    [6, 20, 1],
    [6, 21, 2],
    [6, 22, 2],
    [6, 23, 6],
  ].map(function (item) {
    return [item[1], item[0], item[2] || "-"];
  });

  const optionCompletedCourse = {
    tooltip: {
      trigger: "axis",
      axisPointer: {
        // Use axis to trigger tooltip
        type: "shadow", // 'shadow' as default; can also be 'line' or 'shadow'
      },
    },
    legend: {
      top: "5%",
      left: "center",
      itemWidth: 17,
      itemHeight: 17,
      data: legendData,
    },
    color: paleteColor.reverse(),
    grid: {
      left: "3%",
      right: "4%",
      bottom: "3%",
      containLabel: true,
    },
    xAxis: {
      type: "category",
      data: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "Mei",
        "Jun",
        "Jul",
        "Agu",
        "Sep",
        "Okt",
        "Nov",
        "Des",
      ],
    },
    yAxis: {
      show: false,
    },
    series: graphData,
  };

  const optionHeatMap = {
    tooltip: {
      position: "top",
    },
    grid: {
      height: "78%",
      width: "80%",
      left: "15%",
      top: "3%",
    },
    color: ["#C7C7C7", "#AAD4B9", "#6AB585", "#408056"],
    xAxis: {
      type: "category",
      data: days,
      splitArea: {
        show: false,
      },
      axisLine: {
        show: false, // Remove xAxis lines
      },
      nameTextStyle: {
        fontWeight: "bold",
        color: "#A6A6A6",
      },
      axisTick: {
        show: false,
      },
    },
    yAxis: {
      type: "category",
      data: hours,
      splitArea: {
        show: false,
      },
      axisLine: {
        show: false, // Remove xAxis lines
      },
      axisTick: {
        show: false,
      },
    },
    visualMap: {
      min: 0,
      max: 10,
      type: "piecewise",
      orient: "horizontal",
      left: "center",
      color: ["#408056", "#6AB585", "#AAD4B9", "#C7C7C7"],
      show: false,
    },
    series: [
      {
        name: "Punch Card",
        type: "heatmap",
        data: data,
        label: {
          show: false,
        },
        itemStyle: {
          borderColor: "#fff",
          borderWidth: 6,
          borderRadius: 8, // Set border radius here
        },
      },
    ],
  };

  useEffect(() => {
    setIsLoaded(true);
  }, []);

  useEffect(() => {
    if (companyId) {
      getTotalRombel(companyId);
      getTotalCreatedUser(companyId);
      getEnrolledCourseData(companyId);
      getSummaryCompleted(companyId);
      getGraphCompleteCourse(companyId);
      getCompleteCertificate(companyId);
      getStatusCertificate(companyId);
      getListLiveClass(`${companyId}?size=${sizeData}&page=${active}`);
      getListCourses(
        `${companyId}?size=${sizeDataCourse}&page=${activeCourse}`
      );
      getListCertificate(
        `${companyId}?size=${sizeCertification}&page=${activeCertificate}`
      );
    }
  }, [companyId]);

  const getTotalCreatedUser = async (id) => {
    try {
      const response = await AnalyticLearningService.getTotalUserCreated(id);
      if (response) {
        setTotalUserCreated(response);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getListCertificate = async (param) => {
    try {
      const response = await AnalyticLearningService.getEnrolledCertificate(
        param
      );
      if (response) {
        setListCertification(response);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getEnrolledCourseData = async (id) => {
    try {
      const response = await AnalyticLearningService.getEnrolledCourse(id);
      if (response) {
        setEnrolledCourse(response);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getTotalRombel = async (id) => {
    try {
      const response = await AnalyticLearningService.getTotalRombel(id);
      if (response) {
        setTotalRombel(response);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getSummaryCompleted = async (id) => {
    try {
      const response = await AnalyticLearningService.getSummaryCompletedCourse(
        id
      );
      if (response) {
        setSummaryData(response);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getGraphCompleteCourse = async (id) => {
    try {
      const response = await AnalyticLearningService.getTotalCompleteCourse(id);
      if (response) {
        let data = [];
        let legendData = [];

        response.forEach((item, idx) => {
          let existingItem = data.find((x) => x.name === item.course_type);

          // Ambil nilai month dan ubah menjadi indeks (dikurangi 1 karena indeks array dimulai dari 0)
          let index = parseInt(item.month) - 1;

          if (existingItem) {
            existingItem.data[index] += item.total_course;
          } else {
            let newData = {
              name: item.course_type,
              type: "bar",
              stack: "total",
              label: {
                show: false,
              },
              emphasis: {
                focus: "series",
              },
              data: Array(12).fill(0),
              order: idx,
            };
            newData.data[index] = item.total_course;

            data.push(newData);
            legendData.push(item.course_type);
          }
        });

        const isOnDemandExist = legendData.includes("On Demand");
        const isSertfikasiExist = legendData.includes("Sertifikasi");
        const isWebinarExist = legendData.includes("Webinar");
        const isLiveConsultationExist =
          legendData.includes("Live Consultation");

        if (!isOnDemandExist) {
          let onDemandData = {
            name: "On Demand",
            type: "bar",
            stack: "total",
            label: {
              show: false,
            },
            emphasis: {
              focus: "series",
            },
            data: Array(12).fill(0),
            order: legendData.length,
          };

          data.push(onDemandData);
          legendData.push("On Demand");
        }
        if (!isSertfikasiExist) {
          let sertfikasiData = {
            name: "Sertfikasi",
            type: "bar",
            stack: "total",
            label: {
              show: false,
            },
            emphasis: {
              focus: "series",
            },
            data: Array(12).fill(0),
            order: legendData.length,
          };

          data.push(sertfikasiData);
          legendData.push("Sertfikasi");
        }
        if (!isWebinarExist) {
          let webinarData = {
            name: "Webinar",
            type: "bar",
            stack: "total",
            label: {
              show: false,
            },
            emphasis: {
              focus: "series",
            },
            data: Array(12).fill(0),
            order: legendData.length, // Menggunakan panjang legendData sebagai order
          };

          data.push(webinarData);
          legendData.push("Webinar");
        }
        if (!isLiveConsultationExist) {
          let liveConsultation = {
            name: "Live Consultation",
            type: "bar",
            stack: "total",
            label: {
              show: false,
            },
            emphasis: {
              focus: "series",
            },
            data: Array(12).fill(0),
            order: legendData.length, // Menggunakan panjang legendData sebagai order
          };

          data.push(liveConsultation);
          legendData.push("Live Consultation");
        }

        const dataReverse = [...data].sort((a, b) => b.order - a.order);
        setLegendData(legendData);
        setGraphData(dataReverse);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getCompleteCertificate = async (id) => {
    try {
      const response =
        await AnalyticLearningService.getTotalCompleteCertification(id);
      if (response) {
        setSertificationData(response);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getStatusCertificate = async (id) => {
    try {
      const response = await AnalyticLearningService.getStatusCertification(id);
      if (response) {
        setStatusCertification(response);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const changeSizeData = (e, type) => {
    if (type === "live") {
      setSizeData(e.target.value);
      getListLiveClass(`${companyId}?size=${e.target.value}&page=1`);
    } else if (type === "certification") {
      setSizeCertification(e.target.value);
      getListCertificate(`${companyId}?size=${e.target.value}&page=1`);
    } else {
      setSizeDataCourse(e.target.value);
      getListCourses(`${companyId}?size=${e.target.value}&page=1`);
    }
  };

  const searchCourse = (type) => {
    if (type === "live") {
      getListLiveClass(
        `${companyId}?size=${sizeData}&page=1&keyword=${keywordLiveClass}`
      );
    } else if (type === "certification") {
      getListCertificate(
        `${companyId}?size=${sizeCertification}&page=1&keyword=${keywordCertificate}`
      );
    } else {
      getListCourses(
        `${companyId}?size=${sizeDataCourse}&page=1&keyword=${keywordCourse}`
      );
    }
  };

  const searchData = (e, type) => {
    if (type === "live") {
      setKeywordLiveClass(e.target.value);
    } else if (type === "certification") {
      setKeywordCertificate(e.target.value);
    } else {
      setKeywordCourse(e.target.value);
    }
  };

  const getListLiveClass = async (param) => {
    try {
      const response = await AnalyticLearningService.getEnrolledLiveClasses(
        param
      );
      if (response) {
        setDataLiveClass(response);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getListCourses = async (param) => {
    try {
      const response = await AnalyticLearningService.getEnrolledCourseList(
        param
      );
      if (response) {
        setDataCourses(response);
        // setDataLiveClass(response);
      }
    } catch (err) {
      console.log(err);
    }
  };

  function chartRender() {
    return (
      graphData &&
      legendData && (
        <ReactECharts
          style={{ height: "250px", width: "100%" }}
          option={optionCompletedCourse}
          theme={"light"}
        />
      )
    );
  }

  function paginationChange(newActive) {
    setActive(newActive);
    getListLiveClass(
      `${companyId}?size=${sizeData}&page=${newActive}${
        keywordLiveClass ? `&keyword=${keywordLiveClass}` : ""
      }`
    );
  }

  function paginationChangeCertification(newActive) {
    setActiveCertificate(newActive);
    getListCertificate(
      `${companyId}?size=${sizeCertification}&page=${newActive}${
        keywordCertificate ? `&keyword=${keywordCertificate}` : ""
      }`
    );
  }

  function paginationChangeCourse(newActive) {
    setActiveCourse(newActive);
    getListCourses(
      `${companyId}?size=${sizeDataCourse}&page=${newActive}${
        keywordCourse ? `&keyword=${keywordCourse}` : ""
      }`
    );
  }

  return (
    <Container fluid>
      <h3 className="title__page-man mb-4">Data & Analitik Pembelajaran</h3>
      <Breadcrumb>
        <Breadcrumb.Item href="#">
          <img src={Home} alt="home" />
        </Breadcrumb.Item>
        <Breadcrumb.Item active className="breadcrumb__list-nav">
          Data & Analitik Pembelajaran
        </Breadcrumb.Item>
      </Breadcrumb>
      <Row>
        <Col lg={12}>
          <Row>
            <Col lg={6}>
              <div
                className="d-flex"
                style={{ justifyContent: "space-between" }}
              >
                <div className="card-analitik _left">
                  <div className="ornament-card"></div>
                  <Carousel controls={false} fade>
                    <Carousel.Item className="">
                      <div className="total-anggota_title">
                        Total Anggota Terdaftar
                      </div>
                      <div className="d-flex justify-content-between">
                        <div className="total-anggota_count _green">
                          <span>
                            {totalUserCreated?.total_user
                              ? totalUserCreated?.total_user
                              : 0}
                          </span>{" "}
                          Anggota
                        </div>
                        <div className="wrapper-icon _green">
                          <Image src={Person} width={20} height={20} />
                        </div>
                      </div>
                      <div className="more-info">
                        *Terhitung sejak{" "}
                        {moment(totalUserCreated?.last_updated).format(
                          "DD MMMM YYYY"
                        )}
                      </div>
                    </Carousel.Item>
                    <Carousel.Item>
                      <div className="total-anggota_title">
                        Total Rombongan Belajar
                      </div>
                      <div className="d-flex justify-content-between">
                        <div className="total-anggota_count _green">
                          <span>
                            {totalRombel?.total_rombel
                              ? totalRombel?.total_rombel
                              : 0}
                          </span>{" "}
                          Rombel
                        </div>
                        <div className="wrapper-icon _green">
                          <Image src={Person} width={20} height={20} />
                        </div>
                      </div>
                      <div className="more-info">
                        Rata-rata{" "}
                        <span>
                          {totalRombel?.avg_total_member_per_rombel
                            ? totalRombel?.avg_total_member_per_rombel
                            : 0}
                        </span>{" "}
                        anggota per rombel
                      </div>
                    </Carousel.Item>
                  </Carousel>
                </div>
                <CapaianBelajar data={enrolledCourse} type="company" />
              </div>
              <div className="d-flex card-analitik" style={{ height: "unset" }}>
                <div className="registered-course">
                  <div className="d-flex justify-content-between">
                    <div>
                      <div className="registered-course__number">
                        {summaryData?.completed_courses
                          ? summaryData?.completed_courses
                          : 0}
                      </div>
                      <div className="registered-course__title">
                        Pelatihan Selesai
                      </div>
                    </div>
                    <div className="registered-course__wrap">
                      <Image src={File} width={20} height={20} />
                    </div>
                  </div>
                  <div className="registered-course__more">
                    Setiap anggota telah menyelesaikan rata-rata{" "}
                    {summaryData?.avg_courses_per_user
                      ? summaryData?.avg_courses_per_user
                      : 0}{" "}
                    pelatihan.
                  </div>
                </div>
                <div className="separator-vertikal"></div>
                <div className="individual-course">
                  <div className="d-flex justify-content-between">
                    <div>
                      <div className="individual-course__number">
                        {enrolledCourse?.total_enrolled ? enrolledCourse?.total_enrolled : 0}
                      </div>
                      <div className="individual-course__title">
                        Pelatihan Diikuti
                      </div>
                    </div>
                    <div className="individual-course__wrap">
                      <Image src={FileList} width={20} height={20} />
                    </div>
                  </div>
                  <div className="individual-course__more">
                    Setiap anggota mengikuti rata-rata{" "}
                    <span>
                      {enrolledCourse?.avg_enrolled
                        ? enrolledCourse?.avg_enrolled
                        : 0}
                    </span>{" "}
                    pelatihan.
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={6}>
              <div className="card-analitik _completed">
                <div className="ornament-card"></div>
                <div className="total-anggota_title">
                  Jumlah Pelatihan Selesai
                </div>
                <div>{isLoaded && chartRender()}</div>
              </div>
            </Col>
          </Row>
        </Col>
        <Col lg={6}>
          <div className="card-analitik _certification">
            <div className="total-anggota_title">Total Sertifikasi</div>
            <div className="d-flex" style={{ gap: "16px" }}>
              <div>
                <div className="certification-number _orange">
                  {sertificationData?.total_certification}
                </div>
                <div className="certification-text">Sertifikasi terdaftar</div>
              </div>
              <div>
                <div className="certification-number _orange">
                  {sertificationData?.total_certification_complete}
                </div>
                <div className="certification-text">
                  Ujian Sertifikasi Selesai
                </div>
              </div>
            </div>
            <div className="ornament-card_orange">
              <Image src={Badge} width={98} height={98} />
            </div>
          </div>
        </Col>
        <Col lg={6}>
          <div className="card-analitik _certification">
            <div className="total-anggota_title">Status Sertifikasi</div>
            <div className="d-flex" style={{ gap: "16px" }}>
              <div>
                <div className="certification-number _green ">
                  {statusCertification?.claimed_certificate
                    ? statusCertification.claimed_certificate
                    : 0}
                </div>
                <div className="certification-text">Sertifikasi Diklaim</div>
              </div>
              <div>
                <div className="certification-number _green ">
                  {statusCertification?.delivered_certificate
                    ? statusCertification.delivered_certificate
                    : 0}
                </div>
                <div className="certification-text">Sertifikat Terkirim</div>
              </div>
            </div>
            <div className="ornament-card_green">
              <Image src={FileSend} width={98} height={98} />
            </div>
          </div>
        </Col>
        <Col lg={12}>
          <div className="card-analitik">
            <div className="total-anggota_title _larger">
              Pelatihan yang diikuti
            </div>
            <div className="cta-table_analytic">
              <div className="position-relative">
                <div className="icon__search">
                  <img src={IconSearch} width={16} height={16} alt="" />
                </div>
                <input
                  placeholder="Cari"
                  onChange={(e) => searchData(e, "course")}
                  onKeyDown={(e) => e.key == "Enter" && searchCourse("course")}
                />
              </div>
              <button onClick={() => searchCourse("course")}>
                <span>
                  <Image src={Filter} width={16} height={16} />
                </span>
                Filter
              </button>
            </div>
            <div className="text-right w-100 shown-info">
              Menampilkan{" "}
              <span>
                <select onChange={(e) => changeSizeData(e, "course")}>
                  <option value="5">5</option>
                  <option value="10">10</option>
                  <option value="15">15</option>
                </select>
              </span>{" "}
              data/halaman
            </div>
            <TableAnalyticData
              head=""
              data={dataCourses}
              active={activeCourse}
              size={sizeDataCourse}
              type="course"
              paginationChangeCourse={paginationChangeCourse}
            />
          </div>
        </Col>
        <Col lg={12}>
          <div className="card-analitik">
            <div className="total-anggota_title _larger">
              Live Classes yang diikuti
            </div>
            <div className="cta-table_analytic">
              <div className="position-relative">
                <div className="icon__search">
                  <img src={IconSearch} width={16} height={16} alt="" />
                </div>
                <input
                  placeholder="Cari"
                  onChange={(e) => searchData(e, "live")}
                  onKeyDown={(e) => e.key == "Enter" && searchCourse("live")}
                />
              </div>
              <button onClick={() => searchCourse("live")}>
                <span>
                  <Image src={Filter} width={16} height={16} />
                </span>
                Filter
              </button>
            </div>
            <div className="text-right w-100 shown-info">
              Menampilkan{" "}
              <span>
                <select onChange={(e) => changeSizeData(e, "live")}>
                  <option value="5">5</option>
                  <option value="10">10</option>
                  <option value="15">15</option>
                </select>
              </span>{" "}
              data/halaman
            </div>
            <TableAnalyticData
              head=""
              data={dataLiveClass}
              active={active}
              size={sizeData}
              type="live"
              paginationChangeCourse={paginationChange}
            />
          </div>
        </Col>
        <Col lg={12}>
          <div className="card-analitik">
            <div className="total-anggota_title _larger">
              Program Sertifikasi yang diikuti
            </div>
            <div className="cta-table_analytic">
              <div className="position-relative">
                <div className="icon__search">
                  <img src={IconSearch} width={16} height={16} alt="" />
                </div>
                <input
                  placeholder="Cari"
                  onChange={(e) => searchData(e, "certification")}
                  onKeyDown={(e) =>
                    e.key == "Enter" && searchCourse("certification")
                  }
                />
              </div>
              <button onClick={() => searchCourse("certification")}>
                <span>
                  <Image src={Filter} width={16} height={16} />
                </span>
                Filter
              </button>
            </div>
            <div className="text-right w-100 shown-info">
              Menampilkan{" "}
              <span>
                <select onChange={(e) => changeSizeData(e, "certification")}>
                  <option value="5">5</option>
                  <option value="10">10</option>
                  <option value="15">15</option>
                </select>
              </span>{" "}
              data/halaman
            </div>
            <TableAnalyticData
              head=""
              data={listCertification}
              active={activeCertificate}
              size={sizeCertification}
              type="certificate"
              paginationChangeCourse={paginationChangeCertification}
            />
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default DataAnalyticPembelajaran;
