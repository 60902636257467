import React from "react";
import moment from "moment";
import { Image, Table } from "react-bootstrap";
import PaginationTemplate from "../Layout/Pagination";
import IconShort from "../../assets/images/icon-short.svg";
import Plus from "./../../assets/images/plus.png";
import { Link } from "react-router-dom";

export default function TableAnalyticData(props) {
  const { head, data, active, size, type, paginationChangeCourse, isOverview } =
    props;
  const typeHead = (type) => {
    switch (type) {
      case "course":
        return (
          <tr>
            <th>No</th>
            <th>Nama Anggota</th>
            <th>
              <div className="d-flex align-items-center gp-3">
                Judul Course{" "}
                <span className="ml-2" role="button">
                  <img src={IconShort} alt="" />
                </span>
              </div>
            </th>
            <th>
              {" "}
              <div className="d-flex align-items-center gp-3">
                Waktu Pendaftaran{" "}
                <span className="ml-2" role="button">
                  <img src={IconShort} alt="" />
                </span>
              </div>
            </th>
            <th>Progres</th>
            <th>
              <div className="d-flex align-items-center gp-3">
                Waktu Selesai{" "}
                <span className="ml-2" role="button">
                  <img src={IconShort} alt="" />
                </span>
              </div>
            </th>
            <th>Perubahan Nilai</th>
          </tr>
        );
      case "certificate":
        return (
          <tr>
            <th>No</th>
            <th>Judul Program</th>
            <th>Anggota</th>
            <th>Rombel</th>
            <th>Tanggal Pendaftaran</th>
            <th>Status</th>
          </tr>
        );
      case "live":
        return (
          <tr>
            <th>No</th>
            <th>Nama Anggota</th>
            <th>Judul Pelatihan</th>
            <th>Tipe</th>
            <th>Waktu Pendaftaran</th>
            <th>Waktu Pelaksanaan</th>
          </tr>
        );
      default:
        return <></>;
    }
  };

  const typeBody = (type) => {
    switch (type) {
      case "course":
        return (
          data?.items?.length > 0 &&
          data?.items?.map((item, idx) => (
            <tr key={idx}>
              <td>{idx + 1}</td>
              <td>
                <Link
                  to={`/dashboard/analitik-pembelajaran/user/${item?.user_id}`}
                  className="_blue-text"
                >
                  {item?.user}
                </Link>
              </td>
              <td
                style={{
                  maxWidth: "250px",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                }}
              >
                <Link
                  to={`/dashboard/analitik-pembelajaran/course/course/${item?.course_id}`}
                  className="_ellipsis _blue-text"
                >
                  {item?.course}
                </Link>
              </td>
              <td>{moment(item?.enrolled_at).format("DD MMM YYYY HH:mm")}</td>
              <td>
                <div
                  className={`status-current ${
                    item?.completed === 0 ? "_process" : "_done"
                  }`}
                >
                  {item?.completed === 0 ? "Berlangsung" : "Selesai"}
                </div>
              </td>
              <td>
                {item?.completed_at
                  ? moment(item?.completed_at).format("DD MMM YYYY HH:mm")
                  : "-"}
              </td>
              <td>
                <span>
                  <Image src={Plus} width={24} height={24} />
                </span>
                {item?.growth ? item?.growth : 0}%
              </td>
            </tr>
          ))
        );
      case "certificate":
        return (
          data?.items?.length > 0 &&
          data?.items?.map((item, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>
                <Link
                  to={`/dashboard/analitik-pembelajaran/course/certificate/${item?.course_id}`}
                  className="_ellipsis _blue-text"
                >
                  {item?.certificate}
                </Link>
              </td>
              <td>{item.user}</td>
              <td>{item.group_name ? item.group_name : "-"}</td>
              <td>{moment(item?.enrolled_at).format("DD MMM YYYY HH:mm")}</td>
              <td>
                {!item.status ? (
                  "-"
                ) : item.status?.includes("%") ? (
                  <div className="status-percentage">
                    <div className="percentage">
                      <div
                        style={{ width: `${parseInt(item.status)}%` }}
                        className="percentage-bar"
                      ></div>
                    </div>
                    <div className="current-percentage">
                      {parseInt(item.status)}%
                    </div>
                  </div>
                ) : (
                  <div
                    className={`status-course _capitalize ${
                      item.status == "claimed"
                        ? "_claim"
                        : item.status == "lulus ujian"
                        ? "_lulus"
                        : item.status == "delivered"
                        ? "_send"
                        : "_tidak-lulus"
                    }`}
                  >
                    {item.status}
                  </div>
                )}
                {/* <div className="status-bottom_table">Lulus Ujian</div> */}
              </td>
            </tr>
          ))
        );
      case "live":
        return data?.items?.map((item, index) => (
          <tr key={index}>
            <td>{index + 1}</td>
            <td>
              <Link
                to={`/dashboard/analitik-pembelajaran/user/${item?.user_id}`}
                className="_blue-text"
              >
                {item?.user}
              </Link>
            </td>
            <td
              style={{
                maxWidth: "250px",
                textOverflow: "ellipsis",
                overflow: "hidden",
              }}
            >
              <Link
                to={`/dashboard/analitik-pembelajaran/course/live-class/${item?.course_id}`}
                className="_ellipsis _blue-text"
              >
                {item?.course}
              </Link>
            </td>
            <td>{item?.course_type}</td>
            <td>{moment(item?.enrolled_at).format("DD MMM YYYY HH:mm")}</td>
            <td>{moment(item?.hosted_at).format("DD MMM YYYY HH:mm")}</td>
          </tr>
        ));
      default:
        return <></>;
    }
  };

  return (
    <>
      <Table responsive striped hover>
        <thead>{typeHead(type)}</thead>
        <tbody>{typeBody(type)}</tbody>
      </Table>
      <div className="d-flex align-items-center justify-content-between">
        <div className="footer-info">
          {/* <span>103 Pelatihan & Live Classes</span> */}
        </div>
        {isOverview ? (
          <></>
        ) : (
          <div>
            {data?.items?.length > 0 && (
              <PaginationTemplate
                size={size}
                totalRows={data?.pagination?.totalItems}
                totalPages={data?.pagination?.totalPages}
                paginationSelect={paginationChangeCourse}
                active={active}
              />
            )}
          </div>
        )}
      </div>
    </>
  );
}
