import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Form, Row, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import IconFilter from "../../assets/images/icon-filter.svg";
import IconSearch from "../../assets/images/icon-search.svg";
import { getUsersDataThunk } from "../../redux/thunks/UsersThunks";
import PaginationTemplate from "../Layout/Pagination";
export default function ContentPerson(props) {
  const {
    show,
    type,
    assigneePerson,
    selectedPersonLength,
    statusModal,
    personSelectedList,
    changeSizeData,
    sizeUserDefault,
  } = props;
  const resetForm = useRef();
  const dispatch = useDispatch();
  const [userListByCompany, setUserListByCompany] = useState(null);
  const [activePerson, setActivePerson] = useState(1);
  const [searchPerson, setSearchPerson] = useState("");
  const users = useSelector((state) => state.users);
  const userDetailInfo = users.usersDataId;
  const userDataList = users.usersData;

  useEffect(() => {
    if (userDataList) {
      setUserListByCompany(userDataList);
    }
  }, [userDataList]);

  useEffect(() => {
    if (statusModal == "select-person" || type == "edit") {
      dispatch(
        getUsersDataThunk({
          param: `?company_id=${userDetailInfo.company_id}&role=student&size=${sizeUserDefault}&page=${activePerson}`,
        })
      );
    }
  }, [statusModal, sizeUserDefault]);

  function handleSearchPerson(e) {
    setSearchPerson(e.target.value);
  }

  function filterDataPerson() {
    dispatch(
      getUsersDataThunk({
        param: `?company_id=${
          userDetailInfo.company_id
        }&role=student&size=${sizeUserDefault}&page=1${
          searchPerson ? `&keyword=${searchPerson}` : ""
        }`,
      })
    );
  }

  function paginationSelectEmployee(newActive) {
    setActivePerson(newActive);
    dispatch(
      getUsersDataThunk({
        param: `?company_id=${userDetailInfo.company_id}&role=student&size=${sizeUserDefault}&page=${newActive}`,
      })
    );
  }

  return (
    <>
      <Row className="mt-3 mb-4">
        <Col lg={12} className="d-flex my-3">
          <div className="position-relative">
            <Form.Control
              placeholder="Cari"
              aria-label="Username"
              aria-describedby="basic-addon1"
              className="input__search-pelatihan"
              ref={resetForm}
              onChange={handleSearchPerson}
            />
            <div className="icon__search">
              <img src={IconSearch} alt="" />
            </div>
          </div>
          <Button
            variant="outline-secondary"
            id="button-addon2"
            className="ml-3 btn__filter-pelatihan"
            onClick={filterDataPerson}
          >
            <span className="mr-3">
              <img src={IconFilter} alt="" />
            </span>
            Filter
          </Button>
        </Col>
        <Col
          lg={12}
          className="d-flex justify-content-between align-items-center"
        >
          <div className="d-flex align-items-center">
            Terpilih <b className="mx-1">{selectedPersonLength}</b> dari{" "}
            <b className="mx-1">{userListByCompany?.pagination?.totalItems}</b>{" "}
            Anggota
          </div>
          <div className="d-flex align-items-center">
            <span> Menampilkan</span>
            <select
              className="form-select mx-1"
              onChange={(e) => changeSizeData(e, "person")}
            >
              <option value="5" selected={sizeUserDefault == 5 ? true : false}>
                5
              </option>
              <option
                value="10"
                selected={sizeUserDefault == 10 ? true : false}
              >
                10
              </option>
              <option
                value="15"
                selected={sizeUserDefault == 15 ? true : false}
              >
                15
              </option>
            </select>
            <span>data/halaman</span>
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg={12}>
          <Table responsive striped hover>
            <thead>
              <th>
                <Form.Check inline name="group1" type="checkbox" />
              </th>
              <th className="pl-0">Nama Anggota</th>
              <th className="pl-0">Divisi</th>
            </thead>
            <tbody>
              {userListByCompany?.items?.length > 0 ? (
                userListByCompany?.items?.map((data, index) => (
                  <tr key={index}>
                    <td>
                      <Form.Check
                        onClick={() =>
                          assigneePerson(data.user_id, data.name, data.division)
                        }
                        inline
                        checked={personSelectedList?.find((x) =>
                          x.id == data.user_id ? true : false
                        )}
                        name="group1"
                        value={data.user_id}
                        type="checkbox"
                      />
                    </td>
                    <td className="pl-0">{data.name}</td>
                    <td className="pl-0">{data.division}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={3} className="text-center">
                    Data Belum Tersedia
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </Col>
      </Row>
      <Row className="justify-content-between px-2">
        <div className="d-flex align-items-center">
          Terdapat{" "}
          <b className="mx-1">
            {userListByCompany?.pagination?.totalItems} Anggota
          </b>{" "}
          tersedia
        </div>
        {userListByCompany?.items?.length > 0 && (
          <div>
            <PaginationTemplate
              size={sizeUserDefault}
              totalRows={userListByCompany?.pagination?.totalItems}
              totalPages={userListByCompany?.pagination?.totalPages}
              paginationSelect={paginationSelectEmployee}
              active={activePerson}
            />
          </div>
        )}
      </Row>
    </>
  );
}
