export const COURSES_DATA_LINK = "courses";
export const COURSE_COMPLETED_DATA_LINK = "analytics/completed_courses";

//get
export const COURSES_DATA = "COURSES_DATA";
export const COURSES_DATA_SUCCESS = "COURSES_DATA_SUCCESS";
export const COURSES_DATA_ID_SUCCESS = "COURSES_DATA_ID_SUCCESS";
export const COURSES_DATA_FAIL = "COURSES_DATA_FAIL";

//live search
export const COURSES_LIVE_SEARCH_DATA = "COURSES_LIVE_SEARCH_DATA";
export const COURSES_LIVE_SEARCH_DATA_SUCCESS =
  "COURSES_LIVE_SEARCH_DATA_SUCCESS";
export const COURSES_LIVE_SEARCH_DATA_FAIL = "COURSES_LIVE_SEARCH_DATA_FAIL";

//post
export const COURSES_DATA_POST = "COURSES_DATA_POST";
export const COURSES_DATA_POST_SUCCESS = "COURSES_DATA_POST_SUCCESS";
export const COURSES_DATA_POST_FAIL = "COURSES_DATA_POST_FAIL";

// put
export const COURSES_DATA_PUT = "COURSES_DATA_PUT";
export const COURSES_DATA_PUT_SUCCESS = "COURSES_DATA_PUT_SUCCESS";
export const COURSES_DATA_PUT_FAIL = "COURSES_DATA_PUT_FAIL";

//get completed
export const COURSES_COMPLETED_DATA = "COURSES_COMPLETED_DATA";
export const COURSES_COMPLETED_DATA_SUCCESS =
  "COURSES_COMPLETED_DATA_SUCCESS";
export const COURSES_COMPLETED_DATA_ID_SUCCESS =
  "COURSES_COMPLETED_DATA_ID_SUCCESS";
export const COURSES_COMPLETED_DATA_FAIL = "COURSES_COMPLETED_DATA_FAIL";

// delete
export const COURSES_DATA_DELETE = "COURSES_DATA_DELETE";
export const COURSES_DATA_DELETE_SUCCESS = "COURSES_DATA_DELETE_SUCCESS";
export const COURSES_DATA_DELETE_FAIL = "COURSES_DATA_DELETE_FAIL";

//remove
export const REMOVE_COURSE_DATA_ID = "REMOVE_COURSE_DATA_ID";
export const REMOVE_COURSE_DATA = "REMOVE_COURSE_DATA";
export const REMOVE_COURSE_DATA_PUT = "REMOVE_COURSE_DATA_PUT";
