import { satukelasApi } from "../constant/api";

export const AuthService = {
  postChangePassword(payload) {
    return satukelasApi.post(`/users/change_password`, payload);
  },
  postResetPassword(payload) {
    return satukelasApi.post(`/users/reset_password`, payload);
  },
  getResetPassword(param) {
    return satukelasApi.get(`users/send_email_reset${param}`);
  },
};
