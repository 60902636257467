import { Link } from "@mui/material";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import StudyGroupTab from "../AnalyticsCompany/StudyGroupTab";
import DataStudyGroup from "./DataStudyGroup";
import IconPlusWhite from "../../assets/images/icon-plus-white.svg";
import IconSearch from "../../assets/images/icon-search.svg";
import IconFilter from "../../assets/images/icon-filter.svg";
import IconEdit from "../../assets/images/icon-edit-pen.svg";
import IconTrash from "../../assets/images/icon-trash.svg";
import IconOrganisasi from "../../assets/images/icon-organisasi.svg";
import IconLocation from "../../assets/images/icon-location-gray.svg";
import IconPhone from "../../assets/images/icon-phone.svg";
import IconEmail from "../../assets/images/icon-email.svg";
export default function MemberList(props) {
    const {setKeyword, activePageAnggota, handleDeleteUser,handleDeleteMember, setActivePageAnggota, usersData, groupList, getDetailRombel, setShow, setUserDataModal, dataDel, allDataStudent, memberRombelList,  handleChangeSize, userData, handleCheck, handleViewDetail, formatDate, usersOrganisasiPagination, size, paginationManagOrganisasi, active} = props;
    return (
        <Card className="p-3">
            <Row className="mt-3 mb-4 px-3">
              <b className="d-block pl-3">Daftar Anggota</b>
              <Col lg={12} className="d-flex my-3 justify-content-between">
                <div className="d-flex align-items-center">
                  <div className="position-relative">
                    <Form.Control
                      placeholder="Cari"
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      className="input__search-pelatihan"
                      onChange={(e) => setKeyword(e.target.value)}
                    />
                    <div className="icon__search">
                      <img src={IconSearch} alt="" />
                    </div>
                  </div>
                  <Button
                    variant="outline-secondary"
                    id="button-addon2"
                    className="ml-3 btn__filter-pelatihan"
                  >
                    <span className="mr-3">
                      <img src={IconFilter} alt="" />
                    </span>
                    Filter
                  </Button>
                </div>
                <div className="d-flex gap-3">
                  <Button
                    variant="danger"
                    className="d-flex align-items-center btn__delete-penugasan mr-3"
                    onClick={
                      activePageAnggota === "all"
                        ? () => handleDeleteUser("student")
                        : handleDeleteMember
                    }
                  >
                    <span className="mr-3">
                      <img src={IconTrash} alt="" />
                    </span>
                    Hapus
                  </Button>
                  <Link
                    className="d-flex align-items-center btn__add-penugasan-organisasi"
                    to={"/dashboard/management-organisasi/add-member"}
                  >
                    <span className="">
                      <img src={IconPlusWhite} alt="" />
                    </span>
                    Tambah Student
                  </Link>
                </div>
              </Col>
              <Col
                lg={12}
                className="d-flex align-items-center mb-4"
                style={{ borderBottom: "1px solid #D9D9D9" }}
              >
                <StudyGroupTab
                  tab={activePageAnggota}
                  setTab={setActivePageAnggota}
                  totalItems={usersData}
                  data={groupList}
                  endpoint={getDetailRombel}
                  setShow={setShow}
                  setUserDataModal={setUserDataModal}
                  type="organisasi"
                />
              </Col>
              <Col
                lg={12}
                className="d-flex justify-content-between align-items-center"
              >
                <div className="d-flex align-items-center">
                  Terpilih <b className="mx-1">{dataDel.length}</b> dari
                  <b className="mx-1">
                    {activePageAnggota == "all"
                      ? allDataStudent
                      : memberRombelList?.length}
                  </b>
                  Anggota
                </div>
                <div className="d-flex align-items-center">
                  <span> Menampilkan</span>
                  <select
                    className="form-select mx-1"
                    onChange={handleChangeSize}
                  >
                    <option value="5">5</option>
                    <option value="10" selected>
                      10
                    </option>
                    <option value="15">15</option>
                  </select>
                  <span>data/halaman</span>
                </div>
              </Col>
            </Row>
            <div>
              <DataStudyGroup
                userData={userData}
                memberRombelList={memberRombelList}
                type={activePageAnggota}
                handleCheck={handleCheck}
                formatDate={formatDate}
                handleViewDetail={handleViewDetail}
                usersOrganisasiPagination={usersOrganisasiPagination}
                size={size}
                active={active}
                paginationManagOrganisasi={paginationManagOrganisasi}
              />
            </div>
          </Card>
    )
}