import ReactECharts from "echarts-for-react";
import moment from "moment/moment";
import React, { useEffect, useState } from "react";
import { Breadcrumb, Col, Form, Modal, Row, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import "../../../assets/css/Dashboard/ManagementOrganisasi.css";
import IconConfirmSucces from "../../../assets/images/icon-confirm-succes.svg";
import IconFilter from "../../../assets/images/icon-filter.svg";
import IconInfoBlue from "../../../assets/images/icon-info-blue.svg";
import IconOverviewMan from "../../../assets/images/icon-overview.svg";
import IconPlusWhite from "../../../assets/images/icon-plus-white.svg";
import IconSearch from "../../../assets/images/icon-search.svg";
import IconTrash from "../../../assets/images/icon-trash.svg";
import PaginationTemplate from "../../../components/Layout/Pagination";
import { AuthUsersDetail } from "../../../http/AuthUsersDetail";
import { StudyGroupServices } from "../../../http/StudyGroupHttp";
import {
  deleteUsersDataThunk,
  getUsersDataThunk,
} from "../../../redux/thunks/UsersThunks";
import IconConfirmSeru from "./../../../assets/images/icon-confirm-seru-yellow.svg";
import StudyGroupTab from "../../../components/AnalyticsCompany/StudyGroupTab";
import AdminList from "../../../components/ManagementOrganization/AdminList";
import DataStudyGroup from "../../../components/ManagementOrganization/DataStudyGroup";
import KuotaAnggota from "../../../components/ManagementOrganization/KuotaAnggota";
import RombelList from "../../../components/ManagementOrganization/RombelList";
import MyOrganization from "../../../components/ManagementOrganization/MyOrganization";
import MyMembership from "../../../components/ManagementOrganization/MyMembership";
import MemberList from "../../../components/ManagementOrganization/MemberList";
import { use } from "echarts";
const MySwal = withReactContent(Swal);

function ManagementOrganisasi() {
  const colorPalete = ["#306CCC", "#D6E2F5"];
  const [show, setShow] = useState(false);
  const [showInternal, setShowInternal] = useState("add");
  const [activePageAnggota, setActivePageAnggota] = useState("all");
  const [size, setSize] = useState(10);
  const [active, setActive] = useState(1);
  const [trigger, setTrigger] = useState(false);
  const [triggerRombel, setTriggerRombel] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [currentRombel, setCurrentRombel] = useState(null);
  const [keyword, setKeyword] = useState("");
  const [listAdmin, setListAdmin] = useState(null);
  const [nameRombel, setNameRombel] = useState("");
  const [keywordAdmin, setKeywordAdmin] = useState("");
  const [keywordStudyTab, setKeywordStudyTab] = useState("");
  const [deleteUser, setDeleteUser] = useState(null);
  const [usersOrganisasiPagination, setUserOrganisasiPagination] =
    useState(null);
  const users = useSelector((state) => state.users);
  const usersData = users.usersData;
  const allDataStudent = usersData?.pagination?.totalItems;
  const dispatch = useDispatch();
  const history = useHistory();
  const [userData, setUserData] = useState([]);
  const [memberRombelList, setMemberRombelList] = useState(null);
  const [dataShow, setDataShow] = useState(null);
  const [memberRombels, setMemberRombels] = useState(null);
  const [adminData, setAdminData] = useState([]);
  const [userDataModal, setUserDataModal] = useState([]);
  const [dataDel, setDataDel] = useState([]);
  const [dataChoose, setDataChoose] = useState([]);
  const [totalMembers, setTotalMembers] = useState({});
  const [kuotaMembers, setTotalKuota] = useState({});
  const [companies, setCompanies] = useState({});
  const [selectedName, setSelectedName] = useState(null);
  const [dataOverview, setDataOverview] = useState({
    membership_expired: 0,
  });
  const [groupList, setGroupList] = useState(null);
  const [studyGroupList, setStudyGroupList] = useState(null);
  const result = Math.round((totalMembers.value / kuotaMembers.value) * 100);

  useEffect(() => {
    setUserData(usersData.items);
    setUserDataModal(usersData?.items);
  }, [usersData]);

  useEffect(() => {
    if (dataChoose) {
      console.log(dataChoose);
    }
  }, [dataChoose]);

  useEffect(() => {
    if (usersData?.items?.length > 0 && isEdit && memberRombels) {
      const userIdsList1 = new Set(memberRombels.map((user) => user.user_id));
      const updatedList2 = userDataModal.map((user) => ({
        ...user,
        isChecked: userIdsList1.has(user.user_id),
      }));
      setUserDataModal(updatedList2);
    }
  }, [usersData, memberRombels, isEdit]);

  useEffect(() => {
    if (listAdmin) setAdminData(listAdmin?.items);
  }, [listAdmin]);

  useEffect(async () => {
    if (users.usersDataId.company_id) {
      try {
        const response = await AuthUsersDetail.getCompanyID(
          users?.usersDataId?.company_id
        );
        setTotalKuota({
          value: response.max_students,
        });
        setCompanies(response);
      } catch (error) {
        console.error(error);
      }
    }
  }, [users.usersDataId.company_id]);

  useEffect(async () => {
    if (users.usersDataId.company_id) {
      try {
        const response = await AuthUsersDetail.getKuotaMembers(
          users?.usersDataId?.company_id
        );

        setTotalMembers({
          value: response.members,
        });

        setUserData(usersData.items);
      } catch (error) {
        console.error(error);
      }
    }
  }, [users.usersDataId.company_id, usersData?.pagination?.totalItems]);

  useEffect(async () => {
    if (users.usersDataId.company_id) {
      getAdminList(
        `&size=${size}&page=${active}&company_id=${users?.usersDataId?.company_id}`
      );
      getStudyGroup(`?company_id=${users?.usersDataId?.company_id}`);
      getStudyGroupList(`?company_id=${users?.usersDataId?.company_id}`);
      try {
        const response = await AuthUsersDetail.getMembership(
          users?.usersDataId?.company_id
        );
        setDataOverview({
          membership_expired: response.count_membership_expired,
        });
      } catch (error) {
        console.error(error);
      }
    }
  }, [users.usersDataId.company_id]);

  useEffect(() => {
    if (users.usersDataId.company_id) {
      const timeOutId = setTimeout(
        () =>
          dispatch(
            getUsersDataThunk({
              param: `?size=${size}&page=${active}&company_id=${users?.usersDataId?.company_id}&role=student&keyword=${keyword}`,
            })
          ),
        800
      );
      return () => clearTimeout(timeOutId);
    }
  }, [keyword, users?.usersDataId?.user_id]);

  useEffect(() => {
    if (users.usersDataId.company_id) {
      const timeOutId = setTimeout(
        () =>
          getStudyGroupList(
            `?size=${size}&page=${active}&company_id=${users?.usersDataId?.company_id}&keyword=${keywordStudyTab}`
          ),
        800
      );
      return () => clearTimeout(timeOutId);
    }
  }, [keywordStudyTab, users?.usersDataId?.user_id]);

  useEffect(() => {
    if (trigger) {
      if (userData.usersDataDelete === "success") {
        customDisplayPopup(
          "Selamat!",
          `Anda Telah Berhasil Menghapus <b>${
            dataDel.length < 2 ? dataDel[0].name : dataDel.length
          }</b> dari Organisasimu`,
          IconConfirmSucces,
          false,
          "Ya",
          true,
          "Tutup",
          "#55AA73"
        );
      }
      if (triggerRombel) {
        setTriggerRombel(false);
        getStudyGroup(`?company_id=${users?.usersDataId?.company_id}`);
        getStudyGroupList(`?company_id=${users?.usersDataId?.company_id}`);
      }

      if (activePageAnggota == "all") {
        dispatch(
          getUsersDataThunk({
            param: `?size=${size}&page=${active}&company_id=${users?.usersDataId?.company_id}&role=student&keyword=${keyword}`,
          })
        );
      } else {
        getStudyGroupMember(`/${currentRombel}?size=${size}&page=${active}`);
      }
      getAdminList(
        `&size=${size}&page=${active}&company_id=${users?.usersDataId?.company_id}`
      );

      setTrigger(false);
      setDataDel([]);
    }
  }, [
    keyword,
    users?.usersDataId?.user_id,
    size,
    users?.usersDataDelete,
    triggerRombel,
  ]);

  useEffect(() => {
    if (show == false) {
      setIsEdit(false);
    }
  }, [show]);

  useEffect(() => {
    setUserOrganisasiPagination(usersData.pagination);
  }, [usersData.pagination]);

  const handleSubmitRombel = async () => {
    let selectedUser = dataChoose.map((item) => item.user_id).toString();
    let payload = {
      group_name: nameRombel,
      members: selectedUser,
    };
    try {
      const response = await StudyGroupServices.postStudyGroup(payload);
      if (response) {
        customDisplayPopup(
          "Selamat!",
          `Anda Telah Berhasil Membuat Rombel <b>${payload.group_name}</b> dengan jumlah anggota ${dataChoose.length} orang`,
          IconConfirmSucces,
          false,
          "Ya",
          true,
          "Tutup",
          "#55AA73"
        );

        setTrigger(true);
        setTriggerRombel(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleRedirectStudent = () => {
    let url =
      "https://wa.me/6282130070040?text=saya%20ingin%20konsultasi%20mengenai%20satukelas";
    window.open(url, "_blank");
  };

  const getAdminList = async (param) => {
    try {
      const response = await AuthUsersDetail.getAdminData(param);

      if (response) {
        setListAdmin(response);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getStudyGroup = async (param) => {
    try {
      const response = await StudyGroupServices.getStudyGroup(param);
      if (response) {
        setGroupList(response?.items);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getStudyGroupList = async (param) => {
    try {
      const response = await StudyGroupServices.getStudyGroup(param);
      if (response) {
        setStudyGroupList(response?.items);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getStudyGroupMember = async (param) => {
    try {
      const response = await StudyGroupServices.getStudyGroupMember(param);

      if (response) {
        setMemberRombelList(response?.items);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleChangeSize = (e) => {
    setSize(e.target.value);
    setTrigger(true);
  };
  const handleViewDetail = (id) => {
    history.push(`/dashboard/management-organisasi/${id}`);
  };

  const handleDeleteUser = (type) => {
    customDisplayPopup(
      `Apakah Anda Yakin untuk Menghapus ${
        type == "admin"
          ? "Admin"
          : type === "study_group_list"
          ? "Rombel"
          : "Anggota"
      }?`,
      `Anda akan menghapus <b>${
        dataDel.length < 2 ? dataDel[0].name : dataDel.length
      }</b> ${
        type == "admin"
          ? "dari daftar Admin"
          : type === "study_group_list"
          ? "rombel"
          : "anggota dari daftar Anggota"
      }`,
      IconConfirmSeru,
      true,
      type == "admin" || type == "study_group_list"
        ? "Ya"
        : dataDel.length < 2
        ? "Ya, saya yakin"
        : "Ya",
      type == "student" && dataDel.length < 2 ? false : true,
      `Tidak`,
      "#F2F2F2"
    );
  };

  const handleDeleteMember = () => {
    customDisplayPopup(
      "Apakah Anda Yakin untuk Menghapus Anggota?",
      `Anda akan menghapus <b>${
        dataDel.length < 2 ? dataDel[0].name : dataDel.length
      }</b> anggota dari daftar Anggota`,
      IconConfirmSeru,
      true,
      activePageAnggota != "all" && dataDel.length < 2 ? false : true,
      `<p style='color:#818181;margin-bottom:0;width:180px'>Tidak</p>`,
      "#F2F2F2"
    );
  };

  const executeDeleteMember = async () => {
    let selectedUser = dataDel.map((item) => item.user_id).toString();
    let payload = {
      group_name: activePageAnggota,
      members: selectedUser,
    };

    try {
      const response = await StudyGroupServices.removeStudyGroupMember(payload);
      if (response) {
        customDisplayPopup(
          "Selamat!",
          `Anda Telah Berhasil Menghapus <b>${
            dataDel.length >= 2 ? dataDel.length : dataDel.name
          }</b> dari Organisasimu`,
          IconConfirmSucces,
          false,
          true,
          "Tutup",
          "#55AA73"
        );
        getStudyGroup(`?company_id=${users?.usersDataId?.company_id}`);
        getStudyGroupList(`?company_id=${users?.usersDataId?.company_id}`);
        getStudyGroupMember(`/${currentRombel}`);
      }
    } catch (err) {
      displayPopup(false, "Gagal menghapus member!", true, "Oke");
    }
  };

  const handleCheckModal = (e) => {
    const { name, checked } = e.target;
    if (name == "allselectmodal") {
      const tempData = userDataModal?.map((item) => {
        return { ...item, isChecked: checked };
      });
      setUserDataModal(tempData);
      setDataChoose(checked ? tempData : []);
    } else {
      const tempData = userDataModal?.map((item) =>
        item.user_id == name ? { ...item, isChecked: checked } : item
      );
      setUserDataModal(tempData);

      if (
        userDataModal?.filter(
          (item) => item.isChecked == true && item.user_id == name
        ).length > 0
      ) {
        setDataChoose(dataChoose.filter((item) => item.user_id != name));
      } else {
        const dataUser = userDataModal?.find((item) => item.user_id == name);
        setDataChoose([...dataChoose, dataUser]);
      }
    }
    if (isEdit) {
      let payload = {
        group_name: selectedName,
        members: name,
      };
      if (checked) {
        addMember(payload);
      } else {
        removeMember(payload);
      }
    }
  };
  const addMember = async (payload) => {
    try {
      const response = await StudyGroupServices.postStudyGroup(payload);
      if (response) {
        console.log(response);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const removeMember = async (payload) => {
    try {
      const response = await StudyGroupServices.removeStudyGroupMember(payload);
      if (response) {
        console.log(response);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleCheck = (e, type) => {
    const { name, checked } = e.target;
    const username = e.target.getAttribute("username");

    if (type === "user") {
      if (name == "allselect") {
        const tempData = userData?.map((item) => {
          return { ...item, isChecked: checked };
        });

        setUserData(tempData);
        setDataDel(checked ? tempData : []);
      } else {
        const tempData = userData?.map((item) =>
          item.user_id == name ? { ...item, isChecked: checked } : item
        );
        console.log(tempData);
        setUserData(tempData);

        if (
          userData?.filter(
            (item) => item.isChecked == true && item.user_id == name
          ).length > 0
        ) {
          setDataDel(dataDel.filter((item) => item.user_id != name));
        } else {
          setDataDel([
            ...dataDel,
            { user_id: name, name: username, isChecked: true },
          ]);
        }
      }
    } else if (type === "rombel") {
      if (name == "allselect") {
        const tempData = memberRombelList?.map((item) => {
          return { ...item, isChecked: checked };
        });

        setMemberRombelList(tempData);
        setDataDel(checked ? tempData : []);
      } else {
        const tempData = memberRombelList?.map((item) =>
          item.user_id == name ? { ...item, isChecked: checked } : item
        );
        setMemberRombelList(tempData);

        if (
          memberRombelList?.filter(
            (item) => item.isChecked == true && item.user_id == name
          ).length > 0
        ) {
          setDataDel(dataDel.filter((item) => item.user_id != name));
        } else {
          setDataDel([
            ...dataDel,
            { user_id: name, name: username, isChecked: true },
          ]);
        }
      }
    } else if (type === "study_group_list") {
      if (name == "allselect") {
        const tempData = studyGroupList?.map((item) => {
          return { ...item, isChecked: checked };
        });
        setStudyGroupList(tempData);
        setDataDel(checked ? tempData : []);
      } else {
        const tempData = studyGroupList?.map((item) =>
          item.user_id == name ? { ...item, isChecked: checked } : item
        );
        console.log(tempData);
        setStudyGroupList(tempData);

        if (
          studyGroupList?.filter(
            (item) => item.isChecked == true && item.user_id == name
          ).length > 0
        ) {
          setDataDel(dataDel.filter((item) => item.user_id != name));
        } else {
          setDataDel([
            ...dataDel,
            { user_id: name, name: username, isChecked: true },
          ]);
        }
      }
    } else {
      if (name == "allselect") {
        const tempData = adminData?.map((item) => {
          return { ...item, isChecked: checked };
        });

        setAdminData(tempData);
        setDataDel(checked ? tempData : []);
      } else {
        const tempData = adminData?.map((item) =>
          item.user_id == name ? { ...item, isChecked: checked } : item
        );
        setAdminData(tempData);

        if (
          adminData?.filter(
            (item) => item.isChecked == true && item.user_id == name
          ).length > 0
        ) {
          setDataDel(dataDel.filter((item) => item.user_id != name));
        } else {
          setDataDel([
            ...dataDel,
            { user_id: name, name: username, isChecked: true },
          ]);
        }
      }
    }
  };

  const formatDate = (date) => {
    const rawDate = new Date(date);
    const formattedDate = rawDate.toLocaleDateString("id-ID", {
      day: "numeric",
      month: "short",
      year: "numeric",
    });

    const formattedTime = rawDate.toLocaleTimeString("us-US", {
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    });

    return `${formattedDate} ${formattedTime}`;
  };

  function paginationManagOrganisasi(newActive) {
    setActive(newActive);
    dispatch(
      getUsersDataThunk({
        param: `?size=${size}&page=${newActive}&company_id=${users.usersDataId?.company_id}&role=student`,
      })
    );
  }

  const getStudyGroupMemberList = async (param) => {
    try {
      const response = await StudyGroupServices.getStudyGroupMember(param);
      if (response) {
        setMemberRombels(response?.items);
      }
    } catch (error) {
      console.log(error);
    }
  };

  function switchShowInternalContent() {
    console.log(userDataModal);
    switch (showInternal) {
      case "add":
        return (
          <>
            <Form.Group className="mb-3 w-100" controlId="formGroupKaryawan">
              <Form.Label className="title__form-addmember">
                Nama Rombel
              </Form.Label>
              <Form.Control
                autoComplete="off"
                type="text"
                placeholder="Masukan Nama Rombongan Belajar"
                name="name"
                value={nameRombel}
                onChange={(e) => setNameRombel(e.target.value)}
              />
            </Form.Group>
            <Form.Group
              className="mb-3 w-100 position-relative"
              controlId="formGroupKaryawan"
            >
              <Form.Label className="title__form-addmember">
                Pilih Anggota
              </Form.Label>
              <Form.Control
                placeholder="Cari"
                aria-label="Username"
                aria-describedby="basic-addon1"
                className=""
                onChange={(e) => setKeyword(e.target.value)}
              />
              <div className="icon__search-edit">
                <img src={IconSearch} alt="" />
              </div>
            </Form.Group>
            <div className="d-flex my-3 align-items-center">
              Terpilih <b className="mx-1">{dataChoose.length}</b> dari
              <b className="mx-1">{usersData?.pagination?.totalItems}</b>
              Anggota
            </div>
            <Table responsive striped hover>
              <thead>
                <th>
                  <Form.Check
                    inline
                    name="allselectmodal"
                    type="checkbox"
                    checked={
                      userDataModal?.filter((data) => data?.isChecked !== true)
                        .length < 1
                    }
                    // onChange={handleCheckModal}
                  />
                </th>
                <th className="pl-0">Nama</th>
                <th className="pl-0">Divisi</th>
                <th className="pl-0">Posisi</th>
                <th>Memberships</th>
              </thead>
              <tbody>
                {userDataModal?.length > 0 ? (
                  userDataModal &&
                  userDataModal?.map((data, index) => (
                    <tr key={index}>
                      <td>
                        <Form.Check
                          inline
                          name={data.user_id}
                          type="checkbox"
                          checked={data?.isChecked || false}
                          onChange={handleCheckModal}
                        />
                      </td>
                      <td className="pl-0">
                        <Link to="#">{data.name}</Link>
                      </td>
                      <td className="pl-0">
                        {data.division != "" ? data.division : "-"}
                      </td>
                      <td className="pl-0">
                        {data.position != "" ? data.position : "-"}
                      </td>
                      <td className="pl-0">
                        {data.memberships.length > 0
                          ? data.memberships.map(
                              (item) => item.membership_title + ", "
                            )
                          : "-"}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={8} className="text-center">
                      Data Belum Tersedia
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
            <Row className="justify-content-center px-2">
              <PaginationTemplate
                size={size}
                totalRows={usersOrganisasiPagination?.totalItems}
                totalPages={usersOrganisasiPagination?.totalPages}
                paginationSelect={paginationManagOrganisasi}
                active={active}
              />
            </Row>
          </>
        );
      case "continue":
        return (
          <Table responsive striped hover>
            <thead>
              <th className="pl-0">Nama</th>
              <th className="pl-0">Divisi</th>
              <th className="pl-0">Posisi</th>
              <th>Memberships</th>
            </thead>
            <tbody>
              {dataChoose?.length > 0 ? (
                dataChoose &&
                dataChoose?.map((data, index) => (
                  <tr key={index}>
                    <td className="pl-0">
                      <Link to="#">{data.name}</Link>
                    </td>
                    <td className="pl-0">
                      {data.division != "" ? data.division : "-"}
                    </td>
                    <td className="pl-0">
                      {data.position != "" ? data.position : "-"}
                    </td>
                    <td className="pl-0">
                      {data.memberships.length > 0
                        ? data.memberships.map((item) => item.membership_title)
                        : "-"}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={4} className="text-center">
                    Data Belum Tersedia
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        );

      default:
        return null;
    }
  }

  const filterDataAdmin = () => {
    getAdminList(
      `&size=${size}&page=${active}&company_id=${users?.usersDataId?.company_id}&keyword=${keywordAdmin}`
    );
  };

  const filterStudyGroup = () => {
    getStudyGroupList(
      `?size=${size}&page=${active}&company_id=${users?.usersDataId?.company_id}&keyword=${keywordStudyTab}`
    );
  };
  const getDetailRombel = (id, coid, studyGroup) => {
    let idStudy = studyGroup.split("=");
    setCurrentRombel(id);
    getStudyGroupMember(`/${idStudy[1]}`);
    setDataDel([]);
  };

  function displayPopup(cancel, title, confirm, confirmMsg) {
    MySwal.fire({
      title: title,
      cancelButtonText: "Ok",
      showCancelButton: cancel,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      showConfirmButton: confirm,
      confirmButtonText: confirmMsg,
    }).then((value) => {
      if (confirm) {
        setShow(false);
      }
    });
  }

  function customDisplayPopup(
    title,
    subTitle,
    iconType,
    confirmBtn,
    textConfirm,
    cancelBtn,
    textCancel,
    cancelBgColor
  ) {
    MySwal.fire({
      title: title,
      html: subTitle,
      imageUrl: `${iconType}`,
      imageWidth: 122,
      imageHeight: 135,
      imageAlt: "Custom image",
      showConfirmButton: confirmBtn,
      confirmButtonColor: "#FF8500",
      confirmButtonText: `<p style='margin-bottom:0;width:180px'>${textConfirm}</p>`,
      showCancelButton: cancelBtn,
      cancelButtonText: `<p style='margin-bottom:0;width:188px;color:#818181'>${textCancel}</p>`,
      cancelButtonColor: cancelBgColor,
    }).then((result) => {
      if (result.isConfirmed && activePageAnggota == "all") {
        const id = dataDel.find((item) => item.user_id);

        setTrigger(true);
        dispatch(deleteUsersDataThunk(id.user_id));
      }

      if (result.isConfirmed && activePageAnggota != "all") {
        executeDeleteMember();
      }

      if (
        result.isDismissed &&
        (result.dismiss == "cancel" || result.dismiss == "backdrop")
      ) {
        setShow(false);
        setShowInternal("add");
        setDataChoose([]);
        setUserDataModal([]);
        setNameRombel("");
      }
    });
  }

  function handleDeleteRombel() {}
  function handleEditRombel(id, name) {
    setSelectedName(name);
    setShow(true);
    setIsEdit(true);
    getDetailStudyGroup(`/${id}`);
    getStudyGroupMemberList(`/${id}`);
    // setUserDataModal(usersData.items);
  }

  function getDetailStudyGroup(id) {
    getStudyGroupDetail(`${id}`);
  }

  const getStudyGroupDetail = async (id) => {
    try {
      const response = await StudyGroupServices.getStudyGroup(id);
      if (response) setNameRombel(response?.group_name);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="px-3">
      <h3 className="title__page-man mb-4">Management Organisasi</h3>
      <Breadcrumb>
        <Breadcrumb.Item href="#">
          <img
            src={IconOverviewMan}
            alt=""
            className="icon__breadcrumb"
            style={{ color: "#A6A6A6" }}
            height={24}
            width={24}
          />
        </Breadcrumb.Item>
        <Breadcrumb.Item active className="breadcrumb__list-nav">
          Manajemen Organisasi
        </Breadcrumb.Item>
      </Breadcrumb>
      <Row>
        <div className="w-100 d-flex" style={{ maxHeight: "300px" }}>
          <Col md={12} lg={7} className="mb-4 mb-lg-0">
            <MyOrganization companies={companies} />
          </Col>
          <Col md={12} lg={5}>
            <MyMembership
              dataOverview={dataOverview}
              handleRedirectStudent={handleRedirectStudent}
            />
            <KuotaAnggota
              totalMembers={totalMembers}
              result={result}
              colorPalete={colorPalete}
              kuotaMembers={kuotaMembers}
            />
          </Col>
        </div>
        <Col md={12} lg={12} className="mt-4">
          <MemberList
            setKeyword={setKeyword}
            activePageAnggota={activePageAnggota}
            handleDeleteUser={handleDeleteUser}
            handleDeleteMember={handleDeleteMember}
            setActivePageAnggota={setActivePageAnggota}
            usersData={usersData}
            groupList={groupList}
            getDetailRombel={getDetailRombel}
            setShow={setShow}
            setUserDataModal={setUserDataModal}
            dataDel={dataDel}
            allDataStudent={allDataStudent}
            memberRombelList={memberRombelList}
            handleChangeSize={handleChangeSize}
            userData={userData}
            handleCheck={handleCheck}
            handleViewDetail={handleViewDetail}
            formatDate={formatDate}
            usersOrganisasiPagination={usersOrganisasiPagination}
            size={size}
            paginationManagOrganisasi={paginationManagOrganisasi}
            active={active}
          />
        </Col>
        <Col md={12} lg={12} className="mt-4">
          <RombelList
            setKeywordStudyTab={setKeywordStudyTab}
            filterStudyGroup={filterStudyGroup}
            handleDeleteRombel={handleDeleteRombel}
            setShow={setShow}
            setUserDataModal={setUserDataModal}
            usersData={usersData}
            studyGroupList={studyGroupList}
            handleCheck={handleCheck}
            handleEditRombel={handleEditRombel}
          />
        </Col>
        <Col md={12} lg={12} className="mt-4">
          <AdminList
            dataAdmin={adminData}
            keywordAdmin={keywordAdmin}
            handleDeleteUser={handleDeleteUser}
            setKeywordAdmin={setKeywordAdmin}
            filterDataAdmin={filterDataAdmin}
            handleCheck={handleCheck}
            adminData={adminData}
            handleViewDetail={handleViewDetail}
          />
        </Col>
      </Row>

      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        scrollable={true}
        centered
        show={show}
        onHide={() => setShow(!show)}
      >
        {showInternal == "add" ? (
          <Modal.Header className="d-block">
            <h3 className="title__modal-pelatihan">
              {isEdit ? "Edit" : "Tambahkan"} Rombongan Belajar Baru
            </h3>
            <span className="subtitle__modal-pelatihan">
              Tentukan nama rombongan belajar dan pilih anggota untuk
              ditambahkan ke dalam rombongan belajar ini
            </span>
          </Modal.Header>
        ) : (
          <Modal.Header className="d-block">
            <h3 className="title__modal-pelatihan">
              Konfirmasi {isEdit ? "Edit" : "Pembuatan"} Rombongan Belajar
            </h3>
            <div className="subtitle__modal-confirm d-flex align-items-center">
              <div>
                <img src={IconInfoBlue} alt="" />
              </div>
              <span className="d-block">
                Apakah anda yakin untuk membuat RomBel <b>{nameRombel}</b>{" "}
                dengan anggota berikut?
              </span>
            </div>
          </Modal.Header>
        )}
        <Modal.Body>{switchShowInternalContent()}</Modal.Body>
        <Modal.Footer>
          {showInternal == "continue" && (
            <>
              <button
                className="btn btn__back-show"
                onClick={() => setShowInternal("add")}
              >
                Kembali
              </button>
              <button
                className="btn btn__cotinue-show"
                onClick={
                  isEdit
                    ? () => {
                        setShow(false);
                        customDisplayPopup(
                          "Selamat!",
                          `Anda Telah Berhasil Mengedit Rombel Ini!`,
                          IconConfirmSucces,
                          false,
                          "Ya",
                          true,
                          "Tutup",
                          "#55AA73"
                        );
                      }
                    : () => handleSubmitRombel()
                }
              >
                Simpan
              </button>
            </>
          )}
          {showInternal == "add" && (
            <button
              className="btn btn__cotinue-show"
              onClick={() => setShowInternal("continue")}
              disabled={
                nameRombel != "" && dataChoose?.length > 0 ? false : true
              }
            >
              Selanjutnya
            </button>
          )}
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default ManagementOrganisasi;
