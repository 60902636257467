import React from "react";
import { Card } from "react-bootstrap";
import ReactECharts from "echarts-for-react";
import "../assets/css/Dashboard/companyAnalytics.css";
import moment from "moment";
function StatusPostTestUsers({ data }) {
  // get date of nth week
  const generateWeekDate = (data, i) => {
    return moment(data?.completed_lessons?.datelist[i]?.start).format("MMM D");
  };
  // get array of nth week with date
  const generateWeeklist = (data) => {
    const result = [];
    if (data?.chart_post_test?.weeklist.length > 0) {
      for (let i = 0; i < 8; i++) {
        const e = `Week ${data?.chart_post_test?.weeklist[i]
          }, ${generateWeekDate(data, i)}`;
        result.push(e);
      }
      return result;
    } else {
      const e = "Tidak tersedia";
      result.push(e);
      return result;
    }
  };
  const passed = (data) => {
    const result = [];
    for (let i = 0; i < 8; i++) {
      const e = data?.chart_post_test?.counts[i]?.passed;
      result.push(e);
    }
    return result;
  };
  const fail = (data) => {
    const result = [];
    for (let i = 0; i < 8; i++) {
      const e = data?.chart_post_test?.counts[i]?.failed;
      result.push(e);
    }
    return result;
  };
  function option3() {
    const weeklist = generateWeeklist(data);
    const passeds = passed(data);
    const fails = fail(data);
    return {
      legend: {
        data: ["Lulus", "Tidak Lulus"],
      },
      // brush: {
      //   toolbox: ["rect", "polygon", "lineX", "lineY", "keep", "clear"],
      //   xAxisIndex: 0,
      // },
      // toolbox: {
      //   feature: {
      //     magicType: {
      //       type: ["stack"],
      //     },
      //     dataView: {},
      //   },
      // },
      tooltip: {
        trigger: "axis",
      },
      xAxis: {
        type: "category",
        boundaryGap: false,
        data: weeklist,
        // axisLine: { onZero: true },
        // splitLine: { show: false },
        // splitArea: { show: false },
      },
      yAxis: { type: "value" },
      grid: {
        bottom: 100,
      },
      series: [
        {
          name: "Lulus",
          type: "bar",
          stack: "one",
          emphasis: emphasisStyle,
          data: passeds,
          color: "#91CC75",
        },
        {
          name: "Tidak Lulus",
          type: "bar",
          stack: "one",
          emphasis: emphasisStyle,
          data: fails,
          color: "#EE4253",
        },
      ],
    };
  }

  return (
    <>
      <Card className="card_container">
        <div className="card_inside__padding ">
          <div className="d-flex justify-content-between border_card">
            <div className="card_title__info mb-1">Status Post Test</div>
          </div>
          {/* <ReactECharts option={option()} />
           */}
          <ReactECharts
            style={{ height: "400px", paddingBottom: "16px" }}
            option={option3()}
          />
        </div>
      </Card>
    </>
  );
}

export default StatusPostTestUsers;
var emphasisStyle = {
  itemStyle: {
    shadowBlur: 10,
    shadowColor: "rgba(0,0,0,0.3)",
  },
};
