import React from "react";
import { Card } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import "../assets/css/Dashboard/companyAnalytics.css";

function FollowingConsultation({ data }) {
  let address = useLocation();
  const analyticsuser = useSelector((state) => state.analyticsuser);
  return (
    <Card className="card_container">
      <div className="card_inside__padding ">
        <div className="d-flex flex-column justify-content-between border_card">
          <div className="card_title__info mb-1">Konsultasi yang Diikuti</div>
          <div className="card_subtitle mb-1">
            {" "}
            {data?.stats_konsultasi?.length !== 0 ? (
              <div className="card_subtitle mb-1">
                Total: {data?.stats_konsultasi?.length}
              </div>
            ) : (
              "N/A"
            )}
          </div>
        </div>

        {data?.stats_konsultasi?.length !== 0 ? (
          data?.stats_konsultasi?.map((item, index) => {
            return (
              <div key={index} className="certificate-wrapper">
                <div className="wrapper-table__list">
                  <div className="img-certificate mr-3"></div>
                  <div className="certificate-title__wrap">
                    <div className="certificate-title__main">
                      {item?.course}
                    </div>
                    <div className="certificate-subtitle__secondary">
                      {/* <FaUserAlt className="mr-2 size" /> */}
                      {item?.total_materials}
                    </div>
                  </div>
                </div>
                <div className="my-2">
                  {" "}
                  <span className="container-avg__company">Akan Datang</span>
                </div>
              </div>
            );
          })
        ) : (
          <div className="certificate-title__main">N/A</div>
        )}
      </div>
    </Card>
  );
}

export default FollowingConsultation;
function option2() {
  return {
    dataset: {
      source: [
        ["score", "amount", "product"],
        [89.3, 58212, "Januari"],
        [57.1, 78254, "Febuari"],
        [74.4, 41032, "Maret"],
        [50.1, 12755, "April"],
        [89.7, 20145, "Mei"],
        [68.1, 79146, "Juni"],
        [19.6, 91852, "Juli"],
        [10.6, 101852, "Agustus"],
        [32.7, 20112, "September"],
        [32.7, 20112, "Oktober"],
        [32.7, 20112, "November"],
        [32.7, 20112, "Desember"],
      ],
    },
    grid: { containLabel: true },
    xAxis: { name: "amount" },
    yAxis: { type: "category" },
    visualMap: {
      orient: "horizontal",
      left: "center",
      min: 10,
      max: 100,
      // Map the score column to color
      dimension: 0,
      inRange: {
        color: ["#65B581", "#FFCE34", "#FD665F"],
      },
    },
    series: [
      {
        type: "bar",
        encode: {
          // Map the "amount" column to X axis.
          x: "amount",
          // Map the "product" column to Y axis
          y: "product",
        },
      },
    ],
  };
}
function option() {
  return {
    tooltip: {
      trigger: "axis",
    },
    legend: {
      data: ["Pelatihan yang Selesai"],
    },
    grid: {
      left: "3%",
      right: "4%",
      bottom: "3%",
      containLabel: true,
    },

    xAxis: {
      type: "category",
      boundaryGap: false,
      data: [
        "Week 1",
        "Week 2",
        "Week 3",
        "Week 4",
        "Week 5",
        "Week 6",
        "Week 7",
        "Week 8",
      ],
    },
    yAxis: {
      type: "value",
    },
    series: [
      {
        name: "Pelatihan yang Selesai",
        type: "line",
        stack: "Total",
        // data: [120, 132, 101, 134, 90, 230, 210],
        data: [0],
      },
    ],
  };
}
function option5() {
  return {
    tooltip: {
      trigger: "axis",
    },
    legend: {
      data: ["Pelatihan yang Selesai"],
    },
    grid: {
      left: "3%",
      right: "4%",
      bottom: "3%",
      containLabel: true,
    },
    toolbox: {
      feature: {
        saveAsImage: {},
      },
    },
    xAxis: {
      type: "category",
      boundaryGap: false,
      data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
    },
    yAxis: {
      type: "value",
    },
    series: [
      {
        name: "Pelatihan yang Selesai",
        type: "line",
        stack: "Total",
        data: [120, 132, 101, 134, 90, 230, 210],
      },
    ],
  };
}
let xAxisData = [
  "Week 1",
  "Week 2",
  "Week 3",
  "Week 4",
  "Week 5",
  "Week 6",
  "Week 7",
  "Week 8",
];
let data1 = [0];
let data2 = [0];
var emphasisStyle = {
  itemStyle: {
    shadowBlur: 10,
    shadowColor: "rgba(0,0,0,0.3)",
  },
};

// for (let i = 0; i < 12; i++) {
//   data1.push(+(Math.random() * 2).toFixed(2));
//   data2.push(+(Math.random() * 5).toFixed(2));
// }
function option3() {
  return {
    legend: {
      // data: ["Lulus", "Tidak Lulus"],
      data: ["Belum ada data"],
      // left: "10%",
    },
    // brush: {
    //   toolbox: ["rect", "polygon", "lineX", "lineY", "keep", "clear"],
    //   xAxisIndex: 0,
    // },
    // toolbox: {
    //   feature: {
    //     magicType: {
    //       type: ["stack"],
    //     },
    //     dataView: {},
    //   },
    // },
    // tooltip: {},
    xAxis: {
      data: xAxisData,
      // axisLine: { onZero: true },
      // splitLine: { show: false },
      // splitArea: { show: false },
    },
    yAxis: {},
    grid: {
      bottom: 100,
    },
    series: [
      {
        name: "Lulus",
        type: "bar",
        stack: "one",
        emphasis: emphasisStyle,
        data: data1,
        color: "#91CC75",
      },
      {
        name: "Tidak Lulus",
        type: "bar",
        stack: "one",
        emphasis: emphasisStyle,
        data: data2,
        color: "#EE4253",
      },
    ],
  };
}
