import React, { useEffect } from "react";
import { Row, Col, Container, Table, Card } from "react-bootstrap";
import { getAnalyticsAssignedDataThunk } from "../../redux/thunks/AnalyticsGroupThunks";
import "../../assets/css/Dashboard/index.css";
import { AuthIsLogin } from "../../helper/auth";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

function HistoryAssignment() {
  const history = useHistory();
  const dispatch = useDispatch();
  const analyticsgroup = useSelector((state) => state.analyticsgroup);
  const users = useSelector((state) => state.users);
  const dataHistory = analyticsgroup.analyticsAssignedData;
  useEffect(() => {
    if (users.usersDataId?.company_id !== undefined) {
      dispatch(
        getAnalyticsAssignedDataThunk({
          param: `?company_id=${users.usersDataId?.company_id}`,
        })
      );
    }
  }, [users]);
  useEffect(() => {}, [analyticsgroup]);
  useEffect(() => {
    AuthIsLogin(history, true);
  }, []);
  return (
    <Container fluid>
      <Row className="justify-content-center align-items-center height-inherit">
        <Col>
          <div className="course_create__title mb-3">
            Riwayat Penugasan Course Wajib
          </div>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12}>
          {" "}
          <Card className="card_container mb-5">
            <div className="card_inside__padding ">
              <div className="d-flex flex-row justify-content-between">
                {/* <Form.Control
                  style={{ flex: 0.5 }}
                  className="satukelas_input mb-3"
                  type="text"
                  id="search"
                  name="search"
                  placeholder="Cari Pelatihan"
                  value={search}
                  // onKeyDown={keydownHandler}
                  // onChange={onChangeSearch}
                /> */}
                {/* {coursePagination ? (
            <Pagination
              size={size}
              totalRows={coursePagination.totalItems}
              totalPages={coursePagination.totalPages}
              paginationSelect={paginationSelect}
              active={active}
            />
          ) : null} */}
              </div>
              <Table responsive striped bordered hover>
                <thead>
                  <tr>
                    <th className="w-50">Ditugaskan</th>
                    <th className="w-50">Tipe Pelatihan</th>
                    <th className="w-50">Konten</th>
                    <th>Mulai</th>
                    <th>Deadline</th>
                    {/* <th className="text-center w-25">Tampilkan di Kalender</th> */}
                    {/* <th className="text-center">Actions</th> */}
                  </tr>
                </thead>
                <tbody>
                  {dataHistory?.items?.length > 0 ? (
                    dataHistory.items.map((e, i) => {
                      return (
                        <tr key={i}>
                          <td className="align-middle">{e.name}</td>
                          <td className="align-middle">{e.course_type}</td>
                          <td className="text-nowrap align-middle">
                            {e.course}
                          </td>
                          <td className="text-nowrap align-middle ">
                            <span>
                              {moment(e.started_at).format("DD MMMM YYYY")}
                            </span>
                          </td>
                          <td className="text-nowrap align-middle ">
                            {moment(e.deadline).format("DD MMMM YYYY")}
                          </td>
                          {/* <td className="text-center align-middle">
                            <FaToggleOn />
                          </td> */}
                          {/* <td className="text-center align-middle">
                            <Button className="mb-2 mr-2" variant="info">
                              <FaEdit className="fill-white" />
                            </Button>
                            <Button className="mb-2" variant="danger">
                              <RiDeleteBin2Line className="fill-white" />
                            </Button>
                          </td> */}
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td className="text-center" colSpan={5}>
                        Data tidak di temukan
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default HistoryAssignment;
