import React from "react";
import { Image } from "react-bootstrap";
import Icon from "./../../assets/images/Icon.png";

export default function AverageCompletion(props) {
  const { time, title, desc } = props;
  return (
    <div className="card-analitik _shadow ">
      <div className="total-anggota_title">
        {title ? title : "Average Completion Time"}
      </div>
      <div className="total-hour_wrap">
        <div className="_icon">
          <Image src={Icon} />
        </div>
        <div className="_hour">{time}</div>
      </div>
      <div className="desc-summary">
        {desc
          ? desc
          : "Nilai didapatkan dari rata-rata waktu penyelesaian pelatihan."}
      </div>
    </div>
  );
}
