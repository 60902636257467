import { PromiseService } from "../../services/PromiseService";
import { DISCUSS_TOPICS_DATA_LINK } from "../config/DiscussTopicsConfig";
import {
  //get
  getDiscussTopicsData,
  getDiscussTopicsDataSuccess,
  getDiscussTopicsDataIdSuccess,
  getDiscussTopicsDataFail,
  //post
  postDiscussTopicsData,
  postDiscussTopicsDataSuccess,
  postDiscussTopicsDataFail,
  // put
  putDiscussTopicsData,
  putDiscussTopicsDataSuccess,
  putDiscussTopicsDataFail,
  // delete
  deleteDiscussTopicsData,
  deleteDiscussTopicsDataSuccess,
  deleteDiscussTopicsDataFail,
  // live search
  liveSearchDiscussTopicsData,
  liveSearchDiscussTopicsDataSuccess,
  liveSearchDiscussTopicsDataFail,
} from "../actions/DiscussTopicsActions";

// get
export const getDiscussTopicsDataThunk = (data) => async (dispatch) => {
  dispatch(getDiscussTopicsData(data));
  if (data) {
    let url = `${DISCUSS_TOPICS_DATA_LINK}/${data}`;
    if (data.param) url = `${DISCUSS_TOPICS_DATA_LINK}${data.param}`;
    try {
      const response = await PromiseService.getData(url);
      if (response.status === 200 && response.data) {
        if (data.param) dispatch(getDiscussTopicsDataSuccess(response.data));
        else dispatch(getDiscussTopicsDataIdSuccess(response.data));
      } else {
        dispatch(getDiscussTopicsDataFail(response));
      }
    } catch (error) {
      dispatch(getDiscussTopicsDataFail(error));
    }
  } else {
    try {
      const response = await PromiseService.getData(DISCUSS_TOPICS_DATA_LINK);
      if (response.status === 200 && response.data) {
        dispatch(getDiscussTopicsDataSuccess(response.data));
      } else {
        dispatch(getDiscussTopicsDataFail(response));
      }
    } catch (error) {
      dispatch(getDiscussTopicsDataFail(error));
    }
  }
};

// post
export const postDiscussTopicsDataThunk = (data) => async (dispatch) => {
  dispatch(postDiscussTopicsData(data));
  const body = data;
  try {
    const response = await PromiseService.postData(
      DISCUSS_TOPICS_DATA_LINK,
      body
    );
    if (response.status === 201 && response.data) {
      dispatch(postDiscussTopicsDataSuccess(response.data));
    } else {
      dispatch(postDiscussTopicsDataFail(response));
    }
  } catch (error) {
    dispatch(postDiscussTopicsDataFail(error));
  }
};

// put
export const putDiscussTopicsDataThunk = (data) => async (dispatch) => {
  dispatch(putDiscussTopicsData(data));
  const body = data.body;
  const id = data.id;
  try {
    const response = await PromiseService.putData(
      `${DISCUSS_TOPICS_DATA_LINK}/${id}`,
      body
    );
    if (response.status === 201 && response.data) {
      dispatch(putDiscussTopicsDataSuccess(response.data));
    } else {
      dispatch(putDiscussTopicsDataFail(response));
    }
  } catch (error) {
    dispatch(putDiscussTopicsDataFail(error));
  }
};

// delete
export const deleteDiscussTopicsDataThunk = (data) => async (dispatch) => {
  dispatch(deleteDiscussTopicsData(data));
  const id = data;
  try {
    const response = await PromiseService.deleteData(
      `${DISCUSS_TOPICS_DATA_LINK}/${id}`
    );
    if (response.status === 200 && response.data) {
      dispatch(deleteDiscussTopicsDataSuccess(response.data));
    } else {
      dispatch(deleteDiscussTopicsDataFail(response));
    }
  } catch (error) {
    dispatch(deleteDiscussTopicsDataFail(error));
  }
};

// live search
export const liveSearchDiscussTopicsDataThunk = (data) => async (dispatch) => {
  dispatch(liveSearchDiscussTopicsData(data));
  const id = data;
  try {
    const response = await PromiseService.getData(
      `${DISCUSS_TOPICS_DATA_LINK}${id}`
    );
    if (response.status === 200 && response.data) {
      dispatch(liveSearchDiscussTopicsDataSuccess(response.data));
    } else {
      dispatch(liveSearchDiscussTopicsDataFail(response));
    }
  } catch (error) {
    dispatch(liveSearchDiscussTopicsDataFail(error));
  }
};
