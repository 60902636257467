import React from "react";
import {
  FaHome,
  FaDashcube,
  FaCalendarAlt,
  FaTable,
  FaUser,
} from "react-icons/fa";
import { Nav, NavDropdown } from "react-bootstrap";
import { removeUsersDataId } from "../../redux/actions/UsersActions";
import { AuthLogout } from "../../helper/auth";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);
function StickyNav({ InnerComponent }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const handleLogoutClick = () =>
    MySwal.fire({
      title: "Apakah anda yakin ingin keluar?",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: "Tidak",
      confirmButtonText: "Ya",
    }).then((value) => {
      if (value.isConfirmed) {
        dispatch(removeUsersDataId());
        AuthLogout(history);
      }
    });
  return (
    <div className="app-footer border-0 shadow-lg px-3">
      <a href="/" className="nav-content-bttn nav-center p-0">
        <FaHome />
      </a>
      <Nav className="me-auto" id="navigate">
        <FaDashcube />
        <NavDropdown
          id="nav-dropdown-dark-example"
          menuvariant="dark"
          className="dashboard"
          drop="up"
        >
          {/* <NavDropdown.Item className="px-0" href="/dashboard">
            Overview
          </NavDropdown.Item> */}
          <NavDropdown.Item className="px-0" href="/dashboard/analytic">
            Analitik
          </NavDropdown.Item>
          <NavDropdown.Item className="px-0" href="/dashboard/learning-path/">
            Learning Path
          </NavDropdown.Item>
          <NavDropdown.Item className="px-0" href="/dashboard/kelola-user">
            Kelola User
          </NavDropdown.Item>
          <NavDropdown.Item className="px-0" href="/dashboard/history">
            Riwayat Penugasan Course Wajib
          </NavDropdown.Item>
          {/* <NavDropdown.Item className="px-0" href="/dashboard/home-work">
            PR Student
          </NavDropdown.Item> */}
        </NavDropdown>
      </Nav>
      <a
        href="/dashboard/kalender-pembelajaran"
        className="nav-content-bttn p-0"
      >
        <FaCalendarAlt />
      </a>
      <Nav className="me-auto" id="table">
        <FaTable />
        <NavDropdown
          id="nav-dropdown-dark-example"
          menuvariant="dark"
          drop="up"
        >
          <NavDropdown.Item
            className="px-0"
            href="/dashboard/table/learning-path/"
          >
            Tabel Learning Path
          </NavDropdown.Item>
          <NavDropdown.Item className="px-0" href="/dashboard/table/course/">
            Tabel Pelatihan
          </NavDropdown.Item>
          <NavDropdown.Item className="px-0" href="/dashboard/table/webinar/">
            Tabel Webinar
          </NavDropdown.Item>
        </NavDropdown>
      </Nav>
      <Nav className="me-auto" id="profil">
        <FaUser />
        <NavDropdown
          id="nav-dropdown-dark-example"
          menuvariant="dark"
          className="profil"
          drop="up"
        >
          <NavDropdown.Item className="px-0" href="/dashboard/profile/">
            Profil
          </NavDropdown.Item>
          <NavDropdown.Item className="px-0" href="/company-profile">
            Perusahaan Saya
          </NavDropdown.Item>
          <NavDropdown.Item className="px-0" href="/dashboard/settings/">
            Pengaturan
          </NavDropdown.Item>
          <NavDropdown.Item className="px-0" onClick={handleLogoutClick}>
            Keluar
          </NavDropdown.Item>
        </NavDropdown>
      </Nav>
    </div>
  );
}

export default StickyNav;
