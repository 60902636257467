import React, { useEffect, useState } from "react";
import { Container, Card } from "react-bootstrap";
import { AuthData, AuthIsLogin } from "../helper/auth";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { postPaymentsVerifyDataThunk } from "../redux/thunks/PaymentsThunks";
import { getUsersDataThunk } from "../redux/thunks/UsersThunks";
import { paymentsMethod } from "../helper/payments";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import ModalPembayaran from "./ModalPembayaran";
import { FaInfo } from "react-icons/fa";
const MySwal = withReactContent(Swal);

function HistoryTransaction() {
  const [modalContent, setModalContent] = useState({
    show: false,
    componentBody: "",
  });
  const [order, setOrder] = useState([]);
  const [trigger, setTrigger] = useState(false);
  const [payment, setPayment] = useState([]);
  const history = useHistory();
  const dispatch = useDispatch();
  useEffect(() => {
    AuthIsLogin(history);
    dispatch(getUsersDataThunk(AuthData().userId));
  }, []);
  const users = useSelector((state) => state.users);
  const payments = useSelector((state) => state.payments);
  useEffect(() => {
    const userData = users.usersDataId.my_orders;
    setOrder(userData);
    const paymentData = users.usersDataId.payments;
    setPayment(paymentData);
  }, [users.usersDataId]);
  const handleDetail = (value) => {
    setModalContent({
      ...modalContent,
      show: true,
      body: value,
    });
  };
  const handleVerify = (value) => {
    setTrigger(true);
    const dataSend = {
      order_id: value,
    };
    dispatch(postPaymentsVerifyDataThunk(dataSend));
  };

  useEffect(() => {
    setModalContent({
      ...modalContent,
      show: false,
    });
    if (payments.paymentsVerifyDataPost) {
      if (payments.paymentsVerifyDataPost === "fail" && trigger) {
        setTrigger(false);
        displayPopup(false, "Terjadi kesalahan, coba lagi", true, "Oke");
      } else if (trigger) {
        setTrigger(false);
        displayPopup(
          false,
          `Status Transaksi Anda : ${payments.paymentsVerifyDataPost?.transaction_status ??
          payments.paymentsVerifyDataPost?.status
          }`,
          true,
          "Oke"
        );
      }
    }
  }, [payments.paymentsVerifyDataPost]);

  function displayPopup(cancel, title, confirm, confirmMsg) {
    MySwal.fire({
      title: title,
      showCancelButton: cancel,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      showConfirmButton: confirm,
      confirmButtonText: confirmMsg,
    }).then((value) => {
      if (confirm) {
        dispatch(getUsersDataThunk(AuthData().userId));
      }
    });
  }
  function handleCopy() {
    let copyText = document.getElementById("va-number");
    copyText.select();
    copyText.setSelectionRange(0, 99999); /* For mobile devices */

    /* Copy the text inside the text field */
    navigator.clipboard.writeText(copyText.value);

    /* Alert the copied text */
    alert(copyText.value);
  }
  return (
    <Container className="dashboard_page__container">
      <div className="d-flex justify-content-between align-items-center">
        <h2 className="h2 dashboard__section_title">Riwayat Transaksi</h2>
      </div>
      {payment?.length > 0 || order?.length > 0 ? (
        payment?.map((item, index) => (
          <div key={index}>
            <Card className="topup-item__container mb-3">
              <div className="topup-item__wrapper">
                <div className="topup-item__inside-container">
                  <div className="topup-item__info">
                    <div className="topup-item__info-top">
                      Total {item?.description} <span>{item?.status}</span>
                    </div>
                    <div className="topup-item__info-bottom">
                      <div className="topup-item__virtual-acc mr-2">
                        Rp. {item?.amount}
                      </div>
                      {/* <div className="topup-item__copy-virtual__acc">
                    <FaRegCopy />
                  </div> */}
                    </div>
                  </div>
                </div>
                <div
                  className="info_icon__check"
                  onClick={() => handleDetail(item)}
                >
                  <FaInfo />
                </div>
              </div>
            </Card>
            {order?.map((item, index) => (
              <div key={index} className="dashboard__main_content">
                <div className="payment_bundle__wrapper">
                  <div className="d-flex justify-content-between">
                    <div className=" mr-3">
                      <div className="dashboard__section_number ">
                        No. {item?.order_id}
                      </div>
                      <div className="dashboard__section_date">
                        Tanggal Order:{" "}
                        {moment(item?.created_at).format("DD-MM-YYYY HH:mm:ss")}
                      </div>
                    </div>
                    {/* <div className="payment_status">
              <FaClock style={{ marginRight: "8px" }} /> {item?.status}
            </div> */}
                  </div>
                  <div className="payment_item">
                    <div className="thumbnail_wrapper"></div>
                    <div className="payment_info">
                      <div className="payment_info__title">
                        {" "}
                        Tutorial Perpajakan{" "}
                        <span className="card_content__category">
                          Perpajakan
                        </span>
                      </div>
                      <div className="payment_info__others">
                        <div>
                          <span className="icon-book mr-1"></span>
                          12 Lesson
                        </div>
                        <div>
                          <span className="icon-clock mr-1"></span>
                          03j 45m 03d
                        </div>
                      </div>
                      <div className="payment_info__price"> 300.000,-</div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ))
      ) : (
        <div className="course_min__height mb-4 not_found__course">
          Data tidak tersedia
        </div>
      )}
      <ModalPembayaran
        id="check"
        handleCopy={handleCopy}
        content={modalContent}
        paymentsMethod={paymentsMethod}
        handleVerify={handleVerify}
      />
    </Container>
  );
}

export default HistoryTransaction;
