import { FaCalendar } from "react-icons/fa";
import moment from "moment";

export default function MembershipActiveCard(props) {
  const { membershipActive, text } = props;
  return (
    <div className="card_inside__padding red_card mb-4">
      <div className="card_subtitle">{text}</div>
      <div className="d-flex align-items-center justify-content-start">
        <div className="dash-icon text-warning mr-3">
          <FaCalendar className="white-fill" />
        </div>
        <div className="company-profil__membership-wrapper__list">
          {membershipActive ? (
            <>
              {/* <div className="company-profil__membership-image"></div> */}
              <div className="company-profil__membership-info__wrapper">
                <div className="company-profil__membership-title__detail">
                  {membershipActive?.membership_title}
                  <span className="company-profil__membership-badge">
                    Aktif
                  </span>
                </div>
                <div className="company-profil__membership-subtitle__detail">
                  {moment(membershipActive?.expired_at).format("DD MMMM YYYY")}
                </div>
              </div>
            </>
          ) : (
            <div className="not-found__membership">
              Belum ada membership yang aktif
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
