export const ListCourses = [
  {
    id: 1,
    nama: "Pajak Penghasilan (PPh) Orang Pribadi - Brevet AB",
    nilaiMaksimal: 50,
    nilaiPencapaian: 50,
    persentase: "15%",
  },
  {
    id: 2,
    nama: "Bea Meterai (BM) dan Bea Perolehan atas Hak Tanah dan Bangunan (BPHTB) - Brevet AB",
    nilaiMaksimal: 46,
    nilaiPencapaian: 40,
    persentase: "20%",
  },
  {
    id: 3,
    nama: "Interpersonal Skills",
    nilaiMaksimal: 44,
    nilaiPencapaian: 20,
    persentase: "10%",
  },
  {
    id: 4,
    nama: "Listening - Booking a college course",
    nilaiMaksimal: 20,
    nilaiPencapaian: 5,
    persentase: "20%",
  },
  {
    id: 5,
    nama: "Structure and Written Expression - The secret of success",
    nilaiMaksimal: 19,
    nilaiPencapaian: 2,
    persentase: "15%",
  },
];

export const ListCertificate = [
  {
    id: 1,
    sertifikasi: "Brevet Pajak AB",
    nama: "Budi Santoso",
    kelas: null,
    tanggal: "03 Desember 2023 12:00 WIB",
  },
  {
    id: 2,
    sertifikasi: "Brevet Pajak AB",
    nama: "Ayu Rahma",
    kelas: null,
    tanggal: "14 Desember 2023 14:00 WIB",
  },
  {
    id: 3,
    sertifikasi: "Brevet Pajak AB",
    nama: "Laskar Svvara",
    kelas: "Kelas A",
    tanggal: "03 Desember 2023 15:21 WIB",
  },
  {
    id: 4,
    sertifikasi: "Brevet Pajak AB",
    nama: "Antony Pasaribu",
    kelas: "Kelas A",
    tanggal: "10 Desember 2023 14:00 WIB",
  },
  {
    id: 5,
    sertifikasi: "Brevet Pajak C",
    nama: "Roki Balabo",
    kelas: "Kelas B",
    tanggal: "03 Desember 2023 13:22 WIB",
  },
];

export const ListCourseEducation = [
  {
    id: 1,
    title: "Brand Knowledge 2.0",
    duration: "15 menit",
  },
  {
    id: 2,
    title: "How to Talk Like a Pro",
    duration: "25 menit",
  },
  {
    id: 3,
    title: "Company Rules 2024",
    duration: "10 menit",
  },
  {
    id: 4,
    title: "-",
    duration: "-",
  },
  {
    id: 5,
    title: "-",
    duration: "-",
  },
];

export const ListPeformanceTest = [
  {
    id: 1,
    nama: "Budi Santoso",
    pelatihanSelesai: 50,
    persentase: "50%",
  },
  {
    id: 2,
    nama: "Ayu Rahma",
    pelatihanSelesai: 50,
    persentase: "15%",
  },
  {
    id: 3,
    nama: "Laskar Svvara",
    pelatihanSelesai: 34,
    persentase: "15%",
  },
  {
    id: 4,
    nama: "Antoni Pasaribu",
    pelatihanSelesai: 23,
    persentase: "15%",
  },
  {
    id: 5,
    nama: "Roki Balabo",
    pelatihanSelesai: 20,
    persentase: "15%",
  },
];

export const ListJudulPelatihan = [
  {
    judul: "Business Strategy Development",
    type: "Manajemen SDM",
    kategori: 5,
  },
  {
    judul: "Akuntansi Perpajakan Pada Laporan Keuangan",
    type: "Perpajakan",
    kategori: 8,
  },
  {
    judul: "Manajemen Penyelesaian Sengketa Pajak",
    type: "Perpajakan",
    kategori: 10,
  },
  {
    judul: "Saksi dalam Hukum Pajak dan Peradilan Pajak",
    type: "Perpajakan",
    kategori: 12,
  },
  {
    judul: "Building Self Confidence",
    type: "Soft Skills",
    kategori: 11,
  },
];

export const ListPenugasan = [
  {
    title: "Tugas Akhir Minggu",
    priority: 3,
    progress: 20,
    startDate: "23 Des 2023",
    dueDate: "23 Des 2024 14:00",
    status: 1,
  },
  {
    title: "Midweek Assignment",
    priority: 1,
    progress: 20,
    startDate: "23 Des 2023",
    dueDate: "01 Des 2024 14:00",
    status: 0,
  },
  {
    title: "Onboardning",
    priority: 1,
    progress: 20,
    startDate: "23 Des 2023",
    dueDate: "23 Des 2024 14:00",
    status: 1,
  },
  {
    title: "Latihan Bahasa Inggris",
    priority: 1,
    progress: 20,
    startDate: "23 Des 2023",
    dueDate: "04 Mar 2023 15:00",
    status: 3,
  },
  {
    title: "HR Training",
    priority: 3,
    progress: 20,
    startDate: "04 Mar 2023",
    dueDate: "04 Mar 2023 15:00",
    status: 2,
  },
  {
    title: "Reporting Practice",
    priority: 2,
    progress: 20,
    startDate: "04 Mar 2023",
    dueDate: "04 Mar 2023 15:00",
    status: 3,
  },
  {
    title: "Bussines Strategy Development",
    priority: 4,
    progress: 10,
    startDate: "04 Mar 2023",
    dueDate: "04 Mar 2023 15:00",
    status: 3,
  },
];
export const ListAdmin = [
  {
    nama: "Sabrina ayu",
    email: "sabrinaayu@gmail.com",
    divisi: "Pajak",
    posisi: "Staff",
    dueDate: "04 Mar 2023 15:00",
    status: 3,
  },
  {
    nama: "Budi Bayu",
    email: "budibayu@gmail.com",
    divisi: "Pajak",
    posisi: "Staff",
    dueDate: "04 Mar 2023 15:00",
    status: 2,
  },
  {
    nama: "Amir Sumenep",
    email: "amirsumenep@gmail.com",
    divisi: "Pajak",
    posisi: "Staff",
    dueDate: "04 Mar 2023 15:00",
    status: 3,
  },
  {
    nama: "Hana Ratnasari",
    email: "hanaratnasari@gmail.com",
    divisi: "Human Capital",
    posisi: "Staff",
    dueDate: "04 Mar 2023 15:00",
    status: 3,
  },
];
export const ListAnggota = [
  {
    nama: "Sabrina ayu",
    email: "sabrinaayu@gmail.com",
    divisi: "Pajak",
    posisi: "Staff",
    membership: "Sertifikasi Brevet AB",
    end_date: "23 Des 2024",
    lates_active: "1 Des 2023 14:00 WIB",
  },
  {
    nama: "Budi Bayu",
    email: "budibayu@gmail.com",
    divisi: "Pajak",
    posisi: "Staff",
    membership: "Sertifikasi Brevet AB",
    end_date: "23 Des 2024",
    lates_active: "1 Des 2023 14:00 WIB",
  },
  {
    nama: "Amir Sumenep",
    email: "amirsumenep@gmail.com",
    divisi: "Pajak",
    posisi: "Staff",
    membership: "CHRO",
    end_date: "04 Mar 2024",
    lates_active: "1 Des 2023 14:00 WIB",
  },
  {
    nama: "Hana Ratnasari",
    email: "hanaratnasari@gmail.com",
    divisi: "Human Capital",
    posisi: "Staff",
    membership: "CHRO",
    end_date: "04 Mar 2024",
    lates_active: "1 Des 2023 14:00 WIB",
  },
];
