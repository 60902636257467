import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import Library from "../../components/Library";
import { AuthIsLogin } from "../../helper/auth";
import { useHistory } from "react-router-dom";

function MediaLibrary() {
  const history = useHistory();
  useEffect(() => {
    AuthIsLogin(history);
  }, []);
  return (
    <Container fluid>
      <Library />
    </Container>
  );
}

export default MediaLibrary;
