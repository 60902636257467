import React, { useState, useEffect } from "react";
import {
  Button,
  Table,
  Modal,
  Form,
  Container,
  Row,
  Col,
  Card,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { FaTrashAlt } from "react-icons/fa";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { AuthIsLogin, AuthData } from "../../helper/auth";
import { useHistory } from "react-router-dom";
import {
  getUsersDataThunk,
  postUsersDataThunk,
  deleteUsersDataThunk,
  putUsersDataThunk,
} from "../../redux/thunks/UsersThunks";
import { getCourseLiveSearchDataThunk } from "../../redux/thunks/CoursesThunks";
import { removeCourseData } from "../../redux/actions/CoursesActions";
import Pagination from "../../components/Layout/Pagination";
import "../../assets/css/Courses/manageUser.css";
import { useDispatch, useSelector } from "react-redux";
import { SkValidate } from "../../components/common/SkValidate";
import Dropzone from "react-dropzone";
import { ENDPOINT } from "../../helper/constant";
import { PromiseService } from "../../services/PromiseService";
const sha256 = require("js-sha256");
const MySwal = withReactContent(Swal);

function ManageUser() {
  const [active, setActive] = useState(1);
  const dispatch = useDispatch();
  const [actived, setActived] = useState(0);
  const [trigger, setTrigger] = useState(false);
  const [size, setSize] = useState(10); //pagination size
  const [showModal, setShowModal] = useState(false);
  const [selectedId, setSelectedId] = useState();
  const [searchValue, setSearchValue] = useState("");
  const [search, setSearch] = useState(""); //pagination size
  const [data, setData] = useState({
    company_id: "",
    email: "",
    name: "",
    password: "",
    birthday: "",
    gender: "",
  });
  const [dataCourse, setDataCourse] = useState({
    mandatory_courses: [
      {
        course_id: "",
        deadline: "",
      },
    ],
  });
  const [id, setId] = useState(0);
  const [courseSelect, setCourseSelect] = useState("ondemand");
  const history = useHistory();
  const [usersPagination, setUsersPagination] = useState(null);
  const [inputType, setInputType] = useState("manual");
  const [fileUpload, setFileUpload] = useState("");

  const users = useSelector((state) => state.users);
  const courses = useSelector((state) => state.courses);
  const courseLiveSearchData = courses.courseLiveSearchData;
  const usersData = users.usersData;
  useEffect(() => {
    AuthIsLogin(history, true);
    if (users.usersDataId?.user_id)
      dispatch(
        getUsersDataThunk({
          param: `?size=${size}&page=${active}&company_id=${users.usersDataId?.company_id}&role=student`,
        })
      );
  }, [, users.usersDataId]);

  useEffect(() => {
    setUsersPagination(users.usersData.pagination);
    if (users.usersDataId?.company_id) {
      const newData = { ...data };
      newData.company_id = users.usersDataId.company_name;
      setData(newData);
    }
  }, [users]);

  function paginationSelect(newActive) {
    setActive(newActive);
    dispatch(
      getUsersDataThunk({
        param: `?size=${size}&page=${newActive}&role=student`,
      })
    );
  }

  const handleModal = (idModal, idUser) => {
    setShowModal(true);
    setId(idModal);
    setSelectedId(idUser);
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const handleActive = (actived) => {
    setActived(actived);
  };

  const onChangeHandler = (e) => {
    const newData = { ...data };
    newData[e.target.id] = e.target.value;
    setData(newData);
  };
  const handleSelectedTask = (e) => {
    setCourseSelect(e.target.value);
  };
  const handleInputType = (e) => {
    setInputType(e.target.value);
  };

  function onSubmitHandler(e) {
    e.preventDefault();
    const form = e.target;

    const isValid = SkValidate(form, constraints);
    if (isValid) {
      let body = { ...data };
      body.password = sha256(data.email);
      body.company_id = users.usersDataId.company_id;
      body.role = "student";
      setTrigger(true);
      dispatch(postUsersDataThunk(body));
      setData({
        company_id: "",
        email: "",
        name: "",
        password: "",
        birthday: "",
        gender: "",
      });
    }
  }

  useEffect(() => {
    if (users.usersDataPost.status === "error" && trigger) {
      setTrigger(false);
      let msg =
        users.usersDataPost.value?.error?.response?.data ?? "Registrasi Gagal!";
      displayPopup(false, msg, true, "Oke");
    }
    if (users.usersDataPost === "success" && trigger) {
      setTrigger(false);
      displayPopup(false, "Registrasi Berhasil!", true, "Oke");
      setShowModal(false);
    }
    if (trigger)
      dispatch(
        getUsersDataThunk({
          param: `?size=${size}&page=${active}&role=student`,
        })
      );
  }, [users.usersDataPost, trigger]);

  const handleDelete = (id) => {
    MySwal.fire({
      title: "Anda yakin ingin menghapus ?",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Iya",
      cancelButtonText: "Tidak",
    }).then((value) => {
      if (value.isConfirmed) {
        setTrigger(true);
        dispatch(deleteUsersDataThunk(id));
      }
    });
  };

  const liveSearch = (e) => {
    setSearchValue(e.target.value);
  };

  const onKeyDownHanlder = (e) => {
    if (e.keyCode == 13) {
      if (courseSelect === "ondemand") {
        if (searchValue)
          dispatch(
            getCourseLiveSearchDataThunk(`?size=10&keyword=${searchValue}`)
          );
        else dispatch(removeCourseData());
      } else {
        if (searchValue)
          dispatch(
            getCourseLiveSearchDataThunk(
              `?size=10&keyword=${searchValue}&course_type=webinar`
            )
          );
      }
    }
  };

  function selectCourseHandler(value) {
    setDataCourse(value);
  }

  function removeCourseHandler() {
    setDataCourse({
      mandatory_courses: [
        {
          course_id: "",
          deadline: "",
        },
      ],
    });
  }

  function handleDropzone(files) {
    if (files[0]) {
      setFileUpload(files[0]);
    } else {
      displayPopup(
        true,
        "File yang akan di import tidak sesuai (Hanya .csv dengan format yang benar)",
        false
      );
    }
  }

  const handleChangeDeadline = (e) => {
    const newData = { ...dataCourse };
    newData[e.target.id] = e.target.value;
    setDataCourse(newData);
  };

  const onSubmitBulkImport = async (e) => {
    e.preventDefault();
    if (fileUpload) {
      const formData = new FormData();
      formData.append("upfile", fileUpload);
      const url = "v2/users/import";
      try {
        const response = await PromiseService.postFormData(url, formData);
        if (response?.status == 201) {
          dispatch(
            getUsersDataThunk({
              param: `?size=${size}&page=1&company_id=${users.usersDataId?.company_id}&role=student`,
            })
          );
          setShowModal(false);
        } else {
          displayPopup(true, response?.data || "Terjadi Kesalahan", false);
        }
      } catch (err) {
        displayPopup(true, err?.message || "Terjadi Kesalahan", false);
      }
    }
  };

  const handleSubmitTask = (e) => {
    e.preventDefault();
    setTrigger(true);
    const dataSend = { ...dataCourse };
    let req = {};
    req.body = {
      mandatory_courses: [
        {
          course_id: dataSend.course_id,
          deadline: `${dataSend.deadline} 00:00:00`,
        },
      ],
    };
    req.id = selectedId;
    dispatch(putUsersDataThunk(req));
  };

  useEffect(() => {
    if (showModal === false) {
      setSearchValue("");
      setDataCourse({
        mandatory_courses: [
          {
            course_id: "",
            deadline: "",
          },
        ],
      });
      dispatch(removeCourseData());
    }
  }, [showModal]);

  useEffect(() => {
    if (users.usersDataPut === "error" && trigger) {
      setTrigger(false);
      displayPopup(true, "Terjadi masalah saat menugaskan data", false);
    }
    if (users.usersDataPut === "success" && trigger) {
      setTrigger(false);
      displayPopup(false, "Berhasil memberi mandatory course", true, "Ok");
      setSearchValue("");
      setDataCourse({
        mandatory_courses: [
          {
            course_id: "",
            deadline: "",
          },
        ],
      });
      dispatch(removeCourseData());
      setShowModal(false);
    }
  }, [users, trigger]);

  function displayPopup(cancel, title, confirm, confirmMsg) {
    MySwal.fire({
      title: title,
      showCancelButton: cancel,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      showConfirmButton: confirm,
      confirmButtonText: confirmMsg,
    }).then((value) => {
      if (confirm) {
        dispatch(getUsersDataThunk(AuthData().userId));
        setShowModal(false);
      }
    });
  }

  function keydownHandler(e) {
    if (e.keyCode == 13) {
      setActive(1);
      dispatch(
        getUsersDataThunk({
          param: `?size=${size}&page=${active}&role=student&${
            search ? "&keyword=" + search : ""
          }`,
        })
      );
    }
  }

  function onChangeSearch(e) {
    const newValue = e.target.value;
    setSearch(newValue);
  }

  return (
    <Container fluid>
      <Row className="justify-content-center align-items-center height-inherit">
        <Col xs={12} md={6}>
          <div className=" pt-2 pb-3">
            <div className="course_create__title">Daftar Pengguna</div>
          </div>
        </Col>
        <Col
          xs={12}
          md={6}
          className="mb-4 text-right d-flex justify-content-end h-fit"
        >
          <Button
            className="mr-0 manage-button__style-add"
            onClick={() => handleModal(1)}
          >
            + Tambahkan Pengguna
          </Button>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12}>
          {" "}
          <Card className="card_container">
            <div className="card_inside__padding">
              <div className="w-25 mb-4">
                <Form.Control
                  style={{ flex: 0.5 }}
                  className="satukelas_input"
                  type="text"
                  id="search"
                  name="search"
                  placeholder={`Cari User...`}
                  value={search}
                  onKeyDown={keydownHandler}
                  onChange={onChangeSearch}
                />
              </div>
              <Table responsive striped bordered hover>
                <thead>
                  <tr>
                    <th>Nama</th>
                    <th>Jabatan</th>
                    <th>Membership</th>
                    <th>Email</th>
                    <th className="text-center">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {usersData?.items?.length > 0 ? (
                    usersData.items.map((item, i) => {
                      return (
                        <tr key={i}>
                          <td className="align-middle w-100">{item.name}</td>
                          <td className="text-nowrap align-middle">
                            {item.position}
                          </td>
                          <td className="text-nowrap align-middle">
                            {item.memberships.map((elm, idx) => (
                              <OverlayTrigger
                                overlay={
                                  <Tooltip id={`tooltip-1`}>
                                    {elm.membership_title}
                                  </Tooltip>
                                }
                              >
                                <Button className="mr-2 membership_badge">
                                  {elm.membership_title}
                                </Button>
                              </OverlayTrigger>
                            ))}
                          </td>
                          <td className="text-nowrap align-middle">
                            <span>{item.email}</span>
                          </td>

                          <td className="text-center align-middle d-flex">
                            <button
                              onClick={() => handleModal(0, item.user_id)}
                              className="btn btn-sm btn-primary manage-button__style"
                              type="button"
                              data-toggle="modal"
                              data-target="#largeModal"
                            >
                              + Tambah Course Wajib
                            </button>
                            <button
                              className="btn btn-sm btn-primary manage-button__style-trash"
                              type="button"
                              onClick={() => {
                                handleDelete(item.user_id);
                              }}
                            >
                              <FaTrashAlt className="fill-white size-svg" />
                            </button>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td className="text-center" colSpan={5}>
                        Data tidak di temukan
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
              <Row>
                <Col xs={12} lg={5} className="offset-lg-2">
                  {usersPagination ? (
                    <Pagination
                      size={size}
                      totalRows={usersPagination.totalItems}
                      totalPages={usersPagination.totalPages}
                      paginationSelect={paginationSelect}
                      active={active}
                    />
                  ) : null}
                </Col>
              </Row>
            </div>
          </Card>
        </Col>
      </Row>
      <Modal centered id="manage" show={showModal} onHide={handleClose}>
        <div className="modal-manage__task">
          {id === 0 ? (
            <>
              <Form onSubmit={handleSubmitTask}>
                <div className="modal-manage__task-title">Pelatihan</div>
                <Form.Group controlId="exampleForm.ControlSelect1">
                  <Form.Control
                    className="modal-manage__task-selector mb-3"
                    as="select"
                    onChange={handleSelectedTask}
                  >
                    <option value="ondemand">On Demand</option>
                    <option value="webinar">Webinar</option>
                  </Form.Control>
                </Form.Group>
                {courseSelect === "ondemand" ? (
                  <>
                    <div className="modal-manage__task-title">Pilih Konten</div>
                    <div className="modal-manage__task-filter__content">
                      <Button
                        id="0"
                        onClick={() => handleActive(0)}
                        className={`modal-manage__task-filter  mb-2 ${
                          actived == "0" ? "" : "non-active"
                        }`}
                      >
                        Pelatihan
                      </Button>
                    </div>
                  </>
                ) : null}

                <div className="modal-manage__task-title">
                  {courseSelect === "ondemand"
                    ? "Tipe Materi (Tekan enter untuk mencari)"
                    : "Webinar"}
                </div>
                {courseSelect === "ondemand" ? (
                  <>
                    {actived == 0 ? (
                      <>
                        {dataCourse.title !== "" ||
                        dataCourse.deadline !== "" ? (
                          <div className="selected-item-wrapper">
                            <p
                              className="selected-item"
                              onClick={() => removeCourseHandler()}
                            >
                              {dataCourse.title}
                            </p>
                          </div>
                        ) : null}

                        <Form.Control
                          type="text"
                          name="materi"
                          className="modal-manage__task-selector"
                          value={searchValue}
                          onChange={liveSearch}
                          onKeyDown={onKeyDownHanlder}
                          autoComplete="off"
                          autoCorrect="off"
                          spellCheck="off"
                          disabled={courses?.isLoadingLive}
                        />

                        {searchValue || courseLiveSearchData.items ? (
                          <div className="search-list">
                            {courses?.isLoadingLive ||
                            courseLiveSearchData.length <= 0 ? (
                              <div>
                                <p>Loading...</p>
                              </div>
                            ) : (
                              <ul className="list-wrapper">
                                {courseLiveSearchData.items?.length > 0 ? (
                                  courseLiveSearchData.items.map(
                                    (value, index) => {
                                      return (
                                        <li
                                          className={`list-item`}
                                          key={index}
                                          onClick={() =>
                                            selectCourseHandler(value)
                                          }
                                        >
                                          {value.title}
                                        </li>
                                      );
                                    }
                                  )
                                ) : (
                                  <p>not found</p>
                                )}
                              </ul>
                            )}
                          </div>
                        ) : null}
                        <div className="modal-manage__task-title">Deadline</div>
                        <Form.Group>
                          <Form.Control
                            className="mb-3 add-input__user"
                            type="date"
                            id="deadline"
                            name="deadline"
                            onChange={handleChangeDeadline}
                          />
                        </Form.Group>
                        <Button
                          type="submit"
                          className="modal-manage__task-button"
                          variant="primary"
                        >
                          Submit
                        </Button>
                      </>
                    ) : actived == 1 ? null : actived == 2 ? null : null}
                  </>
                ) : (
                  <>
                    {dataCourse.title !== "" || dataCourse.deadline !== "" ? (
                      <div className="selected-item-wrapper">
                        <p
                          className="selected-item"
                          onClick={() => removeCourseHandler()}
                        >
                          {dataCourse.title}
                        </p>
                      </div>
                    ) : null}
                    <Form.Group controlId="exampleForm.ControlSelect1">
                      <Form.Control
                        className="modal-manage__task-selector"
                        type="text"
                        name="webinar"
                        value={searchValue}
                        onChange={liveSearch}
                        onKeyDown={onKeyDownHanlder}
                        autoComplete="off"
                        autoCorrect="off"
                        spellCheck="off"
                        disabled={courses?.isLoadingLive}
                      />
                      {searchValue || courseLiveSearchData.items ? (
                        <div className="search-list mb-2">
                          {courses?.isLoadingLive ||
                          courseLiveSearchData.length <= 0 ? (
                            <div>
                              <p>Loading...</p>
                            </div>
                          ) : (
                            <ul className="list-wrapper">
                              {courseLiveSearchData.items?.length > 0 ? (
                                courseLiveSearchData.items.map(
                                  (value, index) => {
                                    return (
                                      <li
                                        className={`list-item`}
                                        key={index}
                                        onClick={() =>
                                          selectCourseHandler(value)
                                        }
                                      >
                                        {value.title}
                                      </li>
                                    );
                                  }
                                )
                              ) : (
                                <p>not found</p>
                              )}
                            </ul>
                          )}
                        </div>
                      ) : null}
                      <div className="modal-manage__task-title">Deadline</div>
                      <Form.Group>
                        <Form.Control
                          className="mb-3 add-input__user"
                          type="date"
                          id="deadline"
                          name="deadline"
                          onChange={handleChangeDeadline}
                        />
                      </Form.Group>
                    </Form.Group>
                    <Button
                      type="submit"
                      className="modal-manage__task-button"
                      variant="primary"
                    >
                      Submit
                    </Button>
                  </>
                )}
              </Form>
            </>
          ) : id === 1 ? (
            <div>
              <div className="modal-manage__task-title">Masukkan User Baru</div>
              <Form.Group controlId="exampleForm.ControlSelect1">
                <Form.Control
                  className="modal-manage__task-selector mb-3"
                  as="select"
                  onChange={handleInputType}
                >
                  <option value="manual">Manual Input</option>
                  <option value="bulk">Bulk Import</option>
                </Form.Control>
              </Form.Group>
              {inputType == "bulk" ? (
                <a href="https://satukelas-fe-asset.ap-south-1.linodeobjects.com/users_import.csv">
                  Download Template
                </a>
              ) : null}

              {inputType == "manual" ? (
                <Form onSubmit={onSubmitHandler}>
                  <Form.Group>
                    <Form.Label className="fw-600">
                      Nama <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      className="mb-3 add-input__user"
                      type="text"
                      id="name"
                      name="name"
                      onChange={onChangeHandler}
                      value={data.name}
                    />
                    <div className="messages"></div>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label className="fw-600">
                      Email <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      className="mb-3 add-input__user"
                      type="email"
                      id="email"
                      name="email"
                      onChange={onChangeHandler}
                      value={data.email}
                    />{" "}
                    <div className="messages"></div>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label className="fw-600">
                      Password <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      className="mb-3 add-input__user"
                      type="text"
                      id="email"
                      name="password"
                      onChange={onChangeHandler}
                      value={data.email}
                      readOnly
                    />{" "}
                    <div className="messages"></div>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label className="fw-600">Date of Birth </Form.Label>{" "}
                    <Form.Control
                      className="mb-3 add-input__user"
                      type="date"
                      id="birthday"
                      name="birthday"
                      onChange={onChangeHandler}
                      value={data.birthday}
                    />{" "}
                    <div className="messages"></div>
                  </Form.Group>
                  <Row>
                    <Col>
                      <Form.Group>
                        <Form.Label className="fw-600">Division </Form.Label>{" "}
                        <Form.Control
                          className="mb-3 add-input__user"
                          type="text"
                          id="division"
                          name="division"
                          onChange={onChangeHandler}
                          value={data.division}
                        />{" "}
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group>
                        <Form.Label className="fw-600">Position </Form.Label>{" "}
                        <Form.Control
                          className="mb-3 add-input__user"
                          type="text"
                          id="position"
                          name="position"
                          onChange={onChangeHandler}
                          value={data.position}
                        />{" "}
                      </Form.Group>
                    </Col>
                  </Row>
                  <Form.Group>
                    <Form.Label className="fw-600">Phone Number </Form.Label>{" "}
                    <Form.Control
                      className="mb-3 add-input__user"
                      type="text"
                      id="phone_number"
                      name="phone_number"
                      onChange={onChangeHandler}
                      value={data.phone_number}
                    />{" "}
                  </Form.Group>
                  <Form.Group>
                    <Form.Label className="fw-600">Gender</Form.Label>{" "}
                    <div>
                      <Form.Check
                        type="radio"
                        label="Laki-laki"
                        value="laki-laki"
                        name="gender"
                        id="Laki-laki"
                        inline
                        onChange={onChangeHandler}
                      />
                      <Form.Check
                        type="radio"
                        label="Perempuan"
                        id="Perempuan"
                        name="gender"
                        value="perempuan"
                        inline
                        onChange={onChangeHandler}
                      />
                    </div>
                  </Form.Group>
                  <Button
                    className="modal-manage__task-button mt-2"
                    variant="primary"
                    type="submit"
                  >
                    Masukkan
                  </Button>
                </Form>
              ) : (
                <Form onSubmit={onSubmitBulkImport}>
                  <div className="my-4">
                    <Dropzone accept=".csv" onDrop={handleDropzone}>
                      {({ getRootProps, getInputProps }) => (
                        <div className="container dropzone-style">
                          <div
                            {...getRootProps({
                              className: "dropzone",
                            })}
                          >
                            <input {...getInputProps()} />
                            <p>
                              Drag 'n' drop some files here, or click to select
                              files
                            </p>
                          </div>
                        </div>
                      )}
                    </Dropzone>
                    <div>{fileUpload?.path}</div>
                  </div>
                  <Button
                    className="modal-manage__task-button mt-2"
                    variant="primary"
                    type="submit"
                    disabled={!fileUpload}
                  >
                    Masukkan
                  </Button>
                </Form>
              )}
            </div>
          ) : (
            <Form>
              <div className="modal-manage__task-title">Pilih File</div>

              <Form.Group controlId="formFile" className="mb-3">
                <Form.Control type="file" />
              </Form.Group>

              <Button
                className="modal-manage__task-button"
                variant="primary"
                onClick={handleClose}
              >
                Unggah
              </Button>
            </Form>
          )}
        </div>
      </Modal>
    </Container>
  );
}

export default ManageUser;

const constraints = {
  email: {
    presence: true,
    length: {
      minimum: 3,
      maximum: 100,
    },
    email: true,
  },
  password: {
    presence: true,
    length: {
      minimum: 6,
      maximum: 100,
    },
  },
  name: {
    presence: true,
    length: {
      minimum: 3,
    },
  },
  birthday: {
    presence: false,
    length: {
      minimum: 5,
    },
  },
};
