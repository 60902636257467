import React from "react";
import { Image } from "react-bootstrap";

export default function AvgCourses(props) {
  const { title, value, icon, typeCourse, data } = props;

  return (
    <div className={`card-analitik _shadow`} style={{ height: "auto" }}>
      <div className="total-anggota_title">{title}</div>

      <div className="avg-score">
        <div className="avg-left">
          <div className="avg-left__count">
            {typeCourse == "tryout" ? data?.avg_tryout.toFixed(2) : data?.avg_exam.toFixed(2)}
          </div>
          <div className="avg-left__desc">
            Dengan rata-rata
            <b className="mx-1">
              <span>
                {typeCourse == "tryout"
                  ? data?.avg_tryout_attempt
                  : data?.avg_exam_attempt}
              </span>
            </b>
            kali percobaan.
          </div>
        </div>
        <div className="avg-right">
          <Image src={icon} width={18} height={18} />
        </div>
      </div>
    </div>
  );
}
