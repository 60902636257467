import React, { useState, useEffect } from "react";
import { Modal, Button, Accordion } from "react-bootstrap";
import moment from "moment";
function ModalPembayaran({
  content,
  paymentsMethod,
  handleVerify,
  handleCopy,
  id,
}) {
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
  };
  useEffect(() => {
    setShow(content.show);
  }, [content]);
  // useEffect(() => {}, [show]);
  return (
    <div>
      <Modal id="payments" show={show} onHide={handleClose} centered>
        <div className="card_inside__padding">
          <div className="d-flex flex-column flex-md-row justify-content-between border-bottom__modal">
            <div className="topup-item__info-top">
              Total {`${id === "tp" ? "Topup" : content.body?.description}`}
              <span>
                {content.body?.transaction_status ?? content.body?.status}
              </span>
            </div>
            <div className="topup-item__virtual-acc mr-2 mb-4">
              Rp. {content.body?.amount ?? content.body?.gross_amount}
            </div>
          </div>
          <div>
            {content.body?.payment_id === undefined
              ? content.body?.order_id && (
                <div className=" mt-3 d-flex justify-content-between align-items-center">
                  <div>Cek pembayaran</div>
                  <Button
                    className="check-payments"
                    onClick={() => handleVerify(content.body?.order_id)}
                  >
                    Cek{" "}
                  </Button>
                </div>
              )
              : content.body?.order_id === undefined
                ? content.body?.payment_id && (
                  <div className=" mt-3 d-flex justify-content-between align-items-center">
                    <div>Cek pembayaran</div>
                    <Button
                      className="check-payments"
                      onClick={() => handleVerify(content.body?.payment_id)}
                    >
                      Cek{" "}
                    </Button>
                  </div>
                )
                : null}
          </div>
          <div className="topup-item__info-bottom flex-column d-flex mt-4 mb-5">
            <div className="topup-item__info-top">
              No. virtual akun {content.body?.bank ? content.body?.bank : null}
            </div>
            <div className="d-flex va_container">
              <input
                id="va-number"
                value={content.body?.va_number}
                className="topup-item__virtual-acc mr-3"
                disabled
              />

              <div className="topup-item__copy-virtual__acc">
                <Button className="button_copy__va" onClick={handleCopy}>
                  Copy
                </Button>
              </div>
            </div>
            <div className="d-flex mt-1 justify-content-between">
              <div className="topup-item__info-top">Berlaku Sampai</div>
              <div className="topup-item__info-top fw-700">
                {
                  content.body?.transaction_time &&
                  moment(
                    moment(content.body.transaction_time).format(
                      "YYYY-MM-DD HH:mm:ss"
                    )
                  )
                    .add(1, "d")
                    .format("YYYY-MM-DD HH:mm:ss")

                  // && moment(data.created_at).clone().add(3, 'days') }
                }
              </div>
            </div>
          </div>
          <div className="topup-item__info-top">
            Paduan membayar melalui bank{" "}
            {content.body?.bank ? content.body?.bank : null}
          </div>

          {content.body?.bank === "mandiri"
            ? paymentsMethod.mandiri?.map((item, index) => (
              <Accordion key={index} className="my-2">
                <Accordion.Toggle
                  className="p-0 accordion_bank"
                  eventKey="0"
                >
                  <div>
                    <div className="bank_list text-left">{item.title}</div>
                  </div>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="0">
                  <div className=" d-flex cursor-pointer flex-column">
                    <ul id="bank_accordion">
                      {item.steps.map((e, i) => (
                        <li key={i}>{e}</li>
                      ))}
                    </ul>
                  </div>
                </Accordion.Collapse>
              </Accordion>
            ))
            : content.body?.bank === "bca"
              ? paymentsMethod.bca?.map((item, index) => (
                <Accordion key={index} defaultActiveKey="0" className="my-2">
                  <Accordion.Toggle
                    className="p-0 accordion_bank"
                    eventKey="0"
                  >
                    <div>
                      <div className="bank_list text-left">{item.title}</div>
                    </div>
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="0">
                    <div className=" d-flex cursor-pointer flex-column">
                      <ul id="bank_accordion">
                        {item.steps.map((e, i) => (
                          <li key={i}>{e}</li>
                        ))}
                      </ul>
                    </div>
                  </Accordion.Collapse>
                </Accordion>
              ))
              : content.body?.bank === "permata"
                ? paymentsMethod?.permata?.map((item, index) => (
                  <Accordion key={index} defaultActiveKey="0" className="my-2">
                    <Accordion.Toggle
                      className="p-0 accordion_bank"
                      eventKey="0"
                    >
                      <div>
                        <div className="bank_list text-left">{item.title}</div>
                      </div>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="0">
                      <div className=" d-flex cursor-pointer flex-column">
                        <ul id="bank_accordion">
                          {item.steps.map((e, i) => (
                            <li key={i}>{e}</li>
                          ))}
                        </ul>
                      </div>
                    </Accordion.Collapse>
                  </Accordion>
                ))
                : content.body?.bank === "bni"
                  ? paymentsMethod.bni?.map((item, index) => (
                    <Accordion key={index} defaultActiveKey="0" className="my-2">
                      <Accordion.Toggle
                        className="p-0 accordion_bank"
                        eventKey="0"
                      >
                        <div>
                          <div className="bank_list text-left">{item.title}</div>
                        </div>
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey="0">
                        <div className=" d-flex cursor-pointer flex-column">
                          <ul id="bank_accordion">
                            {item.steps.map((e, i) => (
                              <li key={i}>{e}</li>
                            ))}
                          </ul>
                        </div>
                      </Accordion.Collapse>
                    </Accordion>
                  ))
                  : null}
        </div>
      </Modal>
    </div>
  );
}
export default ModalPembayaran;
