import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import { AuthIsLogin } from "../helper/auth";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "../assets/css/MyCompany/mycompanyprofil.css";
import moment from "moment";
import { getCompaniesDataThunk } from "../redux/thunks/CompaniesThunks";
import MembershipActiveCard from "./MembershipActiveCard";
function MyCompanyProfil() {
  const users = useSelector((state) => state.users);
  const [company, setCompany] = useState([]);
  const dispatch = useDispatch();
  const [dataCompany, setDataCompany] = useState();
  const [membershipActive, setMembershipActive] = useState(null);
  const [data, setData] = useState();
  const [userCompanyId, setUserCompanyId] = useState("");
  const history = useHistory();
  const companies = useSelector((state) => state.companies);
  const userCompanyIdValue = users.usersDataId?.company_id;

  useEffect(() => {
    setUserCompanyId(userCompanyIdValue);
  }, [users]);

  useEffect(() => {
    console.log();
    if (companies?.companiesDataId?.length === 0) {
      if (
        users?.usersDataId &&
        users?.usersDataId?.company_id !== undefined &&
        users?.usersDataId?.company_id !== ""
      ) {
        dispatch(getCompaniesDataThunk(users?.usersDataId?.company_id));
      }
    }
  }, [users, companies]);

  useEffect(() => {
    if (companies?.companiesDataId) {
      setDataCompany(companies.companiesDataId);
    }
  }, [companies]);

  useEffect(() => {
    setData(users.usersDataId);
  }, [users]);

  useEffect(() => {
    if (users) {
      let membershipList = users.usersDataId?.memberships;
      for (let i = 0; i < membershipList?.length; i++) {
        if (membershipList[i].status === "active") {
          setMembershipActive(membershipList[i]);
        }
      }
    }
  }, [users]);

  // useEffect(() => {
  //   setDataCompany(companies.companiesDataId);
  // }, [companies]);

  useEffect(() => {
    AuthIsLogin(history);
  }, []);

  return (
    <Container>
      {userCompanyId === "" ? (
        <div className="not_found__data flex-column ">
          <div className="not_found__course text-center mb-3">
            Perusahaan Anda Belum Terdaftar
          </div>
          <Button href="/korporasi" className="width-fit percentage__button">
            Daftarkan Segera
          </Button>
        </div>
      ) : (
        <Row className="my-5">
          <Col md={9}>
            <div className="company-profil__container mb-4">
              <h2 className="h2 company-profil__title">Wajib Untuk di Ikuti</h2>
              <div className="company-prodil__mandatory-course">
                Data Belum Tersedia
              </div>
            </div>
            <div className="company-profil__container">
              <Card className="company-profil__contact-card">
                <div className="d-flex justify-content-between">
                  <div>
                    <h2 className="h2 company-profil__title text-white">
                      Kontak Admin?
                    </h2>
                    <Button
                      href={`tel:${dataCompany?.cp_phone}`}
                      // className="company-profil__membership-title__detail-white"
                      variant="warning"
                    >
                      Hubungi admin
                    </Button>
                  </div>
                  <div></div>
                </div>
              </Card>
            </div>
            {/* <div className="company-profil__wrapper-division">
            <h3 className="h3 company-profil__division-title">
              Divisi Perusahaan
            </h3>
            <div className="company-profil__table">
              <Tabs
                defaultActiveKey="divisi1"
                transition={false}
                id="noanim-tab-example"
              >
                <Tab
                  id="tab-unique"
                  tabClassName=""
                  eventKey="divisi1"
                  title="Keuangan"
                  className="mb-2 p-4 tab-style"
                >
                  <Table striped hover>
                    <thead>
                      <tr>
                        <th>Nama</th>
                        <th>Email</th>
                        <th>Aktif Terakhir</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>1</td>
                        <td>Mark</td>
                        <td>Otto</td>
                      </tr>
                      <tr>
                        <td>2</td>
                        <td>Jacob</td>
                        <td>Thornton</td>
                      </tr>
                      <tr>
                        <td>3</td>
                        <td>Larry the Bird</td>
                        <td>@twitter</td>
                      </tr>
                    </tbody>
                  </Table>
                </Tab>

                <Tab
                  id="tab-unique"
                  tabClassName="text-blacks"
                  eventKey="divisi2"
                  title="Pariwisata"
                  className="mb-2 p-4 tab-style"
                >
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>First Name</th>
                        <th>Last Name</th>
                        <th>Username</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>1</td>
                        <td>Mark</td>
                        <td>Otto</td>
                        <td>@mdo</td>
                      </tr>
                      <tr>
                        <td>2</td>
                        <td>Jacob</td>
                        <td>Thornton</td>
                        <td>@fat</td>
                      </tr>
                      <tr>
                        <td>3</td>
                        <td colSpan="2">Larry the Bird</td>
                        <td>@twitter</td>
                      </tr>
                    </tbody>
                  </Table>
                </Tab>
              </Tabs>
            </div>
          </div>
         */}
          </Col>
          <Col md={3}>
            <div className="company-profil__container">
              <div className="company-profil__wrapper-company">
                <div className="company-profil__image-profil">
                  <img
                    className="logo_company__size"
                    src={dataCompany?.logo}
                    alt="logo"
                  />
                </div>
                <div className="company-profil__company-name">
                  {dataCompany?.name}
                </div>
              </div>
              <MembershipActiveCard
                membershipActive={membershipActive}
                text="Membership"
              />
              <div className="company-profil__wrapper-leaderboard">
                <div className="company-profil__wrapper-title">
                  <div className="company-profil__container-leaderboard">
                    <div className="company-profil__leaderboard-title">
                      Leaderboard
                    </div>
                    {/* <div className="company-profil__leaderboard-subtitle">
                      Waktu Aktif Terbanyak
                    </div> */}
                  </div>
                  {/* <Button className="company-profil__filter-button">
                    Filter
                  </Button> */}
                </div>
                <Card className="company-profil__leaderboard-container">
                  Data Belum Tersedia
                </Card>
                {/* {[...Array(3)].map((item, i) => {
                  return (
                    <Card
                      key={i}
                      className="company-profil__leaderboard-container"
                    >
                      <div className="company-profil__wrapper-leaderboard-list">
                        <div className="company-profil__list-number">
                          {i + 1}
                        </div>
                        <div className="company-profil__list-wrap">
                          <div className="company-profil__name-list">
                            Muhammad Fitrah
                          </div>
                          <div className="company-profil__subtitle-list">
                            02j 04m 21d
                          </div>
                        </div>
                      </div>
                    </Card>
                  );
                })} */}
              </div>
            </div>
          </Col>
        </Row>
      )}
    </Container>
  );
}

export default MyCompanyProfil;
