import React, { useEffect, useState } from "react";
import { Breadcrumb, Button, Card, Col, Form, Image } from "react-bootstrap";
import { useDropzone } from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import MySwal from "sweetalert2";
import { SkValidate } from "../../../components/common/SkValidate";
import {
  getCitiesDataThunk,
  getProvincesDataThunk,
  getSubdistrictsDataThunk,
} from "../../../redux/thunks/OrdersThunks";
import {
  getUsersDataThunk,
  postUsersDataThunk,
} from "../../../redux/thunks/UsersThunks";
import { PromiseService } from "../../../services/PromiseService";
import "../../../assets/css/Dashboard/AddMember.css";
import IconDropzone from "../../../assets/images/icon-dropzone.svg";
import IconOverviewMan from "../../../assets/images/icon-overview.svg";
import IconEye from "../../../assets/images/icon-eye.svg";
import IconOffEye from "../../../assets/images/icon-eye-off.svg";
import IconConfirmSeru from "../../../assets/images/icon-confirm-seru.svg";
import IconConfirmSucces from "../../../assets/images/icon-confirm-succes.svg";
const sha256 = require("js-sha256");
function AddMember() {
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone();
  const [isActive, setIsActive] = useState("addMember");
  const [active, setActive] = useState(1);
  const [size, setSize] = useState(10);
  const [elmName, setElmName] = useState([]);
  const [trigger, setTrigger] = useState(false);
  const provinces = useSelector((state) => state.orders.ordersProvinces);
  const cities = useSelector((state) => state.orders.ordersCities);
  const subDistricts = useSelector((state) => state.orders.ordersSubdistricts);
  const users = useSelector((state) => state.users);
  const dispatch = useDispatch();
  const history = useHistory();
  const [showPw, setShowPw] = useState(false);
  const [countMember, setCountMember] = useState(1);
  const [massal, setMassal] = useState("");
  const [manualInput, setManualInput] = useState({
    address_city: "",
    address_province: "",
    address_street: "",
    address_subdistrict: "",
    avatar: "",
    company: "",
    company_id: "",
    description: "",
    division: "",
    education_level: "",
    email: "",
    favorite_topics: [],
    is_bnsp: 0,
    major: "",
    name: "",
    nationality: "",
    occupation: "",
    password: "",
    phone: "",
    position: "",
    role: "student",
    sex: "",
    social_media: [],
    university: "",
    zip_code: "",
  });
  const [matchedPassword, setMatchedPassword] = useState({
    password: manualInput.password,
    confirmPassword: "",
  });

  useEffect(() => {
    if (users.usersDataPost.status == "error" && trigger) {
      setTrigger(false);
      let msg =
        users.usersDataPost.value?.error?.response?.data.detail ??
        users.usersDataPost.value?.error?.response?.data;
      displayPopup(true, msg, false);
    }

    if (trigger && massal == "success") {
      setTrigger(false);
      customDisplayPopup(
        "Selamat!",
        `Anda Telah Berhasil Menambahkan banyak daya sebagai Anggota atau Admin Baru`,
        IconConfirmSucces,
        false,
        true,
        "Tutup",
        "#55AA73"
      );
    } else if (
      (trigger && massal == "failed") ||
      (trigger && massal == "error")
    ) {
      setTrigger(false);
      customDisplayPopup(
        "Failed!",
        `Terjadi Kesalahan saat menambahkan banyak data..`,
        "error",
        false,
        true,
        "Tutup",
        "#55AA73"
      );
    }

    if (users.usersDataPost === "success" && trigger) {
      setTrigger(false);
      customDisplayPopup(
        "Selamat!",
        `Anda Telah Berhasil Menambahkan <b>${manualInput.name}</b> sebagai ${
          manualInput.role == "student" ? "Anggota" : "Admin"
        }  Baru`,
        IconConfirmSucces,
        false,
        true,
        "Tutup",
        "#55AA73"
      );
    }

    if (trigger) {
      dispatch(
        getUsersDataThunk({
          param: `?size=${size}&page=${active}&role=student`,
        })
      );
    }
  }, [users.usersDataPost, trigger, massal]);

  useEffect(() => {
    if (acceptedFiles.length > 0) {
      if (acceptedFiles[0].type != "text/csv") {
        displayPopup(
          true,
          "File yang akan diimport tidak sesuai (Hanya .csv dengan format yang benar)",
          false
        );
      } else {
        const liItems = acceptedFiles.map((file) => {
          return <li key={file.path}>{file.path}</li>;
        });

        setElmName(liItems);
      }
    }
  }, [acceptedFiles]);

  useEffect(() => {
    dispatch(getProvincesDataThunk());
  }, []);

  const handleSubmitPerson = (e) => {
    e.preventDefault();
    const form = e.target;

    const isValid = SkValidate(form, constraints);
    if (isValid) {
      let body = { ...manualInput };
      body.password = sha256(manualInput.password);
      body.company_id = users.usersDataId.company_id;

      customDisplayPopup(
        `Apakah Anda Yakin untuk menambahkan ${
          manualInput.role == "student" ? "Anggota" : "Admin"
        } Baru?`,
        `Anda akan menambahkan <b>${manualInput.name}</b> sebagai ${
          manualInput.role == "student" ? "Anggota" : "Admin"
        } Baru`,
        IconConfirmSeru,
        true,
        true,
        `<p style='color:#818181;margin-bottom:0;width:180px'>Tidak</p>`,
        "#F2F2F2",
        body
      );
    }
  };

  function handleSubmitMassal(e) {
    e.preventDefault();
    if (acceptedFiles.length > 0) {
      customDisplayPopup(
        `Apakah Anda Yakin untuk menambahkan Admin atau Anggota Baru?`,
        `Anda akan menambahkan banyak data sebagai Admin atau Anggota Baru`,
        IconConfirmSeru,
        true,
        true,
        `<p style='color:#818181;margin-bottom:0;width:180px'>Tidak</p>`,
        "#F2F2F2"
      );
    }
  }

  const handleChangeInput = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setManualInput((prev) => ({ ...prev, [name]: value }));
    setMatchedPassword({
      ...matchedPassword,
      [name]: value,
    });

    if (name == "address_province") {
      dispatch(getCitiesDataThunk(value));
    }

    if (name == "address_city") {
      dispatch(getSubdistrictsDataThunk(value));
    }
  };

  function displayPopup(cancel, title, confirm, confirmMsg) {
    MySwal.fire({
      title: title,
      cancelButtonText: "Ok",
      showCancelButton: cancel,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      showConfirmButton: confirm,
      confirmButtonText: confirmMsg,
    }).then((value) => {
      if (confirm) {
        setElmName([]);
        history.push("/dashboard/management-organisasi");
      }
    });
  }

  function customDisplayPopup(
    title,
    subTitle,
    iconType,
    confirmBtn,
    cancelBtn,
    textCancel,
    cancelBgColor,
    body
  ) {
    MySwal.fire({
      title: title,
      html: subTitle,
      imageUrl: `${iconType}`,
      imageWidth: 122,
      imageHeight: 135,
      imageAlt: "Custom image",
      showConfirmButton: confirmBtn,
      confirmButtonColor: "#306CCC",
      confirmButtonText: "<p style='margin-bottom:0;width:180px'>Ya</p>",
      showCancelButton: cancelBtn,
      cancelButtonText: `<p style='margin-bottom:0;width:188px'>${textCancel}</p>`,
      cancelButtonColor: cancelBgColor,
    }).then(async (result) => {
      if (result.isConfirmed && isActive == "addMember") {
        setTrigger(true);
        dispatch(postUsersDataThunk(body));
      }

      if (result.isConfirmed && isActive == "addMassal") {
        const formData = new FormData();
        formData.append("upfile", acceptedFiles[0]);
        const url = "v2/users/import";
        try {
          const response = await PromiseService.postFormData(url, formData);
          if (response?.status == 201) {
            setTrigger(true);
            setMassal("success");
          } else {
            setTrigger(false);
            setMassal("failed");
          }
        } catch (err) {
          setTrigger(false);
          setMassal("error");
        }
      }

      if (result.isDismissed && textCancel == "Tutup") {
        history.push("/dashboard/management-organisasi");
      }
    });
  }

  function togglePassword() {
    setShowPw(!showPw);
  }

  return (
    <div className="px-3">
      <h3 className="title__page-addmember mb-4">Tambah Anggota</h3>
      <Breadcrumb>
        <Breadcrumb.Item href="#">
          <img
            src={IconOverviewMan}
            alt=""
            className="icon__breadcrumb"
            style={{ color: "#A6A6A6" }}
            height={24}
            width={24}
          />
        </Breadcrumb.Item>
        <Breadcrumb.Item
          href="/dashboard/management-organisasi"
          className="breadcrumb__list-nav-addmember"
        >
          Manajemen Organisasi
        </Breadcrumb.Item>
        <Breadcrumb.Item active className="breadcrumb__list-nav-addmember">
          Tambah Anggota
        </Breadcrumb.Item>
      </Breadcrumb>
      <Card>
        <Card.Header className="pb-0 px-0">
          <Col
            md={6}
            className={`d-flex flex-wrap justify-content-center card__header-addmember ${
              isActive === "addMember" ? "active" : ""
            }`}
            style={{ cursor: "pointer" }}
            onClick={() => setIsActive("addMember")}
          >
            <Card.Title className="title__card-addmember">
              Tambah Anggota
            </Card.Title>
          </Col>
          <Col
            md={6}
            className={`d-flex flex-wrap justify-content-center card__header-addmember ${
              isActive === "addMassal" ? "active" : ""
            }`}
            style={{ cursor: "pointer" }}
            onClick={() => setIsActive("addMassal")}
          >
            <Card.Title className="title__card-addmember">
              Tambah Massal
            </Card.Title>
          </Col>
        </Card.Header>
        <Card.Body>
          {isActive === "addMember" ? (
            <Form onSubmit={handleSubmitPerson}>
              <Col md={12} className="mb-4 d-flex flex-wrap">
                <Col lg={6}>
                  <h3 className="title__form-addmember">Tambah Individu</h3>
                </Col>
                <Col lg={12}>
                  <span className="subtitle__form-addmember">
                    Isi form dibawah untuk melakukan registrasikan akun karyawan
                    anda
                  </span>
                </Col>
              </Col>
              <Col md={12} className="mb-4 d-flex flex-wrap">
                <Col lg={6}>
                  <h3 className="title__form-addmember">Informasi Pribadi</h3>
                  <span className="subtitle__form-addmember">
                    Masukan Informasi Pribadi Karyawan.
                  </span>
                </Col>
                <Col lg={6}>
                  <Form.Group className="mb-3" controlId="formGroupKaryawan">
                    <Form.Label className="title__form-addmember">
                      Role
                    </Form.Label>
                    <Form.Control
                      autoComplete="off"
                      as="select"
                      defaultValue={"student"}
                      name="role"
                      onChange={handleChangeInput}
                    >
                      <option value={"student"}>Student</option>
                      <option value={"admin"}>Admin</option>
                    </Form.Control>
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formGroupKaryawan">
                    <Form.Label className="title__form-addmember">
                      Nama Karyawan <span className="text-danger ml-2">*</span>
                    </Form.Label>
                    <Form.Control
                      autoComplete="off"
                      type="text"
                      placeholder="Masukan Nama Karyawan"
                      name="name"
                      onChange={handleChangeInput}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label as="legend" className="title__form-addmember">
                      Jenis Kelamin <span className="text-danger ml-2">*</span>
                    </Form.Label>
                    <div>
                      <Form.Check
                        inline
                        type="radio"
                        label="Laki-laki"
                        id="inline-radio-1"
                        name="sex"
                        value={"laki-laki"}
                        onChange={(e) => handleChangeInput(e)}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="Perempuan"
                        id="inline-radio-2"
                        name="sex"
                        value={"perempuan"}
                        onChange={(e) => handleChangeInput(e)}
                      />
                    </div>
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formGroupKaryawan">
                    <Form.Label className="title__form-addmember">
                      No.Handphone <span className="text-danger ml-2">*</span>
                    </Form.Label>
                    <Form.Control
                      autoComplete="off"
                      type="text"
                      placeholder="Masukan No.Handphone"
                      name="phone"
                      onChange={handleChangeInput}
                    />
                  </Form.Group>
                </Col>
              </Col>
              <Col md={12} className="mb-4 d-flex flex-wrap">
                <Col lg={6}>
                  <h3 className="title__form-addmember">Informasi Alamat</h3>
                  <span className="subtitle__form-addmember">
                    Masukan Informasi Alamat Karyawan.
                  </span>
                </Col>
                <Col lg={6}>
                  <Form.Group className="mb-3" controlId="formGroupKaryawan">
                    <Form.Label className="title__form-addmember">
                      Alamat <span className="text__opsional">(Opsional)</span>
                    </Form.Label>
                    <Form.Control
                      autoComplete="off"
                      type="text"
                      placeholder="Masukan Nama Karyawan"
                      name="address_street"
                      onChange={handleChangeInput}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formGroupKaryawan">
                    <Form.Label className="title__form-addmember">
                      Provinsi{" "}
                      <span className="text__opsional">(Opsional)</span>
                    </Form.Label>
                    <Form.Control
                      autoComplete="off"
                      as="select"
                      onChange={handleChangeInput}
                      name="address_province"
                    >
                      <option value="-1" selected>
                        Pilih Provinsi
                      </option>
                      {provinces &&
                        provinces?.map((data) => (
                          <option
                            key={data.province_id}
                            value={data.province_id}
                          >
                            {data.province}
                          </option>
                        ))}
                    </Form.Control>
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formGroupKaryawan">
                    <Form.Label className="title__form-addmember">
                      Kota/Kabupaten{" "}
                      <span className="text__opsional">(Opsional)</span>
                    </Form.Label>
                    <Form.Control
                      autoComplete="off"
                      as="select"
                      name="address_city"
                      onChange={handleChangeInput}
                    >
                      <option value="-1" selected>
                        Pilih Kab/Kota
                      </option>
                      {cities &&
                        cities?.map((data) => (
                          <option key={data.city_id} value={data.city_id}>
                            {data.city_name}
                          </option>
                        ))}
                    </Form.Control>
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formGroupKaryawan">
                    <Form.Label className="title__form-addmember">
                      Kecamatan{" "}
                      <span className="text__opsional">(Opsional)</span>
                    </Form.Label>
                    <Form.Control
                      autoComplete="off"
                      as="select"
                      name="address_subdistrict"
                      onChange={handleChangeInput}
                    >
                      <option value="-1" selected>
                        Pilih Kecamatan
                      </option>
                      {subDistricts &&
                        subDistricts?.map((data) => (
                          <option
                            key={data.subdistrict_id}
                            value={data.subdistrict_id}
                          >
                            {data.subdistrict_name}
                          </option>
                        ))}
                    </Form.Control>
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formGroupKaryawan">
                    <Form.Label className="title__form-addmember">
                      Kelurahan{" "}
                      <span className="text__opsional">(Opsional)</span>
                    </Form.Label>
                    <Form.Control
                      autoComplete="off"
                      type="text"
                      placeholder="Masukan Kelurahan/Desa"
                      name="address_ward"
                      onChange={handleChangeInput}
                    ></Form.Control>
                  </Form.Group>
                </Col>
              </Col>
              <Col md={12} className="mb-4 d-flex flex-wrap">
                <Col lg={6}>
                  <h3 className="title__form-addmember">Informasi Pekerjaan</h3>
                  <span className="subtitle__form-addmember">
                    Tambahkan Informasi Pekerjaan Karyawan Anda.
                  </span>
                </Col>
                <Col lg={6}>
                  <Form.Group className="mb-3" controlId="formGroupKaryawan">
                    <Form.Label className="title__form-addmember">
                      Jabatan <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      autoComplete="off"
                      type="text"
                      placeholder="Masukan Jabatan Karyawan"
                      name="position"
                      onChange={handleChangeInput}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formGroupKaryawan">
                    <Form.Label className="title__form-addmember">
                      Divisi <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Masukan Divisi Karyawan"
                      name="division"
                      onChange={handleChangeInput}
                      autoComplete="off"
                    />
                  </Form.Group>
                </Col>
              </Col>
              <Col md={12} className="mb-4 d-flex flex-wrap">
                <Col lg={6}>
                  <h3 className="title__form-addmember">Informasi Akun</h3>
                  <span className="subtitle__form-addmember">
                    Tambahkan Informasi Akun Karyawan Anda.
                  </span>
                </Col>
                <Col lg={6}>
                  <Form.Group className="mb-3" controlId="formGroupKaryawan">
                    <Form.Label className="title__form-addmember">
                      Email <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      autoComplete="new-password"
                      type="email"
                      placeholder="Masukan Email"
                      name="email"
                      onChange={handleChangeInput}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formGroupKaryawan">
                    <Form.Label className="title__form-addmember">
                      Kata Sandi <span className="text-danger">*</span>
                    </Form.Label>
                    <div className="position-relative">
                      <Form.Control
                        autoComplete="new-password"
                        type={`${showPw ? "text" : "password"}`}
                        placeholder="Masukan Kata Sandi"
                        name="password"
                        onChange={handleChangeInput}
                      />
                      <div
                        className="position-absolute"
                        style={{
                          cursor: "pointer",
                          right: "10px",
                          top: "15px",
                        }}
                        onClick={() => togglePassword()}
                      >
                        <Image src={showPw ? IconEye : IconOffEye} />
                      </div>
                    </div>
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formGroupKaryawan">
                    <Form.Label className="title__form-addmember">
                      Konfirmasi Kata Sandi{" "}
                      <span className="text-danger">*</span>
                    </Form.Label>
                    <div className="position-relative">
                      <Form.Control
                        autoComplete="off"
                        type={`${showPw ? "text" : "password"}`}
                        placeholder="Konfirmasi Kata Sandi"
                        name="confirmPassword"
                        onChange={handleChangeInput}
                      />
                      <div
                        className="position-absolute"
                        style={{
                          cursor: "pointer",
                          right: "10px",
                          top: "15px",
                        }}
                        onClick={() => togglePassword()}
                      >
                        <Image src={showPw ? IconEye : IconOffEye} />
                      </div>
                    </div>
                    {matchedPassword.password !=
                      matchedPassword.confirmPassword && (
                      <span className="text-danger">Password Tidak Sama</span>
                    )}
                  </Form.Group>
                </Col>
              </Col>
              <Col md={12} className="mb-4 d-flex justify-content-end">
                <Link
                  to="/dashboard/management-organisasi"
                  className="btn__back-addmember mr-3 p-2"
                >
                  Kembali
                </Link>
                <Button
                  variant="primary"
                  type="submit"
                  className="btn__submit-addmember"
                >
                  Submit
                </Button>
              </Col>
            </Form>
          ) : (
            <Form onSubmit={handleSubmitMassal}>
              <Col md={12} className="mb-4 d-flex flex-wrap">
                <Col lg={6}>
                  <h3 className="title__form-addmember">Tambah Massal</h3>
                </Col>
                <Col lg={12}>
                  <span className="subtitle__form-addmember">
                    Unggah file csv untuk menambahkan anggota secara massal
                  </span>
                </Col>
              </Col>
              <Col md={12} className="mb-4 d-flex flex-wrap">
                <Col lg={6}>
                  <h3 className="title__form-addmember">Format</h3>
                  <span className="subtitle__form-addmember d-block">
                    Penambahan user secara massal dapat dilakukan menggunakan
                    template yang telah disediakan. Unduh template pada tautan
                    Unduh Template yang tersedia
                  </span>
                  <a
                    href="https://satukelas-fe-asset.ap-south-1.linodeobjects.com/users_import_template_v2.csv"
                    className="link__unduh-template"
                  >
                    Unduh Template
                  </a>
                </Col>
                <Col lg={6}>
                  <div className="container">
                    <div {...getRootProps({ className: "dropzone" })}>
                      <input {...getInputProps()} />
                      <div className="text-center">
                        <span className="d-block">
                          <img src={IconDropzone} alt="" />
                        </span>
                        <p className="text__dropzone">Klik untuk mengunggah</p>
                      </div>
                    </div>
                    <aside>
                      <ul>{elmName}</ul>
                    </aside>
                  </div>
                </Col>
              </Col>
              <Col md={12} className="mb-4 d-flex justify-content-end">
                <Link
                  to="/dashboard/management-organisasi"
                  className="btn__back-addmember mr-3 p-2"
                >
                  Kembali
                </Link>
                <Button
                  variant="primary"
                  type="submit"
                  className="btn__submit-addmember"
                >
                  Submit
                </Button>
              </Col>
            </Form>
          )}
        </Card.Body>
      </Card>
    </div>
  );
}
const constraints = {
  email: {
    presence: true,
    length: {
      minimum: 3,
      maximum: 100,
    },
    email: true,
  },
  password: {
    presence: true,
    length: {
      minimum: 6,
      maximum: 100,
    },
  },
  name: {
    presence: false,
    length: {
      minimum: 3,
    },
  },
  birthday: {
    presence: false,
    length: {
      minimum: 5,
    },
  },
};

export default AddMember;
