import React, { useEffect, useState } from "react";
import { Row, Col, Container, Card, Button, Breadcrumb } from "react-bootstrap";

import {
  getLearningPathData,
  putLearningPathData,
  deleteLearningPathData,
} from "../../redux/actions/LearningPathActions";
import { getLearningPathDataThunk } from "../../redux/thunks/LearningPathThunks";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useDispatch, useSelector } from "react-redux";
import { learning_paths, devlearning_paths } from "../../helper/constant";
import { BiPlayCircle, BiTime } from "react-icons/bi";
import { AuthIsLogin } from "../../helper/auth";
import { useHistory } from "react-router-dom";
import "../../assets/css/Dashboard/index.css";
import Pagination from "../../components/Layout/Pagination";

const MySwal = withReactContent(Swal);
function Path() {
  const [size, setSize] = useState(12); //pagination size
  const [active, setActive] = useState(1);
  const [trigger, setTrigger] = useState(false);
  const [learningPathPagination, setLearningPathPagination] = useState();
  const [learningPathData, setLearningPathData] = useState([]);
  const [learningPathDataId, setLearningPathDataId] = useState([]);
  const dispatch = useDispatch();
  const history = useHistory();
  const learning_paths = useSelector((state) => state.learning_paths);
  useEffect(() => {
    AuthIsLogin(history, true);
  }, []);
  useEffect(() => {
    dispatch(getLearningPathDataThunk({ param: `?size=${size}&page=${active}` }));
  }, []);
  useEffect(() => {
    setLearningPathData(learning_paths.learningPathDataGet.items);
    setLearningPathDataId(learning_paths.learningPathDataGetId);
    setLearningPathPagination(learning_paths.learningPathDataGet.pagination);
  }, [learning_paths]);
  useEffect(() => {
    if (learning_paths.learningPathDataDelete === "error" && trigger) {
      setTrigger(false);
      displayPopup(false, "Terjadi masalah saat submit data", "Ok");
    }
    if (learning_paths.learningPathDataDelete === "success" && trigger) {
      setTrigger(false);
      dispatch(getLearningPathDataThunk({ param: `?size=${size}&page=${active}` }));
    }
  }, [learning_paths]);
  function displayPopup(cancel, title, confirm) {
    MySwal.fire({
      title: title,
      showCancelButton: cancel,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: confirm,
    }).then((value) => {});
  }
  function paginationSelect(newActive) {
    setActive(newActive);
    dispatch(getLearningPathDataThunk({ param: `?size=${size}&page=${newActive}` }));
  }
  return (
    <Container>
      <Row>
        <Col md={6}>
          <div className="text-left">
            <div className="course_create__title mb-2">Learning Paths</div>
            <Breadcrumb className="mb-1">
              <Breadcrumb.Item href="#">Home</Breadcrumb.Item>
              <Breadcrumb.Item href="#">Learning Path</Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </Col>
        <Col md={6} className="text-right d-flex justify-content-end h-fit">
          <Button variant="outline-dark">COURSES</Button>
        </Col>
        {/* <Col md={12}>
          <div className="course_create__point mb-2 separator">
            Featured Paths
          </div>
        </Col> */}
        {learningPathData
          ? learningPathData.map((item, index) => {
              return (
                <Col key={index} md={4} className="mb-4">
                  <Card className="card_container mt-2">
                    <div className="card_inside__padding ">
                      <div className="d-flex mb-3 align-items-center justify-content-between">
                        <div className="d-flex">
                          <div className="fp-card-title mb-0">{item.title}</div>
                        </div>
                      </div>
                      <div className="d-flex justify-content-between my-3">
                        <div>
                          <Card.Text className="fp-card-sub-text fs-12">
                            <BiTime className="mr-1" />
                            50 menit tersisa
                          </Card.Text>
                        </div>
                        <div>
                          <Card.Text className="fp-card-sub-text fs-12">
                            <BiPlayCircle className="mr-1" />
                            {item.total_course} pelajaran
                          </Card.Text>
                        </div>
                      </div>
                      <div className="mb-3">
                        <Card.Text className="fp-card-text">
                          {item?.summary === "" ? "-" : item?.summary}
                        </Card.Text>
                      </div>
                      <div>
                        <Button
                          className="button_edit manage-button__style"
                          showModal={true}
                          href="/dashboard/table/learning-path"
                        >
                          Edit
                        </Button>
                      </div>
                    </div>
                  </Card>
                </Col>
              );
            })
          : null}
        <Col md={12} className="mb-5">
          {learningPathPagination ? (
            <Pagination
              size={size}
              totalRows={learningPathPagination.totalItems}
              totalPages={learningPathPagination.totalPages}
              paginationSelect={paginationSelect}
              active={active}
            />
          ) : null}
        </Col>
        {/* <Col md={12}>
          <h5 className="separator mb-3">Development Paths</h5>
        </Col>
        {devlearning_paths.map((item) => (
          <Col md={4} className="mb-4">
            <Card className="card_container mt-2">
              <div className="card_inside__padding ">
                <div className="d-flex align-items-center justify-content-between">
                  <div className="d-flex">
                    <Card.Img
                      src="https://luma-vue.demo.frontendmatter.com/images/paths/angular_64x64.svg"
                      className="fp-card-img w-25 mr-3"
                    />
                    <div>
                      <div className="fp-card-title">{item.title}</div>
                      <Card.Text className="fp-card-subtitle">
                        pelatihan
                      </Card.Text>
                    </div>
                  </div>

                  <Card.Text
                    variant="primary"
                    className="fp-card-hrt"
                    size="sm"
                  >
                    ❤︎
                  </Card.Text>
                </div>
              </div>
            </Card>
          </Col>
        ))}
        <Col md={12}>
          <h5 className="separator mb-3">Development Paths</h5>
        </Col>
        {devlearning_paths.map((item) => (
          <Col md={4} className="mb-4">
            <Card className="card_container mt-2">
              <div className="card_inside__padding ">
                <div className="d-flex align-items-center justify-content-between">
                  <div className="d-flex">
                    <Card.Img
                      src="https://luma-vue.demo.frontendmatter.com/images/paths/angular_64x64.svg"
                      className="fp-card-img w-25 mr-3"
                    />
                    <div>
                      <div className="fp-card-title">{item.title}</div>
                      <Card.Text className="fp-card-subtitle">
                        pelatihan
                      </Card.Text>
                    </div>
                  </div>

                  <Card.Text
                    variant="primary"
                    className="fp-card-hrt"
                    size="sm"
                  >
                    ❤︎
                  </Card.Text>
                </div>
              </div>
            </Card>
          </Col>
        ))}
        <Col md={12}>
          <h5 className="separator mb-3">Development Paths</h5>
        </Col>
        {devlearning_paths.map((item) => (
          <Col md={4} className="mb-4">
            <Card className="card_container mt-2">
              <div className="card_inside__padding ">
                <div className="d-flex align-items-center justify-content-between">
                  <div className="d-flex">
                    <Card.Img
                      src="https://luma-vue.demo.frontendmatter.com/images/paths/angular_64x64.svg"
                      className="fp-card-img w-25 mr-3"
                    />
                    <div>
                      <div className="fp-card-title">{item.title}</div>
                      <Card.Text className="fp-card-subtitle">
                        pelatihan
                      </Card.Text>
                    </div>
                  </div>

                  <Card.Text
                    variant="primary"
                    className="fp-card-hrt"
                    size="sm"
                  >
                    ❤︎
                  </Card.Text>
                </div>
              </div>
            </Card>
          </Col>
        ))} */}
      </Row>
    </Container>
  );
}

export default Path;
