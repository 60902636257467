import React from "react";
import { Table, Card, Row, Col } from "react-bootstrap";
import ReactECharts from "echarts-for-react";
import "../assets/css/Dashboard/companyAnalytics.css";

function DetailClass() {
  return (
    <>
      <Row>
        <Col md={12} className="mb-4">
          <Card className="card_container">
            <div className="card_inside__padding ">
              <div className="certificate-wrapper">
                <div className="wrapper-table__list">
                  <div className="img-certificate mr-3"></div>
                  <div className="certificate-title__wrap">
                    <div className="certificate-title__main">
                      Perpajakan dan Audit
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Card>
        </Col>
        <Col md={12} className="mb-4">
          {" "}
          <Card className="card_container">
            <div className="card_inside__padding ">
              <div className="d-flex justify-content-between border_card">
                <div className="card_subtitle mb-3">List Karyawan</div>
              </div>
              <Table responsive>
                <thead>
                  <th>Nama Karyawan</th>
                  <th>Waktu Mulai</th>
                  <th>Waktu Selesai</th>
                  <th>Rerata Kelulusan</th>
                </thead>
                <tr>
                  <td>
                    <div className="my-2">Thomas Dwi Agung</div>
                  </td>
                  <td>15/01/2021</td>
                  <td>23/01/2021</td>
                  <td>
                    <span className="container-avg__all">79.5%</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className="my-2">Hanif Reza Saputra</div>
                  </td>
                  <td>15/01/2021</td>
                  <td>23/01/2021</td>
                  <td>
                    <span className="container-avg__all">79.5%</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className="my-2">Savira Putri Utami</div>
                  </td>
                  <td>15/01/2021</td>
                  <td>23/01/2021</td>
                  <td>
                    <span className="container-avg__all">79.5%</span>
                  </td>
                </tr>
                {[...Array(1)].map((item, i) => {
                  return (
                    <tr key={i}>
                      <td>
                        <div className="my-2">Muhammad Firdan</div>
                      </td>
                      <td>15/01/2021</td>
                      <td>23/01/2021</td>
                      <td>
                        <span className="container-avg__all">79.5%</span>
                      </td>
                    </tr>
                  );
                })}
              </Table>
            </div>
          </Card>
        </Col>
        <Col md={6} className="mb-4">
          {" "}
          <Card className="card_container">
            <div className="card_inside__padding ">
              <div className="d-flex justify-content-between border_card">
                <div className="card_subtitle mb-3">Persentase Post Test</div>
              </div>

              <div className="mt-4">
                <ReactECharts
                  style={{ height: "400px", paddingBottom: "16px" }}
                  option={option3()}
                />
              </div>
            </div>
          </Card>
        </Col>
        <Col md={6} className="mb-4">
          {" "}
          <Card className="card_container">
            <div className="card_inside__padding ">
              <div className="d-flex justify-content-between border_card">
                <div className="card_subtitle mb-3">User Aktif</div>
              </div>
              <div className="mt-4">
                <ReactECharts style={{ height: "400px" }} option={option()} />
              </div>
            </div>
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default DetailClass;
function option() {
  return {
    legend: {
      top: "bottom",
    },
    toolbox: {
      show: true,
      feature: {
        mark: { show: true },
        dataView: { show: true, readOnly: false },
        saveAsImage: { show: true },
      },
    },
    series: [
      {
        name: "Status Post Test",
        type: "pie",
        radius: [50, 100],
        center: ["50%", "50%"],
        roseType: "area",
        itemStyle: {
          borderRadius: 8,
        },
        data: [
          { value: 10, name: "Lulus" },
          { value: 5, name: "Tidak Lulus" },
        ],
      },
    ],
  };
}
function option2() {
  return {
    dataset: {
      source: [
        ["score", "amount", "product"],
        [89.3, 58212, "Januari"],
        [57.1, 78254, "Febuari"],
        [74.4, 41032, "Maret"],
        [50.1, 12755, "April"],
        [89.7, 20145, "Mei"],
        [68.1, 79146, "Juni"],
        [19.6, 91852, "Juli"],
        [10.6, 101852, "Agustus"],
        [32.7, 20112, "September"],
        [32.7, 20112, "Oktober"],
        [32.7, 20112, "November"],
        [32.7, 20112, "Desember"],
      ],
    },
    grid: { containLabel: true },
    xAxis: { name: "amount" },
    yAxis: { type: "category" },
    visualMap: {
      orient: "horizontal",
      left: "center",
      min: 10,
      max: 100,
      // Map the score column to color
      dimension: 0,
      inRange: {
        color: ["#65B581", "#FFCE34", "#FD665F"],
      },
    },
    series: [
      {
        type: "bar",
        encode: {
          // Map the "amount" column to X axis.
          x: "amount",
          // Map the "product" column to Y axis
          y: "product",
        },
      },
    ],
  };
}
function option5() {
  return {
    tooltip: {
      trigger: "axis",
    },
    legend: {
      data: ["Pelatihan yang Selesai"],
    },
    grid: {
      left: "3%",
      right: "4%",
      bottom: "3%",
      containLabel: true,
    },
    toolbox: {
      feature: {
        saveAsImage: {},
      },
    },
    xAxis: {
      type: "category",
      boundaryGap: false,
      data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
    },
    yAxis: {
      type: "value",
    },
    series: [
      {
        name: "Pelatihan yang Selesai",
        type: "line",
        stack: "Total",
        data: [120, 132, 101, 134, 90, 230, 210],
      },
    ],
  };
}
let xAxisData = [];
let data1 = [];
let data2 = [];
var emphasisStyle = {
  itemStyle: {
    shadowBlur: 10,
    shadowColor: "rgba(0,0,0,0.3)",
  },
};
for (let i = 0; i < 10; i++) {
  xAxisData.push("Class" + i);
  data1.push(+(Math.random() * 2).toFixed(2));
  data2.push(+(Math.random() * 5).toFixed(2));
}

function option3() {
  return {
    legend: {
      data: ["bar", "bar2"],
      top: "bottom",
    },
    brush: {
      toolbox: ["rect", "polygon", "lineX", "lineY", "keep", "clear"],
      xAxisIndex: 0,
    },
    toolbox: {
      feature: {
        magicType: {
          type: ["stack"],
        },
        dataView: {},
      },
    },
    tooltip: {},
    xAxis: {
      data: xAxisData,
      name: "X Axis",
      axisLine: { onZero: true },
      splitLine: { show: false },
      splitArea: { show: false },
    },
    yAxis: {},
    grid: {
      bottom: 100,
    },
    series: [
      {
        name: "bar",
        type: "bar",
        stack: "one",
        emphasis: emphasisStyle,
        data: data1,
      },
      {
        name: "bar2",
        type: "bar",
        stack: "one",
        emphasis: emphasisStyle,
        data: data2,
      },
    ],
  };
}
