import React, { useEffect, useState } from "react";
import { Breadcrumb, Button, Card, Col, Form, Image } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import MySwal from "sweetalert2";
import "../../../assets/css/Dashboard/AddMember.css";
import IconOverviewMan from "../../../assets/images/icon-overview.svg";
import { AuthUsersDetail } from "../../../http/AuthUsersDetail";
import IconConfirmSucces from "../../../assets/images/icon-confirm-succes.svg";
import IconConfirmFailed from "../../../assets/images/icon-confirm-fail.svg";
const sha256 = require("js-sha256");
function EditCompanies() {
  const [trigger, setTrigger] = useState(false);
  const users = useSelector((state) => state.users);
  const history = useHistory();
  const [editInput, setEditInput] = useState({});
  const [picture, setPicture] = useState(null);
  const [imgData, setImgData] = useState(null);

  useEffect(async () => {
    if (users.usersDataId.company_id) {
      try {
        const response = await AuthUsersDetail.getCompanyID(
          users?.usersDataId?.company_id
        );

        setEditInput(response);
      } catch (error) {
        console.error(error);
      }
    }
  }, [users.usersDataId.company_id]);

  const handleEditCompany = async (e) => {
    e.preventDefault();

    try {
      let data = { body: { ...editInput } };
      const fd = new FormData();
      fd.append("upfile", picture);
      const responseCompany = await AuthUsersDetail.postLogoCompany(fd);

      data.id = users.usersDataId.company_id;
      data.body.logo = responseCompany.url;
      const response = await AuthUsersDetail.putCompany(data.id, data.body);
      if (response) {
        customDisplayPopup(
          "Selamat!",
          `Anda Telah Berhasil Mengubah Informasi Organisasi Anda`,
          IconConfirmSucces,
          false,
          true,
          "Tutup",
          "#55AA73"
        );
      } else {
        customDisplayPopup(
          "Gagal mengubah informasi",
          `Anda gagal untuk mengubah informasi organisasi anda silahkan periksa kembali informasi yang Anda masukan`,
          IconConfirmFailed,
          false,
          true,
          "Tutup",
          "#CB3A31"
        );
      }
    } catch (error) {
      displayPopup(true, "Terjadi Kesalahan!", false);
      console.error(error);
    }
  };

  const handleChangeInput = (e) => {
    const { name, value } = e.target;

    if (name == "logo") {
      setPicture(e.target.files[0]);
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setImgData(reader.result);
      });
      reader.readAsDataURL(e.target.files[0]);
    } else {
      setEditInput({ ...editInput, [name]: value });
    }
  };

  function displayPopup(cancel, title, confirm, confirmMsg) {
    MySwal.fire({
      title: title,
      cancelButtonText: "Ok",
      showCancelButton: cancel,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      showConfirmButton: confirm,
      confirmButtonText: confirmMsg,
    }).then((value) => {
      if (confirm) {
        history.push("/dashboard/management-organisasi");
      }
    });
  }

  function customDisplayPopup(
    title,
    subTitle,
    iconType,
    confirmBtn,
    cancelBtn,
    textCancel,
    cancelBgColor
  ) {
    MySwal.fire({
      title: title,
      html: subTitle,
      imageUrl: `${iconType}`,
      imageWidth: 122,
      imageHeight: 135,
      imageAlt: "Custom image",
      showConfirmButton: confirmBtn,
      confirmButtonColor: "#FF8500",
      showCancelButton: cancelBtn,
      cancelButtonText: textCancel,
      cancelButtonColor: cancelBgColor,
    }).then((result) => {
      if (
        (result.isDismissed && result.dismiss == "cancel") ||
        result.dismiss == "backdrop"
      ) {
        history.push("/dashboard/management-organisasi");
      }
    });
  }

  return (
    <div className="px-3">
      <h3 className="title__page-addmember mb-4">Tambah Anggota</h3>
      <Breadcrumb>
        <Breadcrumb.Item href="#">
          <img
            src={IconOverviewMan}
            alt=""
            className="icon__breadcrumb"
            style={{ color: "#A6A6A6" }}
            height={24}
            width={24}
          />
        </Breadcrumb.Item>
        <Breadcrumb.Item
          href="/dashboard/management-organisasi"
          className="breadcrumb__list-nav-addmember"
        >
          Manajemen Organisasi
        </Breadcrumb.Item>
        <Breadcrumb.Item active className="breadcrumb__list-nav-addmember">
          Ubah Profile Organisasi
        </Breadcrumb.Item>
      </Breadcrumb>
      <Card>
        <Card.Body>
          <Form onSubmit={handleEditCompany}>
            <Col md={12} className="mb-4 d-flex flex-wrap">
              <Col lg={6}>
                <h3 className="title__form-addmember">
                  Silahkan Ubah Informasi Organisasi
                </h3>
              </Col>
              <Col lg={12}>
                <span className="subtitle__form-addmember">
                  Isi form dibawah ini untuk mengubah informasi organisasi anda
                </span>
              </Col>
            </Col>
            <Col md={12} className="mb-4 d-flex flex-wrap">
              <Col lg={6}>
                <h3 className="title__form-addmember">Informasi Organisasi</h3>
                <span className="subtitle__form-addmember">
                  Masukan Informasi Organisasi Anda.
                </span>
              </Col>
              <Col lg={6}>
                <Form.Group className="mb-3" controlId="formGroupKaryawan">
                  <Form.Label className="title__form-addmember">
                    Nama Organisasi
                  </Form.Label>
                  <Form.Control
                    autoComplete="off"
                    type="text"
                    placeholder="PT Akselerasi Edukasi Internasional"
                    name="name"
                    value={editInput.name}
                    onChange={handleChangeInput}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formGroupKaryawan">
                  <Form.Label className="title__form-addmember">
                    Alamat Organisasi{" "}
                  </Form.Label>
                  <Form.Control
                    autoComplete="off"
                    type="text"
                    placeholder="Jl. Ir. H. Djuanda No. 299, Bandung, Jawa Barat 40135"
                    name="address"
                    value={editInput.address}
                    onChange={handleChangeInput}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formUpload">
                  <Form.Label className="title__form-addmember">
                    Masukan Logo Organisasi
                  </Form.Label>
                  <div
                    className="d-flex align-items-center"
                    style={{ gap: 10 }}
                  >
                    <Image
                      src={imgData ? imgData : editInput.logo}
                      width={70}
                      height={70}
                    />
                    <label htmlFor="formFile" className="btn__label-upload">
                      + Unggah Logo
                    </label>
                    <input
                      type="file"
                      name="logo"
                      id="formFile"
                      style={{ visibility: "hidden" }}
                      onChange={handleChangeInput}
                    />
                  </div>
                </Form.Group>
              </Col>
            </Col>
            <Col md={12} className="mb-4 d-flex flex-wrap">
              <Col lg={6}>
                <h3 className="title__form-addmember">Informasi Kontak</h3>
                <span className="subtitle__form-addmember">
                  Tambahkan Informasi Kontak Personal Organisasi Anda
                </span>
              </Col>
              <Col lg={6}>
                <Form.Group className="mb-3" controlId="formGroupKaryawan">
                  <Form.Label className="title__form-addmember">
                    Nama Kontak Personal
                  </Form.Label>
                  <Form.Control
                    autoComplete="off"
                    type="text"
                    placeholder="Budi Hartanto"
                    name="cp_name"
                    value={editInput.cp_name}
                    onChange={handleChangeInput}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formGroupKaryawan">
                  <Form.Label className="title__form-addmember">
                    No Telepon
                  </Form.Label>
                  <Form.Control
                    autoComplete="off"
                    type="text"
                    placeholder="085773736973"
                    name="cp_phone"
                    value={editInput.cp_phone}
                    onChange={handleChangeInput}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formGroupKaryawan">
                  <Form.Label className="title__form-addmember">
                    Email
                  </Form.Label>
                  <Form.Control
                    autoComplete="off"
                    type="text"
                    placeholder="budihartono@gmail.com"
                    name="cp_email"
                    value={editInput.cp_email}
                    onChange={handleChangeInput}
                  />
                </Form.Group>
              </Col>
            </Col>
            <Col md={12} className="mb-4 d-flex justify-content-end">
              <Link
                to="/dashboard/management-organisasi"
                className="btn__back-addmember mr-3 p-2"
              >
                Kembali
              </Link>
              <Button
                variant="primary"
                type="submit"
                className="btn__submit-addmember"
              >
                Simpan
              </Button>
            </Col>
          </Form>
        </Card.Body>
      </Card>
    </div>
  );
}

export default EditCompanies;
