export const WEBINAR_DATA_LINK = "webinars";

//get
export const WEBINAR_DATA = "WEBINAR_DATA";
export const WEBINAR_DATA_SUCCESS = "WEBINAR_DATA_SUCCESS";
export const WEBINAR_DATA_ID_SUCCESS = "WEBINAR_DATA_ID_SUCCESS";
export const WEBINAR_DATA_FAIL = "WEBINAR_DATA_FAIL";

//post
export const WEBINAR_DATA_POST = "WEBINAR_DATA_POST";
export const WEBINAR_DATA_POST_SUCCESS = "WEBINAR_DATA_POST_SUCCESS";
export const WEBINAR_DATA_POST_FAIL = "WEBINAR_DATA_POST_FAIL";

// put
export const WEBINAR_DATA_PUT = "WEBINAR_DATA_PUT";
export const WEBINAR_DATA_PUT_SUCCESS = "WEBINAR_DATA_PUT_SUCCESS";
export const WEBINAR_DATA_PUT_FAIL = "WEBINAR_DATA_PUT_FAIL";

// delete
export const WEBINAR_DATA_DELETE = "WEBINAR_DATA_DELETE";
export const WEBINAR_DATA_DELETE_SUCCESS = "WEBINAR_DATA_DELETE_SUCCESS";
export const WEBINAR_DATA_DELETE_FAIL = "WEBINAR_DATA_DELETE_FAIL";
