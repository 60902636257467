import React from "react";
import { Card } from "react-bootstrap";
import ReactECharts from "echarts-for-react";
import "../assets/css/Dashboard/companyAnalytics.css";
function StatusPreTest({ data }) {
  function option() {
    return {
      tooltip: {
        trigger: "item",
        formatter: "{a} <br/>{b} : {c} ({d}%)",
      },
      label: {
        show: false,
      },
      series: [
        {
          name: "Status Pre Test",
          type: "pie",
          radius: [50, 100],
          center: ["50%", "50%"],
          roseType: "radius",
          itemStyle: {
            borderRadius: 8,
          },
          emphasis: {
            label: {
              show: true,
            },
          },
          data: [
            // { value: 10, name: "Belum ditemukan" },
            { value: data?.chart_pre_test?.passed?.nominal, name: "Lulus" },
            {
              value: data?.chart_pre_test?.failed?.nominal,
              name: "Tidak Lulus",
            },
          ],
          color: ["#91CC75", "#EE4253"],
        },
      ],
    };
  }

  return (
    <>
      <Card className="card_container height-card__tryout">
        <div className="card_inside__padding ">
          <div className="d-flex justify-content-between border_card">
            <div className="card_title__info mb-3">Status Pre Test</div>
          </div>

          <ReactECharts option={option()} />
        </div>
        {/* <div className="loading_status">
          <div className="loading_status__progress">
            <Spinner animation="border" />
            Sistem sedang
            <br />
            mengolah data
          </div>
        </div> */}
      </Card>
    </>
  );
}

export default StatusPreTest;
