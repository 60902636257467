/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import "../../assets/css/Layout/header.css";
import styled from "styled-components";
import { AuthData } from "../../helper/auth";
import { FaUserAlt, FaBuilding } from "react-icons/fa";
import { RiLogoutCircleRLine, RiSettings3Fill } from "react-icons/ri";
import { useHistory, Link } from "react-router-dom";
import { removeUsersDataId } from "../../redux/actions/UsersActions";
import { AuthLogout } from "../../helper/auth";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);
function Header({
  isSidebar,
  handleToggleSidebar,
  collapsed,
  handleCollapsedChange,
}) {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const history = useHistory();

  const dispatch = useDispatch();
  const handleLogoutClick = () =>
    MySwal.fire({
      title: "Apakah anda yakin ingin keluar?",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: "Tidak",
      confirmButtonText: "Ya",
    }).then((value) => {
      if (value.isConfirmed) {
        dispatch(removeUsersDataId());
        AuthLogout(history);
      }
    });
  const users = useSelector((state) => state.users);
  const mySelf = users.usersDataId;
  useEffect(() => {
    const nameUser = users.usersDataId.username;
    const email = users.usersDataId.email;
    setName(nameUser);
    setEmail(email);
  }, [users]);

  const companies = useSelector((state) => state.companies);
  const myCompany = companies.companiesDataId;

  useEffect(() => {
    if (mySelf?.user_id) {
      // const companyID = mySelf?.company_id;
      // dispatch(getCompaniesDataThunk(companyID));
    }
  }, [mySelf]);
  return (
    <StyledWrapperMain>
      <div className="main-content">
        <div className="middle-sidebar-header bg-white">
          {/* <div onClick={() => handleCollapsedChange(!collapsed)}>
            <FaBars className="fill-blue expandable-bars mr-2" />
          </div> */}
          <div className="d-flex align-items-center">
            <div>
              {myCompany?.logo ? (
                <img className="img_logo" src={myCompany.logo} />
              ) : null}
            </div>
            <img
              src="https://old.satukelas.com/wp-content/uploads/2021/02/png-satukelas-296x63.png"
              alt="Satukelas"
              className="satu-kelas-logo"
            />
          </div>
          <ul className="d-flex ml-auto right-menu-icon m-0">
            {/* <li>
              <Form.Control
                className="satukelas_input"
                type="text"
                placeholder="Cari Pelatihan"
              />
            </li> */}
            {AuthData()?.role === "instructor"
              ? ""
              : null
              // <li>
              //   <div>
              //     <span className="dot-count bg-warning"></span>
              //     <FaBell />
              //     <div className="menu-dropdown">
              //       <h4 className="notification_text mb-4">Notification</h4>

              //       <div className="card box-none bg-transparent-card w-100 border-0 pl-5 mb-3">
              //         <Link to="#">See All</Link>
              //       </div>
              //     </div>
              //   </div>
              // </li>
            }

            <li>
              <div>
                {mySelf?.avatar === "" ? (
                  <div className="container-user grey">
                    <FaUserAlt className="size-profile" />
                  </div>
                ) : (
                  <img
                    src={`${mySelf?.avatar}`}
                    alt="user"
                    className="w40 rounded-circle mt--1"
                  />
                )}
                <div className="menu-dropdown menu-dropdown-profile">
                  <div className="menu_name">{name}</div>
                  <br />
                  <div className="email-text mb-3">{email}</div>
                  <Link className="dropdown-item px-0" to="/dashboard/profile/">
                    <i className="dropdown-icon mdi">
                      <FaUserAlt />
                    </i>
                    Profil {name}
                  </Link>
                  {AuthData()?.role === "instructor" ? (
                    ""
                  ) : (
                    <>
                      <Link
                        className="dropdown-item px-0"
                        to="/company-profile"
                      >
                        <i className="dropdown-icon mdi">
                          <FaBuilding />
                        </i>
                        Perusahaan Saya
                      </Link>
                      <Link
                        className="dropdown-item px-0"
                        to="/dashboard/settings/"
                      >
                        <i className="dropdown-icon mdi">
                          <RiSettings3Fill />
                        </i>
                        Pengaturan
                      </Link>
                    </>
                  )}

                  <a
                    className="dropdown-item logout-text px-0"
                    onClick={() => handleLogoutClick()}
                  >
                    <i className="dropdown-icon mdi">
                      <RiLogoutCircleRLine />
                    </i>
                    Keluar
                  </a>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </StyledWrapperMain>
  );
}

export default Header;

const StyledWrapperMain = styled.section`
  svg {
    height: 32px;
    width: 32px;
  }
  .main-content {
    border-style: solid;
    border-width: 0px 0px 1px 0px;
    border-color: #00000011;
  }
`;
