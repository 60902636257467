import { PromiseService } from "../../services/PromiseService";
import { QUIZ_THEME_DATA_LINK } from "../config/QuizThemeConfig";
import {
  //get
  getQuizThemeData,
  getQuizThemeDataSuccess,
  getQuizThemeDataIdSuccess,
  getQuizThemeDataFail,
  //get
  getQuizThemeLiveSearchData,
  getQuizThemeLiveSearchDataSuccess,
  getQuizThemeLiveSearchDataFail,
  //post
  postQuizThemeData,
  postQuizThemeDataSuccess,
  postQuizThemeDataFail,
} from "../actions/QuizThemeActions";

export const postQuizThemeDataThunk = (data) => async (dispatch) => {
  dispatch(postQuizThemeData());
  const body = data;
  try {
    const response = await PromiseService.postData(QUIZ_THEME_DATA_LINK, body);
    if (response.status === 201 && response.data) {
      dispatch(postQuizThemeDataSuccess(response.data));
    } else {
      dispatch(postQuizThemeDataFail(response));
    }
  } catch (error) {
    dispatch(postQuizThemeDataFail(error));
  }
};

export const getQuizThemeDataThunk = (data) => async (dispatch) => {
  dispatch(getQuizThemeData(data));
  if (data) {
    let url = `${QUIZ_THEME_DATA_LINK}/${data}`;
    if (data.param) url = `${QUIZ_THEME_DATA_LINK}${data.param}`;
    try {
      const response = await PromiseService.getData(url);
      if (response.status === 200 && response.data) {
        if (data.param) dispatch(getQuizThemeDataSuccess(response.data));
        else dispatch(getQuizThemeDataIdSuccess(response.data));
      } else {
        dispatch(getQuizThemeDataFail(response));
      }
    } catch (error) {
      dispatch(getQuizThemeDataFail(error));
    }
  } else {
    try {
      const response = await PromiseService.getData(QUIZ_THEME_DATA_LINK);
      if (response.status === 200 && response.data) {
        dispatch(getQuizThemeDataSuccess(response.data));
      } else {
        dispatch(getQuizThemeDataFail(response));
      }
    } catch (error) {
      dispatch(getQuizThemeDataFail(error));
    }
  }
};
//get live search

export const getQuizThemeLiveSearchDataThunk = (data) => async (dispatch) => {
  dispatch(getQuizThemeLiveSearchData(data));
  let url = `${QUIZ_THEME_DATA_LINK}${data}`;
  try {
    const response = await PromiseService.getData(url);
    if (response.status === 200 && response.data) {
      dispatch(getQuizThemeLiveSearchDataSuccess(response.data));
    } else {
      dispatch(getQuizThemeLiveSearchDataFail(response));
    }
  } catch (error) {
    dispatch(getQuizThemeLiveSearchDataFail(error));
  }
};