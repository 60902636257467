import {
  //get
  WEBINAR_DATA,
  WEBINAR_DATA_ID_SUCCESS,
  WEBINAR_DATA_SUCCESS,
  WEBINAR_DATA_FAIL,
  //post
  WEBINAR_DATA_POST,
  WEBINAR_DATA_POST_SUCCESS,
  WEBINAR_DATA_POST_FAIL,
  //put
  WEBINAR_DATA_PUT,
  WEBINAR_DATA_PUT_SUCCESS,
  WEBINAR_DATA_PUT_FAIL,
  //delete
  WEBINAR_DATA_DELETE,
  WEBINAR_DATA_DELETE_SUCCESS,
  WEBINAR_DATA_DELETE_FAIL,
} from "../config/WebinarConfig";

const intialState = {
  webinarDataPost: "",
  webinarDataPut: "",
  webinarDataDelete: "",
  webinarData: [],
  webinarDataId: [],
  isLoading: false,
};

export const WebinarReducers = function (state = intialState, action) {
  switch (action.type) {
    //get
    case WEBINAR_DATA:
      return { ...state, isLoading: true };
    case WEBINAR_DATA_SUCCESS:
      return { ...state, isLoading: false, webinarData: action.value };
    case WEBINAR_DATA_ID_SUCCESS:
      return { ...state, isLoading: false, webinarDataId: action.value };
    case WEBINAR_DATA_FAIL:
      return {
        ...state,
        isLoading: false,
        webinarData: "error",
        webinarDataId: "error",
      };
    //post
    case WEBINAR_DATA_POST:
      return { ...state, isLoading: true, webinarDataPost: "" };
    case WEBINAR_DATA_POST_SUCCESS:
      return { ...state, isLoading: false, webinarDataPost: "success" };
    case WEBINAR_DATA_POST_FAIL:
      return { ...state, isLoading: false, webinarDataPost: "error" };

    //put
    case WEBINAR_DATA_PUT:
      return { ...state, isLoading: true, webinarDataPut: "" };
    case WEBINAR_DATA_PUT_SUCCESS:
      return { ...state, isLoading: false, webinarDataPut: "success" };
    case WEBINAR_DATA_PUT_FAIL:
      return { ...state, isLoading: false, webinarDataPut: "error" };

    //delete
    case WEBINAR_DATA_DELETE:
      return { ...state, isLoading: true, webinarDataDelete: "" };
    case WEBINAR_DATA_DELETE_SUCCESS:
      return { ...state, isLoading: false, webinarDataDelete: "success" };
    case WEBINAR_DATA_DELETE_FAIL:
      return { ...state, isLoading: false, webinarDataDelete: "error" };

    default:
      return state;
  }
};
