import moment from "moment/moment";
import React, { useEffect, useState } from "react";
import {
  Breadcrumb,
  Col,
  Container,
  Form,
  Modal,
  Row,
  Table,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import IconConfirmSucces from "../../assets/images/icon-confirm-succes.svg";
import IconInfo from "../../assets/images/icon-info-blue.svg";
import Home from "../../assets/images/breadcrumb.svg";

import IconSearch from "../../assets/images/icon-search.svg";
import ATPICertificate from "../../components/AnalyticsCompany/ATPICertificate";
import AverageCompletion from "../../components/AnalyticsCompany/AverageCompletion";
import AverageNilai from "../../components/AnalyticsCompany/AverageNilai";
import AvgCourses from "../../components/AnalyticsCompany/AvgCourses";
import CapaianBelajar from "../../components/AnalyticsCompany/CapaianBelajar";
import CompletionRate from "../../components/AnalyticsCompany/CompletionRate";
import InfoCourse from "../../components/AnalyticsCompany/InfoCourse";
import ListUserDetail from "../../components/AnalyticsCompany/ListUserDetail";
import StatusCertificate from "../../components/AnalyticsCompany/StatusCertificate";
import StudyGroupTab from "../../components/AnalyticsCompany/StudyGroupTab";
import PaginationTemplate from "../../components/Layout/Pagination";
import { AnalyticLearningService } from "../../http/AnalyticLearningHttp";
import { StudyGroupServices } from "../../http/StudyGroupHttp";
import {
  deleteUsersDataThunk,
  getUsersDataThunk,
} from "../../redux/thunks/UsersThunks";
import "./../../assets/css/Dashboard/AnalyticsPembelajaran.css";
import FileList from "./../../assets/images/file-list.png";
import { convertDate, convertTime } from "../../helper/functions";
const MySwal = withReactContent(Swal);

function AnalyticCourse() {
  const [show, setShow] = useState(false);
  const [requirementCertif, setRequirementCertif] = useState(null);
  const [userDataModal, setUserDataModal] = useState([]);
  const [activePageAnggota, setActivePageAnggota] = useState("all");
  const [dataChoose, setDataChoose] = useState([]);
  const [groupList, setGroupList] = useState(null);
  const [nameRombel, setNameRombel] = useState("");
  const [triggerRombel, setTriggerRombel] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [keywordCourse, setKeywordCourse] = useState("");
  const [size, setSize] = useState(10);
  const [currentRombel, setCurrentRombel] = useState(null);
  const [active, setActive] = useState(1);
  const [activeCourse, setActiveCourse] = useState(1);
  const [showInternal, setShowInternal] = useState("add");
  const [memberRombelList, setMemberRombelList] = useState(null);
  const [trigger, setTrigger] = useState(false);
  const [userData, setUserData] = useState([]);
  const [adminData, setAdminData] = useState([]);
  const [dataDel, setDataDel] = useState([]);
  const [dataCourse, setDataCourse] = useState(null);
  const [dataMemberCourse, setDataMemberCourse] = useState(null);
  const [userOrganisasiPagination, setUserOrganisasiPagination] =
    useState(null);
  const [isEnglishCourse, setIsEnglishCourse] = useState(false);
  const dataOption = [
    {
      value: requirementCertif?.qualified,
      name: "Qualified",
      color: "#306CCC",
    },
    {
      value: requirementCertif?.total_member - requirementCertif?.qualified,
      name: "Unqualified",
      color: "#D6E2F5",
    },
  ];
  const users = useSelector((state) => state.users);
  const dispatch = useDispatch();
  const { course_id, type } = useParams();
  const usersDataId = users.usersDataId;
  const companyId = usersDataId?.company_id;
  const usersData = users.usersData;
  const allDataStudent = usersData?.pagination?.totalItems;

  // console.log(requirementCertif);
  const optionLiveClass = {
    tooltip: {
      trigger: "item",
      show: false,
    },
    legend: {
      show: false,
    },
    series: [
      {
        name: "Access From",
        type: "pie",
        left: "-15%",
        radius: ["45%", "80%"],
        avoidLabelOverlap: false,
        color: [dataOption[0].color, dataOption[1].color],
        label: {
          show: true,
          position: "center",
          formatter: `{a|${requirementCertif?.qualified_percent}%}`, // Use '{a|text}' to display the static text
          rich: {
            a: {
              fontSize: 16, // Set font size as needed
              fontWeight: "700", // Set font weight as needed
              color: "#4F4F4F",
              fontFamily: "Poppins",
            },
            b: {
              fontSize: 10, // Set font size as needed
              fontWeight: "400", // Set font weight as needed
              color: "#4F4F4F",
              fontFamily: "Poppins",
            },
          },
        },
        labelLine: {
          show: false,
        },
        data: [dataOption[0], dataOption[1]],
      },
    ],
  };

  useEffect(() => {
    setUserData(usersData.items);
    setUserDataModal(usersData.items);
  }, [usersData]);

  useEffect(() => {
    if (show) {
      dispatch(
        getUsersDataThunk({
          param: `?size=${size}&page=${active}&company_id=${companyId}&role=student&keyword=${keyword}`,
        })
      );
    }
  }, [show, companyId, keyword]);

  useEffect(() => {
    if (trigger) {
      if (userData.usersDataDelete === "success") {
        customDisplayPopup(
          "Selamat!",
          `Anda Telah Berhasil Menghapus <b>${
            dataDel.length < 2 ? dataDel[0].name : dataDel.length
          }</b> dari Organisasimu`,
          IconConfirmSucces,
          false,
          "Ya",
          true,
          "Tutup",
          "#55AA73"
        );
      }
      if (triggerRombel) {
        setTriggerRombel(false);
        getStudyGroup(`?company_id=${companyId}&course_id=${course_id}`);
      }

      if (activePageAnggota == "all") {
        dispatch(
          getUsersDataThunk({
            param: `?size=${size}&page=${active}&company_id=${companyId}&role=student&keyword=${keyword}`,
          })
        );
      } else {
        getStudyGroupMember(`/${currentRombel}?size=${size}&page=${active}`);
      }

      setTrigger(false);
      setDataDel([]);
    }
  }, [
    keyword,
    users?.usersDataId?.user_id,
    size,
    users?.usersDataDelete,
    triggerRombel,
  ]);

  useEffect(() => {
    if (usersData?.pagination)
      setUserOrganisasiPagination(usersData.pagination);
  }, [usersData?.pagination]);

  useEffect(() => {
    if (companyId) {
      if (activePageAnggota == "all") {
        getAnalyticCertif(companyId, course_id);
        conditionalCall(type);
      }
      // getListMemberCourse(
      //   companyId,
      //   course_id,
      //   `&size=${size}&page=${activeCourse}&keyword=${keywordCourse}`
      // );
    }
  }, [activePageAnggota, companyId, type]);

  useEffect(() => {
    if (dataCourse?.course) {
      let titleCourse = dataCourse?.course?.title?.toLowerCase();
      if (
        titleCourse?.includes("ielts") ||
        titleCourse?.includes("toefl") ||
        titleCourse?.includes("toeic") ||
        titleCourse?.includes("english")
      ) {
        setIsEnglishCourse(true);
      } else {
        setIsEnglishCourse(false);
      }
    }
  }, [dataCourse]);

  useEffect(() => {
    setIsLoaded(true);
  }, [isLoaded]);

  useEffect(() => {
    if (companyId) {
      getStudyGroup(`?company_id=${companyId}&course_id=${course_id}`);
      getRequirementATPI(`/${companyId}`);
    }
  }, [companyId]);

  const getRequirementATPI = async (company_id) => {
    try {
      const response = await AnalyticLearningService.getATPIRequirement(
        company_id
      );
      if (response) setRequirementCertif(response);
    } catch (err) {
      console.log(err);
    }
  };

  const getAnalyticCertif = async (company_id, course_id, param) => {
    try {
      const response = await AnalyticLearningService.getAnalyticCertification(
        company_id,
        course_id,
        param ? param : ""
      );
      if (response) {
        setDataCourse(response);
      }
    } catch (err) {
      console.log(err);
    }
  };

  // const getDetailRombel = (id) => {
  //   setCurrentRombel(id);
  //   getStudyGroupMember(`/${id}`);
  //   setDataDel([]);
  // };

  const getStudyGroupMember = async (param) => {
    try {
      const response = await StudyGroupServices.getStudyGroupMember(param);

      if (response) {
        setMemberRombelList(response?.items);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getStudyGroup = async (param) => {
    try {
      const response = await StudyGroupServices.getStudyGroup(param);
      if (response) {
        setGroupList(response?.items);
      }
    } catch (error) {
      console.log(error);
    }
  };

  function switchShowInternalContent() {
    switch (showInternal) {
      case "add":
        return (
          <>
            <Form.Group className="mb-3 w-100" controlId="formGroupKaryawan">
              <Form.Label className="title__form-addmember">
                Nama Rombel
              </Form.Label>
              <Form.Control
                autoComplete="off"
                type="text"
                placeholder="Masukan Nama Rombongan Belajar"
                name="name"
                onChange={(e) => setNameRombel(e.target.value)}
              />
            </Form.Group>
            <Form.Group
              className="mb-3 w-100 position-relative"
              controlId="formGroupKaryawan"
            >
              <Form.Label className="title__form-addmember">
                Pilih Anggota
              </Form.Label>
              <Form.Control
                placeholder="Cari"
                aria-label="Username"
                aria-describedby="basic-addon1"
                className=""
                onChange={(e) => setKeyword(e.target.value)}
              />
              <div className="icon__search-edit">
                <img src={IconSearch} alt="" />
              </div>
            </Form.Group>
            <div className="d-flex my-3 align-items-center">
              Terpilih <b className="mx-1">{dataChoose.length}</b> dari
              <b className="mx-1">{usersData?.pagination?.totalItems}</b>
              Anggota
            </div>
            <Table responsive striped hover>
              <thead>
                <th>
                  <Form.Check
                    inline
                    name="allselectmodal"
                    type="checkbox"
                    checked={
                      userDataModal?.filter((data) => data?.isChecked !== true)
                        .length < 1
                    }
                    onChange={handleCheckModal}
                  />
                </th>
                <th className="pl-0">Nama</th>
                <th className="pl-0">Divisi</th>
                <th className="pl-0">Posisi</th>
                <th>Memberships</th>
              </thead>
              <tbody>
                {userDataModal?.length > 0 ? (
                  userDataModal &&
                  userDataModal?.map((data, index) => (
                    <tr key={index}>
                      <td>
                        <Form.Check
                          inline
                          name={data.user_id}
                          type="checkbox"
                          checked={data?.isChecked || false}
                          onChange={handleCheckModal}
                        />
                      </td>
                      <td className="pl-0">
                        <Link to="#">{data.name}</Link>
                      </td>
                      <td className="pl-0">
                        {data.division != "" ? data.division : "-"}
                      </td>
                      <td className="pl-0">
                        {data.position != "" ? data.position : "-"}
                      </td>
                      <td className="pl-0">
                        {data.memberships.length > 0
                          ? data.memberships.map(
                              (item) => item.membership_title + ", "
                            )
                          : "-"}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={8} className="text-center">
                      Data Belum Tersedia
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
            <Row className="justify-content-center px-2">
              {userDataModal?.length > 0 && (
                <PaginationTemplate
                  size={size}
                  totalRows={userOrganisasiPagination?.totalItems}
                  totalPages={userOrganisasiPagination?.totalPages}
                  paginationSelect={paginationManagOrganisasi}
                  active={active}
                />
              )}
            </Row>
          </>
        );
      case "continue":
        return (
          <Table responsive striped hover>
            <thead>
              <th className="pl-0">Nama</th>
              <th className="pl-0">Divisi</th>
              <th className="pl-0">Posisi</th>
              <th>Memberships</th>
            </thead>
            <tbody>
              {dataChoose?.length > 0 ? (
                dataChoose &&
                dataChoose?.map((data, index) => (
                  <tr key={index}>
                    <td className="pl-0">
                      <Link to="#">{data.name}</Link>
                    </td>
                    <td className="pl-0">
                      {data.division != "" ? data.division : "-"}
                    </td>
                    <td className="pl-0">
                      {data.position != "" ? data.position : "-"}
                    </td>
                    <td className="pl-0">
                      {data.memberships.length > 0
                        ? data.memberships.map((item) => item.membership_title)
                        : "-"}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={4} className="text-center">
                    Data Belum Tersedia
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        );

      default:
        return null;
    }
  }

  const handleCheckModal = (e) => {
    const { name, checked } = e.target;

    if (name == "allselectmodal") {
      const tempData = userDataModal?.map((item) => {
        return { ...item, isChecked: checked };
      });

      setUserDataModal(tempData);
      setDataChoose(checked ? tempData : []);
    } else {
      const tempData = userDataModal?.map((item) =>
        item.user_id == name ? { ...item, isChecked: checked } : item
      );
      setUserDataModal(tempData);

      if (
        userDataModal?.filter(
          (item) => item.isChecked == true && item.user_id == name
        ).length > 0
      ) {
        setDataChoose(dataChoose.filter((item) => item.user_id != name));
      } else {
        const dataUser = userDataModal?.find((item) => item.user_id == name);
        setDataChoose([...dataChoose, dataUser]);
      }
    }
  };

  function paginationManagOrganisasi(newActive) {
    setActive(newActive);
    dispatch(
      getUsersDataThunk({
        param: `?size=${size}&page=${newActive}&company_id=${companyId}&role=student`,
      })
    );
  }

  const handleSubmitRombel = async () => {
    let selectedUser = dataChoose.map((item) => item.user_id).toString();
    let payload = {
      group_name: nameRombel,
      members: selectedUser,
    };
    try {
      const response = await StudyGroupServices.postStudyGroup(payload);
      if (response) {
        customDisplayPopup(
          "Selamat!",
          `Anda Telah Berhasil Membuat Rombel <b>${payload.group_name}</b> dengan jumlah anggota ${dataChoose.length} orang`,
          IconConfirmSucces,
          false,
          "Ya",
          true,
          "Tutup",
          "#55AA73"
        );

        setTrigger(true);
        setTriggerRombel(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const executeDeleteMember = async () => {
    let selectedUser = dataDel.map((item) => item.user_id).toString();
    let payload = {
      group_name: activePageAnggota,
      members: selectedUser,
    };

    try {
      const response = await StudyGroupServices.removeStudyGroupMember(payload);
      if (response) {
        customDisplayPopup(
          "Selamat!",
          `Anda Telah Berhasil Menghapus <b>${
            dataDel.length >= 2 ? dataDel.length : dataDel.name
          }</b> dari Organisasimu`,
          IconConfirmSucces,
          false,
          true,
          "Tutup",
          "#55AA73"
        );

        getStudyGroup(`?company_id=${companyId}&course_id=${course_id}`);
      }
    } catch (err) {
      console.log(err);
      displayPopup(false, "Gagal menghapus member!", true, "Oke");
    }
  };

  function customDisplayPopup(
    title,
    subTitle,
    iconType,
    confirmBtn,
    textConfirm,
    cancelBtn,
    textCancel,
    cancelBgColor
  ) {
    MySwal.fire({
      title: title,
      html: subTitle,
      imageUrl: `${iconType}`,
      imageWidth: 122,
      imageHeight: 135,
      imageAlt: "Custom image",
      showConfirmButton: confirmBtn,
      confirmButtonColor: "#FF8500",
      confirmButtonText: `<p style='margin-bottom:0;width:180px'>${textConfirm}</p>`,
      showCancelButton: cancelBtn,
      cancelButtonText: `<p style='margin-bottom:0;width:188px;color:#818181'>${textCancel}</p>`,
      cancelButtonColor: cancelBgColor,
    }).then((result) => {
      if (result.isConfirmed && activePageAnggota == "all") {
        const id = dataDel.find((item) => item.user_id);

        setTrigger(true);
        dispatch(deleteUsersDataThunk(id.user_id));
      }

      if (result.isConfirmed && activePageAnggota != "all") {
        executeDeleteMember();
      }

      if (
        result.isDismissed &&
        (result.dismiss == "cancel" || result.dismiss == "backdrop")
      ) {
        setShow(false);
        setShowInternal("add");
        setDataChoose([]);
        setUserDataModal([]);
        setNameRombel("");
      }
    });
  }

  function displayPopup(cancel, title, confirm, confirmMsg) {
    MySwal.fire({
      title: title,
      cancelButtonText: "Ok",
      showCancelButton: cancel,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      showConfirmButton: confirm,
      confirmButtonText: confirmMsg,
    }).then((value) => {
      if (confirm) {
        setShow(false);
      }
    });
  }

  const getListMemberCourse = async (company_id, courseId, param) => {
    try {
      const response = await AnalyticLearningService.getEnrolledCourseList(
        `${company_id}?course_id=${courseId}${param}`
      );
      if (response) {
        setDataMemberCourse(response);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getListMemberCertificate = async (company_id, courseId, param) => {
    try {
      const response = await AnalyticLearningService.getEnrolledCertificate(
        `${company_id}?course_id=${courseId}${param}`
      );

      setDataMemberCourse(response);
    } catch (error) {
      console.error(error);
    }
  };

  const getListMemberLiveClass = async (company_id, courseId, param) => {
    try {
      const response = await AnalyticLearningService.getEnrolledLiveClasses(
        `${company_id}?course_id=${courseId}${param}`
      );
      setDataMemberCourse(response);
    } catch (error) {
      console.error(error);
    }
  };

  const paginationListMemberCourse = (newActive) => {
    setActiveCourse(newActive);
    conditionalCall(type);
  };

  const searchData = (e) => {
    e.preventDefault();
    conditionalCall(type);
  };

  function conditionalCall(type, param) {
    console.log(type);
    let commonParam = `&size=${size}&page=${activeCourse}&keyword=${keywordCourse}`;
    if (type === "course") {
      getListMemberCourse(
        companyId,
        course_id,
        `${param ? param : commonParam}`
      );
    } else if (type === "certificate") {
      getListMemberCertificate(
        companyId,
        course_id,
        `${param ? param : commonParam}`
      );
    } else {
      getListMemberLiveClass(
        companyId,
        course_id,
        `${param ? param : commonParam}`
      );
    }
  }

  function generateTitle(dataCourse) {
    let titleCourse = dataCourse?.course?.title?.toLowerCase();
    if (type === "certificate") {
      if (isEnglishCourse) {
        return `Detail Course - English`;
      } else if (titleCourse.includes("cptt")) {
        return `CPTT`;
      } else if (titleCourse.includes("ctt")) {
        return `CTT`;
      } else {
        return `Detail Course - Brevet`;
      }
    } else {
      return `Detail ${type.replace("-", " ")}`;
    }
  }
  return (
    <>
      <Container fluid>
        <h3 className="title__page-man mb-4">
          {dataCourse && generateTitle(dataCourse)}
        </h3>
        <Breadcrumb>
          <Breadcrumb.Item href="#">
            <img src={Home} alt="home" />
          </Breadcrumb.Item>
          <Breadcrumb.Item className="breadcrumb__list-nav">
            Data & Analitik Pembelajaran
          </Breadcrumb.Item>{" "}
          <Breadcrumb.Item active className="breadcrumb__list-nav">
            {dataCourse?.course?.title}
          </Breadcrumb.Item>
        </Breadcrumb>
        <Row>
          <Col lg={12}>
            <InfoCourse data={dataCourse} />
          </Col>

          <Col lg={12}>
            <div className="card-analitik">
              <Row>
                <Col
                  lg={12}
                  className="d-flex align-items-center mb-4"
                  style={{ borderBottom: "1px solid #D9D9D9" }}
                >
                  <StudyGroupTab
                    tab={activePageAnggota}
                    setTab={setActivePageAnggota}
                    totalItems={usersDataId}
                    data={groupList}
                    endpoint={getAnalyticCertif}
                    setShow={setShow}
                    conditionalCall={conditionalCall}
                    size={size}
                    activeCourse={activeCourse}
                    keywordCourse={keywordCourse}
                    setUserDataModal={setUserDataModal}
                    type={type}
                  />
                </Col>
              </Row>
              {type === "live-class" ? (
                ""
              ) : type === "course" ? (
                <Row>
                  <Col lg={12} xl={7}>
                    <Row>
                      <Col lg={8} xl={7} className="pr-0">
                        {isLoaded && <CompletionRate data={dataCourse} />}
                      </Col>
                      <Col lg={4} xl={5} className="pr-0">
                        <AverageCompletion
                          time={convertTime(dataCourse?.completion_time)}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col lg={12} xl={5}>
                    <Row>
                      <Col lg={6} className="pr-0">
                        <AverageNilai shadow={true} data={dataCourse} />
                      </Col>
                      <Col lg={6}>
                        <CapaianBelajar shadow={true} data={dataCourse} />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              ) : (
                <>
                  {isEnglishCourse ? (
                    <Row>
                      <Col lg={5}>
                        {isLoaded && <CompletionRate data={dataCourse} />}
                      </Col>
                      <Col lg={3}>
                        <AverageCompletion
                          title="Rerata Waktu Penyelesaian"
                          desc="Waktu dihitung sejak peserta memencet tombol mulai pelatihan, dan selesai ketika lulus ujian dan berhak mendapatkan sertifikat."
                          time={convertTime(dataCourse?.completion_time)}
                        />
                      </Col>
                      <Col lg={2}>
                        <AverageNilai shadow={true} data={dataCourse} />
                      </Col>
                      <Col lg={2}>
                        <CapaianBelajar shadow={true} data={dataCourse} />
                      </Col>
                    </Row>
                  ) : (
                    <Row>
                      <Col lg={5}>
                        {isLoaded && <CompletionRate data={dataCourse} />}
                      </Col>
                      <Col lg={7}>
                        <Row>
                          <Col lg={6}>
                            <StatusCertificate data={dataCourse} />
                          </Col>
                          <Col lg={6}>
                            <AvgCourses
                              title="Rata-rata Nilai Tryout"
                              icon={FileList}
                              data={dataCourse}
                              typeCourse="tryout"
                            />
                            <AvgCourses
                              title="Rata-rata Nilai Ujian"
                              icon={FileList}
                              data={dataCourse}
                              typeCourse="ujian"
                            />
                          </Col>
                        </Row>
                      </Col>
                      <Col lg={4}>
                        <AverageCompletion
                          title="Rerata Waktu Penyelesaian"
                          desc="Waktu dihitung sejak peserta memencet tombol mulai pelatihan, dan selesai ketika lulus ujian dan berhak mendapatkan sertifikat."
                          time={convertTime(dataCourse?.completion_time)}
                        />
                      </Col>
                      <Col lg={4}>
                        <Row>
                          <Col lg={6}>
                            <AverageNilai shadow={true} data={dataCourse} />
                          </Col>
                          <Col lg={6}>
                            <CapaianBelajar shadow={true} data={dataCourse} />
                          </Col>
                        </Row>
                      </Col>
                      <Col lg={4}>
                        {requirementCertif && (
                          <ATPICertificate
                            optionLiveClass={optionLiveClass}
                            title={dataCourse?.course?.title}
                            data={requirementCertif}
                            isLoaded={isLoaded}
                            courseId={course_id}
                          />
                        )}
                      </Col>
                    </Row>
                  )}
                </>
              )}
              <Row>
                <Col lg={12}>
                  <ListUserDetail
                    data={dataMemberCourse?.items}
                    type={type}
                    paginationSelect={paginationListMemberCourse}
                    size={size}
                    setSize={setSize}
                    currentPage={activeCourse}
                    totalRows={dataMemberCourse?.pagination?.totalItems}
                    totalPages={dataMemberCourse?.pagination?.totalPages}
                    convertDate={convertDate}
                    setKeywordCourse={setKeywordCourse}
                    searchData={searchData}
                    infoCourse={dataCourse?.course}
                  />
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        scrollable={true}
        centered
        show={show}
        onHide={() => setShow(!show)}
      >
        {showInternal == "add" ? (
          <Modal.Header className="d-block">
            <h3 className="title__modal-pelatihan">
              Tambahkan Rombongan Belajar Baru
            </h3>
            <span className="subtitle__modal-pelatihan">
              Tentukan nama rombongan belajar dan pilih anggota untuk
              ditambahkan ke dalam rombongan belajar ini
            </span>
          </Modal.Header>
        ) : (
          <Modal.Header className="d-block">
            <h3 className="title__modal-pelatihan">
              Konfirmasi Pembuatan Rombongan Belajar
            </h3>
            <div className="subtitle__modal-confirm d-flex align-items-center">
              <div>
                <img src={IconInfo} width={16} height={16} alt="" />
              </div>
              <span className="d-block">
                Apakah anda yakin untuk membuat RomBel <b>{nameRombel}</b>{" "}
                dengan anggota berikut?
              </span>
            </div>
          </Modal.Header>
        )}
        <Modal.Body>{switchShowInternalContent()}</Modal.Body>
        <Modal.Footer>
          {showInternal == "continue" && (
            <>
              <button
                className="btn btn__back-show"
                onClick={() => setShowInternal("add")}
              >
                Kembali
              </button>
              <button
                className="btn btn__cotinue-show"
                onClick={() => handleSubmitRombel()}
              >
                Simpan
              </button>
            </>
          )}
          {showInternal == "add" && (
            <button
              className="btn btn__cotinue-show"
              onClick={() => setShowInternal("continue")}
              disabled={
                nameRombel != "" && dataChoose?.length > 0 ? false : true
              }
            >
              Selanjutnya
            </button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default AnalyticCourse;
