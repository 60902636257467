import { Link } from "@mui/material";
import { Form, Table } from "react-bootstrap";
import PaginationTemplate from "../Layout/Pagination";
import IconEdit from "../../assets/images/icon-edit-pen.svg";

export default function DataStudyGroup(props) {
  const {
    userData,
    memberRombelList,
    type,
    handleCheck,
    formatDate,
    handleViewDetail,
    usersOrganisasiPagination,
    size,
    active,
    paginationManagOrganisasi,
  } = props;
  let dataRender = userData;
  if (type !== "all") {
    dataRender = memberRombelList;
  }
  return (
    <>
      {dataRender && (
        <>
          <Table responsive striped hover>
            <thead>
              <tr>
                <th>
                  <Form.Check
                    inline
                    name="allselect"
                    type="checkbox"
                    checked={
                      dataRender?.filter((data) => data?.isChecked !== true)
                        .length < 1
                    }
                    onChange={(e) => handleCheck(e, "user")}
                  />
                </th>
                <th className="pl-0">Nama Anggota</th>
                <th className="pl-0">Email</th>
                <th className="pl-0">Divisi</th>
                <th className="pl-0">Posisi</th>
                <th className="pl-0">Membership</th>
                <th className="pl-0">Tanggal Kadaluarsa</th>
                <th className="pl-0">Terakhir Aktif</th>
              </tr>
            </thead>
            <tbody>
              {dataRender ? (
                dataRender?.length > 0 ? (
                  dataRender?.map((data, index) => (
                    <tr key={index}>
                      <td>
                        <Form.Check
                          inline
                          name={data.user_id}
                          type="checkbox"
                          checked={data?.isChecked || false}
                          username={data.name}
                          onChange={(e) => handleCheck(e, "user")}
                        />
                      </td>
                      <td className="pl-0">
                        <Link to="#">{data.name}</Link>
                      </td>
                      <td className="pl-0">{data.email}</td>
                      <td className="pl-0">
                        {data.division != "" ? data.division : "-"}
                      </td>
                      <td className="pl-0">
                        {data.position != "" ? data.position : "-"}
                      </td>
                      <td className="pl-0">
                        {data.memberships?.length > 0
                          ? data.memberships?.map(
                              (item) => item.membership_title
                            )
                          : "-"}
                      </td>
                      <td className="pl-0">
                        {data.memberships?.length > 0
                          ? data.memberships?.map((item) =>
                              formatDate(item.expired_at)
                            )
                          : "-"}
                      </td>
                      <td className="pl-0 d-flex justify-content-between">
                        <span>
                          {" "}
                          {data.memberships?.length > 0
                            ? data.memberships?.map((item) =>
                                formatDate(item.created_at)
                              )
                            : "-"}
                        </span>
                        <span
                          role={"button"}
                          onClick={() => handleViewDetail(data.user_id)}
                        >
                          <img src={IconEdit} alt="" />
                        </span>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={8} className="text-center">
                      Data Belum Tersedia
                    </td>
                  </tr>
                )
              ) : (
                <tr>
                  <td colSpan={8} className="text-center">
                    Menunggu Data...
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
          {type === "all" && usersOrganisasiPagination ? (
            <div className="d-flex justify-content-center align-items-center">
              <PaginationTemplate
                size={size}
                totalRows={usersOrganisasiPagination?.totalItems}
                totalPages={usersOrganisasiPagination?.totalPages}
                paginationSelect={paginationManagOrganisasi}
                active={active}
              />
            </div>
          ) : null}
        </>
      )}
    </>
  );
}
