import { LoginReducers } from "./reducers/LoginReducers";
import { CoursesReducers } from "./reducers/CoursesReducers";
import { CourseTypesReducers } from "./reducers/CourseTypesReducers";
import { LearningPathReducers } from "./reducers/LearningPathReducers";
import { LessonReducers } from "./reducers/LessonReducers";
import { QuizThemeReducers } from "./reducers/QuizThemeReducers";
import { QuizReducers } from "./reducers/QuizReducers";
import { TopicReducers } from "./reducers/TopicReducers";
import { WebinarReducers } from "./reducers/WebinarReducers";
import { UsersReducers } from "./reducers/UsersReducers";
import { AssetsReducers } from "./reducers/AssetsReducers";
import { AssignmentReducers } from "./reducers/AssignmentReducers";
import { AnalyticsGroupReducers } from "./reducers/AnalyticsGroupReducers";
import { AnalyticsUserReducers } from "./reducers/AnalyticsUserReducers";
import { CompaniesReducers } from "./reducers/CompaniesReducers";
import { InstructorsReducers } from "./reducers/InstructorsReducers";
import { PaymentsReducers } from "./reducers/PaymentsReducers";
import { OrdersReducers } from "./reducers/OrdersReducers";
import { ProductsReducers } from "./reducers/ProductsReducers";
import { DeliveryMethodReducers } from "./reducers/DeliveryMethodReducers";
import { SchemesReducers } from "./reducers/SchemesReducers";
import { DiscussTopicsReducers } from "./reducers/DiscussTopicsReducers";
import { SkillTypesReducers } from "./reducers/SkillTypesReducers";
import { CategoriesReducers } from "./reducers/CategoriesReducers";
import { SubCategoriesReducers } from "./reducers/SubCategoriesReducers";
import { PositionsReducers } from "./reducers/PositionsReducers";
import { LearningLevelsReducers } from "./reducers/LearningLevelsReducers";
import { IndustriesReducers } from "./reducers/IndustriesReducers";
import { SectorsReducers } from "./reducers/SectorsReducers";
import { SubIndustriesReducers } from "./reducers/SubIndustriesReducers";

export default {
  login: LoginReducers,
  courses: CoursesReducers,
  learning_paths: LearningPathReducers,
  lessons: LessonReducers,
  quiz_themes: QuizThemeReducers,
  quizzes: QuizReducers,
  topics: TopicReducers,
  webinars: WebinarReducers,
  users: UsersReducers,
  course_types: CourseTypesReducers,
  assets: AssetsReducers,
  assignments: AssignmentReducers,
  analyticsgroup: AnalyticsGroupReducers,
  analyticsuser: AnalyticsUserReducers,
  payments: PaymentsReducers,
  companies: CompaniesReducers,
  instructors: InstructorsReducers,
  orders: OrdersReducers,
  products: ProductsReducers,
  delivery_method: DeliveryMethodReducers,
  schemes: SchemesReducers,
  discussion_topics: DiscussTopicsReducers,
  skill_types: SkillTypesReducers,
  categories: CategoriesReducers,
  sub_categories: SubCategoriesReducers,
  positions: PositionsReducers,
  learning_levels: LearningLevelsReducers,
  industries: IndustriesReducers,
  sectors: SectorsReducers,
  sub_industries: SubIndustriesReducers,
};
