import React, { useEffect } from "react";
import { Row, Col, Container } from "react-bootstrap";
import TableQuiz from "../../components/TableQuiz";
import { AuthIsLogin } from "../../helper/auth";
import { useHistory } from "react-router-dom";
import "../../assets/css/Dashboard/index.css";

function QuizTable() {
  const history = useHistory();
  useEffect(() => {
    AuthIsLogin(history, true);
  }, []);
  return (
    <Container fluid>
      <Row className="justify-content-center align-items-center height-inherit">
        <Col xs={12} sm={12} md={12} lg={12}>
          <TableQuiz />
        </Col>
      </Row>
    </Container>
  );
}

export default QuizTable;
