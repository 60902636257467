import { PromiseService } from "../../services/PromiseService";
import { LESSON_DATA_LINK } from "../config/LessonConfig";
import {
  //get
  getLessonData,
  getLessonDataSuccess,
  getLessonDataIdSuccess,
  getLessonDataFail,
  //post
  postLessonData,
  postLessonDataSuccess,
  postLessonDataFail,
  // put
  putLessonData,
  putLessonDataSuccess,
  putLessonDataFail,
  // delete
  deleteLessonData,
  deleteLessonDataSuccess,
  deleteLessonDataFail,
} from "../actions/LessonActions";

export const postLessonDataThunk = (data) => async (dispatch) => {
  dispatch(postLessonData());
  const body = data;
  try {
    const response = await PromiseService.postData(LESSON_DATA_LINK, body);
    if (response.status === 201 && response.data) {
      dispatch(postLessonDataSuccess(response.data));
    } else {
      dispatch(postLessonDataFail(response));
    }
  } catch (error) {
    dispatch(postLessonDataFail(error));
  }
};

export const getLessonDataThunk = (data) => async (dispatch) => {
  dispatch(getLessonData());
  if (data) {
    let url = `${LESSON_DATA_LINK}/${data}`;
    if (data.param) url = `${LESSON_DATA_LINK}${data.param}`;
    try {
      const response = await PromiseService.getData(url);
      if (response.status === 200 && response.data) {
        if (data.param) dispatch(getLessonDataSuccess(response.data));
        else dispatch(getLessonDataIdSuccess(response.data));
      } else {
        dispatch(getLessonDataFail(response));
      }
    } catch (error) {
      dispatch(getLessonDataFail(error));
    }
  } else {
    try {
      const response = await PromiseService.getData(LESSON_DATA_LINK);
      if (response.status === 200 && response.data) {
        dispatch(getLessonDataSuccess(response.data));
      } else {
        dispatch(getLessonDataFail(response));
      }
    } catch (error) {
      dispatch(getLessonDataFail(error));
    }
  }
};

// put
export const putLessonDataThunk = (data) => async (dispatch) => {
  dispatch(putLessonData());
  const body = data.body;
  const id = data.id;
  try {
    const response = await PromiseService.putData(
      `${LESSON_DATA_LINK}/${id}`,
      body
    );
    if (response.status === 201 && response.data) {
      dispatch(putLessonDataSuccess(response.data));
    } else {
      dispatch(putLessonDataFail(response));
    }
  } catch (error) {
    dispatch(putLessonDataFail(error));
  }
};

// delete
export const deleteLessonDataThunk = (data) => async (dispatch) => {
  dispatch(deleteLessonData());
  const id = data;
  try {
    const response = await PromiseService.deleteData(
      `${LESSON_DATA_LINK}/${id}`
    );
    if (response.status === 200 && response.data) {
      dispatch(deleteLessonDataSuccess(response.data));
    } else {
      dispatch(deleteLessonDataFail(response));
    }
  } catch (error) {
    dispatch(deleteLessonDataFail(error));
  }
};
