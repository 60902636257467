import React, { useState } from "react";
import { Button, Row, Col, Container, Form, Card } from "react-bootstrap";
import { FaLock, FaMailBulk } from "react-icons/fa";
import "../../assets/css/Account/index.css";
import { SkValidate } from "../../components/common/SkValidate";
import { AuthService } from "../../http/AuthHttp";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router";
import {
  loadingFinish,
  loadingStart,
} from "../../redux/reducers/CommonReducers";
const MySwal = withReactContent(Swal);
function ResetPassword() {
  const location = useLocation();
  const parameter = new URLSearchParams(location.search);
  const token = parameter.get("token");
  const dispatch = useDispatch();
  const [data, setData] = useState();
  const history = useHistory();
  const sha256 = require("js-sha256");

  const constraints = {
    password: {
      presence: true,
      length: {
        minimum: 8,
        maximum: 50,
      },
    },
    password_re: {
      presence: true,
      length: {
        minimum: 8,
        maximum: 50,
      },
      equality: "password",
    },
  };
  const handleChange = (e) => {
    const newData = { ...data };
    newData[e.target.id] = e.target.value;
    setData(newData);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(loadingStart());
    const form = e.target;
    const isValid = SkValidate(form, constraints);
    if (isValid) {
      let payload = { ...data };
      payload.password = sha256(data.password);
      payload.password_re = sha256(data.password_re);
      payload.token = token;
      try {
        const response = await AuthService.postResetPassword(payload);
        if (response) {
          displayPopup(false, "Berhasil mengubah password", true, "Ok");
          history.push("/login");
        }
      } catch (error) {
        console.log(error);
        displayPopup(true, "Terjadi masalah saat edit data", false);
      }
    }
    dispatch(loadingFinish());
  };

  function displayPopup(cancel, title, confirm, confirmMsg) {
    MySwal.fire({
      title: title,
      showCancelButton: cancel,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      showConfirmButton: confirm,
      confirmButtonText: confirmMsg,
    }).then((value) => {});
  }

  return (
    <Container className="height-full">
      <Row className="justify-content-center align-items-center height-inherit">
        <Col xs={10} sm={10} md={4} lg={5} className="col-login  rounded p-6 ">
          <div className="text-center">
            <div className="login-title">Atur Ulang Password</div>
          </div>
          <Card className="p-4">
            <Form onSubmit={handleSubmit}>
              <Form.Group className="mb-4">
                <div className="login-label">Email</div>
                <div className="position-absolute p-3 d-flex">
                  <FaMailBulk />
                </div>
                <Form.Control
                  className="input100"
                  type="text"
                  placeholder="Masukkan Email Anda"
                  id="email"
                  name="email"
                  onChange={handleChange}
                />
                <div className="messages"></div>
              </Form.Group>
              <Form.Group className="mb-4">
                <div className="login-label">Kata Sandi</div>
                <div className="position-absolute p-3 d-flex">
                  <FaLock />
                </div>
                <Form.Control
                  className="mb-3 input100"
                  type="password"
                  id="password"
                  name="password"
                  placeholder="Password Baru"
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group className="mb-5">
                <div className="login-label">Konfirmasi Kata Sandi</div>
                <div className="position-absolute p-3 d-flex">
                  <FaLock />
                </div>

                <Form.Control
                  className="mb-3 input100"
                  type="password"
                  id="password_re"
                  name="password_re"
                  placeholder="Password Baru"
                  onChange={handleChange}
                />
              </Form.Group>
              <Button type="submit" className="w-100 login-button">
                Simpan Password Baru
              </Button>
            </Form>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default ResetPassword;
