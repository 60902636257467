import {
  // get
  LEARNING_PATH_DATA,
  LEARNING_PATH_DATA_SUCCESS,
  LEARNING_PATH_DATA_ID_SUCCESS,
  // post
  LEARNING_PATH_DATA_POST,
  LEARNING_PATH_DATA_POST_SUCCESS,
  LEARNING_PATH_DATA_POST_FAIL,
  // put
  LEARNING_PATH_DATA_PUT,
  LEARNING_PATH_DATA_PUT_SUCCESS,
  LEARNING_PATH_DATA_PUT_FAIL,
  // delete
  LEARNING_PATH_DATA_DELETE,
  LEARNING_PATH_DATA_DELETE_SUCCESS,
  LEARNING_PATH_DATA_DELETE_FAIL,
  //remove
  REMOVE_LEARNING_PATH_DATA,
  // fail
  LEARNING_PATH_DATA_FAIL,
} from "../config/LearningPathConfig";

// get
export const getLearningPathData = (value) => ({
  type: LEARNING_PATH_DATA,
  value,
});
export const getLearningPathDataSuccess = (value) => ({
  type: LEARNING_PATH_DATA_SUCCESS,
  value,
});
export const getLearningPathDataIdSuccess = (value) => ({
  type: LEARNING_PATH_DATA_ID_SUCCESS,
  value,
});
export const getLearningPathDataFail = (value) => ({
  type: LEARNING_PATH_DATA_FAIL,
  value,
});

// post
export const postLearningPathData = (value) => ({
  type: LEARNING_PATH_DATA_POST,
  value,
});
export const postLearningPathDataSuccess = (value) => ({
  type: LEARNING_PATH_DATA_POST_SUCCESS,
  value,
});
export const postLearningPathDataFail = (value) => ({
  type: LEARNING_PATH_DATA_POST_FAIL,
  value,
});

// put
export const putLearningPathData = (value) => ({
  type: LEARNING_PATH_DATA_PUT,
  value,
});
export const putLearningPathDataSuccess = (value) => ({
  type: LEARNING_PATH_DATA_PUT_SUCCESS,
  value,
});
export const putLearningPathDataFail = (value) => ({
  type: LEARNING_PATH_DATA_PUT_FAIL,
  value,
});

// delete
export const deleteLearningPathData = (value) => ({
  type: LEARNING_PATH_DATA_DELETE,
  value,
});
export const deleteLearningPathDataSuccess = (value) => ({
  type: LEARNING_PATH_DATA_DELETE_SUCCESS,
  value,
});
export const deleteLearningPathDataFail = (value) => ({
  type: LEARNING_PATH_DATA_DELETE_FAIL,
  value,
});

//remove
export const removeLearningPathData = (value) => ({
  type: REMOVE_LEARNING_PATH_DATA,
  value,
});
