import moment from "moment";

export function CreateResult(data, type, id) {
  // function that use for multiselect result
  let result = [];
  for (let i = 0; i < data.length; i++) {
    if (type === "array") result.push(data[i]);
    else if (type === "json") result.push({ [`${id}_id`]: data[i] });
  }
  return result;
}
export function slugify(content) {
  return content
    ?.toLowerCase()
    .replace(/ /g, "-")
    .replace(/[^\w-]+/g, "");
}

export const convertTime = (inputTime) => {
  if (inputTime) {
    let convertedTime = null;
    if (inputTime.includes("days")) {
      inputTime = inputTime.replace("days", "").trim();
      let parts = inputTime.split(/[:,]/);
      let hours = parseInt(parts[0]) * 24 + parseInt(parts[1]);
      let minutes = parseInt(parts[2]);
      convertedTime = `${hours}j${minutes}m`;
    } else {
      let timeArray = inputTime.split(":");
      let hours = parseInt(timeArray[0]);
      let minutes = parseInt(timeArray[1]);

      // Hitung total menit
      let totalMinutes = hours * 60 + minutes;

      // Ubah menjadi format 4j56m
      convertedTime =
        Math.floor(totalMinutes / 60) + "j" + (totalMinutes % 60) + "m";
    }

    return convertedTime;
  }
};

export const convertDate = (inputDate) => {
  if (inputDate) {
    return moment(inputDate).format("DD MMMM YYYY HH:mm");
  }
};

export function CreateSelected(data, id, options) {
  // function that use for multiselect selected
  let result = [];
  if (data) {
    if (data.length > 0) {
      for (let i = 0; i < data.length; i++) {
        let userExists = false;
        if (id.toLowerCase() === "categories") {
          userExists = options.some((option) => option[`${id}_id`] === data[i]);
          if (userExists) result.push(data[i]);
        } else {
          userExists = options.some(
            (option) => option[`${id}_id`] === data[i][`${id}_id`]
          );
          if (userExists) result.push(data[i][`${id}_id`]);
        }
      }
    }
  }
  return result;
}
export function convertHours(number, detail) {
  number = Number(number);
  var h = Math.floor(number / 3600);
  var m = Math.floor((number % 3600) / 60);
  var s = Math.floor((number % 3600) % 60);
  var hDisplay, mDisplay, sDisplay;
  if (detail === "preview") {
    hDisplay = h > 0 ? h + ":" : " ";
    mDisplay = m > 0 ? (m.toString().length < 2 ? "0" + m : m) + ":" : "00:";
    sDisplay = s > 0 ? (s.toString().length < 2 ? "0" + s : s) : "00";
    return hDisplay + mDisplay + sDisplay;
  } else {
    hDisplay = h > 0 ? h + "j" : " ";
    mDisplay = m > 0 ? m + "m" : " ";
    sDisplay = s > 0 ? s + "s" : " ";
    return hDisplay + " " + mDisplay + " " + sDisplay;
  }
}
export function CreateOption(items, type, id, label) {
  // function that use for multiselect option
  let object = [];
  for (let i = 0; i < items.length; i++) {
    const cond = items[i].role === "instructors" ? true : false;
    const condition = type != "instructor" ? true : cond;
    if (condition) object.push({ key: items[i][id], label: items[i][label] });
  }
  return object;
}

export function CheckIfEmpty(data) {
  // check wheter object have empty key value
  for (var key in data) {
    var value = data[key];
    if (value == []) return true;
    if (value == [""]) return true;
    if (value == "") return true;
    if (value) {
      if (value.length <= 0) return true;
    } else return true;
  }
  return false;
}
export function Clone(obj) {
  //clone object without change the original object
  if (null == obj || "object" != typeof obj) return obj;
  var copy = obj.constructor();
  for (var attr in obj) {
    if (obj.hasOwnProperty(attr)) copy[attr] = obj[attr];
  }
  return copy;
}

export function Capitalize(s) {
  //used for capitalized first letter
  return s[0].toUpperCase() + s.slice(1);
}

export function ValidateEmail(email) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

// determine course type which contains single topic and single material
export function courseSingle(par) {
  return (
    par.toLowerCase() === "webinar" ||
    par.toLowerCase() === "konsultasi" ||
    par.toLowerCase() === "sertifikasi" ||
    par.toLowerCase() === "try out"
  );
}

export function getCookie(cname) {
  if (typeof document === "undefined") return;
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export function setCookie(cname, cvalue, exdays) {
  if (typeof document === "undefined") return;
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  let expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

export function deleteCookie(cname) {
  if (typeof document === "undefined") return;
  let expires = "expires=" + "01 Jan 1970 00:00:00";
  document.cookie = cname + "=" + "" + ";" + expires + ";path=/";
}

export function hmsToSecond(hms) {
  const a = hms.split(":"); // split it at the colons

  // minutes are worth 60 seconds. Hours are worth 60 minutes.
  const seconds = +a[0] * 60 * 60 + +a[1] * 60 + +a[2];

  return hms ? seconds : 0;
}

export function secondToHms(number) {
  return new Date(number * 1000).toISOString().substr(11, 8);
}
