import {
  // get
  INSTRUCTORS_DATA,
  INSTRUCTORS_DATA_SUCCESS,
  INSTRUCTORS_DATA_ID_SUCCESS,
  //remove
  REMOVE_INSTRUCTORS_DATA,
  // fail
  INSTRUCTORS_DATA_FAIL,
  INSTRUCTORS_LIVE_SEARCH_DATA,
  INSTRUCTORS_LIVE_SEARCH_DATA_SUCCESS,
  INSTRUCTORS_LIVE_SEARCH_DATA_FAIL,
} from "../config/InstructorsConfig";

// get
export const getInstructorsData = (value) => ({
  type: INSTRUCTORS_DATA,
  value,
});
export const getInstructorsDataSuccess = (value) => ({
  type: INSTRUCTORS_DATA_SUCCESS,
  value,
});
export const getInstructorsDataIdSuccess = (value) => ({
  type: INSTRUCTORS_DATA_ID_SUCCESS,
  value,
});
export const getInstructorsDataFail = (value) => ({
  type: INSTRUCTORS_DATA_FAIL,
  value,
});

// live search
export const getInstructorsLiveSearchData = (value) => ({
  type: INSTRUCTORS_LIVE_SEARCH_DATA,
  value,
});
export const getInstructorsLiveSearchDataSuccess = (value) => ({
  type: INSTRUCTORS_LIVE_SEARCH_DATA_SUCCESS,
  value,
});
export const getInstructorsLiveSearchDataFail = (value) => ({
  type: INSTRUCTORS_LIVE_SEARCH_DATA_FAIL,
  value,
});

//remove
export const removeInstructorData = (value) => ({
  type: REMOVE_INSTRUCTORS_DATA,
  value,
});
