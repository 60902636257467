import React, { useState } from "react";
import { Card, Button } from "react-bootstrap";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import "../assets/css/Dashboard/companyAnalytics.css";

function DetailStudent({ data }) {
  let address = useLocation();

  return (
    <Card className="card_container">
      <div className="card_inside__padding ">
        <div className="img-profile__user"></div>
        <div className="profile-wrapper">
          <div className="wrapper-table__list justify-content-center mb-3">
            <div className="certificate-title__wrap">
              <div className="profile-title__main">{data?.name}</div>
              <div className="profile-subtitle__secondary">
                {/* <FaUserAlt className="mr-2 size" /> */}
                {data?.position === "" ? "N/A" : data?.position}
              </div>
            </div>
          </div>
          {/* <Button className="detail_button__users mb-4">Ekspor Data</Button> */}

          <div className="d-flex justify-content-between w-100 mb-3">
            <div className="profile-subtitle__secondary">Terakhir Login:</div>
            <div>
              {" "}
              {data?.last_login === ""
                ? "N/A"
                : moment(data?.last_login).format("DD-MM-YYYY")}
            </div>
          </div>
          <div className="d-flex justify-content-between w-100 mb-3">
            <div className="profile-subtitle__secondary">Tanggal Lahir:</div>
            <div>
              {" "}
              {data?.birthday === ""
                ? "N/A"
                : moment(data?.birthday).format("DD-MM-YYYY")}
            </div>
          </div>
          <div className="d-flex justify-content-between w-100 mb-3">
            <div className="profile-subtitle__secondary">Alamat:</div>
            <div className="w-50 text-right">
              {" "}
              {data?.address_street}, {data?.address_subdistrict}{" "}
              {data?.address_city}, {data?.address_province}
            </div>
          </div>
          <div className="d-flex justify-content-between w-100 mb-3">
            <div className="profile-subtitle__secondary">Email:</div>
            <div> {data?.email === "" ? "N/A" : data?.email}</div>
          </div>
          <div className="d-flex justify-content-between w-100 mb-3">
            <div className="profile-subtitle__secondary">No. Telp:</div>
            <div> {data?.phone === "" ? "N/A" : data?.phone}</div>
          </div>
        </div>
      </div>
    </Card>
  );
}

export default DetailStudent;
