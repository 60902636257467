import React, { useEffect } from "react";
import { Row, Col, Container } from "react-bootstrap";
import AddCourse from "../../components/AddCourse";
import { AuthIsLogin } from "../../helper/auth";
import { useHistory } from "react-router-dom";
import "../../assets/css/Dashboard/index.css";

function CreateCourse() {
  const history = useHistory();
  useEffect(() => {
    AuthIsLogin(history);
  }, []);
  return (
    <Container>
      <Row className="justify-content-center align-items-center height-inherit">
        <Col xs={12} sm={12} md={9} lg={9}>
          <div className="">
            <div className="course_create__title mb-3"> Buat Pelatihan </div>
          </div>
        </Col>
        <Col xs={12} sm={12} md={9} lg={9}>
          <AddCourse />
        </Col>
      </Row>
    </Container>
  );
}

export default CreateCourse;
