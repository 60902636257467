import React, { useEffect, useState } from "react";
import { Container, } from "react-bootstrap";
import "../../assets/css/Dashboard/index.css";
import { AuthIsLogin } from "../../helper/auth";
import { useHistory } from "react-router-dom";
import TimelineCalendar from "../../components/TimelineCalendar";
import { getWebinarDataThunk } from "../../redux/thunks/WebinarThunks";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
function LearningCalendar() {
  const [tab, setTap] = useState("month");
  const [webinarsData, setWebinarsData] = useState();
  const history = useHistory();
  const dispatch = useDispatch();
  const webinars = useSelector((state) => state.webinars);

  useEffect(() => {
    AuthIsLogin(history, true);
    const currentDate = moment(new Date()).format("YYYY-MM-DD");
    const nextMonth = moment(new Date()).add(1, "months").format("YYYY-MM-DD");
    dispatch(
      getWebinarDataThunk({
        param: `?size=50&date_from=${currentDate}&date_to=${nextMonth}`,
      })
    );
  }, []);
  useEffect(() => {
    if (webinars.webinarData?.items) setWebinarsData(webinars.webinarData);
  }, [webinars]);
  function dateChanges(sDate, sTab) {
    let currentTab = sTab;
    if (currentTab) {
      setTap(sTab);
    } else {
      currentTab = tab;
    }
    let date_from = moment(new Date()).format("YYYY-MM-DD");
    let date_to = moment(new Date()).format("YYYY-MM-DD");
    switch (currentTab) {
      case "month":
        date_from = moment(sDate.start).format("YYYY-MM-DD");
        date_to = moment(sDate.end).format("YYYY-MM-DD");
        getCurrentData(date_from, date_to);
        break;
      case "week":
        date_from = moment(sDate[0]).format("YYYY-MM-DD");
        date_to = moment(sDate[6]).format("YYYY-MM-DD");
        getCurrentData(date_from, date_to);
        break;
      case "day":
        date_from = moment(sDate[0]).format("YYYY-MM-DD");
        date_to = moment(sDate[0]).format("YYYY-MM-DD");
        getCurrentData(date_from, date_to);
        break;
      case "agenda":
        date_from = moment(sDate.start).format("YYYY-MM-DD");
        date_to = moment(sDate.end).format("YYYY-MM-DD");
        getCurrentData(date_from, date_to);
        break;
      default:
        console.log("default");
        break;
    }
  }
  const getCurrentData = (date_from, date_to) => {
    dispatch(
      getWebinarDataThunk({
        param: `?size=50&date_from=${date_from}&date_to=${date_to}`,
      })
    );
  };

  return (
    <Container fluid>
      <div className="calendar-title">Kalender Pembelajaran</div>
      <TimelineCalendar
        padding={"pt-0"}
        data={webinarsData?.items}
        className="py-0"
        dateChanges={dateChanges}
      />
    </Container>
  );
}

export default LearningCalendar;
