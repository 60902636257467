import { PromiseService } from "../../services/PromiseService";
import { COURSES_DATA_LINK, COURSE_COMPLETED_DATA_LINK } from "../config/CoursesConfig";
import {
  //get
  getCoursesData,
  getCoursesDataSuccess,
  getCoursesDataIdSuccess,
  getCoursesDataFail,
  //get
  getCourseLiveSearchData,
  getCourseLiveSearchDataSuccess,
  getCourseLiveSearchDataFail,
  //post
  postCoursesData,
  postCoursesDataSuccess,
  postCoursesDataFail,
  // put
  putCoursesData,
  putCoursesDataSuccess,
  putCoursesDataFail,
  // delete
  deleteCoursesData,
  deleteCoursesDataSuccess,
  deleteCoursesDataFail,

  // completed
  getCoursesCompletedData,
  getCoursesCompletedDataSuccess,
  getCoursesCompletedDataIdSuccess,
  getCoursesCompletedDataFail,
} from "../actions/CoursesActions";

export const postCoursesDataThunk = (data) => async (dispatch) => {
  dispatch(postCoursesData(data));
  const body = data;
  try {
    const response = await PromiseService.postData(COURSES_DATA_LINK, body);
    if (response.data?.code === 201 && response.data) {
      dispatch(postCoursesDataSuccess(response.data));
    } else {
      dispatch(postCoursesDataFail(response));
    }
  } catch (error) {
    dispatch(postCoursesDataFail(error));
  }
};

export const getCoursesDataThunk = (data) => async (dispatch) => {
  dispatch(getCoursesData(data));
  if (data) {
    let url = `${COURSES_DATA_LINK}/${data}`;
    if (data.param) url = `${COURSES_DATA_LINK}${data.param}`;
    try {
      const response = await PromiseService.getData(url);
      if (response.status === 200 && response.data) {
        if (data.param) dispatch(getCoursesDataSuccess(response.data));
        else dispatch(getCoursesDataIdSuccess(response.data));
      } else {
        dispatch(getCoursesDataFail(response));
      }
    } catch (error) {
      dispatch(getCoursesDataFail(error));
    }
  } else {
    try {
      const response = await PromiseService.getData(COURSES_DATA_LINK);
      if (response.status === 200 && response.data) {
        dispatch(getCoursesDataSuccess(response.data));
      } else {
        dispatch(getCoursesDataFail(response));
      }
    } catch (error) {
      dispatch(getCoursesDataFail(error));
    }
  }
};

export const getCoursesCompletedDataThunk = (data) => async (dispatch) => {
  dispatch(getCoursesCompletedData());
  if (data) {
    let url = `${COURSE_COMPLETED_DATA_LINK}/${data}`;
    if (data.param) url = `${COURSE_COMPLETED_DATA_LINK}${data.param}`;
    try {
      const response = await PromiseService.getData(url);
      if (response.status === 200 && response.data) {
        if (data.param)
          dispatch(getCoursesCompletedDataSuccess(response.data));
        else dispatch(getCoursesCompletedDataIdSuccess(response.data));
      } else {
        dispatch(getCoursesCompletedDataFail(response));
      }
    } catch (error) {
      dispatch(getCoursesCompletedDataFail(error));
    }
  } else {
    try {
      const response = await PromiseService.getData(
        COURSE_COMPLETED_DATA_LINK
      );
      if (response.status === 200 && response.data) {
        dispatch(getCoursesCompletedDataSuccess(response.data));
      } else {
        dispatch(getCoursesCompletedDataFail(response));
      }
    } catch (error) {
      dispatch(getCoursesCompletedDataFail(error));
    }
  }
};

//get live search

export const getCourseLiveSearchDataThunk = (data) => async (dispatch) => {
  dispatch(getCourseLiveSearchData(data));
  let url = `${COURSES_DATA_LINK}${data}`;
  try {
    const response = await PromiseService.getData(url);
    if (response.status === 200 && response.data) {
      dispatch(getCourseLiveSearchDataSuccess(response.data));
    } else {
      dispatch(getCourseLiveSearchDataFail(response));
    }
  } catch (error) {
    dispatch(getCourseLiveSearchDataFail(error));
  }
};

// put
export const putCoursesDataThunk = (data) => async (dispatch) => {
  dispatch(putCoursesData(data));
  const body = data.body;
  const id = data.id;
  try {
    const response = await PromiseService.putData(
      `${COURSES_DATA_LINK}/${id}`,
      body
    );
    if (response.status === 201 && response.data) {
      dispatch(putCoursesDataSuccess(response.data));
    } else {
      dispatch(putCoursesDataFail(response));
    }
  } catch (error) {
    dispatch(putCoursesDataFail(error));
  }
};

// delete
export const deleteCoursesDataThunk = (data) => async (dispatch) => {
  dispatch(deleteCoursesData(data));
  const id = data;
  try {
    const response = await PromiseService.deleteData(
      `${COURSES_DATA_LINK}/${id}`
    );
    if (response.status === 200 && response.data) {
      dispatch(deleteCoursesDataSuccess(response.data));
    } else {
      dispatch(deleteCoursesDataFail(response));
    }
  } catch (error) {
    dispatch(deleteCoursesDataFail(error));
  }
};
