export const SELECT_DEFAULT_STYLES = `
  .dropdown-toggle,
  .multiple-value-text-input input {
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    line-height: 100px;
    height: 50px;
    font-size: 14px;
    display: inline-block;
    border: 2px #eee solid;
    display: block;
    padding: 0.375rem 0.75rem;
    font-weight: 400;
    line-height: 1.5;
    color: #000;
    background-color: #fff;
    background-clip: padding-box;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    // padding: 16px;
    margin-top: 8px
    
  }
  .multiple-value-text-input .multiple-value-text-input-item {
    border-radius: 8px;
    font-size: 0.9rem;
    color: #eee;
    font-weight: 500;
    background-color: #007bff;
    // box-shadow: 0 6px 6px -3px rgba(48, 56, 64, 0.2),
    //   0 10px 14px 1px rgba(48, 56, 64, 0.14),
    //   0 4px 18px 3px rgba(48, 56, 64, 0.12);
    border: 2px #007bff solid;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  p {
    margin-top: 0.75rem !important;

  }
  .dropdown-toggle:hover {
    cursor: default;
  }
  .form-group {
    display : flex;
    flex-direction : column;
  }

  .form-group .dropdown {
    width: 100%;
  }
  `;
