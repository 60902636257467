import {
  //get
  USERS_DATA,
  USERS_DATA_ID_SUCCESS,
  USERS_DATA_SUCCESS,
  USERS_DATA_FAIL,
  //post
  USERS_DATA_POST,
  USERS_DATA_POST_SUCCESS,
  USERS_DATA_POST_FAIL,
  //put
  USERS_DATA_PUT,
  USERS_DATA_PUT_SUCCESS,
  USERS_DATA_PUT_FAIL,
  //delete
  USERS_DATA_DELETE,
  USERS_DATA_DELETE_SUCCESS,
  USERS_DATA_DELETE_FAIL,
  // change password
  USERS_CHANGE_PASSWORD,
  USERS_CHANGE_PASSWORD_SUCCESS,
  USERS_CHANGE_PASSWORD_FAIL
} from "../config/UsersConfig";

const intialState = {
  usersDataPost: "",
  usersDataPut: "",
  usersDataDelete: "",
  usersData: [],
  usersDataId: [],
  usersChangePassword: null,
  isLoading: false,
};

export const UsersReducers = function (state = intialState, action) {
  switch (action.type) {
    //get
    case USERS_DATA:
      return { ...state, isLoading: true };
    case USERS_DATA_SUCCESS:
      return { ...state, isLoading: false, usersData: action.value };
    case USERS_DATA_ID_SUCCESS:
      return { ...state, isLoading: false, usersDataId: action.value };
    case USERS_DATA_FAIL:
      return {
        ...state,
        isLoading: false,
        usersData: "error",
        usersDataId: "error",
      };
    //post
    case USERS_DATA_POST:
      return { ...state, isLoading: true, usersDataPost: "" };
    case USERS_DATA_POST_SUCCESS:
      return { ...state, isLoading: false, usersDataPost: "success" };
    case USERS_DATA_POST_FAIL:
      return { ...state, isLoading: false, usersDataPost: {status:"error", value:action.value} };

    //put
    case USERS_DATA_PUT:
      return { ...state, isLoading: true, usersDataPut: "" };
    case USERS_DATA_PUT_SUCCESS:
      return { ...state, isLoading: false, usersDataPut: "success" };
    case USERS_DATA_PUT_FAIL:
      return { ...state, isLoading: false, usersDataPut: "error" };

    //delete
    case USERS_DATA_DELETE:
      return { ...state, isLoading: true, usersDataDelete: "" };
    case USERS_DATA_DELETE_SUCCESS:
      return { ...state, isLoading: false, usersDataDelete: "success" };
    case USERS_DATA_DELETE_FAIL:
      return { ...state, isLoading: false, usersDataDelete: "error" };
    
    // change password
    case USERS_CHANGE_PASSWORD:
      return { ...state, isLoading: true, usersChangePassword: null };
    case USERS_CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        usersChangePassword: { status: "success", response: action.value },
      };
    case USERS_CHANGE_PASSWORD_FAIL:
      return {
        ...state,
        isLoading: false,
        usersChangePassword: { status: "error", response: action.value },
      };

    default:
      return state;
  }
};
