import {
  // get
  TOPIC_DATA,
  TOPIC_DATA_SUCCESS,
  TOPIC_DATA_ID_SUCCESS,
  // post
  TOPIC_DATA_POST,
  TOPIC_DATA_POST_SUCCESS,
  TOPIC_DATA_POST_FAIL,
  // put
  TOPIC_DATA_PUT,
  TOPIC_DATA_PUT_SUCCESS,
  TOPIC_DATA_PUT_FAIL,
  // delete
  TOPIC_DATA_DELETE,
  TOPIC_DATA_DELETE_SUCCESS,
  TOPIC_DATA_DELETE_FAIL,
  // fail
  TOPIC_DATA_FAIL,
  //remove
  REMOVE_TOPIC_DATA,
} from "../config/TopicConfig";

// get
export const getTopicData = (value) => ({
  type: TOPIC_DATA,
  value,
});
export const getTopicDataSuccess = (value) => ({
  type: TOPIC_DATA_SUCCESS,
  value,
});
export const getTopicDataIdSuccess = (value) => ({
  type: TOPIC_DATA_ID_SUCCESS,
  value,
});
export const getTopicDataFail = (value) => ({
  type: TOPIC_DATA_FAIL,
  value,
});

// post
export const postTopicData = (value) => ({
  type: TOPIC_DATA_POST,
  value,
});
export const postTopicDataSuccess = (value) => ({
  type: TOPIC_DATA_POST_SUCCESS,
  value,
});
export const postTopicDataFail = (value) => ({
  type: TOPIC_DATA_POST_FAIL,
  value,
});

// put
export const putTopicData = (value) => ({
  type: TOPIC_DATA_PUT,
  value,
});
export const putTopicDataSuccess = (value) => ({
  type: TOPIC_DATA_PUT_SUCCESS,
  value,
});
export const putTopicDataFail = (value) => ({
  type: TOPIC_DATA_PUT_FAIL,
  value,
});

// delete
export const deleteTopicData = (value) => ({
  type: TOPIC_DATA_DELETE,
  value,
});
export const deleteTopicDataSuccess = (value) => ({
  type: TOPIC_DATA_DELETE_SUCCESS,
  value,
});
export const deleteTopicDataFail = (value) => ({
  type: TOPIC_DATA_DELETE_FAIL,
  value,
});

// remove

export const removeTopicData = (value) => ({
  type: REMOVE_TOPIC_DATA,
  value,
});
